import * as React from "react";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import { MessageFormStateInterface } from "../../../../interfaces/MessageInterface";

type Props = {
  section: SectionInterface;
  destinationTag?: GuardianAnnounceMessageTagInterface;
};

const initialValues = { content: "", file: null };
const initialFormState = {
  values: initialValues,
  apiErrors: [],
  submitting: false,
};

export const useGuardianAnnounceMessageForm = (props: Props) => {
  const [formStates, setFormStates] = React.useState<{
    [tagId: string]: MessageFormStateInterface;
  }>({});

  const setFormState = (state: Partial<MessageFormStateInterface>) => {
    setFormStates((prevFormStates) => {
      if (!props.destinationTag) {
        return prevFormStates;
      }

      return {
        ...prevFormStates,
        [props.destinationTag.id]: {
          ...prevFormStates[props.destinationTag.id],
          ...state,
        },
      };
    });
  };

  const formState: MessageFormStateInterface | undefined = props.destinationTag
    ? props.destinationTag.id in formStates
      ? formStates[props.destinationTag.id]
      : initialFormState
    : undefined;

  React.useEffect(() => {
    setFormStates((prevFormStates) => {
      if (!props.destinationTag) {
        return prevFormStates;
      }

      return {
        ...prevFormStates,
        [props.destinationTag.id]: formState || initialFormState,
      };
    });
  }, [props.section.id, props.destinationTag?.id]);

  // フォーム送信後に、送信成功時にフォームの入力内容をリセット
  React.useEffect(() => {
    if (
      props.destinationTag &&
      !formState?.submitting &&
      formState?.apiErrors.length == 0
    ) {
      setFormState({
        values: initialValues,
      });
    }
  }, [formState?.submitting]);

  const handleSubmit = () => {
    setFormState({
      submitting: true,
    });
  };

  const handleSubmitSuccess = () => {
    setFormState(initialFormState);
  };
  const handleSubmitError = (errors: ApiErrorInterface[]) => {
    setFormState({
      submitting: false,
      apiErrors: errors,
    });
  };

  // テキストをフォームに設定
  const handleChangeContent = (content: string) => {
    const values = formState?.values || initialValues;
    setFormState({
      values: {
        ...values,
        content,
      },
    });
  };

  // ファイルをフォームに設定
  const handleChangeFormFile = (file: File | null) => {
    const values = formState?.values || initialValues;
    setFormState({
      values: {
        ...values,
        file,
      },
    });
  };

  return {
    formState,
    handleSubmit,
    handleSubmitSuccess,
    handleSubmitError,
    handleChangeContent,
    handleChangeFormFile,
  };
};
