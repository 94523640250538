import * as React from "react";

import Label from "../../../../components/atoms/Label";
import { ExaminationOrganizerSelect } from "../../../../components/features/ExaminationOrganizerSelect/ExaminationOrganizerSelect";
import { ExaminationClassificationSelect } from "../../../../components/features/ExaminationClassificationSelect/ExaminationClassificationSelect";
import { ExaminationSelect } from "../../../../components/features/ExaminationSelect/ExaminationSelect";
import { SingleDatePickerField } from "../../../../components/atoms/SingleDatePickerField";
import { Examination } from "../../../../domains/Examination";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../../components/general/Popover/Popover";
import { IconHelpSupport } from "@studyplus/boron-ui";
import classNames from "classnames";
import { parseISO } from "date-fns";

type Props = {
  handleSetTemplateDownloadLink: (
    examinationId: string | null,
    examinedOn: Date | undefined,
  ) => void;
};
export const ExaminationResultTemplateDownloadForm = (props: Props) => {
  const {
    examinationOrganizerId,
    examinationClassificationId,
    examinedOn,
    isExaminedOnDisabled,
    onChangeExaminationOrganizer,
    onChangeExaminationClassification,
    onChangeExamination,
    onChangeExaminedOn,
    examinationId,
  } = useExaminationResultTemplateDownloadForm(props);

  return (
    <div className="pb-10">
      <div className="flex flex-wrap">
        <div className="w-[32rem] grow-0 pr-4">
          <Label>主催者</Label>
          <ExaminationOrganizerSelect
            onChange={onChangeExaminationOrganizer}
            value={examinationOrganizerId ?? undefined}
          />
        </div>
        <div className="w-[32rem] grow-0 pr-4">
          <Label>試験の種類</Label>
          <ExaminationClassificationSelect
            organizerId={examinationOrganizerId}
            onChange={onChangeExaminationClassification}
            value={examinationClassificationId ?? null}
          />
        </div>
      </div>
      <div className="mt-1 flex flex-wrap">
        <div className="w-[32rem] grow pr-4">
          <Label>試験名</Label>
          <ExaminationSelect
            organizerId={examinationOrganizerId}
            classificationId={examinationClassificationId}
            onChange={onChangeExamination}
            value={examinationId ?? undefined}
          />
        </div>
        <div className="w-[20rem] flex-none" aria-label="受験日を選択">
          <Label>
            受験日
            <ExaminedOnHelpTooltip />
          </Label>
          <SingleDatePickerField
            value={examinedOn}
            disabled={isExaminedOnDisabled}
            onDateChange={(m) => onChangeExaminedOn(m)}
            className={classNames("h-[1.8rem] bg-white", {
              ["cursor-not-allowed"]: isExaminedOnDisabled,
            })}
            calendarPosition={{ left: "lg:left-[-10rem]" }}
          />
        </div>
      </div>
    </div>
  );
};

const ExaminedOnHelpTooltip = () => {
  return (
    <div className="inline-flex items-center">
      <PopoverProvider>
        <Popover>
          <PopoverTrigger className="cursor-pointer">
            <IconHelpSupport className="h-[1.4rem] w-[1.4rem] align-bottom text-gray-800" />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent side="top">
              <div className="text-center">
                成績の一覧を並び替える際に参照されます
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    </div>
  );
};

const useExaminationResultTemplateDownloadForm = (props: Props) => {
  const [examinationOrganizerId, setExaminationOrganizerId] = React.useState<
    string | null
  >(null);
  const [examinationClassificationId, setExaminationClassificationId] =
    React.useState<string | null>(null);
  const [examinationId, setExaminationId] = React.useState<string | null>(null);
  const [examinedOn, setExaminedOn] = React.useState<Date | undefined>();
  const [isExaminedOnDisabled, setIsExaminedOnDisabled] =
    React.useState<boolean>(true);

  const onChangeExaminationOrganizer = (value: string | null) => {
    setExaminationOrganizerId(value);
  };

  const onChangeExaminationClassification = (value: string | null) => {
    setExaminationClassificationId(value);
  };

  const onChangeExamination = (value: Examination | undefined) => {
    if (value) {
      setExaminationId(value.id);
      setExaminedOn(
        value.schedule?.startOn ? parseISO(value.schedule.startOn) : undefined,
      );
    } else {
      setExaminationId(null);
      setExaminedOn(undefined);
    }
  };

  const onChangeExaminedOn = (value: Date) => {
    setExaminedOn(value);
    props.handleSetTemplateDownloadLink(examinationId, value);
  };

  React.useEffect(() => {
    setExaminationClassificationId(null);
    setExaminationId(null);
    setExaminedOn(undefined);
  }, [examinationOrganizerId]);

  React.useEffect(() => {
    setExaminationId(null);
    setExaminedOn(undefined);
  }, [examinationClassificationId]);

  React.useEffect(() => {
    setIsExaminedOnDisabled(!examinationId);
    props.handleSetTemplateDownloadLink(examinationId, examinedOn);
  }, [examinationId]);

  return {
    examinationId,
    examinationOrganizerId,
    examinationClassificationId,
    examinedOn,
    isExaminedOnDisabled,
    onChangeExaminationOrganizer,
    onChangeExaminationClassification,
    onChangeExamination,
    onChangeExaminedOn,
  };
};
