// IE11のために必要なPolyfiil.
//
// サイズを最適化する場合やIE11を切る際には見直してください
// core-js のstable全部入りを利用しています
// https://github.com/zloirock/core-js/tree/master/packages/core-js/stable
import "core-js/stable";

// その他のpolyfill
//
// https://github.com/mo/abortcontroller-polyfill
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
// https://github.com/github/fetch
import "whatwg-fetch";

import "svgxuse";

Promise.allSettled = require("core-js/es/promise/all-settled");
