import { createAction } from "redux-actions";
import StudentTag from "../../domains/StudentTag";

// URLのクエリストリングからfiltersの状態をつくるアクション
export interface InitializeFiltersByQueryPayload {
  query: string;
  allTags: StudentTag[];
  sectionId: string;
  force?: boolean;
}

export const initializeFiltersByQuery =
  createAction<InitializeFiltersByQueryPayload>("INITIALIZE_FILTERS_BY_QUERY");
export const initializeTagFilter = createAction<{ sectionId: string }>(
  "INITIALIZE_TAG_FILTER",
);
export const initializeTagFilterError = createAction(
  "INITIALIZE_TAG_FILTER_ERROR",
);
