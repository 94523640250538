import { useQuery } from "@tanstack/react-query";
import { createError } from "../../errors";
import ApiClient from "../../api";
import ApiResponse from "../../interfaces/ApiResponse";
import { CommentInterface } from "../../interfaces/CommentInterface";

type Props = {
  sectionId: string;
  enabled: boolean;
};
type ResponseBody = {
  studyRecordComments: ApiResponse<CommentInterface[]>;
};
export const useFetchStudyRecordComments = ({ sectionId, enabled }: Props) => {
  const path = `/api/v1/sections/${sectionId}/study_records/comments`;
  return useQuery<ResponseBody, Error>({
    queryKey: [path],
    queryFn: async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    enabled,
  });
};
