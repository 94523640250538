import styles from "./styles.scss";
import HeaderAccount from "../HeaderAccount/index";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { Link } from "react-router-dom";
import SectionInterface from "../../../interfaces/SectionInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect } from "react-redux";
import NaviMenuSPOpenButton from "../NaviMenuSPOpenButton/index";
import { toggleNavigation } from "../../../actions/toggleNavigation";

interface Props {
  currentOperator: OperatorInterface;
  operators: OperatorInterface[];
  section: SectionInterface;
  navigationOpened: boolean;
  toggleNavigation: () => void;
}

// Presentational Component
const SPGlobalHeaderComponent = (props: Props) => {
  return (
    <div className={styles.root}>
      <NaviMenuSPOpenButton />
      <div>
        <Link to={`/sections/${props.section.id}`}>
          <img src="/images/logo.svg" />
        </Link>
      </div>
      <HeaderAccount
        operators={props.operators}
        currentOperator={props.currentOperator}
      />
    </div>
  );
};

// Container Component
const mapStateToProps = (state: AppStateInterface) => {
  return {
    navigationOpened: state.navigationOpened,
  };
};

const actions = {
  toggleNavigation,
};

const SPGlobalHeader = connect(
  mapStateToProps,
  actions,
)(SPGlobalHeaderComponent);

export default SPGlobalHeader;
