import { createAction } from "redux-actions";
import SectionKarteApi from "../api-clients/SectionKarteApi";
import { Dispatch } from "redux";
import { buildMainResourceApiErrorAction } from "./common/errors";

// カルテ一覧
export const getKartesRequestSending = createAction(
  "GET_SECTION_KARTES_REQUEST_SENDING",
);
export const getInitialKartesRequestSending = createAction(
  "GET_INITIAL_SECTION_KARTES_REQUEST_SENDING",
);
export const getKartesResponseReceived = createAction(
  "GET_SECTION_KARTES_RESPONSE_RECEIVED",
);
export const getKartesResponseError = createAction(
  "GET_SECTION_KARTES_RESPONSE_ERROR",
);

// 初期ロード
export const loadInitialSectionKartes =
  (sectionId: string) => (dispatch: Dispatch) => {
    dispatch(getInitialKartesRequestSending());
    const query = location.search;
    // NOTE: 生徒フィルターをURLパラメータから復元する処理により初期ロードが2回走り、取得した結果が重複して表示されるためinterruptGetを使う。
    //       その際、1回目がAbortErrorとなるため、エラー処理でAbortErrorを無視する。(この制御をしなくても問題なさそうだが念のため)
    SectionKarteApi.interruptGetKartes(sectionId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getKartesResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getKartesResponseError());
        }
      })
      .catch((err: any) => {
        if (err.name !== "AbortError") {
          dispatch(getKartesResponseError());
        }
      });
  };

// 追加読み込み
export const loadSectionKartes =
  (sectionId: string, page?: number) => (dispatch: any) => {
    const query = location.search
      ? location.search + `&page=${page}`
      : `?page=${page}`;

    dispatch(getKartesRequestSending());

    SectionKarteApi.getKartes(sectionId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getKartesResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getKartesResponseError());
        }
      })
      .catch(() => {
        dispatch(getKartesResponseError());
      });
  };

export const changeSectionKartesFilter =
  (sectionId: string, query: string) => (dispatch: any) => {
    dispatch(getInitialKartesRequestSending());
    // NOTE: 絞り込みの条件変更で、連続で実行されることを考慮して割り込みfetchにしてあります
    SectionKarteApi.interruptGetKartes(sectionId, { query })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getKartesResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getKartesResponseError());
        }
      })
      .catch(() => {
        dispatch(getKartesResponseError());
      });
  };

export const getKarteTemplatesSuccess = createAction(
  "GET_KARTE_TEMPLATES_SUCCESS",
);
export const getKarteTemplatesError = createAction("GET_KARTE_TEMPLATES_ERROR");
export const loadKarteTemplates =
  (sectionId: string) => (dispatch: Dispatch) => {
    SectionKarteApi.getKarteTemplates(sectionId)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getKarteTemplatesSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getKarteTemplatesError());
        }
      })
      .catch(() => {
        dispatch(getKarteTemplatesError());
      });
  };
