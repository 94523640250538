import { truncate } from "../helpers/String";
export type LearningMaterial = {
  publicId: string;
  code: string;
  name: string;
  imageUrl: string;
  unit: string;
};

export const truncateName = (learningMaterial: LearningMaterial): string => {
  const maxLength = 14;
  return truncate(learningMaterial.name, maxLength);
};
