import { useMemo } from "react";
import StudentTag from "../../domains/StudentTag";

export const useSelectedTags = (selectedTags: StudentTag[] | undefined) => {
  return useMemo(() => {
    return {
      tagIds: selectedTags
        ?.filter((tag) => tag.type === "tag_ids")
        .map((tag) => tag.id),
      smartTagIds: selectedTags
        ?.filter((tag) => tag.type === "smart_tag")
        .map((tag) => tag.id),
      jobIds: selectedTags
        ?.filter((tag) => tag.type === "job")
        .map((tag) => tag.id),
    };
  }, [selectedTags]);
};
