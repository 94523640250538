import * as React from "react";
import styles from "./styles.scss";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { Button } from "@studyplus/boron-ui";
import {
  withFormik,
  FormikBag,
  FormikProps,
  Form,
  Field,
  ErrorMessage,
} from "formik";
import validationSchema from "./validationSchema";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import { TagFormInterface } from "../../../../interfaces/TagFormInterface";
import { HandleThunkActionCreator } from "react-redux";
import { createTag } from "../../../../actions/pages/SectionsSettingsTagsPage/actions";
import ErrorText from "../../../../components/atoms/ErrorText";
import Input from "../../../../components/atoms/Input";
import StudentTag from "../../../../domains/StudentTag";

interface ComponentProps {
  section: SectionInterface;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  onSubmit: HandleThunkActionCreator<typeof createTag>;
  onSubmitCallback: (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => void;
}

type Values = TagFormInterface;

type Props = ComponentProps & FormikProps<Values>;

class SectionTagForm extends React.Component<Props, Record<string, never>> {
  render() {
    return (
      <React.Fragment>
        <div className={styles.errors}>
          {this.renderApiErrors()}
          <ErrorMessage
            name="name"
            component={ErrorText}
            className={styles.input__error}
          />
        </div>
        <Form className={styles.form}>
          <Field
            placeholder="タグ名を入力"
            name="name"
            component={Input}
            className={styles.input}
          />
          <Button
            disabled={this.isDisabled()}
            type="submit"
            className={styles.btn}
          >
            タグを登録
          </Button>
        </Form>
      </React.Fragment>
    );
  }

  private isDisabled = (): boolean => {
    return this.props.submitting || !this.props.isValid;
  };

  private renderApiErrors = () => {
    if (this.props.apiErrors.length > 0) {
      return this.props.apiErrors.map((err: ApiErrorInterface, idx: number) => {
        return (
          <p className={styles.input__error} key={`apiError-${idx}`}>
            {err.message}
          </p>
        );
      });
    }
  };
}

const mapPropsToValues = (): Values => ({ name: "" });

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  const { props } = formikBag;
  const callback = (type: "create", tag: StudentTag) => {
    formikBag.resetForm();
    props.onSubmitCallback(type, tag);
  };
  props.onSubmit(props.section.id, values, callback);
};

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
})(SectionTagForm);
