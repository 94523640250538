import { Recurrence, toByWeekday } from "../domains/Recurrence";

export const fromJson = (json: unknown): Recurrence => {
  const record = json as Record<string, unknown>;

  if (record.until !== null && typeof record.until !== "string") {
    throw new Error("Server responded 'until' as an unexpected value");
  }
  if (record.byDay !== null && typeof record.byDay !== "string") {
    throw new Error("Server responded 'byDay' as an unexpected value");
  }

  const byDay = record.byDay ? toByWeekday(record.byDay as any) : null;

  return {
    ...record,
    until: record.until ? new Date(record.until) : null,
    byDay,
  } as Recurrence;
};
