import styles from "./styles.scss";
import SectionInterface from "../../../interfaces/SectionInterface";
import { dispatchPostNewSectionStudent } from "../../../actions/pages/sectionStudentsNew/index";
import { HandleThunkActionCreator } from "react-redux";
import { NavigateFunction } from "react-router";
import { StudentParamsInterface } from "../../../interfaces/StudentInterface";
import StudentForm from "../StudentForm";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";

interface Props {
  section: SectionInterface;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  navigate: NavigateFunction;
  postNewStudent: HandleThunkActionCreator<
    typeof dispatchPostNewSectionStudent
  >;
}

const StudentNewForm = (props: Props) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>生徒の登録</h2>
      <div className={styles.form}>
        <StudentForm
          onSubmit={handleSubmit(props)}
          apiErrors={props.apiErrors}
          submitting={props.submitting}
        />
      </div>
    </div>
  );
};

const handleSubmit = (props: Props) => (values: StudentParamsInterface) => {
  props.postNewStudent(props.section.id, values, props.navigate);
};

export default StudentNewForm;
