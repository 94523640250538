import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import ApiClient from "../../../api";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import ApiResponse, {
  PaginationMetaData,
} from "../../../interfaces/ApiResponse";
import { Content, fromJson } from "../../../domains/Content";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useCallback } from "react";
import { SearchContentTypes } from "../../pages/SectionsSettingsContentCoursesNewOrEditPage/ContentCourseForm/ContentsSelectModal/useContentsSearchForm";
import { createError, HTTPErrors } from "../../../errors";

export type UseFetchContentsProps = {
  sectionId: string;
  query?: FetchContentsQuery;
};

type FetchContentsQuery = {
  content_types: SearchContentTypes;
  name: string;
};

export type ResponseData = ApiResponse<Content[]>;

export const useFetchSectionContents = ({
  sectionId,
  query,
}: UseFetchContentsProps) => {
  return useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: [`sections/${sectionId}/contents`, query],
    queryFn: async ({ pageParam }) => {
      const res = await ApiClient.get(
        `/api/v1/sections/${sectionId}/contents`,
        {
          query: {
            page: pageParam,
            ...query,
          },
        },
      );
      if (res.ok) {
        const json = await res.json();
        if (
          !json.contents ||
          !Array.isArray(json.contents.data) ||
          !json.contents.meta
        ) {
          throw await createError(res);
        }

        return {
          meta: json.contents.meta as PaginationMetaData,
          data: json.contents.data.map((content: Record<string, unknown>) => {
            return fromJson(content);
          }),
        };
      } else {
        throw await createError(res);
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.meta.currentPage;
      const nextPage = currentPage + 1;
      const totalPages = lastPage.meta.totalPages;
      return nextPage > totalPages ? undefined : nextPage;
    },
    refetchOnWindowFocus: false,
  });
};

type UseDeleteContentProps = {
  sectionId: string;
};
export const useDeleteContent = ({ sectionId }: UseDeleteContentProps) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const client = useQueryClient();
  const { mutate, isPending: isDeleting } = useMutation({
    mutationFn: async (contentId: Content["id"]) => {
      const res = await ApiClient.delete(
        `/api/v1/sections/${sectionId}/contents/${contentId}`,
      );
      if (res.ok) {
        showSuccessMessage("コンテンツを1件削除しました。");
      } else {
        showErrorMessage(HTTP_ERROR_MESSAGE);
      }
    },
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [`sections/${sectionId}/contents`],
      });
    },
  });

  const onDelete = useCallback(
    (content: Content) => {
      if (
        confirm(
          `コンテンツ「${content.name}」を削除してもよろしいですか？\n` +
            "コースにコンテンツを登録している場合、コース内のコンテンツも削除されますのでご注意ください。",
        )
      ) {
        mutate(content.id);
      }
    },
    [mutate],
  );

  return {
    isDeleting,
    onDelete,
  };
};
