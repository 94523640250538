import { createAction } from "redux-actions";
import { Dispatch } from "redux";
import ApiClient from "../api";
import { SectionOperatorInvitationInterface } from "../interfaces/SectionOperatorInvitationInterface";
import { NavigateFunction } from "react-router";
import { HTTP_ERROR_MESSAGE } from "../reducers/index";
import { apiRequestError } from "./apiRequestError";
import { buildMainResourceApiErrorAction } from "./common/errors";

const getSectionOperatorInvitationRequest = createAction(
  "GET_SECTION_OPERATOR_INVITATION_REQUEST",
);
const getSectionOperatorInvitationSuccess = createAction(
  "GET_SECTION_OPERATOR_INVITATION_SUCCESS",
);
const getSectionOperatorInvitationError = createAction(
  "GET_SECTION_OPERATOR_INVITATION_ERRROR",
);
export const getSectionOperatorInvitation =
  (invitationId: string) => (dispatch: Dispatch) => {
    dispatch(getSectionOperatorInvitationRequest());
    ApiClient.get(`/api/v1/invitations/${invitationId}`)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(
              (json: {
                sectionOperatorInvitation: SectionOperatorInvitationInterface;
              }) => {
                dispatch(getSectionOperatorInvitationSuccess(json));
              },
            );
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getSectionOperatorInvitationError());
        }
      })
      .catch(() => {
        dispatch(getSectionOperatorInvitationError());
      });
  };

export const acceptInvitation =
  (
    invitation: SectionOperatorInvitationInterface,
    navigate: NavigateFunction,
    requestCallback: () => void,
  ) =>
  (dispatch: Dispatch) => {
    ApiClient.patch(`/api/v1/invitations/${invitation.id}`)
      .then((res) => {
        if (res.ok) {
          navigate(`/sections/${invitation.sectionId}`);
        } else {
          const payload = {
            errors: [{ message: "招待を承認できませんでした" }],
          };
          dispatch(apiRequestError(payload));
          navigate(`/sections`);
        }
      })
      .catch(() => {
        const payload = { errors: [{ message: HTTP_ERROR_MESSAGE }] };
        dispatch(apiRequestError(payload));
        navigate(`/sections`);
      });
    requestCallback();
  };

export const denyInvitation =
  (
    invitation: SectionOperatorInvitationInterface,
    navigate: NavigateFunction,
    requestCallback: () => void,
  ) =>
  (dispatch: Dispatch) => {
    ApiClient.delete(`/api/v1/invitations/${invitation.id}`)
      .then(() => {
        navigate(`/sections`);
      })
      .catch(() => {
        const payload = { errors: [{ message: HTTP_ERROR_MESSAGE }] };
        dispatch(apiRequestError(payload));
        navigate(`/sections`);
      });
    requestCallback();
  };
