import ApiClient from "../../api";
import * as queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import { createError } from "../../errors";
import StudentTag from "../../domains/StudentTag";

type Props = {
  sectionId: string;
  query?: Record<string, unknown>;
  enabled?: boolean;
  activeStudent?: boolean;
};
type ResponseBody = {
  tags: StudentTag[];
  query: string;
};
// APIからタグ全件欲しいときは十分に大きい数字をperに入れて投げるという仕様
const MAX_PER = 1000000000;

export const useFetchStudentTags = ({
  sectionId,
  query = {},
  enabled = true,
  activeStudent = true,
}: Props) => {
  const params = { ...query, per: MAX_PER, active_student: activeStudent };
  const queryParams = `?${queryString.stringify(params)}`;

  return useQuery<ResponseBody, Error>({
    queryKey: cacheKeyOf(sectionId, queryParams),
    queryFn: async () => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/all_tags`,
        { query: queryParams },
      );
      if (response.ok) {
        const json = await response.json();
        return {
          tags: json.allTags.data as StudentTag[],
          query: queryParams,
        };
      }
      throw await createError(response);
    },
    enabled,
  });
};

const cacheKeyOf = (sectionId: string, queryParams: string) => [
  "student_tags",
  sectionId,
  queryParams,
];
