import * as React from "react";
import StudentInterface from "../../../interfaces/StudentInterface";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import StudentNewLearningMaterial from "../../../components/organisms/StudentNewLearningMaterial";
import {
  LearningMaterialSearchType,
  SearchLearningMaterialParams,
} from "../../../api-clients/SectionLearningMaterialApi";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import StudentsLearningMaterialsNewStateInterface from "../../../interfaces/StudentsLearningMaterialsNewStateInterface";
import * as queryString from "query-string";
import { connect, HandleThunkActionCreator } from "react-redux";
import {
  resetLearningMaterialsSearchResult,
  dispatchSearchLearningMaterials,
  dispatchSearchMoreLearningMaterials,
} from "../../../actions/pages/studentsLearningMaterialsNew/index";
import { dispatchPreRegisterLearningMaterialToBookshelf } from "../../../actions/pages/StudentsLearningMaterialsPreRegistrationsNewPage/index";
import { getPreRegisteredStudentLearningMaterials } from "../../../actions/pages/studentsLearningMaterials/index";
import StudentsLearningMaterialsStateInterface from "../../../interfaces/StudentsLearningMaterialsStateInterface";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface Props
  extends WithRouterProps<{ studentId: string }>,
    OutletContextProps {
  student: StudentInterface | null;
  learningMaterialsState: StudentsLearningMaterialsNewStateInterface;
  studentLearningMaterialsState: StudentsLearningMaterialsStateInterface;
  dispatchSearchLearningMaterials: HandleThunkActionCreator<
    typeof dispatchSearchLearningMaterials
  >;
  dispatchLoadMoreLearningMaterials: HandleThunkActionCreator<
    typeof dispatchSearchMoreLearningMaterials
  >;
  dispatchPreRegisterLearningMaterialToBookshelf: HandleThunkActionCreator<
    typeof dispatchPreRegisterLearningMaterialToBookshelf
  >;

  resetLearningMaterialsSearchResult: () => void;
  loadStudentLearningMaterials: HandleThunkActionCreator<
    typeof getPreRegisteredStudentLearningMaterials
  >;
}

class StudentsLearningMaterialsPreRegistrationsNewPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.loadStudentLearningMaterials();
    props.setActiveMenu("text");
  }

  componentDidMount() {
    (window as any).onMainScrollAreaScroll = this.handleScroll;
    this.initializePage();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location && this.props.student) {
      this.initializePage();
    }
  }

  componentWillUnmount() {
    (window as any).onMainScrollAreaScroll = null;
  }

  private loadStudentLearningMaterials() {
    const { studentId } = this.props.params;
    this.props.loadStudentLearningMaterials(studentId);
  }

  render() {
    if (!this.props.student) {
      return null;
    }

    const { keyword, type } = queryString.parse(this.props.location.search);

    return (
      <StudentNewLearningMaterial
        student={this.props.student}
        defaultSearchText={(keyword as string) || ""}
        defaultSearchType={
          (type as LearningMaterialSearchType) || "section_original"
        }
        onRegister={this.props.dispatchPreRegisterLearningMaterialToBookshelf}
        isPreRegistration={true}
        learningMaterialsState={this.props.learningMaterialsState}
        studentLearningMaterialsState={this.props.studentLearningMaterialsState}
      />
    );
  }

  private handleScroll = () => {
    const {
      learningMaterialsState,
      dispatchLoadMoreLearningMaterials,
      student,
    } = this.props;
    if (
      !student ||
      learningMaterialsState.loading ||
      !learningMaterialsState.hasMore
    ) {
      return;
    }

    const { keyword, type } = queryString.parse(this.props.location.search);

    dispatchLoadMoreLearningMaterials(student.section.id, {
      keyword: keyword as string,
      type: type as LearningMaterialSearchType,
      page: (learningMaterialsState.meta.currentPage || 1) + 1,
      student_id: this.props.params.studentId,
    });
  };

  private initializePage() {
    const { keyword, type } = queryString.parse(this.props.location.search);

    const params: SearchLearningMaterialParams = {
      keyword: keyword as string,
      type: type as LearningMaterialSearchType,
      student_id: this.props.params.studentId,
    };

    if (keyword && type && this.props.student) {
      this.props.dispatchSearchLearningMaterials(
        this.props.student.section.id,
        params,
      );
    } else {
      this.props.resetLearningMaterialsSearchResult();
    }
  }
}

const mapStateToProps = (state: AppStateInterface) => {
  return {
    learningMaterialsState: state.studentsLearningMaterialsNew,
    studentLearningMaterialsState: state.studentsLearningMaterials,
  };
};

const actions = {
  dispatchLoadMoreLearningMaterials: dispatchSearchMoreLearningMaterials,
  dispatchSearchLearningMaterials,
  resetLearningMaterialsSearchResult,
  dispatchPreRegisterLearningMaterialToBookshelf,
  loadStudentLearningMaterials: getPreRegisteredStudentLearningMaterials,
};

const EnhancedPage = enhanceStudentsPage(
  StudentsLearningMaterialsPreRegistrationsNewPage,
  {
    title: "テキスト",
  },
);

const ConnectedPage = connect(mapStateToProps, actions)(EnhancedPage as any);

export default ConnectedPage;
