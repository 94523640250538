import { IconAttention2, IconPlus } from "@studyplus/boron-ui";
import { StudentAnalyticsRowInterface } from "../../../interfaces/StudentAnalyticsTableInterface";
import Time from "../../atoms/Time";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../general/Popover/Popover";

type Props = {
  learningMaterials: StudentAnalyticsRowInterface[];
  withFreePlanTooltip: boolean;
};

export const TotalTime = (props: Props) => {
  const totalTime = props.learningMaterials
    .flatMap((lm) => lm.scores)
    .reduce((prev, current) => prev + current, 0);

  return props.withFreePlanTooltip ? (
    <TotalTimeWithTooltip totalTime={totalTime} />
  ) : (
    <TotalTimeWithoutTooltip totalTime={totalTime} />
  );
};

const TotalTimeWithoutTooltip = ({ totalTime }: { totalTime: number }) => {
  return (
    <div className="flex items-center justify-end">
      Total: <Time seconds={totalTime} />
    </div>
  );
};

const TotalTimeWithTooltip = ({ totalTime }: { totalTime: number }) => {
  return (
    <div className="flex items-center justify-end">
      <PopoverProvider>
        <Popover>
          <PopoverTrigger>
            <div className="flex cursor-pointer items-center justify-end text-slate-700">
              Total: <Time seconds={totalTime} />
              <IconPlus className="inline-block h-2 w-2 text-black" />
              <IconAttention2 className="inline-block h-3 w-3 text-gray-900" />
            </div>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent side="top">
              <p className="text-center text-sm">
                プロプランにすると生徒が記録した
                <br />
                全学習記録が閲覧できます
              </p>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    </div>
  );
};
