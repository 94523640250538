import { Schedule, ScheduleForStudent } from "../domains/Schedule";
import { fromJson as fromJsonToRecurrence } from "./Recurrence";

/*
 * usage:
 * import * as Schedule from "../../domains-adapters/Schedule"
 * ...
 * const responseJson = await res.json()
 * if (Array.isArray(responseJson.schedules)) {
 *    throw new Error("....")
 * }
 * return responseJson.schedules.map(obj => Schedule.fromJson(obj))
 */
export const fromJson = (json: unknown): Schedule => {
  const record = json as Record<string, any>;

  if (typeof record.startAt !== "string" || typeof record.endAt !== "string") {
    throw new Error("Server responded startAt or endAt as unexpected value");
  }

  const studySchedule: Record<string, any> = record.studySchedule;

  return {
    ...record,
    startAt: new Date(record.startAt),
    endAt: new Date(record.endAt),
    recurrence: record.recurrence
      ? fromJsonToRecurrence(record.recurrence)
      : null,
    studySchedule: record.studySchedule
      ? {
          amount: studySchedule.amount,
          numberOfSeconds: studySchedule.numberOfSeconds,
          learningMaterial: {
            name: studySchedule.learningMaterial.name,
            unit: studySchedule.learningMaterial.unit,
            imageUrl: studySchedule.learningMaterial.imageUrl,
            code: studySchedule.learningMaterial.learningMaterialCode,
            publicId: studySchedule.learningMaterial.publicId,
          },
        }
      : null,
  } as Schedule;
};

export const toStudentScheduleFromJson = (
  json: Record<string, any>,
): ScheduleForStudent => {
  return {
    ...fromJson(json),
    lectureAttendance: json.lectureAttendance,
    lectureSession: json.lectureSession,
    section: json.section,
    teacher: json.teacher,
    classroom: json.classroom,
    isOwnedBySection: json.isOwnedBySection,
  };
};
