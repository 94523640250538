import * as React from "react";
import { useMutateSchedule, MutateScheduleProps } from "../useScheduleApi";
import { Schedule } from "../../../../domains/Schedule";

export type UseUpdateWithRecurrencesProps = {
  onClose: () => void;
  schedule: Schedule;
  onMutateSuccess: (message: string) => void;
  onMutateError: (message: string) => void;
} & MutateScheduleProps;

export const useUpdateWithRecurrences = (
  props: UseUpdateWithRecurrencesProps,
) => {
  const { studentId, from, to, schedule } = props;

  const mutateState = useMutateSchedule({
    studentId,
    from,
    to,
    refetchOnSettled: true,
  });

  const onSubmit = React.useCallback(
    (chosen: any) => {
      mutateState.mutate({
        schedule: {
          ...schedule,
          recurrence: chosen === "this" ? null : schedule.recurrence,
        },
        updateType: chosen,
      });
    },
    [schedule, mutateState],
  );

  React.useEffect(() => {
    if (!mutateState.isLoading && mutateState.data) {
      props.onClose();
      props.onMutateSuccess("更新を完了しました");
    } else if (mutateState.isError) {
      props.onMutateError("更新に失敗しました");
    }
  }, [
    mutateState.data,
    mutateState.isLoading,
    mutateState.isError,
    mutateState.error,
  ]);

  return {
    schedule,
    isUpdating: mutateState.isLoading,
    onSubmit,
  };
};
