import { ImportHistoryInterface } from "../../../../domains/ImportHistory";

type Props = {
  operation: ImportHistoryInterface["operation"];
};

export const OperationNote = ({ operation }: Props) => {
  switch (operation) {
    case "bookshelf_insert":
      return (
        <>
          <br />
          登録するデジタル教材が有償教材の場合、生徒の本棚に登録が完了したタイミングで利用料が発生します。無償教材の場合、利用料は発生しません。
          <br />
          詳しくは、デジタル教材のカタログの注意事項をご確認ください。
        </>
      );
    case "student_billing_plan_update":
      return (
        <>
          <br />
          ※プロ（有料）プランに変更してよいか、ご確認の上お進めください。
        </>
      );
    default:
      return null;
  }
};
