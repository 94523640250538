import { useLocation, useParams } from "react-router-dom";

type FeatureTypes = "attendees" | "questionnaires";

export const usePageInfoFromUrl = () => {
  const params = useParams<{ lectureSessionId: string }>();
  const location = useLocation();

  const featureType: FeatureTypes = location.pathname.match(/attendees/)
    ? "attendees"
    : "questionnaires";

  const lectureSessionId: string = params.lectureSessionId || "";

  return {
    featureType,
    lectureSessionId,
  };
};
