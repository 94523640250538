import * as React from "react";
import styles from "./styles.scss";
import Input from "../../../../../components/atoms/Input";
import Label from "../../../../../components/atoms/Label";
import { BlockRow } from "../../../../../components/atoms/BlockRow/index";
import { Button } from "@studyplus/boron-ui";
import { InlineBlock } from "../../../../../components/atoms/InlineBlock";
import { Flex } from "../../../../../components/atoms/Flex/index";
import DeleteIcon from "../../../../../components/atoms/DeleteIcon/index";
import Textarea from "../../../../../components/atoms/Textarea/index";
import ErrorText from "../../../../../components/atoms/ErrorText";
import { FormikErrors, FormikTouched } from "formik";
import { UnitValue, ContentValue } from "../useContentCourseFormState";
import Icon from "../../../../../components/atoms/Icon";
import {
  Droppable,
  Draggable,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";

type Props = {
  unit: UnitValue;
  onOpenContentsSelectModal: () => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onDelete: (id: string) => void;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onRemoveContent: (unitId: string, content: ContentValue) => void;
  errors: FormikErrors<UnitValue>;
  touched: FormikTouched<UnitValue>;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  contentDraggableIdFor: (unit: UnitValue, content: ContentValue) => string;
};
export const ContentUnitForm = ({
  errors,
  unit,
  onBlur,
  onChange,
  onDelete,
  onRemoveContent,
  touched,
  onOpenContentsSelectModal,
  dragHandleProps,
  contentDraggableIdFor,
}: Props) => {
  const handleDelete = () => {
    onDelete(unit.id);
  };

  return (
    <div className={styles.form}>
      <div {...dragHandleProps}>
        <Icon name="icon-drag-indicator" className={styles.unitDragIndicator} />
      </div>
      <Flex justifyContent="flex-end">
        <DeleteIcon size={"large"} theme={"gray"} onDelete={handleDelete} />
      </Flex>
      <BlockRow marginTop="0">
        <Label htmlFor={`unitName-${unit.id}`} isMute>
          ユニット名
        </Label>
        <Input
          id={`unitName-${unit.id}`}
          name={`units.${unit.id}.name`}
          onBlur={onBlur}
          onChange={onChange}
          value={unit.name}
          placeholder="名称を入力"
          hasError={touched.name && !!errors.name}
        />
        <ErrorText>{touched.name && errors.name}</ErrorText>
      </BlockRow>
      <BlockRow marginTop="1.6rem">
        <Label htmlFor={`unitDescription-${unit.id}`} isMute>
          ユニット説明文
        </Label>
        <Textarea
          id={`unitDescription-${unit.id}`}
          name={`units.${unit.id}.description`}
          onBlur={onBlur}
          onChange={onChange}
          value={unit.description}
          placeholder="説明を入力"
          rows={1}
          hasError={touched.description && !!errors.description}
        />
        <ErrorText>{touched.description && errors.description}</ErrorText>
      </BlockRow>
      <BlockRow marginTop="1.6rem">
        <Label isMute isInline>
          コンテンツの設定
          <InlineBlock marginLeft="1.6rem">
            <span className={styles.supplement}>
              ※アップロード済みのコンテンツをユニットごとに設定できます
            </span>
          </InlineBlock>
        </Label>
      </BlockRow>
      <Contents
        contents={unit.contents}
        onRemoveContent={onRemoveContent}
        unit={unit}
        contentDraggableIdFor={contentDraggableIdFor}
      />
      <BlockRow marginTop="1.6rem">
        <Button
          size="sm"
          variant="outline"
          isRound
          className={styles.settingContentButton}
          onClick={onOpenContentsSelectModal}
          type="button" // type指定をしないとsubmitと解釈されコース登録/更新がリクエストされてしまうため指定している
        >
          コンテンツの設定
        </Button>
      </BlockRow>
    </div>
  );
};

type ContentsProps = {
  unit: UnitValue;
  contents: { [id: string]: ContentValue };
  onRemoveContent: (unitId: string, content: ContentValue) => void;
  contentDraggableIdFor: Props["contentDraggableIdFor"];
};
const Contents = ({
  contents,
  onRemoveContent,
  unit,
  contentDraggableIdFor,
}: ContentsProps) => {
  return (
    <Droppable
      droppableId={`unit-${unit.id}-contents`}
      type={`unit-${unit.id}-content`}
    >
      {(provided) => (
        <div ref={provided.innerRef}>
          {Object.values(contents).map(
            (content: ContentValue, index: number) => (
              <Draggable
                key={`unit-${unit.id}-content-${content.id}`}
                draggableId={contentDraggableIdFor(unit, content)}
                index={index}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <Flex
                      marginTop="1.6rem"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Flex alignItems="center" marginTop="0">
                        <div
                          className={styles.contentDragIndicator}
                          {...provided.dragHandleProps}
                        >
                          <Icon name="icon-drag-indicator" />
                        </div>
                        {content.contentType === "ContentVideo" ? (
                          <Icon
                            name="icon-video"
                            className={styles.contentIcon}
                          />
                        ) : (
                          <Icon
                            name="icon-file"
                            className={styles.contentIcon}
                          />
                        )}
                        <InlineBlock marginLeft="1.6rem">
                          {content.url ? (
                            <a
                              href={content.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.contentName}
                            >
                              {content.name}
                            </a>
                          ) : (
                            content.name
                          )}
                        </InlineBlock>
                      </Flex>
                      <InlineBlock marginLeft="1.6rem">
                        <button
                          type="button"
                          onClick={() => {
                            onRemoveContent(unit.id, content);
                          }}
                        >
                          <Icon
                            name="icon-close-x"
                            className={styles.closeIcon}
                          />
                        </button>
                      </InlineBlock>
                    </Flex>
                  </div>
                )}
              </Draggable>
            ),
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
