import * as React from "react";
import { useEffect } from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import { connect, HandleThunkActionCreator } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionMessages from "./SectionMessages/index";
import { OperatorPrivilegeInfoInterface } from "../../../interfaces/OperatorInterface";
import { loadOperatorProfile } from "../../../actions/pages/SettingsProfilePage";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import { useLocation } from "react-router";

interface Props extends OutletContextProps {
  section: SectionInterface;
  loadOperatorProfile: HandleThunkActionCreator<typeof loadOperatorProfile>;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  operatorProfile: OperatorProfileInterface;
  currentOperator: OperatorInterface;
}

const SectionsMessageThreadsPage: React.FC<Props> = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.loadOperatorProfile();
    props.setActiveMenu("message");
  }, []);

  if (!props.section) {
    return null;
  }

  return (
    <SectionMessages
      section={props.section}
      location={location}
      operatorPrivilege={props.operatorPrivilege}
      operatorProfile={props.operatorProfile}
      operator={props.currentOperator}
    />
  );
};

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceSectionsPage(SectionsMessageThreadsPage, pageInfo);

const mapStateToProps = (state: AppStateInterface) => {
  return {
    operatorPrivilege: state.operatorPrivileges.data,
    operatorProfile: state.operatorSettings.operatorProfile,
  };
};

const actions = {
  loadOperatorProfile,
};

const ConnectedPage = connect(mapStateToProps, actions)(EnhancedPage);

export default ConnectedPage;
