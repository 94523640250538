import * as React from "react";
import { useThrottle } from "./useThrottle";

/**
 * 当初から存在したScrollWatcherコンポーネントがreact17からのscrollイベントの仕様変更で動作しなくなったため
 * hooks のバージョンにに修正。
 * TODO: 負荷やユーザビリティを考えると、onScrollは好ましくないので、Intersection Observerに乗り換え
 */

export type UseScrollWatcherProps = {
  onReachToTop?: (e: React.UIEvent | Event) => void;
  onReachToBottom?: (e: React.UIEvent | Event) => void;
};

export const useScrollWatcher = ({
  onReachToTop,
  onReachToBottom,
}: UseScrollWatcherProps) => {
  const callback = React.useCallback(
    (e: React.UIEvent | Event) => {
      if (onReachToTop && isReachedToTop(e)) {
        onReachToTop(e);
      }

      if (onReachToBottom && isReachedToBottom(e)) {
        onReachToBottom(e);
      }
    },
    [onReachToBottom, onReachToTop],
  );
  // scroll時にすごい数のイベントが発生するので、間引いて負荷を軽減する
  const { throttledExecute } = useThrottle(callback, 80);
  return throttledExecute;
};

const isReachedToBottom = (e: React.UIEvent | Event): boolean => {
  const element =
    e.target instanceof HTMLElement ? e.target : document.documentElement;
  const scrollTop = element.scrollTop;
  const elementHeight = element.getBoundingClientRect().height;
  const scrollBottom = scrollTop + elementHeight;

  // NOTE: OSとブラウザの組み合わせによってはずれることがあるので100px分のずれを許容する
  const skew = 100;
  return scrollBottom + skew >= element.scrollHeight;
};

const isReachedToTop = (e: React.UIEvent | Event): boolean => {
  const element = e.target as HTMLElement;
  const scrollTop = element.scrollTop;

  return scrollTop === 0;
};
