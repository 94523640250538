import * as React from "react";
import styles from "./styles.scss";
import KarteInterface from "../../../interfaces/KarteInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import KarteItemFooter from "../KarteItemFooter/index";
import KarteItemHeader from "../KarteItemHeader";
import Icon from "../../atoms/Icon/index";
import SquareImageList from "../../atoms/SquareImageList/index";
import KarteFileAttachmentList from "../KarteFileAttachmentList";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
const reactStringReplace = require("react-string-replace");
const nl2br = require("react-nl2br");

interface Props extends React.PropsWithChildren<any> {
  section: SectionInterface;
  karte: KarteInterface;
  canPin?: boolean;
}

const renderCommentIcon = (props: Props) => {
  const { karte } = props;

  if (karte.comments.length === 0) {
    return (
      <span className={styles.commentIcon}>
        <Icon name="icon-action-comment-default" className={styles.icon} />
        コメント
      </span>
    );
  } else {
    return (
      <span className={`${styles.commentIcon} ${styles.active}`}>
        <Icon
          name="icon-action-comment-selected"
          className={`${styles.icon}`}
        />
        {karte.comments.length}
      </span>
    );
  }
};

const KarteItem = (props: Props) => {
  const { karte, canPin } = props;
  const regexp = RegexpHelper.URL;

  return (
    <div className={styles.root} role="listitem" data-testid="karte-listitem">
      <div className={styles.main}>
        <KarteItemHeader karte={karte} canPin={canPin} />
        <p className={styles.comment}>
          {nl2br(karte.comment).flatMap((line: string, i: number) => {
            return reactStringReplace(
              line,
              regexp,
              (match: string, n: number) => {
                return (
                  <a
                    href={match}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`karte-${karte.id}-link-${i}-${n}`}
                  >
                    {match}
                  </a>
                );
              },
            );
          })}
        </p>
        <SquareImageList
          imageUrls={karte.imageAttachments.map((image) => image.presignedUrl)}
          keys={karte.imageAttachments.map(
            (image) => `KarteItem-SquareImageList-${image.id}`,
          )}
        />
        <KarteFileAttachmentList
          fileAttachments={karte.fileAttachments}
          keys={karte.fileAttachments.map(
            (attachment) =>
              `KarteItem-KarteFileAttachmentList-${attachment.id}`,
          )}
        />
        {renderCommentIcon(props)}
      </div>
      <KarteItemFooter karte={karte} studentId={karte.student.id} />
    </div>
  );
};

export default KarteItem;
