import { useInfiniteQuery } from "@tanstack/react-query";
import ApiResponse, { CursorMetaData } from "../../interfaces/ApiResponse";
import { createError, HTTPErrors } from "../../errors";
import StudentGuardianMessageApi from "../../api-clients/StudentGuardianMessageApi";
import GuardianMessageInterface from "../../interfaces/GuardianMessageInterface";

type ResponseData = ApiResponse<GuardianMessageInterface[], CursorMetaData>;

type Props = {
  studentId: string;
};

export const useFetchStudentGuardianMessages = ({ studentId }: Props) => {
  const { data, ...result } = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(studentId),
    queryFn: async ({ pageParam }) => {
      const response = await StudentGuardianMessageApi.getGuardianMessages(
        studentId,
        {
          query: {
            since: pageParam,
          },
        },
      );
      if (response.ok) {
        const json = await response.json();
        return json.guardianMessages;
      }
      throw await createError(response);
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.meta.till,
    refetchOnWindowFocus: false,
    enabled: !!studentId,
  });

  return {
    data: data?.pages.flatMap((page) => page.data),
    meta: data?.pages[0].meta,
    ...result,
  };
};

export const cacheKeyOf = (studentId: string) =>
  [studentId, "guardian_message"].filter((k) => k !== undefined);
