import SubLayout from "../../components/atoms/SubLayout/index";
import StudyPlusTerm from "../../components/atoms/StudyPlusTerm";

const TermPage = () => {
  return (
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Heading>Studyplus for School 利用規約</SubLayout.Heading>
      <SubLayout.Main>
        <StudyPlusTerm />
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  );
};

export default TermPage;
