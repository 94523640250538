import * as React from "react";
import { connect } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { persistor } from "../../../store";

interface Props {
  shouldReload: boolean;
}

class BaseComponent extends React.Component<Props, unknown> {
  componentDidUpdate() {
    if (this.props.shouldReload) {
      // persistor.flushを呼んで待つことでリロード前にlocalStorageへの反映を保証する
      persistor.flush().then(() => {
        window.location.reload();
      });
    }
  }

  render() {
    return <React.Fragment />;
  }
}

const mapStateToProps = (state: AppStateInterface) => ({
  shouldReload: state.shouldReload,
});

const ConnectedComponent = connect(mapStateToProps, {})(BaseComponent);

const WindowReload = () => <ConnectedComponent />;

export default WindowReload;
