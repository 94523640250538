import * as React from "react";
import styles from "./styles.scss";
import StaffBoardPostInterface, {
  StaffBoardPostCommentFormInterface,
} from "../../../interfaces/StaffBoardPostInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import Input from "../../atoms/Input/index";
import { Button } from "@studyplus/boron-ui";
import Textarea from "../../atoms/Textarea";
import { HandleThunkActionCreator } from "react-redux";
import { FormikProps, Form, Field, FormikBag, withFormik } from "formik";
import { validationSchema } from "./validationSchema";
import isEqual from "lodash-es/isEqual";

interface ComponentProps {
  sectionId: string;
  currentOperator: OperatorInterface;
  staffBoardPost: StaffBoardPostInterface;
  submitting: boolean;
  postComment: HandleThunkActionCreator<(values: Values) => void>;
}
type Values = StaffBoardPostCommentFormInterface;
type Props = ComponentProps & FormikProps<Values>;

class StaffBoardPostCommentForm extends React.Component<Props, unknown> {
  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.staffBoardPost, prevProps.staffBoardPost)) {
      this.props.resetForm();
    }
  }

  render() {
    return (
      <Form className={styles.root}>
        <Field
          name="name"
          className={styles.name}
          component={Input}
          hasError={this.hasError("name")}
        />
        <Field
          placeholder="コメントを入力"
          name="comment"
          rows={1}
          component={Textarea}
          hasError={this.hasError("comment")}
        />
        <Button
          disabled={!this.isSubmittable()}
          type="submit"
          className={styles.button}
        >
          送信
        </Button>
      </Form>
    );
  }

  private isSubmittable() {
    return !this.props.submitting && this.props.isValid;
  }

  private hasError(name: string) {
    const errors = (this.props.errors as any)[name];
    const touched = (this.props.touched as any)[name];
    return errors && touched;
  }
}
const mapPropsToValues = (props: ComponentProps): Values => ({
  name: props.currentOperator.fullName,
  comment: "",
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  formikBag.props.postComment(values);
};

export default withFormik({ mapPropsToValues, handleSubmit, validationSchema })(
  StaffBoardPostCommentForm,
);
