import { AnnounceMessage } from "../../../interfaces/MessageInterface";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";

export const POST_ZOOM_MEETING_MESSAGE_REQUEST =
  "sections/announceMessages/POST_ZOOM_MEETING_MESSAGE_REQUEST" as const;
export const POST_ZOOM_MEETING_MESSAGE_SUCCESS =
  "sections/announceMessages/POST_ZOOM_MEETING_MESSAGE_SUCCESS" as const;
export const POST_ZOOM_MEETING_MESSAGE_ERROR =
  "sections/announceMessages/POST_ZOOM_MEETING_MESSAGE_ERROR" as const;

export type ActionTypes =
  | typeof POST_ZOOM_MEETING_MESSAGE_REQUEST
  | typeof POST_ZOOM_MEETING_MESSAGE_SUCCESS
  | typeof POST_ZOOM_MEETING_MESSAGE_ERROR;

export interface PostMessageSuccessPayload {
  message: AnnounceMessage;
  tagId: string;
}

export type Actions =
  | PostZoomMeetingMessageRequest
  | PostZoomMeetingMessageSuccess
  | PostZoomMeetingMessageError;

interface PostZoomMeetingMessageRequest {
  type: typeof POST_ZOOM_MEETING_MESSAGE_REQUEST;
}
interface PostZoomMeetingMessageSuccess {
  type: typeof POST_ZOOM_MEETING_MESSAGE_SUCCESS;
  payload: PostMessageSuccessPayload;
}
interface PostZoomMeetingMessageError {
  type: typeof POST_ZOOM_MEETING_MESSAGE_ERROR;
  payload?: ApiErrorResponseInterface;
}
