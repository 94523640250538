import { connect, HandleThunkActionCreator } from "react-redux";
import {
  followStudentMessage,
  unfollowStudentMessage,
} from "../../../actions/pages/studentMessages";
import { StudentMessagesStateInterface } from "../../../interfaces/MessageInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import SplitLayout from "../../atoms/SplitLayout";
import MessageHeader from "../../molecules/MessageHeader";
import MessageList from "../../molecules/MessageList";
import StudentMessageForm from "../../molecules/StudentMessageForm";
import styles from "./styles.scss";
import {
  loadMoreStudentMessages,
  changeBotMessageStatusDone,
  changeBotMessageStatusIgnored,
  postZoomMeetingMessage,
  deleteMessage,
} from "../../../actions/pages/studentMessages/index";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";

interface Props {
  student: StudentInterface | null;
  isFollowing: boolean;
  messagesState: StudentMessagesStateInterface;
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
  onCloseRightColumn?: (e: any) => void;
  followStudentMessage: (studentId: string) => void;
  unfollowStudentMessage: (studentId: string) => void;
  loadMoreStudentMessages: HandleThunkActionCreator<
    typeof loadMoreStudentMessages
  >;
  changeBotMessageStatusDone: HandleThunkActionCreator<
    typeof changeBotMessageStatusDone
  >;
  changeBotMessageStatusIgnored: HandleThunkActionCreator<
    typeof changeBotMessageStatusIgnored
  >;
  postZoomMeetingMessage: HandleThunkActionCreator<
    typeof postZoomMeetingMessage
  >;
  deleteMessage: HandleThunkActionCreator<typeof deleteMessage>;
}

const StudentMessage = (props: Props) => {
  if (!props.student) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SplitLayout.Header>
        <MessageHeader
          student={props.student}
          isFollowing={props.isFollowing}
          onClose={props.onCloseRightColumn}
          onFollow={handleFollow(props)}
          onUnfollow={handleUnfollow(props)}
          showCloseIcon={false}
        />
      </SplitLayout.Header>
      <SplitLayout.Body>
        {props.messagesState ? (
          <MessageList
            student={props.student}
            messages={props.messagesState.data}
            isLoading={props.messagesState.loading}
            loadMore={handleLoadMore(props)}
            changeBotMessageStatusDone={props.changeBotMessageStatusDone}
            changeBotMessageStatusIgnored={props.changeBotMessageStatusIgnored}
            deleteMessage={props.deleteMessage}
          />
        ) : null}
        <StudentMessageForm
          apiErrors={props.messagesState ? props.messagesState.apiErrors : []}
          submitting={
            props.messagesState ? props.messagesState.submitting : false
          }
          student={props.student}
          operator={props.operator}
          operatorProfile={props.operatorProfile}
          destination={{
            path: `/students/${props.student.id}/`,
            name: props.student.fullName,
          }}
        />
      </SplitLayout.Body>
    </div>
  );
};

const handleFollow = (props: Props) => () => {
  if (!props.student) {
    return;
  }

  if (window.confirm("通知をオンにしますか？")) {
    props.followStudentMessage(props.student.id);
  }
};

const handleUnfollow = (props: Props) => () => {
  if (!props.student) {
    return;
  }

  if (window.confirm("通知を解除しますか？")) {
    props.unfollowStudentMessage(props.student.id);
  }
};

const handleLoadMore = (props: Props) => (studentId: string) => {
  const cursor = props.messagesState.meta.till;
  props.loadMoreStudentMessages(studentId, cursor);
};

const actions = {
  followStudentMessage,
  unfollowStudentMessage,
  loadMoreStudentMessages,
  changeBotMessageStatusDone,
  changeBotMessageStatusIgnored,
  postZoomMeetingMessage,
};

export default connect(null, actions)(StudentMessage);
