import * as React from "react";

type Props = React.PropsWithChildren<{
  to: string;
}>;
export const ExternalLink = ({ children, to }: Props) => (
  <a href={to} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
