import { useCallback } from "react";

type Return<V> = {
  get: () => V | null;
  set: (content: V) => void;
  remove: () => void;
};

export function useLocalStorage<V>(storageKey: string): Return<V> {
  const get = useCallback((): V | null => {
    const str = localStorage.getItem(storageKey);
    if (str) {
      const obj: V = JSON.parse(str);
      return obj;
    } else {
      return null;
    }
  }, [storageKey]);

  const set = useCallback(
    (content: V) => {
      localStorage.setItem(storageKey, JSON.stringify(content));
    },
    [storageKey],
  );

  const remove = useCallback(() => {
    localStorage.removeItem(storageKey);
  }, [storageKey]);

  return { get, set, remove };
}
