import { useQuery } from "@tanstack/react-query";
import SectionInterface from "../../../interfaces/SectionInterface";
import ApiClient from "../../../api";
import SectionLearningMaterialInterface from "../../../interfaces/SectionLearningMaterialInterface";
import { createError, HTTPErrors } from "../../../errors";

type Props = {
  section: SectionInterface;
  learningMaterialId?: string;
};
type ResponseBody = {
  learningMaterial: SectionLearningMaterialInterface;
};
type CacheKeyOfParams = {
  sectionId: string;
  learningMaterialId: string;
};
export const sectionLearningMaterialQueryKey = ({
  sectionId,
  learningMaterialId,
}: CacheKeyOfParams) => [baseUrl(sectionId, learningMaterialId)];

export const useFetchSectionLearningMaterial = (props: Props) => {
  const sectionId = props.section.id;
  const learningMaterialId = props.learningMaterialId ?? "";
  const path = baseUrl(sectionId, learningMaterialId);
  return useQuery<ResponseBody["learningMaterial"], HTTPErrors>({
    queryKey: sectionLearningMaterialQueryKey({
      sectionId,
      learningMaterialId,
    }),
    queryFn: async () => {
      const res = await ApiClient.get(path);
      if (res.ok) {
        const json = (await res.json()) as ResponseBody;
        return json.learningMaterial;
      }

      throw await createError(res);
    },
    enabled: !!props.learningMaterialId,
  });
};

const baseUrl = (sectionId: string, learningMaterialId: string) =>
  `/api/v1/sections/${sectionId}/learning_materials/${learningMaterialId}`;
