import * as React from "react";
import { BlockRow } from "../../../components/atoms/BlockRow";
import BackLink from "../../../components/atoms/BackLink";
import { MainSection, PageHeader } from "../../../components/atoms/MainSection";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { CourseShareForm } from "./CourseShareForm";
import { OutletContextProps } from "../../../helpers/RouterHelper";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
  };

const SectionsSettingsContentCoursesSharePage_: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  return (
    <>
      <BlockRow marginTop="2rem">
        <BackLink
          path={`/sections/${props.section.id}/settings/content_courses`}
        >
          戻る
        </BackLink>
      </BlockRow>
      <BlockRow marginTop="2.4rem">
        <MainSection>
          <PageHeader helpUrl="https://fs-help.studyplus.co.jp/ja/articles/4964875">
            他教室のコース登録
          </PageHeader>
          <CourseShareForm sectionId={props.section.id} />
        </MainSection>
      </BlockRow>
    </>
  );
};
const pageInfo = {
  title: "他教室のコース登録",
};

export const SectionsSettingsContentCoursesSharePage =
  enhanceSectionsSettingsPage(
    SectionsSettingsContentCoursesSharePage_,
    pageInfo,
    ["content_courses"],
  );
