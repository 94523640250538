import * as React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";

interface Props extends React.PropsWithChildren<unknown> {
  href?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

const Item = (props: Props) => {
  if (props.href) {
    return (
      <li className={styles.item}>
        <Link to={props.href} className={styles.item__link}>
          {props.children}
        </Link>
      </li>
    );
  } else {
    return (
      <li className={`${styles.item}`}>
        <a href="" className={styles.item__link} onClick={handleClick(props)}>
          {props.children}
        </a>
      </li>
    );
  }
};

const handleClick = (props: Props) => (e: React.SyntheticEvent) => {
  e.preventDefault();

  if (props.onClick) {
    props.onClick(e);
  }
};

export default Item;
