import * as React from "react";
import TabsNavi from "../../components/molecules/TabsNavi";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import { LearningMaterialTable } from "./LearningMaterialTable";
import { useFetchDigitalLearningMaterials } from "./useDigitalLearningMaterialApi";
import { SectionsSettingsPageProps } from "../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import Loader from "../../components/atoms/Loader";
import { BlockRow } from "../../components/atoms/BlockRow";
import {
  LearningMaterialTagAssociation,
  LearningMaterialTagModal,
  useOpenLearningMaterialTagModal,
} from "../../components/features/LearningMaterialTagModal";
import { LearningMaterialTagWithDeletable } from "../../domains/LearningMaterialTag";
import {
  useFetchLearningMaterialTags,
  useAssociateLearningMaterialTag,
} from "../../hooks/http/useLearningMaterialTagApi";
import { useQueryError } from "../../hooks/http/useQueryError";
import { OutletContextProps } from "../../helpers/RouterHelper";
import { OperatorPrivilege } from "../../interfaces/OperatorInterface";
import { Link } from "react-router-dom";

// Main hooks
export type UseSectionsSettingsDigitalLearningMaterialsProps = {
  section: SectionInterface;
  privileges?: OperatorPrivilege[];
};

export const useSectionsSettingsDigitalLearningMaterials = ({
  section,
  privileges,
}: UseSectionsSettingsDigitalLearningMaterialsProps) => {
  const {
    data,
    totalCount,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
    error,
  } = useFetchDigitalLearningMaterials({
    section,
  });
  useQueryError(error);

  const { isOpenModal, openModal, closeModal, currentLearningMaterial } =
    useOpenLearningMaterialTagModal();
  const { mutate, isLoading: isUpdatingAssociatedTags } =
    useAssociateLearningMaterialTag({
      sectionId: section.id,
      onSuccess: () => {
        closeModal();
        refetch();
      },
    });

  const onSubmit = (
    learningMaterial: LearningMaterialTagAssociation,
    tags: ReadonlyArray<LearningMaterialTagWithDeletable>,
  ) => {
    mutate({
      learningMaterialCode: learningMaterial.learningMaterialCode,
      learningMaterialTags: tags.filter((tag) => tag.isDeletable),
    });
  };

  const {
    data: learningMaterialTags,
    isLoading: isFetchingLearningMaterialTags,
  } = useFetchLearningMaterialTags({
    section,
    narrowingCondition: "all",
    shouldGetAllRecords: true,
  });
  const modalProps = {
    sectionId: section.id,
    selectableTags: learningMaterialTags ?? [],
    currentLearningMaterial,
    isOpenModal,
    closeModal,
    onSubmit,
    isSubmitting: isUpdatingAssociatedTags,
  };

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  const hasPrivilege =
    !!privileges && privileges.includes("management_learning_materials");
  const digitalLearningMaterialTableProps = {
    sectionId: section.id,
    openModal,
    digitalLearningMaterials: data ?? [],
    shouldShowTagUpdateIcon: hasPrivilege,
  };
  const loaderProps = {
    loading: isLoading || isFetchingNextPage || isFetchingLearningMaterialTags,
  };
  const resultTextProps = {
    hidden: isLoading || !data,
  };

  const digitalLearningMaterialTab = {
    label: "デジタル教材",
    link: `/sections/${section.id}/settings/digital_learning_materials`,
    active: true,
  };
  const allTabs = [
    {
      label: "校舎教材一覧",
      link: `/sections/${section.id}/settings/management_learning_materials`,
      active: false,
    },
    digitalLearningMaterialTab,
    {
      label: "校舎独自教材",
      link: `/sections/${section.id}/settings/section_learning_materials`,
      active: false,
    },
    {
      label: "教材タグ",
      link: `/sections/${section.id}/settings/learning_material_tags`,
      active: false,
    },
  ];
  const tabs = hasPrivilege ? allTabs : [digitalLearningMaterialTab];
  const tabsNaviProps = { tabs };
  return {
    digitalLearningMaterialTableProps,
    loaderProps,
    modalProps,
    totalCount,
    resultTextProps,
    tabsNaviProps,
  };
};

type Props = SectionsSettingsPageProps &
  UseSectionsSettingsDigitalLearningMaterialsProps &
  OutletContextProps;

const SectionsSettingsDigitalLearningMaterials = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("learning_material");
  }, []);
  const section = props.section;
  const privileges = props.operatorPrivileges.data?.privileges;
  const {
    digitalLearningMaterialTableProps,
    loaderProps,
    modalProps,
    totalCount,
    resultTextProps,
    tabsNaviProps,
  } = useSectionsSettingsDigitalLearningMaterials({ section, privileges });
  return (
    <div>
      <TabsNavi tabs={tabsNaviProps.tabs} />
      <BlockRow marginTop="1rem">
        <div {...resultTextProps}>
          <div className="flex flex-wrap items-end justify-between gap-x-9 gap-y-3 sm:flex-nowrap">
            <span className="whitespace-nowrap font-bold">
              教材：{totalCount}件
            </span>
            <Link
              to={`/sections/${section.id}/settings/digital_learning_materials/new`}
              className="buiButtonBase buiButtonVariantFilled"
            >
              デジタル教材を登録
            </Link>
          </div>
        </div>
      </BlockRow>
      <BlockRow marginTop="1.6rem">
        <LearningMaterialTable {...digitalLearningMaterialTableProps} />
        <LearningMaterialTagModal {...modalProps} />
        <Loader {...loaderProps} />
      </BlockRow>
    </div>
  );
};

const pageInfo = {
  title: "教材管理",
};

export const SectionsSettingsDigitalLearningMaterialsPage =
  enhanceSectionsSettingsPage(
    SectionsSettingsDigitalLearningMaterials,
    pageInfo,
  );
