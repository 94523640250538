import { RefObject, useEffect, useRef } from "react";

export const useOnClickOutside = <T extends Element>(
  handler?: (event: MouseEvent) => void,
): { insideRef: RefObject<T> } => {
  const insideRef = useRef<T>(null);

  useEffect(() => {
    if (handler == null) return;

    const listener = (event: MouseEvent) => {
      if (!insideRef.current?.contains(event.target as Node)) {
        handler?.(event);
      }
    };

    document.addEventListener("click", listener, true);

    return () => {
      document.removeEventListener("click", listener, true);
    };
  }, [handler]);

  return { insideRef };
};
