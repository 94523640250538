import * as React from "react";
import styles from "./styles.scss";

type Props = {
  marginTop?: string;
} & React.HTMLProps<HTMLDivElement>;

export const BlockRow = ({
  children,
  marginTop = "0",
  ...restProps
}: React.PropsWithChildren<Props>) => {
  return (
    <div className={styles.root} style={{ marginTop }} {...restProps}>
      {children}
    </div>
  );
};
