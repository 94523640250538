import { useParams } from "react-router-dom";

type Params = { action: string; courseId?: string };
type NewParams = { action: "new"; courseId: null };
type EditParams = { action: "edit"; courseId: string };

type ValidatedParams = NewParams | EditParams;
export const useValidatedParams = (action: string) => {
  const { courseId } = useParams<Params>();
  const validatedParams: ValidatedParams | null =
    action === "edit" && typeof courseId === "string"
      ? { action, courseId }
      : action === "new"
        ? { action: "new", courseId: null }
        : null;

  // Routesで不正なURLを制限しているのでここに来ることはありえない
  if (!validatedParams) {
    throw "URLが不正です";
  }

  return validatedParams;
};
