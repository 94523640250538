import { useCallback, useMemo, useReducer } from "react";
import { LectureAttendanceForSection } from "../../../domains/LectureAttendance";
import { createContext } from "../../../helpers/React";

export type ModalTypes = "forAbsence" | "forAttendance" | "forStaffShareNote";
type ActionTypes =
  | {
      type: "OpenAbsenceModalAsBatch";
    }
  | {
      type: "OpenAbsenceModal";
      target: LectureAttendanceForSection;
    }
  | {
      type: "OpenAttendanceModalAsBatch";
    }
  | {
      type: "OpenAttendanceModal";
      target: LectureAttendanceForSection;
    }
  | {
      type: "OpenStaffShareNoteModal";
      target: LectureAttendanceForSection;
    }
  | {
      type: "Close";
    };

type State = {
  isOpen: boolean;
  updateType: ModalTypes;
  targetLectureAttendance: LectureAttendanceForSection | null;
};

const reducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case "Close":
      return { ...state, isOpen: false, targetLectureAttendance: null };
    case "OpenAbsenceModalAsBatch":
      return { ...state, isOpen: true, updateType: "forAbsence" };
    case "OpenAbsenceModal":
      return {
        isOpen: true,
        updateType: "forAbsence",
        targetLectureAttendance: action.target,
      };
    case "OpenAttendanceModalAsBatch":
      return { ...state, isOpen: true, updateType: "forAttendance" };
    case "OpenAttendanceModal":
      return {
        isOpen: true,
        updateType: "forAttendance",
        targetLectureAttendance: action.target,
      };
    case "OpenStaffShareNoteModal":
      return {
        isOpen: true,
        updateType: "forStaffShareNote",
        targetLectureAttendance: action.target,
      };
    default:
      return state;
  }
};

const initialStateValue: State = {
  isOpen: false,
  updateType: "forAttendance",
  targetLectureAttendance: null,
};

export const useUpdateModalAction = (
  initialState: State = initialStateValue,
) => {
  const [modalState, dispatch] = useReducer(reducer, initialState);

  const openBatchActionAbsentModal = useCallback(
    () => dispatch({ type: "OpenAbsenceModalAsBatch" }),
    [],
  );

  const openAbsentModal = useCallback(
    (lectureAttendance: LectureAttendanceForSection) =>
      dispatch({ type: "OpenAbsenceModal", target: lectureAttendance }),
    [],
  );
  const openBatchActionAttendModal = useCallback(
    () => dispatch({ type: "OpenAttendanceModalAsBatch" }),
    [],
  );

  const openAttendModal = useCallback(
    (lectureAttendance: LectureAttendanceForSection) =>
      dispatch({ type: "OpenAttendanceModal", target: lectureAttendance }),
    [],
  );

  const openStaffShareNoteModal = useCallback(
    (lectureAttendance: LectureAttendanceForSection) =>
      dispatch({ type: "OpenStaffShareNoteModal", target: lectureAttendance }),
    [],
  );

  const closeModal = useCallback(() => dispatch({ type: "Close" }), []);

  const dispatchers = useMemo(
    () => ({
      openBatchActionAbsentModal,
      openBatchActionAttendModal,
      openAbsentModal,
      openAttendModal,
      openStaffShareNoteModal,
      closeModal,
    }),
    [],
  );

  return {
    state: modalState,
    dispatchers,
  };
};

export type ReturnUpdateModalActionHooks = ReturnType<
  typeof useUpdateModalAction
>;
export type ModalDispatchers = ReturnUpdateModalActionHooks["dispatchers"];
export type ModalState = ReturnUpdateModalActionHooks["state"];

export const {
  Provider: ModalDispatchProvider,
  useContext: useModalDispatchContext,
} = createContext<ModalDispatchers>();
export const {
  Provider: ModalStateProvider,
  useContext: useModalStateContext,
} = createContext<ModalState>();
