import * as React from "react";
import styles from "./styles.scss";
import StudyRecordInterface from "../../../../../interfaces/StudyRecordInterface";
import StudentInterface from "../../../../../interfaces/StudentInterface";
import TimelineLikeButton from "./TimelineLikeButton";
import { TimelineComments } from "./TimelineComments";
import { useTimelineComments } from "./useTimelineComments";
import { TimelineCommentButton } from "./TimelineCommentButton";
import { TimelineInsertTemplateCommentButton } from "./TimelineInsertTemplateCommentButton";
import { useFetchSectionsTimelineSummary } from "../../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";

interface Props {
  studyRecord: StudyRecordInterface;
  student: StudentInterface;
  canSendComment: boolean;
  updateStudyRecordQueryCache?: ReturnType<
    typeof useFetchSectionsTimelineSummary
  >["updateStudyRecordQueryCache"];
  isLastItem: boolean;
}

const TimelineItemFooter = ({
  studyRecord,
  student,
  canSendComment,
  updateStudyRecordQueryCache,
  isLastItem,
}: Props) => {
  const {
    comments,
    isVisible,
    onAddComment,
    onDeleteComment,
    toggleVisibility,
    setIsVisible,
  } = useTimelineComments({ studyRecord, updateStudyRecordQueryCache });
  const [comment, setComment] = React.useState("");

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <TimelineCommentButton
          restrictedAttributes={studyRecord.restrictedAttributes}
          totalComments={comments.length}
          toggleVisibility={toggleVisibility}
        />
        <TimelineLikeButton
          studyRecord={studyRecord}
          student={student}
          canSendComment={canSendComment}
          updateStudyRecordQueryCache={updateStudyRecordQueryCache}
        />
        {canSendComment && (
          <TimelineInsertTemplateCommentButton
            studyRecord={studyRecord}
            student={student}
            comment={comment}
            setComment={setComment}
            setIsVisible={setIsVisible}
          />
        )}
      </div>
      <TimelineComments
        studyRecord={studyRecord}
        student={student}
        canSendComment={canSendComment}
        isVisible={isVisible}
        comments={comments}
        onAddComment={onAddComment}
        onDeleteComment={onDeleteComment}
        comment={comment}
        setComment={setComment}
        requireScrollIntoView={isLastItem}
      />
    </div>
  );
};

export default TimelineItemFooter;
