import * as React from "react";
import styles from "./ScreenReaderOnly.scss";
export const ScreenReaderOnly = ({
  children,
  ...rest
}: React.PropsWithChildren<React.ComponentProps<"span">>) => {
  return (
    <span className={styles.root} {...rest}>
      {children}
    </span>
  );
};
