import * as React from "react";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import {
  HorizontalScrollMain,
  PageHeader,
} from "../../components/atoms/MainSection";
import { LectureForm } from "./form/LectureForm";
import { useSectionContext } from "../../contexts/section";
import { useFetchLectureDetail } from "../../hooks/http/useFetchLectureDetail";
import { useQueryError } from "../../hooks/http/useQueryError";
import {
  Breadcrumbs,
  BreadcrumbItem,
} from "../../components/general/Breadcrumbs";
import { useLocation, useParams, Link } from "react-router-dom";
import { CopyableFormMode } from "../../helpers/viewMode";
import styles from "./SectionsSettingsLecturesFormPage.scss";
import { OutletContextProps } from "../../helpers/RouterHelper";

const useSectionsSettingsLecturesFormPage = () => {
  const section = useSectionContext();
  const urlAction = useUrlAction();
  const { lectureId } = useParams<{ lectureId?: string }>();

  const { data, error } = useFetchLectureDetail({
    section,
    lectureId: urlAction !== "new" ? (lectureId ?? "") : "",
    skip: urlAction === "new",
  });
  useQueryError(error);

  const breadCrumbItems = React.useMemo<BreadcrumbItem[]>(
    () => [
      { label: "講座管理", url: `/sections/${section.id}/settings/lectures` },
      { label: `講座の${determineFeatureLabel(urlAction)}` },
    ],
    [urlAction, section],
  );

  return {
    isReadyShowForm: Boolean(urlAction === "new" || data),
    lecture: data ?? null,
    section,
    urlAction,
    breadCrumbItems,
  };
};

const SectionsSettingsLecturesFormPage_ = (props: OutletContextProps) => {
  React.useEffect(() => {
    props.setActiveMenu("lectures");
  }, []);
  const { section, lecture, isReadyShowForm, breadCrumbItems, urlAction } =
    useSectionsSettingsLecturesFormPage();

  return (
    <>
      <div className={styles.breadcrumbsWrapper}>
        <Breadcrumbs items={breadCrumbItems}>
          {(item, isLast) =>
            isLast || !item.url ? (
              <span className={styles.breadcrumbLastLabel}>{item.label}</span>
            ) : (
              <span className={styles.breadcrumbLinkLabel}>
                <Link to={item.url}>{item.label}</Link>
              </span>
            )
          }
        </Breadcrumbs>
      </div>
      <div className={styles.main}>
        <HorizontalScrollMain>
          <div className={styles.mainContentWrapper}>
            <PageHeader helpUrl="https://fs-help.studyplus.co.jp/ja/articles/6064511">
              講座の{determineFeatureLabel(urlAction)}
            </PageHeader>
            {isReadyShowForm && (
              <LectureForm
                section={section}
                lecture={lecture}
                mode={urlAction}
              />
            )}
          </div>
        </HorizontalScrollMain>
      </div>
    </>
  );
};

const determineFeatureLabel = (mode: CopyableFormMode) => {
  if (mode === "copy") {
    return "複製";
  }
  if (mode === "edit") {
    return "編集";
  }
  return "登録";
};

const useUrlAction = (): CopyableFormMode => {
  const result = useLocation();
  if (result.pathname.match(/copy$/)) {
    return "copy";
  }
  if (result.pathname.match(/edit$/)) {
    return "edit";
  }
  return "new";
};

const pageInfo = {
  title: "講座登録/編集",
};
export const SectionsSettingsLecturesFormPage = enhanceSectionsSettingsPage(
  SectionsSettingsLecturesFormPage_,
  pageInfo,
  ["contents"], // TODO: 本当の権限に書き換え
);
