import * as React from "react";
import { createComponent } from "../../../helpers/React";
import styles from "./FormParts.scss";
import { SingleDatePickerField } from "../../../components/atoms/SingleDatePickerField";
import classNames from "classnames";
import Label from "../../atoms/Label";
import { Text } from "../../../components/general/Text";

export const StackForm = createComponent({
  tag: "form",
  className: styles.stackForm,
});

export const StackItem = createComponent({
  tag: "div",
  className: styles.stackItem,
});

export const ScheduleTimeFieldLayout = createComponent({
  tag: "div",
  className: styles.scheduleTimeFieldLayout,
});

/**
 * 予定登録における以下の様な共通レイアウトのコンテナ
 * ----------------------
 * | Icon|  FormField   |
 * ----------------------
 */
export const FieldLayoutOneRow = createComponent({
  tag: "div",
  className: styles.fieldLayoutOneRow,
});

/**
 * 予定登録における以下のような複数行にまたがる共通レイアウトのコンテナ
 * ----------------------
 * | Icon|  Something   |
 * ----------------------
 * |     |  Something   |
 * ----------------------
 * |     |  Something   |
 * ----------------------
 */
export const FieldLayoutMultiRows = createComponent({
  tag: "div",
  className: styles.fieldLayoutMultiRows,
});

// 共通サブミットボタンエリア
export const FormFooter = createComponent({
  tag: "div",
  className: styles.formFooter,
});

// 教材設定後のhours、amountを入力するフィールドのレイアウト
export const StudyPlanFieldStack = createComponent({
  tag: "div",
  className: styles.studyPlanFieldStack,
});

// 教材設定の時間指定のフィールドレイアウト
export const StudyPlanDurationInput = createComponent({
  tag: "div",
  className: styles.studyPlanDurationInput,
});

// 学習計画の単位のテキストのラッパー
export const StudyPlanUnitTextWrapper = createComponent({
  tag: "div",
  className: styles.studyPlanUnitTextWrapper,
});

// 学習計画の数量のボックス
export const StudyPlanAmountInput = createComponent({
  tag: "div",
  className: styles.studyPlanAmountInput,
});

type ScheduleFormLabelProps = Pick<React.ComponentProps<typeof Text>, "size"> &
  Pick<React.ComponentProps<typeof Label>, "htmlFor">;
export const ScheduleFormLabel = ({
  htmlFor,
  children,
  size,
}: React.PropsWithChildren<ScheduleFormLabelProps>) => {
  return (
    // デフォルトの隙間を打ち消す
    <Label htmlFor={htmlFor} noMargin>
      <Text size={size} color="gray-darken-2" bold>
        {children}
      </Text>
    </Label>
  );
};

export const ScheduleTimeHStack = createComponent({
  tag: "div",
  className: styles.scheduleTimeHStack,
});

type ScheduleDatePickerProps = React.ComponentProps<
  typeof SingleDatePickerField
>;
export const ScheduleDatePicker = ({
  className,
  ...props
}: ScheduleDatePickerProps) => {
  return (
    <SingleDatePickerField
      className={classNames(styles.scheduleDatePicker, className)}
      {...props}
    />
  );
};
