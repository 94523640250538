import * as React from "react";
import { createContext } from "../../../helpers/React";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const { useContext, Provider } =
  createContext<ReturnType<typeof useFieldVisibilityToggle>>();

export const useFieldVisibilityToggleContext = useContext;

// 新規の画面で利用する際は、以下に識別子を追加してください
export type FieldVisibilityToggleId =
  | ""
  | "sectionAnalyticsStudyRecord"
  | "sectionAnalyticsExaminationDetail";

const useFieldVisibilityToggle = () => {
  // 画面ごとに状態を分けるために、FieldVisibilityToggleIdをキーにフィールド名を保持するstate
  const [allInvisibleFieldNames, setAllInvisibleFieldNames] = React.useState<
    Record<FieldVisibilityToggleId, string[]>
  >({} as Record<FieldVisibilityToggleId, string[]>);

  const localStorage = useLocalStorage<
    Record<FieldVisibilityToggleId, string[]>
  >("FieldVisibilityToggle");
  // LocalStorageからの復元
  React.useEffect(() => {
    const allInvisibleFieldNamesFromStorage = localStorage.get();
    if (allInvisibleFieldNamesFromStorage) {
      setAllInvisibleFieldNames(allInvisibleFieldNamesFromStorage);
    }
  }, []);

  // LocalStorageへの保存
  React.useEffect(() => {
    if (localStorage) {
      try {
        localStorage.set(allInvisibleFieldNames);
      } catch (e) {
        // NOTE: 以下のエラーが発生して真っ白な画面になってしまう時があるため対処
        // DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'xxx' exceeded the quota.
      }
    }
  }, [allInvisibleFieldNames]);

  const [currentFieldVisibilityToggleId, setCurrentFieldVisibilityToggleId] =
    React.useState<FieldVisibilityToggleId>("");
  const [currentInvisibleFieldNames, setCurrentInvisibleFieldNames] =
    React.useState<string[]>([]);

  React.useEffect(() => {
    setCurrentInvisibleFieldNames(
      allInvisibleFieldNames[currentFieldVisibilityToggleId] ?? [],
    );
  }, [allInvisibleFieldNames, currentFieldVisibilityToggleId]);

  const visualizeField = (fieldName: string) => {
    setAllInvisibleFieldNames({
      ...allInvisibleFieldNames,
      ...{
        [currentFieldVisibilityToggleId]: currentInvisibleFieldNames.filter(
          (name) => name !== fieldName,
        ),
      },
    });
  };

  const unvisualizeField = (fieldName: string) => {
    setAllInvisibleFieldNames({
      ...allInvisibleFieldNames,
      ...{
        [currentFieldVisibilityToggleId]: [
          ...currentInvisibleFieldNames,
          fieldName,
        ],
      },
    });
  };

  const toggleFieldVisibility = (fieldName: string) => {
    if (isInvisible(fieldName)) {
      visualizeField(fieldName);
    } else {
      unvisualizeField(fieldName);
    }
  };

  const isInvisible = (fieldName: string) => {
    return currentInvisibleFieldNames.includes(fieldName);
  };

  return {
    currentInvisibleFieldNames,
    setCurrentFieldVisibilityToggleId,
    toggleFieldVisibility,
    isInvisible,
  };
};

export const FieldVisibilityToggleProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <Provider value={useFieldVisibilityToggle()}>{children}</Provider>;
};
