import * as React from "react";
import { withFormik, FormikProps, FormikBag } from "formik";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import StudentInterface from "../../../interfaces/StudentInterface";
import {
  postStudentMessage,
  postStudentMessageFile,
  postZoomMeetingMessage,
} from "../../../actions/pages/studentMessages/index";
import CommonMessageForm from "../CommonMessageForm";
import { MessageDestinationInterface } from "../CommonMessageForm/index";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import { PostZoomMeetingMessageParams } from "../../../interfaces/PostZoomMeetingMessageParams";
import { generateStorageKey } from "../../atoms/ResumableTextarea/useResumableTextarea";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentMessagesStateInterface } from "../../../interfaces/MessageInterface";

interface ComponentProps {
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  student: StudentInterface;
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
  destination: MessageDestinationInterface;
  postMessage: (studentId: string, content: string) => void;
  postMessageFile: (studentId: string, file: File) => void;
  postZoomMeetingMessage: HandleThunkActionCreator<
    typeof postZoomMeetingMessage
  >;
}

interface Values {
  content: string;
  file: File | null;
}

type StudentMessageState = {
  studentMessageState: StudentMessagesStateInterface;
};

type Props = ComponentProps & FormikProps<Values> & StudentMessageState;

class InnerForm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.submitting && !this.props.submitting) {
      if (this.props.apiErrors.length === 0) {
        this.props.resetForm();
      }
      this.props.setSubmitting(false);
    }
  }

  render() {
    const { props } = this;

    return (
      <CommonMessageForm
        formState={{
          values: props.values,
          apiErrors: props.apiErrors,
          submitting: props.studentMessageState.submitting,
        }}
        canSendMessage={true}
        postMessage={this.handlePostMessage}
        postMessageFile={this.handlePostMessageFile}
        changeFormContent={this.handleChangeMessage}
        changeFormFile={this.handleChangeMessageFile}
        allowedFileTypes={["jpg", "png", "gif", "pdf"]}
        disabledPlaceholder={
          "連携していない生徒のため、メッセージが送信できません"
        }
        operator={props.operator}
        operatorProfile={props.operatorProfile}
        postZoomMeetingMessage={this.handlePostZoomMeetingMessage}
        destination={this.props.destination}
        defaultZoomMeetingContent={`${this.props.student.fullName}さんとZoomミーティングを行います。`}
        storageKey={generateStorageKey(["messages", props.student.id, "new"])}
      />
    );
  }

  private handlePostMessage = (content: string): void => {
    this.props.postMessage(this.props.student.id, content);
  };

  private handlePostMessageFile = (file: File): void => {
    this.props.postMessageFile(this.props.student.id, file);
  };

  private handlePostZoomMeetingMessage = (
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ): void => {
    this.props.postZoomMeetingMessage(
      this.props.student.id,
      params,
      setSubmitting,
      setErrors,
      onSuccessCallback,
    );
  };

  private handleChangeMessage = (content: string): void => {
    this.props.setFieldValue("content", content);
  };

  private handleChangeMessageFile = (file: File | null): void => {
    this.props.setFieldValue("file", file);
  };
}

const mapPropsToValues = (): Values => {
  return {
    content: "",
    file: null,
  };
};
const handleSubmit = (
  // eslint-disable-next-line
  _: Values,
  // eslint-disable-next-line
  __: FormikBag<ComponentProps & StudentMessageState, Values>,
  // eslint-disable-next-line
) => {};

// メモ：バグ対応のため、handleSubmitをダミー化して一時しのぎをしているが、SectionStudentMessageのようにhooksに移行した方が良い
const StudentMessageForm = withFormik({
  mapPropsToValues,
  handleSubmit,
})(InnerForm);

const actions = {
  postMessage: postStudentMessage,
  postMessageFile: postStudentMessageFile,
  postZoomMeetingMessage,
};

const mapStateToProps = (state: AppStateInterface) => ({
  studentMessageState: state.studentMessagesState,
});

export default connect(mapStateToProps, actions)(StudentMessageForm);
