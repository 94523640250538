import {
  StudyTaskStudentsResponse,
  useFetchStudyTaskStudents,
} from "./useFetchStudyTaskStudents";
import Loader from "../../../components/atoms/Loader";
import Table from "../../../components/atoms/Table";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { StudTaskStudentTableRow } from "./StudyTaskStudentTableRow";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../components/general/Popover/Popover";
import { IconHelpSupport } from "@studyplus/boron-ui";

type Props = {
  sectionId: string;
  studyTaskId: string;
  learningMaterialName: string;
};

export const StudyTaskStudentTable = ({
  sectionId,
  studyTaskId,
  learningMaterialName,
}: Props) => {
  const {
    data: students,
    error,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchStudyTaskStudents({
    sectionId,
    studyTaskId,
  });
  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  if (isLoading || !students) {
    return <Loader loading={isLoading || !students} />;
  }

  return (
    <div className="mt-1" aria-label="課題の配信先生徒一覧">
      {!isLoading && (
        <Table
          className="overflow-hidden"
          noRowsText="配信先の対象生徒がいません"
          headers={[
            {
              text: "氏名",
              centering: true,
              key: "fullName",
            },
            {
              text: "",
              centering: true,
              key: "jobHuman",
            },
            {
              text: "課題進捗率",
              centering: true,
              key: "progressRate",
              className: "flex justify-center items-center",
              children: <HelpPopover />,
            },
            {
              text: "",
              centering: true,
              key: "learningMaterialName",
              className: "cursor-pointer max-w-0",
              children: (
                <LearningMaterialNamePopover name={learningMaterialName} />
              ),
            },
          ]}
          rows={students.map((student: StudyTaskStudentsResponse[0]) => (
            <StudTaskStudentTableRow key={student.id} student={student} />
          ))}
        />
      )}
      <Loader loading={isLoading || isFetchingNextPage} />
    </div>
  );
};

const HelpPopover = () => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger className="ml-1 flex p-0">
          <IconHelpSupport className="text-gray-800" width={14} height={14} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent side="top" className="text-center">
            課題として学習した
            <br />
            コンテンツの割合
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

const LearningMaterialNamePopover = ({ name }: { name: string }) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger asChild>
          <div className="overflow-hidden text-ellipsis whitespace-nowrap">
            {name}
          </div>
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent side="top" className="text-center">
            {name}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};
