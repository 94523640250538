import ApiClient from "../api";

const SectionsKarteTemplatesApi = {
  getSectionsKarteTemplates: (sectionId: string) => {
    return ApiClient.get(`/api/v1/sections/${sectionId}/karte_templates`);
  },
  getSectionsKarteTemplate: (sectionId: string, karteTemplateId: string) => {
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/karte_templates/${karteTemplateId}`,
    );
  },
  createSectionKarteTemplate: (
    sectionId: string,
    params: { name: string; template: string },
  ) => {
    const body = { karte_template: params };
    return ApiClient.post(
      `/api/v1/sections/${sectionId}/karte_templates`,
      body,
    );
  },
  updateSectionKarteTemplate: (
    sectionId: string,
    karteTemplateId: string,
    params: { name: string; template: string },
  ) => {
    const body = { karte_template: params };
    return ApiClient.patch(
      `/api/v1/sections/${sectionId}/karte_templates/${karteTemplateId}`,
      body,
    );
  },
  deleteSectionKarteTemplate: (sectionId: string, karteTemplateId: string) => {
    return ApiClient.delete(
      `/api/v1/sections/${sectionId}/karte_templates/${karteTemplateId}`,
    );
  },
};

export default SectionsKarteTemplatesApi;
