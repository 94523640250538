import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { boronClient } from "../../api";
import { paths } from "../../lib/api/v1";
import { HTTPErrors, createError } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";

type Props = {
  sectionId: string;
  queryParams: paths[Url]["get"]["parameters"]["query"];
  enabled: boolean;
};

export type SectionTimelineSummaryResponse =
  paths[Url]["get"]["responses"]["200"]["content"]["application/json"]["students"];
export type SectionTimelineSummaryResponseStudyRecord =
  SectionTimelineSummaryResponse["data"][0]["studyRecords"][0];

export const URL = "/api/v1/sections/{section_id}/timeline/daily";
type Url = typeof URL;

export const useFetchSectionsTimelineSummary = ({
  sectionId,
  queryParams,
  enabled,
}: Props) => {
  const { data, ...result } = useInfiniteQuery<
    SectionTimelineSummaryResponse,
    HTTPErrors
  >({
    queryKey: [URL, sectionId, queryParams],
    queryFn: ({ pageParam }) =>
      fetchSectionsTimelineSummary({
        sectionId,
        queryParams: { ...queryParams, page: pageParam as number },
      }),
    initialPageParam: 1,
    getNextPageParam,
    enabled,
  });

  const queryClient = useQueryClient();

  // いいねやコメントをした学習記録をキャッシュに反映する(画面に反映する)ための関数
  const updateStudyRecordQueryCache = (
    newStudyRecord: SectionTimelineSummaryResponseStudyRecord,
  ) => {
    queryClient.setQueriesData<InfiniteData<SectionTimelineSummaryResponse>>(
      { queryKey: [URL, sectionId, queryParams] },
      (oldData) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          pages: oldData.pages.map((page) => {
            return {
              ...page,
              data: page.data.map((oldStudent) => {
                return {
                  ...oldStudent,
                  studyRecords: oldStudent.studyRecords.map(
                    (oldStudyRecord) => {
                      if (oldStudyRecord.id === newStudyRecord.id)
                        return newStudyRecord;
                      return oldStudyRecord;
                    },
                  ),
                };
              }),
            };
          }),
        };
      },
    );
  };

  return {
    data: data?.pages.flatMap((page) => page.data),
    updateStudyRecordQueryCache,
    ...result,
  };
};

const fetchSectionsTimelineSummary = async ({
  sectionId,
  queryParams,
}: Pick<Props, "sectionId" | "queryParams">) => {
  const { data, response } = await boronClient.GET(URL, {
    params: {
      path: {
        section_id: sectionId,
      },
      query: queryParams,
    },
  });

  if (response.ok && data) {
    return data.students;
  }

  throw await createError(response);
};
