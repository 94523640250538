import { useParams } from "react-router";
/**
 * リソースの編集と新規登録を同じページにしたいときにURLにリソースのIDがあるかどうかで
 * 編集か新規登録かを判断するhoks
 *
 * ex.)
 * /sections/id001/settings/teachers/new
 * /sections/id001/settings/teachers/id000/edit
 * で同じページで解決している場合
 *
 * const urlAction = useDetermineURLAction(
 *   (urlParam) => urlParam.teacherId ?
 *      createEditURLAction({teacherId: urlParam.teacherId}):
 *      createAddURLAction()
 * )
 * コールバックに渡せられるurlParamに応じて新規か編集かのアクションのfactory関数を実行する
 * 作ったアクションをコールバックの戻り値にする
 *
 */

export const URLType = {
  add: "add",
  edit: "edit",
} as const;

export type URLActionTypes = keyof typeof URLType;

type AddURLAction = { type: (typeof URLType)["add"] };
type EditURLAction<T> = { type: (typeof URLType)["edit"] } & T;
export type BaseURLAction<T> = AddURLAction | EditURLAction<T>;

// P = "add" or "edit" 以外のURLアクションを使いたい場合も考慮している
export const useDetermineURLAction = <T, P = BaseURLAction<T>>(
  determineFn: (params: Partial<T>) => P,
) => {
  const params = useParams();
  return determineFn(params as Partial<T>);
};

export const createAddURLAction = (): AddURLAction => ({ type: "add" });
export const createEditURLAction = <T>(resourceIdObj: T): EditURLAction<T> =>
  Object.assign({ type: "edit" as const }, resourceIdObj);

export const determineActionLabel = (urlActionType: URLActionTypes) =>
  urlActionType === URLType.edit ? "更新" : "登録";
