import ApiClient from "../api";

const StudentPreRegisteredLearningMaterialApi = {
  getLearningMaterials: (studentId: string): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/learning_materials/pre_registrations`,
    );
  },

  delete: (studentId: string, materialCode: string): Promise<Response> => {
    return ApiClient.delete(
      `/api/v1/students/${studentId}/learning_materials/${materialCode}/pre_registrations`,
    );
  },
};

export default StudentPreRegisteredLearningMaterialApi;
