import { FormikErrors, useFormik } from "formik";
import type { paths } from "../../lib/api/v1";
import * as yup from "yup";
import { startOfDay } from "date-fns";
import { OptionType } from "../../components/features/LoadableStudentsMultiSelect";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { useMutateStudyTask } from "./useMutateStudyTask";
import SectionInterface from "../../interfaces/SectionInterface";
import { DrillSearchResponseItem } from "./DrillLearningMaterialSelectionForm/DrillLearningMaterialSelector/useFetchDrillLearningMaterials";
import { DrillSection } from "./DrillLearningMaterialSelectionForm/DrillLearningMaterialSelectionForm";
import {
  isUnprocessableEntityError,
  UnprocessableEntityError,
} from "../../errors";
import { useNavigate } from "react-router";

export type Values = Omit<
  paths["/api/v1/sections/{section_id}/study_tasks"]["post"]["requestBody"]["content"]["application/json"],
  "student_ids" | "study_tasks"
> & {
  students: ReadonlyArray<OptionType> | null;
  study_tasks: StudyTaskValues[];
};

type StudyTaskValues = Omit<
  paths["/api/v1/sections/{section_id}/study_tasks"]["post"]["requestBody"]["content"]["application/json"]["study_tasks"][0],
  "drill_learning_material" | "start_at" | "end_at"
> & {
  start_at: Date;
  end_at: Date;
  drill_learning_material: DrillLearningMaterialValue;
};

export type DrillLearningMaterialValue = DrillSearchResponseItem & {
  sections: DrillSection[];
};

export const validate = (values: Values) => {
  return { ...validateStudyTasks(values) };
};

export type StudyTaskError = FormikErrors<StudyTaskValues>;
const validateStudyTasks = (values: Values): FormikErrors<Values> => {
  const errors: Array<StudyTaskError> = [];
  Object.values(values.study_tasks).forEach((studyTask) => {
    const error: StudyTaskError = {};
    // 配信日時のバリデーション
    if (studyTask.start_at > studyTask.end_at) {
      error["end_at"] = "配信終了日は配信開始日以降を設定してください";
    } else if (studyTask.end_at < startOfDay(new Date())) {
      error["end_at"] = "配信終了日は今日以降を設定してください";
    }

    // デジタル教材のバリデーション
    if (!studyTask.drill_learning_material.learningMaterialCode) {
      error["drill_learning_material"] = {
        learningMaterialCode:
          "デジタル教材を選択して課題範囲を設定してください",
      };
    }
    errors.push(error);
  });
  return errors.some((e) => Object.keys(e).length > 0)
    ? { study_tasks: errors }
    : {};
};

export const validationSchema = yup.object().shape({
  students: yup
    .array()
    .of(yup.mixed())
    .max(500, "対象者は500人以下で指定してください")
    .min(1, "対象者を設定してください"),
  study_tasks: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .max(200, "タイトルは200文字以下で指定してください")
        .required("タイトルを入力してください"),
      start_at: yup.date().required(),
      end_at: yup.date().required(),
      drill_learning_material: yup.object().shape({
        learningMaterialCode: yup.string(),
        imageUrl: yup.string(),
        name: yup.string(),
        contents: yup.array().of(
          yup.object().shape({
            id: yup.string(),
            sectionId: yup.string(),
          }),
        ),
      }),
    }),
  ),
});

const makeDefaultStudyTask = () => {
  return {
    title: "",
    start_at: startOfDay(new Date()),
    end_at: startOfDay(new Date()),
    drill_learning_material: makeDefaultDrillLearningMaterial(),
  };
};

const makeDefaultDrillLearningMaterial = (): DrillLearningMaterialValue => {
  return {
    learningMaterialCode: "",
    publicId: "",
    name: "",
    imageUrl: "",
    unit: "",
    isTypeDrill: true,
    creatorName: "",
    sections: [],
  };
};

const buildStudyTaskTitle = (
  drillLearningMaterial: DrillLearningMaterialValue,
) => {
  if (drillLearningMaterial.name === "") return "";

  const contentCount = drillLearningMaterial.sections.reduce(
    (acc, section) => acc + (section.contents?.length || 0),
    0,
  );
  return `${drillLearningMaterial.name}_コンテンツ：${contentCount}`;
};

type Props = {
  section: SectionInterface;
};
export const useStudyTaskForm = ({ section }: Props) => {
  const navigate = useNavigate();
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const { mutate, isPending: isMutating } = useMutateStudyTask({
    section,
    onSuccess() {
      showSuccessMessage("課題の登録に成功しました");
      navigate(`/sections/${section.id}/analytics/study_task`);
    },
    onError(error) {
      const message = isUnprocessableEntityError(error)
        ? createErrorMessage(error)
        : "課題の登録に失敗しました";
      showErrorMessage(message);
    },
  });

  const createErrorMessage = (error: UnprocessableEntityError) => {
    const limitExceededErrors = error.originalErrors.filter(
      (e) => e.message === "drill_learning_material_limit_exceeded",
    );
    if (limitExceededErrors.length > 0) {
      const exceededMaterialNames = limitExceededErrors
        .map((e) => `・${e.resource}`)
        .join("\n");
      return (
        "課題の登録で選択した以下の教材が「生徒配信枠利用数」の上限に達しているため課題を登録できませんでした。" +
        "\n" +
        exceededMaterialNames +
        "\n" +
        "教材管理のデジタル教材一覧から「生徒配信枠利用数」をご確認ください。"
      );
    }
    return "課題の登録に失敗しました";
  };

  const form = useFormik<Values>({
    initialValues: {
      confirmed: false,
      students: [],
      study_tasks: [makeDefaultStudyTask()],
    },
    validationSchema,
    validate,
    onSubmit: (values: Values) => mutate(values),
  });

  const isAddStudyTaskDisabled = form.values.study_tasks.length >= 10;
  const canRemoveStudyTask = form.values.study_tasks.length > 1;

  const addEmptyStudyTask = () => {
    if (isAddStudyTaskDisabled) {
      return;
    }

    form.setFieldValue("study_tasks", [
      ...form.values.study_tasks,
      makeDefaultStudyTask(),
    ]);
  };

  const removeStudyTask = (index: number) => {
    if (!canRemoveStudyTask) {
      return;
    }
    form.setFieldValue(
      "study_tasks",
      form.values.study_tasks.filter((_, i) => i !== index),
    );
  };

  const setDrillLearningMaterialForStudyTask = (
    index: number,
    drillLearningMaterial: DrillLearningMaterialValue,
  ) => {
    const targetStudyTask = form.values.study_tasks[index];
    // タイトルを手動入力していない場合はデジタル教材から出力したタイトルに変更
    // タイトルが更新前の教材から出力したタイトルと同じ場合は手動入力ではないと判断する
    if (
      targetStudyTask.title ===
      buildStudyTaskTitle(targetStudyTask.drill_learning_material)
    ) {
      form.setFieldValue(
        `study_tasks[${index}].title`,
        buildStudyTaskTitle(drillLearningMaterial),
      );
      // 自動補完したタイトルはエラー表示を消しておく
      form.setFieldTouched(`study_tasks[${index}].title`, false);
    }

    form.setFieldValue(
      `study_tasks[${index}].drill_learning_material`,
      drillLearningMaterial,
    );
  };

  const resetDrillLearningMaterial = (index: number) => {
    const targetStudyTask = form.values.study_tasks[index];
    // タイトルを手動入力していない場合はタイトルも削除する
    // タイトルが更新前の教材から出力したタイトルと同じ場合は手動入力ではないと判断する
    if (
      targetStudyTask.title ===
      buildStudyTaskTitle(targetStudyTask.drill_learning_material)
    ) {
      form.setFieldValue(`study_tasks[${index}].title`, "");
    }

    form.setFieldValue(
      `study_tasks[${index}].drill_learning_material`,
      makeDefaultDrillLearningMaterial(),
    );
  };

  return {
    ...form,
    isSubmitting: isMutating,
    isAddStudyTaskDisabled,
    addEmptyStudyTask,
    removeStudyTask,
    setDrillLearningMaterialForStudyTask,
    resetDrillLearningMaterial,
    canRemoveStudyTask,
  };
};
