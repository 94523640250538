import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import StudentLearningMaterialApi from "../../../api-clients/StudentLearningMaterialApi";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../../flashMessage";
import { apiRequestError } from "../../apiRequestError";

// 本棚登録
const preRegisterLearningMaterialToBookshelf = createAction(
  "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF",
);
const preRegisterLearningMaterialToBookshelfSuccess = createAction(
  "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_SUCCESS",
);
const preRegisterLearningMaterialToBookshelfError = createAction(
  "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_ERROR",
);
export const dispatchPreRegisterLearningMaterialToBookshelf =
  (studentId: string, materialCode: string) => (dispatch: Dispatch) => {
    dispatch(preRegisterLearningMaterialToBookshelf());

    StudentLearningMaterialApi.preRegisterToBookshelf(studentId, materialCode)
      .then((res) => {
        if (res.ok) {
          dispatch(
            preRegisterLearningMaterialToBookshelfSuccess({ materialCode }),
          );
          dispatch(showSuccessFlashMessage("生徒の本棚に登録しました"));
        } else if (res.status === 422) {
          // 配布されていないデジタル教材を登録しようとした際に発生する
          dispatch(preRegisterLearningMaterialToBookshelfError());
          dispatch(
            showErrorFlashMessage(
              "この教材を利用するには、事前申し込みが必要です。",
            ),
          );
        } else {
          dispatch(preRegisterLearningMaterialToBookshelfError());
          dispatch(showErrorFlashMessage("生徒の本棚に登録できませんでした"));
        }
      })
      .catch(() => {
        dispatch(preRegisterLearningMaterialToBookshelfError());
        dispatch(apiRequestError());
      });
  };
