import {
  SectionExaminationDetail,
  SectionExaminationDetailStudent,
} from "../../../../domains/SectionExamination";

export const useExaminationResultsTable = (
  examinationResults: SectionExaminationDetail[],
) => {
  const { columns, studentResults } = omitEmptyColumns(examinationResults);

  return { columns, studentResults };
};

// studentResultsのうち、誰も受けていない科目の列を削除する
const omitEmptyColumns = (examinationResults: SectionExaminationDetail[]) => {
  const flattedStudents = examinationResults
    .map((detail) => {
      return detail.students;
    })
    .flat();
  const notEmptyColumnIndex = examinationResults[0].columns
    .map((_, index) => {
      const subjectColumn = flattedStudents.map(
        (student) => student.subjectResults[index],
      );
      const isNotEmpty = subjectColumn.some(
        (subjectResult) => subjectResult.point !== null,
      );
      if (!isNotEmpty) return null;
      return index;
    })
    .filter((e) => e !== null) as number[];

  const columns = notEmptyColumnIndex.map(
    (index) => examinationResults[0].columns[index],
  );
  const studentResults = flattedStudents.map((student) => {
    const subjectResults = notEmptyColumnIndex.map(
      (index) => student.subjectResults[index],
    );
    return { ...student, subjectResults } as SectionExaminationDetailStudent;
  });

  return {
    columns: columns,
    studentResults: studentResults,
  };
};
