import ApiClient from "../api";
import { GetRequestOptions } from "../api";
import * as queryString from "query-string";
import StudentTag from "../domains/StudentTag";

const AnnounceMessageApi = {
  interruptGetAnnounceDestinationTags: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    const query = makeQueryString(options);
    return ApiClient.interruptGet(`/api/v1/sections/${sectionId}/all_tags`, {
      query,
    });
  },

  getAnnounceDestinationTags: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    const query = makeQueryString(options);

    return ApiClient.get(`/api/v1/sections/${sectionId}/all_tags`, {
      query,
    });
  },

  interruptGetMessages: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/sections/${sectionId}/announce_messages`,
      options,
    );
  },

  getMessages: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/announce_messages`,
      options,
    );
  },

  postMessage: (
    sectionId: string,
    tag: StudentTag,
    content: string,
  ): Promise<Response> => {
    const params = {
      type: tag.type,
      destination_id: tag.id,
      content,
    };

    return ApiClient.post(
      `/api/v1/sections/${sectionId}/announce_messages`,
      params,
    );
  },

  postMessageFile: (
    sectionId: string,
    tag: StudentTag,
    file: File,
  ): Promise<Response> => {
    const path = `/api/v1/sections/${sectionId}/announce_messages`;
    const attachment =
      file.type === "application/pdf" ? { file } : { image: file };

    const params = {
      type: tag.type,
      destination_id: tag.id,
      ...attachment,
    };

    return ApiClient.sendFormData(path, "POST", params);
  },
};
const makeQueryString = (options?: GetRequestOptions): string => {
  const destinationTagQuery = queryString.stringify({
    with_latest_message: "true",
    active_student: "true",
  });

  if (options && options.query) {
    return `${options.query}&${destinationTagQuery}`;
  } else {
    return `?${destinationTagQuery}`;
  }
};

export default AnnounceMessageApi;
