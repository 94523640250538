import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../atoms/Icon";
import classNames from "classnames";
import { connect } from "react-redux";
// import Tooltip from '../../atoms/Tooltip/index';
import { toggleNavigation } from "../../../actions/toggleNavigation";
import DeviceHelper from "../../../helpers/DeviceHelper";
import { Link } from "react-router-dom";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";

interface ComponentProps {
  opened: boolean;
  label: string;
  iconName: string;
  link: string;
  isActive: boolean;
  collapsible?: boolean;
  visible: boolean;
}

interface DispatchProps {
  toggleNavigation: typeof toggleNavigation;
}

interface Props extends ComponentProps, DispatchProps, WithRouterProps {}

class NaviMenuListItem extends React.Component<Props, unknown> {
  public static defaultProps: ComponentProps = {
    opened: true,
    label: "",
    iconName: "",
    link: "",
    isActive: false,
    collapsible: true,
    visible: true,
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    if (this.props.visible) {
      return (
        <Link to={this.props.link} onClick={this.handleClick(this.props.link)}>
          <div
            className={classNames(styles.root, {
              [styles.active]: this.props.isActive,
              [styles.opened]: this.props.opened,
              [styles.collapsible]: this.props.collapsible,
            })}
          >
            <div className={styles.iconWrapper}>
              <Icon name={this.props.iconName} className={styles.icon} />
            </div>
            <div className={styles.label}>
              <span>{this.props.label}</span>
            </div>
          </div>
        </Link>
      );
    } else {
      return null;
    }
  }

  private handleClick = (path: string) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (DeviceHelper.isPC()) {
      this.props.navigate(path);
    } else {
      this.props.toggleNavigation();
      setTimeout(() => this.props.navigate(path), 300);
    }
  };

  // FIXME: CSSの問題でデザインを再現できないので一旦コメントアウトしている
  // private renderTooltip() {
  //   if (this.state.hovering) {
  //     return (
  //       <Tooltip label={this.props.label} />
  //     )
  //   } else {
  //     return null
  //   }
  // }
}

export default withRouter<Props>(
  connect(null, { toggleNavigation })(NaviMenuListItem),
);
