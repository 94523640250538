type None = "none";

export const Colors = {
  primary: "primary",
  black: "black",
  grayNormal: "grayNormal",
  grayDarken1: "grayDarken1",
} as const;

export type Colors = keyof typeof Colors;

const DataAttributeKeyColorType = "data-color-type";

export const colorTypeDataAttributesOf = (
  colorType: Colors | undefined,
): Record<string, Colors | None> => {
  if (!colorType) {
    return { [DataAttributeKeyColorType]: "none" };
  }
  return { [DataAttributeKeyColorType]: colorType };
};

const DataAttributeKeyColor = "data-color";
export const colorAttributeOf = (
  color: Colors | undefined,
): Record<string, Colors | None> => {
  if (!color) {
    return { [DataAttributeKeyColor]: "none" };
  }
  return { [DataAttributeKeyColor]: color };
};

export const ButtonVariants = {
  ghost: "ghost",
  outline: "outline",
  solid: "solid",
} as const;

export type ButtonVariants = keyof typeof ButtonVariants;

const DataAttributeKeyButtonVariant = "data-variant";

export const buttonVariantDataAttributesOf = (
  variant: ButtonVariants | undefined,
): Record<string, ButtonVariants | None> => {
  if (!variant) {
    return { [DataAttributeKeyButtonVariant]: "none" };
  }
  return { [DataAttributeKeyButtonVariant]: variant };
};
