import * as React from "react";
import { useEffect } from "react";
import SectionInterface from "../../interfaces/SectionInterface";
import Loader from "../../components/atoms/Loader";
import TabsNavi from "../../components/molecules/TabsNavi";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";
import { useFetchSectionStayingStudents } from "../../hooks/http/useFetchSectionStayingStudents";
import { useQueryError } from "../../hooks/http/useQueryError";
import SectionStayingStudentsTable from "./SectionStayingStudentsTable";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  section: SectionInterface;
}

const SectionsSettingsStayingStudentsPage_: React.FC<Props> = (props) => {
  const { data, isLoading, isFetchingNextPage, error } =
    useFetchSectionStayingStudents({
      sectionId: props.section.id,
    });
  useQueryError(error);

  useEffect(() => {
    props.setActiveMenu("stays");
  }, []);

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "入退室記録",
            link: `/sections/${props.section.id}/settings/stays`,
            active: false,
          },
          {
            label: "入室中生徒",
            link: "#",
            active: true,
          },
        ]}
      />
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <div className="mt-8">
          <SectionStayingStudentsTable
            sectionId={props.section.id}
            sectionStays={data || []}
          />
          <Loader loading={isFetchingNextPage} />
        </div>
      )}
    </>
  );
};

const pageInfo = {
  title: "入室中生徒",
};

export const SectionsSettingsStayingStudentsPage = enhanceSectionsSettingsPage(
  SectionsSettingsStayingStudentsPage_,
  pageInfo,
  ["stay:index"],
);
