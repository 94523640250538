import * as React from "react";
import {
  formatFileSize,
  isValidFileName,
  isValidFilesize,
  uploadFileSizeLimit,
  nameLengthLimit,
} from "../../../domains/Content";
import { genId } from "../../../helpers/Id";
import { InputFile } from "./";

type Status =
  | "ReadySubmit" // ファイルが一つ以上セットされている、かつ全てvalidなファイル
  | "Empty" // ファイルがセットされてない、登録ボタンを押せない
  | "Loading" // セットされたファイルをアップロード処理中、登録ボタン押せない
  | "Complete" // アップロード処理後、セットされた全てのファイルのアップロードが完了
  | "ValidationError" // セットされたファイルがけい100MB以上か名前が32文字以上のファイルがある、登録ボタンを押せない
  | "UploadError"; // アップロード処理後、一つでもアップロードに失敗したファイルがある
type ContextValue = {
  files: readonly InputFile[];
  size: string;
  doneFileIds: Readonly<Record<string, string>>;
  progressPercent: number;
  validationErrors: readonly Error[];
};

type State = {
  status: Status;
  context: ContextValue;
};

type Events =
  | {
      type: "OnChooseFile";
      files: File[];
    }
  | {
      type: "onCompleteAllUpload";
    }
  | {
      type: "OnDelete";
      id: string;
    }
  | {
      type: "OnUpload";
    }
  | {
      type: "OnSuccessUploadFile";
      id: string;
    };

const initialState: State = {
  status: "Empty",
  context: {
    files: [],
    size: "0bytes",
    doneFileIds: {},
    progressPercent: 0,
    validationErrors: [],
  },
};

const reducer = (state: State, action: Events): State => {
  switch (action.type) {
    case "OnChooseFile": {
      const files: InputFile[] = [
        ...state.context.files,
        ...action.files.map((f) => ({
          id: genId("contentFile"),
          file: f,
        })),
      ];
      const validationErrors = validate(files);
      return {
        status: determineStatus(files, validationErrors),
        context: {
          ...state.context,
          files,
          size: formatTotalFileSize(files),
          validationErrors,
        },
      };
    }
    case "onCompleteAllUpload": {
      const doneAll =
        state.context.files.length ===
        Object.keys(state.context.doneFileIds).length;

      const newFiles = state.context.files.reduce<readonly InputFile[]>(
        (p, c) => {
          if (typeof state.context.doneFileIds[c.id] !== "undefined") {
            return p;
          }
          return [...p, c];
        },
        [],
      );

      return {
        status: doneAll ? "Complete" : "UploadError",
        context: {
          ...state.context,
          files: newFiles,
          size: formatTotalFileSize(newFiles),
          doneFileIds: {},
          progressPercent: 0,
        },
      };
    }

    case "OnDelete": {
      const files = state.context.files.filter((cf) => cf.id !== action.id);
      const validationErrors = validate(files);

      return {
        status: determineStatus(files, validationErrors),
        context: {
          ...state.context,
          files,
          size: formatTotalFileSize(files),
          validationErrors,
        },
      };
    }
    case "OnUpload":
      return {
        ...state,
        status: "Loading",
      };

    case "OnSuccessUploadFile": {
      const newDoneFIleIds = {
        ...state.context.doneFileIds,
        [action.id]: action.id,
      };
      const progressPercent = Math.floor(
        (Object.keys(newDoneFIleIds).length / state.context.files.length) * 100,
      );

      return {
        ...state,
        context: {
          ...state.context,
          doneFileIds: { ...state.context.doneFileIds, [action.id]: action.id },
          progressPercent,
        },
      };
    }
  }
};

export const useFilesState = () => {
  const [state, dispatch] = React.useReducer<typeof reducer>(
    reducer,
    initialState,
  );

  return {
    state,
    dispatch,
  };
};

export const ERROR_STR_FILE_SIZE = `一度にアップロードできる容量は${formatFileSize(
  uploadFileSizeLimit,
)}までです`;
export const ERROR_STR_FILE_NAME = `ファイル名が${nameLengthLimit}文字を超えるファイルは登録できません`;

const calcTotalFileSize = (files: readonly InputFile[]) =>
  files.reduce((p, c) => p + c.file.size, 0);

const formatTotalFileSize = (files: readonly InputFile[]) =>
  formatFileSize(calcTotalFileSize(files));

const validate = (files: readonly InputFile[]) => {
  let errors: readonly Error[] = [];
  if (files.some((f) => !isValidFileName(f.file.name))) {
    errors = [...errors, new Error(ERROR_STR_FILE_NAME)];
  }

  if (!isValidFilesize(calcTotalFileSize(files))) {
    errors = [...errors, new Error(ERROR_STR_FILE_SIZE)];
  }
  return errors;
};

const determineStatus = (
  files: readonly InputFile[],
  errors: readonly Error[],
): Status => {
  if (files.length === 0) {
    return "Empty";
  }
  if (errors.length > 0) {
    return "ValidationError";
  }
  return "ReadySubmit";
};
