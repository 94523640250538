import * as React from "react";
import SectionsSettingsTemplate from "../components/templates/SectionsSettingsTemplate/index";
import enhanceAuthenticatedPage, {
  AuthenticatedPageProps,
  AuthenticateRouterProps,
} from "./enhanceAuthenticatedPage";
import {
  AuthenticatedPageCallbacks,
  DispatchProps,
  StateProps,
} from "./enhanceAuthenticatedPage";
import FiltersInterface, {
  StudentFilterInterface,
} from "../interfaces/FiltersInterface";
import { OperatorPrivilege } from "../interfaces/OperatorInterface";
import { initializeFiltersByQuery } from "../actions/common/filters";
import withFiltersInitialization from "./withFiltersInitialization";
import { SectionProvider } from "../contexts/section";
import { withRouter, WithRouterProps } from "../helpers/RouterHelper";
import { StudentTagFilterInterface } from "../domains/StudentTag";

export interface SectionsSettingsPageProps
  extends WithRouterProps<AuthenticateRouterProps>,
    AuthenticatedPageProps,
    AuthenticatedPageCallbacks,
    DispatchProps,
    StateProps {
  initializeFiltersByQuery: typeof initializeFiltersByQuery;
}

interface PageInfoInterface {
  title: string;
}

const enhanceSectionsSettingsPage = (
  WrappedComponent: React.ComponentClass<any, any> | React.FC<any>,
  pageInfo: PageInfoInterface,
  requiredPrivileges?: OperatorPrivilege[],
) => {
  class SectionsPage extends React.Component<SectionsSettingsPageProps> {
    get studentFilter(): StudentFilterInterface | undefined {
      const tagFilter = this.props.params.sectionId
        ? this.props.studentFilterContext.currentFilter
        : undefined;
      return {
        sectionId: this.props.params.sectionId || "",
        tagFilter: tagFilter || ({} as StudentTagFilterInterface),
      };
    }

    get filter() {
      return this.props.filters.find((filter: FiltersInterface) => {
        return (
          !!this.props.currentSection.data &&
          filter.sectionId === this.props.currentSection.data.id
        );
      });
    }

    render() {
      if (
        !this.props.currentSection.data ||
        !this.props.currentOperator ||
        !this.studentFilter ||
        !this.filter ||
        !this.props.operatorPrivileges.data
      ) {
        return null;
      }

      return (
        <React.Fragment>
          <SectionsSettingsTemplate
            pageInfo={{
              title: pageInfo.title,
            }}
          >
            <SectionProvider value={this.props.currentSection.data}>
              <WrappedComponent
                {...this.props}
                section={this.props.currentSection.data}
                tagFilter={this.studentFilter.tagFilter}
              />
            </SectionProvider>
          </SectionsSettingsTemplate>
        </React.Fragment>
      );
    }
  }
  return withRouter<SectionsSettingsPageProps>(
    enhanceAuthenticatedPage(
      withFiltersInitialization(SectionsPage),
      requiredPrivileges,
    ),
  );
};

export default enhanceSectionsSettingsPage;
