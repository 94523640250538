import Icon from "../../../components/atoms/Icon";
import LinkButton from "../../../components/atoms/LinkButton";
import {
  HorizontalScrollTable,
  TableColumn,
} from "../../../components/atoms/Table";
import styles from "./LearningMaterialTable.scss";
import { Flex } from "../../../components/atoms/Flex";
import {
  ManagementLearningMaterialList,
  toLearningMaterialTypeLabel,
} from "../../../domains/ManagementLearningMaterial";
import { LearningMaterialTagAssociation } from "../../../components/features/LearningMaterialTagModal";
import {
  DefaultLearningMaterialTag,
  CustomLearningMaterialTag,
} from "../../../components/features/LearningMaterialTagLabel/LearningMaterialTagLabel";

type Props = {
  managementLearningMaterials: ManagementLearningMaterialList;
  openModal: (learningMaterial: LearningMaterialTagAssociation) => void;
};
export const LearningMaterialTable = ({
  managementLearningMaterials,
  openModal,
}: Props) => {
  if (managementLearningMaterials.length === 0) {
    return null;
  }
  return (
    <HorizontalScrollTable
      headers={[
        { text: "教材名", centering: true, key: "learning-material-name" },
        { text: "教材種別", centering: true, key: "learning-material-type" },
        { text: "利用中生徒数", centering: true, key: "student-count" },
        { text: "教材タグ", centering: true, key: "learning-material-tags" },
        { text: "", centering: true, key: "config-learning-material-tags" },
      ]}
      rows={managementLearningMaterials.map((item) => (
        <tr
          key={`management-learning-material-${item.learningMaterial.learningMaterialCode}`}
        >
          <TableColumn className={styles.learningMaterialCell}>
            <Flex marginTop="0rem" alignItems="center">
              <img
                className={styles.learningMaterialImg}
                src={item.learningMaterial.imageUrl}
              />
              <span className={styles.learningMaterialName}>
                {item.learningMaterial.name}
              </span>
            </Flex>
          </TableColumn>
          <TableColumn centering>
            {toLearningMaterialTypeLabel(item)}
          </TableColumn>
          <TableColumn centering>{item.studentCount}</TableColumn>
          <TableColumn className={styles.learningMaterialTagLabelCell}>
            <ul className={styles.learningMaterialLabelList}>
              {item.tags.map((lmTag) =>
                lmTag.tagType === "default" ? (
                  <DefaultLearningMaterialTag
                    key={`learning-material-tag-label-${lmTag.id}`}
                  >
                    {lmTag.name}
                  </DefaultLearningMaterialTag>
                ) : (
                  <CustomLearningMaterialTag
                    key={`learning-material-tag-label-${lmTag.id}`}
                  >
                    {lmTag.name}
                  </CustomLearningMaterialTag>
                ),
              )}
            </ul>
          </TableColumn>
          <TableColumn className={styles.buttonCell}>
            <LinkButton
              onClick={() => {
                openModal({
                  learningMaterialName: item.learningMaterial.name,
                  learningMaterialCode:
                    item.learningMaterial.learningMaterialCode,
                  learningMaterialTags: item.tags,
                });
              }}
            >
              <Flex marginTop="0rem" alignItems="center">
                <Icon name="icon-import-tag" className={styles.icon} />
                <span className={styles.linkButtonLabel}>タグ設定</span>
              </Flex>
            </LinkButton>
          </TableColumn>
        </tr>
      ))}
    />
  );
};
