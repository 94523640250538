import imageCompression from "browser-image-compression";

interface Options {
  maxSizeMB: number;
}

const compressImage = async (image: File, options?: Options) => {
  const defaultOptions = {
    maxSizeMB: 1,
    maxIteration: 40,
  };

  const compressedBlob = await imageCompression(image, {
    ...defaultOptions,
    ...options,
  });
  const file = new File([compressedBlob], compressedBlob.name, {
    type: image.type,
  });
  return file;
};

export default compressImage;
