import { SectionKarteTemplateEditStateInterface } from "../interfaces/SectionKarteTemplateInterface";

const defaultState: SectionKarteTemplateEditStateInterface = {
  data: null,
  loading: false,
  submitting: false,
  apiErrors: [],
};

const sectionKarteTemplateEditState = (
  state: SectionKarteTemplateEditStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_SECTIONS_KARTE_TEMPLATE_REQUEST":
      return {
        ...defaultState,
        loading: true,
      };
    case "GET_SECTIONS_KARTE_TEMPLATE_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload.karteTemplate,
      };
    case "GET_SECTIONS_KARTE_TEMPLATE_ERROR":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default sectionKarteTemplateEditState;
