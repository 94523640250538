import * as React from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import { SectionStaffBoardPostsStateInterface } from "../../../interfaces/SectionStaffBoardPostInterface";
import SectionStaffBoardPosts from "../../../components/organisms/SectionStaffBoardPosts";
import {
  getStaffBoardPostsInitial,
  getStaffBoardPosts,
} from "../../../actions/pages/SectionStaffBoardPostsPage/actions";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { OutletContextProps, withRouter } from "../../../helpers/RouterHelper";

interface Props extends OutletContextProps {
  section: SectionInterface;
  currentOperator: OperatorInterface | undefined;
  staffBoardPostsState: SectionStaffBoardPostsStateInterface;
  getPostsInitial: HandleThunkActionCreator<typeof getStaffBoardPostsInitial>;
  getPosts: HandleThunkActionCreator<typeof getStaffBoardPosts>;
}

class SectionsStaffBoardPostsPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    props.getPostsInitial(props.section.id);
    props.setActiveMenu("dashboard");
  }

  componentDidMount() {
    (window as any).onMainScrollAreaScroll = this.handleScroll;
  }

  UNSAFE_componentWillMount() {
    (window as any).onMainScrollAreaScroll = null;
  }

  render() {
    return (
      <SectionStaffBoardPosts
        section={this.props.section}
        currentOperator={this.props.currentOperator}
        staffBoardPostsState={this.props.staffBoardPostsState}
      />
    );
  }

  private handleScroll = () => {
    const { staffBoardPostsState } = this.props;
    if (!staffBoardPostsState.loading && staffBoardPostsState.hasMore) {
      this.props.getPosts(
        this.props.section.id,
        staffBoardPostsState.meta.currentPage + 1,
      );
    }
  };
}

const pageInfo = {
  title: "スタッフボード",
};

const EnhancedPage = enhanceSectionsPage(SectionsStaffBoardPostsPage, pageInfo);

const mapStateToProps = (state: AppStateInterface) => ({
  currentOperator: state.session.currentOperator,
  staffBoardPostsState: state.sectionStaffBoardPostsState,
});

const actions = {
  getPostsInitial: getStaffBoardPostsInitial,
  getPosts: getStaffBoardPosts,
};

export default withRouter(connect(mapStateToProps, actions)(EnhancedPage));
