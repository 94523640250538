import React from "react";
import { LectureSession } from "../../../domains/LectureSession";
import { SectionSchedule } from "../../../domains/SectionSchedule";
import { Teacher } from "../../../domains/Teacher";
import { Classroom } from "../../../domains/Classroom";
import { Lecture } from "../../../domains/Lecture";
import { useFetchSectionSchedule } from "./useFetchSectionSchedule";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

type UseSectionScheduleDetailViewProps = {
  section: SectionInterface;
  sectionSchedule?: SectionSchedule | null;
};
export const useSectionScheduleDetail = ({
  section,
  sectionSchedule,
}: UseSectionScheduleDetailViewProps) => {
  const { showErrorMessage } = useFlashMessage();

  const {
    data: sectionScheduleDetail,
    isLoading,
    isError,
  } = useFetchSectionSchedule({
    section,
    sectionSchedule: sectionSchedule ?? null,
  });

  // エラー時のコールバック処理
  React.useEffect(() => {
    if (isError) {
      showErrorMessage(
        "予定の取得に失敗しました。リロードして再度お試しください。",
      );
    }
  }, [isError]);

  return {
    loading: isLoading,
    sectionScheduleDetail,
    members: sectionScheduleDetail?.schedule.students ?? [],
    memberSummary: determineMemberSummary({
      numOfAnotherSection:
        sectionScheduleDetail?.lectureSession?.fromOtherSectionCount ?? 0,
      numOfAnotherSectionTemporarily:
        sectionScheduleDetail?.lectureSession?.transferCount ?? 0,
      numOfMembers: sectionScheduleDetail?.schedule.students.length ?? 0,
    }),
    teacher: determineTeacherText(sectionScheduleDetail?.teacher ?? null),
    classroom: determineClassroomText(sectionScheduleDetail?.classroom ?? null),
    lectureTitleText: determineLectureTitleText(
      sectionScheduleDetail?.lectureSession?.lecture ?? null,
    ),
    lectureSummaryInfo: determineLectureSummaryInfoText(
      sectionScheduleDetail?.lectureSession ?? null,
    ),
  };
};

const determineTeacherText = (teacher: Teacher | null) =>
  teacher ? `講師：${teacher.fullName}` : null;

const determineClassroomText = (classroom: Classroom | null) =>
  classroom ? `教室：${classroom.name} ` : null;

const determineLectureTitleText = (lecture: Lecture | null) =>
  lecture ? `講座：${lecture.name}` : null;

const determineLectureSummaryInfoText = (
  lectureSession: LectureSession | null,
) =>
  lectureSession?.lecture
    ? `出席：${lectureSession.attendanceCount}人／欠席：${lectureSession.absenceCount}人`
    : null;

type DetermineMemberSummaryParams = {
  numOfMembers: number;
  numOfAnotherSection: number;
  numOfAnotherSectionTemporarily: number;
};
const determineMemberSummary = ({
  numOfAnotherSection,
  numOfAnotherSectionTemporarily,
  numOfMembers,
}: DetermineMemberSummaryParams) => {
  const textNumOfanotherSection =
    numOfAnotherSection > 0 || numOfAnotherSectionTemporarily > 0
      ? `（併校：${numOfAnotherSection}人 振替：${numOfAnotherSectionTemporarily}人）`
      : "";
  return numOfMembers > 0
    ? `対象者：${numOfMembers}人 ${textNumOfanotherSection}`
    : null;
};
