import * as React from "react";
import styles from "./LearningMaterialTagLabel.scss";
import classnames from "classnames";

type Props = {
  children?: React.ReactNode;
};
export const DefaultLearningMaterialTag = ({ children }: Props) => {
  return (
    <LearningMaterialTag colorType="lightBlue">{children}</LearningMaterialTag>
  );
};

export const CustomLearningMaterialTag = ({ children }: Props) => {
  return (
    <LearningMaterialTag colorType="lightGreen">{children}</LearningMaterialTag>
  );
};

type Colors = "lightGreen" | "lightBlue";
type LearningMaterialTagProps = {
  colorType: Colors;
} & Props;
const LearningMaterialTag = ({
  colorType,
  children,
}: LearningMaterialTagProps) => {
  const colorTypeClassName =
    colorType === "lightBlue"
      ? styles.colorTypeLightBlue
      : styles.colorTypeLightGreen;

  return (
    <li className={classnames(styles.root, colorTypeClassName)}>{children}</li>
  );
};
