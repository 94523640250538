import * as React from "react";
import styles from "./styles.scss";
import Loader from "../../atoms/Loader/index";
import KarteInterface, {
  StudentKartesStateInterface,
} from "../../../interfaces/KarteInterface";
import KarteItem from "../KarteItem/index";

interface Props {
  karteState: StudentKartesStateInterface;
}

const renderKartesItems = (karteState: StudentKartesStateInterface) => {
  if (!karteState.data) {
    return null;
  }

  return (
    <React.Fragment>
      {renderPinnedKarte(karteState.data)}
      {renderKartes(karteState.data)}
    </React.Fragment>
  );
};

const renderPinnedKarte = (kartes: KarteInterface[]) => {
  const karte: KarteInterface | undefined = kartes.find(
    (karte: KarteInterface) => karte.pinned,
  );
  if (!karte) {
    return null;
  }
  return (
    <KarteItem
      key={`StudentKarteList-${karte.id}`}
      section={karte.student.section}
      karte={karte}
      canPin={true}
    />
  );
};

const renderKartes = (kartes: KarteInterface[]) => {
  return kartes.map((karte: KarteInterface) => {
    if (karte.pinned) {
      return null;
    }
    return (
      <KarteItem
        key={`StudentKarteList-${karte.id}`}
        section={karte.student.section}
        karte={karte}
        canPin={true}
      />
    );
  });
};

const renderLoadMoreLoader = (props: Props) => {
  const { karteState } = props;

  if (karteState.loading && karteState.data) {
    return <Loader loading={karteState.loading} />;
  }

  return null;
};

const StudentKarteList = (props: Props) => {
  const { karteState } = props;

  if (karteState.loading && karteState.meta.currentPage === 0) {
    return <Loader loading={karteState.loading} />;
  }

  return (
    <div className={styles.root} aria-label="カルテ一覧" role="list">
      {karteState.data && karteState.data.length > 0 ? (
        renderKartesItems(karteState)
      ) : (
        <p>カルテがまだありません</p>
      )}
      {renderLoadMoreLoader(props)}
    </div>
  );
};

export default StudentKarteList;
