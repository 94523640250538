import { useInfiniteQuery } from "@tanstack/react-query";
import {
  ContentCourseResult,
  fromJson,
} from "../../domains/ContentCourseResult";
import { createError, HTTPErrors } from "../../errors";
import ApiResponse, { PaginationMetaData } from "../../interfaces/ApiResponse";
import ApiClient from "../../api";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";

type Props = {
  sectionId: string;
};
type ResponseData = ApiResponse<ContentCourseResult[]>;
export const useFetchContentCourseResults = (props: Props) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: [fetchUrl(props.sectionId)],
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.get(fetchUrl(props.sectionId), {
        query: { page: pageParam },
      });
      if (response.ok) {
        const json = await response.json();
        return {
          data: json.courseResults.data.map(
            (courseResult: Record<string, unknown>) => fromJson(courseResult),
          ),
          meta: json.courseResults.meta as PaginationMetaData,
        };
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  useOnMainScrollAreaScroll(() => {
    if (!isLoading && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);
  return {
    data: data?.pages.flatMap((page) => page.data),
    isLoading: isLoading,
    error,
  };
};

const fetchUrl = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/contents/results`;
