import { useMutation } from "@tanstack/react-query";
import api from "../../../api";
import { createError } from "../../../errors";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";

type MutateErrors = ApiErrorResponseInterface;
type Props = {
  onSuccess: () => void;
  onError: () => void;
};

type MutationParams = {
  emailActivationToken: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
};

export const useMutateActivation = ({ onSuccess, onError }: Props) => {
  return useMutation<void, MutateErrors, MutationParams>({
    mutationFn: async ({
      emailActivationToken,
      first_name,
      last_name,
      password,
      password_confirmation,
    }) => {
      const res = await api.patch(
        `/api/v1/activations/${emailActivationToken}`,
        {
          operator: {
            first_name,
            last_name,
            password,
            password_confirmation,
          },
        },
      );

      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    onSuccess,
    onError,
  });
};
