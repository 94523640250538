import * as yup from "yup";

const validationSchema = yup.object().shape({
  current_password: yup
    .string()
    .trim()
    .required("現在のパスワードを入力してください"),
  password: yup.string().trim().required("新しいパスワードを入力してください"),
  password_confirmation: yup
    .string()
    .trim()
    .required("新しいパスワード（確認）を入力してください")
    .oneOf([yup.ref("password"), null], "新しいパスワードと一致しません"),
});

export default validationSchema;
