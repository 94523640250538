import * as React from "react";
import styles from "./styles.scss";
import { SectionKarteTemplatesStateInterface } from "../../../interfaces/SectionKarteTemplatesInterface";
import TabsNavi from "../../molecules/TabsNavi";
import { Link } from "react-router-dom";
import KarteTemplateList from "../../molecules/KarteTemplateList";
import Loader from "../../atoms/Loader";
import SectionInterface from "../../../interfaces/SectionInterface";

interface Props {
  section: SectionInterface;
  sectionKarteTemplatesState: SectionKarteTemplatesStateInterface;
}

const SectionsSettingsKarteTemplates = (props: Props) => {
  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "テンプレート",
            link: `#`,
            active: true,
          },
        ]}
      />
      <div className={styles.newLink}>
        <Link
          to={`/sections/${props.section.id}/settings/karte_templates/new`}
          className={`buiButtonBase buiButtonVariantFilled ${styles.Btn}`}
        >
          新規作成
        </Link>
      </div>
      <div className={styles.tableWrapper}>
        <KarteTemplateList
          sectionId={props.section.id}
          karteTemplates={props.sectionKarteTemplatesState.data}
        />
      </div>
      <Loader loading={props.sectionKarteTemplatesState.loading} />
    </React.Fragment>
  );
};

export default SectionsSettingsKarteTemplates;
