import Input from "../../../components/atoms/Input";
import Label from "../../../components/atoms/Label";
import styles from "./ContentFileForm.scss";
import { Button } from "@studyplus/boron-ui";
import Icon from "../../../components/atoms/Icon";
import { SingleContentFileTable } from "./SingleContentFileTable";
import { Flex } from "../../../components/atoms/Flex";
import {
  useContentFileForm,
  UseContentFileFormProps,
} from "./useContentFileForm";
import ErrorText from "../../../components/atoms/ErrorText";

type ContentFileFormProps = {
  isMutating: boolean;
  className: string;
} & UseContentFileFormProps;
export const ContentFileForm = ({
  isMutating,
  className,
  ...props
}: ContentFileFormProps) => {
  const { inputFileRef, formik, onClickFile, onChangeFile, acceptTypes } =
    useContentFileForm(props);
  return (
    <form onSubmit={formik.handleSubmit} className={className}>
      <Label isMute={true}>コンテンツ名</Label>
      <Input
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        hasError={formik.touched.name && Boolean(formik.errors.name)}
      />
      {formik.errors.name && <ErrorText>{formik.errors.name}</ErrorText>}
      <div className={styles.btnChoose}>
        <Button
          type="button"
          className={styles["button--choose"]}
          variant="outline"
          disabled={isMutating}
          onClick={onClickFile}
        >
          <Icon name="icon-plus-tag" />
          ファイルの選択
        </Button>
        <input
          ref={inputFileRef}
          name="file"
          type="file"
          onChange={onChangeFile}
          hidden
          accept={acceptTypes}
          data-testid="user-file"
        />
      </div>
      <div className={styles.tableFile}>
        <SingleContentFileTable
          file={formik.values.file}
          content={props.fetchedContent}
        />
      </div>
      {formik.errors.file && <ErrorText>{formik.errors.file}</ErrorText>}
      <Flex marginTop="2.6rem" justifyContent="flex-end">
        <div>
          <Button
            type="submit"
            className={styles.submitButton}
            disabled={!formik.isValid}
            isLoading={isMutating}
          >
            更新
          </Button>
        </div>
      </Flex>
    </form>
  );
};
