import * as React from "react";

export const SectionContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="mb-7 rounded border border-solid border-gray-400">
      {children}
    </div>
  );
};

export const SectionTitle = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex items-center rounded-t bg-gray-200 p-4 text-lg">
      {children}
    </div>
  );
};

export const SectionBody = ({ children }: React.PropsWithChildren) => {
  return <div className="p-7">{children}</div>;
};
