import { KarteCommentInterface } from "../interfaces/KarteInterface";

const karteComments = (
  state: KarteCommentInterface[] = [],
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "POST_KARTE_COMMENT_SUCCESS":
      return state.concat(action.payload.karteComment);
    case "DELETE_KARTE_COMMENT_SUCCESS":
      return state.filter((comment) => comment.id !== action.payload.commentId);
    case "DELETE_KARTE_COMMENT_ERROR":
    default:
      return state;
  }
};

export default karteComments;
