import * as React from "react";
import Helmet from "react-helmet";
import SubLayout from "../../../components/atoms/SubLayout";
import Settings from "../../../helpers/Settings";
import styles from "./styles.scss";

const ConsentPage: React.FC<Record<string, never>> = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex"></meta>
      <title>{`個人情報保護に関する同意事項 | ${Settings.SITE_TITLE}`}</title>
    </Helmet>
    <SubLayout>
      <SubLayout.Heading>個人情報保護に関する同意事項</SubLayout.Heading>
      <SubLayout.Main>
        <div className={styles.root}>
          <p>
            ご提供いただく個人情報のお取り扱いの方針について、以下の通り通知いたします。
          </p>
          <h2>1.当社の名称</h2>
          <p>スタディプラス株式会社</p>

          <h2>2.利用目的</h2>
          <p>ご記入いただいた個人情報は、以下の目的に利用します。</p>
          <ul>
            <li>
              商品、サービスに関する情報の提供および提案、連絡、マーケティング活動メール・お電話・郵送・FAX・訪問等によるご案内・連絡等
            </li>
            <li>
              商品、サービスの企画および利用等の調査に関する、お願い・連絡・回答アンケート等による商品、サービス、企画、お客様ご利用状況・満足度等の調査等
            </li>
            <li>
              商品、サービス、その他のお問い合わせ、依頼等の対応、試用の提供お客様からの各種お問い合わせ、資料請求など依頼対応等
            </li>
            <li>
              展示会、セミナー、トレーニング、懸賞、その他イベントに関する案内・回答
            </li>
            <li>統計資料の作成</li>
            <li>その他一般事務・業務等の連絡・お問い合わせ・回答</li>
            <li>
              お客様から受託もしくは個別のサービスにて同意を得た範囲内で利用する場合
            </li>
          </ul>

          <p>【イベントにお申し込み、ご参加の場合】</p>
          <ul>
            <li>
              代金の請求・回収・支払い等の事務処理（イベントが有償の場合）
            </li>
            <li>
              展示会、セミナー、トレーニング、懸賞、その他イベントに関する参加登録（受付カードへの記入、名刺の受け渡しを含む）
            </li>
            <li>
              参加登録された情報に関する確認、連絡、問い合わせ、回答（メール・お電話・郵送・FAX・訪問等による）
            </li>
          </ul>

          <h2>3.第三者提供について</h2>
          <p>
            ご記入いただいた個人情報は、当社の提携企業やイベントの協賛・協力・出展企業に提供する場合があります。
          </p>
          <ul>
            <li>
              提供目的：商品、サービスの情報またはマーケティング活動（イベント、改善提案など）に関する情報の連絡
            </li>
            <li>
              提供項目：氏名、役職、団体法人名、部署名、連絡先（ご住所、電話／FAX番号、電子メールアドレスを含む）
            </li>
          </ul>

          <p>
            当社と提供企業とは、個人情報保護に関する契約を締結いたします。なお、提供項目は目的達成に必要な最低限とし、提供手段は当社の定めた安全な手段といたします。
          </p>
          <p>
            また、法令の定めによる場合、お客様ご本人および人の生命や身体または財産の保護のために必要な場合、あらかじめお客様ご本人から同意を得ている場合にも第三者に提供します。
          </p>

          <h2>4.委託について</h2>
          <p>
            ご記入いただいた個人情報は、利用目的の達成に必要な範囲内において、第三者へ個人情報を委託する場合があります。その場合は、個人情報保護体制が整備された委託先を選定するとともに、個人情報保護に関する契約を締結いたします。
          </p>

          <h2>5.個人情報の開示・訂正・削除について</h2>
          <p>
            当社は、お客様から開示対象個人情報について利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去および第三者への提供の停止の求めがあった場合には、遅滞なく対応します。当社の個人情報管理責任者まで申し出ください。
          </p>

          <h2>6.個人情報を与えることの任意性について</h2>
          <p>
            当社へ個人情報を提供されるかは任意となります。ただし必要な個人情報を提供いただけない場合、当社はお客様のお問い合わせに対して、適切に対応出来ない場合があります。
          </p>

          <h2>7.お問い合わせ窓口</h2>
          <p>会員情報に関するお問い合わせは、下記までご連絡お願い致します。</p>
          <p>
            〒101-0062 東京都千代田区神田駿河台二丁目5番地12
            <br />
            スタディプラス株式会社 個人情報問合せ窓口
            <br />
            メールアドレス：privacycenter@studyplus.jp
          </p>
          <p>
            ※
            土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。
          </p>

          <h2>8.個人情報管理責任者</h2>
          <p>
            利用者の個人情報は、以下の者が責任をもって管理するものとします。
            <br />
            管理者名：中島花絵
            <br />
            所属部署：管理部
            <br />
            連絡先：メールアドレス：privacycenter@studyplus.jp
          </p>
        </div>
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  </>
);

export default ConsentPage;
