import { SectionLearningMaterialEditStateInterface } from "../interfaces/SectionLearningMaterialInterface";

const editDefaultState: SectionLearningMaterialEditStateInterface = {
  loading: false,
  submitting: false,
  data: null,
  apiErrors: [],
};

const sectionLearningMaterialEditState = (
  state: SectionLearningMaterialEditStateInterface = editDefaultState,
  action: ReduxActions.Action<any>,
): any => {
  switch (action.type) {
    case "GET_SECTION_LEARNING_MATERIAL_SENDING":
      return {
        ...state,
        loading: true,
      };
    case "GET_SECTION_LEARNING_MATERIAL_RECEIVED":
      return {
        ...state,
        loading: false,
        apiErrors: [],
        data: action.payload.learningMaterial,
      };
    case "GET_SECTION_LEARNING_MATERIAL_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_SECTION_LEARNING_MATERIAL_SUBMITTING":
      return {
        ...state,
        submitting: true,
      };
    case "UPDATE_SECTION_LEARNING_MATERIAL_SUCCESS":
      return {
        ...state,
        submitting: false,
        apiErrors: [],
        data: action.payload.learningMaterial,
      };
    case "UPDATE_SECTION_LEARNING_MATERIAL_ERROR":
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors || [] : [],
      };
    default:
      return state;
  }
};

export default sectionLearningMaterialEditState;
