export const LectureQuestionnaireFormat = {
  free: "free",
  single: "single",
  multiple: "multiple",
} as const;

export type LectureQuestionnaireFormat =
  keyof typeof LectureQuestionnaireFormat;

export type LectureQuestionnaire = {
  id: string;
  format: LectureQuestionnaireFormat;
  question: string;
  choices?: ReadonlyArray<string>;
  disabled: boolean;
  deleted: boolean;
};
