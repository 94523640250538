import * as React from "react";
import SubLayout from "../../components/atoms/SubLayout";
import PasswordResetRequestForm from "../../components/molecules/PasswordResetRequestForm";

interface Props {}
interface State {}

class PasswordResetRequestPage extends React.Component<Props, State> {
  render() {
    return (
      <SubLayout>
        <SubLayout.Header />
        <SubLayout.Main>
          <div>
            <SubLayout.Heading>パスワード再設定</SubLayout.Heading>
            <PasswordResetRequestForm />
          </div>
        </SubLayout.Main>
        <SubLayout.Footer />
      </SubLayout>
    );
  }
}

export default PasswordResetRequestPage;
