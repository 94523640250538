import { createAction } from "redux-actions";

import ApiClient from "../../api";
import {
  OperatorPrivilege,
  OperatorPrivilegeInfoInterface,
} from "../../interfaces/OperatorInterface";
import { forbiddenError } from "./errors";

// GET /api/v1/sections/:section_hashid actions
const getOperatorPrivilegesResponseReceived = createAction(
  "GET_OPERATOR_PRIVILEGES_RESPONSE_RECEIVED",
);
const getOperatorPrivilegesResponseError = createAction(
  "GET_OPERATOR_PRIVILEGES_RESPONSE_ERROR",
);

export const checkPrivileges =
  (
    currentPrivileges: OperatorPrivilege[],
    requiredPrivileges: OperatorPrivilege[],
  ) =>
  (dispatch: (action: ReduxActions.Action<any>) => void): void => {
    const missedPrivileges = requiredPrivileges.filter(
      (privilege) => !currentPrivileges.includes(privilege),
    );
    if (missedPrivileges.length > 0) {
      dispatch(forbiddenError());
    }
  };
export const loadOperatorPrivileges =
  (sectionId: string, requiredPrivileges?: OperatorPrivilege[]) =>
  (dispatch: (action: ReduxActions.Action<any>) => void): void => {
    ApiClient.get(`/api/v1/sections/${sectionId}/privileges`)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((json: { operator: OperatorPrivilegeInfoInterface }) => {
              dispatch(getOperatorPrivilegesResponseReceived(json));
              if (requiredPrivileges) {
                checkPrivileges(
                  json.operator.privileges,
                  requiredPrivileges,
                )(dispatch);
              }
            });
        } else if (res.status === 404) {
          dispatch(getOperatorPrivilegesResponseError({ status: 404 }));
        } else {
          dispatch(getOperatorPrivilegesResponseError());
        }
      })
      .catch(() => {
        dispatch(getOperatorPrivilegesResponseError());
      });
  };
