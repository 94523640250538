import truncate from "lodash-es/truncate";

const truncateFilename = (filename: string, length: number): string => {
  if (filename.length <= length) {
    return filename;
  }

  const extension = filename
    .substring(filename.lastIndexOf(".") + 1, filename.length)
    .toLowerCase();
  const filenameWithoutExt = filename.replace("." + extension, "");
  const truncatedFileName = truncate(filenameWithoutExt, {
    length: length - extension.length,
  });

  return truncatedFileName + extension;
};

const FileHelper = {
  truncateFilename,
};

export default FileHelper;
