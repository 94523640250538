import * as React from "react";
import InviteOperatorForm from "../../molecules/InviteOperatorForm/index";
import { SectionOperatorsStateInterface } from "../../../interfaces/SectionOperatorsInterface";
import InvitingOperatorList from "../InvitingOperatorList";
import ActiveOperatorList from "../../molecules/ActiveOperatorList";
import TabsNavi from "../../molecules/TabsNavi";
import Loader from "../../atoms/Loader";
import { OperatorRoleTypes } from "../../../interfaces/OperatorInterface";
import StudentTag from "../../../domains/StudentTag";
import SectionInterface from "../../../interfaces/SectionInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  sectionTags: StudentTag[];
  sectionOperatorsState: SectionOperatorsStateInterface;
  inviteOperator: (
    sectionId: string,
    email: string,
    resetFrom: () => void,
  ) => void;
  deleteInvite: (sectionId: string, inviteId: string) => void;
  deleteOperator: (sectionId: string, operatorId: string) => void;
  changeOperatorRole: (
    sectionId: string,
    operatorId: string,
    role: OperatorRoleTypes,
  ) => void;
  updateOperatorSectionTags: (
    sectionId: string,
    operatorId: string,
    tagIds: string[],
  ) => void;
}

const SectionsSettingsOperators = (props: Props) => {
  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "スタッフ",
            link: `#`,
            active: true,
          },
        ]}
      />
      <InviteOperatorForm
        sectionId={props.section.id}
        submitting={props.sectionOperatorsState.submitting}
        apiErrors={props.sectionOperatorsState.inviteApiErrors}
        inviteOperator={props.inviteOperator}
      />
      <InvitingOperatorList
        sectionId={props.section.id}
        sectionName={props.section.sectionName}
        invitingOperators={props.sectionOperatorsState.invitingOperators}
        deleteInvite={props.deleteInvite}
      />
      <ActiveOperatorList
        section={props.section}
        sectionTags={props.sectionTags}
        currentOperator={props.currentOperator}
        submitting={props.sectionOperatorsState.submitting}
        activeOperators={props.sectionOperatorsState.activeOperators.data}
        deleteOperator={props.deleteOperator}
        changeOperatorRole={props.changeOperatorRole}
        updateOperatorSectionTags={props.updateOperatorSectionTags}
      />
      <Loader loading={props.sectionOperatorsState.loading} />
    </React.Fragment>
  );
};

export default SectionsSettingsOperators;
