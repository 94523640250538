import { useInfiniteQuery } from "@tanstack/react-query";
import ApiResponse from "../../interfaces/ApiResponse";
import ApiClient from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { SectionDrillRetentionLevel } from "../../domains/SectionDrillRetentionLevel";
import {
  LearningMaterialTagList,
  toTagParams,
} from "../../domains/LearningMaterialTag";
import { StudentTagFilterInterface } from "../../domains/StudentTag";
import { buildStudentFilterParams } from "../../helpers/FiltersHelper";

type ResponseData = ApiResponse<SectionDrillRetentionLevel[]>;

type Props = {
  sectionId: string;
  tags: LearningMaterialTagList;
  studentFilter?: StudentTagFilterInterface;
};

const compact = <T extends Record<string, any>>(object: T): Partial<T> => {
  return Object.fromEntries(
    Object.entries(object).filter(([, value]) => value != null),
  ) as Partial<T>;
};

export const useFetchSectionDrillRetentionLevel = ({
  sectionId,
  tags,
  studentFilter,
}: Props) => {
  const { learning_material_tag_ids, have_no_tag } = toTagParams({ tags });
  const studentFilterParams = studentFilter
    ? buildStudentFilterParams(studentFilter)
    : {};
  const { data, ...result } = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(
      sectionId,
      learning_material_tag_ids,
      have_no_tag,
      studentFilterParams,
    ),
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.get(`${baseURLOf(sectionId)}`, {
        query: {
          learning_material_tag_ids,
          have_no_tag,
          page: pageParam,
          ...compact(studentFilterParams),
        },
      });
      if (response.ok) {
        const json = await response.json();
        return json.drillLearningMaterialAnalyticsTable;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  return {
    data: data?.pages.flatMap((page) => page.data),
    ...result,
  };
};

export const cacheKeyOf = (
  sectionId: string,
  learning_material_tag_ids: string[],
  have_no_tag?: string,
  studentFilterParams: Record<string, any> = {},
) =>
  [
    sectionId,
    "analytics",
    "retention_level",
    learning_material_tag_ids,
    have_no_tag,
    studentFilterParams,
  ].filter((k) => k !== undefined);

export const baseURLOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/analytics/drill_learning_materials`;
