import {
  OptionType,
  SelectWrapper,
  SelectWrapperProps,
} from "../../general/SelectWrapper";
import { useFetchExaminationOrganizers } from "../../../hooks/http/useFetchExaminationOrganizers";
import { ExaminationOrganizer } from "../../../domains/ExaminationOrganizer";
import { OnChangeValue } from "react-select";

type Props = {
  size?: SelectWrapperProps["size"];
  fontSize?: SelectWrapperProps["fontSize"];
  placeholder?: SelectWrapperProps["placeholder"];
  sectionId?: string;
  studentId?: string;
  onChange: (value: string | null) => void;
  hasError?: boolean;
  value?: string;
  isDisabled?: boolean;
};

export const ExaminationOrganizerSelect = (props: Props) => {
  const { options, isLoadingOptions, onChange } = useExaminationOrganizerSelect(
    {
      sectionId: props.sectionId,
      studentId: props.studentId,
      onChange: props.onChange,
    },
  );

  return (
    <SelectWrapper
      aria-label="主催者を選択"
      options={options}
      size={props.size}
      fontSize={props.fontSize}
      onChange={onChange}
      placeholder={props.placeholder ?? "主催者を選択"}
      isLoading={isLoadingOptions}
      isClearable
      hasError={props.hasError}
      value={options.find((o) => o.value === props.value)}
      isDisabled={props.isDisabled}
    />
  );
};

const useExaminationOrganizerSelect = (
  props: Pick<Props, "onChange" | "sectionId" | "studentId">,
) => {
  const { data: organizers, isLoading } = useFetchExaminationOrganizers({
    sectionId: props.sectionId,
    studentId: props.studentId,
  });
  const options = organizers ? organizers.map((o) => toOption(o)) : [];
  const onChange = (option?: OnChangeValue<OptionType, false>) => {
    props.onChange(option ? (option.value as string) : null);
  };

  return {
    isLoadingOptions: isLoading,
    options,
    onChange,
  };
};

const toOption = (organizer: ExaminationOrganizer) => {
  return {
    label: organizer.name,
    value: organizer.id,
  };
};
