import { AnalyticsTableData } from "../../../containers/pages/SectionsAnalyticsPage";
import { toHeaderDate } from "../../../helpers/AnalyticsHelper";
import {
  AnalyticsRowInterface,
  AnalyticsTermType,
  AnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";

export const useAnalyticsTableHaeder = (
  analyticsType: AnalyticsType,
  onOrderChange: (
    order: null | string,
    initialOrderDir?: OrderDirFilterType,
  ) => void,
) => {
  const analyticsTypeName = (analyticsType: AnalyticsType): string => {
    switch (analyticsType) {
      case "amount":
        return "学習量";
      case "stay":
        return "滞在時間";
      case "directMessage":
      case "announceMessage":
        return "送信数";
      case "time":
      default:
        return "学習時間";
    }
  };

  const handleSortClick = () => {
    onOrderChange(null);
  };

  const handleStudentIdSortClick = (order: string) => () => {
    onOrderChange(order, "asc");
  };

  const isRestrictedAnalyticsTypeByBillingPlan =
    getIsRestrictedAnalyticsTypeByBillingPlan(analyticsType);
  const hasStudentIdColumnAnalyticsType =
    getHasStudentIdColumnAnalyticsType(analyticsType);

  return {
    analyticsTypeName,
    handleSortClick,
    handleStudentIdSortClick,
    isRestrictedAnalyticsTypeByBillingPlan,
    hasStudentIdColumnAnalyticsType,
  };
};

export const useAnalyticsTableHeaderColumn = (
  analyticsTable: AnalyticsTableData,
  onOrderChange: (order: string) => void,
) => {
  const formatColumn = (column: string): string => {
    const term = (analyticsTable.data?.term ?? "daily") as AnalyticsTermType;
    return toHeaderDate(column, term);
  };

  const handleDateColumnClick = (order: string) => () => {
    onOrderChange(order);
  };

  return {
    formatColumn,
    handleDateColumnClick,
  };
};

export const useAnalyticsTableRow = (
  row: AnalyticsRowInterface,
  analyticsType: AnalyticsType,
) => {
  const isRestrictedAnalyticsTypeByBillingPlan =
    getIsRestrictedAnalyticsTypeByBillingPlan(analyticsType);
  const isScoreRestricted =
    isRestrictedAnalyticsTypeByBillingPlan && row.billingPlan === "free";
  const hasStudentIdColumnAnalyticsType =
    getHasStudentIdColumnAnalyticsType(analyticsType);

  const studentPathname = (
    studentId: string,
    analyticsType: AnalyticsType,
  ): string => {
    switch (analyticsType) {
      case "amount":
        return `/students/${studentId}/analytics/amount`;
      case "stay":
        return `/students/${studentId}/analytics/stay`;
      default:
        return `/students/${studentId}/analytics/time`;
    }
  };

  return {
    isRestrictedAnalyticsTypeByBillingPlan,
    isScoreRestricted,
    hasStudentIdColumnAnalyticsType,
    studentPathname,
  };
};

export const useAnalyticsTableScore = (
  styles: any,
  analyticsTable: AnalyticsTableData,
) => {
  const classNameFromDeviation = (deviation: number, score: number): string => {
    if (score === 0) {
      return styles.level15;
    }
    if (deviation >= 70) {
      return styles.level05;
    } else if (deviation >= 66) {
      return styles.level04;
    } else if (deviation >= 62) {
      return styles.level03;
    } else if (deviation >= 58) {
      return styles.level02;
    } else if (deviation >= 54) {
      return styles.level01;
    } else if (deviation >= 50) {
      return styles.level00;
    } else if (deviation >= 46) {
      return styles.level11;
    } else if (deviation >= 42) {
      return styles.level12;
    } else if (deviation >= 38) {
      return styles.level13;
    } else if (deviation >= 34) {
      return styles.level14;
    } else {
      return styles.level15;
    }
  };

  const calculateDeviation = (score: number) => {
    const calculateSum = () => {
      let sum = 0;

      if (analyticsTable.data) {
        analyticsTable.data.students.forEach((student) => {
          sum += student.scores.reduce(
            (prev: number, current: number) => prev + current,
            0,
          );
        });
      }
      return sum;
    };

    const values = () => {
      const _values: number[] = [];
      if (analyticsTable.data) {
        analyticsTable.data.students.forEach((student) => {
          student.scores.forEach((score) => _values.push(score));
        });
      }
      return _values;
    };

    const calculateAverage = () => {
      return calculateSum() / values().length;
    };

    const calculateVariance = () => {
      const average = calculateAverage();
      let variance = -1;
      let sum = 0;
      for (const value of values()) {
        sum += Math.pow(value - average, 2);
      }
      variance = sum / values().length;
      return variance;
    };

    const calculateStandardDeviation = () => {
      const variance = calculateVariance();
      return Math.sqrt(variance);
    };

    const average = calculateAverage();
    const standardDeviation = calculateStandardDeviation();
    return ((score - average) / standardDeviation) * 10 + 50;
  };

  return {
    classNameFromDeviation,
    calculateDeviation,
  };
};

const getIsRestrictedAnalyticsTypeByBillingPlan = (
  analyticsType: AnalyticsType,
) => ["amount", "time"].includes(analyticsType);

const getHasStudentIdColumnAnalyticsType = (analyticsType: AnalyticsType) =>
  ["amount", "time"].includes(analyticsType);
