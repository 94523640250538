import { LearningMaterialTag } from "../../../domains/LearningMaterialTag";
import TagButton from "../TagButton";

export const LearningMaterialTagButton = ({
  tag,
  isSelected,
  isClickable,
  onClick,
}: {
  tag: LearningMaterialTag;
  isSelected: boolean;
  isClickable: boolean;
  onClick: () => void;
}) => {
  const color = tag.tagType === "custom" ? "light-green" : "light-blue";
  const hoverColor =
    tag.tagType === "custom" ? "light-green-lighten-1" : "light-blue-lighten-1";
  return isClickable ? (
    <TagButton
      label={tag.name}
      variant="solid"
      colorType={isSelected ? color : "gray-lighten-2"}
      hoverVariant="solid"
      hoverColorType={isSelected ? hoverColor : color}
      onClick={onClick}
    />
  ) : (
    <TagButton
      label={tag.name}
      variant="solid"
      colorType={isSelected ? color : "gray-lighten-2"}
      disabled={true}
    />
  );
};

export const SelectedLearningMaterialTagButton = ({
  tag,
  isDeselectable,
  onClick,
}: {
  tag: LearningMaterialTag;
  isDeselectable: boolean;
  onClick: () => void;
}) => {
  const color = tag.tagType === "custom" ? "light-green" : "light-blue";
  return isDeselectable ? (
    <TagButton
      label={tag.name}
      variant="outline"
      colorType={color}
      hoverVariant="solid"
      hoverColorType={color}
      onClick={onClick}
      iconName="icon-close-x"
      bold
    />
  ) : (
    <TagButton
      label={tag.name}
      variant="outline"
      colorType={color}
      disabled={true}
      bold
    />
  );
};
