import * as React from "react";
import { usePreventDefault } from "../../../hooks/usePreventDefault";

const DEFAULT_MAGNIFIER_HEIGHT = 200;
const DEFAULT_MAGNIFIER_WIDTH = 200;
const DEFAULT_MAGNIFIER_ZOOM_LEVEL = 3.0;

interface Props {
  src: string;
  width?: string;
  height?: string;
  magnifierHeight?: number;
  magnifierWidth?: number;
  zoomLevel?: number;
}

export const ImageMagnifier = (props: Props) => {
  const {
    magnifierHeight = DEFAULT_MAGNIFIER_HEIGHT, // 拡大時の枠の高さ
    magnifierWidth = DEFAULT_MAGNIFIER_WIDTH, // 拡大時の枠の幅
    zoomLevel = DEFAULT_MAGNIFIER_ZOOM_LEVEL, // 拡大率
  } = props;

  const [[magnifierX, magnifierY], setMagnifierXY] = React.useState([0, 0]);
  const [[imgWidth, imgHeight], setImgSize] = React.useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = React.useState(false);
  // タブレットで画像をタッチしている間, 画面のスワイプを無効化するためにtouchmoveイベントにprevenntDefaultをセットする
  const ref = usePreventDefault<HTMLImageElement>("touchmove");

  return (
    <div
      style={{
        position: "relative",
        height: props.height,
        width: props.width,
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        ref={ref}
        src={props.src}
        style={{
          height: "auto",
          width: "auto",
          maxHeight: "100%",
          maxWidth: "100%",
        }}
        onPointerEnter={(e) => {
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setImgSize([width, height]);
          setShowMagnifier(true);
        }}
        onPointerMove={(e) => {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();
          const magnifierX = e.pageX - left - window.scrollX;
          const magnifierY = e.pageY - top - window.scrollY;
          setMagnifierXY([magnifierX, magnifierY]);
        }}
        onPointerLeave={() => {
          setShowMagnifier(false);
        }}
        alt={"img"}
      />

      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",
          pointerEvents: "none",
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,
          top: `${magnifierY - magnifierHeight / 2}px`,
          left: `${magnifierX - magnifierWidth / 2}px`,
          opacity: "1",
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${props.src}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,
          backgroundPositionX: `${
            -magnifierX * zoomLevel + magnifierWidth / 2
          }px`,
          backgroundPositionY: `${
            -magnifierY * zoomLevel + magnifierHeight / 2
          }px`,
        }}
      ></div>
    </div>
  );
};
