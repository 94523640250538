import { AnalyticsLectureAttendanceLecture } from "./SectionAnalytics";
import { BillingPlan } from "./BillingPlan";
import { LineConnectionStatusType } from "../interfaces/StudentInterface";
// src/interfaces/StudentInterface と被る所があるが徐々にこちらに移行する
// 現状必要なフィールドだけ記述しているので、こちらも徐々に追加前提
export type StudentStatus =
  | "waiting"
  | "preinviting"
  | "inviting"
  | "active"
  | "inactive";

export type Student = {
  id: string;
  fullName: string;
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  status: StudentStatus;
  jobHuman: string;
  billingPlan?: BillingPlan;
  lineConnectionStatus: LineConnectionStatusType;
};

export type ContentCourseStudent = {
  id: string;
  fullName: string;
  status: StudentStatus;
  statusHuman: string;
  jobHuman: string;
  sharedAt: string | null;
  url: string | null;
  deliveryStatus: "in_delivery" | "scheduled_delivery" | "stop_delivery";
};

export const stoppingDelivery = (student: ContentCourseStudent) =>
  student.deliveryStatus === "stop_delivery";

// TODO: 講座別のアナリティクスと共有できるフィールドが多いので、あとでリファクタする
export type LectureAttendanceStudentAnalytics = {
  lecturePresenceCount: ReadonlyArray<number>;
  lectureTotalCount: ReadonlyArray<number>;
  totalScore: number;
  scores: ReadonlyArray<number>;
  rank: number;
} & Pick<Student, "fullName" | "jobHuman" | "id">;

export type LectureAttendanceStudentAnalyticsTable = Pick<
  AnalyticsLectureAttendanceLecture,
  "columns" | "term"
> & {
  students: ReadonlyArray<LectureAttendanceStudentAnalytics>;
};
