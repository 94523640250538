import StudentsLearningMaterialsStateInterface from "../../interfaces/StudentsLearningMaterialsStateInterface";

const defaultState: StudentsLearningMaterialsStateInterface = {
  data: null,
  loading: false,
  selectedType: "in_progress",
};

const studentsLearningMaterials = (
  state: StudentsLearningMaterialsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): any => {
  switch (action.type) {
    case "GET_STUDENT_LEARNING_MATERIALS":
      return {
        ...state,
        loading: true,
      };
    case "GET_STUDENT_LEARNING_MATERIALS_SUCCESS":
      return {
        ...state,
        data: action.payload.learningMaterials,
        loading: false,
      };
    case "GET_STUDENT_LEARNING_MATERIALS_ERROR":
      return {
        ...state,
        loading: false,
      };

    case "CHANGE_STUDENTS_LEARNING_MATERIALS_BOOKSHELF_TYPE":
      return {
        ...state,
        loading: false,
        selectedType: action.payload,
      };

    default:
      return state;
  }
};

export default studentsLearningMaterials;
