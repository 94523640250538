import * as React from "react";
import styles from "./styles.scss";
import {
  ContentCourse,
  DEFAULT_THUMBNAIL_URL,
} from "../../../../domains/ContentCourse";
import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../../../components/atoms/Flex";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import { DescriptionText } from "../../../../components/atoms/DescriptionText";
import Icon from "../../../../components/atoms/Icon";

type Props = {
  course: ContentCourse;
  isSubmitting: boolean;
  onClickDelete: () => void;
  onClickRegisterTag: () => void;
};

export const CourseSummary: React.FC<Props> = ({
  course,
  isSubmitting,
  onClickDelete,
  onClickRegisterTag,
}) => {
  return (
    <BlockRow marginTop="1.6rem">
      <Flex>
        <div className={styles.course__thumbnail}>
          <img src={course.thumbnailUrl ?? DEFAULT_THUMBNAIL_URL} />
        </div>
        <div>
          <div className={styles.course__name}>{course.name}</div>
          <div className={styles.course__description}>
            <DescriptionText>{course.description}</DescriptionText>
          </div>
        </div>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <div>
          <span className={styles.tag__prefix}>配信対象タグ：</span>
          {course.tag ? (
            <div className={styles.selected_tag}>{course.tag.name}</div>
          ) : (
            <span className={styles["tag__name--undefined"]}>未設定</span>
          )}
        </div>
        <Flex justifyContent="right">
          <div className={styles.tag__btn}>
            <Button
              size="sm"
              variant="outline"
              disabled={!course.tag || isSubmitting}
              onClick={onClickDelete}
            >
              <Icon name="icon-trash" className={styles.icon} />
              配信タグの削除
            </Button>
          </div>
          <div className={styles.tag__btn}>
            <Button
              size="sm"
              variant="outline"
              disabled={isSubmitting}
              onClick={onClickRegisterTag}
            >
              <Icon name="icon-tags" className={styles.icon} />
              配信タグの登録
            </Button>
          </div>
        </Flex>
      </Flex>
    </BlockRow>
  );
};
