import * as React from "react";
import enhanceStudentsPage, {
  StudentsPageProps,
} from "../../hocs/enhanceStudentsPage";
import StudentInterface from "../../interfaces/StudentInterface";
import { useFetchStudentTimeline } from "./useFetchStudentTimeline";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import StudentTimeline from "./StudentTimeline";
import { OutletContextProps } from "../../helpers/RouterHelper";
import StudyRecordInterface from "../../interfaces/StudyRecordInterface";

type Props = StudentsPageProps & { student: StudentInterface };

const StudentsTimelinePage = ({
  setActiveMenu,
  student,
}: Props & OutletContextProps) => {
  React.useEffect(() => {
    setActiveMenu("timeline");
  }, []);

  const studentId = student.id ?? "";
  const {
    data: timeline,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
  } = useFetchStudentTimeline({ studentId });
  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  return (
    <StudentTimeline
      timeline={timeline as StudyRecordInterface[]}
      loading={isLoading || isFetchingNextPage}
    />
  );
};

export default enhanceStudentsPage(StudentsTimelinePage, {
  title: "タイムライン",
});
