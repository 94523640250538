import { RouterProps } from "react-router";

import Loader from "../../../components/atoms/Loader";
import TabsNavi from "../../../components/molecules/TabsNavi";
import ImportHistoryList from "./ImportHistoryList";
import { OutletContextProps, withRouter } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { useEffect } from "react";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useFetchImportHistories } from "./useFetchImportHistories";

interface OwnProps {
  section: SectionInterface;
}

type Props = OwnProps & RouterProps & OutletContextProps;

const SectionsSettingsImportHistoriesPage = (props: Props) => {
  const { section } = props;
  const { showErrorMessage } = useFlashMessage();

  useEffect(() => {
    props.setActiveMenu("import_histories");
  }, []);

  const {
    importHistories,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useFetchImportHistories({ sectionId: section.id });
  useQueryError(error);

  useEffect(() => {
    if (error) {
      showErrorMessage("一括操作履歴が取得できませんでした");
    }
  }, [error]);

  useOnMainScrollAreaScroll(() => {
    if (!isLoading && hasNextPage) {
      fetchNextPage();
    }
  }, [isLoading, hasNextPage, fetchNextPage]);

  return (
    <div>
      <TabsNavi
        tabs={[
          {
            label: "一括操作",
            link: `/sections/${section.id}/settings/import_histories/operations`,
            active: false,
          },
          {
            label: "操作履歴",
            link: `/sections/${section.id}/settings/import_histories`,
            active: true,
          },
        ]}
      />
      <div className={styles.list}>
        {isLoading && importHistories.length === 0 ? null : (
          <ImportHistoryList
            section={section}
            importHistories={importHistories}
          />
        )}
        <Loader loading={isFetchingNextPage} />
      </div>
    </div>
  );
};

const pageInfo = {
  title: "一括操作",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsImportHistoriesPage,
  pageInfo,
  ["import_history:index"],
);

export default withRouter(EnhancedPage);
