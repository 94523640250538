import { Navigate, useMatch } from "react-router";
import NotFoundErrorPage from "../pages/NotFoundErrorPage";

// URLの途中に/が重複している場合は、重複した/を削除したURLへリダイレクトします
//   react-router v6では末尾の/は無視してルーティングされるため、他にマッチするpathが優先してルーティングされます
//    ex. //login -> /login
//    ex. //login/ -> /login
//    ex. /login// -> /login// (末尾の/は無視されるため、/loginへルーティングされURLは /login// のままとなる)
const NoRouteMatch = () => {
  const match = useMatch("*");
  const pathname = match?.pathname;
  if (!pathname) return null;

  const duplicateSlashRegexp = "//+";
  const trailingSlashRegexp = "/+$";
  if (
    new RegExp(duplicateSlashRegexp).test(pathname) ||
    new RegExp(trailingSlashRegexp).test(pathname)
  ) {
    const redirectTo = pathname
      .replace(new RegExp(duplicateSlashRegexp, "g"), "/")
      .replace(new RegExp(trailingSlashRegexp), "");

    return <Navigate to={redirectTo} replace />;
  }

  // /の重複を除外してもマッチするpathがない場合
  return <NotFoundErrorPage />;
};

export default NoRouteMatch;
