import * as React from "react";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addDays from "date-fns/addDays";

const terms = ["daily", "weekly", "monthly"] as const;
export type TermType = (typeof terms)[number];

export type DateRange = ReturnType<typeof useStudyScheduleProgressDateParams>;

export const useStudyScheduleProgressDateParams = (
  params: Record<string, string>,
) => {
  const queryParams = params;

  const type: TermType = React.useMemo(() => {
    const defaultTerm = "daily";
    return (
      terms.some((type) => queryParams.type === type)
        ? queryParams.type
        : defaultTerm
    ) as TermType;
  }, [queryParams.type]);

  const startDate = React.useMemo(() => {
    return getStartDate(type, queryParams.date);
  }, [queryParams.date, type]);

  const endDate = React.useMemo(() => {
    return getEndDate(startDate, type);
  }, [startDate, type]);

  return {
    startDate,
    endDate,
    type,
  };
};

export type StudyScheduleProgressDateParams = ReturnType<
  typeof useStudyScheduleProgressDateParams
>;

const getStartDate = (type: TermType, dateStr?: string): Date => {
  const parsedDate = dateStr && new Date(dateStr);

  if (dateStr && parsedDate && parsedDate.toString() !== "Invalid Date") {
    switch (type) {
      case "monthly":
        return startOfMonth(parsedDate);
      case "weekly":
      case "daily":
      default:
        return parsedDate;
    }
  } else {
    return new Date();
  }
};

const getEndDate = (startDate: Date, type: TermType): Date => {
  switch (type) {
    case "monthly":
      return endOfMonth(startDate);
    case "weekly":
      return addDays(startDate, 6);
    case "daily":
    default:
      return new Date(startDate.toISOString());
  }
};
