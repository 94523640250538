export const EmptyDrillLearningMaterial = (props: { keyword: string }) => {
  const message =
    props.keyword === ""
      ? "教材が登録されていません"
      : "該当の教材がありません";

  return (
    <div className="flex flex-nowrap items-center justify-center py-6 pl-6 pr-7">
      <div className="text-black/50">{message}</div>
    </div>
  );
};
