import StudentInterface, {
  StudentParamsInterface,
} from "../../../interfaces/StudentInterface";
import * as React from "react";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { EllipsisDropdownLink } from "../../../components/general/EllipsisDropdown";
import Icon from "../../../components/atoms/Icon";
import StudentEditModal from "../../../components/molecules/StudentEditModal";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { useEffect } from "react";

export const StudentEditLink = ({
  student,
  updateStudent,
}: {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ApiErrorInterface[]>([]);
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  useEffect(() => {
    if (errors.length > 0) setErrors([]);
  }, [student.id]);

  const { mutate, isPending } = useMutation<void, void, StudentParamsInterface>(
    {
      mutationFn: async (values) => {
        const { response, data, error } = await boronClient.PATCH(
          `/api/v1/students/{student_id}`,
          {
            params: {
              path: { student_id: student.id },
            },
            body: {
              student: {
                ...values,
                school_type: values.school_type || undefined,
                grade: values.grade || undefined,
              },
            },
          },
        );
        if (response.ok && data) {
          updateStudent(data.student);
          setIsOpen(false);
          showSuccessMessage("生徒情報を更新しました");
          return;
        }
        if (response.status === 422) {
          if (error?.errors) setErrors(error.errors);
          showErrorMessage("生徒を更新できませんでした");
          return;
        }
        showErrorMessage(HTTP_ERROR_MESSAGE);
      },
    },
  );

  return (
    <>
      <EllipsisDropdownLink onClick={() => setIsOpen(true)}>
        <Icon name="icon-edit" />
        生徒情報の編集
      </EllipsisDropdownLink>
      <StudentEditModal
        isOpen={isOpen}
        student={student}
        submitting={isPending}
        onSubmit={mutate}
        onRequestClose={() => setIsOpen(false)}
        apiErrors={errors}
      >
        <h2>生徒を編集</h2>
      </StudentEditModal>
    </>
  );
};
