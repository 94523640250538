import * as React from "react";

// eslint-disable-next-line
export const useEffectAvoidFirst = (
  fn: () => void,
  deps: ReadonlyArray<any>,
) => {
  const firstRenderRef = React.useRef(true);
  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      fn();
    }
  }, deps);
};
