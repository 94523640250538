import ApiClient, { GetRequestOptions } from "../api";

export interface PostKarteParamsInterface {
  name: string;
  comment: string;
  karte_attachments_attributes?: File[];
  karte_image_attachments_attributes?: File[];
}

export interface PostKarteCommentParamsInterface {
  karte_comment: {
    name: string;
    comment: string;
  };
}

const StudentKarteApi = {
  interruptGetKartes: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/students/${studentId}/kartes`,
      options,
    );
  },

  getKarte: (
    studentId: string,
    karteId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/students/${studentId}/kartes/${karteId}`,
      options,
    );
  },

  getKartes: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(`/api/v1/students/${studentId}/kartes`, options);
  },

  deleteKarte: (studentId: string, karteId: string): Promise<any> => {
    const path = `/api/v1/students/${studentId}/kartes/${karteId}`;
    return ApiClient.delete(path);
  },

  postKarte: (studentId: string, params: any): Promise<any> => {
    const path = `/api/v1/students/${studentId}/kartes`;
    return ApiClient.sendFormData(path, "POST", params);
  },

  updateKarte: (
    studentId: string,
    karteId: string,
    params: any,
  ): Promise<any> => {
    const path = `/api/v1/students/${studentId}/kartes/${karteId}`;
    return ApiClient.sendFormData(path, "PATCH", params);
  },

  postKarteComment: (
    studentId: string,
    karteId: string,
    body: PostKarteCommentParamsInterface,
  ): Promise<any> => {
    return ApiClient.post(
      `/api/v1/students/${studentId}/kartes/${karteId}/comments`,
      body,
    );
  },

  deleteComment: (
    studentId: string,
    karteId: string,
    commentId: string,
  ): Promise<any> => {
    const path = `/api/v1/students/${studentId}/kartes/${karteId}/comments/${commentId}`;
    return ApiClient.delete(path);
  },

  pinKarte: (studentId: string, karteId: string): Promise<any> => {
    const path = `/api/v1/students/${studentId}/kartes/${karteId}/pin`;
    return ApiClient.post(path);
  },

  deleteKartePin: (studentId: string, karteId: string): Promise<any> => {
    const path = `/api/v1/students/${studentId}/kartes/${karteId}/pin`;
    return ApiClient.delete(path);
  },
  postStudentShare: (
    studentId: string,
    karteId: string,
    message: string,
  ): Promise<Response> => {
    return ApiClient.post(
      `/api/v1/students/${studentId}/kartes/${karteId}/share`,
      { message },
    );
  },
};

export default StudentKarteApi;
