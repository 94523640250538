import * as React from "react";
import styles from "./styles.scss";
import classNames from "classnames";

const ErrorText = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
  const { children, className, ...attributes } = props;

  return (
    <p className={classNames(styles.root, className)} {...attributes}>
      {children}
    </p>
  );
};

export default ErrorText;
