import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../api";
import ApiResponse from "../../interfaces/ApiResponse";
import SectionInterface from "../../interfaces/SectionInterface";
import { createError, HTTPErrors } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { useQueryError } from "../../hooks/http/useQueryError";
import { DigitalLearningMaterialList } from "../../domains/DigitalLearningMaterial";

type UseFetchDigitalLearningMaterialsProps = {
  section: SectionInterface;
};

type Response = ApiResponse<DigitalLearningMaterialList>;

export const useFetchDigitalLearningMaterials = (
  props: UseFetchDigitalLearningMaterialsProps,
) => {
  const cacheKey = toCacheKeyOf({
    sectionId: props.section.id,
  });
  const baseUrl = `/api/v1/sections/${props.section.id}/drill_learning_materials`;
  const result = useInfiniteQuery<Response, HTTPErrors>({
    queryKey: cacheKey,
    queryFn: async ({ pageParam }) => {
      const res = await api.interruptGet(baseUrl, {
        query: {
          page: pageParam,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return json.drillLearningMaterials;
      }
      throw await createError(res);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  useQueryError(result.error);

  const data = result.data?.pages.flatMap((page) => page.data);
  const totalCount = result.data?.pages[0].meta.totalCount ?? 0;

  return {
    ...result,
    data,
    totalCount,
  };
};

export const toCacheKeyOf = ({ sectionId }: { sectionId: string }) => {
  return [`/api/v1/sections/${sectionId}/digital_learning_materials`];
};
