import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import styles from "./styles.scss";
import AnnounceMessageRecipientsModal from "../../../../components/molecules/AnnounceMessageRecipientsModal";
import * as React from "react";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { useFetchRecipients } from "./useFetchRecipients";

export const DestinationLabel = (props: {
  sectionId: string;
  destinationTag: GuardianAnnounceMessageTagInterface;
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { isLoading, data, fetchNextPage, error, hasNextPage } =
    useFetchRecipients({
      sectionId: props.sectionId,
      tag: props.destinationTag,
    });
  useQueryError(error);

  // AnnounceMessageRecipientsModalがReduxのstateを期待しているので、react-queryの結果を変換する
  const recipientsState = React.useMemo(() => {
    return {
      loading: isLoading,
      hasMore: hasNextPage ?? false,
      data: data?.pages.flatMap((page) => page.data) ?? [],
      meta: data?.pages[0]?.meta ?? { currentPage: 0, totalPages: 0 },
    };
  }, [isLoading, hasNextPage, data]);

  return (
    <>
      <span
        className={`${styles.destinationLabel} ${styles.destinationLabel__link}`}
        onClick={handleOpenModal}
      >
        保護者送信先：{props.destinationTag.studentCount}名
      </span>
      <AnnounceMessageRecipientsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        destinationTag={props.destinationTag}
        recipients={recipientsState}
        totalCount={props.destinationTag.studentCount}
        onLoadMore={fetchNextPage}
      />
    </>
  );
};
