import { SectionKarteTemplateNewStateInterface } from "../interfaces/SectionKarteTemplateInterface";

const defaultState: SectionKarteTemplateNewStateInterface = {
  submitting: false,
  apiErrors: [],
};

const sectionKarteTemplateNewState = (
  state: SectionKarteTemplateNewStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "CREATE_SECTIONS_KARTE_TEMPLATE_REQUEST":
      return {
        ...defaultState,
        submitting: true,
      };
    case "CREATE_SECTIONS_KARTE_TEMPLATE_SUCCESS":
      return defaultState;
    case "CREATE_SECTIONS_KARTE_TEMPLATE_ERROR":
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors || [] : [],
      };
    default:
      return state;
  }
};

export default sectionKarteTemplateNewState;
