import styles from "./styles.scss";
import { StudentLearningMaterialsPreRegistrationsStateInterface } from "../../../interfaces/StudentLearningMaterialsPreRegistrationsStateInterface";
import Loader from "../../atoms/Loader/index";
import StudentPreRegisteredLearningMaterialInterface from "../../../interfaces/StudentPreRegisteredLearningMaterialInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import { Link } from "react-router-dom";
import Card from "../../atoms/Card/index";

interface Props {
  student: StudentInterface;
  learningMaterialsState: StudentLearningMaterialsPreRegistrationsStateInterface;
  onDelete: (learningMaterialCode: string) => void;
}

const StudentLearningMaterialsPreRegistrations = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <p className={styles.studentStatus}>
          生徒アプリ連携状況：{props.student.statusHuman}
        </p>
        <Link
          to={`/students/${props.student.id}/learning_materials/pre_registrations/new`}
          className={"buiButtonBase buiButtonVariantFilled"}
        >
          新規登録
        </Link>
      </div>
      {renderLearningMaterials(props)}
    </div>
  );
};

const renderLearningMaterials = (props: Props) => {
  const { data } = props.learningMaterialsState;

  if (!data) {
    return <Loader loading={props.learningMaterialsState.loading} />;
  }

  if (data.length > 0) {
    return (
      <div className={styles.learningMaterials}>
        {data.map(
          (learningMaterial: StudentPreRegisteredLearningMaterialInterface) => {
            return (
              <>
                <Card
                  title={learningMaterial.title}
                  image={learningMaterial.imageUrl}
                  key={`StudentBookshelf-Bookshelf-learningMaterial-${learningMaterial.code}`}
                  showDeleteIcon={true}
                  onDelete={handleDelete(props, learningMaterial.code)}
                />
              </>
            );
          },
        )}
      </div>
    );
  } else {
    return <p>登録済みの教材がありません</p>;
  }
};

const handleDelete = (props: Props, code: string) => () => {
  props.onDelete(code);
};

export default StudentLearningMaterialsPreRegistrations;
