import { useMutation } from "@tanstack/react-query";
import api from "../../../api";

type Props = {
  sectionId: string;
};

type ResultData = {
  isSuccess: boolean;
  value: boolean;
};

export const useMutateAllowGuardianMessage = ({ sectionId }: Props) => {
  return useMutation<ResultData, unknown, boolean, unknown>({
    mutationFn: async (value: boolean) => {
      const res = await api.patch(
        `/api/v1/sections/${sectionId}/guardian_message_settings`,
        {
          section: {
            receive_guardian_messages: value,
          },
        },
      );

      return {
        isSuccess: res.ok,
        value: res.ok ? value : !value,
      };
    },
  });
};
