import * as React from "react";

import { OperatorPrivilege } from "../../../interfaces/OperatorInterface";
import { SectionDetailInterface } from "../../../interfaces/SectionInterface";
import NaviMenuList from "../../atoms/NaviMenuList";
import SidebarLayout from "../../atoms/SidebarLayout";
import NaviBack from "../../molecules/NaviBack";
import NaviMenuListItem from "../../molecules/NaviMenuListItem";
import SettingNaviSectionProfile from "../../molecules/SettingNaviSectionProfile";

interface Props {
  section: SectionDetailInterface;
  activeMenu: string;
  opened: boolean;
  privileges: OperatorPrivilege[];
}

export default class SettingNavi extends React.Component<
  Props,
  Record<string, unknown>
> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <SidebarLayout
        opened={this.props.opened}
        scrollable={true}
        collapsible={false}
      >
        <NaviBack section={this.props.section} />
        <SettingNaviSectionProfile section={this.props.section} />
        <NaviMenuList>
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "students"}
            label="生徒一覧"
            iconName="icon-students"
            link={`/sections/${this.props.section.id}/settings/students`}
            collapsible={false}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "stays"}
            label="入退室管理"
            iconName="icon-door-open"
            link={`/sections/${this.props.section.id}/settings/stays`}
            collapsible={false}
            visible={this.props.privileges.includes("stay:index")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "courses"}
            label="コンテンツ配信"
            iconName="icon-contents-add"
            link={`/sections/${this.props.section.id}/settings/contents`}
            collapsible={false}
            visible={this.props.privileges.includes("contents")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "lectures"}
            label="講座管理"
            iconName="icon-textbooks"
            link={`/sections/${this.props.section.id}/settings/lectures`}
            collapsible={false}
            visible={this.props.privileges.includes("lectures")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "classrooms"}
            label="教室管理"
            iconName="icon-door"
            link={`/sections/${this.props.section.id}/settings/classrooms`}
            collapsible={false}
            visible={this.props.privileges.includes("classrooms")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "teachers"}
            label="講師管理"
            iconName="icon-school-mini"
            link={`/sections/${this.props.section.id}/settings/teachers`}
            collapsible={false}
            visible={this.props.privileges.includes("teachers")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "learning_material"}
            label="教材管理"
            iconName="icon-textbook"
            link={
              this.props.privileges.includes("management_learning_materials")
                ? `/sections/${this.props.section.id}/settings/management_learning_materials`
                : `/sections/${this.props.section.id}/settings/digital_learning_materials`
            }
            collapsible={false}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "staff"}
            label="スタッフ管理"
            iconName="icon-staff"
            link={`/sections/${this.props.section.id}/settings/operators`}
            collapsible={false}
            visible={this.props.privileges.includes(
              "current_section_operator:index",
            )}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "skills"}
            label="アシスタント"
            iconName="icon-notice-bot"
            link={`/sections/${this.props.section.id}/settings/skills`}
            collapsible={false}
            visible={this.props.privileges.includes("assistant:index")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "tags"}
            label="タグ"
            iconName="icon-tags"
            link={`/sections/${this.props.section.id}/settings/tags`}
            collapsible={false}
            visible={this.props.privileges.includes("tag:index")}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "template"}
            label="テンプレート"
            iconName="icon-template"
            link={`/sections/${this.props.section.id}/settings/karte_templates`}
            collapsible={false}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "guardian_message"}
            label="保護者連絡"
            iconName="icon-guardian"
            link={`/sections/${this.props.section.id}/settings/guardian_message`}
            collapsible={false}
            visible={this.props.privileges.includes(
              "guardian_message:settings",
            )}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "import_histories"}
            label="一括操作"
            iconName="icon-batch"
            link={`/sections/${this.props.section.id}/settings/import_histories/operations`}
            collapsible={false}
            visible={this.props.privileges.includes(
              "third_party_application:settings",
            )}
          />
          <NaviMenuListItem
            opened={this.props.opened}
            isActive={this.props.activeMenu === "applications"}
            label="アプリ連携"
            iconName="icon-staff"
            link={`/sections/${this.props.section.id}/settings/applications`}
            collapsible={false}
            visible={this.props.privileges.includes("import_history:index")}
          />
        </NaviMenuList>
      </SidebarLayout>
    );
  }
}
