import { SectionOperatorInvitationStateInterface } from "../interfaces/SectionOperatorsInterface";

const defaultState = {
  loading: false,
  submitting: false,
  data: null,
};

const invitation = (
  state: SectionOperatorInvitationStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_SECTION_OPERATOR_INVITATION_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_SECTION_OPERATOR_INVITATION_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload.sectionOperatorInvitation,
      };
    case "GET_SECTION_OPERATOR_INVITATION_ERRROR":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default invitation;
