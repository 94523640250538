import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";

export const useQueryString = <T = string>(): Record<string, T> => {
  const locationState = useLocation();
  return useMemo(
    () => parse(locationState.search, { arrayFormat: "bracket" }),
    [locationState.search],
  ) as unknown as Record<string, T>;
};
