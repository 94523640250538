import {
  SectionStaysStateInterface,
  StayStateInterface,
} from "../interfaces/StayInterface";
import ApiResponse from "../interfaces/ApiResponse";
import { ApiErrorInterface } from "../interfaces/ApiErrorResponseInterface";

const defaultState: SectionStaysStateInterface = {
  loading: false,
  submitting: false,
  hasMore: false,
  data: [],
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
};

const sectionStaysState = (
  state: SectionStaysStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): SectionStaysStateInterface => {
  switch (action.type) {
    case "GET_SECTION_STAYS_INITIAL_REQUEST":
      return {
        ...defaultState,
        loading: true,
      };
    case "GET_SECTION_STAYS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_SECTION_STAYS_RECEIVED":
      return appendResponseToState(state, action.payload.stays);
    case "UPDATE_SECTION_STAY_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "UPDATE_SECTION_STAY_SUCCESS":
      return replaceResponseToState(state, action.payload.stay);
    case "UPDATE_SECTION_STAY_ERROR": {
      const { stayId, errors } = action.payload;
      return {
        ...state,
        submitting: false,
        data: appendApiErrosToState(state.data, stayId, errors),
      };
    }
    case "DELETE_SECTION_STAY_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "DELETE_SECTION_STAY_SUCCESS":
      return {
        ...state,
        submitting: false,
        data: state.data.filter(
          (stay: StayStateInterface) => stay.id != action.payload.stayId,
        ),
      };
    case "DELETE_SECTION_STAY_ERROR":
      return {
        ...state,
        submitting: false,
      };
    case "GET_SECTION_STAYS_ERROR":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const replaceResponseToState = (
  state: SectionStaysStateInterface,
  payload: StayStateInterface,
) => {
  return {
    ...state,
    submitting: false,
    data: replaceData(state.data, payload),
    apiErrors: [],
  };
};

const replaceData = (
  data: StayStateInterface[],
  newData: StayStateInterface,
) => {
  if (data.find((stay) => stay.id == newData.id)) {
    const idx = data.findIndex((stay) => stay.id == newData.id);
    data.splice(idx, 1, newData);
  }
  return data;
};

const appendResponseToState = (
  state: SectionStaysStateInterface,
  payload: ApiResponse<StayStateInterface[]>,
) => {
  const { data, meta } = payload;
  return {
    ...state,
    loading: false,
    hasMore: meta.currentPage < meta.totalPages,
    data: concatData(state.data, data),
    meta: meta,
  };
};

const appendApiErrosToState = (
  data: StayStateInterface[],
  stayId: string,
  errors: ApiErrorInterface[],
) => {
  if (data.find((stay) => stay.id == stayId)) {
    const idx = data.findIndex((stay) => stay.id == stayId);
    data.splice(idx, 1, { ...data[idx], apiErrors: errors });
  }

  return data;
};

const concatData = (
  prevData: StayStateInterface[],
  data: StayStateInterface[],
) => {
  if (data.length > 0) {
    return prevData.concat(data);
  } else {
    return prevData;
  }
};

export default sectionStaysState;
