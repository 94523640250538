import { Teacher } from "../../domains/Teacher";
import Icon from "../../components/atoms/Icon/index";
import AlignMiddle from "../../components/atoms/AlignMiddle/index";
import LinkButton from "../../components/atoms/LinkButton/index";
import { BlockRow } from "../../components/atoms/BlockRow/index";
import { Text } from "../../components/general/Text/Text";
import { useMutateTeacherArchiveStatus } from "../../hooks/http/useMutateTeacherArchiveStatus";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import {
  TeacherTable,
  TeacherTableRow,
  translateMutateResult,
} from "./TeacherTable";

type Props = {
  sectionId: string;
  teachers: Teacher[];
};

export const ArchivedTeacherTable = ({ sectionId, teachers }: Props) => {
  const onActivate = useActivateTeacher(sectionId);

  if (teachers.length === 0) {
    return (
      <BlockRow marginTop="1.6rem">
        <Text align="center">アーカイブの講師がありません。</Text>
      </BlockRow>
    );
  }

  return (
    <TeacherTable>
      {teachers.map((teacher: Teacher) => (
        <TeacherTableRow teacher={teacher} key={teacher.id}>
          <LinkButton onClick={() => onActivate(teacher.id)}>
            <AlignMiddle>
              <Icon name="icon-archive" />
              アクティブに戻す
            </AlignMiddle>
          </LinkButton>
        </TeacherTableRow>
      ))}
    </TeacherTable>
  );
};

const useActivateTeacher = (sectionId: string) => {
  const archiveStatusMutation = useMutateTeacherArchiveStatus({
    sectionId,
    status: "active",
  });
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const activate = (teacherId: string) => {
    archiveStatusMutation.mutate(teacherId, {
      onSuccess: () => {
        const message = translateMutateResult("active", "success");
        showSuccessMessage(message);
      },
      onError: () => {
        const message = translateMutateResult("active", "failure");
        showErrorMessage(message);
      },
    });
  };

  return activate;
};
