import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../api";
import { cacheKeyOf } from "./useFetchBuildingsApi";
import { Building } from "../../../../domains/Building";
import { createError, HTTPErrors } from "../../../../errors";

type Props = {
  sectionId: string;
};

export const useDeleteBuildingApi = ({ sectionId }: Props) => {
  const client = useQueryClient();

  return useMutation<void, HTTPErrors, Building, unknown>({
    mutationFn: async (building: Building) => {
      const res = await api.delete(
        `/api/v1/sections/${sectionId}/buildings/${building.id}`,
      );

      if (res.ok) {
        return;
      }

      throw await createError(res);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: cacheKeyOf(sectionId) });
    },
  });
};
