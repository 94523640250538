import { createAction } from "redux-actions";
import StudentTag from "../../../domains/StudentTag";
import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import SectionTagApi from "../../../api-clients/SectionTagApi";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
} from "../../flashMessage";
import { apiRequestError } from "../../apiRequestError";
import { TagFormInterface } from "../../../interfaces/TagFormInterface";

export const initializePage = createAction("sections/settings/tags/INIT");
const createTagRequest = createAction<void>(
  "sections/settings/CREATE_TAG_REQUEST",
);
const createTagSuccess = createAction<{
  tag: StudentTag;
  sectionId: string;
}>("sections/settings/CREATE_TAG_SUCCESS");
const createTagFailure = createAction("sections/settings/CREATE_TAG_FAILURE");
export const createTag =
  (
    sectionId: string,
    params: TagFormInterface,
    callBack: (type: "create", tag: StudentTag) => void,
  ): ThunkAction<
    void,
    Record<string, never>,
    Record<string, never>,
    AnyAction
  > =>
  (dispatch: Dispatch) => {
    dispatch(createTagRequest());
    SectionTagApi.createTag(sectionId, params)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: { tag: StudentTag }) => {
            const createdTag: StudentTag = {
              ...json.tag,
              type: "tag_ids",
            };
            dispatch(createTagSuccess({ tag: createdTag, sectionId }));
            dispatch(showSuccessFlashMessage("タグを作成しました"));
            callBack("create", createdTag);
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(createTagFailure(json));
            dispatch(showErrorFlashMessage("タグを作成できませんでした"));
          });
        } else {
          dispatch(createTagFailure());
          dispatch(showErrorFlashMessage("タグを作成できませんでした"));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(createTagFailure());
        dispatch(apiRequestError());
      });
  };

const updateTagRequest = createAction<void>(
  "sections/settings/UPDATE_TAG_REQUEST",
);
const updateTagSuccess = createAction<{
  tag: StudentTag;
  sectionId: string;
}>("sections/settings/UPDATE_TAG_SUCCESS");
const updateTagFailure = createAction("sections/settings/UPDATE_TAG_FAILURE");
export const updateTag =
  (
    sectionId: string,
    tagId: string,
    params: TagFormInterface,
    callBack: (type: "update", tag: StudentTag) => void,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(updateTagRequest());
    SectionTagApi.updateTag(sectionId, tagId, params)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: { tag: StudentTag }) => {
            dispatch(updateTagSuccess({ ...json, sectionId }));
            dispatch(showSuccessFlashMessage("タグを更新しました"));
            callBack("update", json.tag);
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(updateTagFailure(json));
            dispatch(showErrorFlashMessage("タグを更新できませんでした"));
          });
        } else {
          dispatch(updateTagFailure());
          dispatch(showErrorFlashMessage("タグを更新できませんでした"));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(updateTagFailure());
        dispatch(apiRequestError());
      });
  };

const deleteTagRequest = createAction<void>(
  "sections/settings/DELETE_TAG_REQUEST",
);
const deleteTagSuccess = createAction<{ sectionId: string; tagId: string }>(
  "sections/settings/DELETE_TAG_SUCCESS",
);
const deleteTagFailure = createAction("sections/settings/DELETE_TAG_FAILURE");
export const deleteTag =
  (
    sectionId: string,
    tagId: string,
    callBack: (type: "delete", tag: StudentTag) => void,
  ) =>
  (dispatch: Dispatch): void => {
    dispatch(deleteTagRequest());
    SectionTagApi.deleteTag(sectionId, tagId)
      .then((res: Response) => {
        if (res.ok) {
          dispatch(deleteTagSuccess({ tagId, sectionId }));
          dispatch(showSuccessFlashMessage("タグを削除しました"));
          const dummyTag: StudentTag = {
            id: tagId,
            name: "",
            type: "tag_ids",
            enabled: false,
          };
          callBack("delete", dummyTag);
        } else if (res.status === 422) {
          dispatch(deleteTagFailure());
          dispatch(
            showErrorFlashMessage(
              "アシスタントの集計対象として使用中のためタグを削除できません",
            ),
          );
        } else {
          dispatch(deleteTagFailure());
          dispatch(showErrorFlashMessage("タグを削除できませんでした"));
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(deleteTagFailure());
        dispatch(apiRequestError());
      });
  };
