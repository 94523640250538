import { Button } from "@studyplus/boron-ui";
import ErrorText from "../../../components/atoms/ErrorText";
import { Flex } from "../../../components/atoms/Flex";
import Icon from "../../../components/atoms/Icon";
import Input from "../../../components/atoms/Input";
import Label from "../../../components/atoms/Label";
import styles from "./styles.scss";
import { CourseShareFormProps, useCourseShareForm } from "./useCourseShareForm";

type Props = CourseShareFormProps;

export const CourseShareForm = (props: Props) => {
  const form = useCourseShareForm(props);

  return (
    <form onSubmit={form.handleSubmit}>
      <Label isMute={true}>コースコードの入力</Label>
      <Input
        placeholder="コースコードを入力"
        name="code"
        value={form.values.code}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        hasError={form.touched["code"] && Boolean(form.errors["code"])}
      />
      <ErrorText>{form.touched["code"] && form.errors["code"]}</ErrorText>
      <Flex justifyContent="space-between" marginTop="2.4rem">
        <div className={styles.notice}>
          <Icon name="icon-attention" className={styles.attentionIcon} />
          <p>
            作成元の他教室側がコースの削除を行った場合、共有をされた教室側のコースも消去されますのでご了承ください。
          </p>
        </div>
        <div>
          <Button
            type="submit"
            className={styles.submitButton}
            disabled={!form.isValid}
            isLoading={form.isSubmitting}
          >
            登録
          </Button>
        </div>
      </Flex>
    </form>
  );
};
