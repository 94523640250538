import { HeaderLectureSession } from "../../../domains/LectureStudentAnalytics";
import {
  formatMonthDay,
  ONEDAY_IN_MSEC,
  toTimeString,
} from "../../../helpers/TimeHelper";

type Props = Pick<HeaderLectureSession, "allday" | "startAt" | "endAt">;
export const StartEndTitleHeader = ({ allday, startAt, endAt }: Props) => {
  const startDate = formatMonthDay(startAt);
  const timeSpan = `${toTimeString(startAt)} - ${toTimeString(endAt)}`;

  if (allday && endAt.getTime() - startAt.getTime() > ONEDAY_IN_MSEC) {
    return (
      <>
        {startDate}〜<br />
        {makeEndDateForAllDay(endAt)}
      </>
    );
  }
  if (allday) {
    return <>{makeEndDateForAllDay(endAt)}</>;
  }
  return (
    <>
      {startDate}
      <br />
      {timeSpan}
    </>
  );
};

const makeEndDateForAllDay = (endAt: Date) => {
  const date = new Date();
  date.setTime(endAt.getTime() - ONEDAY_IN_MSEC);
  return formatMonthDay(date);
};
