export type GuardianEmail = {
  id: string;
  email: string;
  confirmed: boolean;
  confirmationSentAt: string;
};

// サーバー側でのemailの復号コストを気にして、emailを省いた型
export type GuardianEmailStatus = Omit<GuardianEmail, "email">;

// メール登録状況を日本語で表現
export const translateGuardianEmailStatuses = (
  emailStatuses?: GuardianEmailStatus[],
) => {
  if (confirmedAll(emailStatuses)) {
    return "認証済";
  }

  return emailStatuses !== undefined && emailStatuses.length > 0
    ? "未認証あり"
    : "未登録";
};

export const confirmedAll = (emailStatuses?: GuardianEmailStatus[]) => {
  return (
    emailStatuses !== undefined &&
    emailStatuses.length > 0 &&
    emailStatuses.every((emailStatus) => emailStatus.confirmed)
  );
};

// メール認証状態を日本語で表現
export const translateGuardianEmailStatus = (
  emailStatus: GuardianEmailStatus,
) => {
  return emailStatus.confirmed ? "認証済み" : "認証待ち";
};

// 登録できる保護者メールアドレスの上限
export const GUARDIAN_EMAILS_LIMIT = 2;
