import * as React from "react";
import { createComponent } from "../../../helpers/React";
import Icon from "../../atoms/Icon";
import styles from "./Breadcrumbs.styles.scss";

export type BreadcrumbItem = {
  label: string;
  url?: string;
};

type BreadcrumbList = readonly BreadcrumbItem[];

export type Props = {
  items: BreadcrumbList;
  children: (item: BreadcrumbItem, isLast: boolean) => React.ReactNode;
};

export const Breadcrumbs = ({ items, children }: Props) => {
  const itemSize = items.length;
  if (itemSize === 0) {
    return null;
  }

  return (
    <nav>
      <ol className={styles.Breadcrumbs}>
        {items.map((item, i) => {
          if (i === itemSize - 1) {
            return (
              <li
                key={`BreadcrumbItem-${i}`}
                className={styles.BreadcrumbLastItemWrapper}
                aria-current="page"
              >
                {children(item, true)}
              </li>
            );
          }
          return (
            <li
              className={styles.BreadcrumbItemWrapper}
              key={`BreadcrumbItem-${i}`}
            >
              {children(item, false)}
              <Separator />
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

const Separator = () => {
  return (
    <span className={styles.Separator}>
      <Icon name="icon-arrow-right" color="gray-darken-2" />
    </span>
  );
};

export const BreadCrumbItem = createComponent<"span">({
  tag: "span",
  className: styles.BreadcrumbItem,
});
