import * as React from "react";
import styles from "./styles.scss";

type Props = {
  isRestricted: boolean;
  canSendComment: boolean;
  comment: string;
  onChangeComment: React.Dispatch<React.SetStateAction<string>>;
};

export const TextArea = ({
  isRestricted,
  canSendComment,
  comment,
  onChangeComment,
}: Props) => {
  if (canSendComment && !isRestricted) {
    return (
      <textarea
        placeholder={"コメントを入力"}
        className={styles.textarea}
        rows={1}
        onChange={(e) => onChangeComment(e.target.value)}
        value={comment}
      />
    );
  } else {
    const placeholder = isRestricted
      ? "プロプランにすると学習記録にコメントができます。"
      : "連携をしていない生徒のため、コメントできません";

    return (
      <textarea
        placeholder={placeholder}
        className={styles.textarea}
        rows={1}
        disabled={true}
      />
    );
  }
};
