import { Student } from "../../../../domains/Student";
import { useScrollWatcher } from "../../../../hooks/useScrollWatcher";
import Table, { TableColumn } from "../../../../components/atoms/Table";
import styles from "./DistributeTagStudentsTable.scss";
import { useFetchSectionStudents } from "../../../../hooks/http/useFetchSectionStudents";
import Icon from "../../../../components/atoms/Icon";
import { Flex } from "../../../../components/atoms/Flex";
import Loader from "../../../../components/atoms/Loader";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import classnames from "classnames";

export type UseStudentsProps = {
  sectionId: string;
  tagId: string | null;
};

export const useDistributeTagStudents = ({
  sectionId,
  tagId,
}: UseStudentsProps) => {
  const fetchStudentsResult = useFetchSectionStudents({
    sectionId: sectionId,
    params: tagId ? { tag_ids: [tagId], status: ["active"] } : null,
    enabled: tagId !== null,
  });

  useQueryError(fetchStudentsResult.error);

  const data =
    tagId && fetchStudentsResult.data ? fetchStudentsResult.data : null;

  return {
    fetch,
    data,
    isLoading: fetchStudentsResult.isInitialLoading,
    isFetching: fetchStudentsResult.isFetching,
    fetchNextPage: fetchStudentsResult.fetchNextPage,
  };
};

type StudentTableProps = {
  hidden: boolean;
  students: readonly Student[] | null;
  onFetchNextPage: () => void;
  isFetching: boolean;
};

export const DistributeTagStudentsTable = ({
  onFetchNextPage,
  hidden,
  isFetching,
  ...props
}: StudentTableProps) => {
  const onScroll = useScrollWatcher({ onReachToBottom: onFetchNextPage });
  if (hidden) {
    return null;
  }
  const tableClassName = classnames(styles.studentsTable, {
    [styles["studentTable--bottomBorder_none"]]:
      props.students === null || props.students.length === 0,
  });

  return (
    <div className={styles.scrollView__scroller} onScroll={onScroll}>
      <Table
        headSticky
        className={tableClassName}
        noOutlineBorder
        headers={tableHeaders}
        headColumnClassName={styles.headColumn}
        rows={renderRows(props)}
      />
      <div className={styles.studentsTable__loader} hidden={!isFetching}>
        <Loader loading={isFetching} />
      </div>
    </div>
  );
};
const tableHeaders = [
  { key: "studentName", text: "氏名", centering: true },
  { key: "job", text: "職業（学年）", centering: false },
];

type TableRowsProps = Pick<StudentTableProps, "students">;

const renderRows = (props: TableRowsProps) => {
  if (!props.students) {
    return [
      <tr key="no-tag">
        <TableColumn className={styles.studentsTable__caution} colSpan={2}>
          タグが設定されていません
        </TableColumn>
      </tr>,
    ];
  }
  if (props.students.length === 0) {
    return [
      <tr key="no-students">
        <TableColumn className={styles.studentsTable__caution} colSpan={2}>
          設定されたタグに生徒が存在しません
        </TableColumn>
      </tr>,
    ];
  }
  return props.students.map((student) => (
    <tr key={`student-${student.id}`}>
      <TableColumn className={styles.studentsTable__nameColumn}>
        <Flex marginTop="0" alignItems="center">
          <div>
            <Icon
              className={styles.studentsTable__icon}
              name="icon-notice-user"
            />
          </div>
          <div className={styles.studentsTable__fullName}>
            {student.fullName}
          </div>
        </Flex>
      </TableColumn>
      <TableColumn>{student.jobHuman}</TableColumn>
    </tr>
  ));
};
