import * as React from "react";
import { useInView } from "react-intersection-observer";

/**
 * DOMの構造上ScrollWatcherが使えないときに、
 * 特定のoverflow=auto,scrollなコンテンツが末尾までスクロールされた時のイベント
 */
type UseOnScrollContentBottomProps = {
  rootContentWithScroll: HTMLDivElement | null;
  onScrollContentBottom: () => void;
};

export const useOnScrollContentBottom = ({
  onScrollContentBottom,
  rootContentWithScroll,
}: UseOnScrollContentBottomProps) => {
  const inViewTrackerRef = React.useRef(false);
  const { ref, inView, entry } = useInView({
    root: rootContentWithScroll,
  });

  React.useEffect(() => {
    // 末尾までスクロールした時コールバックは一度だけ実行する
    if (!inViewTrackerRef.current && inView) {
      onScrollContentBottom();
      inViewTrackerRef.current = true;
    }
  }, [inView]);

  React.useEffect(() => {
    // 末尾のエレメントが更新された時(APIのレスポンスによりエレメントが追加された時)
    // コールバックのストッパーをリセット
    if (entry?.target) {
      inViewTrackerRef.current = false;
    }
  }, [entry?.target]);

  return {
    refLastElement: ref,
  };
};
