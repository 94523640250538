import * as React from "react";
import styles from "./styles.scss";
import classnames from "classnames";

type Props = {
  hasError?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const TimeInput = ({
  hasError,
  ...props
}: Props): React.ReactElement => {
  const classes = classnames(styles.input, { [styles.error]: hasError });
  return <input type="time" className={classes} {...props} />;
};
