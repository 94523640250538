export type SchoolType =
  | "unknown"
  | "junior_high_school" // 中学校
  | "high_school" // 高校
  | "technical_college" // 高専
  | "university" // 大学
  | "graduate_school" // 大学院
  | "junior_college" // 短大
  | "carrer_college" // 専門学校
  | "high_school_graduate" // 高卒生
  | "working" // 社会人
  | "elementary_school"; // 小学校

export const SchoolTypeNames: { [key: string]: string } = {
  unknown: "未設定",
  junior_high_school: "中学校",
  high_school: "高校",
  technical_college: "高専",
  university: "大学",
  graduate_school: "大学院",
  junior_college: "短大",
  carrer_college: "専門学校",
  high_school_graduate: "高卒生",
  working: "社会人",
  elementary_school: "小学校",
};
