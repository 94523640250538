import AppStateInterface from "../../interfaces/AppStateInterface";
import SectionTagApi from "../../api-clients/SectionTagApi";
import { initializeTagFilterError, initializeTagFilter } from "./filters";
import { initializeAnnounceDestinationTagOptionsSuccess } from "../pages/sectionAnnounceMessages/selector";
import { Dispatch } from "redux";
import { buildMainResourceApiErrorAction } from "./errors";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";

//
// 校舎のタグをAPIから読み込み,
// sectionTagsとfiltersとannounceDestinationTagOptionsにセットするためのアクション
// タグは複数のページで異なる形に整形して利用する必要があるが、APIリクエストは1回で済ませたいので
// すべてこちらのthunkからdispatchしています
//
export const initializeSectionTags =
  (
    sectionId: string,
    studentFilterContext: ReturnType<typeof useStudentFilterContext>,
  ) =>
  (dispatch: Dispatch, getState: () => AppStateInterface): void => {
    dispatch(initializeTagFilter({ sectionId }));

    const { session } = getState();

    if (!session.currentOperator) {
      return;
    }

    SectionTagApi.getAllTags(sectionId)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            const tags = json.allTags;

            // filtersにセット
            studentFilterContext.setFetchedAllStudentTags({
              tags: tags.data,
            });

            // sectionAnnounceMessages.announceDestinationTagOptionsにセット
            dispatch(initializeAnnounceDestinationTagOptionsSuccess(tags));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(initializeTagFilterError());
        }
      })
      .catch(() => {
        dispatch(initializeTagFilterError());
      });
  };
