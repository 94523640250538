import { RRule, Frequency, Weekday, WeekdayStr } from "rrule";
import format from "date-fns/format";

export type Recurrence = {
  frequency: string;
  byDay: ReturnType<typeof toByWeekday> | null;
  byMonthDay: string | null;
  interval: number;
  until: Date | null;
};

// rruleライブラリのbyweekdayが期待する型に変換する
export const toByWeekday = (byDay: string): Weekday | Weekday[] => {
  const byDayArr = byDay.split(",");

  if (byDayArr.length === 1) {
    const day = toWeekday(byDayArr[0]);
    return day;
  }

  const byDays = byDayArr.map((d: string) => {
    return toWeekday(d);
  });

  return byDays;
};

export type RequestBody = {
  frequency: string;
  by_day: string | null;
  by_month_day: string | null;
  interval: number;
  until: string | null;
};
export const toJson = (recurrence: Recurrence): RequestBody => ({
  frequency: recurrence.frequency,
  by_day: recurrence.byDay ? toByDay(recurrence.byDay) : null,
  by_month_day: recurrence.byMonthDay,
  interval: recurrence.interval,
  until: recurrence.until ? recurrence.until.toISOString() : null,
});

//
// 表示のバリエーションは以下
// - 毎日
// - 毎週 X曜日
// - 毎月 第YX曜日
// - 毎月 Z日
// - 毎週 平日
export const formatRecurrence = (
  recurrence: Recurrence | null,
  hideUntil?: boolean,
): string => {
  if (recurrence === null) {
    return "繰り返さない";
  }
  const rrule = new RRule({
    freq: FREQUENCIES.indexOf(recurrence.frequency),
    interval: recurrence.interval,

    // NOTE: 表示状はいつまでかの表示はしないので、untilは入れない
    // until: recurrence.until,

    bymonthday: recurrence.byMonthDay
      ? recurrence.byMonthDay.split(",").map((n: string) => parseInt(n))
      : null,
    byweekday: recurrence.byDay,
  });

  // rruleライブラリのテキストを日本語化するための設定
  // https://github.com/jakubroztocil/rrule#rruleprototypetotextgettext-language
  const japanese = {
    dayNames: [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ],
    monthNames: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    tokens: {},
  };

  const japaneseStrings = {
    // FREQ=YEARLY;INTERVAL=1;BYMONTH=12;BYMONTHDAY=21 => "every December on the 21st" になってしまうため
    every: rrule.options.freq === Frequency.YEARLY ? "毎年" : "毎",
    day: "日",
    week: "週",
    weeks: "週",
    month: "月",
    years: "年",
    year: "年",
    weekday: "週平日",
    nth: "日",
    on: " ",
    the: " ",
    "on the": " ",
    and: " ",
    th: "第",
    st: "第",
    nd: "第",
    rd: "第",
  };

  const getText = (id: string | number | Weekday): string => {
    if (typeof id === "string") {
      return (japaneseStrings as any)[id] || id;
    } else if (typeof id === "number") {
      return (japaneseStrings as any)[id] || id;
    } else {
      return id.toString();
    }
  };

  let rruleText = rrule.toText(getText, japanese).replace(/\s/g, "");
  rruleText = tweakRRuleText(rruleText);

  if (recurrence.until && !hideUntil) {
    rruleText += format(recurrence.until, "（yyyy/MM/ddまで）");
  }

  return rruleText;
};

// RRuleは0~6のenumで表現されるので、
// FREQUENCIES[Frequency.YEARLY] のようにして文字列表現を得られるようにしておく
const FREQUENCIES = [
  "yearly",
  "monthly",
  "weekly",
  "daily",
  "hourly",
  "minutely",
  "secondly",
];

// 1日分の曜日文字列をWeekdayに変換
// "1MO" -> Weekday { weekday: 0, n: 1}
const toWeekday = (d: string): Weekday => {
  const matched = d.match(/^([+-]?\d{1,2})([A-Z]{2})$/);
  if (matched) {
    const n = parseInt(matched[1]);
    const day = matched[2] as WeekdayStr;
    return Weekday.fromStr(day).nth(n);
  } else {
    return Weekday.fromStr(d as WeekdayStr);
  }
};

// 日本語的に語順がおかしい部分などを微調整
const tweakRRuleText = (recurrenceText: string) => {
  return (
    recurrenceText
      // 文末の第は日に変換
      .replace(/第$/, "日")
      // 文中の第は数字と順番を入れ替える
      .replace(/\d第/, (substring: string) => {
        return substring[1] + substring[0];
      })
      // スペースを挿入
      .replace(/(毎年|毎日|毎週|毎月)/, (substring: string) => substring + " ")
  );
};

// RRuleのWeekday -> APIリクエスト用の文字列へ変換
const toByDay = (byWeekday: ReturnType<typeof toByWeekday>): string => {
  return Array.isArray(byWeekday)
    ? byWeekday.map((bwd) => bwd.toString()).join(",")
    : byWeekday.toString().replace(/\+/g, ""); // ライブラリが曜日の前の第n週の番号に"+"を付与してきていて、これがサーバー側でエラーになるため
};

export const createMonthly = (date: Date, until: Date | null): Recurrence => ({
  frequency: "monthly",
  byDay: null,
  byMonthDay: String(date.getDate()),
  interval: 1,
  until,
});

const rruleDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
export const createWeekly = (date: Date, until: Date | null): Recurrence => {
  const rruleDay = rruleDays[date.getDay()];
  return {
    frequency: "weekly",
    byDay: toByWeekday(rruleDay),
    byMonthDay: null,
    interval: 1,
    until,
  };
};

export const createIndexedWeekDay = (
  date: Date,
  until: Date | null,
): Recurrence => {
  const rruleDay = rruleDays[date.getDay()];
  // http://snowsunny.hatenablog.com/entry/2014/01/18/214751
  const index = Math.floor((date.getDate() - 1) / 7) + 1;
  return {
    frequency: "monthly",
    byDay: toByWeekday(`${index}${rruleDay}`),
    byMonthDay: null,
    interval: 1,
    until,
  };
};

export const createWeekDays = (until: Date | null): Recurrence => ({
  frequency: "weekly",
  byDay: toByWeekday("MO,TU,WE,TH,FR"),
  byMonthDay: null,
  interval: 1,
  until,
});

export const createDaily = (until: Date | null): Recurrence => ({
  frequency: "daily",
  byDay: null,
  byMonthDay: null,
  interval: 1,
  until,
});

const isWeekDays = (recurrence: Recurrence) => {
  if (recurrence.frequency !== "weekly" || !Array.isArray(recurrence.byDay)) {
    return false;
  }
  const byDaySet = new Set(recurrence.byDay.map((w) => w.getJsWeekday()));
  // 土曜と日曜
  if (byDaySet.has(0) || byDaySet.has(6)) {
    return false;
  }
  return true;
};

const isIndexedWeekDay = (recurrence: Recurrence) =>
  recurrence.frequency === "monthly" && recurrence.byDay;

const isMonthly = (recurrence: Recurrence) =>
  recurrence.frequency === "monthly" && recurrence.byMonthDay;

type ChangeStartDateParams = {
  currentReucurrence: Recurrence;
  startDate: Date;
};

export const changeStartDate = ({
  currentReucurrence,
  startDate,
}: ChangeStartDateParams) => {
  // weekdaysとdailyの場合は開始日が変わるだけなので、何もしない
  if (
    currentReucurrence.frequency === "daily" ||
    isWeekDays(currentReucurrence)
  ) {
    return currentReucurrence;
  }
  if (isMonthly(currentReucurrence)) {
    return createMonthly(startDate, currentReucurrence.until);
  }

  if (isIndexedWeekDay(currentReucurrence)) {
    return createIndexedWeekDay(startDate, currentReucurrence.until);
  }

  if (currentReucurrence.frequency === "weekly") {
    return createWeekly(startDate, currentReucurrence.until);
  }

  return currentReucurrence;
};

export const RecurringOptions = [
  "daily",
  "weekly",
  "weekdays",
  "indexedWeekDay",
  "monthly",
] as const;
export type RecurringOptions = (typeof RecurringOptions)[number];

export const determineOption = (recurrence: Recurrence): RecurringOptions => {
  if (recurrence.frequency === "daily") {
    return "daily";
  }
  if (isWeekDays(recurrence)) {
    return "weekdays";
  }
  if (isMonthly(recurrence)) {
    return "monthly";
  }
  if (isIndexedWeekDay(recurrence)) {
    return "indexedWeekDay";
  }
  return "weekly";
};

type CreateFromOptionAndStartDateParams = {
  selectedOption: RecurringOptions;
  startDate: Date;
  until: Date | null;
};
export const createFromOptionAndStartDate = ({
  selectedOption,
  startDate,
  until,
}: CreateFromOptionAndStartDateParams) => {
  switch (selectedOption) {
    case "daily": {
      return createDaily(until);
    }
    case "weekly": {
      return createWeekly(startDate, until);
    }
    case "weekdays": {
      return createWeekDays(until);
    }
    case "indexedWeekDay": {
      return createIndexedWeekDay(startDate, until);
    }
    case "monthly": {
      return createMonthly(startDate, until);
    }
  }
};

export const isChangeableToEmptyRecurrence = (
  currentRecurrence: Recurrence | null,
) => currentRecurrence === null;
