import * as React from "react";
import { Link } from "react-router-dom";

import TimeHelper from "../../../helpers/TimeHelper";
import { MessageNotificationInterface } from "../../../interfaces/NotificationInterface";
import CircleIcon from "../../atoms/CircleIcon";
import HeaderNotificationItemRow from "../../atoms/HeaderNotificationItemRow";
import styles from "./styles.scss";

interface Props {
  message: MessageNotificationInterface;
}

const messagePath = (studentId: string): string => {
  return `/students/${studentId}/messages`;
};

const HeaderBotMessageNotificationItem = (props: Props): React.ReactElement => {
  const { message } = props;

  return (
    <HeaderNotificationItemRow highlighted={!message.isRead} key={message.id}>
      <Link to={messagePath(message.studentId)} className={styles.root}>
        <div className={styles.left}>
          <CircleIcon
            iconProps={{
              name: "icon-notice-assistant",
              fileType: "png",
              iconClassName: styles.icon__user,
            }}
            className={styles.icon__user}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{message.studentFullName}</div>
          <div className={styles.datetime}>
            {TimeHelper.japaneseFormatFullDateTime(props.message.sentAt)}
          </div>
          <div className={styles.content}>
            <div className={styles.content__text}>
              「<span className={styles.skill_name}>{message.content}</span>
              」に該当しました。
            </div>
          </div>
        </div>
      </Link>
    </HeaderNotificationItemRow>
  );
};

export default HeaderBotMessageNotificationItem;
