import * as React from "react";
import Icon from "../../../components/atoms/Icon";
import styles from "./AddQuestionnaireButton.scss";
type AddQuestionnaireButtonProps =
  React.ButtonHTMLAttributes<HTMLButtonElement>;

export const AddQuestionnaireButton = ({
  ...props
}: AddQuestionnaireButtonProps) => {
  return (
    <button className={styles.addQuestionnaireButton} {...props} type="button">
      <Icon name="icon-plus-tag" />
      <span className={styles.text}>質問項目を追加</span>
    </button>
  );
};
