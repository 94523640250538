import { useLayoutEffect, useRef } from "react";
import * as React from "react";
import { HandleThunkActionCreator } from "react-redux";

import { loadMoreSectionAnnounceMessages } from "../../../actions/pages/sectionAnnounceMessages/messageList";
import TimeHelper from "../../../helpers/TimeHelper";
import { AnnounceMessage } from "../../../interfaces/MessageInterface";
import {
  AnnounceDestinationTagWithFormStateInterface,
  AnnounceMessagesStateInterface,
} from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import CircleIcon from "../../atoms/CircleIcon";
import Loader from "../../atoms/Loader";
import styles from "./styles.scss";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
const reactStringReplace = require("react-string-replace");
const nl2br = require("react-nl2br");

interface Props {
  destinationTag: AnnounceDestinationTagWithFormStateInterface;
  messagesState: AnnounceMessagesStateInterface;
  loadMoreSectionAnnounceMessages: HandleThunkActionCreator<
    typeof loadMoreSectionAnnounceMessages
  >;
  section: SectionInterface;
  onOpenModal: (message: AnnounceMessage) => void;
}

const AnnounceMessageList: React.FC<Props> = (props: Props) => {
  const { messagesState } = props;

  const scroller = useRef<HTMLDivElement | null>(null);
  const scrollerHeight = useRef<number>(0);
  const loadedMessages = useRef<Props["messagesState"]["data"]>([]);

  useLayoutEffect(() => {
    const initializing = loadedMessages.current === null;
    if (scroller.current && initializing) {
      scroller.current.scrollTop = scroller.current.scrollHeight;
    }
    if (scroller.current && !initializing) {
      if (
        loadedMessages.current &&
        loadedMessages.current[0] &&
        messagesState.data &&
        messagesState.data[0] &&
        loadedMessages.current[0].id === messagesState.data[0].id
      ) {
        // 追加読込を行った時
        scroller.current.scrollTop =
          scroller.current.scrollHeight - scrollerHeight.current;
      } else {
        // 新しいメッセージを送信した時
        scroller.current.scrollTop = scroller.current.scrollHeight;
      }
    }
    if (scroller.current) {
      scrollerHeight.current = scroller.current.scrollHeight;
    }
    loadedMessages.current = messagesState.data;
  }, [messagesState.data]);

  if (!messagesState.data && messagesState.loading) {
    return (
      <div className={styles.listContainer}>
        <Loader loading={props.messagesState.loading} />
      </div>
    );
  }

  return (
    <div
      className={styles.listContainer}
      ref={scroller}
      onScroll={handleScroll(props)}
    >
      <div className={styles.list}>
        {renderList(props)}
        {messagesState.data && messagesState.loading ? (
          <Loader loading={props.messagesState.loading} />
        ) : null}
      </div>
    </div>
  );
};

const renderList = (props: Props) => {
  const { messagesState, section, onOpenModal } = props;

  if (messagesState.data) {
    return messagesState.data.map((message) => {
      return (
        <AnnounceMessageListItem
          message={message}
          section={section}
          key={`AnnounceMessageList-GuardianMessageListItem-${message.id}`}
          onOpenModal={onOpenModal}
        />
      );
    });
  } else {
    return null;
  }
};

const AnnounceMessageListItem = (props: {
  message: AnnounceMessage;
  section: SectionInterface;
  onOpenModal: (message: AnnounceMessage) => void;
}) => {
  const { message, section, onOpenModal } = props;
  const { sentAt, receivedTotalCount } = message;

  return (
    <div
      className={styles.message}
      key={`AnnounceMessage-item-${message.id}`}
      data-testid="announce-message-listitem"
    >
      <div className={styles.message__header}>
        <CircleIcon
          iconProps={{ name: "icon-account", iconClassName: styles.icon }}
          className={styles.iconWrapper}
        />
        <span className={styles.name}>{section.fullName}</span>
        <time className={styles.datetime}>{TimeHelper.fullFormat(sentAt)}</time>
        <div className={styles.recipientsDropdown}>
          <span
            className={styles.dropdownLabel}
            onClick={openModal(onOpenModal, message)}
          >
            {`受信者一覧 ${
              receivedTotalCount > 0 ? `（既読 ${receivedTotalCount}名）` : ""
            }`}
          </span>
        </div>
      </div>
      {renderMessageBody(message)}
    </div>
  );
};

const renderMessageBody = (message: AnnounceMessage) => {
  if (message.presignedUrl) {
    return (
      <a href={message.presignedUrl} target="_blank" rel="noopener noreferrer">
        <img src={message.presignedUrl} className={styles.image} />
      </a>
    );
  } else {
    return (
      <p className={styles.message__content}>
        {reactStringReplace(
          nl2br(message.content),
          RegexpHelper.URL,
          (match: string): React.ReactNode => (
            <a href={match} target="_blank" rel="noopener noreferrer">
              {match}
            </a>
          ),
        )}
      </p>
    );
  }
};

const handleScroll = (props: Props) => (e: any) => {
  const {
    destinationTag,
    section,
    messagesState,
    loadMoreSectionAnnounceMessages,
  } = props;

  if (messagesState.loading || !messagesState.hasMore) {
    return;
  }

  if (e.target.scrollTop === 0 && messagesState.hasMore) {
    loadMoreSectionAnnounceMessages(
      section.id,
      destinationTag,
      messagesState.meta.currentPage + 1,
    );
  }
};

const openModal =
  (onOpenModal: (message: AnnounceMessage) => void, message: AnnounceMessage) =>
  () => {
    onOpenModal(message);
  };

export default AnnounceMessageList;
