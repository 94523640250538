import * as React from "react";

import StudentInterface from "../../../interfaces/StudentInterface";
import MessageForm from "./MessageForm";
import { generateStorageKey } from "../../atoms/ResumableTextarea/useResumableTextarea";
import { MessageFormValuesInterface } from "../../../interfaces/MessageInterface";
import { useEffect } from "react";
import {
  usePostGuardianMessage,
  usePostGuardianMessageFile,
} from "./useMutateGuardianMessage";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { UnprocessableEntityError } from "../../../errors";
import GuardianMessageInterface from "../../../interfaces/GuardianMessageInterface";

interface Props {
  student: StudentInterface;
  unshiftGuardianMessage: (message: GuardianMessageInterface[]) => void;
  updateCurrentThreadLatestMessage?: (
    guardianMessage: GuardianMessageInterface,
  ) => void;
}

const GuardianMessageForm = (props: Props) => {
  const form = useForm(props);

  if (!form.values[props.student.id]) {
    return null;
  }

  return (
    <React.Fragment>
      <MessageForm
        formState={{
          values: form.values[props.student.id],
          apiErrors: form.apiErrors,
          submitting: form.isSubmitting,
        }}
        student={props.student}
        canSendMessage={props.student.canSendGuardianMessage}
        allowedFileTypes={["jpg", "png", "gif", "pdf"]}
        disabledPlaceholder={form.disabledPlaceholder}
        postMessage={form.handlePostMessage}
        changeFormContent={form.handleChangeContent}
        changeFormFile={form.handleChangeFile}
        storageKey={generateStorageKey([
          "guardian_messages",
          props.student.id,
          "new",
        ])}
      />
    </React.Fragment>
  );
};

type Values = { [id: string]: MessageFormValuesInterface };

const useForm = ({
  student,
  unshiftGuardianMessage,
  updateCurrentThreadLatestMessage,
}: Props) => {
  const studentId = student.id;
  const [values, setValues] = React.useState<Values>({});
  const [apiErrorsForAllThreads, setApiErrorsForAllThreads] = React.useState<{
    [studentId: string]: ApiErrorInterface[];
  }>({});
  const { showErrorMessage } = useFlashMessage();

  const disabledPlaceholder = student.canSendGuardianMessage
    ? ""
    : "メール登録またはLINE連携をしていないため、メッセージは送信できません";

  const clearApiErrors = (studentId: string) => {
    setApiErrorsForAllThreads({
      ...apiErrorsForAllThreads,
      [studentId]: [],
    });
  };
  const onSuccess = (guardianMessages: GuardianMessageInterface[]) => {
    if (guardianMessages) {
      unshiftGuardianMessage(guardianMessages);
      if (updateCurrentThreadLatestMessage)
        updateCurrentThreadLatestMessage(guardianMessages[0]);
    }
    setValues((prevValues) => ({
      ...prevValues,
      [studentId]: { content: "", file: null },
    }));
  };

  const onError = (error: any) => {
    if (error instanceof UnprocessableEntityError) {
      setApiErrorsForAllThreads({
        ...apiErrorsForAllThreads,
        [studentId]: error.originalErrors,
      });
    }
    showErrorMessage("メッセージが送信できませんでした");
  };

  const { mutate: mutateMessage, isPending: isMutatingMessage } =
    usePostGuardianMessage({
      studentId,
      onSuccess,
      onError,
    });

  const handlePostMessage = (content?: string, file?: File) => {
    mutateMessage({ content, file });
    clearApiErrors(studentId);
  };

  const { mutate: mutateMessageFile, isPending: isMutatingMessageFile } =
    usePostGuardianMessageFile({
      studentId,
      onSuccess: (response) => onSuccess(response),
      onError,
    });

  const handlePostMessageFile = (file: File) => {
    mutateMessageFile({ file });
    clearApiErrors(studentId);
  };

  useEffect(() => {
    if (!values[studentId]) {
      setValues((prevValues) => ({
        ...prevValues,
        [studentId]: { content: "", file: null },
      }));
    }
  }, [studentId]);

  const handleChangeContent = (content: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [studentId]: {
        ...prevValues[studentId],
        content,
      },
    }));
  };

  const handleChangeFile = (file: File | null) => {
    setValues((prevValues) => ({
      ...prevValues,
      [studentId]: {
        ...prevValues[studentId],
        file,
      },
    }));
  };

  return {
    disabledPlaceholder,
    handleChangeContent,
    handleChangeFile,
    handlePostMessage,
    handlePostMessageFile,
    values,
    isSubmitting: isMutatingMessage || isMutatingMessageFile,
    apiErrors: apiErrorsForAllThreads[studentId] || [],
  };
};

export default GuardianMessageForm;
