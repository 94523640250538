import { useCallback, useState } from "react";
import { parseUrl, stringify } from "query-string";
import { SearchCondition } from "../types";
import { useNavigate } from "react-router-dom";
import { OrderDirectionType } from "../../../components/molecules/SortLabel";
import { PaginateDirectionTypes } from "../../../domains/LectureStudentAnalytics";

type HooksReturnType = {
  currentSearchCondition: SearchCondition;
  changeSearchCondition: (newSearchCondition: SearchCondition) => void;
};

export const useURLSyncSearchCondition = () => {
  const { query } = parseUrl(location.href, { arrayFormat: "bracket" });
  const [searchCondition, setSearchCondition] = useState<SearchCondition>(
    () => {
      return {
        paginationDir: query.pagination_dir as PaginateDirectionTypes,
        lectureSessionId: query.lecture_session_id as string,
        orderDirection: query.order_dir as OrderDirectionType,
      };
    },
  );
  const deps = [
    searchCondition.paginationDir,
    searchCondition.orderDir,
    searchCondition.order,
    searchCondition.lectureSessionId,
  ];

  const navigate = useNavigate();

  const changeSearchCondition = useCallback<
    HooksReturnType["changeSearchCondition"]
  >((newSearchCondition) => {
    setSearchCondition((prev) => ({ ...prev, ...newSearchCondition }));
    const search = stringify(
      /*
  この画面では*URLからの検索条件*のorderフィールドをURLと同期させない。
  - 他のアナリティクス画面ではorderが未設定か日付
    - 未設定の場合は日付のヘッダではない、先頭のカラムをソートする仕様
  対してこのアナリティクス画面では
  - 日付のソートはしない
  - 日付ヘッダではないカラムが2種類ある
  このため、orderフィールドをURLのパラメータと同期させると、
  他のアナリティクスが期待する値と辻褄が合わなくなってしまい、
  違うアナリティクス画面に遷移する際にURLを引き継ぐとおかしくなる
*/

      {
        ...query,
        ...{
          order_dir: newSearchCondition.orderDir ?? searchCondition.orderDir,
        },
      },
      { arrayFormat: "bracket" },
    );
    navigate({ search });
  }, deps);

  return {
    changeSearchCondition,
    searchCondition,
  };
};
