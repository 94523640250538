import StudentInterface from "../../../interfaces/StudentInterface";
import { IssuePasscodeButton } from "./IssuePasscodeButton";

export const Passcode = ({
  student,
  updateStudent,
}: {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
}) => {
  switch (student.status) {
    case "active":
      return (
        <IssuePasscodeButton
          studentId={student.id}
          reissue
          updateStudent={updateStudent}
          confirmMessage={`アプリ連携コードを再発行すると、現在連携しているStudyplusユーザーの最新の勉強記録の閲覧や、メッセージの送信などができなくなります。\n本当に「${student.fullName}」さんの連携を停止して、新規アプリ連携コードを発行してもよろしいですか？`}
        >
          再発行
        </IssuePasscodeButton>
      );
    case "inviting":
      return <>{student.passcode}</>;
    case "inactive":
      return (
        <IssuePasscodeButton
          studentId={student.id}
          reissue
          updateStudent={updateStudent}
        >
          再開
        </IssuePasscodeButton>
      );
    case "preinviting":
    default:
      return (
        <IssuePasscodeButton
          studentId={student.id}
          reissue={false}
          updateStudent={updateStudent}
          confirmMessage="アプリ連携コードを発行し、生徒のアプリに登録すると、生徒がStudyplusに入力した学習記録がStudyplus for Schoolに表示されます。"
        >
          発行
        </IssuePasscodeButton>
      );
  }
};
