import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import { cacheKeyOf } from "../../../hooks/http/useSectionSettingsApi";

type Props = {
  sectionId: string;
};
type ResultData = {
  isSuccess: boolean;
  value: boolean;
};
export const useMutateStayLocationSettings = ({ sectionId }: Props) => {
  const client = useQueryClient();
  return useMutation<ResultData, unknown, boolean, unknown>({
    mutationFn: async (value: boolean) => {
      const res = await api.patch(`/api/v1/sections/${sectionId}/settings`, {
        section_setting: {
          should_check_stay_location: value,
        },
      });

      return {
        isSuccess: res.ok,
        value: res.ok ? value : !value,
      };
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: cacheKeyOf(sectionId) });
    },
  });
};
