import { useQuery } from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../../../errors";
import { boronClient } from "../../../../api";
import { paths } from "../../../../lib/api/v1";

const URL =
  "/api/v1/sections/{section_id}/drill_learning_materials/{steak_material_code}";

export type DrillShowResponseItem =
  paths["/api/v1/sections/{section_id}/drill_learning_materials/{steak_material_code}"]["get"]["responses"]["200"]["content"]["application/json"]["drill"];

type Props = {
  sectionId: string;
  drillMaterialCode: string;
  enabled?: boolean;
};
export const useFetchDrillWithContents = ({
  sectionId,
  drillMaterialCode,
  enabled,
}: Props) => {
  return useQuery<DrillShowResponseItem, HTTPErrors>({
    queryKey: [URL, sectionId, drillMaterialCode, { include: ["contents"] }],
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/drill_learning_materials/{steak_material_code}",
        {
          params: {
            path: {
              section_id: sectionId,
              steak_material_code: drillMaterialCode,
            },
            query: {
              include: ["contents"],
            },
          },
        },
      );
      if (data) {
        return data.drill;
      }
      throw await createError(response);
    },
    enabled,
  });
};
