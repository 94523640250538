import * as React from "react";
import TimeHelper from "../../../helpers/TimeHelper";
import styleVars from "../../../styles/variables.scss";
import {
  Schedule,
  create,
  validateDateRange,
  defaultScheduleTitle,
  ScheduleForStudent,
} from "../../../domains/Schedule";
import { changeStartDate as changeStartDateRecurrence } from "../../../domains/Recurrence";
import {
  EventInput,
  EventClickArg,
  DateSelectArg,
  EventApi,
  DateSpanApi,
  EventDropArg,
} from "@fullcalendar/core";
import { truncateName } from "../../../domains/LearningMaterial";
import { useFetchSchedules } from "./useScheduleApi";
import { CalendarType } from "../../../components/atoms/Calendar";
import StudentInterface from "../../../interfaces/StudentInterface";
import {
  EventDragStartArg,
  EventResizeStartArg,
  EventResizeStopArg,
} from "@fullcalendar/interaction";
import { useMutateScheduleDragAndDrop } from "./useScheduleApi";
import { determineScheduleColorCode } from "./utilities";
import { useQueryError } from "../../../hooks/http/useQueryError";

export type UseStudentCalendarContainerProps = {
  onMutateError: (message: string) => void;
  onMutateSuccess: (message: string) => void;
  onSelectSchedule: (schedule: ScheduleForStudent | null) => void;
  student: StudentInterface;
  startDate: Date;
  endDate: Date;
  calendarType: CalendarType;
  onSelectNewScheduleFromSlot: (schedule?: Schedule) => void;
  openConfirmModal: (schedule: ScheduleForStudent) => void;
};

export const useStudentCalendarContainer = (
  props: UseStudentCalendarContainerProps,
) => {
  const { isLoading, data, error } = useFetchSchedules({
    from: props.startDate,
    to: props.endDate,
    studentId: props.student.id,
  });
  useQueryError(error);

  const events = React.useMemo(
    () => (data ? buildEventsFromSchedules(data) : []),
    [data],
  );

  /*
   * full-calendarはドラッグ&ドロップ中に予定一覧が更新されると、ドラッグ&ドロップ中の予定と新しい予定一覧どちらも表示しようとするため。
   * ドラッグ&ドロップと最新の予定一覧のリクエストのタイミングが被ると、カレンダー上に同じ予定がダブって表示されることがある。https://github.com/studyplus/boron-web/pull/1054#pullrequestreview-561596886
   * その問題を解消するため、通常の登録、編集とは違う処理を行う
   * - ドラッグ&ドロップで予定を更新する場合は更新後に最新の予定一覧をリクエストしない
   * - 編集APIでは予定の更新ごとにIDが変わる仕様になっているが、更新後に最新の予定一覧をリクエストしないため、
   *   ドラッグ&ドロップ中のイベントのインスタンスを保持し、更新が成功した場合にインスタンスを直接更新することで表示を最新にする
   *
   * なぜここでonSuccessを指定しているかについては https://studyplus.esa.io/posts/12283
   */
  const heldEventApisRef = React.useRef<Record<string, EventApi>>({});

  const { mutate } = useMutateScheduleDragAndDrop({
    studentId: props.student.id,
    from: props.startDate,
    to: props.endDate,
    onSuccess: (data, _v, context) => {
      props.onMutateSuccess("更新に成功しました。");
      if (context) {
        const event = heldEventApisRef.current[context.originalSchedule.id];
        if (event) {
          event.setExtendedProp("schedule", {
            ...context.requestedSchedule,
            id: data.id,
          });
          delete heldEventApisRef.current[context.originalSchedule.id];
        }
      }
    },
    onError: () => {
      props.onMutateError(
        "更新に失敗しました。リロードして再度お試しください。",
      );
    },
  });

  const onChangeScheduleFromInteraction = React.useCallback(
    (arg: EventDropArg | EventResizeStopArg) => {
      const schedule = arg.event.extendedProps.schedule as ScheduleForStudent;
      const newStart = arg.event.start;
      const newEnd = arg.event.end;
      if (!newStart || !newEnd) {
        return;
      }

      const newStudyDuration = calcNewStudyDuration({
        originalSchedule: schedule,
        newScheduleStartTime: newStart,
        newScheduleEndTime: newEnd,
      });
      const newRecurrence = schedule.recurrence
        ? changeStartDateRecurrence({
            currentReucurrence: schedule.recurrence,
            startDate: newStart,
          })
        : null;

      const newSchedule: ScheduleForStudent = {
        ...schedule,
        startAt: newStart,
        endAt: newEnd,
        studySchedule: schedule.studySchedule
          ? { ...schedule.studySchedule, numberOfSeconds: newStudyDuration }
          : schedule.studySchedule,
        recurrence: newRecurrence,
      };

      // リクエスト前に新データで予定の表示を更新する
      // サーバーからのエラーの場合はロールバックせずリロードを促すので一旦これでよし
      arg.event.setProp("title", formatToDisplayTitleOnCalendar(newSchedule));

      if (schedule.recurrence) {
        props.openConfirmModal(newSchedule);
        return;
      }
      mutate({
        schedule: newSchedule,
        updateType: "this",
        originalSchedule: schedule,
      });
    },
    [],
  );

  const onSelectFromSlot = React.useCallback((arg: DateSelectArg) => {
    props.onSelectNewScheduleFromSlot(
      create({ startAt: arg.start, endAt: arg.end, allday: arg.allDay }),
    );
  }, []);

  const onClickSchedule = React.useCallback((e: EventClickArg) => {
    props.onSelectSchedule(
      e.event.extendedProps.schedule as ScheduleForStudent,
    );
  }, []);

  const onMouseInteractionStart = React.useCallback(
    (e: EventDragStartArg | EventResizeStartArg) => {
      heldEventApisRef.current[e.event.extendedProps.schedule.id] = e.event;
    },
    [],
  );

  const shouldShowPlaceHolderEvent = React.useCallback(
    (dropInfo: DateSpanApi, draggedEvent: EventApi | null) => {
      if (
        validateDateRange(
          create({
            startAt: dropInfo.start,
            endAt: dropInfo.end,
            allday: dropInfo.allDay,
          }),
        ) !== "valid"
      ) {
        return false;
      }

      // 移動元がない(予定の新規作成の)場合はtrueを返す
      if (!draggedEvent) {
        return true;
      }

      // 終日予定から日時予定には変更できない
      // 日時予定から終日予定には変更できない
      return dropInfo.allDay == draggedEvent.allDay;
    },
    [],
  );

  return {
    onChangeScheduleFromInteraction,
    onSelectFromSlot,
    events,
    isLoading,
    error,
    eventColor: styleVars.colorPrimary,
    onClickSchedule,
    onMouseInteractionStart,
    shouldShowPlaceHolderEvent,
  };
};

// build fullcalendar's event objects
// ref: https://fullcalendar.io/docs/event-object
const buildEventsFromSchedules = (
  schedules: readonly ScheduleForStudent[],
): EventInput[] => {
  return schedules.map((schedule: ScheduleForStudent) => {
    const eventAttributes = buildEventAttributesFromSchedule(schedule);

    return {
      ...eventAttributes,
    };
  });
};

type CalcNewStudyDurationParam = {
  originalSchedule: Schedule;
  newScheduleStartTime: Date;
  newScheduleEndTime: Date;
};
const calcNewStudyDuration = ({
  originalSchedule: schedule,
  newScheduleEndTime,
  newScheduleStartTime,
}: CalcNewStudyDurationParam) => {
  const scheduleDurationMs =
    schedule.endAt.getTime() - schedule.startAt.getTime();

  const studyDuration = schedule.studySchedule
    ? schedule.studySchedule.numberOfSeconds
    : 0;

  if (scheduleDurationMs === studyDuration * 1000) {
    const newStudyDurationMs =
      newScheduleEndTime.getTime() - newScheduleStartTime.getTime();
    return Math.floor(newStudyDurationMs / 1000);
  }
  return studyDuration;
};

const buildEventAttributesFromSchedule = (schedule: ScheduleForStudent) => {
  const backgroundColor = determineScheduleColorCode(schedule);

  return {
    allDay: schedule.allday,
    title: formatToDisplayTitleOnCalendar(schedule),
    start: schedule.startAt,
    end: schedule.endAt,
    backgroundColor,
    borderColor: backgroundColor,
    extendedProps: { schedule },
  };
};

const formatToDisplayTitleOnCalendar = (schedule: Schedule): string => {
  const { studySchedule } = schedule;

  let title = schedule.summary || defaultScheduleTitle;

  if (studySchedule) {
    title = schedule.summary ? `${schedule.summary}：` : "";
    title += truncateName(studySchedule.learningMaterial);

    const additionalInfo = [
      studySchedule.numberOfSeconds
        ? TimeHelper.secondsToDisplayTime(studySchedule.numberOfSeconds)
        : null,
      studySchedule.amount
        ? `${studySchedule.amount}${studySchedule.learningMaterial.unit}`
        : null,
    ].filter((info) => info !== null);

    if (additionalInfo.length > 0) {
      const additionalText = additionalInfo.join(" / ");

      title += ` / ${additionalText}`;
    }
  }

  return title;
};
