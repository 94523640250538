export const ActivityProgress = {
  Initialized: "initialized",
  Started: "started",
  InProgress: "inProgress",
  Submitted: "submitted",
  Completed: "completed",
} as const;
export type ActivityProgressType =
  (typeof ActivityProgress)[keyof typeof ActivityProgress];
export const GradingProgress = {
  FullyGraded: "fullyGraded",
  Pending: "pending",
  PendingManual: "pendingManual",
  Failed: "failed",
  NotReady: "notReady",
} as const;
export type GradingProgressType =
  (typeof GradingProgress)[keyof typeof GradingProgress];

interface Result {
  scoreGiven: number | null;
  activityProgress: ActivityProgressType | null;
  gradingProgress: GradingProgressType | null;
}
export const Status = {
  Completed: "completed", // 提出済&採点済
  Submitted: "submitted", // 提出済&未採点
  NotSubmitted: "delivered", // 未提出
} as const;
export type StatusType = (typeof Status)[keyof typeof Status];
export const detectStatus = (result: Result): StatusType => {
  return result.activityProgress === ActivityProgress.Completed ||
    result.activityProgress === ActivityProgress.Submitted
    ? result.gradingProgress === GradingProgress.FullyGraded
      ? Status.Completed
      : Status.Submitted
    : Status.NotSubmitted;
};
