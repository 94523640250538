import { useRef, useEffect, useCallback } from "react";

export const useThrottle = <Args extends any[]>(
  fn: (...args: Args) => void,
  interval: number,
) => {
  const timeout = useRef<number | null>(null);
  const lastExecTime = useRef<number>(0);

  useEffect(() => {
    return () => {
      timeout.current && window.clearTimeout(timeout.current);
    };
  }, [interval]);

  const execIfNeeded = useCallback(
    (func: () => void) => {
      if (performance.now() - lastExecTime.current > interval) {
        func();
        lastExecTime.current = performance.now();
      }
    },
    [interval],
  );

  const setTimeout = useCallback(
    (func: () => void) => {
      timeout.current = window.setTimeout(() => {
        execIfNeeded(func);
        timeout.current = null;
      }, interval);
    },
    [interval],
  );

  const throttledExecute: typeof fn = useCallback(
    (...args: Args) => {
      // 前回の実行から interval ms以上経過している場合のみ実行する
      execIfNeeded(() => fn(...args));

      // 連続実行時の最後の実行を保証するためにsetTimeoutする
      if (!timeout.current) {
        setTimeout(() => fn(...args));
      } else {
        // 通常の実行とtimeoutで二重に実行されるのを避けるために延期する
        window.clearTimeout(timeout.current);
        setTimeout(() => fn(...args));
      }
    },
    [setTimeout, fn, execIfNeeded],
  );

  const cancelThrottledExecution = useCallback(() => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
    }
  }, []);

  return {
    throttledExecute,
    cancelThrottledExecution,
  };
};
