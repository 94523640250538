import LandingTemplate from "../../../components/templates/LandingTemplate/index";
import Login from "../../../components/organisms/Login/index";

const LoginPage = () => {
  return (
    <LandingTemplate
      title="ログイン"
      subHeading="いつもご利用いただきありがとうございます"
      subMessage="生徒の学習状況がひと目でわかる。"
      backgroundUrl="/images/bg_register.png"
    >
      <Login />
    </LandingTemplate>
  );
};

export default LoginPage;
