import SectionAnnounceMessagesStateInterface from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import Loader from "../../atoms/Loader";
import AnnounceDestinationTagListItem from "../AnnounceDestinationTagListItem";
import styles from "./styles.scss";

interface Props {
  announceMessagesState: SectionAnnounceMessagesStateInterface;
  onLoadMore: () => void;
  onClickTag: (e: any) => void;
}

const SectionAnnounceDestinationTagList = (props: Props) => {
  if (
    props.announceMessagesState.loading &&
    props.announceMessagesState.meta.currentPage === 0
  ) {
    return <Loader loading={props.announceMessagesState.loading} />;
  }

  return (
    <div className={styles.root} onScroll={handleScroll(props)}>
      {renderList(props)}
      {renderLoader(props)}
    </div>
  );
};

const renderList = (props: Props) => {
  const { data, openedTagId } = props.announceMessagesState;

  if (!data) {
    return null;
  }

  return data.map((destinationTag) => {
    return (
      <AnnounceDestinationTagListItem
        key={destinationTag.id}
        destinationTag={destinationTag}
        isActive={!!openedTagId && openedTagId === destinationTag.id}
        onClickTag={props.onClickTag}
      />
    );
  });
};

const renderLoader = (props: Props) => {
  if (props.announceMessagesState.loading && hasMoreData(props)) {
    return <Loader loading={props.announceMessagesState.loading} />;
  } else {
    return null;
  }
};

const handleScroll = (props: Props) => (e: any) => {
  if (props.announceMessagesState.loading) {
    return;
  }

  const scrollBottom = e.target.scrollTop + e.target.offsetHeight;
  if (scrollBottom + 100 >= e.target.scrollHeight) {
    props.onLoadMore();
  }
};

const hasMoreData = (props: Props): boolean => {
  const { currentPage, totalPages } = props.announceMessagesState.meta;
  return currentPage < totalPages;
};

export default SectionAnnounceDestinationTagList;
