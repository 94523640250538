import { useEffect } from "react";
import { connect } from "react-redux";

import Settings from "../../helpers/Settings";
import AppStateInterface from "../../interfaces/AppStateInterface";
import AuthorizationStateInterface from "../../interfaces/AuthorizationStateInterface";
import ReactGA4 from "react-ga4";
import { withRouter, WithRouterProps } from "../../helpers/RouterHelper";

interface StateProps {
  session: AuthorizationStateInterface;
}

type Props = StateProps & WithRouterProps;

const Tracker = (props: Props) => {
  const operatorId = props.session.currentOperator?.publicId || null;
  const setUserId = (userId: string | null) => {
    Settings.GOOGLE_ANALYTICS_MESUREMENT_ID && ReactGA4.set({ userId });
  };
  const sendPageview = (path: string, query: string | null = null) => {
    Settings.GOOGLE_ANALYTICS_MESUREMENT_ID &&
      ReactGA4.send({ hitType: "pageview", page: path + query });
  };
  const trackPage = (path: string, query: string, userId: string | null) => {
    setUserId(userId);
    sendPageview(path, query);

    // 特定のページを生徒・校舎毎でなくページ単位でのPVとして算出する
    const matchList: { [key: string]: RegExp } = {
      "/students/learning_material_tag_analytics":
        /students\/[a-zA-Z0-9]+\/learning_material_tag_analytics/,
      "/students/analytics": /students\/[a-zA-Z0-9]+\/analytics/,
      "/students/learning_materials":
        /students\/[a-zA-Z0-9]+\/learning_materials/,
      "/sections/settings/learning_material_tags":
        /sections\/[a-zA-Z0-9]+\/settings\/learning_material_tags/,
      "/sections/settings/management_learning_materials":
        /sections\/[a-zA-Z0-9]+\/settings\/management_learning_materials/,
      "/sections/settings/section_learning_materials":
        /sections\/[a-zA-Z0-9]+\/settings\/section_learning_materials/,
    };

    for (const [newPath, pattern] of Object.entries(matchList)) {
      if (pattern.test(path)) {
        sendPageview(newPath);
      }
    }
  };

  useEffect(() => {
    trackPage(props.location.pathname, props.location.search, operatorId);
  }, [props.location.pathname, props.location.search, operatorId]);

  return null;
};

const mapStateToProps = (state: AppStateInterface): StateProps => {
  return {
    session: state.session,
  };
};

export default withRouter<Props>(connect(mapStateToProps)(Tracker));
