import KarteInterface from "../interfaces/KarteInterface";
import karteComments from "./karteComments";

const karte = (
  state: KarteInterface | null = null,
  action: ReduxActions.Action<any>,
): KarteInterface | null => {
  if (!state) {
    return state;
  }
  if (action.payload.karteId !== state.id) {
    return state;
  }

  switch (action.type) {
    case "DELETE_KARTE_COMMENT_SUCCESS":
    case "DELETE_KARTE_COMMENT_ERROR":
    case "POST_KARTE_COMMENT_SUCCESS":
    case "POST_KARTE_COMMENT_ERROR":
      return {
        ...state,
        comments: karteComments(state.comments, action),
      };

    case "OPEN_KARTE_SHARE_MODAL": {
      return {
        ...state,
        isOpenShareModal: true,
      };
    }
    case "CLOSE_KARTE_SHARE_MODAL": {
      return {
        ...state,
        isOpenShareModal: false,
      };
    }
    case "POST_KARTE_SHARE_SENDING": {
      return {
        ...state,
        submittingShare: true,
        isOpenShareModal: true,
      };
    }
    case "POST_KARTE_SHARE_SUCCESS": {
      return {
        ...state,
        submittingShare: false,
        isOpenShareModal: false,
      };
    }
    case "POST_KARTE_SHARE_ERROR": {
      return {
        ...state,
        submittingShare: false,
        isOpenShareModal: true,
      };
    }
    default:
      return state;
  }
};

export default karte;
