import * as React from "react";
import TabsNavi from "../../../components/molecules/TabsNavi";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { LearningMaterialTagNarrowForm } from "./LearningMaterialTagManipulator";
import { LearningMaterialTagTable } from "./LearningMaterialTagTable";
import { NarrowingConditions } from "../../../hooks/http/useLearningMaterialTagApi";
import styles from "./SectionSettingsLearningMaterialTags.scss";
import { Modal } from "../../../components/atoms/Modal";
import { LearningMaterialTagForm } from "./LearningMaterialTagForm";
import { LearningMaterialTag } from "../../../domains/LearningMaterialTag";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { OutletContextProps } from "../../../helpers/RouterHelper";

// Main hooks

const useSectionsSettingsLearningMaterialTags = () => {
  const [narrowingCondition, setNarrowingCondition] =
    React.useState<NarrowingConditions>("all");

  const modalState = useModalState();

  const learningMaterialTagNarrowFormProps = {
    onChangeNarrowingCondition(narrowingCondition: NarrowingConditions) {
      setNarrowingCondition(narrowingCondition);
    },
    onClickRegisterTag: modalState.openNewModal,
  };

  const learningMaterialTagTableProps = {
    narrowingCondition,
    onClickEdit: React.useCallback(
      (learningMaterialTag: LearningMaterialTag) => {
        modalState.openEditModal(learningMaterialTag);
      },
      [modalState],
    ),
  };

  const modalProps = {
    isOpen: modalState.value.type !== "closed",
    onClose: modalState.close,
    onRequestClose: modalState.close,
  };

  const modalHeaderProps = {
    onClose: modalState.close,
  };

  const learningMaterialTagFormProps = {
    learningMaterialTag:
      modalState.value.type === "editing"
        ? modalState.value.learningMaterialTag
        : null,
    onSuccessRegister() {
      modalState.close();
    },
  };

  const modalHeaderText =
    modalState.value.type === "editing" ? "教材タグを編集" : "教材タグを登録";

  return {
    modalProps,
    modalHeaderProps,
    modalHeaderText,
    learningMaterialTagNarrowFormProps,
    learningMaterialTagTableProps,
    learningMaterialTagFormProps,
  };
};

// Main Component
type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
  };
const SectionsSettingsLearningMaterialTags_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("learning_material");
  }, []);
  const {
    modalHeaderProps,
    modalHeaderText,
    modalProps,
    learningMaterialTagNarrowFormProps,
    learningMaterialTagTableProps,
    learningMaterialTagFormProps,
  } = useSectionsSettingsLearningMaterialTags();

  return (
    <div>
      <TabsNavi
        tabs={[
          {
            label: "校舎教材一覧",
            link: `/sections/${props.section.id}/settings/management_learning_materials`,
            active: false,
          },
          {
            label: "デジタル教材",
            link: `/sections/${props.section.id}/settings/digital_learning_materials`,
            active: false,
          },
          {
            label: "校舎独自教材",
            link: `/sections/${props.section.id}/settings/section_learning_materials`,
            active: false,
          },
          {
            label: "教材タグ",
            link: `/sections/${props.section.id}/settings/learning_material_tags`,
            active: true,
          },
        ]}
      />
      <div className={styles.narrowingForm}>
        <LearningMaterialTagNarrowForm
          {...learningMaterialTagNarrowFormProps}
        />
      </div>
      <div className={styles.learningMaterialTags}>
        <LearningMaterialTagTable
          section={props.section}
          {...learningMaterialTagTableProps}
        />
      </div>
      <Modal {...modalProps}>
        <Modal.Header {...modalHeaderProps}>
          <h2>{modalHeaderText}</h2>
        </Modal.Header>
        <Modal.Body>
          <LearningMaterialTagForm
            section={props.section}
            {...learningMaterialTagFormProps}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

// Helpers
type ModalState =
  | { type: "new" }
  | { type: "editing"; learningMaterialTag: LearningMaterialTag }
  | { type: "closed" };
const useModalState = () => {
  const [modalState, setModalState] = React.useState<ModalState>({
    type: "closed",
  });
  const close = () => {
    setModalState({ type: "closed" });
  };

  const openEditModal = (learningMaterialTag: LearningMaterialTag) => {
    setModalState({ type: "editing", learningMaterialTag });
  };

  const openNewModal = () => {
    setModalState({ type: "new" });
  };

  return {
    value: modalState,
    close,
    openNewModal,
    openEditModal,
  };
};

const pageInfo = {
  title: "教材タグ",
};

export const SectionsSettingsLearningMaterialTags = enhanceSectionsSettingsPage(
  SectionsSettingsLearningMaterialTags_,
  pageInfo,
  ["management_learning_materials"],
);
