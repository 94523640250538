import * as React from "react";
import SectionInterface from "../../interfaces/SectionInterface";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { connect, HandleThunkActionCreator } from "react-redux";
import SectionStaffBoardPostEdit from "../../components/organisms/SectionStaffBoardPostEdit";
import { getStaffBoardPost } from "../../actions/pages/SectionStaffBoardPostPage/actions";
import AppStateInterface from "../../interfaces/AppStateInterface";
import OperatorInterface from "../../interfaces/OperatorInterface";
import { SectionStaffBoardPostStateInterface } from "../../interfaces/SectionStaffBoardPostInterface";
import {
  OutletContextProps,
  withRouter,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";

interface StateProps {
  currentOperator: OperatorInterface | undefined;
  staffBoardPostState: SectionStaffBoardPostStateInterface;
}
interface Props
  extends StateProps,
    WithRouterProps<AuthenticateRouterProps & { staffBoardPostId: string }>,
    OutletContextProps {
  section: SectionInterface;
  getPost: HandleThunkActionCreator<typeof getStaffBoardPost>;
}

class SectionStaffBoardPostPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { params } = props;
    props.getPost(params.sectionId, params.staffBoardPostId);
    props.setActiveMenu("dashboard");
  }

  render() {
    return (
      <SectionStaffBoardPostEdit
        section={this.props.section}
        currentOperator={this.props.currentOperator}
        staffBoardPostState={this.props.staffBoardPostState}
      />
    );
  }
}

const pageInfo = {
  title: "スタッフボード",
};
const EnhancedPage = enhanceSectionsPage(SectionStaffBoardPostPage, pageInfo);

const mapStateToProps = (state: AppStateInterface) => {
  return {
    currentOperator: state.session.currentOperator,
    staffBoardPostState: state.sectionStaffBoardPostState,
  };
};

const actions = {
  getPost: getStaffBoardPost,
};

export default withRouter<Props>(
  connect(mapStateToProps, actions)(EnhancedPage),
);
