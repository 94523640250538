import * as React from "react";
import styles from "./QuestionnaireForm.scss";
import { GrayedBox } from "./GrayedBox";
import { Flex } from "../../../components/atoms/Flex";
import {
  OptionType,
  SelectWrapper,
} from "../../../components/general/SelectWrapper";
import Input from "../../../components/atoms/Input";
import Icon from "../../../components/atoms/Icon";
import LinkButton from "../../../components/atoms/LinkButton";
import classnames from "classnames";
import { LectureQuestionnaireFormat } from "../../../domains/LectureQuestionnaire";
import { AddQuestionnaireButton } from "./AddQuestionnaireButton";
import { useQuestionnairesForm } from "./FormikWithLecture";
import ErrorText from "../../../components/atoms/ErrorText";
import SwitchButton, {
  Label as SwitchButtonLabelType,
} from "../../../components/atoms/SwitchButton";
import DeleteIcon from "../../../components/atoms/DeleteIcon/index";

export const QuestionnairesForm = () => {
  const {
    questionnaires,
    getFormatProps,
    getQuestionProps,
    getChoiceProps,
    getDisabledProps,
    getAddChoiceButtonProps,
    getRemoveChoiceButtonProps,
    addQuestionnaireButtonProps,
    getRemoveQuestionnaireButtonProps,
    getQuestionError,
    getChoiceError,
    isEditing,
    isAttendanceConfirmNone,
  } = useQuestionnairesForm();
  return (
    <div
      className={classnames(styles.questionnairesStack, {
        [styles.questionnaireDisabled]: isAttendanceConfirmNone,
      })}
    >
      {questionnaires.map((qs, questionnaireIndex) => {
        // 削除済みのデータは表示しない
        if (qs.deleted) return null;
        const isNotFreeFormat = qs.format !== "free";
        const questionError = getQuestionError(questionnaireIndex);
        return (
          <GrayedBox key={`questionnaire-${questionnaireIndex}`}>
            <div className={styles.rootContainer}>
              <QuestionnairesFormHeader>
                {/* 表示中・非表示のSwitchButtonは新規登録では見せない */}
                {isEditing && (
                  <SwitchButton
                    {...getDisabledProps(questionnaireIndex)}
                    label={switchButtonLabel}
                  />
                )}
                <RemoveQuestionnaireButton
                  {...getRemoveQuestionnaireButtonProps(questionnaireIndex)}
                  index={questionnaireIndex}
                />
              </QuestionnairesFormHeader>
              <QuestionnairesFormStack>
                <QuestionLayout>
                  <Format>
                    <SelectWrapper
                      {...getFormatProps(
                        questionnaireIndex,
                        determineFormatValue,
                      )}
                      size="md"
                      aria-label={`アンケート${
                        questionnaireIndex + 1
                      }問目の質問のタイプを選択する`}
                      options={titleOptions}
                    />
                  </Format>
                  <Question>
                    <Input
                      {...getQuestionProps(questionnaireIndex)}
                      placeholder="質問のタイトルを入力"
                      aria-label={`アンケート${
                        questionnaireIndex + 1
                      }問目の質問のタイトルを入力`}
                    />
                  </Question>
                  {questionError && (
                    <QuestionError>
                      <ErrorText>{questionError}</ErrorText>
                    </QuestionError>
                  )}
                </QuestionLayout>
                {isNotFreeFormat &&
                  qs.choices &&
                  qs.choices.map((_, choiceIndex) => {
                    const choiceError = getChoiceError({
                      questionnaireIndex,
                      choiceIndex,
                    });
                    return (
                      <ChoiceLayout key={`choices-${choiceIndex}`}>
                        <Choice>
                          <Input
                            {...getChoiceProps({
                              questionnaireIndex,
                              choiceIndex,
                            })}
                            placeholder="選択肢を入力"
                            aria-label={`アンケート${
                              questionnaireIndex + 1
                            }問目の回答設定${
                              choiceIndex + 1
                            }行目の選択肢を入力`}
                          />
                        </Choice>
                        <RemoveChoiceButton>
                          <LinkButton
                            {...getRemoveChoiceButtonProps({
                              questionnaireIndex,
                              targetChoiceIndex: choiceIndex,
                            })}
                            aria-label={`アンケート${
                              questionnaireIndex + 1
                            }問目の回答設定${
                              choiceIndex + 1
                            }行目の選択肢を削除`}
                            type="button"
                          >
                            <Icon name="icon-close-x" />
                          </LinkButton>
                        </RemoveChoiceButton>
                        {choiceError && (
                          <ChoiceError>
                            <ErrorText>{choiceError}</ErrorText>
                          </ChoiceError>
                        )}
                      </ChoiceLayout>
                    );
                  })}
              </QuestionnairesFormStack>
              <QuestionnairesFooter>
                {isNotFreeFormat && (
                  <AddChoiceButton
                    {...getAddChoiceButtonProps(questionnaireIndex)}
                  />
                )}
              </QuestionnairesFooter>
            </div>
          </GrayedBox>
        );
      })}
      <div className={styles.addQuestionnaireButtonContainer}>
        <AddQuestionnaireButton {...addQuestionnaireButtonProps} />
      </div>
    </div>
  );
};

const switchButtonLabel: SwitchButtonLabelType = {
  on: "表示中",
  off: "非表示",
};

export const QuestionnairesFormHeader = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <Flex marginTop="0" justifyContent="flex-end">
      <Flex gap="2" marginTop="0" alignItems="center">
        {children}
      </Flex>
    </Flex>
  );
};

export const QuestionnairesFooter = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <div className={classnames(styles.contentMargin, styles["u-mt-1"])}>
      {children}
    </div>
  );
};

type AddChoiceButtonProps = {
  onClick: () => void;
};
export const AddChoiceButton = ({ onClick }: AddChoiceButtonProps) => {
  return (
    <Flex marginTop="0" alignItems="center">
      <LinkButton type="button" color="primary" onClick={onClick} bold>
        <span className={styles.addButtonIcon}>
          <Icon name="icon-plus-tag" />
        </span>
        <span>選択肢を追加</span>
      </LinkButton>
    </Flex>
  );
};

type RemoveQuestionnaireButtonProps = {
  index: number;
  onClick: () => void;
};
export const RemoveQuestionnaireButton = ({
  index,
  onClick,
}: RemoveQuestionnaireButtonProps) => {
  return (
    <LinkButton
      onClick={onClick}
      type="button"
      color="gray-darken-1"
      aria-label={`アンケート${index + 1}問目の質問を削除`}
    >
      <DeleteIcon size="large" theme="gray" />
    </LinkButton>
  );
};

export const QuestionnairesFormStack = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <div className={styles.questionnaireFormStack}>{children}</div>;
};

type TitleOptionType = {
  label: string;
  value: LectureQuestionnaireFormat;
};
const titleOptions: TitleOptionType[] = [
  { label: "単一選択", value: "single" },
  { label: "複数選択", value: "multiple" },
  { label: "記述式テキスト", value: "free" },
];

const titleOptionsTable = titleOptions.reduce(
  (p, c) => ({ ...p, [c.value]: c }),
  {} as Record<LectureQuestionnaireFormat, OptionType>,
);

const determineFormatValue = (format: LectureQuestionnaireFormat): OptionType =>
  titleOptionsTable[format];

type PropsOnlyChildren = React.PropsWithChildren<unknown>;
const createWrapperComponentWithClassName = (className: string) => {
  return ({ children }: PropsOnlyChildren) => {
    return <div className={className}>{children}</div>;
  };
};

const QuestionLayout = createWrapperComponentWithClassName(
  styles.questionLayout,
);
const Format = createWrapperComponentWithClassName(styles.formatWrapper);
const Question = createWrapperComponentWithClassName(styles.questionWrapper);
const QuestionError = createWrapperComponentWithClassName(
  styles.questionErrorWrapper,
);
const ChoiceLayout = createWrapperComponentWithClassName(styles.choiceLayout);
const Choice = createWrapperComponentWithClassName(styles.choiceWrapper);
const ChoiceError = createWrapperComponentWithClassName(
  styles.choiceErrorWrapper,
);
const RemoveChoiceButton = createWrapperComponentWithClassName(
  styles.removeChoiceButtonWrapper,
);
