import * as React from "react";
import { connect } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import FlashMessageInterface, {
  FlashMessageType,
} from "../../../interfaces/FlashMessageInterface";
import styles from "./styles.scss";
import classNames from "classnames";
import { resetFlashMessage } from "../../../actions/flashMessage";
import Icon from "../../atoms/Icon/index";

interface Props {
  flashMessage: FlashMessageInterface;
  resetFlashMessage: () => void;
}

interface State {
  hidden: boolean;
}

class PressentationalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hidden: false };
  }

  componentDidMount() {
    this.setState({ hidden: false });
    setTimeout(() => {
      this.setState({ hidden: true }, () => {
        this.props.resetFlashMessage();
      });
    }, 10000);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (!this.props.flashMessage.visible && props.flashMessage.visible) {
      this.setState({ hidden: false });
      setTimeout(() => {
        this.setState({ hidden: true });
        this.props.resetFlashMessage();
      }, 10000);
    }
  }

  render() {
    if (this.props.flashMessage.messages.length === 0) {
      return null;
    }

    return (
      <button
        className={classNames(styles.message, this.messageTypeClassName(), {
          [styles.hidden]: this.state.hidden,
        })}
        onClick={this.handleClickClose}
      >
        <Icon name={this.iconName()} />
        {this.renderMessages()}
        <Icon
          name="icon-close-x"
          className={styles.closeIcon}
          onClick={this.handleClickClose}
        />
      </button>
    );
  }

  private renderMessages = () => {
    const { messages } = this.props.flashMessage;

    // NOTE: 原因不明だがmessagesが[{}]になることがあり、以下のエラーで画面が開けないユーザーがいるため対処
    //  Objects are not valid as a React child (found: object with keys {}). If you meant to render a collection of children, use an array instead.
    const filteredMessages = messages.filter(
      (message) => typeof message === "string",
    );
    return (
      <ul>
        {filteredMessages.map((message: string, i: number) => (
          <li key={`FlashMessage-${i}`}>{message}</li>
        ))}
      </ul>
    );
  };

  private messageTypeClassName(): string {
    switch (this.props.flashMessage.type) {
      case FlashMessageType.ERROR:
        return styles.error;
      case FlashMessageType.SUCCESS:
      case FlashMessageType.NONE:
      default:
        return styles.success;
    }
  }

  private iconName(): string {
    if (this.props.flashMessage.type === FlashMessageType.ERROR) {
      return "icon-failed";
    } else {
      return "icon-success";
    }
  }

  private handleClickClose = () => {
    this.setState(
      { hidden: true },
      // NOTE: CSSアニメーションで消えてからactionをdispatchするため500ms遅らせている
      () => {
        setTimeout(this.props.resetFlashMessage, 500);
      },
    );
  };
}

const mapStateToProps = (state: AppStateInterface) => ({
  flashMessage: state.flashMessage,
});

const FlashMessage = connect(mapStateToProps, { resetFlashMessage })(
  PressentationalComponent,
);

export default FlashMessage;
