import { SectionStaffBoardPostsStateInterface } from "../interfaces/SectionStaffBoardPostInterface";
import ApiResponse from "../interfaces/ApiResponse";
import StaffBoardPostInterface, {
  StaffBoardPostCommentInterface,
} from "../interfaces/StaffBoardPostInterface";

const defaultState = {
  data: [],
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
  loading: false,
  submitting: false,
  hasMore: false,
};

const sectionStaffBoardPostsState = (
  state: SectionStaffBoardPostsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): SectionStaffBoardPostsStateInterface => {
  switch (action.type) {
    // スタッフボード一覧取得
    case "sections/staff_board_posts/GET_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "sections/staff_board_posts/GET_REQUEST_INITIAL_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload.staffBoardPosts.data,
        meta: action.payload.staffBoardPosts.meta,
        hasMore: hasMore(action.payload.staffBoardPosts),
      };
    case "sections/staff_board_posts/GET_REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload.staffBoardPosts.data],
        meta: action.payload.staffBoardPosts.meta,
        hasMore: hasMore(action.payload.staffBoardPosts),
      };
    case "sections/staff_board_posts/GET_REQUEST_FAILURE":
      return {
        ...state,
        loading: false,
      };

    // スタッフボード投稿
    case "sections/staff_booard_posts/CREATE_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "sections/staff_booard_posts/CREATE_REQUEST_SUCCESS":
      return {
        ...state,
        submitting: false,
        data: [action.payload.staffBoardPost, ...state.data],
      };
    case "sections/staff_booard_posts/CREATE_REQUEST_FAILURE":
      return {
        ...state,
        submitting: false,
      };

    // スタッフボード削除
    case "sections/staff_board_posts/DELETE_REQUEST":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: true,
            };
          } else {
            return post;
          }
        }),
      };
    case "sections/staff_board_posts/DELETE_REQUEST_SUCCESS":
      return {
        ...state,
        data: state.data.filter((post: StaffBoardPostInterface) => {
          return post.id !== action.payload.staffBoardPostId;
        }),
      };
    case "sections/staff_board_posts/DELETE_REQUEST_FAILURE":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: false,
            };
          } else {
            return post;
          }
        }),
      };

    // スタッフボードコメント投稿
    case "sections/staff_board_posts/POST_COMMENT_REQUEST":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: true,
            };
          } else {
            return post;
          }
        }),
      };
    case "sections/staff_board_posts/POST_COMMENT_REQUEST_SUCCESS":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: false,
              comments: [
                ...post.comments,
                action.payload.staffBoardPostComment,
              ],
            };
          } else {
            return post;
          }
        }),
      };
    case "sections/staff_board_posts/POST_COMMENT_REQUEST_FAILURE":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: false,
            };
          } else {
            return post;
          }
        }),
      };

    // スタッフボードコメント削除
    case "sections/staff_board_posts/DELETE_COMMENT_REQUEST":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: true,
            };
          } else {
            return post;
          }
        }),
      };
    case "sections/staff_board_posts/DELETE_COMMENT_REQUEST_SUCCESS":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: false,
              comments: post.comments.filter(
                (comment: StaffBoardPostCommentInterface) => {
                  return comment.id !== action.payload.commentId;
                },
              ),
            };
          } else {
            return post;
          }
        }),
      };
    case "sections/staff_board_posts/DELETE_COMMENT_REQUEST_FAILURE":
      return {
        ...state,
        data: state.data.map((post: StaffBoardPostInterface) => {
          if (post.id === action.payload.staffBoardPostId) {
            return {
              ...post,
              submitting: false,
            };
          } else {
            return post;
          }
        }),
      };

    default:
      return state;
  }
};

const hasMore = (payload: ApiResponse<StaffBoardPostInterface[]>) => {
  const { meta } = payload;
  return meta.currentPage < meta.totalPages;
};

export default sectionStaffBoardPostsState;
