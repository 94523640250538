import * as React from "react";
import enhanceSectionPage from "../../hocs/enhanceSectionsPage";
import styles from "./SectionsAnalyticsLectureAttendancePage.scss";
import SectionInterface from "../../interfaces/SectionInterface";
import { DateRangeNavigation } from "../../components/general/DateRangeNavigation";
import SwitchTabs from "../../components/molecules/SwitchTabs";
import PrintButton from "../../components/atoms/PrintButton";
import Loader from "../../components/atoms/Loader";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import {
  AnalyticsFilter,
  useAnalyticsFilter,
} from "../../hooks/filters/useAnalyticsFilter";
import { LectureAttendanceAnalyticsSwitcher } from "./LectureAttendanceAnalyticsSwitcher";
import { useParams } from "react-router";
import { AnalyticsTable } from "./AnalyticsTable/AnalyticsTable";
import StudentFilter from "../../components/features/NewStudentFilter";
import isAfter from "date-fns/isAfter";
import { useCallback } from "react";
import { isPushableCalendarNext } from "../../helpers/AnalyticsHelper";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { StudentFilterInterface } from "../../interfaces/FiltersInterface";

export type LectureAttendanceAnalyticsType = "student" | "lecture";

const isLectureAttendanceAnalyticsType = (param: string | undefined) => {
  return param === "student" || param === "lecture";
};

type Props = OutletContextProps & {
  section: SectionInterface;
};

const SectionsAnalyticsLectureAttendancePage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("analytics");
  }, []);

  const { lectureAttendanceAnalyticsType } = useParams<{
    lectureAttendanceAnalyticsType: LectureAttendanceAnalyticsType;
  }>();

  const {
    analyticsFilter,
    studentFilter,
    changeDates,
    changeTerm,
    changeOrder,
  } = useAnalyticsFilter({
    sectionId: props.section.id,
    withStudentFilter: lectureAttendanceAnalyticsType === "student",
  });

  const handleDatesChange = useCallback(
    (dates: { startDate?: Date; endDate?: Date }) => {
      if (dates.startDate && dates.endDate) {
        changeDates({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }
    },
    [changeDates],
  );

  const { sendNotFound } = useErrorDispatcher();
  if (!isLectureAttendanceAnalyticsType(lectureAttendanceAnalyticsType)) {
    sendNotFound();
  }

  if (!analyticsFilter) return <Loader loading />;

  return (
    <>
      <div>
        <SectionAnalyticsTypeTab
          sectionId={props.section.id}
          analyticsType="lectureAttendance"
        />
      </div>
      {lectureAttendanceAnalyticsType === "student" && (
        <StudentFilter sectionId={props.section.id} />
      )}

      <div
        className={`${styles.tableControl} ${
          lectureAttendanceAnalyticsType === "student"
            ? styles.studentControlMargin
            : styles.lectureControlMargin
        }`}
      >
        <div className={styles.tableControlLeft}>
          <LectureAttendanceAnalyticsSwitcher
            sectionId={props.section.id}
            currentAnalyticsType={
              lectureAttendanceAnalyticsType as LectureAttendanceAnalyticsType
            }
          />
        </div>
        <DateRangeNavigation
          startDate={analyticsFilter.startDate}
          endDate={analyticsFilter.endDate}
          term={analyticsFilter.term}
          onDatesChange={handleDatesChange}
          calendarDisabled={
            analyticsFilter.term === "monthly" ||
            analyticsFilter.term === "weekly"
          }
          isDayBlocked={isDayBlocked}
          canPushRightButton={isPushableCalendarNext(
            analyticsFilter.endDate,
            analyticsFilter.term,
          )}
        />
        <div className={styles.tableControlRight}>
          <SwitchTabs
            tabs={[
              {
                id: "daily",
                label: "日",
                active: analyticsFilter.term === "daily",
              },
              {
                id: "weekly",
                label: "週",
                active: analyticsFilter.term === "weekly",
              },
              {
                id: "monthly",
                label: "月",
                active: analyticsFilter.term === "monthly",
              },
            ]}
            onClickTab={(tab: any) => () => {
              changeTerm(tab.id);
            }}
          />
          <PrintButton />
        </div>
      </div>

      <AnalyticsTable
        lectureAttendanceAnalyticsType={
          lectureAttendanceAnalyticsType as LectureAttendanceAnalyticsType
        }
        sectionId={props.section.id}
        analyticsFilter={analyticsFilter as AnalyticsFilter}
        studentFilter={
          {
            sectionId: props.section.id,
            tagFilter: studentFilter,
          } as StudentFilterInterface
        }
        changeOrder={changeOrder}
      />
    </>
  );
};

const isDayBlocked = (day: Date) => {
  return isAfter(day, new Date());
};

const pageInfo = {
  title: "アナリティクス",
};
export const SectionsAnalyticsLectureAttendancePage = withRouter(
  enhanceSectionPage(SectionsAnalyticsLectureAttendancePage_, pageInfo),
);
