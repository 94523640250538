import ApiResponse from "../interfaces/ApiResponse";
import StudentAnalyticsTableInterface, {
  StudentAnalyticsTableStateInterface,
} from "../interfaces/StudentAnalyticsTableInterface";

const defaultStudentAnalyticsTableState: StudentAnalyticsTableStateInterface = {
  loading: true,
  submittingShare: false,
  isOpenShareModal: false,
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
  data: null,
};

const concatData = (
  prevData: StudentAnalyticsTableInterface | null,
  data: StudentAnalyticsTableInterface,
): StudentAnalyticsTableInterface => {
  if (prevData === null) {
    return data;
  } else {
    return {
      ...prevData,
      learningMaterials: prevData.learningMaterials.concat(
        data.learningMaterials,
      ),
    };
  }
};

const appendSuccessResponse = (
  state: StudentAnalyticsTableStateInterface,
  response: ApiResponse<StudentAnalyticsTableInterface>,
) => {
  return {
    ...state,
    loading: false,
    data: concatData(state.data, response.data),
    meta: response.meta,
  };
};

const studentAnalyticsTable = (
  state: StudentAnalyticsTableStateInterface = defaultStudentAnalyticsTableState,
  action: ReduxActions.Action<any>,
) => {
  const { payload } = action;

  switch (action.type) {
    // Loading
    case "GET_INITIAL_STUDENT_ANALYTICS_TABLE_TIME_REQUEST_SENDING":
    case "GET_INITIAL_STUDENT_ANALYTICS_TABLE_AMOUNT_REQUEST_SENDING":
      return defaultStudentAnalyticsTableState;

    case "GET_STUDENT_ANALYTICS_TABLE_TIME_REQUEST_SENDING":
    case "GET_STUDENT_ANALYTICS_TABLE_AMOUNT_REQUEST_SENDING":
      return { ...state, loading: true };

    // Success Response
    case "GET_STUDENT_ANALYTICS_TABLE_TIME_RESPONSE_RECEIVED":
      return appendSuccessResponse(state, payload.timeAnalyticsTable);
    case "GET_STUDENT_ANALYTICS_TABLE_AMOUNT_RESPONSE_RECEIVED":
      return appendSuccessResponse(state, payload.amountAnalyticsTable);

    // ERROR
    case "GET_STUDENT_ANALYTICS_TABLE_TIME_REQUEST_ERROR":
    case "GET_STUDENT_ANALYTICS_TABLE_AMOUNT_REQUEST_ERROR":
      if (action.payload && action.payload.name === "AbortError") {
        return state;
      } else {
        return { ...state, loading: true };
      }

    case "OPEN_ANALYTICS_SHARE_MODAL": {
      return {
        ...state,
        isOpenShareModal: true,
      };
    }
    case "CLOSE_ANALYTICS_SHARE_MODAL": {
      return {
        ...state,
        isOpenShareModal: false,
      };
    }
    case "POST_ANALYTICS_SHARE_SENDING": {
      return {
        ...state,
        submittingShare: true,
        isOpenShareModal: true,
      };
    }
    case "POST_ANALYTICS_SHARE_SUCCESS": {
      return {
        ...state,
        submittingShare: false,
        isOpenShareModal: false,
      };
    }
    case "POST_ANALYTICS_SHARE_ERROR": {
      return {
        ...state,
        submittingShare: false,
        isOpenShareModal: true,
      };
    }

    default:
      return state;
  }
};

export default studentAnalyticsTable;
