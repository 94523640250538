import * as React from "react";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentStateInterface } from "../../../interfaces/StudentInterface";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import { connect } from "react-redux";
import { StudentKarteEditStateInterface } from "../../../interfaces/KarteInterface";
import { getKarte, resetKarte } from "../../../actions/karte";
import Loader from "../../../components/atoms/Loader";
import StudentKarteEdit from "../../../components/organisms/StudentsKarteEdit/index";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";

interface Props
  extends WithRouterProps<AuthenticateRouterProps & { karteId: string }>,
    OutletContextProps {
  student: StudentStateInterface;
  karteEditState: StudentKarteEditStateInterface;
  getKarte: (studentId: string, karteId: string) => void;
  resetKarte: () => void;
}

class StudentsKarteEditPage extends React.Component<Props, never> {
  constructor(props: Props) {
    super(props);
    this.loadKarte();
    props.setActiveMenu("karte");
  }

  componentWillUnmount() {
    // karteEditStateが残ったままだとloadingがfalseになり、
    // 次回表示時に一瞬loadingではなく古い状態のStudentKarteEditが表示されてしまう
    this.props.resetKarte();
  }

  render() {
    if (!this.props.student.data) {
      return null;
    }

    if (this.props.karteEditState.loading) {
      return <Loader loading={true} />;
    }

    return (
      <StudentKarteEdit
        student={this.props.student.data}
        karteEditState={this.props.karteEditState}
      />
    );
  }

  private loadKarte() {
    const params = this.props.params;
    this.props.getKarte(params.studentId, params.karteId);
  }
}

const mapStateToProps = (state: AppStateInterface) => {
  return {
    student: state.student,
    karteEditState: state.studentKarteEdit,
  };
};

const actions = {
  getKarte,
  resetKarte,
};

const ConnectedStudentsKartePage = connect(
  mapStateToProps,
  actions,
)(StudentsKarteEditPage);

export default enhanceStudentsPage(ConnectedStudentsKartePage, {
  title: "カルテ編集",
});
