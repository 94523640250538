import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../../../components/atoms/Icon";
import { Modal } from "../../../../components/atoms/Modal/index";
import { Button } from "@studyplus/boron-ui";
import { FormikBag, withFormik, Form, Field, FormikProps } from "formik";
import validationSchema from "./validationSchema";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import StudentTag from "../../../../domains/StudentTag";
import { HandleThunkActionCreator } from "react-redux";
import {
  updateTag,
  deleteTag,
} from "../../../../actions/pages/SectionsSettingsTagsPage/actions";

interface ComponentProps {
  sectionId: string;
  tag: StudentTag;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  updateTag: HandleThunkActionCreator<typeof updateTag>;
  deleteTag: HandleThunkActionCreator<typeof deleteTag>;
  onSubmitCallback: (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => void;
}

interface Values {
  name: string;
}

interface State {
  isOpen: boolean;
}

type Props = ComponentProps & FormikProps<Values>;

class SectionTagItem extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.state.isOpen) {
      return;
    }

    if (this.props.tag.name != prevProps.tag.name) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { tag } = this.props;
    return (
      <div className={styles.row}>
        <p className={styles.name}>{tag.name}</p>
        <div className={styles.actionList}>
          <button className={styles.link} onClick={this.handleOpenUpdateModal}>
            <Icon name="icon-edit" />
            編集
          </button>
          {this.renderUpdateModal(tag)}
          <button className={styles.link} onClick={this.handleDestroy}>
            <Icon name="icon-trash" />
            削除
          </button>
        </div>
      </div>
    );
  }

  private renderUpdateModal = (tag: StudentTag) => {
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={this.handleCloseUpdateModal}
        className={styles.modal}
      >
        <Modal.Header onClose={this.handleCloseUpdateModal}>
          <h2 className={styles.modalheader}>タグを編集</h2>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <label
              htmlFor={`sectionTag-${tag.id}--name`}
              className={styles.label}
            >
              タグ名
            </label>
            {this.renderNameError()}
            {this.renderApiErrors()}
            <Field
              id={`sectionTag-${tag.id}--name`}
              name="name"
              className={styles.input}
            />
            <div className={styles.form_row}>
              <Button
                disabled={this.isDisabled()}
                type="submit"
                className={styles.btn}
              >
                保存
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  private isDisabled = () => {
    return this.props.submitting || !this.props.isValid;
  };

  private renderNameError = () => {
    if (this.shouldRenderErrors(this.props, "name")) {
      return <p className={styles.input__error}>{this.props.errors.name}</p>;
    } else {
      return null;
    }
  };

  private renderApiErrors = () => {
    if (this.props.apiErrors && this.props.apiErrors.length > 0) {
      return this.props.apiErrors.map((err: ApiErrorInterface, idx: number) => {
        return (
          <p
            className={styles.input__error}
            key={`sectionTag-${this.props.tag.id}-${idx}`}
          >
            {err.message}
          </p>
        );
      });
    }
  };

  private shouldRenderErrors = (props: Props, key: string) => {
    const error = (props.errors as any)[key];
    const touched = (props.touched as any)[key];
    return error && touched;
  };

  private handleDestroy = () => {
    if (
      confirm(
        "コンテンツ配信機能の配信タグとして設定されている場合、生徒へ送信した全てのURLが無効になります。削除してよろしいですか？",
      )
    ) {
      this.props.deleteTag(
        this.props.sectionId,
        this.props.tag.id,
        this.props.onSubmitCallback,
      );
    }
  };

  private handleOpenUpdateModal = () => {
    this.setState({ isOpen: true });
  };

  private handleCloseUpdateModal = () => {
    this.setState({ isOpen: false });
  };
}

const mapPropsToValues = (props: ComponentProps): Values => ({
  name: props.tag.name,
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  const { props } = formikBag;
  props.updateTag(
    props.sectionId,
    props.tag.id,
    { name: values.name },
    props.onSubmitCallback,
  );
};

export default withFormik({
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  isInitialValid: true,
})(SectionTagItem);
