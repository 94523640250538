import * as React from "react";
import styles from "./styles.scss";

interface Props extends React.PropsWithChildren<unknown> {
  opened: boolean;
}

const SidebarFooter: React.FC<Props> = (props: Props) => {
  return <div className={styles.root}>{props.children}</div>;
};

export default SidebarFooter;
