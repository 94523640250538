import { SectionKarteTemplatesStateInterface } from "../interfaces/SectionKarteTemplatesInterface";

const defaultState = {
  data: [],
  loading: false,
};

const sectionKarteTemplatesState = (
  state: SectionKarteTemplatesStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_SECTIONS_KARTE_TEMPLATES_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_SECTIONS_KARTE_TEMPLATES_SUCCESS":
      return {
        ...state,
        data: action.payload.karteTemplates,
        loading: false,
      };
    case "GET_SECTIONS_KARTE_TEMPLATES_ERROR":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default sectionKarteTemplatesState;
