import * as React from "react";
import styles from "./styles.scss";
import SectionInterface from "../../../interfaces/SectionInterface";
import BackLink from "../../atoms/BackLink";
import StudentNewForm from "../../molecules/StudentNewForm";
import { connect, HandleThunkActionCreator } from "react-redux";
import { dispatchPostNewSectionStudent } from "../../../actions/pages/sectionStudentsNew/index";
import SectionStudentsNewStateInterface from "../../../interfaces/SectionStudentsNewStateInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";

interface Props extends WithRouterProps {
  section: SectionInterface;
  sectionsStudentsNewState: SectionStudentsNewStateInterface;
  postStudent: HandleThunkActionCreator<typeof dispatchPostNewSectionStudent>;
}

const SectionsSettingsStudentsNew = (props: Props): React.ReactElement => {
  return (
    <div className={styles.root}>
      <BackLink>戻る</BackLink>
      <StudentNewForm
        section={props.section}
        navigate={props.navigate}
        postNewStudent={props.postStudent}
        submitting={props.sectionsStudentsNewState.submitting}
        apiErrors={props.sectionsStudentsNewState.apiErrors}
      />
    </div>
  );
};

const mapStateToProps = (state: AppStateInterface, ownProps: any) => ({
  section: ownProps.section,
  sectionsStudentsNewState: state.sectionsStudentsNewState,
});

const ConnectedComponent = connect(mapStateToProps, {
  postStudent: dispatchPostNewSectionStudent,
})(SectionsSettingsStudentsNew);

export default withRouter<Props>(ConnectedComponent);
