import * as React from "react";
import styles from "./styles.scss";
import { Modal } from "../../../../components/atoms/Modal/index";
import Radio from "../../../../components/atoms/Radio/index";
import { UpdateType } from "../../../../domains/Schedule";

type Props = {
  onClose: () => void;
  headerLabel: string;
  buttonsArea: (chosen: UpdateType) => React.ReactElement;
};

export const ConfirmUpdateTypeModal = (props: Props): React.ReactElement => {
  const [checkedUpdateType, checkUpdateType] =
    React.useState<UpdateType>("this");
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "this" || e.target.value === "following") {
        checkUpdateType(e.target.value);
      } else {
        throw "didn't match any recurrence value";
      }
    },
    [],
  );

  return (
    <>
      <Modal.Header onClose={props.onClose}>{props.headerLabel}</Modal.Header>
      <Modal.Body>
        <Radio
          id="ConfirmUpdateModal-this"
          name="recurrence-update-type"
          label="この予定のみ"
          isBlock={true}
          checked={checkedUpdateType === "this"}
          value={"this"}
          onChange={handleChange}
          weight={"normal"}
        />
        <Radio
          id="ConfirmUpdateModal-following"
          name="recurrence-update-type"
          label="これ以降のすべての予定"
          isBlock={true}
          checked={checkedUpdateType === "following"}
          value={"following"}
          onChange={handleChange}
          weight={"normal"}
        />

        <div className={styles.buttons}>
          {props.buttonsArea(checkedUpdateType)}
        </div>
      </Modal.Body>
    </>
  );
};
