import * as React from "react";
import TabsNavi from "../../../components/molecules/TabsNavi/index";
import SectionInterface from "../../../interfaces/SectionInterface";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useFetchSectionLearningMaterials } from "../../../hooks/http/useFetchSectionLearningMaterials";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { Link } from "react-router-dom";
import Loader from "../../../components/atoms/Loader/index";
import { Flex } from "../../../components/atoms/Flex/index";
import { BlockRow } from "../../../components/atoms/BlockRow/index";
import { Text } from "../../../components/general/Text";
import { LearningMaterialTable } from "./LearningMaterialTable";
import { OutletContextProps } from "../../../helpers/RouterHelper";

type Props = OutletContextProps & {
  section: SectionInterface;
};

const useSectionsSettingsLearningMaterials = ({ section }: Props) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    totalCount,
  } = useFetchSectionLearningMaterials({
    sectionId: section.id,
  });

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching]);

  useQueryError(error);

  return {
    totalCount,
    isLoading,
    isFetchingNextPage,
    data,
  };
};

const SectionsSettingsLearningMaterialsPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("learning_material");
  }, []);
  const { totalCount, isLoading, isFetchingNextPage, data } =
    useSectionsSettingsLearningMaterials(props);

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "校舎教材一覧",
            link: `/sections/${props.section.id}/settings/management_learning_materials`,
            active: false,
          },
          {
            label: "デジタル教材",
            link: `/sections/${props.section.id}/settings/digital_learning_materials`,
            active: false,
          },
          {
            label: "校舎独自教材",
            link: `/sections/${props.section.id}/settings/section_learning_materials`,
            active: true,
          },
          {
            label: "教材タグ",
            link: `/sections/${props.section.id}/settings/learning_material_tags`,
            active: false,
          },
        ]}
      />
      {data ? (
        <>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <Text bold>登録済教材：{totalCount}件</Text>
            <Link
              to={`/sections/${props.section.id}/settings/section_learning_materials/new`}
              className={`buiButtonBase buiButtonVariantFilled`}
            >
              独自教材を追加
            </Link>
          </Flex>
          <BlockRow marginTop="1.6rem">
            <LearningMaterialTable
              section={props.section}
              sectionLearningMaterials={data}
            />
          </BlockRow>
        </>
      ) : null}
      <Loader loading={isLoading || isFetchingNextPage} />
    </>
  );
};

const pageInfo = {
  title: "校舎独自教材",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsLearningMaterialsPage,
  pageInfo,
  ["management_learning_materials"],
);

export default EnhancedPage;
