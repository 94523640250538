import classNames from "classnames";
import * as React from "react";

import Body from "./Body";
import Header from "./Header";
import Navigation, { NavigationProps } from "./Navigation";
import styles from "./styles.scss";

interface Props extends React.PropsWithChildren<unknown> {
  // NOTE: SPの可視状態の切り替えに使う
  focusedColumn: Column;
}

interface State {
  height: number;
}

export enum Column {
  Left,
  Right,
}

// NOTE: .leftと.rightを
// PCでは左右に、SPでは別画面に
// 分割して表示するレイアウトコンポーネント
class SplitLayout extends React.Component<Props, State> {
  static Body: (props: React.PropsWithChildren<unknown>) => JSX.Element;
  static Header: (props: React.PropsWithChildren<unknown>) => JSX.Element;
  static Navigation: (props: NavigationProps) => JSX.Element;

  constructor(props: Props) {
    super(props);
  }

  render() {
    const children = React.Children.toArray(this.props.children);

    return (
      <div className={styles.root}>
        {children[0]}
        <div
          className={classNames(styles.right, {
            [styles.focused]: this.props.focusedColumn === Column.Right,
            [styles.covered]: this.props.focusedColumn === Column.Right,
          })}
        >
          {children[1]}
        </div>
      </div>
    );
  }
}

SplitLayout.Navigation = Navigation;
SplitLayout.Header = Header;
SplitLayout.Body = Body;

export default SplitLayout;
