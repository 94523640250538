import * as React from "react";
import styles from "./styles.scss";
import { HelpLink } from "../HelpLink/index";
import classnames from "classnames";

type Props = React.PropsWithChildren<
  Pick<
    React.LabelHTMLAttributes<HTMLTableSectionElement>,
    "hidden" | "className"
  >
>;
export const MainSection = ({ children, className, ...props }: Props) => {
  return (
    // TODO: デフォルトのpaddingを0にする、できれば中身のレイアウトのことには関与しないようにしたい
    <section className={classnames(styles.root, className)} {...props}>
      {children}
    </section>
  );
};

export const HorizontalScrollMain = ({
  children,
  className,
  ...props
}: Props) => {
  return (
    <main
      className={classnames(styles.root, styles.scrollX, className)}
      {...props}
    >
      {children}
    </main>
  );
};

type As = "h1" | "h2" | "h3" | "h4" | "h5";

type PageHeaderProps = {
  as?: As;
  children: React.ReactNode;
  helpUrl?: string;
};
export const PageHeader = ({
  as: Comp = "h2",
  children,
  helpUrl,
}: PageHeaderProps) => {
  return (
    <Comp className={styles.pageHeader}>
      {children}
      {helpUrl ? (
        <span className={styles.helpUrl}>
          <HelpLink to={helpUrl} />
        </span>
      ) : null}
    </Comp>
  );
};
