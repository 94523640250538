import { components, paths } from "../lib/api/v1";

export type ExaminationResult = components["schemas"]["ExaminationResult"];

export type TimelineExaminationResult =
  paths["/api/v1/sections/{section_id}/timeline/examination_results"]["get"]["responses"]["200"]["content"]["application/json"]["examinationResults"]["data"][0];

export type ExaminationResultAttachment =
  components["schemas"]["ExaminationResult"]["resultAttachments"][0];

export type ExaminationResultAttachmentFile = {
  id: string;
  file: File;
};

export type ExaminationResultDetail = Omit<
  ExaminationResult,
  "examinedOn" | "examination" | "subjectResults" | "resultAttachments"
>;

export const FileTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
] as const;
