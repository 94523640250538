import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useCallback, useEffect, useState } from "react";
import { useThrottle } from "../../../hooks/useThrottle";
import { useNavigationBlocker } from "./useNavigationBlocker";

type Content = {
  text: string;
  createdAt: number;
};

const generateContent = (text: string) => ({
  text,
  createdAt: new Date().getTime(),
});

export const generateStorageKey = (keys: string[]) => {
  return keys.join(".");
};

interface Arguments {
  value: string;
  key: string;
  confirmMessage: string;
  onResume?: (value: string) => void;
}

export function useResumableTextarea({
  value,
  key,
  confirmMessage,
  onResume,
}: Arguments) {
  const storageKey = generateStorageKey(["studyplus", "backup", key]);
  const {
    get: loadStorage,
    set: saveStorage,
    remove: clearStorage,
  } = useLocalStorage<Content>(storageKey);
  const [isBlockingNavigation, setNavigationBlocker] = useNavigationBlocker();
  const [resumedValue, setResumedValue] = useState<string | null>(null);
  const [isResumed, setIsResumed] = useState<boolean>(false);

  const loadValue = useCallback(() => {
    const content = loadStorage();
    return content ? content.text : null;
  }, [loadStorage]);

  const clearValue = useCallback(() => {
    cancelThrottledExecution();
    clearStorage();
    setResumedValue(null);
    setIsResumed(true);
    setNavigationBlocker(false);
  }, [clearStorage]);

  const throttledCallback = useCallback(
    (targetValue: string) => {
      if (targetValue === "") {
        clearValue();
      } else {
        saveStorage(generateContent(targetValue));
        setNavigationBlocker(true);
      }
    },
    [clearValue, saveStorage],
  );

  const { throttledExecute: saveValue, cancelThrottledExecution } = useThrottle(
    throttledCallback,
    1000,
  );

  // 初回render時のLocalStorageからのロード
  useEffect(() => {
    const loadedValue = loadValue();
    if (loadedValue) {
      if (loadedValue !== value) {
        // LocalStorageとvalueが違う場合
        // window.confirmで再開するか確認する
        if (window.confirm(confirmMessage)) {
          setResumedValue(loadedValue);
          if (onResume) {
            onResume(loadedValue);
          }
        } else {
          clearValue();
        }
      } else {
        // LocalStorageとvalueが合致する場合
        // 単純に再開した状態とする
        setResumedValue(loadedValue);
        if (onResume) {
          onResume(loadedValue);
        }
      }
    } else {
      setResumedValue(null);
    }
    setIsResumed(true);
    return () => {
      setResumedValue(null);
      setIsResumed(false);
    };
  }, [storageKey]);

  return {
    storageKey,
    isResumed,
    resumedValue,
    saveValue,
    clearValue,
    isBlockingNavigation,
    setNavigationBlocker,
  };
}
