import * as React from "react";
import { connect, HandleThunkActionCreator } from "react-redux";

import {
  loadFollowStatus,
  loadInitialStudentMessages,
  loadMoreStudentMessages,
  deleteMessage,
} from "../../../actions/pages/studentMessages";
import TabsNavi from "../../../components/molecules/TabsNavi";
import StudentMessage from "../../../components/organisms/StudentMessage";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentMessagesStateInterface } from "../../../interfaces/MessageInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import { loadOperatorProfile } from "../../../actions/pages/SettingsProfilePage/index";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  student: StudentInterface | null;
  messagesState: StudentMessagesStateInterface;
  loadFollowStatus: (studentId: string) => void;
  loadInitialStudentMessages: (studentId: string) => void;
  loadMoreStudentMessages: (studentId: string, since: string) => void;
  loadOperatorProfile: HandleThunkActionCreator<typeof loadOperatorProfile>;
  currentOperator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
  deleteMessage: HandleThunkActionCreator<typeof deleteMessage>;
}

class PresentationalComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.loadInitialMessages();
    this.props.loadOperatorProfile();

    const { studentId } = props.params;
    this.props.loadFollowStatus(studentId);
    props.setActiveMenu("message");
  }

  render() {
    const { student, messagesState, location } = this.props;

    return (
      <>
        <div style={{ marginTop: "-1.0rem" }}>
          <TabsNavi
            tabs={[
              {
                label: "生徒",
                link: {
                  pathname: `/students/${this.props.params.studentId}/messages`,
                  search: location.search,
                },
                active: true,
              },
              {
                label: "保護者",
                link: {
                  pathname: `/students/${this.props.params.studentId}/guardian_messages`,
                  search: location.search,
                },
                active: false,
              },
            ]}
          />
        </div>
        {student && messagesState.isFollowing !== null && (
          <StudentMessage
            student={student}
            isFollowing={messagesState.isFollowing}
            messagesState={messagesState}
            operator={this.props.currentOperator}
            operatorProfile={this.props.operatorProfile}
            deleteMessage={this.props.deleteMessage}
          />
        )}
      </>
    );
  }

  private loadInitialMessages(): void {
    const { studentId } = this.props.params;

    this.props.loadInitialStudentMessages(studentId);
  }
}

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceStudentsPage(PresentationalComponent, pageInfo);

const mapStateToProps = (state: AppStateInterface) => {
  return {
    messagesState: state.studentMessagesState,
    operatorProfile: state.operatorSettings.operatorProfile,
  };
};

const actions = {
  loadInitialStudentMessages,
  loadMoreStudentMessages,
  loadFollowStatus,
  loadOperatorProfile,
  deleteMessage,
};

const StudentsMessagesPage = connect(
  mapStateToProps,
  actions,
)(EnhancedPage as any);

export default StudentsMessagesPage;
