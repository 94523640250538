import { ChartDatasetProperties, ChartOptions, TooltipItem } from "chart.js";
import {
  determineColorWithRank,
  toHeaderDate,
} from "../../../helpers/AnalyticsHelper";
import TimeHelper from "../../../helpers/TimeHelper";
import {
  AnalyticsTermType,
  AnalyticsType,
  StudentAnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import { StudentAnalyticsRowInterface } from "../../../interfaces/StudentAnalyticsTableInterface";
import styleVars from "../../../styles/variables.scss";
import BarChart from "../BarChart";
import styles from "./styles.scss";

export interface Props {
  learningMaterials: StudentAnalyticsRowInterface[];
  analyticsType: Extract<StudentAnalyticsType, "time" | "amount">;
  term: AnalyticsTermType;
  columns: string[];
  setChart?: BarChart["props"]["setChart"];
}

const datasetsFromData = (
  learningMaterials: StudentAnalyticsRowInterface[],
): ChartDatasetProperties<"bar", number[]>[] => {
  return learningMaterials.map((row: StudentAnalyticsRowInterface) => {
    const backgroundColor = determineColorWithRank(row.rank);
    return {
      label: row.name,
      data: row.scores,
      borderWidth: 0,
      backgroundColor,
      maxBarThickness: 60,
    };
  });
};

const formatXaxesLabel = (
  dateString: string,
  term: AnalyticsTermType,
): string => {
  return toHeaderDate(dateString, term);
};

const formatYaxesLabel = (
  value: number,
  analyticsType: AnalyticsType,
): string => {
  if (analyticsType === "time") {
    if (value === 0) {
      return "0";
    }

    const hours = Math.floor(value / 3600);
    return `${hours}h`;
  } else {
    return value.toString();
  }
};

const lineColor = "#e7e9ee";

const StudentAnalyticsGraph = (props: Props): JSX.Element => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          color: lineColor,
          lineWidth: function (context) {
            return context.index === 0 ? 2 : 1;
          },
        },
        beginAtZero: true,
        max: undefined,
        ticks: {
          callback: (v: string | number) =>
            formatYaxesLabel(Number(v), props.analyticsType),
          stepSize: props.analyticsType === "time" ? 7200 : undefined,
          color: styleVars.colorBlackLighten2,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        ticks: {
          padding: 8,
          color: styleVars.colorGrayDarken3,
          font: {
            weight: "bold",
          },
          callback: function (_, index) {
            // cf. https://www.chartjs.org/docs/latest/axes/labelling.html#creating-custom-tick-formats
            return formatXaxesLabel(this.getLabelForValue(index), props.term);
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false, // NOTE: これがないと横軸の線が2重に表示されてしまう
        },
      },
    },
    plugins: {
      tooltip: {
        padding: {
          top: 10,
          bottom: 10,
          left: 16,
          right: 16,
        },
        backgroundColor: styleVars.colorBlackLighten1,
        cornerRadius: 4,
        caretSize: 10,
        callbacks: {
          label: (tooltipItem: TooltipItem<"bar">) => {
            const dataset = tooltipItem.dataset;
            const score = dataset.data[tooltipItem.dataIndex];
            const itemName = dataset.label;
            const formattedScore =
              props.analyticsType === "amount"
                ? score
                : TimeHelper.secondsToDisplayTime(score as number);
            return `${itemName}：${formattedScore}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const data = {
    datasets: datasetsFromData(props.learningMaterials),
    labels: props.columns,
  };

  return (
    <div className={styles.graph}>
      <BarChart data={data} options={options} setChart={props.setChart} />
    </div>
  );
};

export default StudentAnalyticsGraph;
