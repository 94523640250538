import styles from "./styles.scss";
import {
  withFormik,
  FormikBag,
  FormikProps,
  Form,
  Field,
  ErrorMessage,
} from "formik";
import Input from "../../atoms/Input/index";
import Label from "../../atoms/Label/index";
import Button from "../../atoms/Button/index";
import ErrorText from "../../atoms/ErrorText/index";
import validationSchema from "./validationSchema";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";

interface ComponentProps {
  resetPassword: (password: string, passwordConfirmation: string) => void;
  apiErrors: ApiErrorInterface[];
  submitting: boolean;
}

interface Values {
  password: string;
  passwordConfirmation: string;
}

type Props = ComponentProps & FormikProps<Values>;

const PasswordResetForm = (props: Props) => {
  const shouldRenderError = (name: string) => {
    const errors = (props.errors as any)[name];
    const touched = (props.touched as any)[name];
    return errors && touched;
  };

  return (
    <Form>
      <div className={styles.input}>
        <div className={styles.input_label}>
          <Label htmlFor="password_reset__password">パスワード</Label>
          <ErrorMessage name="password" component={ErrorText} />
        </div>
        <Field
          name="password"
          id="password_reset__password"
          component={Input}
          type="password"
          hasError={shouldRenderError("password")}
        />
      </div>
      <div className={styles.input}>
        <div className={styles.input_label}>
          <Label htmlFor="password_reset__password">パスワード（確認）</Label>
          <ErrorMessage name="passwordConfirmation" component={ErrorText} />
        </div>
        <Field
          name="passwordConfirmation"
          id="password_reset__password_confirmation"
          component={Input}
          type="password"
          hasError={shouldRenderError("passwordConfirmation")}
        />
      </div>
      <div>
        {props.apiErrors.map((apiError, i) => (
          <ErrorText key={`api_error_${i}`}>{apiError.message}</ErrorText>
        ))}
      </div>
      <div className={styles.button}>
        <Button type="submit" disabled={props.submitting || !props.isValid}>
          パスワードを設定
        </Button>
      </div>
    </Form>
  );
};

const mapPropsToValues = () => ({
  password: "",
  passwordConfirmation: "",
});

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  formikBag.props.resetPassword(values.password, values.passwordConfirmation);
};

export default withFormik({ handleSubmit, mapPropsToValues, validationSchema })(
  PasswordResetForm,
);
