import SwitchTabs from "../../components/molecules/SwitchTabs";
import { useNavigate } from "react-router";
import { AnalyticsType } from "../../interfaces/AnalyticsTableInterface";

type Props = {
  sectionId: string;
  currentAnalyticsType: AnalyticsType;
};
export const SectionStudyRecordAnalyticsSwitcher = ({
  sectionId,
  currentAnalyticsType,
}: Props) => {
  const navigate = useNavigate();
  return (
    <SwitchTabs
      tabs={[
        {
          id: "time",
          label: "学習時間",
          active: currentAnalyticsType === "time",
        },
        {
          id: "amount",
          label: "学習量",
          active: currentAnalyticsType === "amount",
        },
      ]}
      onClickTab={(tab) => () => {
        const url =
          tab.id === "time"
            ? `/sections/${sectionId}/analytics/time${location.search}`
            : `/sections/${sectionId}/analytics/amount${location.search}`;
        navigate(url);
      }}
    />
  );
};
