import * as React from "react";
import styles from "./styles.scss";
import classNames from "classnames";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../atoms/Icon";
import styleVars from "../../../styles/variables.scss";
import AnalyticsHelper, {
  getHeatmapClass,
} from "../../../helpers/AnalyticsHelper";
import { ColorDot } from "../../../components/general/ColorDot";

/**
 * アナリティクスの見映えを共通化するためのテーブル関連のコンポーネント
 *
 * TODO: 既存の教材別生徒アナリティクスの共通化までできなかったので、どこかのタイミングでやる
 */

type ClassNameProps = {
  className?: string;
};
type CellDisabled = {
  disabled?: boolean;
};

type TableProps = React.PropsWithChildren<React.ComponentProps<"table">>;
export const FixedTable = ({ children, className, ...props }: TableProps) => {
  return (
    <div className={styles.tableWrapper}>
      <table
        {...props}
        className={classNames(
          styles.AnalyticsTable,
          styles["AnalyticsTable--fixed"],
          className, // fixedの時にmin-widthに入れたい値は使う側によって異なるため
        )}
      >
        {children}
      </table>
    </div>
  );
};

export const Table = ({ children, className }: TableProps) => {
  return (
    <div className={styles.tableWrapper}>
      <table className={classNames(styles.AnalyticsTable, className)}>
        {children}
      </table>
    </div>
  );
};

type ThProps = React.PropsWithChildren<
  React.ThHTMLAttributes<HTMLTableHeaderCellElement>
>;
export const Th = ({ children, className, ...rest }: ThProps) => {
  return (
    <th className={classNames(styles.AnalyticsTableTh, className)} {...rest}>
      {children}
    </th>
  );
};

type TrProps = React.PropsWithChildren<unknown>;
export const Tr = ({ children }: TrProps) => {
  return <tr className={styles.AnalyticsTableTr}>{children}</tr>;
};

type TdProps = React.PropsWithChildren<ClassNameProps & CellDisabled> & {
  noBorderTop?: boolean;
  positionRelative?: boolean; // tdはabsoluteやfixedにはならないはずなので、position = "absolute" | "relative"... じゃなくrelativeのみのフラグにする
  wordWrap?: boolean; // 既存の機能の実装の関係で、改行させないがデフォルト
  colSpan?: number;
};
export const Td = ({
  children,
  className,
  disabled,
  noBorderTop = false,
  positionRelative = false,
  wordWrap = false,
  colSpan,
}: TdProps) => {
  const disabledClass = disabled ? styles["AnalyticsTableTd--disabled"] : null;
  const noBorderBottomClass = noBorderTop
    ? styles["AnalyticsTableTd--noBorderTop"]
    : null;
  const positionRelativeClass = positionRelative
    ? styles["AnalyticsTableTd--positionRelative"]
    : null;
  return (
    <td
      {...(colSpan ? { colSpan } : {})}
      className={classNames(
        styles.AnalyticsTableTd,
        disabledClass,
        className,
        noBorderBottomClass,
        positionRelativeClass,
        { [styles["AnalyticsTableTd--whitespace-normal"]]: wordWrap },
      )}
    >
      {children}
    </td>
  );
};

type AnalyticsColorDotProps = {
  rank: number;
};
export const AnalyticsColorDot = ({ rank }: AnalyticsColorDotProps) => {
  return (
    <ColorDot
      style={{
        background: (styleVars as any)[
          `color${AnalyticsHelper.getRankColorNumber(rank).toString()}`
        ],
      }}
    />
  );
};

type TitleTdProps = TdProps;
export const TitleTd = ({ children, ...rest }: TitleTdProps) => {
  return (
    <Td {...rest} className={styles.AnalyticsTableTd__Title}>
      {children}
    </Td>
  );
};

type HeatMapTdProps = React.PropsWithChildren<
  {
    deviation: number;
    score: number;
  } & CellDisabled
>;
export const HeatMapTd = ({
  children,
  deviation,
  score,
  ...rest
}: HeatMapTdProps) => {
  const heatmapClass = getHeatmapClass(deviation, score);

  return (
    <Td {...rest} className={heatmapClass}>
      {children}
    </Td>
  );
};

type NoTextTdProps = TdProps;
export const NoTextTd = ({ children, ...rest }: NoTextTdProps) => (
  <Td {...rest} className={styles["AnalyticsTableTd--NoText"]}>
    {children}
  </Td>
);

type ColorDotTdProps = TdProps;
export const ColorDotTd = ({
  children,
  className,
  ...rest
}: ColorDotTdProps) => (
  <Td
    {...rest}
    className={classNames(styles.AnalyticsTableTd__Colorlegend, className)}
  >
    {children}
  </Td>
);

export type MixedCheckValue = boolean | "indeterminate";
type MixedCheckboxProps = {
  id: string;
  value: MixedCheckValue;
  disabled?: boolean;
  onChanged?: (checked: boolean) => void;
};
export const MixedCheckbox = ({
  id,
  disabled = false,
  value,
  onChanged,
}: MixedCheckboxProps) => {
  return (
    <React.Fragment>
      <Checkbox
        id={id}
        onChange={(e) =>
          onChanged?.(value === "indeterminate" ? false : e.target.checked)
        }
        checked={value === true}
        disabled={disabled}
        className={classNames(styles.Checkbox, {
          [styles["Checkbox--hidden"]]: disabled || value === "indeterminate",
        })}
      />
      <label
        htmlFor={id}
        className={classNames(styles.Checkbox__Label, {
          [styles["Checkbox__Label--hidden"]]:
            !disabled && value !== "indeterminate",
        })}
      >
        <Icon name="icon-checkbox-hyphen" />
      </label>
    </React.Fragment>
  );
};

export * from "./TableWrapper";
