import ApiClient from "../../api";
import { Dispatch } from "redux";
import { FlashMessageAction, showErrorFlashMessage } from "../flashMessage";
import AuthenticationsStateInterface from "../../interfaces/AuthenticationsStateInterface";
import { createAction } from "redux-actions";

export enum ActionTypes {
  GET_USER_INFO_REQUEST = "authorization/GET_USER_INFO_REQUEST",
  GET_USER_INFO_SUCCESS = "authorization/GET_USER_INFO_SUCCESS",
  GET_USER_INFO_ERROR = "authorization/GET_USER_INFO_ERROR",
}

export type Actions =
  | GetUserInfoRequest
  | GetUserInfoSuccess
  | GetUserInfoError;

interface GetUserInfoRequest {
  type: ActionTypes.GET_USER_INFO_REQUEST;
}

interface GetUserInfoSuccess {
  type: ActionTypes.GET_USER_INFO_SUCCESS;
  payload: AuthenticationsStateInterface;
}

interface GetUserInfoError {
  type: ActionTypes.GET_USER_INFO_ERROR;
}

export const updateUserConsentAgreedSucess = createAction(
  "UPDATE_USER_CONSENT_AGREED_SUCCESS",
);

export const dispatchGetUserInfo =
  () =>
  (dispatch: Dispatch<Actions | FlashMessageAction>): void => {
    dispatch({ type: ActionTypes.GET_USER_INFO_REQUEST });

    ApiClient.get(`/api/v1/me`)
      .then((response) => {
        if (response.ok) {
          response.json().then((json: AuthenticationsStateInterface) => {
            dispatch({
              type: ActionTypes.GET_USER_INFO_SUCCESS,
              payload: json,
            });
          });
        } else {
          dispatch({ type: ActionTypes.GET_USER_INFO_ERROR });
          dispatch(showErrorFlashMessage("ログインしてください"));
        }
      })
      .catch(() => {
        dispatch({ type: ActionTypes.GET_USER_INFO_ERROR });
      });
  };
