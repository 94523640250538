import * as React from "react";
import type {
  CalendarOptions,
  SlotLabelContentArg,
  DayHeaderContentArg,
  EventSourceInput,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import jaLocale from "@fullcalendar/core/locales/ja";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import "./styles.scss";
import { useCalendar } from "./useCalendar";
import styles from "./styles.scss";
import { formatMonthDay } from "../../../helpers/TimeHelper";

const Daily = "Daily";
export type Daily = typeof Daily;
export type Weekly = "Weekly";

export type CalendarType = Daily | Weekly;

export const determineCalendarType = (str?: string): CalendarType => {
  if (typeof str !== "undefined" && str.toLowerCase() === Daily.toLowerCase()) {
    return "Daily";
  }
  return "Weekly";
};

const SevenDays = { days: 7 };
const OneDay = { days: 1 };

const determineDuration = (type?: CalendarType) =>
  type === "Weekly" ? SevenDays : OneDay;

export type Props = {
  onClickSchedule?: CalendarOptions["eventClick"];
  onDropSchedule?: CalendarOptions["eventDrop"];
  onSelectSlot?: CalendarOptions["select"];
  eventColor?: CalendarOptions["eventColor"];
  startDate?: Date;
  calendarType?: CalendarType;
  events: EventSourceInput;
  onEventResize?: CalendarOptions["eventResize"];
  onDragStart?: CalendarOptions["eventDragStart"];
  onEventResizeStart?: CalendarOptions["eventResizeStart"];
  shouldShowPlaceHolderEvent?: CalendarOptions["eventAllow"];
};

const Component = (props: Props) => {
  const { rootRef, calendarApiRef } = useCalendar({
    startDate: props.startDate,
    calendarType: props.calendarType,
  });
  return (
    <div className={styles.root} ref={rootRef}>
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin, rrulePlugin]}
        duration={determineDuration(props.calendarType)}
        editable
        eventClick={props.onClickSchedule}
        eventDrop={props.onDropSchedule}
        eventDragStart={props.onDragStart}
        eventResize={props.onEventResize}
        eventResizeStart={props.onEventResizeStart}
        events={props.events}
        headerToolbar={false}
        initialDate={props.startDate}
        initialView="timeGrid"
        locale={jaLocale}
        ref={calendarApiRef}
        select={props.onSelectSlot}
        selectable={true}
        selectMirror={true}
        selectAllow={props.shouldShowPlaceHolderEvent}
        eventAllow={props.shouldShowPlaceHolderEvent}
        nowIndicator
        scrollTime="06:00:00"
        height="100%"
        slotLabelContent={translateSlotTime}
        dayHeaderContent={createDayHeader}
        eventColor={props.eventColor}
      />
    </div>
  );
};

export const Calendar = React.memo(Component);

const translateSlotTime = (ps: SlotLabelContentArg) => {
  const hoursString = ps.date.getHours().toString().padStart(2, "0");
  return <div className={styles.cssClassTimeAxis}>{`${hoursString}:00`}</div>;
};

const createDayHeader = (da: DayHeaderContentArg) => {
  return formatMonthDay(da.date);
};
