import styles from "./StudentTimeline.scss";
import StudyRecordInterface from "../../interfaces/StudyRecordInterface";
import Loader from "../../components/atoms/Loader";
import TimelineItem from "../../components/features/Timeline/TimelineItem";

interface Props {
  timeline: StudyRecordInterface[];
  loading: boolean;
}

const StudentTimeline = ({ timeline, loading }: Props) => {
  if (loading && !timeline) {
    return <Loader loading={loading} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.timeline}>
        {timeline && timeline.length > 0 ? (
          <StudentTimelineItems timeline={timeline} />
        ) : (
          <p>勉強記録がまだありません</p>
        )}
      </div>
      {loading && <Loader loading={loading} />}
    </div>
  );
};

const StudentTimelineItems = ({ timeline }: Pick<Props, "timeline">) => {
  return (
    <>
      {timeline.map((studyRecord) => {
        return (
          <div
            className={styles.timelineItem}
            key={`study-record-${studyRecord.id}`}
          >
            <TimelineItem
              studyRecord={studyRecord}
              student={studyRecord.student}
              sectionId={studyRecord.student.section.id}
              showHeader={true}
              canSendComment={studyRecord.student.canSendComment}
              key={`StudentTimeline-TimelineItem-${studyRecord.id}`}
            />
          </div>
        );
      })}
    </>
  );
};

export default StudentTimeline;
