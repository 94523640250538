import * as React from "react";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { ClassroomForm } from "./forms/ClassroomForm";
import {
  useClassroomURLAction,
  ClassroomURLType,
  determineTitleLabel,
} from "./useClassroomURLAction";
import { useFetchClassroomWithId } from "./useClassroomApi";
import Loader from "../../../components/atoms/Loader";
import { MainSection, PageHeader } from "../../../components/atoms/MainSection";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { BlockRow } from "../../../components/atoms/BlockRow";
import {
  Breadcrumbs,
  BreadcrumbItem,
} from "../../../components/general/Breadcrumbs";
import { Link } from "react-router-dom";
import styles from "./SectionsSettingsClassRoomFormPage.scss";
import { OutletContextProps } from "../../../helpers/RouterHelper";

// Main hooks
type UseSectionSettingsClassroomFormPageProps = {
  section: SectionInterface;
};
const useSectionSettingsClassroomFormPage = ({
  section,
}: UseSectionSettingsClassroomFormPageProps) => {
  const urlAction = useClassroomURLAction();
  const {
    isLoading,
    data: classroom,
    error,
  } = useFetchClassroomWithId({
    sectionId: section.id,
    classroomId:
      urlAction.type !== ClassroomURLType.add ? urlAction.classroomId : null,
  });
  useQueryError(error);

  const shouldShowLoading =
    urlAction.type !== ClassroomURLType.add && isLoading;
  const loaderProps = { loading: shouldShowLoading };
  const clasroomFormProps = {
    editingClassroom: classroom,
    urlActionType: urlAction.type,
    sectionId: section.id,
  };
  const mainSectionProps = {
    hidden: shouldShowLoading,
  };

  const actionLabel = determineTitleLabel(urlAction.type);

  const breadcrumbProps = React.useMemo<BreadcrumbItem[]>(
    () => [
      { label: "教室管理", url: `/sections/${section.id}/settings/classrooms` },
      { label: `教室の${actionLabel}` },
    ],
    [urlAction, section.id],
  );

  return {
    loaderProps,
    clasroomFormProps,
    mainSectionProps,
    actionLabel,
    breadcrumbProps,
  };
};
const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/6046037";

type Props = SectionsSettingsPageProps &
  UseSectionSettingsClassroomFormPageProps &
  OutletContextProps;

const SectionsSettingsClassroomFormPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("classrooms");
  }, []);
  const section = props.section;
  const {
    loaderProps,
    mainSectionProps,
    clasroomFormProps,
    actionLabel,
    breadcrumbProps,
  } = useSectionSettingsClassroomFormPage({ section });
  return (
    <>
      <div className={styles.breadcrumbsWrapper}>
        <Breadcrumbs items={breadcrumbProps}>
          {(item, isLast) =>
            isLast || !item.url ? (
              <span className={styles.breadcrumbLastLabel}>{item.label}</span>
            ) : (
              <span className={styles.breadcrumbLinkLabel}>
                <Link to={item.url}>{item.label}</Link>
              </span>
            )
          }
        </Breadcrumbs>
      </div>
      <Loader {...loaderProps} />
      <BlockRow marginTop="2.4rem">
        <MainSection {...mainSectionProps}>
          <PageHeader helpUrl={HELP_URL}>教室の{actionLabel}</PageHeader>
          <ClassroomForm {...clasroomFormProps} />
        </MainSection>
      </BlockRow>
    </>
  );
};

const pageInfo = {
  title: "教室登録/編集",
};
export const SectionsSettingsClassroomFormPage = enhanceSectionsSettingsPage(
  SectionsSettingsClassroomFormPage_,
  pageInfo,
  ["contents"], // TODO: 出欠用の権限に書き換え
);
