import * as React from "react";
import styles from "./styles.scss";
import StudentInterface from "../../../interfaces/StudentInterface";
import { FormikProps, useFormik } from "formik";
import validationSchema from "./validationSchema";
import { Button } from "@studyplus/boron-ui";
import { updateKarte } from "../../../actions/studentKartes";
import { connect, HandleThunkActionCreator } from "react-redux";
import InputIcon from "../../atoms/InputIcon";
import DeprecatedTagButton from "../../features/DeprecatedTagButton/index";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import {
  StudentKarteEditStateInterface,
  KarteImageAttachment,
  KarteAttachment,
} from "../../../interfaces/KarteInterface";
import SquareImageList from "../../atoms/SquareImageList/index";
import FileHelper from "../../../helpers/FileHelper";
import KarteFileAttachmentList from "../KarteFileAttachmentList";
import Input from "../../atoms/Input/index";
import Label from "../../atoms/Label/index";
import ResumableTextarea from "../../atoms/ResumableTextarea";
import { useCallback, useEffect, useRef } from "react";
import {
  generateStorageKey,
  useResumableTextarea,
} from "../../atoms/ResumableTextarea/useResumableTextarea";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";

interface Props extends WithRouterProps<AuthenticateRouterProps> {
  student: StudentInterface;
  karteEditState: StudentKarteEditStateInterface;
  updateKarte: HandleThunkActionCreator<typeof updateKarte>;
}

interface KarteAttachmentValue extends KarteAttachment {
  shouldBeDeleted: boolean;
}

interface KarteImageAttachmentValue extends KarteImageAttachment {
  shouldBeDeleted: boolean;
}

interface Values {
  name: string;
  comment: string;
  existingImageAttachments: KarteImageAttachmentValue[];
  existingFileAttachments: KarteAttachmentValue[];
  karteImageAttachments?: File[];
  karteFileAttachments?: File[];
}

const StudentKarteEditForm: React.FC<Props> = (props) => {
  const prevSubmitting = useRef(props.karteEditState.submitting);

  const values = mapPropsToValues(props);

  const { clearValue: clearCommentStorage, ...resumableTextareaProps } =
    useResumableTextarea({
      value: values.comment,
      key: generateStorageKey([
        "kartes",
        props.student.id,
        props.karteEditState.data?.id || "null",
      ]),
      confirmMessage:
        "編集途中のカルテを復元しますか？\n（「キャンセル」を選択すると復元内容が破棄されます。）",
    });

  const handleSubmit = useCallback(
    (values: Values) => {
      const { updateKarte, student, karteEditState, navigate, location } =
        props;
      if (student && karteEditState.data) {
        updateKarte(
          student.id,
          karteEditState.data.id,
          valuesToParams(values),
          () => {
            clearCommentStorage();

            // 遷移元のページをbacktoから判断して戻る
            // 指定がなければ生徒カルテ一覧に戻る
            const query = new URLSearchParams(location.search);
            const backto = query.get("backto") ?? "sections";

            if (backto === "sections") {
              navigate(`/sections/${student.section.id}/kartes`);
            } else {
              navigate(`/students/${student.id}/kartes`);
            }
          },
        );
      }
    },
    [
      props.updateKarte,
      props.student,
      props.karteEditState.data,
      props.navigate,
      clearCommentStorage,
    ],
  );

  const formik = useFormik<Values>({
    initialValues: values,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const { isResumed, resumedValue } = resumableTextareaProps;
    if (isResumed && resumedValue) {
      formik.getFieldHelpers("comment").setValue(resumedValue);
    }
  }, [resumableTextareaProps.isResumed, resumableTextareaProps.resumedValue]);

  useEffect(() => {
    if (prevSubmitting.current && !props.karteEditState.submitting) {
      formik.setSubmitting(false);
    }
    prevSubmitting.current = props.karteEditState.submitting;
  }, [formik.setSubmitting, props.karteEditState.submitting]);

  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.header}>
          <div className={styles.staffName}>
            <Label
              htmlFor={"StudentKarteForm-staffName-input"}
              isMute={true}
              isInline={true}
            >
              スタッフ名
            </Label>
            {renderStaffNameError(formik)}
            <Input
              id="StudentKarteForm-staffName-input"
              name="name"
              hasError={shouldDisplayError(formik, "name")}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.comment}>
            <Label
              htmlFor={"StudentKarteForm-comment-textarea"}
              isInline={true}
            >
              コメント
            </Label>
            {renderCommentError(formik)}
            {props.karteEditState.data && (
              <ResumableTextarea
                id="StudentKarteForm-comment-textarea"
                name="comment"
                hasError={shouldDisplayError(formik, "comment")}
                rows={3}
                placeholder="コメントを入力"
                value={formik.values.comment}
                onChange={formik.handleChange}
                {...resumableTextareaProps}
              />
            )}
          </div>
        </div>

        {renderExistingImages(formik)}
        {renderExistingFiles(formik)}
        {renderApiErrors(props)}

        <div className={styles.footer}>
          {renderAttachments(props, formik)}
          <Button type="submit" disabled={props.karteEditState.submitting}>
            登録
          </Button>
        </div>
      </form>
    </div>
  );
};

const renderAttachments = (props: Props, formik: FormikProps<Values>) => {
  if (props.student.canCreateKarteAttachment) {
    return (
      <React.Fragment>
        <div className={styles.attachments}>
          {renderImageNames(formik)}
          {renderAttachmentNames(formik)}
        </div>
        <InputIcon
          iconName="icon-picture-img"
          inputAttributes={{
            accept: "image/jpeg,image/gif,image/png",
            id: "karte_image_attachments_attributes",
            type: "file",
          }}
          className={styles.footer__icon}
          tooltipLabel="画像の追加( jpg,png,gif )"
          onChange={handleChangeImageAttachment(formik)}
        />
        <InputIcon
          iconName="icon-file"
          inputAttributes={{
            id: "karte_attachments_attributes",
            type: "file",
          }}
          className={styles.footer__icon}
          tooltipLabel="ファイルの追加( PDF,Word,Excel,PPT )"
          onChange={handleChangeAttachment(formik)}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const renderAttachmentNames = (formik: FormikProps<Values>) => {
  const { karteFileAttachments } = formik.values;

  if (karteFileAttachments) {
    return karteFileAttachments.map((attachment: File, i: number) => {
      return (
        <div
          className={styles.filename}
          key={`StudentKarteForm-attachmentNames-${i}`}
        >
          <DeprecatedTagButton
            label={FileHelper.truncateFilename(attachment.name, 30)}
            iconName="icon-close-x"
            theme="lightgray"
            onClick={handleAttachmentDelete(formik, i)}
          />
        </div>
      );
    });
  }
};

const renderImageNames = (formik: FormikProps<Values>) => {
  const { karteImageAttachments } = formik.values;

  if (karteImageAttachments) {
    return karteImageAttachments.map((imageAttachment: File, i: number) => {
      return (
        <div
          className={styles.filename}
          key={`StudentKarteForm-imageNames-${i}`}
        >
          <DeprecatedTagButton
            label={FileHelper.truncateFilename(imageAttachment.name, 30)}
            iconName="icon-close-x"
            theme="lightgray"
            onClick={handleImageAttachmentDelete(formik, i)}
          />
        </div>
      );
    });
  }
};

const renderApiErrors = (props: Props) => {
  return (
    <div className={styles.apiErrors}>
      {props.karteEditState.errors.map(
        (error: ApiErrorInterface, i: number) => {
          return (
            <p
              key={`StudentKarteForm-apiErrors-${i}`}
              className={styles.apiErrors__error}
            >
              {error.message}
            </p>
          );
        },
      )}
    </div>
  );
};

const renderStaffNameError = (formik: FormikProps<Values>) => {
  if (shouldDisplayError(formik, "name")) {
    return <p className={styles.staffName__error}>{formik.errors.name}</p>;
  } else {
    return null;
  }
};

const renderCommentError = (formik: FormikProps<Values>) => {
  if (shouldDisplayError(formik, "comment")) {
    return <p className={styles.staffName__error}>{formik.errors.comment}</p>;
  } else {
    return null;
  }
};

const renderExistingImages = (formik: FormikProps<Values>) => {
  const imageAttachments = formik.values.existingImageAttachments.filter(
    (image) => !image.shouldBeDeleted,
  );

  return (
    <SquareImageList
      keys={imageAttachments.map((image) => image.id)}
      imageUrls={imageAttachments.map((image) => image.presignedUrl)}
      deletable={true}
      onDelete={handleExistingImageAttachmentDelete(formik)}
    />
  );
};

const renderExistingFiles = (formik: FormikProps<Values>) => {
  const fileAttachments = formik.values.existingFileAttachments.filter(
    (fileAttachment) => !fileAttachment.shouldBeDeleted,
  );

  return (
    <KarteFileAttachmentList
      fileAttachments={fileAttachments}
      keys={fileAttachments.map(
        (fileAttachment) =>
          `StudentKarteEditForm-KarteFileAttachmentList-${fileAttachment.id}`,
      )}
      deletable={true}
      onDelete={handleExistingFileAttachmentDelete(formik)}
    />
  );
};

const handleAttachmentDelete =
  (formik: FormikProps<Values>, index: number) => () => {
    const { setFieldValue } = formik;

    if (!formik.values.karteFileAttachments) {
      return;
    }

    formik.values.karteFileAttachments.splice(index, 1);
    setFieldValue(`karteFileAttachments`, formik.values.karteFileAttachments);
  };

const handleImageAttachmentDelete =
  (formik: FormikProps<Values>, index: number) => () => {
    const { setFieldValue } = formik;

    if (!formik.values.karteImageAttachments) {
      return;
    }

    formik.values.karteImageAttachments.splice(index, 1);
    setFieldValue(`karteImageAttachments`, formik.values.karteImageAttachments);
  };

const handleExistingImageAttachmentDelete =
  (formik: FormikProps<Values>) => (index: number) => (e: MouseEvent) => {
    e.preventDefault();
    const { setFieldValue } = formik;

    const visibleImageAttachments =
      formik.values.existingImageAttachments.filter(
        (imageAttachment) => !imageAttachment.shouldBeDeleted,
      );
    visibleImageAttachments[index].shouldBeDeleted = true;

    setFieldValue(
      `existingImageAttachments`,
      formik.values.existingImageAttachments,
    );
  };

const handleExistingFileAttachmentDelete =
  (formik: FormikProps<Values>) => (index: number) => (e: MouseEvent) => {
    e.preventDefault();
    const { setFieldValue } = formik;

    const visibleFileAttachments = formik.values.existingFileAttachments.filter(
      (fileAttachment) => !fileAttachment.shouldBeDeleted,
    );
    visibleFileAttachments[index].shouldBeDeleted = true;

    setFieldValue(
      `existingFileAttachments`,
      formik.values.existingFileAttachments,
    );
  };

const handleChangeAttachment =
  (formik: FormikProps<Values>) => (e: React.ChangeEvent<any>) => {
    const file = e.currentTarget.files[0];
    const currentIndex = formik.values.karteFileAttachments
      ? formik.values.karteFileAttachments.length
      : 0;
    formik.setFieldValue(`karteFileAttachments[${currentIndex}]`, file);
  };

const handleChangeImageAttachment =
  (formik: FormikProps<Values>) => (e: React.ChangeEvent<any>) => {
    const file = e.currentTarget.files[0];
    const currentIndex = formik.values.karteImageAttachments
      ? formik.values.karteImageAttachments.length
      : 0;
    formik.setFieldValue(`karteImageAttachments[${currentIndex}]`, file);
  };

const mapPropsToValues = (props: Props): Values => {
  const { data } = props.karteEditState;

  if (data) {
    return {
      name: data.staffName,
      comment: data.comment,
      existingImageAttachments: data.imageAttachments.map(
        (imageAttachment) => ({ ...imageAttachment, shouldBeDeleted: false }),
      ),
      existingFileAttachments: data.fileAttachments.map((fileAttachment) => ({
        ...fileAttachment,
        shouldBeDeleted: false,
      })),
    };
  } else {
    return {
      name: "",
      comment: "",
      existingImageAttachments: [],
      existingFileAttachments: [],
    };
  }
};

const valuesToParams = (values: Values): any => {
  const params: any = {
    "karte[name]": values.name,
    "karte[comment]": values.comment,
  };

  // 既存の値は削除したいときだけparamsに含める
  if (values.existingImageAttachments) {
    values.existingImageAttachments.forEach(
      (attachment: KarteImageAttachmentValue, i: number) => {
        if (attachment.shouldBeDeleted) {
          params[`karte[karte_image_attachments_attributes][${i}]][id]`] =
            attachment.id;
          params[`karte[karte_image_attachments_attributes][${i}]][_destroy]`] =
            "true";
        }
      },
    );
  }

  if (values.existingFileAttachments) {
    values.existingFileAttachments.forEach(
      (attachment: KarteAttachmentValue, i: number) => {
        if (attachment.shouldBeDeleted) {
          params[`karte[karte_attachments_attributes][${i}][id]`] =
            attachment.id;
          params[`karte[karte_attachments_attributes][${i}][_destroy]`] =
            "true";
        }
      },
    );
  }

  // フォームに入力された値
  if (values.karteImageAttachments) {
    values.karteImageAttachments.forEach((imageAttachment: File, i: number) => {
      params[
        `karte[karte_image_attachments_attributes][${
          i + values.existingImageAttachments.length
        }][file]`
      ] = imageAttachment;
    });
  }

  if (values.karteFileAttachments) {
    values.karteFileAttachments.forEach((attachment: File, i: number) => {
      params[
        `karte[karte_attachments_attributes][${
          i + values.existingFileAttachments.length
        }][file]`
      ] = attachment;
    });
  }

  return params;
};

const shouldDisplayError = (
  formik: FormikProps<Values>,
  key: string,
): boolean => {
  const meta = formik.getFieldMeta(key);
  const { touched, error } = meta;

  return touched && !!error;
};

const actions = {
  updateKarte,
};

export default withRouter<Props>(connect(null, actions)(StudentKarteEditForm));
