import classNames from "classnames";
import * as React from "react";
import { useState } from "react";

import Icon from "../Icon";
import styles from "./styles.scss";

export interface Label {
  on: string;
  off: string;
}

type Props = React.AriaAttributes & {
  value: boolean;
  onChange?:
    | ((value: boolean) => Promise<boolean>)
    | ((value: boolean) => void);
  label?: Label;
};

const SwitchButton = (props: Props): React.ReactElement<Props> => {
  const [isChanging, setChanging] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!props.onChange) {
      return;
    }
    if (isChanging) {
      return;
    }
    e.preventDefault();

    const result = props.onChange(!props.value);
    if (result) {
      setChanging(true);
      result.finally(() => {
        setChanging(false);
      });
    }
  };

  return (
    <div
      className={classNames(styles.switchButton, {
        [styles.disabled]: isChanging,
        [styles.checked]: props.value,
      })}
      onClick={handleClick}
      aria-label={props["aria-label"]}
    >
      <div className={styles.toggle}>
        <Icon name={props.value ? "icon-toggle-on" : "icon-toggle-off"} />
      </div>
      <div className={styles.label}>{renderLabel(props)}</div>
    </div>
  );
};

const renderLabel = (props: Props) => {
  if (props.value) {
    return props.label?.on || "オン";
  } else {
    return props.label?.off || "オフ";
  }
};

export default SwitchButton;
