import { LearningMaterial } from "./LearningMaterial";

export type LearningMaterialProgress = {
  learningMaterial: LearningMaterial;
  amount: Progress;
  numberOfSeconds: Progress;
};

export type Progress = {
  plan: number;
  result: number;
  ratio: number;
};

export const fromJson = (json: unknown): LearningMaterialProgress => {
  return json as LearningMaterialProgress;
};

export const toPercent = (progress: Progress): number => {
  const percent = progress.ratio * 100;
  return percent > 100 ? 100 : Math.floor(percent);
};

export const hasStudyScheduleWithTime = (
  learningMaterialProgress: LearningMaterialProgress,
) => learningMaterialProgress.numberOfSeconds.plan > 0;

export const hasStudyScheduleWithAmount = (
  learningMaterialProgress: LearningMaterialProgress,
) => learningMaterialProgress.amount.plan > 0;

export const hasProgressWithTime = (
  learningMaterialProgress: LearningMaterialProgress,
) => learningMaterialProgress.numberOfSeconds.result > 0;

export const hasProgressWithAmount = (
  learningMaterialProgress: LearningMaterialProgress,
) => learningMaterialProgress.amount.result > 0;
