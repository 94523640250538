import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../../api";
import { toLectureAttedanceForSectionFromJson } from "../../../domain-adapters/LectureAttendance";
import { LectureAttendanceForSection } from "../../../domains/LectureAttendance";
import { HTTPErrors, createError } from "../../../errors";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import ApiResponse from "../../../interfaces/ApiResponse";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useMemo } from "react";

export type ResponseData = ApiResponse<LectureAttendanceForSection[]>;

type Props = {
  section: SectionInterface;
  lectureSessionId: string;
};

export const useFetchLectureAttendances = ({
  section,
  lectureSessionId,
}: Props) => {
  const result = useInfiniteQuery<ResponseData, HTTPErrors>({
    queryKey: cacheKeyOf(section, lectureSessionId),
    queryFn: async ({ pageParam }) => {
      const response = await api.interruptGet(
        urlOf(section, lectureSessionId),
        { query: { page: pageParam } },
      );
      if (response.ok) {
        const json = await response.json();
        return {
          meta: json.lectureAttendances.meta,
          data: json.lectureAttendances.data.map(
            (lectureAttendance: Record<string, any>) =>
              toLectureAttedanceForSectionFromJson(lectureAttendance),
          ),
        };
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });
  const data = useMemo(
    () => result.data?.pages.flatMap((page) => page.data),
    [result],
  );
  return { ...result, data };
};

export const cacheKeyOf = (
  section: SectionInterface,
  lectureSessionId: string,
) => ["lectureAttendances", section.id, lectureSessionId];

const urlOf = (section: SectionInterface, lectureSessionId: string) =>
  `/api/v1/sections/${section.id}/lecture_sessions/${lectureSessionId}/lecture_attendances`;
