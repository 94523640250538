import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../../components/atoms/Flex";
import { Text } from "../../../components/general/Text";
import styles from "./AttendeesMenu.scss";
import {
  useBatchActionDispatchContext,
  useBatchActionStateContext,
} from "./AttendBatchActionContext";
import { useModalDispatchContext } from "./UpdateModalActionContext";
import DropDown from "../../../components/atoms/Dropdown";
import Icon from "../../../components/atoms/Icon";

const useAttendeesMenu = () => {
  const { targets } = useBatchActionStateContext();
  const { openBatchActionAbsentModal, openBatchActionAttendModal } =
    useModalDispatchContext();

  const { removeAll: clearAlltargets } = useBatchActionDispatchContext();

  const count = targets.length;

  const onClickAbsent = () => {
    if (count > 0) {
      openBatchActionAbsentModal();
    }
  };
  const onClickAttend = () => {
    if (count > 0) {
      openBatchActionAttendModal();
    }
  };
  return {
    onClickAbsent,
    onClickAttend,
    clearAlltargets,
    count,
    disabled: targets.length === 0,
  };
};

export const AttendeesMenu = () => {
  const { count, disabled, onClickAbsent, onClickAttend, clearAlltargets } =
    useAttendeesMenu();

  return (
    <div className={styles.AttendeesMenu}>
      {count > 0 && <Text>{count}件の対象者を選択中</Text>}
      <Flex marginTop="0" alignItems="center" gap="1.6">
        <div>
          <DropDown.Container disabled={disabled}>
            <DropDown.Label>
              <Button
                variant={disabled ? "outline" : "filled"}
                disabled={disabled}
                size="sm"
              >
                <Flex marginTop="0" alignItems="center" gap="0.8">
                  <span>一括操作</span>
                  <div className={styles.BatchActionButtonIconWrapper}>
                    <Icon name="icon-arrow-down" />
                  </div>
                </Flex>
              </Button>
            </DropDown.Label>

            <DropDown.List className={styles.BatchActionMenu}>
              <ul>
                <li>
                  <div
                    className={styles.BatchActionMenuItem}
                    role="button"
                    onClick={onClickAttend}
                  >
                    出席に変更
                  </div>
                </li>
                <li>
                  <div
                    className={styles.BatchActionMenuItem}
                    role="button"
                    onClick={onClickAbsent}
                  >
                    欠席に変更
                  </div>
                </li>
              </ul>
            </DropDown.List>
          </DropDown.Container>
        </div>
        <Button
          onClick={clearAlltargets}
          variant="outline"
          disabled={disabled}
          size="sm"
        >
          選択をクリア
        </Button>
      </Flex>
    </div>
  );
};
