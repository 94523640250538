import styles from "./styles.scss";
import DeleteIcon from "../DeleteIcon";

interface Props {
  keys: string[];
  imageUrls: string[];
  deletable?: boolean;
  onDelete?: (index: number) => (e: any) => void;
}

const SquareImageList = (props: Props) => {
  const { imageUrls } = props;

  if (imageUrls.length > 0) {
    return <div className={styles.root}>{renderImages(props)}</div>;
  } else {
    return null;
  }
};

const renderDeleteIcon = (props: Props, i: number) => {
  if (props.deletable && props.onDelete) {
    return (
      <span className={styles.deleteIcon}>
        <DeleteIcon onDelete={props.onDelete(i)} />
      </span>
    );
  } else {
    return null;
  }
};

const renderImages = (props: Props) => {
  const { keys, imageUrls } = props;

  return imageUrls.map((imageUrl: string, i: number) => {
    return (
      <a
        href={imageUrl}
        target="_blank"
        rel="noopener noreferrer"
        key={keys[i]}
        className={styles.imageWrapper}
      >
        <img src={imageUrl} className={styles.image} />
        {renderDeleteIcon(props, i)}
      </a>
    );
  });
};

export default SquareImageList;
