import styles from "./styles.scss";
import SectionInterface from "../../../../interfaces/SectionInterface";
import SectionTagItem from "../SectionTagItem";
import StudentTag from "../../../../domains/StudentTag";
import { HandleThunkActionCreator } from "react-redux";
import {
  updateTag,
  deleteTag,
} from "../../../../actions/pages/SectionsSettingsTagsPage/actions";
import SectionsSettingsTagsStateInterface from "../../../../interfaces/SectionsSettingsTagsState";

interface Props {
  section: SectionInterface;
  tags: StudentTag[];
  sectionsSettingsTagsState: SectionsSettingsTagsStateInterface;
  updateTag: HandleThunkActionCreator<typeof updateTag>;
  deleteTag: HandleThunkActionCreator<typeof deleteTag>;
  onSubmitCallback: (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => void;
}

const SectionTagList = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>タグ名</div>
      {renderItems(props)}
    </div>
  );
};

const renderItems = (props: Props) => {
  const {
    section,
    tags,
    sectionsSettingsTagsState,
    updateTag,
    deleteTag,
    onSubmitCallback,
  } = props;

  if (tags.length == 0) {
    return <p className={styles.notfound}>タグが登録されていません</p>;
  }

  return tags.map((tag: StudentTag) => {
    return (
      <div key={`sectionTag-${tag.id}`}>
        <SectionTagItem
          sectionId={section.id}
          tag={tag}
          submitting={sectionsSettingsTagsState.submitting}
          apiErrors={sectionsSettingsTagsState.apiError[tag.id] || []}
          updateTag={updateTag}
          deleteTag={deleteTag}
          onSubmitCallback={onSubmitCallback}
        />
      </div>
    );
  });
};

export default SectionTagList;
