import * as React from "react";
import styles from "./styles.scss";
import BackLink from "../../../../components/atoms/BackLink";
import { CourseSummary } from "./CourseSummary";
import { StudentList } from "./StudentList";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import { Flex } from "../../../../components/atoms/Flex";
import { Link } from "react-router-dom";
import { useSectionsSettingsCourseStudents } from "./useSectionsSettingsCourseStudents";
import { MainSection } from "../../../../components/atoms/MainSection";
import Loader from "../../../../components/atoms/Loader";
import { SelectDistributeStudentModal } from "../SelectDistributeTagModal";
import { OutletContextProps } from "../../../../helpers/RouterHelper";

export const SectionsSettingsCourseStudents = (props: OutletContextProps) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const {
    course,
    courseStudents,
    isDeleting,
    isCourseLoading,
    isCourseStudentsLoading,
    onClickDelete,
    isModalOpen,
    openModal,
    closeModal,
    sectionId,
    onFetchNextPage,
  } = useSectionsSettingsCourseStudents();

  if (isCourseLoading || !course) return <Loader loading={true} />;

  return (
    <BlockRow marginTop="1.6rem">
      <div className={styles.backLink}>
        <BackLink path={`/sections/${sectionId}/settings/content_courses`}>
          戻る
        </BackLink>
      </div>
      <MainSection>
        <CourseSummary
          course={course}
          isSubmitting={isDeleting}
          onClickDelete={onClickDelete}
          onClickRegisterTag={openModal}
        />
        <StudentList
          courseStudents={courseStudents}
          isLoading={isCourseStudentsLoading}
          onFetchNextPage={onFetchNextPage}
        />
        <Flex marginTop="2.4rem" justifyContent="flex-end">
          <Link
            to={`/sections/${sectionId}/settings/content_courses`}
            className={`buiButtonBase buiButtonVariantFilled buiButtonSizeLg ${styles.contentCourseLinkButton}`}
          >
            コース一覧
          </Link>
        </Flex>
      </MainSection>
      <SelectDistributeStudentModal
        isOpen={isModalOpen}
        onCloseModal={closeModal}
        sectionId={sectionId}
        contentCourse={course}
      />
    </BlockRow>
  );
};
