import classNames from "classnames";
import * as React from "react";

import styles from "./styles.scss";

interface Props {
  className?: string;
}

const ContentBox = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.root, props.className)}>
      {props.children}
    </div>
  );
};

export default ContentBox;
