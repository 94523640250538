import * as React from "react";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";
import { AnalyticsLearningMaterialTagFilter } from "../../components/features/AnalyticsLearningMaterialTagFilter";
import { useLearningMaterialFilterWithLocalStorageContext } from "../../components/features/LearningMaterialFilter/useLearningMaterialFilterWithLocalStorageContext";
import ContentBox from "../../components/atoms/ContentBox";
import { useFetchSectionDrillRetentionLevel } from "./useFetchSectionDrillRetentionLevel";
import Loader from "../../components/atoms/Loader";
import { RetentionLevelTable } from "./RetentionLevelTable";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import {
  StatusFilter,
  TagFilter,
} from "../../components/features/NewStudentFilter";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { FilterWrapper } from "../../components/features/FilterWrapper";

type Props = OutletContextProps & { section: SectionInterface };

const SectionsAnalayticsRetentionLevelPage_ = ({
  section,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useSectionsAnalyticsRetentionLevelPage({ sectionId: section.id });

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="retentionLevel"
      />
      <div className="mb-6 mt-8">
        <FilterWrapper
          StudentStatusFilter={<StatusFilter />}
          StudentTagFilter={<TagFilter sectionId={section.id} label="生徒" />}
          LearningMaterialTagFilter={
            <AnalyticsLearningMaterialTagFilter
              context={useLearningMaterialFilterWithLocalStorageContext()}
              section={section}
              withNotSetTag={true}
              label="教材"
              colorType="light-blue"
              variants="outline"
              withStudentFilter
              isInitializeByQueryParams={true}
              isUseLocalStorage={true}
            />
          }
        />
      </div>
      {isLoading || data == null ? (
        <Loader loading />
      ) : (
        <ContentBox className="overflow-hidden p-0">
          {data && <RetentionLevelTable sectionId={section.id} data={data} />}
        </ContentBox>
      )}
      {isFetchingNextPage && <Loader loading />}
    </>
  );
};

type UseSectionsAnalyticsRetentionLevelPageProps = {
  sectionId: string;
};
const useSectionsAnalyticsRetentionLevelPage = ({
  sectionId,
}: UseSectionsAnalyticsRetentionLevelPageProps) => {
  const learningMaterialFilterContext =
    useLearningMaterialFilterWithLocalStorageContext();
  const { findTagFilter } = useStudentFilterContext();
  const studentFilter = findTagFilter({ sectionId });

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useFetchSectionDrillRetentionLevel({
      sectionId: sectionId,
      tags: learningMaterialFilterContext.items,
      studentFilter,
    });

  return {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

const pageInfo = {
  title: "アナリティクス",
};

export const SectionsAnalyticsRetentionLevelPage = withRouter(
  enhanceSectionsPage(SectionsAnalayticsRetentionLevelPage_, pageInfo),
);
