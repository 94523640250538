import classNames from "classnames";
import * as React from "react";
// Helpers for helping us struggling with React

/**
 * コンテキストで管理する型(ContextType)を指定すると、その型応じたProviderとuseContextを生成して返すユーティリティ関数
 * @returns {Provider, useContext} コンテキストのProviderとuseContextのオブジェクト
 */
export const createContext = <ContextType,>() => {
  const context = React.createContext<ContextType | undefined>(undefined);

  const useContext = () => {
    const contextState = React.useContext(context);
    if (!contextState) {
      const error = new Error("This context might be used without Provider.");
      error.name = "ContextError";
      Error.captureStackTrace?.(error, useContext);
      throw error;
    }
    return contextState;
  };

  return { Provider: context.Provider, useContext };
};

type CreateComponentParams<Tag extends keyof JSX.IntrinsicElements = "div"> = {
  tag: Tag;
  className?: string;
};

type HTMLWrapperComponent<Tag extends keyof JSX.IntrinsicElements = "div"> = (
  props: JSX.IntrinsicElements[Tag],
) => React.ReactElement;

export const createComponent = <
  Tag extends keyof JSX.IntrinsicElements = "div",
>({
  tag: Comp,
  className: baseClassName,
}: CreateComponentParams<Tag>): HTMLWrapperComponent<Tag> => {
  return ({ className, ...props }) => {
    return (
      <Comp
        {...(props as any)}
        className={classNames(baseClassName, className)}
      />
    );
  };
};
