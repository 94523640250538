import * as yup from "yup";
import { useFormik } from "formik";
import { create, Content, nameLengthLimit } from "../../../domains/Content";
import { ERROR_STR_CONTENT_NAME } from "../SectionSettingsEditContentFiles/useContentFileForm";

type FormValues = {
  id: string;
  name: string;
  url: string;
};

export type ContentVideoFormProps = {
  initialContent: Content | null;
  onSubmit: (values: Content) => void;
};

const formValuesOf = (content: Content | null): FormValues => {
  if (!content) {
    return {
      id: "",
      name: "",
      url: "",
    };
  }
  return {
    id: content.id,
    name: content.name,
    url: content.url ?? "",
  };
};

export const useContentVideoForm = ({
  onSubmit,
  initialContent,
}: ContentVideoFormProps) => {
  const initialValues = formValuesOf(initialContent);
  return useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      onSubmit(
        create({ id: values.id, name: values.name.trim(), url: values.url }),
      );
    },
    validationSchema,
  });
};

export const videoRegExps: readonly RegExp[] = [
  /^https:\/\/www\.youtube\.com\/watch\?v=\S+$/,
  /^https:\/\/m\.youtube\.com\/watch\?v=\S+$/,
  /^https:\/\/youtube\.com\/watch\?v=\S+$/,
  /^https:\/\/youtu\.be\/\S+$/,
  /^https:\/\/vimeo\.com\/\S+$/,
  /^https:\/\/vimeo\.com\/channels\/\S+\/\S+$/,
  /^https:\/\/vimeo\.com\/groups\/\S+\/\S+$/,
  /^https:\/\/player\.vimeo\.com\/video\/\S+$/,
];

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("コンテンツ名の入力は必須です")
    .max(nameLengthLimit, ERROR_STR_CONTENT_NAME),
  url: yup
    .string()
    .required("動画URLの入力は必須です")
    .test(
      "VideoURLValidator",
      "動画の登録はYouTubeまたはVimeoのURLのみ利用可能です。",
      (strUrl) => videoRegExps.some((r) => (strUrl ? r.test(strUrl) : false)),
    ),
});
