import { SettingsEmailStateInterface } from "../../../interfaces/SettingsEmailStateInterface";

const defaultState: SettingsEmailStateInterface = {
  loading: false,
};

const settingsEmail = (
  state: SettingsEmailStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "PUT_EMAIL_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "PUT_EMAIL_SUCCESS":
    default:
      return {
        ...state,
        loading: false,
      };
  }
};

export default settingsEmail;
