import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import ApiClient from "../../../api";
import { Content, fromJson } from "../../../domains/Content";

type HttpErrors = "404ContentError" | "403ContentError" | Error;

type UseFetchContentProps = {
  sectionId: string;
  contentId: string;
};
export const useFetchContent = (props: UseFetchContentProps) => {
  const result = useQuery<Content, HttpErrors>({
    queryKey: [`sections/${props.sectionId}/contents/${props.contentId}`],
    queryFn: async () => {
      const res = await ApiClient.get(
        `/api/v1/sections/${props.sectionId}/contents/${props.contentId}`,
      );
      if (res.status === 200) {
        const json: Record<string, any> = await res.json();
        return fromJson(json.content);
      }
      if (res.status === 404) {
        throw "404ContentError";
      }
      if (res.status === 403) {
        throw "403ContentError";
      }
      throw Error("Something went wrong");
    },
    gcTime: 0, // 編集の場合、キャッシュのデータを見せてしまうと不具合になるため
    retry: 0, // 404とかの場合はリトライしたくないので
  });
  return result;
};

type UseMutateProps = {
  sectionId: string;
  onSuccess: () => void;
  onError: UseMutationOptions<
    Content,
    string,
    ContentMutationParams
  >["onError"];
};

export type ContentMutationParams = {
  contentId: string;
  name: string | null;
  file: File | null;
};
type RequestBody = {
  name?: string;
  file?: File;
};

export const useMutateContentFile = ({
  sectionId,
  onSuccess,
  onError,
}: UseMutateProps) => {
  const mutation = useMutation<Content, string, ContentMutationParams>({
    mutationFn: async ({ contentId, name, file }) => {
      const apiPath = `/api/v1/sections/${sectionId}/contents/${contentId}/files`;

      const requestBody: RequestBody = {};
      if (name) {
        requestBody.name = name;
      }
      if (file) {
        requestBody.file = file;
      }

      const response = await ApiClient.sendFormData(
        apiPath,
        "PATCH",
        requestBody,
      );

      if (response.ok) {
        const json = await response.json();
        return fromJson(json.content);
      } else {
        throw "ファイルを編集できませんでした";
      }
    },
    onSuccess,
    onError,
  });

  return {
    mutate: mutation.mutate,
    isMutating: mutation.isPending,
  };
};
