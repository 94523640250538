import ApiClient, { GetRequestOptions } from "../api";

const AnalyticsApi = {
  // 生徒アナリティクス
  interruptGetStudentTime: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/students/${studentId}/analytics/time`,
      options,
    );
  },

  interruptGetStudentAmount: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/students/${studentId}/analytics/amount`,
      options,
    );
  },

  getStudentTime: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/analytics/time`,
      options,
    );
  },

  getStudentAmount: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/analytics/amount`,
      options,
    );
  },

  postStudentShare: (
    studentId: string,
    message: string,
    image: Blob,
  ): Promise<Response> => {
    return ApiClient.sendFormData(
      `/api/v1/students/${studentId}/analytics/share`,
      "POST",
      { message, image },
    );
  },
};

export default AnalyticsApi;
