import { BlockRow } from "../../../components/atoms/BlockRow/index";
import {
  MainSection,
  PageHeader,
} from "../../../components/atoms/MainSection/index";
import { Flex } from "../../../components/atoms/Flex/index";
import { InlineBlock } from "../../../components/atoms/InlineBlock/index";
import { HelpLink } from "../../../components/atoms/HelpLink/index";
import SwitchButton from "../../../components/atoms/SwitchButton";
import { Text } from "../../../components/general/Text";
import { useMutateStayLineSettings } from "./useMutateStayLineSettings";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/5630465";

type Props = {
  sectionId: string;
  value: boolean | undefined;
};
const useStayLineSettingsToggleForm = ({
  sectionId,
}: Pick<Props, "sectionId">) => {
  const mutation = useMutateStayLineSettings({ sectionId });

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const handleSuccess = () => {
    showSuccessMessage("設定を更新しました");
  };

  const handleError = () => {
    showErrorMessage("設定が更新できませんでした");
  };

  const toggle = async (value: boolean) => {
    if (
      !window.confirm(
        `入退室自動通知を「${
          value ? "オン" : "オフ"
        }」にしますか？\n連携中の保護者の連絡先へ入退室時間の自動通知が${
          value ? "有効" : "無効"
        }になります。`,
      )
    ) {
      return !value;
    }

    const result = await mutation.mutateAsync(value);
    result.isSuccess ? handleSuccess() : handleError();

    return result.value;
  };

  return { toggle };
};

export const StayLineSettingsToggleForm = ({ sectionId, value }: Props) => {
  const { toggle } = useStayLineSettingsToggleForm({ sectionId });

  return (
    <MainSection>
      <Flex justifyContent="space-between" marginTop="0">
        <div>
          <PageHeader>
            <Text as="span" size="xl">
              入退室自動通知
            </Text>
            <InlineBlock marginLeft="2.4rem">
              <HelpLink to={HELP_URL}>
                <InlineBlock marginLeft="0.4rem">
                  <Text color="primary">入退室自動通知とは？</Text>
                </InlineBlock>
              </HelpLink>
            </InlineBlock>
          </PageHeader>
          <BlockRow marginTop="1rem">
            <Text size="xs">
              ※オンにすると保護者の連絡先へ入退室時間が通知されるようになります
            </Text>
          </BlockRow>
        </div>

        {value !== undefined ? (
          <SwitchButton
            value={value}
            label={{ on: "オン", off: "オフ" }}
            onChange={toggle}
            aria-label={`入退室自動通知を${value ? "オフ" : "オン"}にする`}
          />
        ) : null}
      </Flex>
    </MainSection>
  );
};
