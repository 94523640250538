import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { Examination } from "../../../domains/Examination";
import {
  ExaminationResult,
  ExaminationResultAttachmentFile,
  ExaminationResultAttachment,
} from "../../../domains/ExaminationResult";
import { useMutateExaminationResult } from "./useMutateExaminationResult";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useNavigate } from "react-router";
import { MAX_NUM_OF_IMAGES } from "../../../components/features/ExaminationResultFileAttachmentItem";
import { toStringFromDate } from "../../../helpers/TimeHelper";

export type ResultValue = {
  id: string;
  name: string;

  // ""があるのはフォーム上で入力後に消したりすると空文字列が入るため
  point: ExaminationResult["point"] | "" | null;
  deviation: ExaminationResult["deviation"] | "" | null;
  allocationOfMarks: ExaminationResult["allocationOfMarks"] | "" | null;
};
export type Values = {
  organizerId: string | null;
  classificationId: string | null;
  examination: Examination | null;
  examinedOn: Date;
  totalResult: ResultValue;
  subjectResults: { [id: string]: ResultValue };
  // DBに登録済みのものはidが振られているが、これから登録しようとしているattachmentにはidは存在しない
  resultAttachments: { id?: ExaminationResultAttachment["id"]; file: File }[];
  numberOfImages: number;
  attachmentsToBeRemoved: {
    id: ExaminationResultAttachment["id"];
    file: File;
  }[];
};

export const defaultValues: Values = {
  organizerId: null,
  classificationId: null,
  examination: null,
  examinedOn: new Date(),
  totalResult: {
    id: "total",
    name: "総合",
    point: null,
    deviation: null,
    allocationOfMarks: null,
  },
  subjectResults: {},
  resultAttachments: [],
  numberOfImages: 0,
  attachmentsToBeRemoved: [],
};

const POINT_MAX = 9999;
const DEVIATION_MAX = 99.9;
const ALLOCATION_OF_MARKS_MAX = 9999;
const oneMB = 1048576;
const validationAttachmentsSize = (
  attachments?: ExaminationResultAttachmentFile[],
) => {
  if (!attachments || attachments.length === 0) return true;

  const attachmentsSize = attachments
    .filter((attachment) => !attachment.id)
    .map((attachment) => attachment.file)
    .reduce((acc: number, attachment: File) => {
      return acc + attachment.size;
    }, 0);

  return attachmentsSize <= 100 * oneMB;
};
const validationNumberOfImages = (numberOfImages?: number) => {
  if (!numberOfImages) return true;
  return numberOfImages <= MAX_NUM_OF_IMAGES;
};
const validationSchema = yup.object().shape({
  organizerId: yup.string().nullable().required("主催者を選択してください"),
  classificationId: yup
    .string()
    .nullable()
    .required("試験の種類を選択してください"),
  examination: yup
    .object()
    .nullable()
    .required("試験名を選択してください")
    .shape({
      id: yup.string().nullable().required("試験名を選択してください"),
    }),
  totalResult: yup.object().shape({
    point: yup
      .number()
      .typeError("得点は半角数字で入力してください")
      .nullable()
      .min(0, `得点は0〜${POINT_MAX}の値を指定してください`)
      .max(POINT_MAX, `得点は0〜${POINT_MAX}の値を指定してください`),
    allocationOfMarks: yup
      .number()
      .typeError("配点は半角数字で入力してください")
      .nullable()
      .min(0, `配点は0〜${ALLOCATION_OF_MARKS_MAX}の値を指定してください`)
      .max(
        ALLOCATION_OF_MARKS_MAX,
        `配点は0〜${ALLOCATION_OF_MARKS_MAX}の値を指定してください`,
      ),
    deviation: yup
      .number()
      .typeError("偏差値は半角数字で入力してください")
      .nullable()
      .min(0, `偏差値は0〜${DEVIATION_MAX}の値を指定してください`)
      .max(DEVIATION_MAX, `偏差値は0〜${DEVIATION_MAX}の値を指定してください`),
  }),
  resultAttachments: yup.array().test({
    message: "一度にアップロードできる容量は100MBまでです",
    test: validationAttachmentsSize,
  }),
  numberOfImages: yup.number().test({
    message: `一度にアップロードできる画像は${MAX_NUM_OF_IMAGES}個までです`,
    test: validationNumberOfImages,
  }),
  // subjectResultsは動的に生成するのでここではバリデーションせず
  // validateSubjectResultsで行う
});
type SubjectResultErrors = { [id: string]: FormikErrors<ResultValue> };
const validateSubjectResults = (values: Values): FormikErrors<Values> => {
  const errors: SubjectResultErrors = {};
  Object.values(values.subjectResults).forEach((subjectResult: ResultValue) => {
    if (isValuePresent(subjectResult.point)) {
      const point = Number(subjectResult.point);

      if (isNaN(point)) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          point: `得点は半角数字で入力してください`,
        };
      }

      if (point > POINT_MAX || point < 0) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          point: `得点は0〜${POINT_MAX}の値を指定してください`,
        };
      }
    }

    if (isValuePresent(subjectResult.allocationOfMarks)) {
      const allocationOfMarks = Number(subjectResult.allocationOfMarks);

      if (isNaN(allocationOfMarks)) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          allocationOfMarks: `配点は半角数字で入力してください`,
        };
      }

      if (
        allocationOfMarks > ALLOCATION_OF_MARKS_MAX ||
        allocationOfMarks < 0
      ) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          allocationOfMarks: `配点は0〜${ALLOCATION_OF_MARKS_MAX}の値を指定してください`,
        };
      }
    }

    if (isValuePresent(subjectResult.deviation)) {
      const deviation = Number(subjectResult.deviation);

      if (isNaN(deviation)) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          deviation: `偏差値は半角数字で入力してください`,
        };
      }

      if (deviation > DEVIATION_MAX || deviation < 0) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          deviation: `偏差値は0〜${DEVIATION_MAX}の値を指定してください`,
        };
      }
    }

    if (
      isValuePresent(subjectResult.point) &&
      isValuePresent(subjectResult.allocationOfMarks)
    ) {
      const point = Number(subjectResult.point);
      const allocationOfMarks = Number(subjectResult.allocationOfMarks);

      if (point > allocationOfMarks) {
        errors[subjectResult.id] = {
          ...errors[subjectResult.id],
          point: `得点は配点以下の値にしてください`,
        };
      }
    }

    // SPに合わせて得点:0、配点:未入力の場合はエラーにしない(得点が1以上の場合はエラーにする)
    if (
      isValuePresent(subjectResult.point) &&
      Number(subjectResult.point) > 0 &&
      !isValuePresent(subjectResult.allocationOfMarks)
    ) {
      errors[subjectResult.id] = {
        ...errors[subjectResult.id],
        allocationOfMarks: `配点を入力してください`,
      };
    }
  });

  return Object.keys(errors).length > 0 ? { subjectResults: errors } : {};
};

const isValuePresent = (value: number | "" | null | undefined) => {
  return value !== "" && value !== null && value !== undefined;
};

const validateTotalResult = (values: Values): FormikErrors<Values> => {
  const errors: FormikErrors<Values> = {};

  // 総合

  // SPに合わせて得点:0、配点:未入力の場合はエラーにしない(得点が1以上の場合はエラーにする)
  if (
    isValuePresent(values.totalResult.point) &&
    Number(values.totalResult.point) > 0 &&
    !isValuePresent(values.totalResult.allocationOfMarks)
  ) {
    errors.totalResult = {
      allocationOfMarks: `配点を入力してください`,
    };
  }
  if (
    isValuePresent(values.totalResult.point) &&
    isValuePresent(values.totalResult.allocationOfMarks)
  ) {
    const point = Number(values.totalResult.point);
    const allocationOfMarks = Number(values.totalResult.allocationOfMarks);

    if (point > allocationOfMarks) {
      errors.totalResult = { point: `得点は配点以下の値にしてください` };
    }
  }

  return errors;
};

const validate = (values: Values): FormikErrors<Values> => {
  return {
    ...validateSubjectResults(values),
    ...validateTotalResult(values),
  };
};

type SubmitType = "create" | "update";
type UseFormProps = {
  studentId: string;
  submitType: SubmitType;
};
export const useExaminationResultForm = ({
  studentId,
  submitType,
}: UseFormProps) => {
  const { mutate, isPending } = useMutateExaminationResult({
    studentId,
    httpMethod: submitType === "create" ? "POST" : "PATCH",
  });
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const navigate = useNavigate();

  const onSubmit = (values: Values) => {
    if (values.examination !== null) {
      // 実装としてはbuildMutationParams(values)でいいのですが、
      // examinationの型が合わなくて型が不一致になるのでこのようにしています
      const params = buildMutationParams({
        ...values,
        examination: values.examination,
      });

      const action = submitType === "create" ? "成績を登録" : "成績を更新";
      mutate(params, {
        onSuccess: () => {
          showSuccessMessage(
            submitType === "create" ? `${action}しました` : `${action}しました`,
          );
          if (values.examination) {
            navigate(
              `/students/${studentId}/analytics/examinations/${values.examination.id}/result`,
            );
          }
        },
        onError: () => {
          showErrorMessage(`${action}できませんでした`);
        },
      });
    }
  };

  const formik = useFormik<Values>({
    initialValues: defaultValues,
    validationSchema,
    onSubmit,
    validate,
    // 初期はvalidとして登録ボタンを押せるようにしておく（とりあえず押してエラーにきづけるように）
    isInitialValid: true,
  });

  const resultAttachmentErrorTexts = [
    formik.errors.resultAttachments?.toString(),
    formik.errors.numberOfImages?.toString(),
  ].filter((error): error is string => error !== undefined);

  const handleChangeAttachments = ({
    files,
    numberOfImages,
  }: {
    files?: File[];
    numberOfImages?: number;
  }) => {
    // ファイル変更のとき
    if (files !== undefined) {
      const newAttachmentsToRemoved = formik.values.resultAttachments.filter(
        (resultAttachment) => {
          return (
            resultAttachment.id &&
            !formik.values.attachmentsToBeRemoved.find(
              (attachment) => attachment.file === resultAttachment.file,
            ) &&
            files.find((file) => file === resultAttachment.file) === undefined
          );
        },
      );

      const nextAttachmentsToRemoved = [
        ...newAttachmentsToRemoved,
        ...formik.values.attachmentsToBeRemoved,
      ];

      const existAttachments = formik.values.resultAttachments.filter(
        (attachment) =>
          attachment.id &&
          !nextAttachmentsToRemoved.some(
            (toBeRemoved) => toBeRemoved.id === attachment.id,
          ),
      );

      const newAttachments = files
        .filter(
          (file) =>
            !existAttachments.some((attachment) => attachment.file === file),
        )
        .map((file) => ({ file }));

      formik.setFieldValue("resultAttachments", [
        ...existAttachments,
        ...newAttachments,
      ]);
      formik.setFieldValue("attachmentsToBeRemoved", nextAttachmentsToRemoved);
    }

    // 画像数変わったとき
    if (numberOfImages !== undefined) {
      formik.setFieldValue("numberOfImages", numberOfImages);
    }
  };

  return {
    ...formik,
    isSubmitting: isPending,
    resultAttachmentErrorTexts,
    handleChangeAttachments,
  };
};

// 送信するときには試験は決まっている必要があるのでValues["examination"]のnullを許容していない
type MutationValues = Omit<Values, "examination"> & {
  examination: Examination;
};

const valueToParam = (value: number | "" | null) => {
  return value === "" ? null : value;
};
const buildMutationParams = (values: MutationValues) => {
  // 添付ファイルですでにidがあるものは送らない
  const newAttachments = values.resultAttachments
    .filter((attachment) => !attachment.id)
    .map((attachment) => ({ file: attachment.file }));

  const attachmentsToBeRemoved = values.attachmentsToBeRemoved.map(
    (attachment) => ({
      id: attachment.id,
      destroy: "true" as const,
    }),
  );

  return {
    examinationId: values.examination.id,
    point: valueToParam(values.totalResult.point),
    allocationOfMarks: valueToParam(values.totalResult.allocationOfMarks),
    deviation: valueToParam(values.totalResult.deviation),
    examinedOn: toStringFromDate(values.examinedOn),
    subjectResults: Object.values(values.subjectResults).map((result) => ({
      id: result.id,
      point: valueToParam(result.point),
      allocationOfMarks: valueToParam(result.allocationOfMarks),
      deviation: valueToParam(result.deviation),
    })),

    resultAttachments: [...newAttachments, ...attachmentsToBeRemoved],
  };
};
