import GuardianMessageInterface from "../../../../interfaces/GuardianMessageInterface";
import { useMutation } from "@tanstack/react-query";
import {
  createError,
  HTTPErrors,
  UnprocessableEntityError,
  UnprocessableEntityErrorItem,
} from "../../../../errors";
import ApiClient from "../../../../api";
import compressImage from "../../../../helpers/compressImage";

type Props = {
  sectionId: string;
  onSuccess: (response: GuardianMessageInterface[]) => void;
  onError: (error: any) => void;
};

type Params = {
  tagId: string;
  content?: string;
  file?: File;
};
export const usePostGuardianAnnounceMessage = ({
  sectionId,
  onSuccess,
  onError,
}: Props) => {
  return useMutation<GuardianMessageInterface[], HTTPErrors, Params>({
    mutationFn: async ({ tagId, content, file }) => {
      const res = await postGuardianAnnounceMessage(
        sectionId,
        tagId,
        content,
        file,
      );

      if (res.ok) {
        const json = await res.json();
        return json.guardianMessages;
      }

      if (res.status === 422) {
        const json = await res.json();
        throw new UnprocessableEntityError(
          json.errors as UnprocessableEntityErrorItem[],
        );
      }

      throw await createError(res);
    },
    onSuccess,
    onError,
  });
};

const postGuardianAnnounceMessage = (
  sectionId: string,
  tagId: string,
  content?: string,
  file?: File,
): Promise<Response> => {
  if (file) {
    return postGuardianAnnounceMessageFormData({
      sectionId,
      tagId,
      file,
      content,
    });
  }
  return postGuardianAnnounceMessageJson({
    sectionId,
    tagId,
    content: content ?? "",
  });
};

const postGuardianAnnounceMessageJson = ({
  sectionId,
  tagId,
  content,
}: {
  sectionId: string;
  tagId: string;
  content: string;
}): Promise<Response> => {
  return ApiClient.post(
    `/api/v1/sections/${sectionId}/guardian_announce_message_threads/${tagId}/messages`,
    {
      content,
    },
  );
};

const postGuardianAnnounceMessageFormData = async ({
  sectionId,
  tagId,
  content,
  file,
}: {
  sectionId: string;
  tagId: string;
  content?: string;
  file: File;
}): Promise<Response> => {
  const fileBody =
    file.type === "application/pdf"
      ? { file }
      : { image: await compressImage(file, { maxSizeMB: 0.5 }) };
  return ApiClient.sendFormData(
    `/api/v1/sections/${sectionId}/guardian_announce_message_threads/${tagId}/messages`,
    "POST",
    { ...fileBody, ...(content ? { content } : {}) },
  );
};
