import { AnyAction } from "redux";
import { createAction } from "redux-actions";
import { buildMainResourceApiErrorAction } from "../../common/errors";
import OperatorSettingsApi from "../../../api-clients/OperatorSettingsApi";
import { showSuccessFlashMessage } from "../../flashMessage";
import { ActionTypes as SessionActionTypes } from "../../common/session/types";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { ThunkDispatch } from "redux-thunk";
import ApiClient from "../../../api";
import AuthenticationsStateInterface from "../../../interfaces/AuthenticationsStateInterface";
import { ActionTypes as UserInfoActionTypes } from "../../authorization/userInfo";

const putEmailRequest = createAction<void>("PUT_EMAIL_REQUEST");
const putEmailSuccess = createAction<void>("PUT_EMAIL_SUCCESS");
const putEmailError = createAction<void>("PUT_EMAIL_ERROR");

export const putEmail =
  (token: string) =>
  (dispatch: ThunkDispatch<AppStateInterface, undefined, AnyAction>): void => {
    dispatch(putEmailRequest());

    OperatorSettingsApi.putEmail(token)
      .then((res: Response) => {
        if (res.ok) {
          dispatch({ type: SessionActionTypes.SIGN_OUT });
          dispatch(putEmailSuccess());
          dispatch(
            showSuccessFlashMessage(
              "メールアドレスが更新されました。再度ログインしてください",
            ),
          );

          ApiClient.get(`/api/v1/me`).then((meResponse) => {
            if (meResponse.ok) {
              meResponse.json().then((json: AuthenticationsStateInterface) => {
                dispatch({
                  type: UserInfoActionTypes.GET_USER_INFO_SUCCESS,
                  payload: json,
                });
              });
            } else {
              dispatch({ type: UserInfoActionTypes.GET_USER_INFO_ERROR });
            }
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(putEmailError());
        }
      })
      .catch(() => {
        dispatch(buildMainResourceApiErrorAction());
        dispatch(putEmailError());
      });
  };
