import * as React from "react";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
const reactStringReplace = require("react-string-replace");
const nl2br = require("react-nl2br");

type Props = {
  children: React.ReactText | null;
};
const LinkText: React.FC<Props> = (props) => {
  if (!props.children) return null;

  return reactStringReplace(
    nl2br(props.children),
    RegexpHelper.URL,
    (match: string): React.ReactNode => (
      <a href={match} target="_blank" rel="noopener noreferrer">
        {match}
      </a>
    ),
  );
};

export default LinkText;
