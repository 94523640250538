import { Action } from "redux";
import SkillMasterInterface from "../../../interfaces/SkillMasterInterface";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import StudyRecordSkillInterface from "../../../interfaces/StudyRecordSkillInterface";

export enum ActionTypes {
  POST_REQUEST = "sections/settings/skills/new/POST_REQUEST",
  POST_SUCCESS = "sections/settings/skills/new/POST_SUCCESS",
  POST_FAILURE = "sections/settings/skills/new/POST_FAILURE",
  CLOSE_MODAL = "sections/settings/skills/new/CLOSE_MODAL",
  GET_SKILL_MASTER = "sections/settings/skills/GET_SKILL_MASTER",
  GET_SKILL_MASTER_SUCCESS = "sections/settings/skills/GET_SKILL_MASTER_SUCCESS",
  GET_SKILL_MASTER_ERROR = "sections/settings/skills/GET_SKILL_MASTER_ERROR",
  UPDATE_REQUEST = "sections/settings/skills/edit/UPDATE_REQUEST",
  UPDATE_SUCCESS = "sections/settings/skills/edit/UPDATE_SUCCESS",
  UPDATE_FAILURE = "sections/settings/skills/edit/UPDATE_FAILURE",
  GET_REQUEST = "sections/settings/skills/edit/GET_REQUEST",
  GET_SUCCESS = "sections/settings/skills/edit/GET_SUCCESS",
  GET_FAILURE = "sections/settings/skills/edit/GET_FAILURE",
}

export type Actions =
  | PostRequestActions
  | PostSuccessActions
  | PostFailureActions
  | CloseModalActions
  | GetSkillMasterAction
  | GetSkillMasterSuccessAction
  | GetSkillMasterErrorAction
  | UpdateRequestActions
  | UpdateSuccessActions
  | UpdateFailureActions
  | GetRequestActions
  | GetSuccessActions
  | GetFailureActions;

interface PostRequestActions extends Action {
  type: ActionTypes.POST_REQUEST;
}
interface PostSuccessActions extends Action {
  type: ActionTypes.POST_SUCCESS;
}
interface PostFailureActions extends Action {
  type: ActionTypes.POST_FAILURE;
  payload?: ApiErrorResponseInterface;
}
interface CloseModalActions extends Action {
  type: ActionTypes.CLOSE_MODAL;
}
interface GetSkillMasterAction {
  type: ActionTypes.GET_SKILL_MASTER;
}
interface GetSkillMasterSuccessAction {
  type: ActionTypes.GET_SKILL_MASTER_SUCCESS;
  payload: {
    skillMaster: SkillMasterInterface;
  };
}
interface GetSkillMasterErrorAction {
  type: ActionTypes.GET_SKILL_MASTER_ERROR;
}
interface UpdateRequestActions {
  type: ActionTypes.UPDATE_REQUEST;
}
interface UpdateSuccessActions {
  type: ActionTypes.UPDATE_SUCCESS;
}
interface UpdateFailureActions {
  type: ActionTypes.UPDATE_FAILURE;
  payload?: ApiErrorResponseInterface;
}
interface GetRequestActions {
  type: ActionTypes.GET_REQUEST;
}
interface GetSuccessActions {
  type: ActionTypes.GET_SUCCESS;
  payload: { skill: StudyRecordSkillInterface };
}
interface GetFailureActions {
  type: ActionTypes.GET_FAILURE;
  payload?: ApiErrorResponseInterface;
}
