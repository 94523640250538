import { useState } from "react";
import styles from "./StudentsStayItem.scss";
import Icon from "../../components/atoms/Icon";
import { StudentStayUpdateModal } from "./StudentStayUpdateModal";
import { useDeleteStudentStay } from "./useStudentStayAnalyticsApi";
import StudentInterface from "../../interfaces/StudentInterface";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { StudentStayAnalytics } from "../../domains/StudentAnalytics";
import { Flex } from "../../components/atoms/Flex";
import LinkButton from "../../components/atoms/LinkButton";

type Props = {
  sectionId: string;
  student: StudentInterface;
  stay: StudentStayAnalytics;
};

type UseStudentStayUpdateModal = {
  isModalOpen: boolean;
  editingStay: StudentStayAnalytics | null;
  handleModalOpen: (stay: StudentStayAnalytics) => void;
  handleModalClose: () => void;
};

export const StudentStayItem = ({ sectionId, stay, student }: Props) => {
  const { showSuccessMessage } = useFlashMessage();
  const { mutate: deleteStudentStay } = useDeleteStudentStay({
    sectionId: sectionId,
    stayId: stay.id,
    student: student,
  });
  const {
    isModalOpen,
    editingStay,
    handleModalOpen,
    handleModalClose,
  }: UseStudentStayUpdateModal = useStudentStayUpdateModal();

  const onClickDelete = () => {
    if (!window.confirm(`この入退室履歴を削除しますか？`)) {
      return;
    }

    deleteStudentStay(
      {},
      {
        onSuccess() {
          showSuccessMessage("入退室記録を削除しました");
        },
      },
    );
  };

  return (
    <Flex gap="1" justifyContent="end" marginTop="0">
      <div className={styles.linkContainer}>
        <LinkButton onClick={() => handleModalOpen(stay)}>
          <Icon name="icon-edit" />
          編集
        </LinkButton>
      </div>
      <div className={styles.linkContainer}>
        <LinkButton onClick={() => onClickDelete()}>
          <Icon name="icon-trash" />
          削除
        </LinkButton>
      </div>
      <StudentStayUpdateModal
        isOpen={isModalOpen}
        student={student}
        sectionId={sectionId}
        stay={editingStay}
        closeModal={handleModalClose}
      />
    </Flex>
  );
};

const useStudentStayUpdateModal = (): UseStudentStayUpdateModal => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingStay, setEditingStay] = useState<StudentStayAnalytics | null>(
    null,
  );

  const handleModalOpen = (stay: StudentStayAnalytics) => {
    setEditingStay(stay);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setEditingStay(null);
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    editingStay,
    handleModalOpen,
    handleModalClose,
  };
};
