import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  deleteKarte,
  deleteKartePin,
  postKartePin,
} from "../../../actions/karte";
import TimeHelper from "../../../helpers/TimeHelper";
import KarteInterface from "../../../interfaces/KarteInterface";
import Icon from "../../atoms/Icon";
import KarteShareButton from "../../organisms/KarteShareButton";
import styles from "./styles.scss";
import {
  EllipsisDropdown,
  EllipsisDropdownLink,
} from "../../general/EllipsisDropdown";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "../../general/Tooltip/Tooltip";

interface Props {
  karte: KarteInterface;
  canPin?: boolean;
  deleteKarte: (studentId: string, karteId: string) => void;
  postKartePin: (studentId: string, karteId: string) => void;
  deleteKartePin: (studentId: string, karteId: string) => void;
}

class PresentationalComponent extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { karte } = this.props;

    // 現在のURLによってカルテ編集後に戻る先を校舎カルテ一覧と生徒カルテ一覧にわける
    const editSearch = new URLSearchParams({
      backto: window.location.pathname.startsWith("/students")
        ? "students"
        : "sections",
    });
    const editLinkTo = {
      pathname: `/students/${karte.student.id}/kartes/${karte.id}/edit`,
      search: editSearch.toString(),
    };

    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <Icon name="icon-navi-avatar" className={styles.icon} />
          <Link
            to={`/students/${karte.student.id}/kartes`}
            className={styles.name}
          >
            {karte.student.fullName}
          </Link>
          <Link
            to={`/students/${karte.student.id}/kartes/${karte.id}`}
            className={styles.createdAt}
          >
            <time>
              {TimeHelper.japaneseFormatFullDateTime(karte.createdAt)}
            </time>
          </Link>
          {this.props.canPin && karte.pinned && (
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div
                    data-for={`KarteItemHeader-${karte.id}-tooltip`}
                    data-tip={true}
                    className={`${styles.pin} ml-[1.4rem] leading-[0]`} // ./styles.scssでmargin-left: 1.4remを当てて左の要素との間隔を取っていたため
                  >
                    <Icon name="icon-pin" onClick={this.handleClickPin} />
                  </div>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    <p>トップに固定を解除</p>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        <div className={styles.right} aria-label="カルテ編集メニュー">
          <Link to={editLinkTo} className={styles.editLink}>
            <Icon name="icon-edit" />
          </Link>
          <span className={styles.staffName}>{karte.staffName}</span>
          <KarteShareButton karte={karte} />
          {this.renderDropdown()}
        </div>
      </div>
    );
  }

  private handleDeleteClick = (e: any) => {
    e.preventDefault();
    if (window.confirm("本当に削除してよろしいですか？")) {
      this.props.deleteKarte(this.props.karte.student.id, this.props.karte.id);
    }
  };

  private postKartePin = () => {
    const { karte } = this.props;
    if (window.confirm("このカルテをトップに固定しますか？")) {
      this.props.postKartePin(karte.student.id, karte.id);
    }
  };
  private deleteKartePin = () => {
    const { karte } = this.props;
    if (window.confirm("このカルテをトップから外しますか？")) {
      this.props.deleteKartePin(karte.student.id, karte.id);
    }
  };

  private handleClickPin = () => {
    const { karte } = this.props;

    if (karte.pinned) {
      this.deleteKartePin();
    } else {
      this.postKartePin();
    }
  };

  private renderDropdown = () => {
    const { karte, canPin } = this.props;

    return (
      <EllipsisDropdown position={{ top: "0", right: "3rem" }}>
        {canPin ? (
          <EllipsisDropdownLink onClick={this.handleClickPin}>
            <Icon name="icon-pin" />
            {karte.pinned ? "トップに固定を解除" : "トップに固定"}
          </EllipsisDropdownLink>
        ) : null}
        <EllipsisDropdownLink
          href={`/students/${karte.student.id}/kartes/${karte.id}`}
        >
          <Icon name="icon-home" />
          詳細
        </EllipsisDropdownLink>
        <EllipsisDropdownLink onClick={this.handleDeleteClick}>
          <Icon name="icon-trash" />
          削除
        </EllipsisDropdownLink>
      </EllipsisDropdown>
    );
  };
}

const actions = {
  deleteKarte,
  postKartePin,
  deleteKartePin,
};

const KarteItemHeader = connect(null, actions)(PresentationalComponent);
export default KarteItemHeader;
