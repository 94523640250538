import {
  Button,
  IconHelpSupport,
  IconNaviMenuClose,
  IconPlusTag,
} from "@studyplus/boron-ui";
import * as React from "react";
import { ScheduleDatePicker } from "../../components/features/Schedule/FormParts";
import Input from "../../components/atoms/Input";
import {
  Values as FormValues,
  StudyTaskError,
  useStudyTaskForm,
} from "./useStudyTaskForm";
import { SelectedDrillLearningMaterial } from "./SelectedDrillLearningMaterial";
import ErrorText from "../../components/atoms/ErrorText";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import classNames from "classnames";

type Props = {
  studyTaskIndex: number;
  form: ReturnType<typeof useStudyTaskForm>;
  drillLearningMaterial: FormValues["study_tasks"][0]["drill_learning_material"];
  removeItemHandler: () => void;
  openDrillLearningMaterialHandler: () => void;
};

export const DrillLearningMaterialItem = ({
  studyTaskIndex,
  form,
  drillLearningMaterial,
  removeItemHandler,
  openDrillLearningMaterialHandler,
}: Props) => {
  const formError = form.errors.study_tasks?.[studyTaskIndex] as StudyTaskError;
  return (
    <div
      className="mb-7 rounded border border-solid border-gray-400 p-7"
      aria-label={`課題${studyTaskIndex + 1}`}
    >
      <div className="mb-7 flex">
        <div className="mr-7 flex flex-grow flex-col">
          <div className="mb-3 flex items-center">
            デジタル教材と対象範囲の選択
            <HelpPopover>
              <div className="text-center">
                1課題に対して1つの教材を
                <br />
                選択できます
              </div>
            </HelpPopover>
          </div>
          {drillLearningMaterial.learningMaterialCode ? (
            <SelectedDrillLearningMaterial
              drillLearningMaterial={drillLearningMaterial}
              resetDrillLearningMaterialHandler={() =>
                form.resetDrillLearningMaterial(studyTaskIndex)
              }
              openDrillLearningMaterialHandler={
                openDrillLearningMaterialHandler
              }
            />
          ) : (
            <div className="flex flex-grow items-center rounded bg-gray-100 px-4 py-4">
              <Button
                size="xs"
                variant="outline"
                isRound
                onClick={openDrillLearningMaterialHandler}
              >
                <IconPlusTag />
                <span className="text-sm">教材を選択</span>
              </Button>
            </div>
          )}
          {form.touched?.study_tasks?.[studyTaskIndex]?.drill_learning_material
            ?.learningMaterialCode &&
            formError?.drill_learning_material?.learningMaterialCode && (
              <ErrorText>
                <>{formError.drill_learning_material.learningMaterialCode}</>
              </ErrorText>
            )}
        </div>
        <div>
          <div className="flex">
            <div className="grid grid-cols-[15rem_1.4rem_1fr] grid-rows-[2rem_4rem] gap-x-4">
              <div className="mb-1 flex items-center text-sm">
                <span>配信開始</span>
                <HelpPopover>
                  <div className="text-center">
                    開始日の0:00を過ぎた時点で
                    <br />
                    課題の学習が可能になります
                    <br />
                    当日の場合は即時
                    <br />
                    学習することができます
                  </div>
                </HelpPopover>
              </div>
              <div></div>
              <div className="mb-1 flex items-center text-sm">
                <span>配信終了</span>
                <HelpPopover>
                  <div className="text-center">
                    終了日の23:59を過ぎた時点で
                    <br />
                    生徒側から課題の閲覧が
                    <br />
                    できなくなります
                  </div>
                </HelpPopover>
              </div>
              <ScheduleDatePicker
                className="w-[15rem]"
                iconClassName="text-gray-600 cursor-pointer hover:text-blue-400"
                iconRight
                noIcon
                value={form.values.study_tasks[studyTaskIndex].start_at}
                onDateChange={(date) =>
                  form.setFieldValue(
                    `study_tasks.[${studyTaskIndex}].start_at`,
                    date,
                  )
                }
                calendarPosition={{ left: "lg:left-[-17rem]" }}
              />
              <div className="flex items-center justify-center">〜</div>
              <ScheduleDatePicker
                className="w-[15rem]"
                iconClassName="text-gray-600 cursor-pointer hover:text-blue-400"
                iconRight
                noIcon
                value={form.values.study_tasks[studyTaskIndex].end_at}
                onDateChange={(date) =>
                  form.setFieldValue(
                    `study_tasks.[${studyTaskIndex}].end_at`,
                    date,
                  )
                }
                calendarPosition={{ left: "lg:left-[-17rem]" }}
              />
            </div>
          </div>
          {form.touched?.study_tasks?.[studyTaskIndex]?.end_at &&
            formError?.end_at && (
              <ErrorText>
                {/* https://github.com/jaredpalmer/formik/issues/3683#issuecomment-1426924431 */}
                <>{formError.end_at}</>
              </ErrorText>
            )}
        </div>
        <div className="ml-4 flex items-start">
          <button
            className="cursor-default"
            onClick={removeItemHandler}
            disabled={!form.canRemoveStudyTask}
          >
            <IconNaviMenuClose
              className={classNames("text-gray-300", {
                "cursor-pointer text-gray-600 hover:text-blue-400":
                  form.canRemoveStudyTask,
              })}
            />
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor={`study_task[${studyTaskIndex}]-title`}>
          課題のタイトル
        </label>
        <span className="ml-3 text-md">※生徒側にも表示されます</span>
      </div>
      <Input
        className="text-black"
        id={`study_task[${studyTaskIndex}]-title`}
        type="text"
        placeholder="教材を選択すると教材名を課題のタイトルに反映できます"
        name={`study_tasks.[${studyTaskIndex}].title`}
        onChange={form.handleChange}
        value={form.values.study_tasks[studyTaskIndex].title}
        hasError={
          form.touched.study_tasks?.[studyTaskIndex]?.title &&
          Boolean(formError?.title)
        }
      />
      {form.touched.study_tasks?.[studyTaskIndex]?.title &&
        formError?.title && <ErrorText>{formError.title}</ErrorText>}
    </div>
  );
};

const HelpPopover = ({ children }: React.PropsWithChildren) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger className="ml-1 flex p-0">
          <IconHelpSupport className="text-gray-800" width={14} height={14} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent side="top">{children}</PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};
