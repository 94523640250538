import * as React from "react";
import styles from "./styles.scss";
import Item, { Props as LinkItemProps } from "./Item";

class LinkList extends React.Component<React.PropsWithChildren<unknown>> {
  static Item: (props: LinkItemProps) => JSX.Element;

  render() {
    return <ul className={styles.root}>{this.props.children}</ul>;
  }
}

LinkList.Item = Item;

export default LinkList;
