import {
  Actions,
  ActionTypes,
} from "../../../actions/pages/SectionsSettingsSkillsPage/types";
import SectionsSettingsSkillsStateInterface from "../../../interfaces/SectionsSettingsSkillsStateInterface";

const defaultState: SectionsSettingsSkillsStateInterface = {
  data: [],
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
  loading: false,
  submitting: false,
  hasMore: false,
};

const sectionsSettingsSkills = (
  state: SectionsSettingsSkillsStateInterface = defaultState,
  action: Actions,
) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE:
      return defaultState;
    case ActionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload.skills.data),
        meta: action.payload.skills.meta,
        loading: false,
        hasMore:
          action.payload.skills.meta.currentPage <
          action.payload.skills.meta.totalPages,
      };
    case ActionTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.DELETE_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: state.data.filter((skill) => skill.id !== action.payload.skillId),
      };
    case ActionTypes.DELETE_FAILURE:
      return {
        ...state,
        submitting: false,
      };
    case ActionTypes.ENABLE_SUCCESS:
      return {
        ...state,
        data: state.data.map((skill) => {
          if (skill.id === action.payload) {
            return {
              ...skill,
              enabled: true,
            };
          } else {
            return skill;
          }
        }),
      };
    case ActionTypes.DISABLE_SUCCESS:
      return {
        ...state,
        data: state.data.map((skill) => {
          if (skill.id === action.payload) {
            return {
              ...skill,
              enabled: false,
            };
          } else {
            return skill;
          }
        }),
      };
    case ActionTypes.ENABLE_FAILURE:
    case ActionTypes.DISABLE_FAILURE:
    default:
      return {
        ...state,
      };
  }
};

export default sectionsSettingsSkills;
