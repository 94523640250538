import SectionsIndex from "../../../components/organisms/SectionsIndex";
import enhanceHomePage from "../../../hocs/enhanceHomePage";
import { useFetchSections } from "./useFetchSections";
import { useQueryError } from "../../../hooks/http/useQueryError";

const SectionsPage = () => {
  const { sections, sectionOperatorInvitations, isLoading } = useSectionsPage();

  return (
    <SectionsIndex
      sections={sections}
      sectionOperatorInvitations={sectionOperatorInvitations}
      loading={isLoading}
    />
  );
};

const useSectionsPage = () => {
  const {
    sections,
    sectionOperatorInvitations,
    isLoading,
    isFetchingNextPage,
    error,
  } = useFetchSections();

  useQueryError(error);

  return {
    sections,
    sectionOperatorInvitations,
    isLoading: isLoading || isFetchingNextPage,
  };
};

export default enhanceHomePage(SectionsPage, { title: "校舎一覧" });
