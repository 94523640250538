import * as React from "react";
import { StudyTaskContentsResponse } from "./useFetchStudyTaskContents";
import { IconArrowDown, IconArrowRight } from "@studyplus/boron-ui";

export const StudyTaskContentRow = ({
  studyTaskContent,
}: {
  studyTaskContent: StudyTaskContentsResponse["studyTaskContents"][0];
}) => {
  const [isOpenSectionRow, setIsOpenSectionRow] =
    React.useState<boolean>(false);

  return (
    <div className="border border-0 border-t border-solid border-gray-400 px-4 py-2">
      <div
        className="cursor-pointer hover:text-blue-400"
        onClick={() => setIsOpenSectionRow(!isOpenSectionRow)}
      >
        <DrillSectionRow
          isOpenSectionRow={isOpenSectionRow}
          name={studyTaskContent.drillSectionName}
        />
        {isOpenSectionRow &&
          studyTaskContent.drillContents.map((drillContent) => {
            return (
              <DrillContentRow
                key={drillContent.publicId}
                name={drillContent.name}
              />
            );
          })}
      </div>
    </div>
  );
};

type DrillSectionRowProps = {
  isOpenSectionRow: boolean;
  name: string;
};
const DrillSectionRow = ({ isOpenSectionRow, name }: DrillSectionRowProps) => {
  return (
    <div className="flex items-center p-0">
      {isOpenSectionRow ? <IconArrowDown /> : <IconArrowRight />}
      <div>{name}</div>
    </div>
  );
};

type DrillContentRowProps = {
  name: string;
};
const DrillContentRow = ({ name }: DrillContentRowProps) => {
  return (
    <div className="px-9 py-2 text-black">
      <span>{name}</span>
    </div>
  );
};
