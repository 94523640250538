import * as yup from "yup";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("名を入力してください")
    .max(16, "16文字以内で入力してください"),
  lastName: yup
    .string()
    .trim()
    .required("姓を入力してください")
    .max(16, "16文字以内で入力してください"),
});

export default validationSchema;
