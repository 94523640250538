import { EllipsisDropdownLink } from "../../../components/general/EllipsisDropdown";
import Icon from "../../../components/atoms/Icon";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import StudentInterface from "../../../interfaces/StudentInterface";

type Props = {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
};
export const DisconnectLineDropdownLink = ({
  student,
  updateStudent,
}: Props) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate } = useMutation<void, void>({
    mutationFn: async () => {
      const { response, data } = await boronClient.DELETE(
        "/api/v1/students/{student_id}/line_connection_code",
        {
          params: {
            path: { student_id: student.id },
          },
        },
      );
      if (response.ok && data) {
        updateStudent(data.student);
        showSuccessMessage("LINE連携を停止しました");
        return;
      }
      showErrorMessage("LINE連携を停止できませんでした");
    },
  });

  const handleDisconnectLineCode = () => {
    if (
      window.confirm(
        `本当に「${student.fullName}」さんのLINE連携を停止してよろしいですか？`,
      )
    ) {
      mutate();
    }
  };

  return (
    <EllipsisDropdownLink onClick={handleDisconnectLineCode}>
      <Icon name="icon-line-stop" />
      保護者LINE連携の停止
    </EllipsisDropdownLink>
  );
};
