import { useCallback } from "react";
import { Matcher } from "react-day-picker";

import { StyledDayPicker } from "../StyledDayPicker";

type Props = {
  date?: Date;
  onDateChange: (date: Date) => void;
  numberOfMonths?: number;
  disabled?: Matcher | Matcher[];
  defaultMonth?: Date;
  calendarFromDate?: Date;
  calendarToDate?: Date;
};
// 単数の日付をカレンダーから選択するためのコンポーネント
export const DatePicker = (props: Props) => {
  const {
    date,
    onDateChange,
    calendarFromDate,
    calendarToDate,
    ...dayPickerProps
  } = props;

  const onSelect = useCallback(
    (date?: Date) => {
      if (date) {
        onDateChange(date);
      }
    },
    [onDateChange],
  );

  return (
    <StyledDayPicker
      mode="single"
      onSelect={onSelect}
      selected={date}
      fromDate={calendarFromDate}
      toDate={calendarToDate}
      {...dayPickerProps}
    />
  );
};
