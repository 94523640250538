import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useStudyScheduleProgressDateParams } from "../../../components/organisms/StudyScheduleProgressHeader";
import { toStringFromDate } from "../../../helpers/TimeHelper";
import { useQueryString } from "../../../hooks/useQueryString";

const terms = ["daily", "weekly", "monthly"] as const;
export type TermType = (typeof terms)[number];

export const useStudentStudySchedules = () => {
  const navigate = useNavigate();
  const queryParams = useQueryString();
  const { startDate, endDate, type } =
    useStudyScheduleProgressDateParams(queryParams);

  const onChangeDateParams = React.useCallback((date: Date, type: TermType) => {
    navigate(`.?type=${type}&date=${toStringFromDate(date)}`);
  }, []);

  return {
    onChangeDateParams,
    startDate,
    endDate,
    type,
  };
};
