import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { boronClient, makeFormData } from "../../../../api";
import { ImportHistoryInterface } from "../../../../domains/ImportHistory";

export const usePostImportOperation = ({
  sectionId,
  operation,
}: {
  sectionId: string;
  operation: ImportHistoryInterface["operation"];
}) => {
  return useBoronMutation(
    async ({ csv, zip }: { csv: File; zip?: File }) =>
      await boronClient.POST("/api/v1/sections/{section_id}/import", {
        params: {
          path: { section_id: sectionId },
        },
        body: { operation, csv, zip },
        bodySerializer: makeFormData,
      }),
  );
};
