import { Link } from "react-router-dom";

import TimeHelper from "../../../../helpers/TimeHelper";
import { ImportHistoryInterface } from "../../../../domains/ImportHistory";
import SectionInterface from "../../../../interfaces/SectionInterface";
import Table, { TableColumn } from "../../../../components/atoms/Table";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
  importHistories: ImportHistoryInterface[];
}

const ImportHistory = ({
  sectionId,
  importHistory,
}: {
  sectionId: string;
  importHistory: ImportHistoryInterface;
}) => {
  return (
    <tr key={`importHistory-${importHistory.id}`}>
      <TableColumn centering={true}>
        {importHistory.operator.fullName}
      </TableColumn>
      <TableColumn centering={true}>{importHistory.operationHuman}</TableColumn>
      <TableColumn
        className={importHistory.status === "failure" ? styles.error : ""}
        centering={true}
      >
        {importHistory.statusHuman}
      </TableColumn>
      <TableColumn centering={true}>
        {importHistory.startedAt &&
          TimeHelper.fullFormat(importHistory.startedAt)}
      </TableColumn>
      <TableColumn centering={true}>
        {importHistory.endedAt && TimeHelper.fullFormat(importHistory.endedAt)}
      </TableColumn>
      <TableColumn centering={true}>
        <Link
          to={`/sections/${sectionId}/settings/import_histories/${importHistory.id}`}
        >
          詳細
        </Link>
      </TableColumn>
    </tr>
  );
};

const ImportHistoryList = (props: Props) => {
  return (
    <div className={styles.root}>
      <Table
        headers={[
          { text: "スタッフ名", centering: true },
          { text: "操作", centering: true },
          { text: "ステータス", centering: true },
          { text: "開始", centering: true },
          { text: "完了", centering: true },
          { text: "", centering: true },
        ]}
        rows={props.importHistories.map((importHistory) => (
          <ImportHistory
            key={`importHistory-${importHistory.id}`}
            sectionId={props.section.id}
            importHistory={importHistory}
          />
        ))}
        noRowsText="一括操作履歴がありません"
      />
    </div>
  );
};

export default ImportHistoryList;
