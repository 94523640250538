import SectionInterface from "../interfaces/SectionInterface";
import { ContentUnit } from "./ContentUnit";
import { Content } from "./Content";
import Settings from "../helpers/Settings";
import { components } from "../lib/api/v1";

export type ContentCourse = {
  id: string;
  name: string;
  description: string | null;
  updatedAt: Date;
  startAt: Date | null;
  endAt: Date | null;
  thumbnailUrl: string | null;
  tag: { id: string; name: string } | null;
  ownerSection: SectionInterface;
  isSharedCourse: boolean;
  units: readonly ContentUnit[];
};

export const fromJson = (
  contentCourse: components["schemas"]["ContentCourse"],
): ContentCourse => {
  const { ownerSection } = contentCourse;
  const units: readonly Record<string, any>[] =
    contentCourse.units && Array.isArray(contentCourse.units)
      ? contentCourse.units
      : [];
  return {
    id: contentCourse.id,
    name: contentCourse.name,
    description: contentCourse.description ?? null,
    updatedAt: new Date(contentCourse.updatedAt),
    startAt: contentCourse.startAt ? new Date(contentCourse.startAt) : null,
    endAt: contentCourse.endAt ? new Date(contentCourse.endAt) : null,
    thumbnailUrl: contentCourse.thumbnailUrl,
    isSharedCourse: contentCourse.isSharedCourse,
    tag: contentCourse.tag,
    ownerSection: {
      id: ownerSection.id,
      sectionName: ownerSection.sectionName,
      schoolName: ownerSection.schoolName,
      fullName: ownerSection.fullName,
      isAllowedToReceiveGuardianMessage:
        ownerSection.isAllowedToReceiveGuardianMessage,
      guardianLineConnectionUsable: ownerSection.guardianLineConnectionUsable,
    },
    units: units.map<ContentUnit>((unit) => {
      const contents =
        unit.contents && Array.isArray(unit.contents) ? unit.contents : [];
      return {
        id: unit.id,
        name: unit.name,
        description: unit.description,
        sequence: unit.sequence,
        contents: contents.map<Content>((content) => {
          return {
            id: content.id,
            name: content.name,
            url: content.url,
            fileSize: content.fileSize,
            updatedAt: new Date(content.updatedAt),
            contentType: content.contentType,
            ltiToolName: content.ltiToolName,
            availableStartAt: content.availableStartAt
              ? new Date(content.availableStartAt)
              : null,
            availableEndAt: content.availableEndAt
              ? new Date(content.availableEndAt)
              : null,
          };
        }),
      };
    }),
  };
};

export const DEFAULT_THUMBNAIL_URL = `${Settings.BORON_URL}/images/owners_course_default.png`;
