import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useBoronMutation } from "../../../hooks/http/useBoronMutation";
import { boronClient } from "../../../api";
import { FSHTTPError } from "../../../errors";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";

const MAX_LINK_GOOGLE_CLASSROOM_COURSES_REQUEST_SIZE = 50;

export const useLinkCourses = ({ sectionId }: { sectionId: string }) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate, isPending } = useBoronMutation(
    async ({ courseIds }: { courseIds: string[] }) =>
      await boronClient.POST(
        "/api/v1/sections/{section_id}/google_classroom/courses",
        {
          params: {
            path: { section_id: sectionId },
          },
          body: {
            course_ids: courseIds,
          },
        },
      ),
    {
      onSuccess: () => {
        showSuccessMessage("Google Classroomクラスの連携を開始しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("Google Classroomクラスと連携できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  const requestLinkCourses = ({
    selectedCourseIds,
    onSuccess,
  }: {
    selectedCourseIds: string[];
    onSuccess: () => void;
  }) => {
    if (selectedCourseIds.length === 0) {
      return;
    }

    if (
      selectedCourseIds.length > MAX_LINK_GOOGLE_CLASSROOM_COURSES_REQUEST_SIZE
    ) {
      showErrorMessage(
        `一度に連携するGoogle Classroomクラスの数は${MAX_LINK_GOOGLE_CLASSROOM_COURSES_REQUEST_SIZE}以下にしてください`,
      );
      return;
    }

    mutate({ courseIds: selectedCourseIds }, { onSuccess });
  };

  return { requestLinkCourses, isPending };
};
