import { StudentLearningMaterialsPreRegistrationsStateInterface } from "../../../interfaces/StudentLearningMaterialsPreRegistrationsStateInterface";

const defaultState = {
  data: null,
  loading: false,
  submitting: false,
};

const studentLearningMaterialsPreRegistrations = (
  state: StudentLearningMaterialsPreRegistrationsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_LEARNING_MATERIALS_PRE_REGISTRATIONS":
      return {
        ...state,
        loading: true,
      };
    case "GET_LEARNING_MATERIALS_PRE_REGISTRATIONS_SUCCESS":
      return {
        ...state,
        data: action.payload.learningMaterials,
        loading: false,
      };
    case "GET_LEARNING_MATERIALS_PRE_REGISTRATIONS_ERROR":
      return {
        ...state,
        loading: false,
      };

    case "DELETE_STUDENT_PRE_REGISTERED_LEARNING_MATERIAL_SUCCESS":
      if (state.data) {
        return {
          ...state,
          data: state.data.filter(
            (learningMaterial) => learningMaterial.code !== action.payload,
          ),
        };
      } else {
        return state;
      }

    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF":
      return {
        ...state,
        submitting: true,
      };

    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_SUCCESS":
    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_ERROR":
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
};

export default studentLearningMaterialsPreRegistrations;
