import * as React from "react";
import SubLayout from "../../components/atoms/SubLayout";

interface Props {}
interface State {}

class PasswordResetSentPage extends React.Component<Props, State> {
  render() {
    return (
      <SubLayout>
        <SubLayout.Header />
        <SubLayout.Main>
          <div>
            <SubLayout.Heading>再設定メールを送信しました</SubLayout.Heading>
            <p>
              ご入力いただいたメールアドレスに、本人認証を行うメールをお送りしました。
            </p>
            <p>
              メールに記載されたURLから、パスワードの再設定を行ってください。
            </p>
          </div>
        </SubLayout.Main>
        <SubLayout.Footer />
      </SubLayout>
    );
  }
}

export default PasswordResetSentPage;
