import AnnounceMessageApi from "../../../api-clients/AnnounceMessageApi";
import { createAction } from "redux-actions";
import { showErrorFlashMessage } from "../../flashMessage";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import { Dispatch } from "redux";
import StudentTag from "../../../domains/StudentTag";
import ApiClient from "../../../api";
import ApiResponse from "../../../interfaces/ApiResponse";
import { Recipient } from "../../../interfaces/SectionAnnounceMessagesStateInterface";

const getInitialAnnounceMessages = createAction(
  "GET_INITIAL_ANNOUNCE_MESSAGES",
);
const getAnnounceMessagesResponseReceived = createAction(
  "GET_ANNOUNCE_MESSAGES_RESPONSE_RECEIVED",
);
const getInitialAnnounceMessagesResponseReceived = createAction(
  "GET_INITIAL_ANNOUNCE_MESSAGES_RESPONSE_RECEIVED",
);
const getAnnounceMessagesResponseError = createAction(
  "GET_ANNOUNCE_MESSAGES_RESPONSE_ERROR",
);

const MESSAGES_PER_PAGE = 10;
// 宛先タグ一覧 初期読み込み
export const loadInitialAnnounceMessages =
  (sectionId: string, tag: StudentTag) => (dispatch: any) => {
    dispatch(getInitialAnnounceMessages());
    const query = {
      destination_id: tag.id,
      type: tag.type,
      page: 1,
      per: MESSAGES_PER_PAGE,
    };

    AnnounceMessageApi.interruptGetMessages(sectionId, { query })
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getInitialAnnounceMessagesResponseReceived(json));
          });
        } else {
          dispatch(getAnnounceMessagesResponseError());
          dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
        }
      })
      .catch((err: any) => {
        dispatch(getAnnounceMessagesResponseError());
        if (err.name !== "AbortError") {
          dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
        }
      });
  };

// 追加読み込み
const getAnnounceMessages = createAction("GET_ANNOUNCE_MESSAGES");
export const loadMoreSectionAnnounceMessages =
  (sectionId: string, tag: StudentTag, page: number) =>
  (dispatch: Dispatch) => {
    dispatch(getAnnounceMessages());
    const query = {
      per: MESSAGES_PER_PAGE,
      page,
      destination_id: tag.id,
      type: tag.type,
    };

    AnnounceMessageApi.getMessages(sectionId, { query })
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getAnnounceMessagesResponseReceived(json));
          });
        } else {
          dispatch(getAnnounceMessagesResponseError());
        }
      })
      .catch(() => {
        dispatch(getAnnounceMessagesResponseError());
      });
  };

// 受信者一覧モーダル表示
const getAnnounceMessageRecipients = createAction<void>(
  "GET_ANNOUNCE_MESSAGE_RECIPIENTS",
);
const getAnnounceMessageRecipientsSuccess = createAction<
  ApiResponse<Recipient[]>
>("GET_ANNOUNCE_MESSAGE_RECIPIENTS_SUCCESS");
const getAnnounceMessageRecipientsError = createAction<void>(
  "GET_ANNOUNCE_MESSAGE_RECIPIENTS_ERROR",
);
const openRecipientsModal = createAction<{ messageId: string }>(
  "OPEN_ANNOUNCE_MESSAGE_RECIPIENTS_MODAL",
);
const dispatchRecipientsError = (dispatch: Dispatch) => {
  dispatch(getAnnounceMessageRecipientsError());
  dispatch(showErrorFlashMessage(HTTP_ERROR_MESSAGE));
  dispatch(closeRecipientsModal());
};

export const dispatchOpenRecipientsModal =
  (sectionId: string, announceMessageId: string) => (dispatch: Dispatch) => {
    dispatch(openRecipientsModal({ messageId: announceMessageId }));
    dispatch(getAnnounceMessageRecipients());

    ApiClient.get(
      `/api/v1/sections/${sectionId}/announce_messages/${announceMessageId}/recipient_students`,
    )
      .then((res: Response) => {
        res
          .json()
          .then((json: { recipientStudents: ApiResponse<Recipient[]> }) => {
            dispatch(
              getAnnounceMessageRecipientsSuccess(json.recipientStudents),
            );
          })
          .catch(() => {
            dispatchRecipientsError(dispatch);
          });
      })
      .catch(() => {
        dispatchRecipientsError(dispatch);
      });
  };

const closeRecipientsModal = createAction<void>(
  "CLOSE_ANNOUNCE_MESSAGE_RECIPIENTS_MODAL",
);
export const dispatchCloseRecipientsModal = () => (dispatch: Dispatch) => {
  dispatch(closeRecipientsModal());
};

export const dispatchLoadMoreRecipients =
  (sectionId: string, announceMessageId: string, page: number) =>
  (dispatch: Dispatch) => {
    dispatch(getAnnounceMessageRecipients());

    const query = {
      page,
    };
    ApiClient.get(
      `/api/v1/sections/${sectionId}/announce_messages/${announceMessageId}/recipient_students`,
      { query },
    )
      .then((res: Response) => {
        res
          .json()
          .then((json: { recipientStudents: ApiResponse<Recipient[]> }) => {
            dispatch(
              getAnnounceMessageRecipientsSuccess(json.recipientStudents),
            );
          })
          .catch(() => {
            dispatchRecipientsError(dispatch);
          });
      })
      .catch(() => {
        dispatchRecipientsError(dispatch);
      });
  };
