import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { HTTPErrors, createError } from "../../errors";
import { paths } from "../../lib/api/v1";

type ResponseData =
  paths["/api/v1/settings/profile"]["get"]["responses"]["200"]["content"]["application/json"]["operator"];
export const useFetchProfile = () => {
  return useQuery<ResponseData, HTTPErrors>({
    queryKey: ["/api/v1/settings/profile"],
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/settings/profile",
      );

      if (response.ok && data) {
        return data.operator;
      }
      throw await createError(response);
    },
    refetchOnWindowFocus: false, // reduxだったときにはrefetchしていなかったので合わせる
  });
};
