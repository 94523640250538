import * as React from "react";
import { Chart, Point, Tick } from "chart.js";
import { useEffect, useRef } from "react";
import TimeHelper from "../../../helpers/TimeHelper";
import {
  AnalyticsTermType,
  AnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import styles from "./styles.scss";

const { secondsToDisplayTime } = TimeHelper;

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  term: AnalyticsTermType | undefined;
  rangeString: string;
  chart: Chart | null;
  sumScore: number;
  totalScore: number;
  analyticsType: AnalyticsType;
  setSendImage: (canvas: HTMLCanvasElement) => void;
}

interface Radius {
  tl: number;
  tr: number;
  bl: number;
  br: number;
}

const drawRoundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: Radius | number,
  fill: boolean,
  stroke: boolean,
) => {
  if (typeof stroke === "undefined") {
    stroke = true;
  }
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height,
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
};

const SharingAnalyticsGraph: React.FC<Props> = (props) => {
  const refCanvas = useRef<HTMLCanvasElement>(null);

  const drawImage = () => {
    if (props.chart && refCanvas.current) {
      const ctx = refCanvas.current.getContext("2d");
      const graph = props.chart.canvas;

      if (!ctx || !graph) {
        return;
      }
      const canvasWidth = 800;
      const canvasHeight = 470;
      refCanvas.current.width = canvasWidth * 2;
      refCanvas.current.height = canvasHeight * 2;

      // グラフを描画
      ctx.scale(2, 2);
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
      ctx.drawImage(
        graph,
        0,
        0,
        graph.width,
        graph.height,
        0,
        130,
        canvasWidth,
        canvasWidth * (graph.height / graph.width),
      );

      // 合計などの箱を描画
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.strokeStyle = "#C7CCD9";
      ctx.fillStyle = "#E8EBEF";
      drawRoundRect(
        ctx,
        10,
        50,
        canvasWidth - 20,
        24,
        { tl: 4, tr: 4, bl: 0, br: 0 },
        true,
        false,
      );
      drawRoundRect(ctx, 10, 50, canvasWidth - 20, 58, 4, false, true);
      ctx.beginPath();
      ctx.moveTo(canvasWidth / 2, 50);
      ctx.lineTo(canvasWidth / 2, 50 + 58);
      ctx.stroke();

      // タイトルなどを描画
      ctx.fillStyle = "#2F364F";
      ctx.font =
        "bold 16px " +
        '"Helvetica Neue",Helvetica,' +
        '"Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3",' +
        '"メイリオ",Meiryo,' +
        '"ＭＳ Ｐゴシック",' +
        "Arial,Verdana,sans-serif";
      ctx.fillText(`学習期間：${props.rangeString}`, canvasWidth / 2, 15);
      ctx.font =
        "bold 14px " +
        '"Helvetica Neue",Helvetica,' +
        '"Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3",' +
        '"メイリオ",Meiryo,' +
        '"ＭＳ Ｐゴシック",' +
        "Arial,Verdana,sans-serif";
      const leftCenter = canvasWidth * 0.25 + 10;
      const rightCenter = canvasWidth * 0.75 - 10;
      ctx.fillText("合計", leftCenter, 55);
      ctx.fillText(
        `総学習${props.analyticsType === "time" ? "時間" : "量"}`,
        rightCenter,
        55,
      );

      // 合計などの値を描画
      let sumScore: number | string = props.sumScore;
      let totalScore: number | string = props.totalScore;
      if (props.analyticsType === "time") {
        sumScore = secondsToDisplayTime(sumScore);
        totalScore = secondsToDisplayTime(totalScore);
      }
      ctx.fillText(sumScore.toString(), leftCenter, 84);
      ctx.fillText(totalScore.toString(), rightCenter, 84);

      // グラフ下の箱を描画
      ctx.strokeStyle = "#C7CCD9";
      ctx.textBaseline = "middle";
      const xScale = (props.chart as any).scales["x"];
      let { left, right } = props.chart.chartArea;
      const canvasRatio = canvasWidth / (props.chart as any).width;
      left = left * canvasRatio;
      right = right * canvasRatio;
      const width = right - left;
      drawRoundRect(ctx, left, 425, width, 35, 4, false, true);

      // グラフ下の値を描画
      const totalize = (
        accumulator: number,
        currentValue: number | number[] | Point | null | undefined,
      ): number => {
        if (typeof currentValue === "number") {
          return accumulator + currentValue;
        }
        return accumulator;
      };
      const datasets = props.chart.config.data.datasets || [];
      xScale.ticks.forEach((tick: Tick, i: number) => {
        const textX = xScale.getPixelForValue(tick.value, i) * canvasRatio;
        const score = datasets
          .map((dataset) => (dataset.data ? dataset.data[i] : 0))
          .reduce(totalize, 0);
        if (score === 0) {
          ctx.fillStyle = "#A3AEC7";
        } else {
          ctx.fillStyle = "#2F364F";
        }
        if (typeof score === "number") {
          const displayScore =
            props.analyticsType === "time"
              ? secondsToDisplayTime(score)
              : score;
          ctx.fillText(displayScore.toString(), textX, 425 + 18);
        }
        if (i !== 0) {
          const x = (width / xScale.ticks.length) * i + left;
          ctx.beginPath();
          ctx.moveTo(x, 425);
          ctx.lineTo(x, 425 + 35);
          ctx.stroke();
        }
      });
      props.setSendImage(refCanvas.current);
    }
  };
  useEffect(() => {
    if (props.chart) {
      drawImage();
    }
  }, [
    refCanvas.current,
    props.chart,
    props.sumScore,
    props.totalScore,
    props.startDate,
    props.endDate,
    props.term,
  ]);
  return <canvas ref={refCanvas} className={styles.canvas} />;
};

export default SharingAnalyticsGraph;
