import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("タグ名は必須です")
    .max(32, "タグ名は32文字以下で入力してください"),
});

export default validationSchema;
