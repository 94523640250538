import * as React from "react";
import { StudentsPageProps } from "../../../hocs/enhanceStudentsPage";
import { StudentAnalyticsTypeTab } from "../../../components/features/StudentAnalyticsTypeTab";
import StudentInterface from "../../../interfaces/StudentInterface";
import styles from "./StudentsLearningMaterialTagAnalyticsTimePage.scss";
import { useLearningMaterialTagStudyScores } from "./useLearningMaterialTagAnalyticsApi";
import { enhanceStudentsLearningMaterialTagAnalyticsPage } from "../../../hocs/enhanceStudentsLearningMaterialTagAnalyticsPage";
import { DateRangeNavigation } from "../../../components/general/DateRangeNavigation";
import SwitchTabs from "../../../components/molecules/SwitchTabs";
import PrintButton from "../../../components/atoms/PrintButton";
import FiltersInterface, {
  OrderDirFilterType,
} from "../../../interfaces/FiltersInterface";
import { LearningMaterialTagAnalyticsSwitcher } from "../../../components/features/LearningMaterialTagAnalyticsSwitcher";
import {
  AnalyticsTermType,
  AnalyticsType,
} from "../../../interfaces/AnalyticsTableInterface";
import DateRangeFilterInterface from "../../../interfaces/DateRangeFilterInterface";
import { isPushableCalendarNext } from "../../../helpers/AnalyticsHelper";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { Provider as ActiveHeatMapRowMapProvider } from "../../../components/features/LearningMaterialTagAnalyticsHeatMapTable/useActiveHeatMapRowMap";
import { LearningMaterialTagAnalyticsHeatMapTable } from "../../../components/features/LearningMaterialTagAnalyticsHeatMapTable";
import { LearningMaterialTagTimeAnalyticsChart } from "./LearningMaterialTagTimeAnalyticsChart";
import Loader from "../../../components/atoms/Loader";
import { AnalyticsLearningMaterialTagFilter } from "../../../components/features/AnalyticsLearningMaterialTagFilter";
import { BlockRow } from "../../../components/atoms/BlockRow";
import { useLearningMaterialFilterContext } from "./useLearningMaterialFilterContext";
import isAfter from "date-fns/isAfter";
import { useCallback } from "react";
import { defaultLearningMaterialTagFilterOptions } from "./constant";

type UseStudentsLearningMaterialTagAnalyticsTimePageProps = {
  student: StudentInterface;
  term: AnalyticsTermType;
  dateRange: DateRangeFilterInterface;
  order: string;
  orderDir: OrderDirFilterType;
  onDateFilterChange: (dateRange: DateRangeFilterInterface) => void;
};

// Main hooks
const useStudentsLearningMaterialTagAnalyticsTimePage = ({
  student,
  dateRange,
  term,
  order,
  orderDir,
  onDateFilterChange,
}: UseStudentsLearningMaterialTagAnalyticsTimePageProps) => {
  // 毎レンダリングでtoDateすると毎回新しい日付インスタンスを作ってしまい、その度にAPIを実行してしまう。
  // それをメモ化して防ぐ。
  const dateParams = React.useMemo(
    () => ({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }),
    [dateRange],
  );

  const { items: selectedOptions, isInitialized: isDeterminedDefaultOptions } =
    useLearningMaterialFilterContext();

  const {
    hasNextPage,
    isLoading: isHeatMapLoading,
    isFetchingNextPage,
    data: heatMapData,
    fetchNextPage,
    error: heatMapError,
  } = useLearningMaterialTagStudyScores({
    startDate: dateParams.endDate, // API 側は遡って計算するため
    term,
    student,
    learningMaterialTags: selectedOptions,
    order: order ?? "",
    orderDir,
    enabledRequest: isDeterminedDefaultOptions,
  });
  useQueryError(heatMapError);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isHeatMapLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isHeatMapLoading, isFetchingNextPage]);

  const analyticsChartProps = React.useMemo(
    () => ({
      startDate: dateParams.startDate,
      endDate: dateParams.endDate,
      term,
      student,
      order,
      orderDir,
    }),
    [dateParams, student, term, order, orderDir],
  );

  const handleDatesChange = useCallback(
    (dates: { startDate?: Date; endDate?: Date }) => {
      if (dates.startDate && dates.endDate) {
        onDateFilterChange({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }
    },
    [],
  );
  const datePickerProps = React.useMemo(
    () => ({
      calendarDisabled: !term || term !== "daily",
      canPushRightButton: isPushableCalendarNext(dateRange.endDate, term),
      onDatesChange: handleDatesChange,
    }),
    [dateRange, term],
  );

  const heatMapLoaderProps = {
    loading:
      isHeatMapLoading || isFetchingNextPage || !isDeterminedDefaultOptions,
  };
  return {
    readyRenderContens: isDeterminedDefaultOptions,
    heatMapData,
    datePickerProps,
    heatMapLoaderProps,
    analyticsChartProps,
  };
};

type Props = StudentsPageProps & {
  student: StudentInterface;
  filter: FiltersInterface;
  onTermChange: (term: AnalyticsTermType) => void;
  onDateFilterChange: (dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
  analyticsType: AnalyticsType;
  onOrderChange: (
    order?: string | null,
    orderDir?: OrderDirFilterType | null,
  ) => void;
};

// Main component
export const StudentsLearningMaterialTagAnalyticsTimePage_ = ({
  student,
  filter,
  onTermChange,
  onDateFilterChange,
  analyticsType,
  onOrderChange,
}: Props) => {
  const {
    analyticsChartProps,
    heatMapData,
    datePickerProps,
    heatMapLoaderProps,
    readyRenderContens,
  } = useStudentsLearningMaterialTagAnalyticsTimePage({
    student,
    dateRange: filter.dateRangeFilter,
    term: filter.term,
    order: filter.order ?? "",
    orderDir: filter.orderDir ?? "desc",
    onDateFilterChange,
  });

  return (
    <ActiveHeatMapRowMapProvider>
      <div className={styles.TabWrapper}>
        <StudentAnalyticsTypeTab activeAnalyticsType="time" student={student} />
      </div>
      <div className={styles.TableControl}>
        <div className={styles.TableControl__LeftArea}>
          <LearningMaterialTagAnalyticsSwitcher
            student={student}
            currentAnalyticsType="time"
            value="learning_material_tag"
          />
        </div>
        <DateRangeNavigation
          startDate={filter.dateRangeFilter.startDate}
          endDate={filter.dateRangeFilter.endDate}
          term={filter.term}
          isDayBlocked={(day: Date) => isAfter(day, new Date())}
          {...datePickerProps}
        />
        <div className={styles.TableControl__RightArea}>
          <SwitchTabs
            tabs={[
              {
                id: "daily",
                label: "日",
                active: filter.term === "daily",
              },
              {
                id: "weekly",
                label: "週",
                active: filter.term === "weekly",
              },
              {
                id: "monthly",
                label: "月",
                active: filter.term === "monthly",
              },
            ]}
            onClickTab={(tab: any) => () => {
              onTermChange(tab.id);
            }}
          />
          <PrintButton />
        </div>
      </div>
      <BlockRow marginTop="2.0rem">
        <AnalyticsLearningMaterialTagFilter
          context={useLearningMaterialFilterContext()}
          section={student.section}
          defaultLearningMaterialTagFilterOptions={
            defaultLearningMaterialTagFilterOptions
          }
          isInitializeByQueryParams={true}
          isUseLocalStorage={false}
        />
      </BlockRow>
      {readyRenderContens && (
        <div className={styles.MainContentsWrapper}>
          <LearningMaterialTagTimeAnalyticsChart {...analyticsChartProps} />
          <div className={styles.TableWrapper}>
            <LearningMaterialTagAnalyticsHeatMapTable
              learningMaterialTagAnalyticsHeatMap={heatMapData}
              filter={filter}
              onOrderChange={onOrderChange}
              analyticsType={analyticsType}
              student={student}
            />
            <Loader {...heatMapLoaderProps} />
          </div>
        </div>
      )}
    </ActiveHeatMapRowMapProvider>
  );
};

export const StudentsLearningMaterialTagAnalyticsTimePage =
  enhanceStudentsLearningMaterialTagAnalyticsPage(
    "time",
    StudentsLearningMaterialTagAnalyticsTimePage_ as any,
  );
