import * as React from "react";
import styles from "./styles.scss";
import TabsNavi from "../../molecules/TabsNavi/index";
import SectionInterface from "../../../interfaces/SectionInterface";
import SplitLayout, { Column } from "../../atoms/SplitLayout";
import SectionAnnounceMessagesStateInterface from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import SectionAnnounceDestinationTagList from "../../molecules/SectionAnnounceDestinationTagList/index";
import AnnounceMessage from "../../molecules/AnnounceMessage/index";
import { Link } from "react-router-dom";
import Icon from "../../atoms/Icon";
import { connect } from "react-redux";
import {
  openAnnounceDestinationModal,
  closeAnnounceDestinationModal,
} from "../../../actions/pages/sectionAnnounceMessages/common";
import { OperatorPrivilegeInfoInterface } from "../../../interfaces/OperatorInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";

interface Props extends WithRouterProps<{ action: string }> {
  section: SectionInterface;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  announceMessagesState: SectionAnnounceMessagesStateInterface;
  currentOperator: OperatorInterface;
}

interface DispatchProps {
  openModal: typeof openAnnounceDestinationModal;
  closeModal: typeof closeAnnounceDestinationModal;
}

interface Props extends WithRouterProps<{ action: string }>, DispatchProps {
  section: SectionInterface;
  onLoadMore: () => void;
}

interface State {
  focusedColumn: Column;
}

class SectionAnnounceMessages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { action } = this.props.params;
    this.state = {
      focusedColumn: action === "new" ? Column.Right : Column.Left,
    };
  }

  render() {
    const { section, location, operatorPrivilege } = this.props;

    const tabs = [
      {
        label: "個別送信",
        link: {
          pathname: `/sections/${section.id}/message_threads`,
          search: location.search,
        },
        active: false,
      },
    ];

    if (
      operatorPrivilege?.role === "admin" ||
      operatorPrivilege?.role === "unlimited_member"
    ) {
      tabs.push({
        label: "一斉送信",
        link: {
          pathname: `/sections/${section.id}/tags/messages`,
          search: location.search,
        },
        active: true,
      });
    }

    return (
      <React.Fragment>
        <TabsNavi tabs={tabs} />
        <div className={styles.message}>
          <SplitLayout focusedColumn={this.state.focusedColumn}>
            <SplitLayout.Navigation focusedColumn={this.state.focusedColumn}>
              <div className={styles.newMessage}>
                <Link
                  to={`/sections/${section.id}/tags/messages/new`}
                  className={styles.newMessage__button}
                  onClick={this.handleClickNewMessage}
                >
                  <Icon name="icon-edit" />
                  新しいメッセージを作成
                </Link>
              </div>
              <SectionAnnounceDestinationTagList
                announceMessagesState={this.props.announceMessagesState}
                onLoadMore={this.props.onLoadMore}
                onClickTag={this.handleNavigateColumn}
              />
            </SplitLayout.Navigation>
            <AnnounceMessage
              destinationTag={this.getOpenedTag()}
              messagesState={this.props.announceMessagesState.messages}
              onCloseRightColumn={this.handleCloseRightColumn}
              section={this.props.section}
              isModalOpen={this.props.announceMessagesState.isModalOpen}
              isRecipientsModalOpen={
                this.props.announceMessagesState.isRecipientsModalOpen
              }
              onOpenModal={this.props.openModal}
              onCloseModal={this.props.closeModal}
              recipients={this.props.announceMessagesState.recipients}
              selectedMessageId={
                this.props.announceMessagesState.selectedMessageId
              }
              currentOperator={this.props.currentOperator}
            />
          </SplitLayout>
        </div>
      </React.Fragment>
    );
  }

  private handleClickNewMessage = () => {
    this.props.openModal();
    this.handleNavigateColumn();
  };

  private handleNavigateColumn = () => {
    this.setState({
      focusedColumn: Column.Right,
    });
  };

  private handleCloseRightColumn = () => {
    this.setState({
      focusedColumn: Column.Left,
    });
  };

  private getOpenedTag = () => {
    const { announceMessagesState } = this.props;
    const { announceDestinationTagOptions } = this.props.announceMessagesState;

    if (announceDestinationTagOptions.data) {
      return (
        announceDestinationTagOptions.data.find(
          (tag) => tag.id === announceMessagesState.openedTagId,
        ) || null
      );
    } else {
      return null;
    }
  };
}

const actions = {
  openModal: openAnnounceDestinationModal,
  closeModal: closeAnnounceDestinationModal,
};

export default withRouter<Props>(
  connect(null, actions)(SectionAnnounceMessages),
);
