import ApiClient from "./../api";
import { StaffBoardPostFormInterface } from "../interfaces/StaffBoardPostInterface";
import * as queryString from "query-string";

export const StaffBoardApi = {
  getPosts: (sectionId: string, page: number, per: number = 20) => {
    const query = `?${queryString.stringify({ page, per })}`;
    return ApiClient.get(`/api/v1/sections/${sectionId}/staff_board_posts`, {
      query,
    });
  },
  getPost: (sectionId: string, staffBoardPostId: string) => {
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/staff_board_posts/${staffBoardPostId}`,
    );
  },
  createPost: (sectionId: string, params: StaffBoardPostFormInterface) => {
    return ApiClient.post(`/api/v1/sections/${sectionId}/staff_board_posts`, {
      staff_board_post: params,
    });
  },
  deletePost: (sectionId: string, staffBoardPostId: string) => {
    return ApiClient.delete(
      `/api/v1/sections/${sectionId}/staff_board_posts/${staffBoardPostId}`,
    );
  },
  updatePost: (
    sectionId: string,
    StaffBoardPostId: string,
    params: StaffBoardPostFormInterface,
  ): Promise<Response> => {
    return ApiClient.patch(
      `/api/v1/sections/${sectionId}/staff_board_posts/${StaffBoardPostId}`,
      { staff_board_post: params },
    );
  },
  postComment: (
    sectionId: string,
    staffBoardPostId: string,
    params: {
      name: string;
      comment: string;
    },
  ) => {
    return ApiClient.post(
      `/api/v1/sections/${sectionId}/staff_board_posts/${staffBoardPostId}/comments`,
      { staff_board_post_comment: params },
    );
  },
  deleteComment: (
    sectionId: string,
    staffBoardPostId: string,
    commentId: string,
  ) => {
    return ApiClient.delete(
      `/api/v1/sections/${sectionId}/staff_board_posts/${staffBoardPostId}/comments/${commentId}`,
    );
  },
};
