import { useQuery } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../../errors";
import api from "../../../api";

type UseFetchCheckTokenProps = {
  token: string;
};

export const useFetchCheckToken = ({ token }: UseFetchCheckTokenProps) => {
  return useQuery<unknown, HTTPErrors>({
    queryKey: [`/api/v1/activations/${token}`],
    queryFn: async () => {
      const response = await api.get(`/api/v1/activations/${token}`);

      if (response.ok) {
        return null;
      }
      throw await createError(response);
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });
};
