import * as React from "react";
import { QRCodeSVG } from "qrcode.react";

import { Button } from "@studyplus/boron-ui";
import Icon from "../../../../components/atoms/Icon";
import { Modal } from "../../../../components/atoms/Modal";
import styles from "./styles.scss";

interface Props {
  title: string;
  qrcodeString: string;
  beforePrintButton?: JSX.Element;
  afterPrintButton?: JSX.Element;
}

const handlePrint = () => {
  window.print();
};
const QRCodePrintButton: React.FC = () => {
  return (
    <Button
      className={styles.printButton}
      onClick={handlePrint}
      disabled={false}
    >
      QRコードを印刷
    </Button>
  );
};
const QRCodeModal: React.FC<Props> = (props: Props): JSX.Element => {
  const [isOpen, setOpen] = React.useState(false);
  const handleOpen = () => {
    const root = document.querySelector("#root");
    if (root) {
      root.classList.add(styles.printBody);
    }
    setOpen(true);
  };
  const handleClose = () => {
    const root = document.querySelector("#root");
    if (root) {
      root.classList.remove(styles.printBody);
    }
    setOpen(false);
  };
  return (
    <>
      <button className="p-0" onClick={handleOpen} aria-label="QRコードを表示">
        <Icon className={styles.button} name="icon-qr" />
      </button>
      <Modal
        className={styles.modal}
        isOpen={isOpen}
        onRequestClose={handleClose}
      >
        <Modal.Header onClose={handleClose}>
          <h2>{props.title}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.body}>
            <div className={styles.qrcodeContainer}>
              <QRCodeSVG
                className={styles.qrcode}
                value={props.qrcodeString}
                size={300}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
                imageSettings={{
                  src: "",
                  height: 24,
                  width: 24,
                  excavate: true,
                }}
              />
            </div>
            {props.beforePrintButton}
            <QRCodePrintButton />
            {props.afterPrintButton}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QRCodeModal;
