import ApiClient from "../api";
import { GetRequestOptions } from "../api";
import { PostZoomMeetingMessageParams } from "../interfaces/PostZoomMeetingMessageParams";

export interface PostMessageParams {
  content?: string;
  image?: File;
}

const StudentMessageApi = {
  interruptGetMessages: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    // NOTE:
    // 通常のApiClient.interruptGetではパス名ごとにabortControllerを管理しているので、
    // students/:id/direct_messagesを1本のAPIとして認識して競合するリクエストをabortしてから
    // 次のリクエストを投げている
    const apiRegexp = /\/api\/v1\/students\/.+\/direct_messages/;
    const abortControllers = ApiClient.abortControllerStore.filter(
      (abortControllerWrapper) => apiRegexp.test(abortControllerWrapper.path),
    );
    if (abortControllers.length > 0) {
      abortControllers.forEach((abortController) => {
        ApiClient.abortPreviousRequest(abortController.path);
      });
    }

    return ApiClient.interruptGet(
      `/api/v1/students/${studentId}/direct_messages`,
      options,
    );
  },

  getMessages: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/direct_messages`,
      options,
    );
  },

  getBotMessages: (
    studentId: string,
    pointer: { from?: string; to?: string },
  ): Promise<Response> => {
    const options = { query: pointer };
    return ApiClient.get(`/api/v1/students/${studentId}/bot_messages`, options);
  },

  postMessage: (studentId: string, content: string): Promise<Response> => {
    return ApiClient.post(`/api/v1/students/${studentId}/direct_messages`, {
      content,
    });
  },

  postMessageFile: async (studentId: string, file: File): Promise<Response> => {
    const path = `/api/v1/students/${studentId}/direct_messages`;
    const body = file.type === "application/pdf" ? { file } : { image: file };
    return ApiClient.sendFormData(path, "POST", body);
  },

  postFollow: (studentId: string): Promise<Response> => {
    return ApiClient.post(
      `/api/v1/students/${studentId}/direct_messages/follow`,
    );
  },

  deleteFollow: (studentId: string): Promise<Response> => {
    return ApiClient.delete(
      `/api/v1/students/${studentId}/direct_messages/follow`,
    );
  },

  getFollowStatus: (studentId: string): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/direct_messages/follow`,
    );
  },

  changeBotMessageStatusDone: (
    studentId: string,
    messageId: string,
  ): Promise<Response> => {
    return ApiClient.put(
      `/api/v1/students/${studentId}/bot_messages/${messageId}/done`,
    );
  },

  changeBotMessageStatusIgnored: (
    studentId: string,
    messageId: string,
  ): Promise<Response> => {
    return ApiClient.put(
      `/api/v1/students/${studentId}/bot_messages/${messageId}/ignored`,
    );
  },

  postZoomMeetingMessage: (
    studentId: string,
    params: PostZoomMeetingMessageParams,
  ): Promise<Response> => {
    return ApiClient.post(
      `/api/v1/students/${studentId}/direct_messages/zoom_meetings`,
      params,
    );
  },

  deleteMessage: (studentId: string, messageId: string): Promise<Response> => {
    return ApiClient.delete(
      `/api/v1/students/${studentId}/direct_messages/${messageId}/delete`,
    );
  },
};

export default StudentMessageApi;
