import * as React from "react";
import styles from "./styles.scss";
import {
  LearningMaterialTag,
  LearningMaterialTagWithDeletable,
} from "../../../domains/LearningMaterialTag";
import { Modal } from "../../atoms/Modal";
import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../atoms/Flex";
import { SelectedLearningMaterialTagButton } from "../LearningMaterialTagButton";
import { LearningMaterialTagSelector } from "../LearningMaterialTagSelector";

export type LearningMaterialTagAssociation = {
  learningMaterialName: string;
  learningMaterialCode: string;
  learningMaterialTags: ReadonlyArray<LearningMaterialTagWithDeletable>;
};

export const useOpenLearningMaterialTagModal = () => {
  const [currentLearningMaterial, setCurrentLearningMaterial] =
    React.useState<LearningMaterialTagAssociation | null>(null);
  return {
    currentLearningMaterial,
    isOpenModal: !!currentLearningMaterial,
    openModal: (lm: LearningMaterialTagAssociation) =>
      setCurrentLearningMaterial(lm),
    closeModal: () => setCurrentLearningMaterial(null),
  };
};

const useSelectedTags = (
  currentLearningMaterial: LearningMaterialTagAssociation | null,
) => {
  const [selectedTags, setSelectedTags] = React.useState<
    ReadonlyArray<LearningMaterialTagWithDeletable>
  >([]);
  React.useEffect(() => {
    setSelectedTags(currentLearningMaterial?.learningMaterialTags || []);
  }, [currentLearningMaterial?.learningMaterialCode]);
  const addTag = (tag: LearningMaterialTag) => {
    selectedTags.find((t) => t.id === tag.id) ||
      setSelectedTags([...selectedTags, { ...tag, isDeletable: true }]);
  };
  const removeTag = (tag: LearningMaterialTag) => {
    setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
  };
  return {
    selectedTags,
    addTag,
    removeTag,
  };
};

type Props = {
  sectionId: string;
  selectableTags: LearningMaterialTag[];
  onSubmit: (
    learningMaterial: LearningMaterialTagAssociation,
    selectedTags: ReadonlyArray<LearningMaterialTagWithDeletable>,
  ) => void;
  isSubmitting: boolean;
  currentLearningMaterial: LearningMaterialTagAssociation | null;
  isOpenModal: boolean;
  closeModal: () => void;
};
export const LearningMaterialTagModal = ({
  sectionId,
  selectableTags,
  onSubmit,
  isSubmitting,
  currentLearningMaterial,
  closeModal,
  isOpenModal,
}: Props) => {
  const { addTag, removeTag, selectedTags } = useSelectedTags(
    currentLearningMaterial,
  );

  if (!currentLearningMaterial) return null;

  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal}>
      <Modal.Header onClose={closeModal}>
        {currentLearningMaterial.learningMaterialName.length > 0
          ? `${currentLearningMaterial.learningMaterialName}の教材タグ設定`
          : "教材タグ設定"}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caution_statement}>
          ※生徒の教材に設定した「教材タグ」は校舎全体の教材に反映されます。
        </div>
        <Flex justifyContent="left" marginTop="1.2rem">
          <LearningMaterialTagSelector
            sectionId={sectionId}
            tags={selectableTags}
            selectedTags={selectedTags}
            onSelect={addTag}
            onDeselect={removeTag}
            isSelectable={(tag) => {
              const selected = selectedTags.find((t) => t.id === tag.id);
              return !selected || selected.isDeletable;
            }}
          />
        </Flex>
        <SelectedTagList tags={selectedTags} removeTag={removeTag} />
        <Flex justifyContent="left">
          <span className={styles.caution_statement}>
            ※事前に設定されている教材タグ（デフォルトタグ）は削除できません。
          </span>
        </Flex>
        <Flex justifyContent="center" marginTop="1.6rem">
          <Button
            size="md"
            onClick={() => {
              currentLearningMaterial &&
                onSubmit(currentLearningMaterial, selectedTags);
            }}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            教材タグを設定
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

const SelectedTagList = ({
  tags,
  removeTag,
}: {
  tags: ReadonlyArray<LearningMaterialTagWithDeletable>;
  removeTag: (tag: LearningMaterialTag) => void;
}) => (
  <div className={styles.selected_tags_container}>
    {tags.map((tag) => (
      <div key={`selected_tag-${tag.id}`} className={styles.selected_tag}>
        <SelectedLearningMaterialTagButton
          tag={tag}
          isDeselectable={tag.isDeletable}
          onClick={() => removeTag(tag)}
        />
      </div>
    ))}
  </div>
);
