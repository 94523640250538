import ErrorTemplate from "../../../components/templates/ErrorTemplate/index";

const NotFoundErrorPage = () => {
  return (
    <ErrorTemplate
      title="お探しのページが見つかりません。"
      heading="お探しのページが見つかりません。"
    >
      <p>
        一時的にアクセス出来ない状態か、移動もしくは削除された可能性があります。
      </p>
      <p>また、URLに誤りがないか再度ご確認ください。</p>
    </ErrorTemplate>
  );
};

export default NotFoundErrorPage;
