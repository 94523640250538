import * as React from "react";
import { PageHeader } from "../../components/atoms/MainSection";
import { IconHelp } from "@studyplus/boron-ui";
import enhanceStudentsPage, {
  StudentsPageProps,
} from "../../hocs/enhanceStudentsPage";
import { OutletContextProps } from "../../helpers/RouterHelper";
import StudentInterface from "../../interfaces/StudentInterface";
import { ExaminationResultForm } from "../../components/features/ExaminationResultForm/ExaminationResultForm";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemLink,
} from "../../components/general/Breadcrumb";
import { useNewExaminationResultFormEffect } from "./useNewExaminationResultFormEffect";
import { useExaminationResultForm } from "../../components/features/ExaminationResultForm/useExaminationResultForm";
import { useFetchStudentExaminationResult } from "../StudentsAnalyticsExaminationResultPage/useFetchStudentExaminationResult";
import { matchHttpError } from "../../errors";

type Props = StudentsPageProps &
  OutletContextProps & {
    student: StudentInterface;
  };

const StudentsExaminationResultsNewPage_ = ({
  student,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbItemLink
            href={`/students/${student.id}/analytics/examination`}
          >
            <div className="mr-4 text-lg">成績記録一覧</div>
          </BreadcrumbItemLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrent>
          <span className="ml-4">成績記録の登録</span>
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="mt-8 rounded border border-solid border-gray-400 bg-white px-7 pb-7 pt-8">
        <PageHeader>成績記録の登録</PageHeader>
        <div className="mt-7">
          <Description />
        </div>
        <div className="mt-9">
          <NewExaminationResultForm student={student} />
        </div>
      </div>
    </>
  );
};

const Description = () => {
  return (
    <div className="flex items-center gap-4">
      <p>成績情報の入力は登録方法を確認しながら進めてください。</p>
      <a
        href="https://fs-help.studyplus.co.jp/ja/articles/7963949"
        className="text-blue-400"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="inline-flex items-center gap-1">
          <IconHelp />
          登録方法を見る
        </span>
      </a>
    </div>
  );
};

const NewExaminationResultForm = ({
  student,
}: {
  student: Props["student"];
}) => {
  const form = useExaminationResultForm({
    studentId: student.id,
    submitType: "create",
  });

  useNewExaminationResultFormEffect(form);
  const { registrationStatus, isLoading } = useExaminationResult({
    studentId: student.id,
    examinationId: form.values.examination?.id,
  });

  return (
    <ExaminationResultForm
      studentId={student.id}
      form={form}
      resultDisabled={
        form.values.examination === null ||
        (!isLoading && registrationStatus === "registered")
      }
      isExaminationSelectable={true}
    />
  );
};

// 選択中の試験の成績登録状況
type RegistrationStatus = "registered" | "unregistered";
const useExaminationResult = ({
  studentId,
  examinationId,
}: {
  studentId: string;
  examinationId?: string;
}) => {
  const [registrationStatus, setRegistrationStatus] =
    React.useState<RegistrationStatus | null>(null);
  const { isSuccess, isError, error, data, isLoading } =
    useFetchStudentExaminationResult({
      studentId,
      examinationId: examinationId ?? null,
      retry: false,
    });

  // APIからのレスポンスが404 NotFundのとき
  if (isError && error && registrationStatus != "unregistered") {
    matchHttpError(error, {
      caseNotFound: () => {
        setRegistrationStatus("unregistered");
      },
    });
  }

  // データが取得できたとき
  if (isSuccess && data && registrationStatus != "registered") {
    setRegistrationStatus("registered");
  }

  return {
    registrationStatus,
    examinationResult: data,
    isLoading,
  };
};

export const StudentsExaminationResultsNewPage = enhanceStudentsPage(
  StudentsExaminationResultsNewPage_,
  {
    title: "成績記録の登録",
  },
);
