import StudyRecordInterface from "../../interfaces/StudyRecordInterface";

type Props = {
  studyRecord: StudyRecordInterface;
};
// 要点・ひとことメモの吹き出しコンポーネント
export const CommentFromStudent = ({ studyRecord }: Props) => {
  if (studyRecord.restrictedAttributes.includes("comment")) return null;
  if (!studyRecord.comment) return null;

  return (
    <div className="mt-7 h-fit min-w-[14rem] max-w-[14rem] rounded-b-[2rem] rounded-tr-[2rem] border border-solid border-gray-400">
      <p className="line-height-6 mb-3 line-clamp-[9] overflow-hidden whitespace-pre-wrap break-words px-4 pt-3 group-hover:opacity-50">
        {studyRecord.comment}
      </p>
    </div>
  );
};
