import * as React from "react";

export const useOnMainScrollAreaScroll = (
  handleScroll: () => void,
  deps: React.DependencyList,
) => {
  React.useEffect(() => {
    window.onMainScrollAreaScroll = handleScroll;

    return () => {
      window.onMainScrollAreaScroll = undefined;
    };
  }, [...deps]);
};
