import { useMutation } from "@tanstack/react-query";
import {
  createError,
  HTTPErrors,
  UnprocessableEntityError,
  UnprocessableEntityErrorItem,
} from "../../../errors";
import StudentGuardianMessageApi from "../../../api-clients/StudentGuardianMessageApi";
import GuardianMessageInterface from "../../../interfaces/GuardianMessageInterface";

type Props = {
  studentId: string;
  onSuccess: (response: GuardianMessageInterface[]) => void;
  onError: (error: any) => void;
};

type Params = {
  content?: string;
  file?: File;
};
export const usePostGuardianMessage = ({
  studentId,
  onSuccess,
  onError,
}: Props) => {
  return useMutation<GuardianMessageInterface[], HTTPErrors, Params>({
    mutationFn: async (params) => {
      const res = await StudentGuardianMessageApi.postGuardianMessage(
        studentId,
        params.content,
        params.file,
      );

      if (res.ok) {
        const json = await res.json();
        return json.guardianMessages;
      }

      if (res.status === 422) {
        const json = await res.json();
        throw new UnprocessableEntityError(
          json.errors as UnprocessableEntityErrorItem[],
        );
      }

      throw await createError(res);
    },
    onSuccess,
    onError,
  });
};

type PostFileParams = {
  file: File;
};
export const usePostGuardianMessageFile = ({
  studentId,
  onSuccess,
  onError,
}: Props) => {
  return useMutation<GuardianMessageInterface[], HTTPErrors, PostFileParams>({
    mutationFn: async (params) => {
      const res = await StudentGuardianMessageApi.postGuardianMessage(
        studentId,
        undefined,
        params.file,
      );

      if (res.ok) {
        const json = await res.json();
        return json.guardianMessages;
      }

      if (res.status === 422) {
        const json = await res.json();
        throw new UnprocessableEntityError(
          json.errors as UnprocessableEntityErrorItem[],
        );
      }

      throw await createError(res);
    },
    onSuccess,
    onError,
  });
};
