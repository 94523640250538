import * as yup from "yup";
const validationSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .min(8, "パスワードは8文字以上で入力してください")
    .required("パスワードを入力してください"),
  passwordConfirmation: yup
    .string()
    .trim()
    .required("パスワード（確認）を入力してください")
    .oneOf([yup.ref("password")], "新しいパスワードと一致しません"),
});

export default validationSchema;
