import { Field, Form, ErrorMessage, FormikErrors, Formik } from "formik";
import Button from "../../atoms/Button/index";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.scss";
import * as yup from "yup";
import Input from "../../atoms/Input/index";
import Label from "../../atoms/Label/index";
import ErrorText from "../../atoms/ErrorText/index";
import { TermAndPolicyField } from "../../molecules/TermAndPolicyField";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useMutateActivation } from "./useMutateActivation";

interface Props {
  token: string;
}

const ActivationForm = (props: Props) => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate, isPending } = useMutateActivation({
    onSuccess: () => {
      showSuccessMessage(
        "アカウントを登録しました。ログインを行ってください。",
      );
      navigate("/login");
    },
    onError: () => {
      showErrorMessage("アカウント登録ができませんでした");
    },
  });

  const onSubmit = (values: typeof initialValues) => {
    if (!values.isTermsAccepted) {
      return;
    }

    mutate({
      emailActivationToken: props.token,
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password,
      password_confirmation: values.passwordConfirm,
    });
  };

  return (
    <div className={styles.root}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Label>氏名</Label>
            <div className={styles.nameContainer}>
              <div className={styles.lastNameContainer}>
                <Field
                  name="lastName"
                  id="ActivateForm-lastName-input"
                  component={Input}
                  hasError={formik.touched.lastName && formik.errors.lastName}
                />
                <ErrorMessage component={ErrorText} name="lastName" />
              </div>

              <div className={styles.firstNameContainer}>
                <Field
                  name="firstName"
                  id="ActivateForm-firstName-input"
                  component={Input}
                  hasError={formik.touched.firstName && formik.errors.firstName}
                />
                <ErrorMessage component={ErrorText} name="firstName" />
              </div>
            </div>

            <div className={styles.passwordContainer}>
              <Label>パスワード</Label>
              <Field
                type="password"
                name="password"
                id="ActivateForm-password-input"
                component={Input}
                hasError={formik.touched.password && formik.errors.password}
              />
              <ErrorMessage component={ErrorText} name="password" />
            </div>

            <div className={styles.passwordConfirmContainer}>
              <Label>パスワード（確認）</Label>
              <Field
                type="password"
                name="passwordConfirm"
                id="ActivateForm-passwordConfirm-input"
                component={Input}
                hasError={
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm
                }
              />
              <ErrorMessage component={ErrorText} name="passwordConfirm" />
            </div>

            <div className={styles.checkboxContainer}>
              <TermAndPolicyField id={"ActivateForm-terms-checkbox"} />
            </div>

            <div className={styles.buttonContainer}>
              <Button
                disabled={!formik.isValid || isPending}
                isBlock={true}
                type="submit"
              >
                アカウント登録
              </Button>
            </div>

            <p className={styles.description}>
              アカウントをお持ちの方は<Link to="/login">ログイン</Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

interface Values {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  isTermsAccepted: boolean;
}

const initialValues = {
  lastName: "",
  firstName: "",
  password: "",
  passwordConfirm: "",
  isTermsAccepted: false,
};

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("名をご入力ください"),
  lastName: yup.string().trim().required("姓をご入力ください"),
  password: yup
    .string()
    .trim()
    .required("パスワードをご入力ください")
    // TODO: 英数字・記号のみであることのバリデーション
    .min(8, "8文字以上の英数字・記号のみでご入力ください"),
  passwordConfirm: yup
    .string()
    .trim()
    .required("パスワード（確認）をご入力ください")
    .min(8, "8文字以上の英数字・記号のみでご入力ください"),
  isTermsAccepted: yup
    .boolean()
    .oneOf([true], "利用規約、プライバシーポリシーに同意してください"),
});

const validate = (values: Values) => {
  const errors: FormikErrors<Values & { passwords: string }> = {};

  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = "パスワード(確認)とパスワードの入力が一致しません";
  }

  return errors;
};

export default ActivationForm;
