import ApiClient from "../api";
import { OperatorSettingsPasswordFormInterface } from "../interfaces/OperatorSettingsInterface";

const OperatorSettingsApi = {
  updateEmail: (email: string): Promise<Response> => {
    const body = {
      operator_email_confirmation_token: { email },
    };
    return ApiClient.patch(`/api/v1/settings/email`, body);
  },
  updateName: (firstName: string, lastName: string): Promise<Response> => {
    const body = {
      operator: {
        first_name: firstName,
        last_name: lastName,
      },
    };
    return ApiClient.patch(`/api/v1/settings/profile`, body);
  },
  updatePassword: (
    params: OperatorSettingsPasswordFormInterface,
  ): Promise<Response> => {
    return ApiClient.patch(`/api/v1/settings/password`, { operator: params });
  },
  putEmail: (token: string): Promise<Response> => {
    return ApiClient.put(`/api/v1/settings/email/${token}`);
  },
  getProfile: (): Promise<Response> => {
    return ApiClient.get(`/api/v1/settings/profile`);
  },
};

export default OperatorSettingsApi;
