import * as React from "react";
import styles from "./CheckboxColumnGrid.scss";
import classNames from "classnames";

/**
 * ページング時にカラムの幅がガタつかないようにtable-layout:fixedのテーブルにしたい
 * チェックボックスのカラムを%ではなく絶対値の幅指定にしたいが、そうすると他のカラムの%表示と辻褄が合わなくなる
 * よってチェックボックスを他の情報のカラムに吸収して、伸縮が発生しないように&thとtdでチェックボックスが同じ位置に来るようにする
 */
type CheckboxColumGridProps = {
  noGap?: boolean;
};

export const CheckboxColumGrid = ({
  noGap,
  children,
}: React.PropsWithChildren<CheckboxColumGridProps>) => {
  return (
    <div
      className={classNames(styles.CheckboxColumGrid, {
        [styles["CheckboxColumGrid--nogap"]]: noGap,
      })}
    >
      {children}
    </div>
  );
};

export const ChecboxColumnGridText = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <div className={styles.CheckboxColumGrid__Text}>{children}</div>
);
