import * as React from "react";
import { BillingPlan } from "../../../../domains/BillingPlan";
import StudyRecordInterface from "../../../../interfaces/StudyRecordInterface";
import styles from "./styles.scss";
import StudentInterface from "../../../../interfaces/StudentInterface";
import TimelineItemFooter from "./TimelineItemFooter";
import { Header } from "./Header";
import { Images } from "./Images";
import { Comment } from "./Comment";
import { Content } from "./Content";
import { useFetchSectionsTimelineSummary } from "../../../../features/SectionsTimelineSummaryPage/useFetchSectionsTimelineSummary";
import classNames from "classnames";

interface Props {
  student: StudentInterface;
  sectionId: string;
  studyRecord: StudyRecordInterface;
  canSendComment: boolean;
  showHeader?: boolean;
  billingPlan?: BillingPlan;
  updateStudyRecordQueryCache?: ReturnType<
    typeof useFetchSectionsTimelineSummary
  >["updateStudyRecordQueryCache"];
  isFocus?: boolean;
  isLastItem?: boolean;
}

const TimelineItem = ({
  showHeader,
  student,
  studyRecord,
  billingPlan,
  sectionId,
  canSendComment,
  updateStudyRecordQueryCache,
  isFocus = false,
  isLastItem = false,
}: Props): JSX.Element => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const firstRenderRef = React.useRef<boolean>(true);
  React.useEffect(() => {
    if (firstRenderRef.current && isFocus && rootRef.current) {
      // NOTE: いいねやコメントをした時の再描画でスクロールするのを防ぐため、1回だけスクロールさせる
      firstRenderRef.current = false;
      rootRef.current.scrollIntoView();
    }
  }, [firstRenderRef.current, isFocus, rootRef.current]);

  return React.useMemo(() => {
    return (
      <div
        ref={rootRef}
        className={classNames(styles.root, { [styles.focus]: isFocus })}
        data-testid="timeline-item"
      >
        {showHeader && (
          <Header
            student={student}
            studyRecord={studyRecord}
            billingPlan={billingPlan}
          />
        )}
        <div className={styles.body}>
          {studyRecord.studyRecordImages.length > 0 && (
            <Images studyRecord={studyRecord} />
          )}
          <Comment studyRecord={studyRecord} />
          <Content
            studyRecord={studyRecord}
            student={student}
            sectionId={sectionId}
            showHeader={showHeader}
          />
        </div>
        <TimelineItemFooter
          studyRecord={studyRecord}
          student={student}
          canSendComment={canSendComment}
          updateStudyRecordQueryCache={updateStudyRecordQueryCache}
          isLastItem={isLastItem}
        />
      </div>
    );
  }, [studyRecord]);
};

export default TimelineItem;
