import * as React from "react";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import { SectionsSettingsPageProps } from "../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { OutletContextProps } from "../../helpers/RouterHelper";
import { BreadcrumbItemLink } from "../../components/general/Breadcrumb/Breadcrumb";
import Loader from "../../components/atoms/Loader/index";
import { useFetchDrillDetail } from "./useFetchDrill";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../components/general/Breadcrumb/Breadcrumb";
import { useFetchDrillProgress } from "./useFetchDrillProgress";
import { DrillProgressTable } from "./DrillProgressTable";
import { DrillDetail } from "./DrillDetail";
import { useNavigate, useParams } from "react-router";
import { useQueryError } from "../../hooks/http/useQueryError";
import { OrderDirectionType } from "../../components/molecules/SortLabel";
import { useSearchParams } from "react-router-dom";
import { DrillProgressLegend } from "../../components/features/DrillProgressLegend";
import StudentFilter from "../../components/features/NewStudentFilter";
import FiltersHelper, {
  buildStudentFilterParams,
} from "../../helpers/FiltersHelper";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { SectionAnalyticsTypeTab } from "../../components/features/SectionAnalyticsTypeTab";

// Main hooks
export type UseSectionsAnalyticsRetentionLevelDetailProps = {
  section: SectionInterface;
};

type Props = SectionsSettingsPageProps &
  UseSectionsAnalyticsRetentionLevelDetailProps &
  OutletContextProps;

const SectionsAnalyticsRetentionLevelDetail = (props: Props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    props.setActiveMenu("analytics");
  }, []);
  const { materialCode } = useParams<{
    materialCode: string;
  }>();
  const section = props.section;
  const [searchParams] = useSearchParams();
  const order = (searchParams.get("order") as OrderDirectionType) || "desc";

  const { findTagFilter } = useStudentFilterContext();
  const studentFilter = findTagFilter({ sectionId: section.id });
  const filtersQuery = studentFilter
    ? buildStudentFilterParams(studentFilter)
    : {};
  const filterString = studentFilter
    ? FiltersHelper.toQueryString({ tagFilter: studentFilter })
    : "";

  const onOrderChange = () => {
    navigate(`${filterString}&order=${order === "desc" ? "asc" : "desc"}`);
  };

  const {
    data: digitalLearningMaterialsProgress,
    isFetchingNextPage,
    error: errorLearningMaterial,
  } = useFetchDrillProgress({
    section,
    materialCode,
    order,
    studentFilterParams: filtersQuery,
  });

  useQueryError(errorLearningMaterial);

  const {
    data: drill,
    isLoading,
    error,
  } = useFetchDrillDetail({
    section,
    materialCode,
  });

  useQueryError(error);

  if (isLoading || !drill) {
    return <Loader loading />;
  }

  return (
    <>
      <SectionAnalyticsTypeTab
        sectionId={section.id}
        analyticsType="retentionLevel"
      />
      <div className="mt-5">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbItemLink
              href={`/sections/${section.id}/analytics/retention_level`}
            >
              <div className="mr-4 text-lg">学習定着</div>
            </BreadcrumbItemLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrent>
            <span className="ml-4">{drill.name}</span>
          </BreadcrumbItem>
        </Breadcrumb>

        <StudentFilter sectionId={section.id} />

        <div
          className={
            "mt-7 rounded-md border border-solid border-gray-400 bg-white p-7 pt-9"
          }
        >
          <DrillDetail
            name={drill.name}
            imageUrl={drill.imageUrl}
            creatorName={drill.creatorName}
            sectionCount={drill.sections.length}
          />
          <div className="flex flex-nowrap justify-end pb-6">
            <DrillProgressLegend />
          </div>
          {digitalLearningMaterialsProgress ? (
            <DrillProgressTable
              drillLearningMaterialsProgresses={
                digitalLearningMaterialsProgress
              }
              drillSections={drill.sections}
              onOrderChange={onOrderChange}
              order={order}
            />
          ) : (
            <Loader loading />
          )}
          <Loader loading={isFetchingNextPage} />
        </div>
      </div>
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};

export const SectionsAnalyticsRetentionLevelDetailPage =
  enhanceSectionsSettingsPage(SectionsAnalyticsRetentionLevelDetail, pageInfo);
