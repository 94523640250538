import { SectionBody, SectionContainer, SectionTitle } from "./Section";
import { IconPlusTag } from "@studyplus/boron-ui";
import { DrillLearningMaterialItem } from "./DrillLearningMaterialItem";
import { useStudyTaskForm } from "./useStudyTaskForm";
import classNames from "classnames";

type Props = {
  form: ReturnType<typeof useStudyTaskForm>;
  openDrillLearningMaterialSelectionFormHandler: (index: number) => void;
};

export const DrillLearningMaterialsArea = ({
  form,
  openDrillLearningMaterialSelectionFormHandler,
}: Props) => {
  return (
    <SectionContainer>
      <SectionTitle>
        <span className="flex h-[1rem] w-[1rem] items-center justify-center rounded-full bg-blue-400 p-1 text-sm text-white">
          2
        </span>
        <div className="ml-4">課題の設定 </div>
        <span className="ml-3 text-md">※一度に最大10教材まで登録できます</span>
      </SectionTitle>
      <SectionBody>
        {form.values.study_tasks.map((studyTask, index) => (
          <DrillLearningMaterialItem
            key={index}
            studyTaskIndex={index}
            form={form}
            drillLearningMaterial={studyTask.drill_learning_material}
            removeItemHandler={() => form.removeStudyTask(index)}
            openDrillLearningMaterialHandler={() =>
              openDrillLearningMaterialSelectionFormHandler(index)
            }
          />
        ))}
        <button
          className={classNames("flex cursor-default items-center", {
            "cursor-pointer hover:opacity-60": !form.isAddStudyTaskDisabled,
          })}
          onClick={form.addEmptyStudyTask}
          disabled={form.isAddStudyTaskDisabled}
        >
          <div
            className={classNames(
              "mr-1 flex h-2 w-2 items-center justify-center rounded-[3px] bg-blue-400 text-white",
              {
                "bg-gray-500": form.isAddStudyTaskDisabled,
              },
            )}
          >
            <IconPlusTag height={"22px"} />
          </div>
          <div
            className={classNames("text-blue-400", {
              "text-gray-500": form.isAddStudyTaskDisabled,
            })}
          >
            課題を追加
          </div>
        </button>
      </SectionBody>
    </SectionContainer>
  );
};
