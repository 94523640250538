import * as yup from "yup";

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("名前を入力してください")
    .max(32, "名前は32文字以内で入力してください"),
  comment: yup.string().trim().required("コメントを入力してください"),
});
