import { Modal } from "../../../../components/atoms/Modal";
import { Link } from "react-router-dom";
import * as React from "react";
import StudentInterface from "../../../../interfaces/StudentInterface";
import { DisconnectAppForm } from "./DisconnectAppForm";

type Props = {
  student: StudentInterface;
  isModalOpen: boolean;
  closeModal: () => void;
  updateStudent: (student: StudentInterface) => void;
};
export const DisconnectAppModal = ({
  student,
  isModalOpen,
  closeModal,
  updateStudent,
}: Props) => {
  const handleSubmitSuccess = () => {
    closeModal();
  };
  return (
    <div onClick={stopPropagation}>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <Modal.Header onClose={closeModal}>
          <h2>
            <Link to={`/students/${student.id}`}>{student.fullName}</Link>
            の連携を停止
          </h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            アカウント連携を「<strong>停止</strong>」すると、
            <strong>
              最新の勉強記録の閲覧や、メッセージの送信などができなく
            </strong>
            なります。
            <br />
            また、「保護者LINE連携」をしている場合は同時に
            <strong>LINE連携も停止</strong>されます。
            <br />
            また、生徒がデジタル教材を利用中の場合は同時に
            <strong>教材利用も停止</strong>されます。
            <br />
            「停止」のご理由を選択の上、「連携を停止」を押してください。
          </p>
          <DisconnectAppForm
            student={student}
            onSubmitSuccess={handleSubmitSuccess}
            updateStudent={updateStudent}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};
