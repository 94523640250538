import styles from "./styles.scss";
import classNames from "classnames";
import { ColorProps, resolveColorStyles } from "../../../helpers/styles/Colors";

export type IconFileType = "svg" | "png";

interface Props {
  name: string;
  onClick?: (e: any) => void;
  className?: string;
  fileType?: IconFileType;
  disabled?: boolean;
  hoverWithBackground?: boolean;
  dataTestId?: string;
}

const attachParentClassName = (props: Props) => {
  if (props.className) {
    return `${styles.defaultSize} ${props.className}`;
  } else {
    return styles.defaultSize;
  }
};

const Icon = (props: Props & Pick<ColorProps, "color" | "hoverColor">) => {
  // SVGスプライトを利用しているので本来はすべてSVGにするべき
  // しかしグラデーションなど、スプライトにする過程で落ちてしまうものがあるため、
  // 一部のアイコンはpng画像にして利用している
  if (props.fileType === "png") {
    // NOTE: spanタグで囲んでいるのはflexboxコンテナ内のimg画像のアスペクト比が崩れる不具合への対処
    // ref: https://github.com/studyplus/boron-web/pull/514
    return (
      <span className={props.className || styles.defaultSize}>
        <img
          onClick={props.onClick}
          src={`/images/${props.name}.png`}
          className={classNames(props.className || styles.defaultSize, {
            [styles.disabled]: props.disabled,
            [styles.hoverWithBackground]: props.hoverWithBackground,
          })}
        />
      </span>
    );
  }

  return (
    <svg
      role="img"
      onClick={props.onClick}
      className={classNames(
        attachParentClassName(props),
        {
          [styles.disabled]: props.disabled,
          [styles.hoverWithBackground]: props.hoverWithBackground,
        },
        props.color ? resolveColorStyles(props) : "",
      )}
      data-testid={props.dataTestId}
    >
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`/static/sprite.svg#${props.name}`}
      />
    </svg>
  );
};

export default Icon;
