import { Dispatch } from "redux";
import {
  showErrorFlashMessage,
  FlashMessageAction,
  showSuccessFlashMessage,
} from "../../flashMessage";
import { createAction } from "redux-actions";
import AnnounceMessageApi from "../../../api-clients/AnnounceMessageApi";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import {
  MessageFormValuesInterface,
  MessageInterface,
} from "../../../interfaces/MessageInterface";
import StudentTag from "../../../domains/StudentTag";
import compressImage from "../../../helpers/compressImage";
import { PostZoomMeetingMessageParams } from "../../../interfaces/PostZoomMeetingMessageParams";
import {
  Actions,
  POST_ZOOM_MEETING_MESSAGE_REQUEST,
  POST_ZOOM_MEETING_MESSAGE_SUCCESS,
  POST_ZOOM_MEETING_MESSAGE_ERROR,
} from "./types";
import ApiClient from "../../../api";
import { AnnounceMessage } from "../../../interfaces/MessageInterface";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";

// メッセージ送信
const showPostAnnounceMessageError = (dispatch: Dispatch) => {
  dispatch(showErrorFlashMessage("メッセージが送信できませんでした"));
};
const postAnnounceMessageSending = createAction("POST_ANNOUNCE_MESSAGE");
const postAnnounceMessageSuccess = createAction(
  "POST_ANNOUNCE_MESSAGE_SUCCESS",
);
const postAnnounceMessageError = createAction("POST_ANNOUNCE_MESSAGE_ERROR");
export const postAnnounceMessage =
  (sectionId: string, tag: StudentTag, content: string) =>
  (dispatch: Dispatch): void => {
    dispatch(postAnnounceMessageSending({ tagId: tag.id }));
    AnnounceMessageApi.postMessage(sectionId, tag, content)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: { message: MessageInterface }) => {
            dispatch(
              postAnnounceMessageSuccess({
                tagId: tag.id,
                message: json.message,
              }),
            );
          });
        } else {
          showPostAnnounceMessageError(dispatch);
          dispatch(postAnnounceMessageError({ tagId: tag.id }));
        }
      })
      .catch((err: any) => {
        dispatch(postAnnounceMessageError({ tagId: tag.id }));
        console.error(err);
        showPostAnnounceMessageError(dispatch);
      });
  };

const postAnnounceMessageFileSending = createAction(
  "POST_ANNOUNCE_MESSAGE_FILE",
);
const postAnnounceMessageFileSuccess = createAction(
  "POST_ANNOUNCE_MESSAGE_FILE_SUCCESS",
);
const postAnnounceMessageFileError = createAction(
  "POST_ANNOUNCE_MESSAGE_FILE_ERROR",
);

const compressFile = async (file: File): Promise<File> => {
  const compressedFile =
    file.type === "application/pdf" ? file : await compressImage(file);
  return compressedFile;
};

export const postAnnounceMessageFile =
  (sectionId: string, tag: StudentTag, file: File) =>
  (dispatch: Dispatch): void => {
    dispatch(postAnnounceMessageFileSending({ tagId: tag.id }));

    compressFile(file)
      .then((compressedFile) => {
        return AnnounceMessageApi.postMessageFile(
          sectionId,
          tag,
          compressedFile,
        );
      })
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: { message: MessageInterface }) => {
            dispatch(
              postAnnounceMessageFileSuccess({
                tagId: tag.id,
                message: json.message,
              }),
            );
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(
              postAnnounceMessageFileError({
                tagId: tag.id,
                errors: json.errors,
              }),
            );
            showPostAnnounceMessageError(dispatch);
          });
        } else {
          dispatch(postAnnounceMessageFileError({ tagId: tag.id }));
          showPostAnnounceMessageError(dispatch);
        }
      })
      .catch(() => {
        dispatch(postAnnounceMessageFileError({ tagId: tag.id }));
        showPostAnnounceMessageError(dispatch);
      });
  };

const changeSectionAnnounceMessageFormValues = createAction<{
  tagId: string;
  values: MessageFormValuesInterface;
}>("CHANGE_SECTION_ANNOUNCE_MESSAGE_FORM_VALUES");
export const dispatchChangeSectionAnnounceMessageFormValues =
  (tagId: string, values: MessageFormValuesInterface) =>
  (dispatch: Dispatch): void => {
    dispatch(changeSectionAnnounceMessageFormValues({ tagId, values }));
  };

export const postAnnounceZoomMeetingMessage =
  (
    sectionId: string,
    destinationTag: AnnounceDestinationTagWithFormStateInterface,
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) =>
  (dispatch: Dispatch<Actions | FlashMessageAction>): void => {
    dispatch({ type: POST_ZOOM_MEETING_MESSAGE_REQUEST });
    setSubmitting(true);

    const requestBody = {
      destination_id: destinationTag.id,
      type: destinationTag.type,
      ...params,
    };

    ApiClient.post(
      `/api/v1/sections/${sectionId}/announce_messages/zoom_meetings`,
      requestBody,
    )
      .then((response: Response) => {
        if (response.ok) {
          response.json().then((json: { message: AnnounceMessage }) => {
            dispatch({
              type: POST_ZOOM_MEETING_MESSAGE_SUCCESS,
              payload: { tagId: destinationTag.id, message: json.message },
            });
            dispatch(showSuccessFlashMessage("メッセージを送信しました"));
          });
          setSubmitting(false);
          onSuccessCallback();
        } else if (response.status === 422) {
          response.json().then((json: ApiErrorResponseInterface) => {
            dispatch({ type: POST_ZOOM_MEETING_MESSAGE_ERROR, payload: json });
            const errors = json.errors.reduce<Record<string, unknown>>(
              (p, c) => {
                if (!c.field) return p;
                return { ...p, [c.field]: c.message };
              },
              {},
            );
            setErrors(errors);
            dispatch(showErrorFlashMessage("メッセージを送信できませんでした"));
            setSubmitting(false);
          });
        } else {
          dispatch({ type: POST_ZOOM_MEETING_MESSAGE_ERROR });
          dispatch(showErrorFlashMessage("メッセージを送信できませんでした"));
          setSubmitting(false);
        }
      })
      .catch(() => {
        dispatch({ type: POST_ZOOM_MEETING_MESSAGE_ERROR });
        dispatch(showErrorFlashMessage("メッセージを送信できませんでした"));
        setSubmitting(false);
      });
  };
