import { Link } from "react-router-dom";

type Props = {
  sectionId: string;
  learningMaterialCode: string;
};

export const StudyProgressLink = ({
  sectionId,
  learningMaterialCode,
}: Props) => {
  return (
    <Link
      to={`/sections/${sectionId}/analytics/retention_level/${learningMaterialCode}`}
      className="buiButtonBase buiButtonVariantOutline buiButtonSizeXs"
    >
      学習進捗を見る
    </Link>
  );
};
