import * as React from "react";
import { useScrollWatcher } from "../../../hooks/useScrollWatcher";

import Footer from "./Footer";
import Header from "./Header";
import Heading from "./Heading";
import Main from "./Main";
import styles from "./styles.scss";

const handleLoadMore = () => {
  if ((window as any).onMainScrollAreaScroll) {
    (window as any).onMainScrollAreaScroll();
  }
};

const SubLayout = ({ children }: React.PropsWithChildren<unknown>) => {
  const onScroll = useScrollWatcher({ onReachToBottom: handleLoadMore });
  // NOTE: SubLayoutでは画面全体がスクロールするので、スクロールイベントがwindowで発生するためイベントリスナーを登録します
  React.useEffect(() => {
    window.addEventListener("scroll", (e) => onScroll(e));
    return () => window.removeEventListener("scroll", (e) => onScroll(e));
  }, [onScroll]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

SubLayout.Header = Header;
SubLayout.Main = Main;
SubLayout.Heading = Heading;
SubLayout.Footer = Footer;

export default SubLayout;
