import * as React from "react";
import { connect } from "react-redux";
import SectionsSettingsTags from "./SectionsSettingsTags";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import { initializePage } from "../../../actions/pages/SectionsSettingsTagsPage/actions";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { OutletContextProps } from "../../../helpers/RouterHelper";

interface OuterProps {
  section: SectionInterface;
  tagFilter: StudentTagFilterInterface;
}

interface InnerProps {
  initializePage: typeof initializePage;
}

type Props = InnerProps & OuterProps & OutletContextProps;

const mapStateToProps = (state: AppStateInterface) => ({
  section: state.currentSection,
});

const actions = {
  initializePage,
};

const SectionsSettingsTagsPage = (props: Props) => {
  React.useEffect(() => {
    props.initializePage();
    props.setActiveMenu("tags");
  }, []);
  return (
    <SectionsSettingsTags section={props.section} tagFilter={props.tagFilter} />
  );
};

const pageInfo = {
  title: "タグ",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsTagsPage,
  pageInfo,
  ["tag:index"],
);

export default connect(mapStateToProps, actions)(EnhancedPage as any);
