import { ArchiveStatus, invertArchiveStatus } from "../../domains/Attendance";
import {
  useQueryClient,
  useMutation,
  InfiniteData,
} from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../errors";
import ApiClient from "../../api";
import { cacheKeyOf, ResponseData } from "../../hooks/http/useFetchLectures";

type Props = {
  sectionId: string;
  onSuccess: (archiveStatus: ArchiveStatus) => void;
  onError: (archiveStatus: ArchiveStatus) => void;
};
type Params = {
  lectureId: string;
  archiveStatus: ArchiveStatus;
};
export const useMutateLectureArchiveStatus = ({
  sectionId,
  onError,
  onSuccess,
}: Props) => {
  const client = useQueryClient();
  return useMutation<void, HTTPErrors, Params>({
    mutationFn: async ({ lectureId, archiveStatus }) => {
      const res = await ApiClient.patch(
        `/api/v1/sections/${sectionId}/lectures/${lectureId}`,
        { status: archiveStatus },
      );
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    onSuccess(_, param) {
      const cacheKey = cacheKeyOf(
        sectionId,
        invertArchiveStatus(param.archiveStatus),
      );
      const cache = client.getQueryData<InfiniteData<ResponseData>>(cacheKey);
      if (cache) {
        client.setQueryData<InfiniteData<ResponseData>>(cacheKey, {
          ...cache,
          pages: cache.pages.map((resData) => ({
            ...resData,
            data: resData.data.filter(
              (lecture) => lecture.id !== param.lectureId,
            ),
          })),
        });
      }
      onSuccess(param.archiveStatus);
    },
    onError(_, param) {
      onError(param.archiveStatus);
    },
  });
};
