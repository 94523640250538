export type BillingPlan = "free" | "pro";

export const toJapanese = (billingPlan: BillingPlan) => {
  switch (billingPlan) {
    case "free":
      return "フリー";
    case "pro":
      return "プロ";
  }
};
