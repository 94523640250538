export type Progress = {
  notStudiedCount: number;
  studyingCount: number;
  establishedCount: number;
  perfectCount: number;
};

export const calcTotalCount = (progress: Progress) => {
  const total = Object.values(progress).reduce(
    (prevCount: number, currentCount: number) => prevCount + currentCount,
  );
  // NOTE: 0件の場合に定着率を算出するときの0除算を防ぐために1を返す
  return total || 1;
};

export const toPercent = (numerator: number, denominator: number) => {
  const percent =
    denominator === 0 ? null : Math.round((numerator / denominator) * 100);

  return percent === null ? "ー" : `${percent}%`;
};
