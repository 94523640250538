import * as React from "react";
import { useFormik, FormikErrors } from "formik";
import {
  secondsToTime,
  createDateFromDateAndTimeString,
} from "../../../../helpers/TimeHelper";
import {
  Schedule,
  ScheduleType,
  validateDateRange,
  create,
} from "../../../../domains/Schedule";
import {
  RecurringOptions,
  createFromOptionAndStartDate,
  determineOption,
} from "../../../../domains/Recurrence";
import { calcCeiledTime } from "../../../../helpers/TimeHelper";
import { LearningMaterial } from "../../../../domains/LearningMaterial";
import { useEffectAvoidFirst } from "../../../../hooks/useEffectAvoidFirst";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import addDays from "date-fns/addDays";

export type ScheduleForm = {
  id: string;
  title: string;
  memo: string;
  locale: string;
  // データの変換はそれぞれのフォームのパーツの責務ではないので、フォームが提供してるデータ型をそのまま受ける
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  isWholeDay: boolean;
  learningMaterial: LearningMaterial | null;
  studyHours: number;
  studyMinutes: number;
  studyAmount: number;
  recurrence: RecurringOptions | null;
};

export type UseScheduleFormStateProps = {
  onSubmit: (schedule: Schedule) => void;
  defaultStartAt?: Date;
  defaultEndAt?: Date;
  schedule?: Schedule;
};

// eslint-disable-next-line
export const useScheduleFormState = (props: UseScheduleFormStateProps) => {
  const isSetMaterialRef = React.useRef(false);
  const unitTitleRef = React.useRef("ページ");

  const onSubmit = (values: ScheduleForm) => {
    const schedule = translateFormValuesToSchedule(values, props.schedule);
    props.onSubmit?.(schedule);
  };

  const initialValues = React.useMemo(
    () => createScheduleForm(props.schedule),
    [],
  );

  const {
    isValid,
    errors,
    touched,
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  React.useEffect(() => {
    if (values.learningMaterial !== null) {
      isSetMaterialRef.current = true;
      unitTitleRef.current = values.learningMaterial.unit;
    } else {
      isSetMaterialRef.current = false;
      unitTitleRef.current = "ページ";
    }
  }, [values.learningMaterial]);

  useSyncScheduleDurations({
    values,
    setFieldValue,
    isSetMaterial: isSetMaterialRef.current,
  });

  useSyncEndDate({
    values,
    setFieldValue,
  });

  const onChangeWholeDay = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue("isWholeDay", e.target.checked, false);

  return {
    values,
    isSetMaterial: isSetMaterialRef.current,
    handleChange,
    handleSubmit,
    touched,
    errors,
    isValid,
    setFieldValue,
    onChangeWholeDay,
    unitTitle: unitTitleRef.current,
  };
};

const validate = (
  values: ScheduleForm,
): FormikErrors<Record<string, string>> => {
  const errors: FormikErrors<Record<string, string>> = {};

  const start = new Date(
    `${format(values.startDate, "yyyy/MM/dd")} ${values.startTime}`,
  );
  const end = new Date(
    `${format(values.endDate, "yyyy/MM/dd")} ${values.endTime}`,
  );

  const result = validateDateRange(
    create({ startAt: start, endAt: end, allday: values.isWholeDay }),
  );

  if (result !== "valid") {
    errors.endDate = result.message;
  }

  return errors;
};

// フォームのデータからScheduleに詰め替えてAPI処理に渡せるようにする
const translateFormValuesToSchedule = (
  formValues: ScheduleForm,
  schedule?: Schedule,
): Schedule => {
  const start = new Date(
    `${format(formValues.startDate, "yyyy/MM/dd")} ${formValues.startTime}`,
  );

  const end = new Date(
    `${format(formValues.endDate, "yyyy/MM/dd")} ${formValues.endTime}`,
  );

  // この値はサーバーに送られるものではない
  const scheduleType: ScheduleType = schedule
    ? schedule.scheduleType
    : formValues.recurrence
      ? "recurring_original"
      : "single_schedule";

  const startAt = formValues.isWholeDay ? startOfDay(start) : start;

  const originalRecurrence = schedule ? schedule.recurrence : null;

  return {
    id: formValues.id,
    description: formValues.memo,
    locale: formValues.locale,
    startAt,
    endAt: formValues.isWholeDay
      ? // NOTE:
        // フォーム上では見た目の都合上1日引いた値で保持しているため、
        // 送信値にするときは1日足して戻す
        addDays(startOfDay(end), 1)
      : end,
    summary: formValues.title,
    studySchedule:
      formValues.learningMaterial === null
        ? null
        : {
            learningMaterial: {
              publicId: "",
              name: "",
              imageUrl: "",
              unit: "",
              code: formValues.learningMaterial.code,
            },
            amount: Number(formValues.studyAmount),
            numberOfSeconds:
              formValues.studyHours * 3600 + formValues.studyMinutes * 60,
          },
    recurrence: formValues.recurrence
      ? createFromOptionAndStartDate({
          selectedOption: formValues.recurrence,
          startDate: startAt,
          until: originalRecurrence ? originalRecurrence.until : null,
        })
      : null,
    allday: formValues.isWholeDay,
    scheduleType,
  };
};

export const createScheduleForm = (schedule?: Schedule): ScheduleForm => {
  const startDate = schedule?.startAt
    ? schedule.startAt
    : calcCeiledTime(new Date());
  const endDate = schedule?.endAt
    ? schedule.allday
      ? // NOTE:
        // 2021年1月1日の終日の場合以下のようなデータになるため、
        // { startAt: '2021-01-01 00:00:00', endAt: '2021-01-02 00:00:00'}
        // フォーム上は「2021-01-01〜2021-01-01」として表示するために1日引いている
        // （translateFormValuesToSchedule で再び1日後に戻すことで帳尻を合わせている）
        subDays(schedule.endAt, 1)
      : schedule.endAt
    : addHours(startDate, 1);

  const startTime = format(startDate, "HH:mm");
  const endTime = format(endDate, "HH:mm");

  const numberOfSeconds = schedule?.studySchedule?.numberOfSeconds;
  const studyTime = numberOfSeconds
    ? secondsToTime(numberOfSeconds)
    : { hours: 0, minutes: 0 };

  return {
    id: schedule?.id ?? "",
    title: schedule?.summary ?? "",
    memo: schedule?.description ?? "",
    locale: schedule?.locale ?? "",
    startDate,
    startTime,
    endDate,
    endTime,
    isWholeDay: schedule?.allday ?? false,
    learningMaterial: schedule?.studySchedule?.learningMaterial ?? null,
    studyHours: studyTime.hours,
    studyMinutes: studyTime.minutes,
    studyAmount: schedule?.studySchedule?.amount ?? 0,
    recurrence: schedule?.recurrence
      ? determineOption(schedule.recurrence)
      : null,
  };
};

type ReturnUseScheduleFormState = ReturnType<typeof useScheduleFormState>;
type UseSyncScheduleDurationsProps = Pick<
  ReturnUseScheduleFormState,
  "setFieldValue" | "values" | "isSetMaterial"
>;

const useSyncScheduleDurations = ({
  values,
  setFieldValue,
  isSetMaterial,
}: UseSyncScheduleDurationsProps) => {
  const hasStudySchedule = React.useRef(!!values.learningMaterial);
  const previousTimeRef = React.useRef<{ startDate: Date; endDate: Date }>({
    startDate: values.startDate,
    endDate: values.endDate,
  });

  useEffectAvoidFirst(() => {
    const newStartTime = createDateFromDateAndTimeString(
      values.startDate,
      values.startTime,
    ).getTime();
    const newEndTime = createDateFromDateAndTimeString(
      values.endDate,
      values.endTime,
    ).getTime();
    const newDuration = secondsToTime(
      Math.floor((newEndTime - newStartTime) / 1000),
    );
    const isValidTime = newEndTime > newStartTime;

    if (isSetMaterial && isValidTime) {
      if (!values.isWholeDay) {
        const previousStartTime = previousTimeRef.current.startDate.getTime();
        const previousEndTime = previousTimeRef.current.endDate.getTime();
        const duration = previousEndTime - previousStartTime;
        const studyDuration =
          (values.studyHours * 3600 + values.studyMinutes * 60) * 1000;
        if (hasStudySchedule.current === false || duration === studyDuration) {
          setFieldValue("studyHours", newDuration.hours);
          setFieldValue("studyMinutes", newDuration.minutes);
        }
      }
      hasStudySchedule.current = true;
    }
    if (isValidTime) {
      previousTimeRef.current = {
        startDate: createDateFromDateAndTimeString(
          values.startDate,
          values.startTime,
        ),
        endDate: createDateFromDateAndTimeString(
          values.endDate,
          values.endTime,
        ),
      };
    }
  }, [
    values.startDate,
    values.endDate,
    values.startTime,
    values.endTime,
    values.studyHours,
    values.studyMinutes,
    isSetMaterial,
  ]);
};

// 日付を跨いだ時刻予定は作成できないので、終日予定ではない場合、開始日が変更されたら終了日も同じ日にする
type UseSyncEndDateProps = Pick<
  ReturnUseScheduleFormState,
  "values" | "setFieldValue"
>;
const useSyncEndDate = ({ values, setFieldValue }: UseSyncEndDateProps) => {
  React.useLayoutEffect(() => {
    if (!values.isWholeDay) {
      setFieldValue("endDate", values.startDate);
    }
  }, [values.startDate, values.isWholeDay]);
};
