import AuthorizationStateInterface from "../interfaces/AuthorizationStateInterface";
import AppStateInterface from "../interfaces/AppStateInterface";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import Settings from "../helpers/Settings";

interface Props {
  session: AuthorizationStateInterface;
}

const SetupSentry = (props: Props) => {
  if (!Settings.SENTRY_DSN) {
    return null;
  }

  if (props.session.currentOperator) {
    const currentOperator = props.session.currentOperator;
    Sentry.setUser({
      id: currentOperator?.id,
    });
  }

  return null;
};

const mapStateToProps = (state: AppStateInterface) => ({
  session: state.session,
});

const ErrorTracking = connect(mapStateToProps, {})(SetupSentry);

export default ErrorTracking;
