import { useMutation } from "@tanstack/react-query";
import SectionInterface from "../../../interfaces/SectionInterface";
import { InputFile } from "./";
import ApiClient from "../../../api";

export type UseMutateFileProps = {
  section: SectionInterface;
  files: readonly InputFile[];
  onSuccessUploadSingleFile: (id: string) => void;
  onDoneUploadAllFiles: () => void;
};

export const useMutateFile = ({
  files,
  section,
  onSuccessUploadSingleFile,
  onDoneUploadAllFiles,
}: UseMutateFileProps) => {
  const state = useMutation({
    mutationFn: async () => {
      for (const inputFile of files) {
        const res = await ApiClient.sendFormData(
          `/api/v1/sections/${section.id}/contents/files`,
          "POST",
          { name: inputFile.file.name, file: inputFile.file },
        );
        if (res.ok) {
          onSuccessUploadSingleFile(inputFile.id);
        }
      }
    },
    onSettled: onDoneUploadAllFiles,
  });
  return {
    mutate: state.mutate,
  };
};
