import { useInfiniteQuery } from "@tanstack/react-query";
import { ContentCourseStudent } from "../../../../domains/Student";
import ApiResponse from "../../../../interfaces/ApiResponse";
import ApiClient from "../../../../api";
import { createError, HTTPErrors } from "../../../../errors";
import { getNextPageParam } from "../../../../helpers/ReactQueryHelper";

type UseFetchContentCourseStudentsProps = {
  sectionId: string;
  courseId: string;
};
export const useFetchContentCourseStudents = ({
  sectionId,
  courseId,
}: UseFetchContentCourseStudentsProps) => {
  return useInfiniteQuery<ApiResponse<ContentCourseStudent[]>, HTTPErrors>({
    queryKey: cacheKeyOf(sectionId, courseId),
    queryFn: async ({ pageParam = 1 }) => {
      const response = await ApiClient.get(
        `/api/v1/sections/${sectionId}/contents/courses/${courseId}/students`,
        { query: { page: pageParam } },
      );
      if (response.ok) {
        const json = (await response.json()) as {
          students: ApiResponse<ContentCourseStudent[]>;
        };
        return json.students;
      } else {
        throw await createError(response);
      }
    },
    initialPageParam: 1,
    getNextPageParam,
  });
};

export const cacheKeyOf = (sectionId: string, courseId: string) => [
  `/sections/${sectionId}/content_courses/${courseId}/students`,
];
