import { StudentAnalyticsTable } from "./StudentAnalyticsTable";
import { LectureAnalyticsTable } from "./LectureAnalyticsTable";
import { LectureAttendanceAnalyticsType } from "../SectionsAnalyticsLectureAttendancePage";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";
import { StudentFilterInterface } from "../../../interfaces/FiltersInterface";

type Props = {
  lectureAttendanceAnalyticsType: LectureAttendanceAnalyticsType;
  sectionId: string;
  analyticsFilter: AnalyticsFilter;
  studentFilter: StudentFilterInterface | undefined;
  changeOrder: (order: string | null) => void;
};
export const AnalyticsTable = ({
  lectureAttendanceAnalyticsType,
  sectionId,
  analyticsFilter,
  studentFilter,
  changeOrder,
}: Props) => {
  if (lectureAttendanceAnalyticsType === "student") {
    return (
      <StudentAnalyticsTable
        sectionId={sectionId}
        analyticsFilter={analyticsFilter}
        studentFilter={studentFilter}
        changeOrder={changeOrder}
      />
    );
  } else {
    return (
      <LectureAnalyticsTable
        sectionId={sectionId}
        analyticsFilter={analyticsFilter}
        changeOrder={changeOrder}
      />
    );
  }
};
