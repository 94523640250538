import * as React from "react";
import { connect } from "react-redux";
import FiltersHelper, { toQueryObject } from "../helpers/FiltersHelper";
import {
  AnalyticsTermType,
  AnalyticsType,
} from "../interfaces/AnalyticsTableInterface";
import { initializeFiltersByQuery } from "../actions/common/filters";
import AppStateInterface from "../interfaces/AppStateInterface";
import { DateRangeFilterQueryInterface } from "../interfaces/DateRangeFilterInterface";
import {
  FiltersQueryInterface,
  OrderDirFilterType,
} from "../interfaces/FiltersInterface";
import { StudentAnalyticsTableStateInterface } from "../interfaces/StudentAnalyticsTableInterface";
import StudentInterface from "../interfaces/StudentInterface";
import enhanceStudentsPage from "./enhanceStudentsPage";
import FiltersInterface from "../interfaces/FiltersInterface";
import SectionInterface from "../interfaces/SectionInterface";
import {
  OutletContextProps,
  withRouter,
  WithRouterProps,
} from "../helpers/RouterHelper";
import { Location } from "react-router";
import {
  AuthenticateRouterProps,
  AuthenticatedPageProps,
} from "./enhanceAuthenticatedPage";
import { usePushHistory } from "../hooks/usePushHistory";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    Pick<AuthenticatedPageProps, "studentFilterContext">,
    OutletContextProps,
    WithPushHistoryProps {
  analyticsType: AnalyticsType;
  student: StudentInterface;
  analyticsTable: StudentAnalyticsTableStateInterface;
  filters: FiltersInterface[];
  section: SectionInterface;
  initializeFiltersByQuery: typeof initializeFiltersByQuery;
}

interface DerivedState {
  location: Location;
}

type ComponentType = React.ComponentClass<any> | React.FunctionComponent<any>;

export const createComponent = (Comp: ComponentType) => {
  class StudentsLearningMaterialTagAnalyticsPage extends React.Component<
    Props,
    DerivedState
  > {
    static getDerivedStateFromProps(nextProps: Readonly<Props>) {
      return { location: nextProps.location };
    }

    get filter(): FiltersInterface | undefined {
      return this.props.filters.find(
        (filter: FiltersInterface) =>
          filter.sectionId === this.props.student.section.id,
      );
    }
    constructor(props: Props) {
      super(props);
      this.state = { location: props.location };
      props.setActiveMenu("analytics");
    }

    componentDidUpdate(prevProps: Props) {
      if (prevProps.location !== this.state.location) {
        this.updateAnalyticsDataByQuery(this.state.location.search.toString());
      }
    }

    render() {
      if (!this.props.student || !this.filter) {
        return null;
      }

      return (
        <Comp
          student={this.props.student}
          analyticsTable={this.props.analyticsTable}
          onDateFilterChange={this.handleDateFilterChange}
          onTermChange={this.handleTermChange}
          onOrderChange={this.handleOrderChange}
          analyticsType={this.props.analyticsType}
          navigate={this.props.navigate}
          filter={this.filter}
        />
      );
    }

    private handleDateFilterChange = (
      dateRangeFilter: DateRangeFilterQueryInterface,
    ) => {
      this.pushFilterHistory({ dateRangeFilter });
    };

    private handleTermChange = (term: AnalyticsTermType) => {
      // termが変更されるときはdateの範囲をリセット
      this.pushFilterHistory({ term, dateRangeFilter: undefined });
    };

    private handleOrderChange = (
      order?: string | null,
      orderDir?: OrderDirFilterType | null,
    ) => {
      this.pushFilterHistory({ orderDir, order });
    };

    private pushFilterHistory(obj: FiltersQueryInterface) {
      const params = toQueryObject({ ...this.filter, ...obj });
      this.props.pushHistory(params);
    }

    private updateAnalyticsDataByQuery(query: string) {
      this.props.initializeFiltersByQuery({
        query,
        allTags: FiltersHelper.getAllTags(
          this.props.studentFilterContext.currentFilter,
        ),
        sectionId: this.props.student.section.id,
        force: !!query,
      });
    }
  }
  return StudentsLearningMaterialTagAnalyticsPage;
};

const mapStateToProps =
  (analyticsType: AnalyticsType) => (state: AppStateInterface) => {
    return {
      analyticsType,
      filters: state.filters,
    };
  };
const actions = {
  initializeFiltersByQuery,
};

export const enhanceStudentsLearningMaterialTagAnalyticsPage = (
  analyticsType: AnalyticsType,
  Comp: ComponentType,
) => {
  const pageInfo = {
    title: "アナリティクス",
  };

  const EnhancedStudentsAnalyticsPage = withPushHistory<WithPushHistoryProps>(
    enhanceStudentsPage(createComponent(Comp), pageInfo),
  );

  return withRouter<Props>(
    connect(
      mapStateToProps(analyticsType),
      actions,
    )(EnhancedStudentsAnalyticsPage as any),
  );
};

// NOTE: usePushHistoryを使ってURLパラメータを構築したいのですが、createComponentがクラスコンポーネントなのでusePushHistoryを使えないです。
//       そのため、関数コンポーネントでラップしています。
type WithPushHistoryProps = {
  pushHistory: ReturnType<typeof usePushHistory>["pushHistory"];
};
const withPushHistory = <Props extends WithPushHistoryProps>(
  Component: any,
) => {
  return (props: Partial<Omit<Props, keyof WithPushHistoryProps>>) => {
    const { pushHistory } = usePushHistory();
    return <Component {...props} pushHistory={pushHistory} />;
  };
};
