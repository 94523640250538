import Loader from "../Loader";
import styles from "./styles.scss";

const HeaderNotificationLoading = () => {
  return (
    <div className={styles.root}>
      <Loader loading={true} />
    </div>
  );
};

export default HeaderNotificationLoading;
