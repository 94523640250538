import * as React from "react";
import styles from "./styles.scss";
import {
  FormikProps,
  FormikBag,
  withFormik,
  Form,
  Field,
  ErrorMessage,
} from "formik";
import Label from "../../atoms/Label/index";
import Input from "../../atoms/Input/index";
import Textarea from "../../atoms/Textarea/index";
import { Button } from "@studyplus/boron-ui";
import { validationSchema } from "./validationSchema";
import ErrorText from "../../atoms/ErrorText/index";
import { HandleThunkActionCreator } from "react-redux";
import StaffBoardPostInterface from "../../../interfaces/StaffBoardPostInterface";
import { MainSection } from "../../atoms/MainSection/index";
import { BlockRow } from "../../atoms/BlockRow";

interface ComponentProps {
  staffBoardPost?: StaffBoardPostInterface;
  submitting: boolean;
  operatorName: string;
  onSubmit: HandleThunkActionCreator<
    (values: Values, resetForm: () => void) => void
  >;
}

interface Values {
  title: string;
  name: string;
  comment: string;
}

type Props = ComponentProps & FormikProps<Values>;

class StaffBoardPostForm extends React.Component<Props> {
  render() {
    return (
      <BlockRow marginTop="1.6rem">
        <MainSection>
          <Form>
            <div className={styles.form_row}>
              <div className={styles.title}>
                <Label htmlFor="staff_board_post__title">タイトル</Label>
                <Field
                  id="staff_board_post__title"
                  name="title"
                  component={Input}
                  hasError={this.shouldRenderError("title")}
                  placeholder="タイトルを入力"
                />
                <ErrorMessage
                  name="title"
                  component={ErrorText}
                  className={styles.errors}
                />
              </div>
              <div className={styles.name}>
                <Label htmlFor="staff_board_post__name">スタッフ名</Label>
                <Field
                  id="staff_board_post__name"
                  name="name"
                  component={Input}
                  hasError={this.shouldRenderError("name")}
                  placeholder="スタッフ名を入力"
                />
                <ErrorMessage
                  name="name"
                  component={ErrorText}
                  className={styles.errors}
                />
              </div>
            </div>
            <Label htmlFor="staff_board_post__comment">コメント</Label>
            <Field
              id="staff_board_post__comment"
              name="comment"
              component={Textarea}
              hasError={this.shouldRenderError("comment")}
              rows={4}
            />
            <ErrorMessage
              name="comment"
              component={ErrorText}
              className={styles.errors}
            />
            <div className={styles.btn_group}>
              <Button
                className={styles.submitButton}
                type="submit"
                disabled={!this.submittable()}
                isLoading={this.props.isSubmitting}
              >
                {this.props.staffBoardPost ? "更新" : "送信"}
              </Button>
            </div>
          </Form>
        </MainSection>
      </BlockRow>
    );
  }

  private submittable() {
    return this.props.isValid && !this.props.isSubmitting;
  }

  private shouldRenderError(name: string) {
    const errors = (this.props.errors as any)[name];
    const touched = (this.props.touched as any)[name];
    return errors && touched;
  }
}

const mapPropsToValues = (props: ComponentProps) => {
  if (props.staffBoardPost) {
    return {
      title: props.staffBoardPost.title,
      name: props.operatorName,
      comment: props.staffBoardPost.comment,
    };
  } else {
    return {
      title: "",
      name: props.operatorName,
      comment: "",
    };
  }
};

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<ComponentProps, Values>,
) => {
  formikBag.props.onSubmit(values, formikBag.resetForm);
};

export default withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  isInitialValid: false,
})(StaffBoardPostForm);
