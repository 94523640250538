import classNames from "classnames";
import * as React from "react";
import ReactModal from "react-modal";

import { Body } from "./Body";
import { Header, NoCloseHeader } from "./Header";
import styles from "./styles.scss";

// for accesibility.
// http://reactcommunity.org/react-modal/accessibility/
if (window.Environment?.MODE !== "test") {
  ReactModal.setAppElement("#root");
}

export type Props = ReactModal.Props & {
  children: React.ReactNode;
  className?: string;
};

export const Modal = (props: Props): React.ReactElement => {
  return (
    <React.Fragment>
      <ReactModal
        {...props}
        className={classNames(styles.modal, props.className)}
        overlayClassName={styles.overlay}
      >
        {props.children}
      </ReactModal>
    </React.Fragment>
  );
};

Modal.Header = Header;
Modal.NoCloseHeader = NoCloseHeader;
Modal.Body = Body;
