import {
  Search,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffectAvoidFirst } from "../hooks/useEffectAvoidFirst";

// react-router v6からwithRouterが廃止された
// withRouterから完全に脱却するにはコストがかかるため、自前でwithRouterを用意して対処する
// See https://whereisthemouse.com/how-to-use-withrouter-hoc-in-react-router-v6-with-typescript
export interface WithRouterProps<T = Record<string, string>> {
  location: ReturnType<typeof useLocation>;
  params: T;
  navigate: ReturnType<typeof useNavigate>;
}

export interface OutletContextProps {
  setActiveMenu: (activeMenu: string) => void;
}

export const withRouter = <Props extends WithRouterProps>(Component: any) => {
  return (props: Partial<Omit<Props, keyof WithRouterProps>>) => {
    const { setActiveMenu } = useActiveMenu();
    return (
      <Component
        {...props}
        location={useLocation()}
        params={useParams()}
        navigate={useNavigate()}
        setActiveMenu={setActiveMenu}
      />
    );
  };
};

// react-routerのv6からhistory.listenが使えないための対処
// See: https://github.com/remix-run/react-router/issues/8211#issuecomment-1024143483
export const useOnLocationChange = (
  handleLocationChange: (search: Search) => void,
) => {
  const location = useLocation();

  useEffectAvoidFirst(() => handleLocationChange(location.search), [location]);
};

export const useActiveMenu = () => {
  const context = useOutletContext<any>();

  if (context) {
    return { setActiveMenu: context.setActiveMenu };
  }
  return { setActiveMenu: null };
};
