import * as React from "react";
import { TableColumn } from "../../../components/atoms/Table";
import { StudyTaskStudentsResponse } from "./useFetchStudyTaskStudents";
import { DrillProgressCell } from "../../../components/features/DrillProgressCell";
import { JobHumanLabel } from "../../../components/features/JobHumanLabel";
import { AnalyticsFreePlanNotice } from "../../../components/features/AnalyticsFreePlanNotice";
import classNames from "classnames";

export const StudTaskStudentTableRow = ({
  student,
}: {
  student: StudyTaskStudentsResponse[0];
}) => {
  const isGrayedOut = student.status === "inactive" || !student.isShelved;

  return (
    <tr key={student.id} className={classNames({ "bg-gray-100": isGrayedOut })}>
      <TableColumn className="h-auto whitespace-nowrap py-4 pl-12 font-bold">
        <span className={classNames({ "opacity-50": isGrayedOut })}>
          {student.fullName}
        </span>
      </TableColumn>
      <TableColumn className="h-auto w-5 px-6 py-4">
        <span className={classNames({ "opacity-50": isGrayedOut })}>
          <JobHumanLabel>{student.jobHuman}</JobHumanLabel>
        </span>
      </TableColumn>
      {student.billingPlan === "free" ? (
        <FreePlanCells />
      ) : (
        <ProPlanCells student={student} isGrayedOut={isGrayedOut} />
      )}
    </tr>
  );
};

const FreePlanCells = () => {
  return (
    <>
      <TableColumn className="h-auto w-[12rem] border-0 border-x border-t border-solid border-gray-400 p-0 text-gray-800"></TableColumn>
      <TableColumn className="h-auto w-full px-2 py-0 text-sm text-gray-800">
        <AnalyticsFreePlanNotice
          description="にすると学習状況が閲覧できます"
          iconClassName="h-2 w-2"
        />
      </TableColumn>
    </>
  );
};

const ProPlanCells = ({
  student,
  isGrayedOut,
}: {
  student: StudyTaskStudentsResponse[0];
  isGrayedOut: boolean;
}) => {
  return (
    <>
      <TableColumn className="h-auto w-[12rem] border-0 border-x border-t border-solid border-gray-400 p-0">
        <ProgressRateCell
          progressRate={student.studyResult.progressRate || 0}
          isGrayedOut={isGrayedOut}
        />
      </TableColumn>
      <TableColumn className="h-auto w-full p-0">
        <DrillProgressCell
          progress={{
            notStudiedCount:
              student.studyResult.retentionLevels?.notStudiedCount || 0,
            studyingCount: student.studyResult.retentionLevels?.weakCount || 0,
            establishedCount:
              student.studyResult.retentionLevels?.establishedCount || 0,
            perfectCount:
              student.studyResult.retentionLevels?.perfectCount || 0,
          }}
          duration={student.studyResult.numberOfSeconds}
          className={classNames("h-[5.2rem]", {
            "opacity-50": isGrayedOut,
          })}
        />
      </TableColumn>
    </>
  );
};

const ProgressRateCell = ({
  progressRate,
  isGrayedOut,
}: {
  progressRate: number;
  isGrayedOut: boolean;
}) => {
  const percentage = React.useMemo(
    () => Math.floor(progressRate * 100),
    [progressRate],
  );
  return (
    <div
      className={classNames("flex h-[5.2rem] w-full flex-col justify-between", {
        "opacity-50": isGrayedOut,
      })}
    >
      <div className="flex grow items-center justify-center">{percentage}%</div>
      <div className="flex flex-nowrap items-stretch justify-start">
        <div
          className="flex h-[5px] w-full bg-green-300"
          style={{ width: `${percentage}%` }}
        />
        <div
          className="flex h-[5px] w-full bg-gray-400"
          style={{ width: `${100 - percentage}%` }}
        />
      </div>
    </div>
  );
};
