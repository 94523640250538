// react-routerのv6から<Prompt>が廃止されたための対処
// Navigatorでのページ遷移時に確認ダイアログを表示する
// See: https://github.com/remix-run/react-router/issues/8139
import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export const usePrompt = (message: string, when = true) => {
  const { navigator } = useContext(NavigationContext);
  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};
