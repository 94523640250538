import * as React from "react";
import styles from "./Tooltip.styles.scss";
import {
  Arrow,
  Content,
  Portal,
  Root,
  Trigger,
  Provider,
  TooltipContentProps,
} from "@radix-ui/react-tooltip";

export const TooltipContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  TooltipContentProps
>(({ children, ...props }, ref) => {
  return (
    <Content
      className={`${styles.TooltipContent} rounded-sm bg-black px-4 py-3 text-sm text-white`}
      ref={ref}
      {...props}
    >
      {children}
      <Arrow className="fill-black" />
    </Content>
  );
});

// NOTE: 以下は現状@radix-uiそのままだが、今後挙動を調整したりスタイルを変えたいことがあるかもしれないので
// general/Tooltipのコンポーネントとして公開している
export const TooltipPortal = Portal;
export const Tooltip = Root;
export const TooltipTrigger = Trigger;
export const TooltipProvider = Provider;
