import Settings from "../helpers/Settings";

const BORON_SIGN_IN_URL = `${Settings.BORON_URL}/sign_in`;

export const BoronNavigator = {
  signIn: (): void => {
    const backToUrl = new URL(Settings.ROOT_URL);
    backToUrl.searchParams.append("login", "success");
    const boronUrl = new URL(BORON_SIGN_IN_URL);
    boronUrl.searchParams.append("url", backToUrl.toString());
    window.location.replace(boronUrl.toString());
  },

  // backTo（ログイン後の戻り先）を指定してboronのログインページへ
  signInWithBackTo: (backTo: string): void => {
    const backToUrl = createURLAfterLoggedIn(backTo);
    backToUrl.searchParams.append("login", "success");
    const boronUrl = new URL(BORON_SIGN_IN_URL);
    boronUrl.searchParams.append("url", backToUrl.toString());
    window.location.replace(boronUrl.toString());
  },
};

const createURLAfterLoggedIn = (backTo: string) => {
  try {
    return new URL(backTo, Settings.ROOT_URL);
  } catch {
    return new URL("/", Settings.ROOT_URL);
  }
};
