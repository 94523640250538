import { SectionStudyRecordCommentsStateInterface } from "../interfaces/SectionStudyRecordCommentsStateInterface";
import ApiResponse from "../interfaces/ApiResponse";
import { CommentInterface } from "../interfaces/CommentInterface";

const defaultState: SectionStudyRecordCommentsStateInterface = {
  loading: false,
  hasMore: false,
  data: [],
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
};

export const sectionStudyRecordCommentsState = (
  state: SectionStudyRecordCommentsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_STUDY_RECORD_COMMENT_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_STUDY_RECORD_COMMENT_INITIAL_SUCCESS":
      return {
        ...state,
        loading: false,
        hasMore: hasMore(action.payload.studyRecordComments),
        data: action.payload.studyRecordComments.data,
        meta: action.payload.studyRecordComments.meta,
      };
    case "GET_STUDY_RECORD_COMMENT_SUCCESS":
      return {
        ...state,
        loading: false,
        hasMore: hasMore(action.payload.studyRecordComments),
        data: [...state.data, ...action.payload.studyRecordComments.data],
        meta: action.payload.studyRecordComments.meta,
      };
    case "GET_STUDY_RECORD_COMMENT_ERROR":
    default:
      return state;
  }
};

const hasMore = (payload: ApiResponse<CommentInterface[]>): boolean => {
  const meta = payload.meta;
  return meta.currentPage < meta.totalPages;
};
