import classNames from "classnames";
import * as React from "react";

import AnalyticsHelper, {
  toHeaderDate,
} from "../../../helpers/AnalyticsHelper";
import TimeHelper from "../../../helpers/TimeHelper";
import { AnalyticsType } from "../../../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import {
  StudentAnalyticsRowInterface,
  StudentAnalyticsTableStateInterface,
} from "../../../interfaces/StudentAnalyticsTableInterface";
import StudentInterface from "../../../interfaces/StudentInterface";
import styleVars from "../../../styles/variables.scss";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import SortLabel, { OrderDirectionType } from "../SortLabel";
import styles from "./styles.scss";
import Checkbox from "../../atoms/Checkbox";
import { ColorDot } from "../../../components/general/ColorDot";
import { AnalyticsFreePlanNotice } from "../../features/AnalyticsFreePlanNotice";

interface Props {
  student: StudentInterface;
  analyticsTable: StudentAnalyticsTableStateInterface;
  analyticsType: AnalyticsType;
  onOrderChange: (
    order: string | null,
    orderDir: OrderDirFilterType | null,
  ) => void;
  onHeadCheckboxChange: (e: any) => void;
  onRowCheckboxChange: (row: StudentAnalyticsRowInterface) => (e: any) => void;
  orderDir?: OrderDirFilterType | null;
  order?: string | null;
  uncheckedRows: StudentAnalyticsRowInterface[];
}

export default class StudentAnalyticsTable extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  get values(): number[] {
    const values: number[] = [];

    if (this.props.analyticsTable.data) {
      this.props.analyticsTable.data.learningMaterials.forEach((student) => {
        student.scores.forEach((score) => values.push(score));
      });
    }

    return values;
  }

  get checkableRows(): StudentAnalyticsRowInterface[] {
    if (!this.props.analyticsTable.data) {
      return [];
    }

    return this.props.analyticsTable.data.learningMaterials.filter((row) => {
      return row.scores.every((score) => score === 0);
    });
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <div className={styles.root}>{this.renderTable()}</div>
      </React.Fragment>
    );
  }

  private renderTable() {
    const { analyticsTable } = this.props;

    if (analyticsTable.loading && analyticsTable.meta.currentPage === 0) {
      return <Loader loading={analyticsTable.loading} />;
    }

    if (!analyticsTable.data) {
      return null;
    }

    return (
      <React.Fragment>
        <div>
          <table className={styles.table}>
            <thead>
              <tr className={classNames(styles.headRow, styles.row)}>
                <th>{this.renderHeadCheckbox()}</th>
                <th></th>
                <th className={`${styles.heading}`}>教材名</th>
                <th className={`${styles.heading}`}>
                  <SortLabel
                    label={this.analyticsTypeName(this.props.analyticsType)}
                    active={!this.props.order}
                    orderDirection={
                      (this.props.orderDir as OrderDirectionType) || "desc"
                    }
                    onClick={this.handleSortClick}
                  />
                </th>
                {analyticsTable.data.columns.map((column) =>
                  this.renderColumn(column),
                )}
              </tr>
            </thead>
            {this.renderTableBody()}
          </table>
        </div>
        {analyticsTable.data.learningMaterials.length <= 0 && (
          <p className={styles.notfound}>教材が登録されていません</p>
        )}
        {this.props.student.billingPlan === "free" && (
          <div className="border-0 border-t border-solid border-gray-300 py-8 print:hidden">
            <AnalyticsFreePlanNotice />
          </div>
        )}
        {this.renderLoadMoreButton()}
      </React.Fragment>
    );
  }

  private renderLoadMoreButton(): any {
    const { analyticsTable } = this.props;

    if (
      !analyticsTable ||
      analyticsTable.meta.currentPage >= analyticsTable.meta.totalPages
    ) {
      return null;
    }

    if (analyticsTable.loading && analyticsTable.meta.currentPage >= 1) {
      return <Loader loading={analyticsTable.loading} />;
    }

    return null;
  }

  private handleSortClick = () => {
    const orderDir =
      !this.props.orderDir || this.props.orderDir === "desc" ? "asc" : "desc";
    this.props.onOrderChange(null, orderDir);
  };

  private handleDateColumnClick = (order: string) => () => {
    if (this.orderOf(order) === "desc") {
      this.props.onOrderChange(order, "asc");
    } else {
      this.props.onOrderChange(order, "desc");
    }
  };

  private orderOf(column: string): OrderDirFilterType | null | undefined {
    if (this.props.order === column) {
      return this.props.orderDir;
    } else {
      return null;
    }
  }

  private analyticsTypeName(analyticsType: AnalyticsType): string {
    switch (analyticsType) {
      case "amount":
        return "学習量";
      case "time":
      default:
        return "学習時間";
    }
  }

  private classNameFromDeviation(deviation: number, score: number): string {
    if (score === 0) {
      return styles.level15;
    }
    if (deviation >= 70) {
      return styles.level05;
    } else if (deviation >= 66) {
      return styles.level04;
    } else if (deviation >= 62) {
      return styles.level03;
    } else if (deviation >= 58) {
      return styles.level02;
    } else if (deviation >= 54) {
      return styles.level01;
    } else if (deviation >= 50) {
      return styles.level00;
    } else if (deviation >= 46) {
      return styles.level11;
    } else if (deviation >= 42) {
      return styles.level12;
    } else if (deviation >= 38) {
      return styles.level13;
    } else if (deviation >= 34) {
      return styles.level14;
    } else {
      return styles.level15;
    }
  }

  private formatScore(score: number): string {
    let displayScore: string = score.toString();

    if (this.props.analyticsType === "time") {
      displayScore = TimeHelper.secondsToDisplayTime(score);
    }

    return displayScore;
  }

  private renderScore(
    row: StudentAnalyticsRowInterface,
    score: number,
    i: number,
  ) {
    const className = `${styles.cell} ${this.classNameFromDeviation(
      this.calculateDeviation(score),
      score,
    )}`;

    return (
      <td key={`analytics-scores-${row.rank}-${i}`} className={className}>
        {this.formatScore(score)}
      </td>
    );
  }

  private renderScores(row: StudentAnalyticsRowInterface) {
    return row.scores.map((score: number, i: number) =>
      this.renderScore(row, score, i),
    );
  }

  private renderColorDot(rank: number) {
    return (
      <ColorDot
        style={{
          background: (styleVars as any)[
            `color${AnalyticsHelper.getRankColorNumber(rank).toString()}`
          ],
        }}
      />
    );
  }

  private renderRow(row: StudentAnalyticsRowInterface, i: number) {
    const cellClassName = classNames(styles.cell, {
      [styles.unchecked]: this.isUnchecked(row),
      [styles.disabled]: this.isDisabled(row),
    });

    return (
      <tr className={styles.row} key={`analytics-table-row-${row.rank}-${i}`}>
        <td className={classNames(cellClassName, styles.checkboxCell)}>
          {this.renderRowCheckbox(row)}
        </td>
        <td className={`${cellClassName} ${styles.colorLegend}`}>
          {this.renderColorDot(row.rank)}
        </td>
        <td className={`${cellClassName} ${styles.title}`}>{row.name}</td>
        <td className={cellClassName}>
          <span>{this.formatScore(row.totalScore)}</span>
        </td>
        {this.renderScores(row)}
      </tr>
    );
  }

  private checkedAll(): boolean {
    if (!this.props.analyticsTable.data) {
      return false;
    }

    return this.props.uncheckedRows.length === 0;
  }

  private editingCheckboxes(): boolean {
    if (this.checkedAll()) {
      return false;
    }

    return this.props.uncheckedRows.length < this.checkableRows.length;
  }

  private renderHeadCheckbox() {
    return (
      <React.Fragment>
        <Checkbox
          id="student-analytics-head-checkbox"
          checked={this.checkedAll()}
          onChange={this.props.onHeadCheckboxChange}
          className={classNames(styles.headCheckbox, {
            [styles.editing]: this.editingCheckboxes(),
          })}
        />
        <label
          htmlFor="student-analytics-head-checkbox"
          className={classNames(styles.headCheckboxLabel, {
            [styles.editing]: this.editingCheckboxes(),
          })}
        >
          <Icon name="icon-checkbox-hyphen" />
        </label>
      </React.Fragment>
    );
  }

  private renderRowCheckbox(row: StudentAnalyticsRowInterface) {
    const inputId = `student-analytics-row-${row.rank}`;

    return (
      <React.Fragment>
        <Checkbox
          id={inputId}
          onChange={this.props.onRowCheckboxChange(row)}
          checked={!this.isUnchecked(row)}
          disabled={this.isDisabled(row)}
          className={classNames(styles.checkbox, {
            [styles.disabled]: this.isDisabled(row),
          })}
        />
        <label
          htmlFor={inputId}
          className={classNames(styles.checkboxLabel, {
            [styles.disabled]: this.isDisabled(row),
          })}
        >
          <Icon name="icon-checkbox-hyphen" />
        </label>
      </React.Fragment>
    );
  }

  private isUnchecked(row: StudentAnalyticsRowInterface): boolean {
    return this.props.uncheckedRows.some((_row) => _row.id === row.id);
  }

  private isDisabled(row: StudentAnalyticsRowInterface): boolean {
    return row.scores.every((score) => score === 0);
  }

  private renderTableBody() {
    const { analyticsTable } = this.props;

    if (
      analyticsTable.data &&
      analyticsTable.data.learningMaterials.length > 0
    ) {
      return (
        <tbody className={styles.tbody}>
          {analyticsTable.data.learningMaterials.map((row, i) => {
            return this.renderRow(row, i);
          })}
        </tbody>
      );
    } else {
      return null;
    }
  }

  private renderColumn(column: string) {
    return (
      <th key={`analytics-table-column-${column}`}>
        <SortLabel
          active={this.props.order === column}
          label={toHeaderDate(column, this.props.analyticsTable.data?.term)}
          onClick={this.handleDateColumnClick(column)}
          orderDirection={this.props.orderDir as OrderDirectionType}
        />
      </th>
    );
  }

  private calculateDeviation(score: number) {
    const average = this.calculateAverage();
    const standardDeviation = this.calculateStandardDeviation();
    return ((score - average) / standardDeviation) * 10 + 50;
  }

  private calculateSum() {
    let sum = 0;

    if (this.props.analyticsTable.data) {
      this.props.analyticsTable.data.learningMaterials.forEach((student) => {
        sum += student.scores.reduce(
          (prev: number, current: number) => prev + current,
          0,
        );
      });
    }

    return sum;
  }

  private calculateAverage() {
    return this.calculateSum() / this.values.length;
  }

  private calculateVariance() {
    const average = this.calculateAverage();
    let variance = -1;
    let sum = 0;
    for (const value of this.values) {
      sum += Math.pow(value - average, 2);
    }
    variance = sum / this.values.length;
    return variance;
  }

  private calculateStandardDeviation() {
    const variance = this.calculateVariance();
    return Math.sqrt(variance);
  }
}
