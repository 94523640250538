import * as React from "react";
import { useLearningMaterialTagStudyTimeSummary } from "./useLearningMaterialTagAnalyticsApi";
import {
  useActiveHeatMapRowMapValues,
  ActiveHeatMapRowRecord,
} from "../../../components/features/LearningMaterialTagAnalyticsHeatMapTable/useActiveHeatMapRowMap";
import { useQueryError } from "../../../hooks/http/useQueryError";
import {
  LearningMaterialTagStudyScore,
  LearningMaterialTagStudyTimeSummary,
} from "../../../domains/LearningMaterialTag";
import { MixedCheckValue } from "../../../components/features/AnalyticsTable";
import AnalyticsHelper, {
  determineColorWithRank,
} from "../../../helpers/AnalyticsHelper";
import {
  AnalyticsPieChart,
  PieChartProps,
} from "../../../components/features/AnalyticsChart";
import { AnalyticsTotalHeatMapScore } from "../../../components/features/AnalyticsTotalHeatMapScore";
import Loader from "../../../components/atoms/Loader";
import styles from "./LearningMaterialTagTimeAnalyticsChart.scss";
import TimeHelper from "../../../helpers/TimeHelper";
import { useLearningMaterialFilterContext } from "./useLearningMaterialFilterContext";
import StudentInterface from "../../../interfaces/StudentInterface";
import { AnalyticsTermType } from "../../../interfaces/AnalyticsTableInterface";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";

// Main hooks
type UseLearningMaterialTagTimeAnalyticsChartProps = {
  student: StudentInterface;
  endDate: Date;
  term: AnalyticsTermType;
  order: string;
  orderDir: OrderDirFilterType;
  startDate: Date;
};
const useLearningMaterialTagTimeAnalyticsChart = ({
  endDate,
  ...props
}: UseLearningMaterialTagTimeAnalyticsChartProps) => {
  const {
    items: selectedLearningMaterialTags,
    isInitialized: isDeterminedDefaultOptions,
  } = useLearningMaterialFilterContext();
  const { data, error, isLoading } = useLearningMaterialTagStudyTimeSummary({
    student: props.student,
    startDate: endDate, // API 側は遡って計算するため
    term: props.term,
    order: props.order,
    orderDir: props.orderDir,
    learningMaterialTags: selectedLearningMaterialTags,
    enabledRequest: isDeterminedDefaultOptions,
  });
  useQueryError(error);

  const {
    activeHeatMapRowRecord,
    headerCheckboxChecked,
    noScoreInSpecificTermRecord,
  } = useActiveHeatMapRowMapValues();

  const chartInfo = React.useMemo(() => {
    return data
      ? makeChartInfo({
          learningMaterialTags: data,
          noScoreInSpecificTermRecord,
          headerCheckboxChecked,
          activeHeatMapRowRecord,
        })
      : // ? makeChartInfo(data, activeHeatMapRowRecord, headerCheckboxChecked)
        null;
  }, [data, activeHeatMapRowRecord]);

  const pieChartProps = {
    data: chartInfo?.pieChartData.data ?? null,
    backgroundColor: chartInfo?.pieChartData.backgroundColor ?? null,
    tooltipItemLabels: chartInfo?.pieChartData.tooltipItemLabels ?? null,
    tooltipTitle: `${AnalyticsHelper.formatTermDate(
      props.startDate,
      props.term,
    )} - ${AnalyticsHelper.formatTermDate(endDate, props.term)}`,
  };

  const totalHeatMapScoreProps = {
    seconds: chartInfo?.totalScore ?? 0,
    hidden: !data,
  };

  const loaderProps = {
    loading: isLoading || !isDeterminedDefaultOptions,
  };

  return {
    pieChartProps,
    totalHeatMapScoreProps,
    loaderProps,
  };
};

// Main component
type Props = UseLearningMaterialTagTimeAnalyticsChartProps;
export const LearningMaterialTagTimeAnalyticsChart_ = (props: Props) => {
  const { pieChartProps, totalHeatMapScoreProps, loaderProps } =
    useLearningMaterialTagTimeAnalyticsChart(props);
  return (
    <div className={styles.Summary}>
      {pieChartProps && (
        <>
          <AnalyticsTotalHeatMapScore {...totalHeatMapScoreProps} />
          <div className={styles.PieChartWrapper}>
            <Loader {...loaderProps} />
            {pieChartProps.data && pieChartProps.backgroundColor && (
              <AnalyticsPieChart {...pieChartProps} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export const LearningMaterialTagTimeAnalyticsChart = React.memo(
  LearningMaterialTagTimeAnalyticsChart_,
);

// Helpers
type MakeChartInfoParams = {
  learningMaterialTags: LearningMaterialTagStudyTimeSummary;
  activeHeatMapRowRecord: ActiveHeatMapRowRecord;
  noScoreInSpecificTermRecord: ActiveHeatMapRowRecord;
  headerCheckboxChecked: MixedCheckValue;
};
export const makeChartInfo = ({
  learningMaterialTags,
  activeHeatMapRowRecord,
  noScoreInSpecificTermRecord,
  headerCheckboxChecked,
}: MakeChartInfoParams) => {
  const totalScore = learningMaterialTags.learningMaterialTags.reduce(
    (p, c) => {
      // グラフのAPIとヒートマップのAPIのどちらかのAPIがmockになってる場合などにundefined のケースでも動くようにfalse以外は常に足すようにする。
      if (activeHeatMapRowRecord[c.id] === false) return p;
      return p + c.totalScore;
    },
    0,
  );

  const pieChartData =
    learningMaterialTags.learningMaterialTags.reduce<PieChartProps>(
      (p, c, i) => {
        if (
          noScoreInSpecificTermRecord[c.id] ||
          headerCheckboxChecked === false ||
          (!activeHeatMapRowRecord[c.id] &&
            headerCheckboxChecked === "indeterminate")
        ) {
          return { ...p };
        }
        const percentage = Math.round((c.totalScore / totalScore) * 100);
        return {
          ...p,
          data: [...(p.data ?? []), percentage],
          backgroundColor: [
            ...p.backgroundColor,
            determineColorWithRank(i + 1),
          ],
          tooltipItemLabels: [
            ...p.tooltipItemLabels,
            toToolTipLabel(c, percentage),
          ],
        };
      },
      { data: [], backgroundColor: [], tooltipItemLabels: [] } as PieChartProps,
    );
  return { pieChartData, totalScore };
};

export const toToolTipLabel = (
  studyScore: LearningMaterialTagStudyScore,
  percentage: number,
) =>
  `${studyScore.name}：${TimeHelper.secondsToDisplayTime(
    studyScore.totalScore,
  )}（${percentage}%）`;
