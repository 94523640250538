import * as React from "react";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect } from "react-redux";
import SectionInterface from "../../../interfaces/SectionInterface";
import BackLink from "../../atoms/BackLink/index";
import KarteTemplateForm from "../../molecules/KarteTemplateForm";
import { KarteTemplateFormInterface } from "../../../interfaces/KarteTemplateInterface";
import { createSectionsKarteTemplate } from "../../../actions/sectionsKarteTemplates";
import { SectionKarteTemplateNewStateInterface } from "../../../interfaces/SectionKarteTemplateInterface";
import { KarteTemplateFormType } from "../../molecules/KarteTemplateForm/index";
import { NavigateFunction } from "react-router";

interface Props {
  section: SectionInterface;
  sectionKarteTemplateState: SectionKarteTemplateNewStateInterface;
  createSectionsKarteTemplate: (
    sectionId: string,
    params: KarteTemplateFormInterface,
    navigate: NavigateFunction,
  ) => void;
}

const SectionsSettingsKarteTemplatesNew = (props: Props) => {
  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      <KarteTemplateForm
        type={KarteTemplateFormType.New}
        sectionId={props.section.id}
        karteTemplate={null}
        submitting={props.sectionKarteTemplateState.submitting}
        loading={false}
        onSubmit={handleCreateTemplate(props)}
        apiErrors={props.sectionKarteTemplateState.apiErrors}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppStateInterface, ownProps: any) => ({
  section: ownProps.section,
  sectionKarteTemplateState: state.sectionsSettingsKarteTemplatesNewState,
});

const handleCreateTemplate =
  (props: Props) =>
  (values: KarteTemplateFormInterface, navigate: NavigateFunction) => {
    props.createSectionsKarteTemplate(props.section.id, values, navigate);
  };

const actions = {
  createSectionsKarteTemplate,
};

export default connect(
  mapStateToProps,
  actions,
)(SectionsSettingsKarteTemplatesNew);
