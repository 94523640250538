import KarteInterface from "../interfaces/KarteInterface";
import karte from "./karte";
import karteComments from "./karteComments";

const updateKarteComments = (
  state: KarteInterface[],
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "POST_KARTE_COMMENT_SUCCESS":
    case "DELETE_KARTE_COMMENT_SUCCESS":
      return state.map((karte) => {
        if (karte.id === action.payload.karteId) {
          return {
            ...karte,
            comments: karteComments(karte.comments, action),
          };
        } else {
          return karte;
        }
      });
    default:
      return state;
  }
};

const updateKartePin = (
  state: KarteInterface[],
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "POST_KARTE_PIN_SUCCESS":
      return state.map((karte) => {
        if (karte.id === action.payload.karteId) {
          return {
            ...karte,
            pinned: true,
          };
        } else {
          return karte;
        }
      });
    case "DELETE_KARTE_PIN_SUCCESS":
      return state.map((karte) => {
        if (karte.id === action.payload.karteId) {
          return {
            ...karte,
            pinned: false,
          };
        } else {
          return karte;
        }
      });
    default:
      return state;
  }
};

const kartes = (
  state: KarteInterface[] | null = [],
  action: ReduxActions.Action<any>,
) => {
  if (!state) {
    return state;
  }

  switch (action.type) {
    // カルテ
    case "DELETE_KARTE_SUCCESS":
      return state.filter((karte) => karte.id !== action.payload.karteId);
    case "POST_KARTE_SUCCESS":
      return [action.payload.karte, ...state];

    // カルテコメント
    case "POST_KARTE_COMMENT_SUCCESS":
    case "DELETE_KARTE_COMMENT_SUCCESS":
      return updateKarteComments(state, action);

    // カルテピン
    case "POST_KARTE_PIN_SUCCESS":
    case "DELETE_KARTE_PIN_SUCCESS":
      return updateKartePin(state, action);

    case "OPEN_KARTE_SHARE_MODAL":
    case "CLOSE_KARTE_SHARE_MODAL":
    case "POST_KARTE_SHARE_SENDING":
    case "POST_KARTE_SHARE_SUCCESS":
    case "POST_KARTE_SHARE_ERROR": {
      return state.map((karteState) =>
        karteState.id === action.payload.karteId
          ? karte(karteState, action)
          : karteState,
      );
    }
    default:
      return state;
  }
};

export default kartes;
