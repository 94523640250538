import classNames from "classnames";
import * as React from "react";
import styles from "./styles.scss";

type ColumnWidth = 160 | 240;
interface HeadColumnProps {
  className?: string;
  centering?: boolean;
  noWrap?: boolean;
  sticky?: boolean;
  width?: ColumnWidth;
}

interface ColumnProps {
  className?: string;
  centering?: boolean;
  noWrap?: boolean;
  bold?: boolean;
  align?: "left" | "center" | "right";
  colSpan?: number;
  rowSpan?: number;
  noBorder?: boolean;
}

interface TableProps {
  className?: string;
  headers: Array<{
    text: string;
    centering: boolean;
    width?: ColumnWidth;
    key?: string;
    className?: string;
    children?: React.ReactElement;
  }>;
  rows: JSX.Element[];
  noRowsText?: string;
  headColumnClassName?: string;
  headSticky?: boolean;
  noOutlineBorder?: boolean;
}

export const TableHeadColumn = (
  props: React.PropsWithChildren<
    HeadColumnProps & Pick<React.HTMLProps<HTMLHtmlElement>, "colSpan">
  >,
) => {
  return (
    <th
      className={classNames(
        styles.headColumn,
        props.centering ? styles.center : null,
        props.noWrap ? styles.noWrap : null,
        props.sticky ? styles.sticky : null,
        {
          [styles.width160]: props.width === 160,
          [styles.width240]: props.width === 240,
        },
        props.className,
      )}
      colSpan={props.colSpan}
    >
      {props.children}
    </th>
  );
};

export const TableColumn = (
  props: React.PropsWithChildren<ColumnProps>,
): React.ReactElement<React.PropsWithChildren<ColumnProps>> => {
  return (
    <td
      className={classNames(
        styles.column,
        props.centering ? styles.center : null,
        props.noWrap ? styles.noWrap : null,
        props.bold ? [styles.bold] : null,
        {
          [styles.right]: props.align === "right",
          [styles.left]: props.align === "left",
          [styles.center]: props.align === "center",
          [styles.noBorder]: props.noBorder,
        },
        props.className,
      )}
      colSpan={props.colSpan}
      rowSpan={props.rowSpan}
    >
      {props.children}
    </td>
  );
};

const Table = (props: TableProps): React.ReactElement<TableProps> | null => {
  return (
    <table
      className={classNames(styles.table, props.className, {
        [styles.noOutlineBorder]: props.noOutlineBorder,
        [styles.firstRow_borderTop_none]: props.headSticky,
      })}
    >
      <thead>
        <tr>
          {props.headers.map((header) => {
            return (
              <TableHeadColumn
                centering={header.centering}
                key={header.key || `thead-${header.text}`}
                sticky={props.headSticky}
                className={classNames(
                  props.headColumnClassName,
                  header.className,
                )}
                width={header.width}
              >
                {header.text}
                {header.children}
              </TableHeadColumn>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {props.rows.length > 0 ? (
          props.rows
        ) : (
          <NoRows
            noRowsText={props.noRowsText}
            colSpan={props.headers.length}
          />
        )}
      </tbody>
    </table>
  );
};

type NoRowsProps = {
  noRowsText?: string;
  colSpan: number;
};
const NoRows = ({ noRowsText, colSpan }: NoRowsProps) => {
  return (
    <tr>
      <TableColumn colSpan={colSpan} centering className={styles.noRowsColumn}>
        {noRowsText}
      </TableColumn>
    </tr>
  );
};

// ヘッダーの中にボタンなどカスタムなヘッダーの要求を考慮に入れたもの
type FlexibleTableProps = Omit<
  TableProps,
  "noRowsText" | "headColumnClassName" | "rows" | "headers"
> &
  React.AriaAttributes;
export const FlexibleTable = ({
  children,
  className,
  noOutlineBorder,
  headSticky,
  ...props
}: React.PropsWithChildren<FlexibleTableProps>) => {
  return (
    <table
      className={classNames(styles.table, className, {
        [styles.noOutlineBorder]: noOutlineBorder,
        [styles.firstRow_borderTop_none]: headSticky,
      })}
      {...props}
    >
      {children}
    </table>
  );
};

// 外枠のボーダー等を親のコンテナのレイアウトに任せるテーブル
type DependantTableProps = Omit<FlexibleTableProps, "noOutlineBorder">;
export const DependantTable = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<DependantTableProps>) => {
  return (
    <FlexibleTable
      noOutlineBorder
      className={classNames(styles.DependantTable, className)}
      {...props}
    >
      {children}
    </FlexibleTable>
  );
};

export const HorizontalScrollTable = (props: TableProps) => {
  return (
    <div className={styles.horizontalScroll}>
      <Table {...props} noOutlineBorder />
    </div>
  );
};

export const EvenColorTr = React.forwardRef<
  HTMLTableRowElement,
  React.PropsWithChildren<unknown>
>(({ children }, ref) => {
  return (
    <tr ref={ref} className={styles.evenColorTr}>
      {children}
    </tr>
  );
});

export default Table;
