import * as React from "react";
import styles from "./styles.scss";

interface Props extends React.PropsWithChildren<unknown> {
  title: string;
  onClickControl?: (e: any) => void;
  controlLabel?: string;
}

const HeaderNotificationsHeading = (props: Props) => {
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{props.title}</h3>
      {props.onClickControl && props.controlLabel && (
        <a className={styles.link} onClick={props.onClickControl}>
          {props.controlLabel}
        </a>
      )}
    </div>
  );
};

export default HeaderNotificationsHeading;
