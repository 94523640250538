import * as React from "react";
import {
  useAccordion,
  AccordionProvider,
  UseAccordionProps,
  AccordionItemProvider,
  useAccordionItem,
  useAccordionButton,
  useAccordionPanel,
} from "./useAccordion";
import styles from "./styles.scss";
import classnames from "classnames";
import Icon from "../../atoms/Icon";

type DivProps = React.HTMLProps<HTMLDivElement>;
type AccordionProps = UseAccordionProps & {
  children: React.ReactNode; // forwardRef の型が参照する children が Element で都合が良くないので上書き
  theme?: "gray";
} & DivProps;

export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(
  (props, ref) => {
    const { mode, children, theme = "gray", className, ...htmlProps } = props;

    const mergedClassName = classnames(styles.accordion, className, {
      [styles["theme--gray"]]: theme === "gray",
    });

    const value = useAccordion({ mode });
    return (
      <AccordionProvider value={value}>
        <div {...htmlProps} className={mergedClassName} ref={ref}>
          {children}
        </div>
      </AccordionProvider>
    );
  },
);

type AccordionItemProps = {
  children?: React.ReactNode;
} & DivProps;
export const AccordionItem = React.forwardRef<
  HTMLDivElement,
  AccordionItemProps
>((props, ref) => {
  const { children, ...htmlProps } = props;
  const value = useAccordionItem();
  return (
    <AccordionItemProvider value={value}>
      <div className={styles.item} ref={ref} {...htmlProps}>
        {children}
      </div>
    </AccordionItemProvider>
  );
});

type AccordionButtonProps = {
  children?: React.ReactNode;
} & React.HTMLProps<HTMLButtonElement>;
export const AccordionButton = React.forwardRef<
  HTMLButtonElement,
  AccordionButtonProps
>(({ children, ...htmlProps }, ref) => {
  const { isOpen, ...buttonProps } = useAccordionButton();

  return (
    <button
      {...htmlProps}
      {...buttonProps}
      className={styles.button}
      ref={ref}
      type="button"
    >
      <div>{children}</div>
      <div className={styles.button__icon}>
        <Icon name={isOpen ? "icon-arrow-up" : "icon-arrow-down"} />
      </div>
    </button>
  );
});

type AccordionPanelProps = { children?: React.ReactNode };
export const AccordionPanel = React.forwardRef<
  HTMLDivElement,
  AccordionPanelProps
>((props, ref) => {
  const { isOpen } = useAccordionPanel();
  return isOpen ? (
    <div className={styles.panel} ref={ref}>
      {props.children}
    </div>
  ) : null;
});
