import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import { ZoomMeetingSettingInterface } from "../../../interfaces/OperatorProfileInterface";

export enum ActionTypes {
  // アカウント情報更新
  UPDATE_OPERATOR_EMAIL_REQUEST = "settings/profile/UPDATE_OPERATOR_EMAIL_REQUEST",
  UPDATE_OPERATOR_EMAIL_SUCCESS = "settings/profile/UPDATE_OPERATOR_EMAIL_SUCCESS",
  UPDATE_OPERATOR_EMAIL_ERROR = "settings/profile/UPDATE_OPERATOR_EMAIL_ERROR",
  UPDATE_OPERATOR_NAME_REQUEST = "settings/profile/UPDATE_OPERATOR_NAME_REQUEST",
  UPDATE_OPERATOR_NAME_SUCCESS = "settings/profile/UPDATE_OPERATOR_NAME_SUCCESS",
  UPDATE_OPERATOR_NAME_ERROR = "settings/profile/UPDATE_OPERATOR_NAME_ERROR",
  UPDATE_OPERATOR_PASSWORD_REQUEST = "settings/profile/UPDATE_OPERATOR_PASSWORD_REQUEST",
  UPDATE_OPERATOR_PASSWORD_SUCCESS = "settings/profile/UPDATE_OPERATOR_PASSWORD_SUCCESS",
  UPDATE_OPERATOR_PASSWORD_ERROR = "settings/profile/UPDATE_OPERATOR_PASSWORD_ERROR",

  // アカウント情報取得
  GET_OPERATOR_PROFILE_REQUEST = "settings/profile/GET_OPERATOR_PROFILE_REQUEST",
  GET_OPERATOR_PROFILE_SUCCESS = "settings/profile/GET_OPERATOR_PROFILE_SUCCESS",

  // Zoom連携
  CONNECT_ZOOM_REQUEST = "settings/profile/CONNECT_ZOOM_REQUEST",
  CONNECT_ZOOM_ERROR = "settings/profile/CONNECT_ZOOM_ERROR",
  DISCONNECT_ZOOM_REQUEST = "settings/profile/DISCONNECT_ZOOM_REQUEST",
  DISCONNECT_ZOOM_SUCCESS = "settings/profile/DISCONNECT_ZOOM_SUCCESS",
  DISCONNECT_ZOOM_ERROR = "settings/profile/DISCONNECT_ZOOM_ERROR",
  UPDATE_ZOOM_MEETING_SETTING_REQUEST = "settings/profile/UPDATE_ZOOM_MEETING_SETTING_REQUEST",
  UPDATE_ZOOM_MEETING_SETTING_ERROR = "settings/profile/UPDATE_ZOOM_MEETING_SETTING_ERROR",
  UPDATE_ZOOM_MEETING_SETTING_SUCCESS = "settings/profile/UPDATE_ZOOM_MEETING_SETTING_SUCCESS",

  // GoogleClassroom連携
  CONNECT_GOOGLE_CLASSROOM_REQUEST = "settings/profile/CONNECT_GOOGLE_CLASSROOM_REQUEST",
  CONNECT_GOOGLE_CLASSROOM_ERROR = "settings/profile/CONNECT_GOOGLE_CLASSROOM_ERROR",
  DISCONNECT_GOOGLE_CLASSROOM_REQUEST = "settings/profile/DISCONNECT_GOOGLE_CLASSROOM_REQUEST",
  DISCONNECT_GOOGLE_CLASSROOM_SUCCESS = "settings/profile/DISCONNECT_GOOGLE_CLASSROOM_SUCCESS",
  DISCONNECT_GOOGLE_CLASSROOM_ERROR = "settings/profile/DISCONNECT_GOOGLE_CLASSROOM_ERROR",
}

export type Action =
  | UpdateOperatorEmailRequest
  | UpdateOperatorEmailSuccess
  | UpdateOperatorEmailError
  | UpdateOperatorNameRequest
  | UpdateOperatorNameSuccess
  | UpdateOperatorNameError
  | UpdateOperatorPasswordRequest
  | UpdateOperatorPasswordSuccess
  | UpdateOperatorPasswordError
  | GetOperatorProfileRequest
  | GetOperatorProfileSuccess
  | ConnectZoomRequest
  | ConnectZoomError
  | DisconnectZoomRequest
  | DisconnectZoomSuccess
  | DisconnectZoomError
  | UpdateZoomMeetingSettingRequest
  | UpdateZoomMeetingSettingError
  | UpdateZoomMeetingSettingSuccess
  | ConnectGoogleClassroomRequest
  | ConnectGoogleClassroomError
  | DisconnectGoogleClassroomRequest
  | DisconnectGoogleClassroomSuccess
  | DisconnectGoogleClassroomError;

export interface UpdateOperatorEmailRequest {
  type: ActionTypes.UPDATE_OPERATOR_EMAIL_REQUEST;
}

export interface UpdateOperatorEmailSuccess {
  type: ActionTypes.UPDATE_OPERATOR_EMAIL_SUCCESS;
}

export interface UpdateOperatorEmailError {
  type: ActionTypes.UPDATE_OPERATOR_EMAIL_ERROR;
  payload?: ApiErrorResponseInterface;
}

export interface UpdateOperatorNameRequest {
  type: ActionTypes.UPDATE_OPERATOR_NAME_REQUEST;
}

export interface UpdateOperatorNameSuccess {
  type: ActionTypes.UPDATE_OPERATOR_NAME_SUCCESS;
}

export interface UpdateOperatorNameError {
  type: ActionTypes.UPDATE_OPERATOR_NAME_ERROR;
  payload?: ApiErrorResponseInterface;
}

export interface UpdateOperatorPasswordRequest {
  type: ActionTypes.UPDATE_OPERATOR_PASSWORD_REQUEST;
}

export interface UpdateOperatorPasswordSuccess {
  type: ActionTypes.UPDATE_OPERATOR_PASSWORD_SUCCESS;
}

export interface UpdateOperatorPasswordError {
  type: ActionTypes.UPDATE_OPERATOR_PASSWORD_ERROR;
  payload?: ApiErrorResponseInterface;
}

export interface GetOperatorProfileRequest {
  type: ActionTypes.GET_OPERATOR_PROFILE_REQUEST;
}
export interface GetOperatorProfileSuccess {
  type: ActionTypes.GET_OPERATOR_PROFILE_SUCCESS;
  payload: OperatorProfileInterface;
}

// Zoom連携
export interface ConnectZoomRequest {
  type: ActionTypes.CONNECT_ZOOM_REQUEST;
}
export interface ConnectZoomError {
  type: ActionTypes.CONNECT_ZOOM_ERROR;
}
export interface DisconnectZoomRequest {
  type: ActionTypes.DISCONNECT_ZOOM_REQUEST;
}
export interface DisconnectZoomSuccess {
  type: ActionTypes.DISCONNECT_ZOOM_SUCCESS;
}
export interface DisconnectZoomError {
  type: ActionTypes.DISCONNECT_ZOOM_ERROR;
}
export interface UpdateZoomMeetingSettingRequest {
  type: ActionTypes.UPDATE_ZOOM_MEETING_SETTING_REQUEST;
}
export interface UpdateZoomMeetingSettingError {
  type: ActionTypes.UPDATE_ZOOM_MEETING_SETTING_ERROR;
  payload?: ApiErrorResponseInterface;
}
export interface UpdateZoomMeetingSettingSuccess {
  type: ActionTypes.UPDATE_ZOOM_MEETING_SETTING_SUCCESS;
  payload: ZoomMeetingSettingInterface;
}
// GoogleClassroom連携
export interface ConnectGoogleClassroomRequest {
  type: ActionTypes.CONNECT_GOOGLE_CLASSROOM_REQUEST;
}
export interface ConnectGoogleClassroomError {
  type: ActionTypes.CONNECT_GOOGLE_CLASSROOM_ERROR;
}
export interface DisconnectGoogleClassroomRequest {
  type: ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_REQUEST;
}
export interface DisconnectGoogleClassroomSuccess {
  type: ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_SUCCESS;
}
export interface DisconnectGoogleClassroomError {
  type: ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_ERROR;
}
