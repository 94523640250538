import styles from "./LectureAttendanceAnalyticsTable.styles.scss";
import {
  HeatMapTd,
  NoTextTd,
  Table,
  Td,
  Th,
  TitleTd,
  Tr,
} from "../../components/features/AnalyticsTable";
import SortLabel from "../../components/molecules/SortLabel";
import StudentInterface from "../../interfaces/StudentInterface";
import {
  useAnalyticsFilter,
  AnalyticsFilter,
} from "../../hooks/filters/useAnalyticsFilter";
import { useFetchStudentLectureAttendanceAnalytics } from "./useFetchStudentLectureAttendanceAnalytics";
import Loader from "../../components/atoms/Loader/index";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import {
  calculateDeviation,
  toHeaderDate,
} from "../../helpers/AnalyticsHelper";
import { AttendanceRate } from "./AttendanceRate";
import { EmptyResultText } from "./EmptyResultText";
import { Link } from "react-router-dom";

type UseLectureAttendanceAnalyticsTableProps = {
  student: StudentInterface;
  analyticsFilter: AnalyticsFilter;
  changeOrder: ReturnType<typeof useAnalyticsFilter>["changeOrder"];
};
type Props = UseLectureAttendanceAnalyticsTableProps;

export const LectureAttendanceAnalyticsTable = ({
  student,
  analyticsFilter,
  changeOrder,
}: Props) => {
  const {
    data,
    error,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchStudentLectureAttendanceAnalytics({
    student,
    enabledRequest: analyticsFilter.enabledRequest,
    analyticsFilter,
  });

  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage]);

  if (data === undefined) {
    return <Loader loading />;
  }

  const analyticsTableColumns = data.pages[0].data.columns;
  const analyticsTableRows = data.pages.flatMap(
    (page) => page.data.lectureAttendances,
  );
  const allScores =
    data?.pages.flatMap((page) =>
      page.data.lectureAttendances.flatMap(
        (lectureAttendance) => lectureAttendance.scores,
      ),
    ) ?? [];

  if (analyticsTableRows?.length === 0)
    return (
      <EmptyResultText>該当する講座が見つかりませんでした</EmptyResultText>
    );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <thead>
          <Tr>
            <Th></Th>
            <Th>講座名</Th>
            <Th>
              <SortLabel
                label="出席率"
                orderDirection={analyticsFilter?.orderDir ?? "desc"}
                active={analyticsFilter?.order === null}
                onClick={() => {
                  changeOrder(null);
                }}
              />
            </Th>
            {analyticsTableColumns.map((column) => {
              return (
                <Th key={`section-header-${column}`}>
                  <SortLabel
                    label={toHeaderDate(column, analyticsFilter?.term)}
                    active={analyticsFilter?.order === column}
                    orderDirection={analyticsFilter?.orderDir || "desc"}
                    onClick={() => changeOrder(column)}
                  />
                </Th>
              );
            })}
          </Tr>
        </thead>
        <tbody>
          {analyticsTableRows.map((lectureAttendance) => {
            return (
              <tr key={`lecture-row-${lectureAttendance.lecture.id}`}>
                <NoTextTd>{lectureAttendance.rank}</NoTextTd>
                <TitleTd>
                  <div className={styles.analyticsTableTitle}>
                    <Link
                      to={{
                        pathname: `/sections/${lectureAttendance.sectionId}/analytics/lectures/${lectureAttendance.lecture.id}/students`,
                      }}
                      className={styles.lectureName}
                    >
                      {lectureAttendance.lecture.name}
                    </Link>
                  </div>
                </TitleTd>
                <Td>
                  <AttendanceRate rate={lectureAttendance.totalScore} />
                </Td>
                {analyticsTableColumns.map((_, columnIndex) => {
                  return (
                    <HeatMapTd
                      key={`student-column-${lectureAttendance.lecture.id}-${columnIndex}`}
                      score={lectureAttendance.scores[columnIndex]}
                      deviation={calculateDeviation(
                        allScores,
                        lectureAttendance.scores[columnIndex],
                      )}
                    >
                      <StudentLectureAttendanceCount
                        attendanceRate={lectureAttendance.scores[columnIndex]}
                        lecturePresenceCount={
                          lectureAttendance.lectureAttendancePresences[
                            columnIndex
                          ]
                        }
                        lectureTotalCount={
                          lectureAttendance.lectureAttendanceTotals[columnIndex]
                        }
                      />
                    </HeatMapTd>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const StudentLectureAttendanceCount = ({
  attendanceRate,
  lecturePresenceCount,
  lectureTotalCount,
}: {
  attendanceRate: number;
  lecturePresenceCount: number | undefined;
  lectureTotalCount: number | undefined;
}) => {
  return lectureTotalCount && lectureTotalCount > 0 ? (
    <>
      <div>
        <AttendanceRate rate={attendanceRate} />
      </div>
      <div>
        （{lecturePresenceCount}/{lectureTotalCount}件）
      </div>
    </>
  ) : (
    <>0件</>
  );
};
