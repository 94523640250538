import * as React from "react";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { BlockRow } from "../../../components/atoms/BlockRow/index";
import { Flex } from "../../../components/atoms/Flex";
import BackLink from "../../../components/atoms/BackLink";
import { MainSection, PageHeader } from "../../../components/atoms/MainSection";
import styles from "./styles.scss";
import { useSectionsSettingsContentCourseDetail } from "./useSectionsSettingsContentCourseDetail";
import { Units, UnitsAccordion } from "./Units";
import { Link } from "react-router-dom";
import TimeHelper from "../../../helpers/TimeHelper";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import Loader from "../../../components/atoms/Loader";
import {
  ContentCourse,
  DEFAULT_THUMBNAIL_URL,
} from "../../../domains/ContentCourse";
import { DescriptionText } from "../../../components/atoms/DescriptionText";
import { OutletContextProps } from "../../../helpers/RouterHelper";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
  };

const SectionsSettingsContentCourseDetail_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const { course, isLoading } = useSectionsSettingsContentCourseDetail({
    section: props.section,
  });

  return (
    <div className={styles.root}>
      <BlockRow marginTop="2rem">
        <BackLink
          path={`/sections/${props.section.id}/settings/content_courses`}
        >
          戻る
        </BackLink>
      </BlockRow>
      <div className={styles.mainSection}>
        <MainSection>
          <Loader loading={isLoading} />
          <Course course={course} section={props.section} />
        </MainSection>
      </div>
    </div>
  );
};

type CourseProps = {
  course?: ContentCourse;
  section: SectionInterface;
};

const useCourseOwner = (props: CourseProps) => {
  return {
    isOwner: props.course?.ownerSection.id === props.section.id,
    ownerName: props.course?.ownerSection.fullName,
  };
};

const Course = (props: CourseProps) => {
  const { course } = props;
  const ownerInfo = useCourseOwner(props);
  if (!course) return null;
  return (
    <Flex marginTop="0">
      <div className={styles.thumbnail}>
        <img src={course.thumbnailUrl ?? DEFAULT_THUMBNAIL_URL} />
      </div>
      <div className={styles.detail}>
        <div>
          <PageHeader>{course.name}</PageHeader>
        </div>
        <div className={styles.subInfo}>
          <div>ユニット数：{course.units.length}</div>
          <div>更新日：{TimeHelper.fullFormat(course.updatedAt)}</div>
          <ShareCode hidden={!ownerInfo.isOwner}>
            コースコード：{course.id}
          </ShareCode>
          <div>
            配信期間：
            {course.startAt && course.endAt
              ? `${TimeHelper.fullFormat(
                  course.startAt,
                )} ~ ${TimeHelper.fullFormat(course.endAt)}`
              : "未設定"}
          </div>
          <OwnerSection hidden={ownerInfo.isOwner}>
            {ownerInfo.ownerName}
          </OwnerSection>
        </div>
        <div className={styles.description}>
          <DescriptionText>{course.description}</DescriptionText>
        </div>
        <Units hidden={course.units.length === 0}>
          <div className={styles.unitTitle}>
            <ItemTitle>ユニット</ItemTitle>
          </div>
          <div className={styles.units}>
            <UnitsAccordion units={course.units} />
          </div>
        </Units>
        <div className={styles.targetTitle}>
          <ItemTitle>配信対象生徒</ItemTitle>
        </div>
        <div className={styles.target}>
          <div className={styles.target__current}>
            <div>配信対象タグ：</div>
            <div>
              <CoursesTag>{course.tag?.name}</CoursesTag>
            </div>
          </div>
          <div>
            <Link
              to={`/sections/${props.section.id}/settings/content_courses/${course.id}/students`}
              className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm buiButtonRound`}
            >
              配信対象タグの設定
            </Link>
          </div>
        </div>
        <Flex marginTop="2.4rem" justifyContent="flex-end">
          <EditButton
            hidden={!ownerInfo.isOwner}
            path={`/sections/${props.section.id}/settings/content_courses/${course.id}/edit`}
          />
        </Flex>
      </div>
    </Flex>
  );
};

const ItemTitle: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <div className={styles.itemTitle}>{children}</div>;

type Hideable = {
  hidden: boolean;
};
type EditButtonProps = Hideable & {
  path: string;
};
const EditButton = ({ hidden, path }: EditButtonProps) =>
  hidden ? null : (
    <Link
      to={path}
      className={`buiButtonBase buiButtonVariantFilled ${styles.editButton}`}
    >
      編集
    </Link>
  );

const ShareCode: React.FC<React.PropsWithChildren<Hideable>> = ({
  hidden,
  children,
}) => (hidden ? null : <div>{children}</div>);

const OwnerSection: React.FC<React.PropsWithChildren<Hideable>> = ({
  hidden,
  children,
}) =>
  hidden ? null : (
    <div className={styles.subInfo__owner}>
      <div className={styles.boxText}>共有コース</div>
      <div>{children}</div>
    </div>
  );

const CoursesTag: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const text = children ? children : "未設定";
  return <div className={styles.current__name}>{text}</div>;
};

const pageInfo = {
  title: "コース詳細",
};

export const SectionsSettingsContentCourseDetail = enhanceSectionsSettingsPage(
  SectionsSettingsContentCourseDetail_,
  pageInfo,
  ["content_courses"],
);
