import React from "react";

import * as Sentry from "@sentry/react";
import UnknownErrorPage from "../../../containers/pages/UnknownErrorPage";
import { useDispatch } from "react-redux";

const FallbackComponent = () => {
  return <UnknownErrorPage hideButton={true} />;
};

export const FSErrorBoundary = ({ children }: React.PropsWithChildren) => {
  const dispatch = useDispatch();
  const onError = (error: Error) => {
    if (
      new RegExp("Objects are not valid as a React child").test(error.message)
    ) {
      // redux-persist内のstateが不正な形式になっていると発生することがあるエラー
      // 解消するにはクリアする他ないため、reduxのstateをすべてクリアする
      dispatch({ type: "redux/CLEAR_STATE" });
    }
  };

  return (
    // NOTE: Sentryへのエラー送信は<Sentry.ErrorBoundary>でやってくれる
    <Sentry.ErrorBoundary fallback={FallbackComponent} onError={onError}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
