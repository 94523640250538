import * as React from "react";
import styles from "./styles.scss";
import Icon from "../Icon";

type HelpLinkProps = React.PropsWithChildren<{
  to: string;
  className?: string;
}>;
export const HelpLink = ({ to, className = "", children }: HelpLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      className={styles.root}
    >
      <Icon name="icon-help" className={`${styles.icon} ${className}`} />
      {children}
    </a>
  );
};
