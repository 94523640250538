import classNames from "classnames";
import * as React from "react";

import styles from "./styles.scss";

interface Props extends React.PropsWithChildren<unknown> {
  position?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  className?: string;
}

const List = (props: Props) => {
  return (
    <span
      className={classNames(styles.list, props.className)}
      style={{ ...props.position }}
    >
      {props.children}
    </span>
  );
};

export default List;
