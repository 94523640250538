import ApiResponse from "../../../interfaces/ApiResponse";
import SectionAnnounceMessagesStateInterface from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import announceMessages from "./announceMessages";
import { defaultAnnounceMessagesState } from "./announceMessages";
import { AnyAction } from "redux";
import { defaultAnnounceDestinationTagOptions } from "./announceDestinationTagOptions";
import announceDestinationTagOptions from "./announceDestinationTagOptions";
import AnnounceDestinationTagInterface from "../../../interfaces/AnnounceDestinationTagInterface";
import { POST_ZOOM_MEETING_MESSAGE_SUCCESS } from "../../../actions/pages/sectionAnnounceMessages/types";

const sectionAnnounceMessages = (
  state: SectionAnnounceMessagesStateInterface = defaultState,
  action: AnyAction,
): SectionAnnounceMessagesStateInterface => {
  const { payload } = action;

  switch (action.type) {
    // Loading
    case "GET_INITIAL_SECTION_ANNOUNCE_DESTINATION_TAGS_REQUEST_SENDING":
      return {
        ...defaultState,
        announceDestinationTagOptions: announceDestinationTagOptions(
          state.announceDestinationTagOptions,
          action,
        ),
        loading: true,
      };
    case "GET_SECTION_ANNOUNCE_DESTINATION_TAGS_REQUEST_SENDING":
      return { ...state, loading: true };

    // 一斉送信メッセージの宛先タグ一覧取得
    case "GET_SECTION_ANNOUNCE_DESTINATION_TAGS_RESPONSE_RECEIVED":
    case "GET_INITIAL_SECTION_ANNOUNCE_DESTINATION_TAGS_RESPONSE_RECEIVED":
      return appendSuccessResponse(state, payload.allTags);

    case "GET_SECTION_ANNOUNCE_DESTINATION_TAGS_RESPONSE_ERROR":
    case "GET_INITIAL_SECTION_ANNOUNCE_DESTINATION_TAGS_RESPONSE_ERROR":
      return {
        ...state,
        loading: false,
      };

    case "ANNOUNCE_DESTINATION_TAG_OPENED":
      return {
        ...state,
        showSubmittedMessage: false,
        openedTagId: action.payload.id,
      };

    case "ANNOUNCE_DESTINATION_TAG_CLOSED":
      return {
        ...state,
        openedTagId: null,
      };

    // 一斉メッセージ送信
    case "POST_ANNOUNCE_MESSAGE_SUCCESS":
    case "POST_ANNOUNCE_MESSAGE_FILE_SUCCESS":
    case POST_ZOOM_MEETING_MESSAGE_SUCCESS:
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: updateLatestMessage(state.data, action),
        messages: announceMessages(state.messages, action),
        announceDestinationTagOptions: announceDestinationTagOptions(
          state.announceDestinationTagOptions,
          action,
        ),
      };

    case "OPEN_ANNOUNCE_MESSAGE_DESTINATION_MODAL":
      return { ...state, isModalOpen: true };

    case "CLOSE_ANNOUNCE_MESSAGE_DESTINATION_MODAL":
      return { ...state, isModalOpen: false };

    case "OPEN_ANNOUNCE_MESSAGE_RECIPIENTS_MODAL":
      return {
        ...state,
        isRecipientsModalOpen: true,
        selectedMessageId: action.payload.messageId,
      };

    case "CLOSE_ANNOUNCE_MESSAGE_RECIPIENTS_MODAL":
      return {
        ...state,
        isRecipientsModalOpen: false,
        selectedMessageId: null,
        recipients: defaultRecipients,
      };

    case "GET_ANNOUNCE_MESSAGE_RECIPIENTS":
      return { ...state, recipients: { ...state.recipients, loading: true } };

    case "GET_ANNOUNCE_MESSAGE_RECIPIENTS_SUCCESS":
      return {
        ...state,
        recipients: {
          data: state.recipients.data.concat(action.payload.data),
          meta: action.payload.meta,
          loading: false,
          hasMore:
            action.payload.meta.currentPage != action.payload.meta.totalPages,
        },
      };

    case "GET_ANNOUNCE_MESSAGE_RECIPIENTS_ERROR":
      return { ...state, recipients: { ...state.recipients, loading: false } };

    default:
      return {
        ...state,
        messages: announceMessages(state.messages, action),
        announceDestinationTagOptions: announceDestinationTagOptions(
          state.announceDestinationTagOptions,
          action,
        ),
      };
  }
};

const defaultRecipients = {
  loading: false,
  hasMore: true,
  meta: {
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
  },
  data: [],
};

const defaultState: SectionAnnounceMessagesStateInterface = {
  loading: true,
  hasMore: true,
  showSubmittedMessage: false,
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
  data: null,
  openedTagId: null,
  messages: defaultAnnounceMessagesState,
  announceDestinationTagOptions: defaultAnnounceDestinationTagOptions,
  isModalOpen: false,
  isRecipientsModalOpen: false,
  recipients: defaultRecipients,
  selectedMessageId: null,
};

const concatData = (prevData: any[] | null, data: any[]): any[] => {
  if (prevData === null) {
    return data;
  } else {
    return prevData.concat(data);
  }
};

const appendSuccessResponse = (
  state: SectionAnnounceMessagesStateInterface,
  response: ApiResponse<any[]>,
) => {
  const { meta, data } = response;

  return {
    ...state,
    hasMore: response.meta.currentPage < response.meta.totalPages,
    loading: false,
    data: concatData(state.data, data),
    meta,
  };
};

const updateLatestMessage = (
  state: AnnounceDestinationTagInterface[],
  action: AnyAction,
) => {
  return state.map((tag) => {
    if (tag.id === action.payload.tagId) {
      return {
        ...tag,
        latestMessage: action.payload.message,
      };
    } else {
      return tag;
    }
  });
};

export default sectionAnnounceMessages;
