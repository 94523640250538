import { Link } from "react-router-dom";
import StudentInterface from "../../../interfaces/StudentInterface";
import Icon from "../../atoms/Icon";
import styles from "./GuardianMessageHeader.scss";

interface Props {
  student: StudentInterface;
  showCloseIcon: boolean;
  onClose?: (e: any) => void;
}

const GuardianMessageHeader = (props: Props) => {
  const { student, showCloseIcon } = props;

  return (
    <div className={styles.root} data-testid="guardian_message_header">
      {showCloseIcon ? (
        <Icon
          name="icon-arrow-close"
          className={styles.close}
          onClick={props.onClose}
        />
      ) : null}
      <div className={styles.title}>
        <Link to={`/students/${student.id}`} className={styles.name}>
          {student.fullName}
        </Link>
        （保護者）とのメッセージ
      </div>
    </div>
  );
};

export default GuardianMessageHeader;
