import { useQuery } from "@tanstack/react-query";
import { createError } from "../../errors";
import ApiClient from "../../api";
import { GuardianMessageWithStudentInterface } from "../../interfaces/GuardianMessageInterface";
import ApiResponse from "../../interfaces/ApiResponse";

type Props = {
  sectionId: string;
  enabled: boolean;
};
type ResponseBody = {
  guardianMessages: ApiResponse<GuardianMessageWithStudentInterface[]>;
};
export const useFetchSectionGuardianMessages = ({
  sectionId,
  enabled,
}: Props) => {
  const path = `/api/v1/sections/${sectionId}/guardian_messages`;
  return useQuery<ResponseBody, Error>({
    queryKey: [path],
    queryFn: async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    enabled,
  });
};
