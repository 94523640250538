import {
  CursorAvailability,
  LectureStudentAnalytics,
  LectureStudentData,
} from "../domains/LectureStudentAnalytics";

export const fromJson = (
  json: Record<string, any>,
): LectureStudentAnalytics => {
  return {
    columns: json.columns.map((obj: Record<string, string>) => ({
      lectureSessionId: obj.lectureSessionId,
      allday: obj.allday,
      startAt: new Date(obj.startAt),
      endAt: new Date(obj.endAt),
    })),
    students: json.students as ReadonlyArray<LectureStudentData>,
    cursor: json.cursor as CursorAvailability,
  };
};
