import * as React from "react";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import SectionsSettingsGoogleClassroom from "./SectionsSettingsGoogleClassroom";
import { OutletContextProps } from "../../../helpers/RouterHelper";

interface Props extends OutletContextProps {
  section: SectionInterface;
  operator: OperatorInterface;
}

const SectionsSettingsGoogleClassroomLinkPage: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.setActiveMenu("applications");
  }, []);
  return (
    <SectionsSettingsGoogleClassroom
      section={props.section}
      operator={props.operator}
    />
  );
};

const pageInfo = {
  title: "アプリ連携",
};

export default enhanceSectionsSettingsPage(
  SectionsSettingsGoogleClassroomLinkPage,
  pageInfo,
  ["third_party_application:settings"],
);
