import classNames from "classnames";
import { Link } from "react-router-dom";

import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";

interface Props {
  opened: boolean;
  section: SectionInterface;
}

const renderLogoImage = (props: Props) => {
  return (
    <Link to={`/sections/${props.section.id}`}>
      <div className={styles.shallowImage}>
        <div className={styles.logoIcon}>
          <img src="/images/logo.svg" />
        </div>
      </div>
      <div className={styles.wideImage}>
        <div className={styles.logoIcon}>
          <img src="/images/logo.svg" />
        </div>
        <div className={styles.logoTxt}>
          <img src="/images/logo_txt.svg" />
        </div>
      </div>
    </Link>
  );
};

const NaviMenuLogo = (props: Props) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.opened]: props.opened,
      })}
    >
      {renderLogoImage(props)}
    </div>
  );
};

export default NaviMenuLogo;
