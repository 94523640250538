import { createAction } from "redux-actions";
import StudentTag from "../../../domains/StudentTag";
import { Dispatch, AnyAction, ActionCreator } from "redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import TagEntity from "../../../entities/TagEntity";
import * as queryString from "query-string";
import StudentApi from "../../../api-clients/StudentApi";
import { loadInitialAnnounceMessages } from "./messageList";
import { showErrorFlashMessage } from "../../flashMessage";
import { HTTP_ERROR_MESSAGE } from "../../../reducers/index";
import { loadInitialDestinationTagOptionRecipients } from "./selector";
import { buildMainResourceApiErrorAction } from "../../common/errors";

// 受信者一覧の取得
export const loadInitialTaggedStudents =
  (
    destinationTag: StudentTag,
    actionCreators: {
      sending: ActionCreator<AnyAction>;
      success: ActionCreator<AnyAction>;
      error: ActionCreator<AnyAction>;
    },
  ) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    dispatch(actionCreators.sending({ tagId: destinationTag.id }));

    const tagEntity = new TagEntity(destinationTag);
    const queryParams = tagEntity.toQueryParams();
    const query = queryString.stringify(
      {
        ...queryParams,
        status: ["active"],
      },
      { arrayFormat: "bracket" },
    );

    const { currentSection } = getState();

    if (!currentSection.data) {
      dispatch(actionCreators.error());
      return;
    }

    StudentApi.getStudents(currentSection.data.id, { query: `?${query}` })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(
              actionCreators.success({
                tagId: destinationTag.id,
                students: json.students,
              }),
            );
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(actionCreators.error());
        }
      })
      .catch(() => {
        dispatch(actionCreators.error());
      });
  };

export const loadMoreTaggedStudents =
  (
    destinationTag: StudentTag,
    page: number,
    actionCreators: {
      sending: ActionCreator<AnyAction>;
      success: ActionCreator<AnyAction>;
      error: ActionCreator<AnyAction>;
    },
  ) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    dispatch(actionCreators.sending({ tagId: destinationTag.id }));
    const tagEntity = new TagEntity(destinationTag);
    const queryParams = tagEntity.toQueryParams();
    const query = queryString.stringify(
      {
        ...queryParams,
        status: ["active"],
        page,
      },
      { arrayFormat: "bracket" },
    );
    const { currentSection } = getState();

    if (!currentSection.data) {
      dispatch(actionCreators.error());
      return;
    }

    StudentApi.getStudents(currentSection.data.id, { query: `?${query}` })
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(
              actionCreators.success({
                tagId: destinationTag.id,
                students: json.students,
              }),
            );
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(actionCreators.error());
        }
      })
      .catch(() => {
        dispatch(actionCreators.error());
        showErrorFlashMessage(HTTP_ERROR_MESSAGE);
      });
  };

// 宛先タグ一覧取得成功したときにdispatchするアクション
export const initializeAnnounceDestinationTagOptionsSuccess = createAction(
  "INITIALIZE_ANNOUNCE_DESTINATION_TAG_OPTIONS_SUCCESS",
);

// 宛先タグを開く
const announceDestinationTagOpened = createAction(
  "ANNOUNCE_DESTINATION_TAG_OPENED",
);
export const openAnnounceDestinationTag =
  (sectionId: string, destinationTag: StudentTag) =>
  (dispatch: Dispatch<any>) => {
    dispatch(announceDestinationTagOpened(destinationTag));
    dispatch(loadInitialAnnounceMessages(sectionId, destinationTag));
    dispatch(loadInitialDestinationTagOptionRecipients(destinationTag));
  };

// 宛先タグを閉じる
const announceDestinationTagClosed = createAction(
  "ANNOUNCE_DESTINATION_TAG_CLOSED",
);
export const closeAnnounceDestinationTag = () => (dispatch: Dispatch<any>) => {
  dispatch(announceDestinationTagClosed());
};

// 宛先タグモーダルを開く
export const openAnnounceDestinationModal = createAction(
  "OPEN_ANNOUNCE_MESSAGE_DESTINATION_MODAL",
);
export const closeAnnounceDestinationModal = createAction(
  "CLOSE_ANNOUNCE_MESSAGE_DESTINATION_MODAL",
);
