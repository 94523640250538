import * as React from "react";
import styles from "./styles.scss";
import Icon from "../Icon";

const handleClick = (): void => {
  window.print();
};

const PrintButton: React.FC<Record<string, unknown>> = () => {
  return (
    <button className={styles.root} onClick={handleClick}>
      <Icon name="icon-printer" className={styles.icon} />
    </button>
  );
};

export default PrintButton;
