import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { SectionStaffBoardPostStateInterface } from "../../../interfaces/SectionStaffBoardPostInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import Loader from "../../atoms/Loader";
import StaffBoardPostForm from "../../molecules/StaffBoardPostForm";
import { StaffBoardPostFormInterface } from "../../../interfaces/StaffBoardPostInterface";
import BackLink from "../../atoms/BackLink/index";
import { updateStaffBoardPost } from "../../../actions/pages/SectionStaffBoardPostPage/actions";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";

interface Props extends WithRouterProps {
  section: SectionInterface;
  currentOperator: OperatorInterface | undefined;
  staffBoardPostState: SectionStaffBoardPostStateInterface;
  updatePost: HandleThunkActionCreator<typeof updateStaffBoardPost>;
}

const SectionStaffBoardPostEdit = (props: Props) => {
  if (!props.currentOperator) return null;
  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      {renderForm(props)}
    </React.Fragment>
  );
};

const renderForm = (props: Props) => {
  if (!props.staffBoardPostState.data || !props.currentOperator) return null;

  if (props.staffBoardPostState.loading) {
    return <Loader loading={true} />;
  }

  return (
    <StaffBoardPostForm
      staffBoardPost={props.staffBoardPostState.data}
      submitting={props.staffBoardPostState.submitting}
      operatorName={props.currentOperator.fullName}
      onSubmit={handleSubmit(props)}
    />
  );
};

const handleSubmit =
  (props: Props) => (values: StaffBoardPostFormInterface) => {
    if (!props.staffBoardPostState.data) return;
    props.updatePost(
      props.section.id,
      props.staffBoardPostState.data.id,
      values,
      props.navigate,
    );
  };

const actions = {
  updatePost: updateStaffBoardPost,
};

const ConnectedComponent = connect(null, actions)(SectionStaffBoardPostEdit);
export default withRouter<Props>(ConnectedComponent);
