import * as React from "react";
import { TableColumn } from "../../../components/atoms/Table";
import {
  AttendanceUpdaterTypes,
  LectureAttendanceForSection,
  LocationStatusTypes,
  reasonOptionLabels,
} from "../../../domains/LectureAttendance";
import { ChecboxColumnGridText, CheckboxColumGrid } from "./CheckboxColumnGrid";
import {
  LectureTypeLabel,
  LectureTypes,
} from "../../../components/features/Schedule/LectureTypeLabel";
import { Flex } from "../../../components/atoms/Flex";
import TimerHelper from "../../../helpers/TimeHelper";
import styles from "./AttendeesTableRow.scss";
import LinkButton from "../../../components/atoms/LinkButton";
import { Text } from "../../../components/general/Text";
import Icon from "../../../components/atoms/Icon";
import classNames from "classnames";
import { MixedCheckbox } from "../../../components/general/MixedCheckbox";
import { useBatchActionDispatchContext } from "./AttendBatchActionContext";
import { useModalDispatchContext } from "./UpdateModalActionContext";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { Link } from "react-router-dom";
import { AttendanceStatus } from "../../../components/features/AttendanceStatus";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
} from "../../../components/general/DropdownMenu/DropdownMenu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { useNavigate } from "react-router";

type AttendeesTableRowProps = {
  lectureAttendanceForSection: LectureAttendanceForSection;
  selected?: boolean;
  handleLectureTransferReadModalOpen: (
    lectureAttendanceForSection: LectureAttendanceForSection,
  ) => void;
};
export const AttendeesTableRow_ = ({
  selected,
  lectureAttendanceForSection,
  handleLectureTransferReadModalOpen,
}: AttendeesTableRowProps) => {
  const {
    lectureType,
    studentName,
    studentId,
    updateTypeText,
    updateTimeText,
    absenseText,
    staffShareNote,
    studentJob,
    isCanceled,
    hasLectureTransfer,
    openAbsentModal,
    openAttendModal,
    openStaffShareNoteModal,
    toggleTarget,
    navigateToLectureTransferPage,
  } = useAttendeesTableRow({ lectureAttendanceForSection });

  const { id } = lectureAttendanceForSection;
  const url = `/students/${studentId}/calendar`;
  return (
    <tr>
      <TableColumn>
        <CheckboxColumGrid>
          <MixedCheckbox
            id={id}
            disabled={isCanceled}
            value={selected ?? false}
            onChanged={() => toggleTarget(lectureAttendanceForSection)}
          />
          <ChecboxColumnGridText>
            <Name lectureType={lectureType}>
              {!lectureAttendanceForSection.fromOtherSection ? (
                <Link to={url} className={styles.studentName}>
                  {studentName}
                </Link>
              ) : (
                studentName
              )}
            </Name>
          </ChecboxColumnGridText>
        </CheckboxColumGrid>
      </TableColumn>
      <TableColumn centering>{studentJob}</TableColumn>
      <TableColumn centering>
        <AttendanceStatus
          attendanceStatus={lectureAttendanceForSection.attendanceStatus}
          transferredAttendanceStatus={
            lectureAttendanceForSection.transferredLectureAttendanceStatus
          }
        />
      </TableColumn>
      <TableColumn centering>{updateTypeText}</TableColumn>
      <TableColumn centering>{updateTimeText}</TableColumn>
      <TableColumn centering>{absenseText}</TableColumn>
      <TableColumn centering>
        <LastColumnGrid>
          <StaffShareNote onClick={openStaffShareNoteModal}>
            {staffShareNote}
          </StaffShareNote>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Icon name="icon-ellipsis" />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onSelect={openAttendModal}>
                  <Flex gap="0.8" alignItems="center" marginTop="0">
                    <Icon name="icon-students-plus" />
                    出席に変更
                  </Flex>
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={openAbsentModal}>
                  <Flex gap="0.8" alignItems="center" marginTop="0">
                    <Icon name="icon-students-stop" />
                    欠席に変更
                  </Flex>
                </DropdownMenuItem>
                {hasLectureTransfer ? (
                  <DropdownMenuItem
                    onSelect={() =>
                      handleLectureTransferReadModalOpen(
                        lectureAttendanceForSection,
                      )
                    }
                  >
                    <Flex gap="0.8" alignItems="center" marginTop="0">
                      <Icon name="icon-replacement" />
                      振替情報を見る
                    </Flex>
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem onSelect={navigateToLectureTransferPage}>
                    <Flex gap="0.8" alignItems="center" marginTop="0">
                      <Icon name="icon-replacement" />
                      振替受講に変更
                    </Flex>
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        </LastColumnGrid>
      </TableColumn>
    </tr>
  );
};

export const AttendeesTableRow =
  React.memo<AttendeesTableRowProps>(AttendeesTableRow_);

// main hooks
type UseAttendeesTableRowProps = {
  lectureAttendanceForSection: LectureAttendanceForSection;
};
const useAttendeesTableRow = ({
  lectureAttendanceForSection,
}: UseAttendeesTableRowProps) => {
  const {
    openAbsentModal: originalOpenAbsentModal,
    openAttendModal: orginalOpenAttendModal,
    openStaffShareNoteModal: originalOpenStaffShareNoteModal,
  } = useModalDispatchContext();
  const { toggleTarget } = useBatchActionDispatchContext();

  const isCanceled = lectureAttendanceForSection.attendanceStatus === "cancel";

  const { showErrorMessage } = useFlashMessage();
  const navigate = useNavigate();

  const onNotCanceledAttendance = (callback: () => void) => {
    if (isCanceled) {
      showErrorMessage("休講の講義は出欠席の登録ができません");
      return;
    }
    callback();
  };

  const openAbsentModal = () => {
    onNotCanceledAttendance(() =>
      originalOpenAbsentModal(lectureAttendanceForSection),
    );
  };

  const openAttendModal = () => {
    onNotCanceledAttendance(() =>
      orginalOpenAttendModal(lectureAttendanceForSection),
    );
  };

  const openStaffShareNoteModal = () => {
    onNotCanceledAttendance(() =>
      originalOpenStaffShareNoteModal(lectureAttendanceForSection),
    );
  };

  const navigateToLectureTransferPage = () => {
    navigate(
      `/sections/${lectureAttendanceForSection.student.section.id}/lecture_transfers/new?lecture_attendance_id=${lectureAttendanceForSection.id}`,
    );
  };

  const lectureType = determineLectureType(lectureAttendanceForSection);
  const studentSectionNameText = [
    "fromOtherSection",
    "transferredFromOtherSection",
  ].includes(lectureType)
    ? `（${lectureAttendanceForSection.student.section.sectionName}）`
    : "";
  const studentName = `${lectureAttendanceForSection.student.fullName}${studentSectionNameText}`;

  const updateTimeText = lectureAttendanceForSection.attendanceStatusUpdatedAt
    ? TimerHelper.fullFormat(
        lectureAttendanceForSection.attendanceStatusUpdatedAt,
      )
    : null;

  // 振替先または振替元が存在するか
  const hasLectureTransfer =
    lectureAttendanceForSection.hasTransferOriginLectureAttendance ||
    lectureAttendanceForSection.hasTransferredLectureAttendance;

  return {
    lectureType,
    studentName,
    studentId: lectureAttendanceForSection.student.id,
    updateTypeText: determineUpdateText(
      lectureAttendanceForSection.attendanceUpdater,
      lectureAttendanceForSection.locationStatus,
    ),
    updateTimeText,
    absenseText: determineAbsenseText(
      lectureAttendanceForSection.absenceReason,
      lectureAttendanceForSection.absenceDescription,
    ),
    staffShareNote: lectureAttendanceForSection.staffShareNote,
    studentJob: lectureAttendanceForSection.student.jobHuman,
    isCanceled,
    hasLectureTransfer,
    openAbsentModal,
    openAttendModal,
    openStaffShareNoteModal,
    toggleTarget,
    navigateToLectureTransferPage,
  };
};

// helper functions
const determineLectureType = (
  lectureAttendance: LectureAttendanceForSection,
): LectureTypes => {
  if (
    lectureAttendance.fromOtherSection &&
    lectureAttendance.hasTransferOriginLectureAttendance
  ) {
    return "transferredFromOtherSection";
  }
  if (lectureAttendance.fromOtherSection) {
    return "fromOtherSection";
  }
  if (lectureAttendance.hasTransferOriginLectureAttendance) {
    return "transferred";
  }
  return "normal";
};

const attedanceUpdaterLabels: Record<
  keyof typeof AttendanceUpdaterTypes,
  string
> = {
  student: "生徒入力",
  operator: "スタッフ入力",
  auto: "自動入力",
};

const locationStatusLabels: Record<keyof typeof LocationStatusTypes, string> = {
  inside: "（位置範囲内）",
  outside: "（位置範囲外）",
  indeterminable: "（位置判定不可）",
  unable_to_locate: "（位置取得不可）",
};

const determineUpdateText = (
  attendanceUpdater: LectureAttendanceForSection["attendanceUpdater"],
  locationStatus: LectureAttendanceForSection["locationStatus"],
) => {
  if (!attendanceUpdater) {
    return null;
  }
  if (attendanceUpdater !== AttendanceUpdaterTypes.student) {
    return attedanceUpdaterLabels[attendanceUpdater];
  }
  const locationStatusText = locationStatus
    ? locationStatusLabels[locationStatus]
    : "";
  return `${attedanceUpdaterLabels[attendanceUpdater]}${locationStatusText}`;
};

const determineAbsenseText = (
  absenseReason: LectureAttendanceForSection["absenceReason"],
  absenseDescription: LectureAttendanceForSection["absenceDescription"],
) => {
  if (!absenseReason) {
    return null;
  }
  const descriptionText = absenseDescription ? `：${absenseDescription}` : "";
  return `${reasonOptionLabels[absenseReason]}${descriptionText}`;
};

// helper components
const LastColumnGrid = ({ children }: React.PropsWithChildren<unknown>) => (
  <div
    className={classNames(styles.LastColumnGrid, styles["u-margin-canceler"])}
  >
    {children}
  </div>
);

type NameProps = { lectureType: LectureTypes };
const Name = ({
  children,
  lectureType,
}: React.PropsWithChildren<NameProps>) => {
  return (
    <Flex marginTop="0" gap="1.6" alignItems="center">
      {lectureType !== "normal" && (
        <div>
          <LectureTypeLabel lectureType={lectureType} />
        </div>
      )}
      {children}
    </Flex>
  );
};

type StaffShareNoteProps = { onClick: () => void };
const StaffShareNote = ({
  children,
  onClick,
}: React.PropsWithChildren<StaffShareNoteProps>) => {
  return children ? (
    <Flex marginTop="0" alignItems="center" gap="0.4">
      <span className={styles.StaffShareText}>{children}</span>
      <div
        className={classNames(
          styles.StaffShareText__IconWrapper,
          styles["u-margin-canceler"],
        )}
      >
        <LinkButton onClick={onClick}>
          <Icon name="icon-edit" />
        </LinkButton>
      </div>
    </Flex>
  ) : (
    <LinkButton onClick={onClick}>
      <Flex marginTop="0" alignItems="center" gap="0.4" groupHover>
        <Text color="gray-darken-1" hoverColor="primary">
          メモを記入
        </Text>
        <div className={styles.StaffShareText__IconWrapper}>
          <Icon name="icon-edit" color="gray-darken-1" hoverColor="primary" />
        </div>
      </Flex>
    </LinkButton>
  );
};
