import * as React from "react";
import {
  FlexibleTable as Table,
  TableHeadColumn,
  TableColumn,
  EvenColorTr,
} from "../../components/atoms/Table";
import { ColorDot } from "../../components/general/ColorDot";
import classnames from "classnames";
import { ScreenReaderOnly } from "../../components/general/ScreenReaderOnly";
import Icon from "../../components/atoms/Icon";
import styles from "./SearchScheduleList.scss";
import TimeHelper, { toTimeString } from "../../helpers/TimeHelper";
import { Text, Props as TextProps } from "../../components/general/Text";
import LinkButton from "../../components/atoms/LinkButton";
import { Colors } from "../../helpers/styles/Colors";
import { useOnScrollContentBottom } from "../../hooks/useOnScrollContentBottom";
import SectionInterface from "../../interfaces/SectionInterface";
import { useDateFilter } from "../../hooks/filters/useDateFilter";
import { useFetchSectionSchedules } from "./useFetchSectionSchedules";
import { useQueryError } from "../../hooks/http/useQueryError";
import Loader from "../../components/atoms/Loader";
import { Schedule } from "../../domains/Schedule";
import { LectureSession } from "../../domains/LectureSession";
import {
  ModalDetailHeader,
  EditButton,
  TrashButton,
  CloseButton,
} from "../../components/general/ModalDetailHeaderButtons";
import {
  useModalState,
  SectionScheduleDetailView as DetailView,
  Modal as SectionScheduleModal,
  ModalProvider,
  ModalHeader,
  ModalBody,
} from "./modal";
import { ScheduleDetailModalBox } from "../../components/features/Schedule/DetailModalParts";
import { Flex } from "../../components/atoms/Flex";
import { SectionScheduleFormFields } from "./SectionScheduleForm";
import AppStateInterface from "../../interfaces/AppStateInterface";
import { useSelector } from "react-redux";
import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";
import { useDeleteSectionSchedule } from "./useDeleteSectionSchedule";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { FieldLayoutMultiRows } from "../../components/features/Schedule/FormParts";

type Props = {
  selectedStudentIds: ReadonlyArray<string>;
  selectedClassroomIds: ReadonlyArray<string>;
  selectedTeacherIds: ReadonlyArray<string>;
  section: SectionInterface;
};

export const useScheduleListTable = ({
  section,
  selectedStudentIds,
  selectedClassroomIds,
  selectedTeacherIds,
}: Props) => {
  const { dateFilter } = useDateFilter({ skipInitialzeFiltersByQuery: false });
  const targetDate = React.useMemo(
    () => dateFilter ?? new Date(),
    [dateFilter],
  );

  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    data: sectionSchedules,
    error,
  } = useFetchSectionSchedules({
    selectedStudentIds,
    selectedClassroomIds,
    selectedTeacherIds,
    section,
    date: targetDate,
  });
  useQueryError(error);

  const refScrollDiv = React.useRef<HTMLDivElement | null>(null);
  const { refLastElement } = useOnScrollContentBottom({
    rootContentWithScroll: refScrollDiv.current,
    onScrollContentBottom() {
      if (hasNextPage && !isLoading) {
        fetchNextPage();
      }
    },
  });

  const modalState = useModalState();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const operatorPrivileges = useSelector<
    AppStateInterface,
    OperatorPrivilegesStateInterface
  >((state) => state.operatorPrivileges);

  const { mutate } = useDeleteSectionSchedule({
    section,
    onSuccess() {
      showSuccessMessage("予定を削除しました");
      modalState.close();
    },
    onError() {
      showErrorMessage("予定の削除に失敗しました");
    },
  });

  const onClickDelete = () => {
    if (
      modalState.selectedSectionSchedule &&
      window.confirm("この予定を本当に削除しますか？")
    ) {
      mutate(modalState.selectedSectionSchedule.id);
    }
  };

  return {
    sectionSchedules,
    refScrollDiv,
    refLastElement,
    modalState,
    openModal: modalState.open,
    isLoading: isLoading || isFetchingNextPage,
    operatorPrivileges,
    onClickDelete,
  };
};
const SearchScheduleList_ = (props: Props) => {
  const {
    openModal,
    modalState,
    sectionSchedules,
    refScrollDiv,
    refLastElement,
    isLoading,
    operatorPrivileges,
    onClickDelete,
  } = useScheduleListTable(props);

  return (
    <div className={styles.wrapper} ref={refScrollDiv}>
      <Table noOutlineBorder headSticky className={styles.resultTable}>
        <thead>
          <tr>
            <TableHeadColumn
              centering
              sticky
              className={styles.scheduleTimeWidth}
            >
              <ScreenReaderOnly>予定時刻</ScreenReaderOnly>
            </TableHeadColumn>
            <TableHeadColumn centering sticky className={styles.summaryWidth}>
              件名
            </TableHeadColumn>
            <TableHeadColumn centering sticky className={styles.targetNumWidth}>
              対象者
            </TableHeadColumn>
            <TableHeadColumn
              centering
              sticky
              className={styles.attendeesNumWidth}
            >
              出席 / 欠席
            </TableHeadColumn>
            <TableHeadColumn centering sticky className={styles.clasroomWidth}>
              教室
            </TableHeadColumn>
            <TableHeadColumn centering sticky className={styles.teacherWidth}>
              講師
            </TableHeadColumn>
            <TableHeadColumn centering sticky className={styles.lectureWidth}>
              講座 / 学習計画
            </TableHeadColumn>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <TableColumn colSpan={7}>
                <Loader loading />
              </TableColumn>
            </tr>
          )}
          {sectionSchedules?.map((sectionSchedule) => {
            const canceled = sectionSchedule.lectureSession?.canceled ?? false;
            return (
              <EvenColorTr
                key={`section-schedule-${sectionSchedule.id}`}
                ref={refLastElement}
              >
                <TableColumn noBorder>
                  <TextScheduleTime
                    allday={sectionSchedule.schedule.allday}
                    startAt={sectionSchedule.schedule.startAt}
                    endAt={sectionSchedule.schedule.endAt}
                  />
                </TableColumn>
                <TableColumn noBorder className={styles.colorDotColumn}>
                  <Flex marginTop="0" alignItems="center">
                    <ColorDotSectionSchedule
                      canceled={canceled}
                      studySchedule={sectionSchedule.schedule.studySchedule}
                    />
                    <div className={styles.SummaryLinkWrapper}>
                      <SummaryLink
                        onClick={() => {
                          openModal(sectionSchedule);
                        }}
                        canceled={canceled}
                        summary={sectionSchedule.schedule.summary}
                      />
                    </div>
                  </Flex>
                </TableColumn>
                <TableColumn noBorder>
                  <TextNumOfStudents
                    canceled={canceled}
                    totalCount={sectionSchedule.studentCount}
                  />
                </TableColumn>
                <TableColumn noBorder>
                  <TextNumOfAttendees
                    canceled={canceled}
                    absenceCount={
                      sectionSchedule.lectureSession?.absenceCount || 0
                    }
                    attendanceCount={
                      sectionSchedule.lectureSession?.attendanceCount || 0
                    }
                    totalCount={sectionSchedule.lectureSession?.totalCount || 0}
                  />
                </TableColumn>
                <TableColumn noBorder>
                  <CancelableText align="center" canceled={canceled}>
                    {sectionSchedule.classroom?.name}
                  </CancelableText>
                </TableColumn>
                <TableColumn noBorder>
                  <CancelableText align="center" canceled={canceled}>
                    {sectionSchedule.teacher?.fullName}
                  </CancelableText>
                </TableColumn>
                <TableColumn noBorder>
                  <LectureStudyPlan
                    canceled={canceled}
                    studySchedule={sectionSchedule.schedule.studySchedule}
                    lecture={sectionSchedule.lectureSession?.lecture ?? null}
                  />
                </TableColumn>
              </EvenColorTr>
            );
          })}
        </tbody>
      </Table>
      <ModalProvider value={modalState}>
        <SectionScheduleModal className={styles.modal}>
          {modalState.isOpen && modalState.isDetail && (
            <ScheduleDetailModalBox>
              <ModalDetailHeader>
                {operatorPrivileges.data !== null &&
                  operatorPrivileges.data.role !== "limited_member" && (
                    <>
                      <EditButton onClick={modalState.edit} />
                      <TrashButton onClick={onClickDelete} />
                    </>
                  )}
                <CloseButton onClick={modalState.close} />
              </ModalDetailHeader>

              <DetailView
                section={props.section}
                sectionSchedule={modalState.selectedSectionSchedule}
              />
            </ScheduleDetailModalBox>
          )}
          {modalState.isOpen &&
            (modalState.isEditing || modalState.isStudentTag) && (
              <>
                {!modalState.isStudentTag ? (
                  <ModalHeader>予定の編集</ModalHeader>
                ) : (
                  <ModalHeader>
                    <span onClick={modalState.edit} className={styles.backBtn}>
                      <FieldLayoutMultiRows>
                        <Icon
                          name="icon-arrow-close"
                          className={styles.backArrowIcon}
                        />
                        <p>戻る</p>
                      </FieldLayoutMultiRows>
                    </span>
                  </ModalHeader>
                )}
                <ModalBody>
                  <div className={styles.sectionScheduleFormWrapper}>
                    <SectionScheduleFormFields
                      sectionSchedule={modalState.selectedSectionSchedule}
                    />
                  </div>
                </ModalBody>
              </>
            )}
        </SectionScheduleModal>
      </ModalProvider>
    </div>
  );
};

export const SearchScheduleList = React.memo(SearchScheduleList_);

type CanceledField = Pick<LectureSession, "canceled">;

type CancelableTextProps = {
  canceled: boolean;
} & Pick<TextProps, "as" | "whiteSpace" | "lineClamp" | "align">;
const CancelableText = ({
  canceled,
  children,
  ...props
}: React.PropsWithChildren<CancelableTextProps>) => {
  if (!children) {
    return null;
  }
  return (
    <Text color={canceled ? "gray-normal" : "black"} {...props}>
      {children}
    </Text>
  );
};

type TextScheduleTimeProps = Pick<Schedule, "allday" | "endAt" | "startAt">;
const TextScheduleTime = ({
  allday,
  startAt,
  endAt,
}: TextScheduleTimeProps) => (
  <Text align="center">
    {allday ? "終日" : `${toTimeString(startAt)} - ${toTimeString(endAt)}`}
  </Text>
);

type ColorDotSectionScheduleProps = Pick<Schedule, "studySchedule"> &
  Pick<LectureSession, "canceled">;

const ColorDotSectionSchedule = ({
  studySchedule,
  canceled,
}: ColorDotSectionScheduleProps) => {
  let color: Colors = "yellow";
  if (studySchedule) color = "primary";
  if (canceled) color = "gray-normal";
  return (
    <div className={styles.sectionScheduleColorDot}>
      <ColorDot backgroundColor={color} />
    </div>
  );
};

type SummaryLinkProps = CanceledField &
  Pick<Schedule, "summary"> &
  Pick<React.ComponentProps<typeof LinkButton>, "onClick">;

const SummaryLink = ({ canceled, summary, onClick }: SummaryLinkProps) => {
  if (!summary || summary === "") {
    return null;
  }
  return (
    <LinkButton onClick={onClick}>
      <Text
        as="span"
        bold
        color={canceled ? "gray-normal" : "primary"}
        lineClamp={3}
      >
        {summary}
      </Text>
    </LinkButton>
  );
};

type TextNumOfStudentsProps = CanceledField &
  Pick<LectureSession, "totalCount">;

const TextNumOfStudents = ({
  canceled,
  totalCount,
}: TextNumOfStudentsProps) => {
  if (totalCount === 0) {
    return null;
  }
  return (
    <CancelableText canceled={canceled} align="center">
      {totalCount}人
    </CancelableText>
  );
};

type TextNumOfAttendeesProps = CanceledField &
  Pick<LectureSession, "attendanceCount" | "absenceCount" | "totalCount">;

const TextNumOfAttendees = ({
  canceled,
  absenceCount,
  attendanceCount,
  totalCount,
}: TextNumOfAttendeesProps) => {
  if (canceled) {
    return <>{"休講"}</>;
  }

  // 出席/欠席の人数を見せるかどうかは紐付く生徒の合計人数で判断する
  if (totalCount === 0) {
    return null;
  }
  return (
    <CancelableText canceled={canceled} align="center">
      {`${attendanceCount}人 / ${absenceCount}人`}
    </CancelableText>
  );
};

type LectureStudyPlanProps = CanceledField &
  Pick<Schedule, "studySchedule"> &
  Pick<LectureSession, "lecture">;
export const LectureStudyPlan = ({
  canceled,
  studySchedule,
  lecture,
}: LectureStudyPlanProps) => {
  const studyScheduleText = React.useMemo(() => {
    if (!studySchedule) return;

    const texts = [];
    if (studySchedule.numberOfSeconds > 0) {
      texts.push(
        TimeHelper.secondsToDisplayTime(studySchedule.numberOfSeconds, {
          exceptZeroMinute: true,
        }),
      );
    }
    if (studySchedule.amount > 0) {
      texts.push(
        `${studySchedule.amount}${studySchedule.learningMaterial.unit}`,
      );
    }
    return texts.join(" ");
  }, [
    studySchedule?.amount,
    studySchedule?.numberOfSeconds,
    studySchedule?.learningMaterial.unit,
  ]);

  if (!lecture && !studySchedule) {
    return null;
  }

  return (
    <>
      {lecture && (
        <div
          className={classnames(styles.studyPlanTitle, styles.studyPlanElement)}
        >
          <CancelableText as="span" canceled={canceled} lineClamp={2}>
            {lecture.name}
          </CancelableText>
        </div>
      )}
      {studySchedule && (
        <div
          className={classnames(
            styles.studyPlanValues,
            styles.studyPlanElement,
          )}
        >
          {(studySchedule.numberOfSeconds > 0 || studySchedule.amount > 0) && (
            <>
              <Icon
                className={styles.studyPlanValues__icon}
                name="icon-planning-timelist"
                color={canceled ? "gray-normal" : "primary"}
              />
              <div className={styles.studyPlanValues__text}>
                <CancelableText as="span" lineClamp={1} canceled={canceled}>
                  {studyScheduleText}
                </CancelableText>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
