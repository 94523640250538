import { AnnounceMessageRecipientsWrapper } from "../../../../components/features/AnnounceMessageRecipientsWrapper";
import Icon from "../../../../components/atoms/Icon";
import Loader from "../../../../components/atoms/Loader";
import * as React from "react";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import { useQueryError } from "../../../../hooks/http/useQueryError";
// TODO: このコンポーネントはGuardianAnnounceMessageFormでしか使用されていないので、そちらに移動する
// 別コンポーネントのhookだが、今後同一ディレクトリになるので一時的にコンポーネントをまたぐ
import { useFetchRecipients } from "../GuardianAnnounceMessageForm/useFetchRecipients";

type Props = {
  sectionId: string;
  tag: GuardianAnnounceMessageTagInterface;
};

export const RecipientStudents = (props: Props) => {
  const {
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    data,
    fetchNextPage,
    error,
  } = useFetchRecipients(props);
  useQueryError(error);

  const loadMore = React.useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const students = React.useMemo(() => {
    return data?.pages.flatMap((page) => page.data) ?? [];
  }, [data]);

  return (
    <AnnounceMessageRecipientsWrapper onReachToBottom={loadMore}>
      {students.map((student) => (
        <div
          key={`guardian-announce-message-recipient-${student.id}`}
          className="ml-[3rem] flex h-5 items-center justify-start border-0 border-b border-solid border-gray-300 pl-1"
        >
          <Icon name="icon-navi-avatar" className="h-2 w-2 fill-darkred-300" />
          <div className="ml-3">{student.fullName}</div>
        </div>
      ))}
      <Loader loading={isLoading || isFetchingNextPage} />
    </AnnounceMessageRecipientsWrapper>
  );
};
