import * as React from "react";
import { SectionDetailInterface } from "../../../interfaces/SectionInterface";
import { AuthenticatedPageCallbacks } from "../../../hocs/enhanceAuthenticatedPage";
import TabsNavi from "../../../components/molecules/TabsNavi";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { CourseIndex } from "./CourseIndex/index";
import { Flex } from "../../../components/atoms/Flex/index";
import { Link } from "react-router-dom";
import { ContentCourse } from "../../../domains/ContentCourse";
import { useFetchSectionCotentCourses } from "../../../hooks/http/useContentCoursesApi";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import Loader from "../../../components/atoms/Loader";
import { useTabs } from "./useTabs";
import { useQueryError } from "../../../hooks/http/useQueryError";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface OwnProps {
  section: SectionDetailInterface;
}

interface Props
  extends WithRouterProps,
    AuthenticatedPageCallbacks,
    OwnProps,
    OutletContextProps {}

const SectionsSettingsCoursesPage_: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    error,
    fetchNextPage,
  } = useFetchSectionCotentCourses({
    sectionId: props.section.id,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  const courses = data
    ? data.pages.reduce<ContentCourse[]>((previousValue, currentValue) => {
        return [...previousValue, ...currentValue.data];
      }, [])
    : [];

  const tabs = useTabs({
    sectionId: props.section.id,
    ltiDeployments: props.section.ltiDeployments,
  });

  return (
    <>
      <TabsNavi tabs={tabs} />
      <Flex justifyContent="flex-end">
        <Link
          to={`/sections/${props.section.id}/settings/content_courses/new`}
          className={`buiButtonBase buiButtonVariantFilled`}
        >
          コースの登録
        </Link>
      </Flex>
      {!isLoading ? (
        <CourseIndex courses={courses} section={props.section} />
      ) : null}
      <Loader loading={isLoading || isFetchingNextPage} />
    </>
  );
};

const pageInfo = {
  title: "コース",
};

export const SectionsSettingsContentCoursesPage = enhanceSectionsSettingsPage(
  SectionsSettingsCoursesPage_,
  pageInfo,
  ["content_courses"],
);
