import * as React from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Persistor } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import { PersistGate } from "redux-persist/integration/react";
import { FSErrorBoundary } from "../components/general/ErrorBoundary";
import FlashMessage from "../components/organisms/FlashMessage";
import WindowReload from "../components/organisms/WindowReload";
import IntercomContainer from "./IntercomContainer";
import ActivationPage from "./pages/ActivationPage";
import InvitationsPage from "./pages/InvitationsPage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordResetRequestPage from "./pages/PasswordResetRequestPage";
import PasswordResetSentPage from "./pages/PasswordResetSentPage";
import RegisterPage from "./pages/RegisterPage";
import RegisterSentPage from "./pages/RegisterSentPage";
import SectionsAnalyticsAmountPage from "./pages/SectionsAnalyticsAmountPage";
import SectionsAnalyticsStayPage from "./pages/SectionsAnalyticsStayPage";
import SectionsAnalyticsTimePage from "./pages/SectionsAnalyticsTimePage";
import SectionsAnalyticsDirectMessagePage from "./pages/SectionsAnalyticsDirectMessagePage";
import SectionsAnalyticsAnnounceMessagePage from "./pages/SectionsAnalyticsAnnounceMessagePage";
import SectionsAnnounceMessagesPage from "./pages/SectionsAnnounceMessagesPage";
import SectionsDashboardPage from "./pages/SectionsDashboardPage";
import SectionsGuardianMessageThreadsPage from "../features/SectionsGuardianMessageThreadsPage/SectionsGuardianMessageThreadsPage";
import SectionsGuardianAnnounceMessagesPage from "./pages/SectionsGuardianAnnounceMessagesPage";
import SectionsKartesPage from "./pages/SectionsKartesPage";
import SectionsMessageThreadsPage from "./pages/SectionsMessageThreadsPage";
import SectionsPage from "./pages/SectionsPage";
import SectionsSettingsGuardianMessagePage from "./pages/SectionsSettingsGuardianMessagePage";
import SectionsSettingsImportHistoriesOperationsPage from "./pages/SectionsSettingsImportHistoriesOperationsPage";
import SectionsSettingsImportHistoriesPage from "./pages/SectionsSettingsImportHistoriesPage";
import SectionsSettingsImportHistoryPage from "./pages/SectionsSettingsImportHistoryPage";
import SectionsSettingsImportOperationPage from "./pages/SectionsSettingsImportOperationPage";
import SectionsSettingsKarteTemplatesEditPage from "./pages/SectionsSettingsKarteTemplatesEditPage";
import SectionsSettingsKarteTemplatesNewPage from "./pages/SectionsSettingsKarteTemplatesNewPage";
import SectionsSettingsKarteTemplatesPage from "./pages/SectionsSettingsKarteTemplatesPage";
import SectionsSettingsManagementLearningMaterialsPage from "./pages/SectionsSettingsManagementLearningMaterialsPage";
import { SectionsSettingsDigitalLearningMaterialsPage } from "../features/SectionsSettingsDigitalLearningMaterialsPage";
import { SectionsSettingsDigitalLearningMaterialsNewPage } from "../features/SectionsSettingsDigitalLearningMaterialsNewPage";
import SectionsSettingsSectionLearningMaterialsPage from "./pages/SectionsSettingsSectionLearningMaterialsPage";
import SectionsSettingsOperatorsPage from "./pages/SectionsSettingsOperatorsPage";
import SectionsSettingsStaysPage from "./pages/SectionsSettingsStaysPage";
import SectionsSettingsStudentsNewPage from "./pages/SectionsSettingsStudentsNewPage";
import SectionsSettingsStudentsPage from "../features/SectionsSettingsStudentsPage/SectionsSettingsStudentsPage";
import SectionsSettingsTagsPage from "./pages/SectionsSettingsTagsPage";
import SectionsStaffBoardPostsPage from "./pages/SectionsStaffBoardPostsPage";
import SectionStaffBoardPostEditPage from "./pages/SectionStaffBoardPostEditPage";
import SectionStaffBoardPostPage from "./pages/SectionStaffBoardPostPage";
import SectionsTimelinePage from "../features/SectionsTimelinePage";
import SectionsTimelineSummaryPage from "../features/SectionsTimelineSummaryPage";
import SectionStudyRecordCommentsPage from "./pages/SectionStudyRecordCommentsPage";
import SessionsEditPage from "./pages/SessionsEditPage";
import SettingsEmailPage from "./pages/SettingsEmailPage";
import SettingsProfilePage from "./pages/SettingsProfilePage";
import StudentsAnalyticsAmountPage from "./pages/StudentsAnalyticsAmountPage";
import StudentsAnalyticsTimePage from "./pages/StudentsAnalyticsTimePage";
import { StudentsLearningMaterialTagAnalyticsTimePage } from "./pages/StudentsLearningMaterialTagAnalyticsTimePage";
import StudentsGuardianMessagesPage from "../features/StudentsGuardianMessagesPage/StudentsGuardianMessagesPage";
import StudentsKarteEditPage from "./pages/StudentsKarteEditPage";
import StudentsKartePage from "./pages/StudentsKartePage";
import StudentsKartesPage from "./pages/StudentsKartesPage";
import StudentsLearningMaterialsNewPage from "./pages/StudentsLearningMaterialsNewPage";
import StudentsLearningMaterialsPage from "./pages/StudentsLearningMaterialsPage";
import StudentsLearningMaterialsPreRegistrationsNewPage from "./pages/StudentsLearningMaterialsPreRegistrationsNewPage";
import StudentsLearningMaterialsPreRegistrationsPage from "./pages/StudentsLearningMaterialsPreRegistrationsPage";
import StudentsMessagesPage from "./pages/StudentsMessagesPage";
import StudentsStudyRecordPage from "../features/StudentsStudyRecordPage";
import StudentsTimelinePage from "../features/StudentsTimelinePage";
import TermPage from "./pages/TermPage";
import RequireLogin from "./Routes/RequireLogin";
import Tracker from "./Tracker";
import SectionsSettingsSkillMastersPage from "./pages/SectionsSettingsSkillMastersPage";
import SectionsSettingsSkillsPage from "./pages/SectionsSettingsSkillsPage";
import SectionsSettingsSkillsNewPage from "./pages/SectionsSettingsSkillsNewPage";
import { AnyAction, Store } from "redux";
import AppStateInterface from "../interfaces/AppStateInterface";
import ErrorTracking from "./ErrorTracking";
import SectionsSettingsSkillsEditPage from "./pages/SectionsSettingsSkillsEditPage";
import EnglishPrivacyPolicyPage from "./pages/EnglishPrivacyPolicyPage";
import EnglishTermPage from "./pages/EnglishTermPage";
import ConsentPage from "./pages/Consent/index";
import LoginCallback from "./LoginCallback";
import { StudentsCalendar } from "./pages/StudentsCalendar";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/index";
import SectionsSettingsGoogleClassroomPage from "./pages/SectionsSettingsGoogleClassroomPage";
import SectionsSettingsThirdPartyApplicationsPage from "./pages/SectionsSettingsThirdPartyApplicationsPage";
import StudentsStudySchedulesPage from "./pages/StudentsStudySchedulesPage";
import { SectionsStudySchedules } from "./pages/SectionsStudySchedules";
import { SectionsSettingsContentCoursesPage } from "./pages/SectionsSettingsContentCoursesPage";
import { SectionSettingsNewContentFiles } from "./pages/SectionSettingsNewContentFiles";
import { SectionsSettingsContentVideosNewOrEditPage } from "./pages/SectionsSettingsContentVideosNewOrEditPage";
import { SectionsSettingsContentsPage } from "./pages/SectionsSettingsContentsPage";
import { SectionsSettingsContentCourseDetail } from "./pages/SectionsSettingsContentCourseDetail";
import { SectionsSettingsContentCoursesNewOrEditPage } from "./pages/SectionsSettingsContentCoursesNewOrEditPage/index";
import { SectionSettingsEditContentFiles } from "./pages/SectionSettingsEditContentFiles";
import { SectionsSettingsContentCoursesSharePage } from "./pages/SectionsSettingsContentCoursesSharePage";
import { SectionsSettingsContentCourseStudentsPage } from "./pages/SectionsSettingsContentCourseStudentsPage";
import { SectionsSettingsLearningMaterialTags } from "./pages/SectionSettingsLearningMaterialTags";
import { SectionsSettingsTeacherNewPage } from "./pages/SectionsSettingsTeacherFormPage";
import SectionsSettingsSectionLearningMaterialsFormPage from "./pages/SectionsSettingsSectionLearningMaterialFormPage";
import { SectionsSettingsClassroomFormPage } from "./pages/SectionsSettingsClassroomFormPage";
import { SectionsSettingsTeachersPage } from "../features/SectionsSettingsTeachersPage/index";
import { SectionsSettingsClassroomPage } from "../features/SectionsSettingsClassroomsPage";
import { SectionsSettingsLecturesFormPage } from "../features/SectionsSettingsLecturesFormPage";
import { SectionsSettingsLecturesPage } from "../features/SectionsSettingsLecturesPage/SectionsSettingsLecturesPage";
import { SectionsSettingsSectionScheduleListPage } from "../features/SectionsSettingsSectionScheduleListPage";
import { SectionsSettingsStaysDetailsPage } from "../features/SectionsSettingsStaysDetailsPage/index";
import { SectionsLectureSessionDetailPage } from "../features/SectionsLectureSessionDetailPage";
import SectionsSettingsContentCoursesResultsPage from "./pages/SectionsSettingsContentCoursesResultsPage";
import SectionsSettingsContentUnitsResultsPage from "./pages/SectionsSettingsContentUnitsResultsPage";
import SectionsSettingsContentContentsResultsPage from "./pages/SectionsSettingsContentContentsResultsPage";
import StudentCourseResultPage from "./pages/StudentCourseResultPage";
import { SectionsAnalyticsLectureAttendancePage } from "../features/SectionsAnalyticsLectureAttendancePage";
import { SectionsAnalyticsLectureStudentPage } from "../features/SectionsAnalyticsLectureStudentPage";
import { StudentsStayAnalyticsPage } from "../features/StudentsStayAnalyticsPage";
import { StudentsAnalyticsLectureAttendancePage } from "../features/StudentsAnalyticsLectureAttendancePage";
import { SectionsLectureTransfersNewPage } from "../features/SectionsLectureTransfersNewPage/SectionsLectureTransfersNewPage";
import NoRouteMatch from "./NoRouteMatch";
import SectionLayout from "./Routes/SectionLayout/SectionLayout";
import SectionSettingLayout from "./Routes/SectionSettingLayout/SectionSettingLayout";
import StudentLayout from "./Routes/StudentsLayout/StudentLayout";
import { SectionsSettingsStayingStudentsPage } from "../features/SectionsSettingsStayingStudentsPage";
import { SectionsAnalyticsExaminationPage } from "../features/SectionsAnalyticsExaminationPage/SectionsAnalyticsExaminationPage";
import { SectionsAnalyticsExaminationDetailPage } from "../features/SectionsAnalyticsExaminationDetailPage";
import { StudentsExaminationResultsNewPage } from "../features/StudentsExaminationResultsNewPage/StudentsExaminationResultsNewPage";
import { StudentsAnalyticsExaminationPage } from "../features/StudentsAnalyticsExaminationPage";
import { StudentAnalyticsExaminationResultPage } from "../features/StudentsAnalyticsExaminationResultPage/StudentsAnalyticsExaminationResultPage";
import { StudentFilterProvider } from "../components/features/NewStudentFilter/useStudentFilterContext";
import { SectionsTimelineExaminationPage } from "../features/SectionsTimelineExaminationPage";
import { StudentsExaminationResultEditPage } from "../features/StudentsExaminationResultEditPage/StudentsExaminationResultEditPage";
import { StudentsAnalyticsRetentionLevelPage } from "../features/StudentsAnalyticsRetentionLevelPage";
import { SectionsAnalyticsRetentionLevelPage } from "../features/SectionsAnalyticsRetentionLevelPage/SectionsAnalyticsRetentionLevelPage";
import { SectionsAnalyticsRetentionLevelDetailPage } from "../features/SectionsAnalyticsRetentionLevelDetailPage/SectionsAnalyticsRetentionLevelDetailPage";
import { SectionsAnalyticsStudyTaskPage } from "../features/SectionsAnalyticsStudyTaskPage";
import { SectionsAnalyticsStudyTaskNewPage } from "../features/SectionsAnalyticsStudyTaskNewPage";
import { SectionsAnalyticsStudyTaskDetailPage } from "../features/SectionAnalyticsStudyTaskDetailPage";
import { SectionAnalyticsStudyTaskTabsProvider } from "../features/SectionsAnalyticsStudyTaskPage/useStudyTaskSwitchTabs";
import { SingleDateFilterProvider } from "../contexts/SingleDateFilterContext";
import { FieldVisibilityToggleProvider } from "../components/general/FieldVisibilityToggle/useFieldVisibilityToggleContext";
import { LearningMaterialFilterProvider } from "./pages/StudentsLearningMaterialTagAnalyticsTimePage/useLearningMaterialFilterContext";
import { LearningMaterialFilterWithLocalStorageProvider } from "../components/features/LearningMaterialFilter/useLearningMaterialFilterWithLocalStorageContext";
import { DrawerProvider } from "../components/general/Drawer/useDrawerContext";
import { MultiSectionBatchesDownloadStudyRecordsPage } from "../features/MultiSectionBatchesDownloadStudyRecordsPage";
import { MultiSectionBatchesDetailPage } from "../features/MultiSectionBatchesDetailPage";
import { MultiSectionBatchesDownloadStaysPage } from "../features/MultiSectionBatchesDownloadStaysPage";
import { MultiSectionBatchesPage } from "../features/MultiSectionBatchesPage";
import { MultiSectionBatchesDownloadKartesPage } from "../features/MultiSectionBatchesDownloadKartesPage";
import { MultiSectionBatchesDownloadStudentsPage } from "../features/MultiSectionBatchesDownloadStudentsPage";

interface Props {
  store: Store<AppStateInterface & PersistPartial, AnyAction>;
  persistor: Persistor;
}

export const FSRoutes = (): JSX.Element => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/terms" element={<TermPage />} />
    <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
    {/* NOTE: 利用規約・プライバシーポリシーの英語ページ。Zoomアプリ申請用に必要になった */}
    <Route path="/en/privacy_policy" element={<EnglishPrivacyPolicyPage />} />
    <Route path="/en/terms" element={<EnglishTermPage />} />
    <Route path="/consent" element={<ConsentPage />} />
    <Route path="/register/sent" element={<RegisterSentPage />} />
    <Route
      path="/activations/:emailActivationToken"
      element={<ActivationPage />}
    />
    <Route path="/password_reset" element={<PasswordResetRequestPage />} />
    <Route path="/password_reset/sent" element={<PasswordResetSentPage />} />
    <Route
      path="/password_reset/:password_reset_token"
      element={<PasswordResetPage />}
    />
    <Route element={<RequireLogin />}>
      {/* NOTE: ログインしているときのみ表示できるコンポーネントはここへ */}
      <Route path="/" element={<SectionsPage />} />

      {/* 校舎横断操作 */}
      <Route
        path="/multi_section_batches"
        element={<MultiSectionBatchesPage />}
      />
      <Route
        path="/multi_section_batches/download_study_records"
        element={<MultiSectionBatchesDownloadStudyRecordsPage />}
      />
      <Route
        path="/multi_section_batches/:batchId"
        element={<MultiSectionBatchesDetailPage />}
      />
      <Route
        path="/multi_section_batches/download_stays"
        element={<MultiSectionBatchesDownloadStaysPage />}
      />
      <Route
        path="/multi_section_batches/download_kartes"
        element={<MultiSectionBatchesDownloadKartesPage />}
      />
      <Route
        path="/multi_section_batches/download_students"
        element={<MultiSectionBatchesDownloadStudentsPage />}
      />

      {/* sessions */}
      <Route path="/sessions/edit" element={<SessionsEditPage />} />
      <Route path="/invitations/:invitationId" element={<InvitationsPage />} />
      {/* sections/ */}
      <Route path="/sections" element={<SectionsPage />} />
      <Route element={<SectionLayout />}>
        <Route
          path="/sections/:sectionId"
          element={<SectionsDashboardPage />}
        />
        <Route
          path="/sections/:sectionId/timeline/daily"
          element={<SectionsTimelineSummaryPage />}
        />
        <Route
          path="/sections/:sectionId/timeline/examination"
          element={<SectionsTimelineExaminationPage />}
        />
        <Route
          path="/sections/:sectionId/timeline"
          element={<SectionsTimelinePage />}
        />
        <Route
          path="/sections/:sectionId/analytics"
          element={<SectionsAnalyticsTimePage />}
        />
        <Route
          path="/sections/:sectionId/analytics/time"
          element={<SectionsAnalyticsTimePage />}
        />
        <Route
          path="/sections/:sectionId/analytics/amount"
          element={<SectionsAnalyticsAmountPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/stay"
          element={<SectionsAnalyticsStayPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/lecture_attendance/:lectureAttendanceAnalyticsType"
          element={<SectionsAnalyticsLectureAttendancePage />}
        />
        <Route
          path="/sections/:sectionId/analytics/lectures/:lectureId/students"
          element={<SectionsAnalyticsLectureStudentPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/direct_messages"
          element={<SectionsAnalyticsDirectMessagePage />}
        />
        <Route
          path="/sections/:sectionId/analytics/announce_messages"
          element={<SectionsAnalyticsAnnounceMessagePage />}
        />
        <Route
          path="/sections/:sectionId/analytics/examinations"
          element={<SectionsAnalyticsExaminationPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/examinations/:examinationId"
          element={<SectionsAnalyticsExaminationDetailPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/retention_level"
          element={<SectionsAnalyticsRetentionLevelPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/retention_level/:materialCode"
          element={<SectionsAnalyticsRetentionLevelDetailPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/study_task"
          element={<SectionsAnalyticsStudyTaskPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/study_task/new"
          element={<SectionsAnalyticsStudyTaskNewPage />}
        />
        <Route
          path="/sections/:sectionId/analytics/study_task/:studyTaskId"
          element={<SectionsAnalyticsStudyTaskDetailPage />}
        />
        <Route
          path="/sections/:sectionId/kartes"
          element={<SectionsKartesPage />}
        />
        <Route
          path="/sections/:sectionId/message_threads"
          element={<SectionsMessageThreadsPage />}
        />
        <Route
          path="/sections/:sectionId/tags/messages"
          element={<SectionsAnnounceMessagesPage />}
        />
        <Route
          path="/sections/:sectionId/staff_board_posts"
          element={<SectionsStaffBoardPostsPage />}
        />
        <Route
          path="/sections/:sectionId/staff_board_posts/:staffBoardPostId"
          element={<SectionStaffBoardPostPage />}
        />
        <Route
          path="/sections/:sectionId/staff_board_posts/:staffBoardPostId/edit"
          element={<SectionStaffBoardPostEditPage />}
        />
        <Route
          // NOTE:
          // /sections/:sectionId/tags/messagesと
          // /sections/:sectionId/tags/messages/newで
          // 振る舞いを変えたいのでmatch.params.actionとして受け取っている
          path="/sections/:sectionId/tags/messages/:action"
          element={<SectionsAnnounceMessagesPage />}
        />
        <Route
          path="/sections/:sectionId/tags/:destinationTagId/messages"
          element={<SectionsAnnounceMessagesPage />}
        />
        <Route
          path="/sections/:sectionId/study_records/comments"
          element={<SectionStudyRecordCommentsPage />}
        />
        <Route
          path="/sections/:sectionId/guardian_message_threads"
          element={<SectionsGuardianMessageThreadsPage />}
        />
        <Route
          path="/sections/:sectionId/tags/guardian_messages"
          element={<SectionsGuardianAnnounceMessagesPage />}
        />
        <Route
          path="/sections/:sectionId/tags/guardian_messages/:action"
          element={<SectionsGuardianAnnounceMessagesPage />}
        />
        <Route
          path="/sections/:sectionId/tags/:tagId/guardian_messages"
          element={<SectionsGuardianAnnounceMessagesPage />}
        />
        <Route
          path="/sections/:sectionId/study_schedules"
          element={<SectionsStudySchedules />}
        />
        <Route
          path="/sections/:sectionId/schedules/list"
          element={<SectionsSettingsSectionScheduleListPage />}
        />
        <Route
          path="/sections/:sectionId/lecture_sessions/:lectureSessionId/attendees"
          element={<SectionsLectureSessionDetailPage />}
        />
        <Route
          path="/sections/:sectionId/lecture_sessions/:lectureSessionId/questionnaires"
          element={<SectionsLectureSessionDetailPage />}
        />
        <Route
          path="/sections/:sectionId/lecture_transfers/new"
          element={<SectionsLectureTransfersNewPage />}
        />
      </Route>
      <Route element={<StudentLayout />}>
        <Route path="/students/:studentId" element={<StudentsTimelinePage />} />
        <Route
          path="/students/:studentId/timeline"
          element={<StudentsTimelinePage />}
        />
        <Route
          path="/students/:studentId/analytics"
          element={<StudentsAnalyticsTimePage />}
        />
        <Route
          path="/students/:studentId/analytics/time"
          element={<StudentsAnalyticsTimePage />}
        />
        <Route
          path="/students/:studentId/analytics/retention_level"
          element={<StudentsAnalyticsRetentionLevelPage />}
        />
        <Route
          path="/students/:studentId/analytics/examination"
          element={<StudentsAnalyticsExaminationPage />}
        />
        <Route
          path="/students/:studentId/analytics/examinations/:examinationId/result"
          element={<StudentAnalyticsExaminationResultPage />}
        />
        <Route
          path="/students/:studentId/analytics/lecture_attendance"
          element={<StudentsAnalyticsLectureAttendancePage />}
        />
        <Route
          path="/students/:studentId/learning_material_tag_analytics/time"
          element={<StudentsLearningMaterialTagAnalyticsTimePage />}
        />
        <Route
          path="/students/:studentId/analytics/stay"
          element={<StudentsStayAnalyticsPage />}
        />
        <Route
          path="/students/:studentId/kartes"
          element={<StudentsKartesPage />}
        />
        <Route
          path="/students/:studentId/kartes/:karteId"
          element={<StudentsKartePage />}
        />
        <Route
          path="/students/:studentId/kartes/:karteId/edit"
          element={<StudentsKarteEditPage />}
        />
        <Route
          path="/students/:studentId/study_records/:studyRecordId"
          element={<StudentsStudyRecordPage />}
        />
        <Route
          path="/students/:studentId/analytics/amount"
          element={<StudentsAnalyticsAmountPage />}
        />
        <Route
          path="/students/:studentId/messages"
          element={<StudentsMessagesPage />}
        />
        <Route
          path="/students/:studentId/guardian_messages"
          element={<StudentsGuardianMessagesPage />}
        />
        <Route
          path="/students/:studentId/learning_materials"
          element={<StudentsLearningMaterialsPage />}
        />
        <Route
          path="/students/:studentId/learning_materials/new"
          element={<StudentsLearningMaterialsNewPage />}
        />
        <Route
          path="/students/:studentId/learning_materials/pre_registrations"
          element={<StudentsLearningMaterialsPreRegistrationsPage />}
        />
        <Route
          path="/students/:studentId/learning_materials/pre_registrations/new"
          element={<StudentsLearningMaterialsPreRegistrationsNewPage />}
        />
        <Route
          path="/students/:studentId/calendar"
          element={<StudentsCalendar />}
        />
        <Route
          path="/students/:studentId/study_schedules"
          element={<StudentsStudySchedulesPage />}
        />
        <Route
          path="/students/:studentId/content_courses/:courseId/results"
          element={<StudentCourseResultPage />}
        />
        <Route
          path="/students/:studentId/analytics/examinations/result/new"
          element={<StudentsExaminationResultsNewPage />}
        />
        <Route
          path="/students/:studentId/analytics/examinations/:examinationId/result/edit"
          element={<StudentsExaminationResultEditPage />}
        />
      </Route>
      {/* settings/ */}
      <Route path="/settings/profile" element={<SettingsProfilePage />} />
      <Route path="/settings/email/:token" element={<SettingsEmailPage />} />
      <Route element={<SectionSettingLayout />}>
        <Route
          path="/sections/:sectionId/students/new"
          element={<SectionsSettingsStudentsNewPage />}
        />
        <Route
          path="/sections/:sectionId/settings/learning_material_tags"
          element={<SectionsSettingsLearningMaterialTags />}
        />
        <Route
          path="/sections/:sectionId/settings/management_learning_materials"
          element={<SectionsSettingsManagementLearningMaterialsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/digital_learning_materials"
          element={<SectionsSettingsDigitalLearningMaterialsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/digital_learning_materials/new"
          element={<SectionsSettingsDigitalLearningMaterialsNewPage />}
        />

        {/* 新しい校舎独自教材ページ */}
        <Route
          path="/sections/:sectionId/settings/section_learning_materials"
          element={<SectionsSettingsSectionLearningMaterialsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/section_learning_materials/new"
          element={<SectionsSettingsSectionLearningMaterialsFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/section_learning_materials/:learningMaterialId/edit"
          element={<SectionsSettingsSectionLearningMaterialsFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/students"
          element={<SectionsSettingsStudentsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/operators"
          element={<SectionsSettingsOperatorsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/karte_templates"
          element={<SectionsSettingsKarteTemplatesPage />}
        />
        <Route
          path="/sections/:sectionId/settings/karte_templates/new"
          element={<SectionsSettingsKarteTemplatesNewPage />}
        />
        <Route
          path="/sections/:sectionId/settings/karte_templates/:karteTemplateId/edit"
          element={<SectionsSettingsKarteTemplatesEditPage />}
        />
        <Route
          path="/sections/:sectionId/settings/stays"
          element={<SectionsSettingsStaysPage />}
        />
        <Route
          path="/sections/:sectionId/settings/stays/staying_students"
          element={<SectionsSettingsStayingStudentsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/stays/details"
          element={<SectionsSettingsStaysDetailsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/tags"
          element={<SectionsSettingsTagsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/guardian_message"
          element={<SectionsSettingsGuardianMessagePage />}
        />
        <Route
          path="/sections/:sectionId/settings/applications"
          element={<SectionsSettingsThirdPartyApplicationsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/applications/google_classroom"
          element={<SectionsSettingsGoogleClassroomPage />}
        />
        <Route
          path="/sections/:sectionId/settings/:importOperation"
          element={<SectionsSettingsImportOperationPage />}
        />
        <Route
          path="/sections/:sectionId/settings/import_histories"
          element={<SectionsSettingsImportHistoriesPage />}
        />
        <Route
          path="/sections/:sectionId/settings/import_histories/operations"
          element={<SectionsSettingsImportHistoriesOperationsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/import_histories/:importHistoryId"
          element={<SectionsSettingsImportHistoryPage />}
        />
        <Route
          path="/sections/:sectionId/settings/skills"
          element={<SectionsSettingsSkillsPage />}
        />
        <Route
          path={`/sections/:sectionId/settings/skill_masters`}
          element={<SectionsSettingsSkillMastersPage />}
        />
        <Route
          path={`/sections/:sectionId/settings/skill_masters/:id/skills/new`}
          element={<SectionsSettingsSkillsNewPage />}
        />
        <Route
          path={`/sections/:sectionId/settings/skills/:skillId/edit`}
          element={<SectionsSettingsSkillsEditPage />}
        />
        <Route
          path="/sections/:sectionId/settings/contents"
          element={<SectionsSettingsContentsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses"
          element={<SectionsSettingsContentCoursesPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/results"
          element={<SectionsSettingsContentCoursesResultsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/:contentCourseId/results"
          element={<SectionsSettingsContentUnitsResultsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/:contentCourseId/units/:unitId/results"
          element={<SectionsSettingsContentContentsResultsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/:contentCourseId/students"
          element={<SectionsSettingsContentCourseStudentsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/new"
          element={<SectionsSettingsContentCoursesNewOrEditPage action="new" />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/:courseId/edit"
          element={
            <SectionsSettingsContentCoursesNewOrEditPage action="edit" />
          }
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/share"
          element={<SectionsSettingsContentCoursesSharePage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/:contentCourseId/students"
          element={<SectionsSettingsContentCourseStudentsPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_courses/:courseId"
          element={<SectionsSettingsContentCourseDetail />}
        />
        <Route
          path="/sections/:sectionId/settings/content_videos/new"
          element={<SectionsSettingsContentVideosNewOrEditPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_videos/:contentId/edit"
          element={<SectionsSettingsContentVideosNewOrEditPage />}
        />
        <Route
          path="/sections/:sectionId/settings/content_files/new"
          element={<SectionSettingsNewContentFiles />}
        />
        <Route
          path="/sections/:sectionId/settings/content_files/:contentId/edit"
          element={<SectionSettingsEditContentFiles />}
        />
        <Route
          path="/sections/:sectionId/settings/teachers"
          element={<SectionsSettingsTeachersPage />}
        >
          <Route
            path=":archiveStatus"
            element={<SectionsSettingsTeachersPage />}
          />
        </Route>
        <Route
          path="/sections/:sectionId/settings/teachers/new"
          element={<SectionsSettingsTeacherNewPage />}
        />
        <Route
          path="/sections/:sectionId/settings/classrooms"
          element={<SectionsSettingsClassroomPage />}
        >
          <Route
            path=":archiveStatus"
            element={<SectionsSettingsClassroomPage />}
          />
        </Route>
        <Route
          path="/sections/:sectionId/settings/classrooms/new"
          element={<SectionsSettingsClassroomFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/classrooms/:classroomId/edit"
          element={<SectionsSettingsClassroomFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/classrooms/:classroomId/copy"
          element={<SectionsSettingsClassroomFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/lectures/new"
          element={<SectionsSettingsLecturesFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/lectures/:lectureId/edit"
          element={<SectionsSettingsLecturesFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/lectures/:lectureId/copy"
          element={<SectionsSettingsLecturesFormPage />}
        />
        <Route
          path="/sections/:sectionId/settings/lectures"
          element={<SectionsSettingsLecturesPage />}
        >
          <Route
            path=":archiveStatus"
            element={<SectionsSettingsLecturesPage />}
          />
        </Route>
      </Route>
    </Route>

    {/* NOTE: 完全一致でマッチするpathがないときにここへルーティング */}
    <Route path="*" element={<NoRouteMatch />} />
  </Routes>
);

const Root = (props: Props): React.ReactElement<Props> => {
  return (
    <Provider store={props.store}>
      <FSErrorBoundary>
        <PersistGate loading={null} persistor={props.persistor}>
          <IntercomContainer />
          <FlashMessage />
          <WindowReload />
          <Tracker />
          <ErrorTracking />
          <LoginCallback />
          <FSFeatureProviders>
            <FSRoutes />
          </FSFeatureProviders>
        </PersistGate>
      </FSErrorBoundary>
    </Provider>
  );
};

export const FSFeatureProviders = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  // FIXME: Providerが無意味にネストしないようにしたい(ここのProvider同士は依存しない実装になっているのでネストの順に意味はない)
  <StudentFilterProvider>
    <SingleDateFilterProvider>
      <LearningMaterialFilterWithLocalStorageProvider>
        <LearningMaterialFilterProvider>
          <SectionAnalyticsStudyTaskTabsProvider>
            <FieldVisibilityToggleProvider>
              <DrawerProvider>{children}</DrawerProvider>
            </FieldVisibilityToggleProvider>
          </SectionAnalyticsStudyTaskTabsProvider>
        </LearningMaterialFilterProvider>
      </LearningMaterialFilterWithLocalStorageProvider>
    </SingleDateFilterProvider>
  </StudentFilterProvider>
);

export default Root;
