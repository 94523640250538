import ApiClient, { GetRequestOptions } from "../api";

const SectionGuardianMessageThreadApi = {
  interruptGetGuardianMessageThreads: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/sections/${sectionId}/guardian_message_threads`,
      options,
    );
  },

  getGuardianMessageThreads: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/guardian_message_threads`,
      options,
    );
  },
};

export default SectionGuardianMessageThreadApi;
