import { Action } from "redux";
import ApiResponse from "../../../interfaces/ApiResponse";
import SkillInterface from "../../../interfaces/SkillInterface";

export enum ActionTypes {
  INITIALIZE = "sections/settings/skills/INITIALIZE",
  START_LOADING = "sections/settings/skills/START_LOADING",
  LOAD_SUCCESS = "sections/settings/skills/LOAD_SUCCESS",
  LOAD_FAILURE = "sections/settings/skills/LOAD_FAILURE",
  DELETE_REQUEST = "sections/settings/skills/DELETE_REQUEST",
  DELETE_SUCCESS = "sections/settings/skills/DELETE_SUCCESS",
  DELETE_FAILURE = "sections/settings/skills/DELETE_FAILURE",
  ENABLE_SUCCESS = "sections/settings/skills/ENABLE_SUCCESS",
  ENABLE_FAILURE = "sections/settings/skills/ENABLE_FAILURE",
  DISABLE_SUCCESS = "sections/settings/skills/DISABLE_SUCCESS",
  DISABLE_FAILURE = "sections/settings/skills/DISABLE_FAILURE",
}

export type Actions =
  | InitializeActions
  | StartLoadingActions
  | LoadSuccessActions
  | LoadFailureActions
  | DeleteSkillRequest
  | DeleteSkillSuccess
  | DeleteSkillFailure
  | EnableSkillSuccess
  | EnableSkillFailure
  | DisableSkillSuccess
  | DisableSkillFailure;

export interface LoadSuccessPayload {
  skills: ApiResponse<SkillInterface[]>;
}

interface InitializeActions extends Action {
  type: ActionTypes.INITIALIZE;
}

interface StartLoadingActions extends Action {
  type: ActionTypes.START_LOADING;
}

interface LoadSuccessActions extends Action {
  type: ActionTypes.LOAD_SUCCESS;
  payload: LoadSuccessPayload;
}

interface LoadFailureActions extends Action {
  type: ActionTypes.LOAD_FAILURE;
}

// スキルの削除
export interface DeleteSuccessPayload {
  skillId: string;
}
interface DeleteSkillRequest extends Action {
  type: ActionTypes.DELETE_REQUEST;
}

interface DeleteSkillSuccess extends Action {
  type: ActionTypes.DELETE_SUCCESS;
  payload: DeleteSuccessPayload;
}

interface DeleteSkillFailure extends Action {
  type: ActionTypes.DELETE_FAILURE;
}

// スキルをオンにする
interface EnableSkillSuccess extends Action {
  type: ActionTypes.ENABLE_SUCCESS;
  payload: string;
}

interface EnableSkillFailure extends Action {
  type: ActionTypes.ENABLE_FAILURE;
}

// スキルをオフにする
interface DisableSkillSuccess extends Action {
  type: ActionTypes.DISABLE_SUCCESS;
  payload: string;
}

interface DisableSkillFailure extends Action {
  type: ActionTypes.DISABLE_FAILURE;
}
