import * as React from "react";
import styles from "./styles.scss";
import { IconTrash } from "@studyplus/boron-ui";

import {
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from "../../general/Tooltip/Tooltip";

interface Props extends React.PropsWithChildren<unknown> {
  image: string;
  title: string;
  key: string;
  showDeleteIcon?: boolean;
  onDelete?: (e: any) => void;
}

const Card = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <img src={props.image} />
      </div>
      <h4 className={styles.title}>{props.title}</h4>
      {renderDeleteButton(props)}
    </div>
  );
};

const renderDeleteButton = (props: Props) => {
  if (props.showDeleteIcon) {
    return (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger
            className="absolute right-3 top-3 p-0"
            onClick={props.onDelete}
            aria-label={`${props.title}を本棚から削除`}
          >
            <IconTrash className="h-2 w-2 text-gray-600 hover:text-blue-400" />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent side="top">削除</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    );
  } else {
    return null;
  }
};

export default Card;
