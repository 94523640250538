import * as React from "react";
import styles from "./styles.scss";
import { Progress, toPercent } from "../../../domains/LearningMaterialProgress";
import classNames from "classnames";
import { ProgressBar } from "../../atoms/ProgressBar";

interface Props {
  progress: Progress;
}

const StudyScheduleProgressBar = (props: Props): React.ReactElement => {
  const percent = toPercent(props.progress);
  return (
    <ProgressBar
      value={percent}
      className={classNames({
        [styles.ltHalf]: percent < 50,
        [styles.gteqHalf]: percent >= 50,
        [styles.completed]: percent >= 100,
      })}
    />
  );
};

export default StudyScheduleProgressBar;
