import * as React from "react";
import { Modal } from "../../../../components/atoms/Modal";
import { Body as ModalBody } from "../../../../components/atoms/Modal/Body";
import Loader from "../../../../components/atoms/Loader";
import { Button } from "@studyplus/boron-ui";
import styles from "./DistributeTagModal.scss";
import { DistributeTagSelectbox } from "./DistributeTagSelectbox";
import {
  useSelectDistributeTagModal,
  UseSelectDistributeTagModalProps,
} from "./useSelectDistributeTagModal";
import { DistributeTagStudentsTable } from "./DistributeTagStudentsTable";
import { ContentCourse } from "../../../../domains/ContentCourse";
import { useBoolean } from "../../../../hooks/useBoolean";

type Props = {
  isOpen: boolean;
  sectionId: string;
  onCloseModal: () => void;
  contentCourse: ContentCourse;
};
export const SelectDistributeStudentModal = ({
  isOpen,
  onCloseModal,
  sectionId,
  contentCourse,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={onCloseModal}
    >
      <Modal.Header onClose={onCloseModal}>配信対象タグの登録</Modal.Header>
      <ModalView
        onClose={onCloseModal}
        contentCourse={contentCourse}
        sectionId={sectionId}
      />
    </Modal>
  );
};

export const useModalManager = () => {
  const modalState = useBoolean();
  return {
    isModalOpen: modalState.value,
    openModal: modalState.on,
    closeModal: modalState.off,
  };
};

const ModalView = (props: UseSelectDistributeTagModalProps) => {
  const {
    tagSelectboxProps,
    tagStudentsTableProps,
    submitButtonProps,
    initialLoaderProps,
    distributeTagSelectorProps,
    studentTableLoaderProps,
  } = useSelectDistributeTagModal(props);
  return (
    <>
      <ModalBody>
        <Loader {...initialLoaderProps} />
        <DistributeTagSelector {...distributeTagSelectorProps}>
          <div className={styles.label}>
            <label htmlFor="distribute-tag">配信対象のタグを選択</label>
          </div>
          <div className={styles.distributeTagModal__tag}>
            <DistributeTagSelectbox {...tagSelectboxProps} />
          </div>
          <div className={styles.distributeTagModal__studentsLabel}>
            <div className={styles.label}>配信対象生徒</div>
            <div
              className={`${styles.label} ${styles["distributeTagModal__studentsLabel--subNote"]}`}
            >
              ※未招待・招待中・停止中の生徒は配信対象外です。
            </div>
          </div>
          <div className={styles.distributeTagModal__students}>
            <div className={styles.studentsView}>
              <Loader {...studentTableLoaderProps} />
              <DistributeTagStudentsTable {...tagStudentsTableProps} />
            </div>
          </div>
          <div className={styles.distributeTagModal__submit}>
            <Button className={styles.submitButton} {...submitButtonProps}>
              決定
            </Button>
          </div>
        </DistributeTagSelector>
      </ModalBody>
    </>
  );
};

type DistributeTagSelectorProps = {
  hidden: boolean;
};
export const DistributeTagSelector: React.FC<
  React.PropsWithChildren<DistributeTagSelectorProps>
> = ({ children, hidden }) => {
  if (hidden) {
    return null;
  }
  return <div className={styles.DistributeTagSelector}>{children}</div>;
};
