import * as React from "react";

enum ActionTypes {
  SELECT = "SELECT",
  DESELECT = "DESELECT",
}
type SelectCourseAction = { type: ActionTypes.SELECT; payload: string };
type DeselectCourseAction = { type: ActionTypes.DESELECT; payload: string };
type Actions = SelectCourseAction | DeselectCourseAction;
type CourseReducer = React.Reducer<string[], Actions>;

const courseReducer: CourseReducer = (state, action): string[] => {
  switch (action.type) {
    case ActionTypes.SELECT:
      return Array.from(new Set(state.concat(action.payload)));
    case ActionTypes.DESELECT:
      return state.filter((courseId) => courseId !== action.payload);
    default:
      return state;
  }
};

export const useCourseSelector = (): [
  string[],
  (course_id: string) => boolean,
  (course_id: string) => () => void,
] => {
  const [state, dispatch] = React.useReducer(courseReducer, []);
  const isCourseSelected = (courseId: string): boolean =>
    !!state.find((n) => courseId === n);
  const handleChange = (courseId: string) => (): void => {
    if (isCourseSelected(courseId)) {
      dispatch({ type: ActionTypes.DESELECT, payload: courseId });
    } else {
      dispatch({ type: ActionTypes.SELECT, payload: courseId });
    }
  };

  return [state, isCourseSelected, handleChange];
};
