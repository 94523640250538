const isUserAgentIos = () => {
  let result = false;
  if (
    navigator.userAgent.toLowerCase().indexOf("iphone") >= 0 ||
    navigator.userAgent.toLowerCase().indexOf("ipod") >= 0 ||
    navigator.userAgent.toLowerCase().indexOf("ipad") >= 0
  ) {
    result = true;
  }
  return result;
};

const isUserAgentAndroid = () => {
  let result = false;
  if (navigator.userAgent.toLowerCase().indexOf("android") >= 0) {
    result = true;
  }
  return result;
};

const isPC = (): boolean => {
  return !(isUserAgentIos() || isUserAgentAndroid());
};

const isScreenPC = (): boolean => {
  return window.matchMedia("(min-width:960px)").matches;
};

const isScreenTablet = (): boolean => {
  return window.matchMedia("(min-width:768px)").matches;
};

const isScreenMobile = (): boolean => {
  return window.matchMedia("(max-width: 767px)").matches;
};

const isIosMobile = (): boolean => {
  return isUserAgentIos() && isScreenMobile();
};

const isAndroidMobile = (): boolean => {
  return isUserAgentAndroid() && isScreenMobile();
};

const isTargetDevice = (devices?: Device[]): boolean => {
  if (!devices || devices.length === 0) {
    return true;
  }
  if (devices.includes("mobile") && isScreenMobile()) {
    return true;
  } else if (devices.includes("iosMobile") && isIosMobile()) {
    return true;
  } else if (devices.includes("androidMobile") && isAndroidMobile()) {
    return true;
  } else if (devices.includes("tablet") && isScreenTablet()) {
    return true;
  } else if (devices.includes("pc") && isPC() && isScreenPC()) {
    return true;
  }
  return false;
};

export type Device = "mobile" | "iosMobile" | "androidMobile" | "tablet" | "pc";

const DeviceHelper = {
  isUserAgentIos,
  isUserAgentAndroid,
  isPC,
  isScreenPC,
  isScreenTablet,
  isScreenMobile,
  isIosMobile,
  isAndroidMobile,
  isTargetDevice,
};

export default DeviceHelper;
