import * as React from "react";
import Checkbox from "../../../components/atoms/Checkbox";

export type MixedCheckValue = boolean | "indeterminate";
type MixedCheckboxProps = {
  id: string;
  value: MixedCheckValue;
  disabled?: boolean;
  onChanged?: (checked: boolean) => void;
};

/**
 * このコンポーネントが解決すること：
 * - 値の一要素としてindeterminateを扱うので使う側からステート管理が行いやすい
 * - indeterminate から onChangeが発生した場合は必ずoff状態になり挙動に一貫性があるので、ユーザーから予測しやすい
 */

export const MixedCheckbox = ({
  id,
  disabled = false,
  value,
  onChanged,
  children,
}: React.PropsWithChildren<MixedCheckboxProps>) => {
  return (
    <Checkbox
      isBlock
      id={id}
      onChange={(e) => {
        onChanged?.(value === "indeterminate" ? false : e.target.checked);
      }}
      checked={value === true}
      disabled={disabled}
      indeterminate={value === "indeterminate"}
    >
      {children}
    </Checkbox>
  );
};
