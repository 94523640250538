import { IconHelp } from "@studyplus/boron-ui";
import classNames from "classnames";

type Props = {
  helpUrl: string;
  isOnlyProPlanStudent: boolean;
};

export const MultiSectionBatchesDescription = ({
  helpUrl,
  isOnlyProPlanStudent,
}: Props) => {
  return (
    <div className="mb-4 mt-8">
      <p className="flex items-center">
        操作方法を確認しながら進めてください。
        <a
          href={helpUrl}
          rel="noopener noreferrer"
          target="_blank"
          className="flex items-center text-blue-400 hover:text-blue-300"
        >
          <IconHelp />
          操作方法を見る
        </a>
      </p>
      <p
        className={classNames("text-gray-800", {
          invisible: !isOnlyProPlanStudent,
        })}
      >
        ※Pro生徒のみが出力されます。
      </p>
    </div>
  );
};
