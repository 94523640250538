import styles from "./styles.scss";
import StaffBoardPostInterface from "../../../interfaces/StaffBoardPostInterface";
import StaffBoardPostItem from "../StaffBoardPostItem";
import SectionInterface from "../../../interfaces/SectionInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { HandleThunkActionCreator } from "react-redux";
import {
  deleteStaffBoardPost,
  deleteComment,
  postComment,
} from "../../../actions/pages/SectionStaffBoardPostsPage/actions";
import { StaffBoardPostCommentFormInterface } from "../../../interfaces/StaffBoardPostInterface";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  staffBoardPosts: StaffBoardPostInterface[];
  deletePost: HandleThunkActionCreator<typeof deleteStaffBoardPost>;
  postComment: HandleThunkActionCreator<typeof postComment>;
  deleteComment: HandleThunkActionCreator<typeof deleteComment>;
}

const StaffBoardPostList = (props: Props) => {
  if (props.staffBoardPosts.length === 0) return null;

  return <div className={styles.root}>{renderList(props)}</div>;
};

const renderList = (props: Props) => {
  const handlePostComment =
    (post: StaffBoardPostInterface) =>
    (values: StaffBoardPostCommentFormInterface) => {
      props.postComment(props.section.id, post.id, values);
    };

  const handleDeleteComment =
    (post: StaffBoardPostInterface) => (commentId: string) => {
      props.deleteComment(props.section.id, post.id, commentId);
    };

  const handleDeletePost = (post: StaffBoardPostInterface) => () => {
    props.deletePost(props.section.id, post.id);
  };

  return props.staffBoardPosts.map((post: StaffBoardPostInterface) => {
    return (
      <StaffBoardPostItem
        section={props.section}
        currentOperator={props.currentOperator}
        staffBoardPost={post}
        submitting={post.submitting}
        postComment={handlePostComment(post)}
        deleteComment={handleDeleteComment(post)}
        deletePost={handleDeletePost(post)}
        key={`staff_board_post-${post.id}`}
      />
    );
  });
};

export default StaffBoardPostList;
