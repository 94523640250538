import * as React from "react";
import { format, parseISO } from "date-fns";

type ExaminationResultDetailProps = {
  examinedOn: string;
  organizerName: string;
  classificationName: string;
  targetJobName: string;
  examinationName: string;
};

export const ExaminationResultDetail = ({
  examinedOn,
  organizerName,
  classificationName,
  targetJobName,
  examinationName,
  children,
}: React.PropsWithChildren<ExaminationResultDetailProps>) => {
  const formatedExaminedOn = format(parseISO(examinedOn), "yyyy/M/d");
  return (
    <div
      aria-label={`試験名: ${formatedExaminedOn} ${organizerName} ${classificationName} ${targetJobName} ${examinationName}`}
    >
      <span className="mr-4">{formatedExaminedOn}</span>
      <span className="mr-4">{organizerName}</span>
      <span className="mr-4">{classificationName}</span>
      <span>{targetJobName}</span>
      <div className="mt-4 text-2xl font-bold leading-none">
        {children ? children : examinationName}
      </div>
    </div>
  );
};
