import styles from "./styles.scss";

interface Props {
  label: string;
}

const HeaderNotificationEmptyList = (props: Props) => {
  return <p className={styles.root}>{props.label}</p>;
};

export default HeaderNotificationEmptyList;
