import { NarrowingConditions } from "../../../hooks/http/useLearningMaterialTagApi";
import { Flex } from "../../../components/atoms/Flex";
import { Button } from "@studyplus/boron-ui";
import SelectField from "../../../components/atoms/DeprecatedSelectField";
import styles from "./LearningMaterialTagManipulator.scss";

type Props = {
  onChangeNarrowingCondition: (
    narrrowingCondition: NarrowingConditions,
  ) => void;
  onClickRegisterTag: () => void;
};

type Option = {
  label: string;
  value: NarrowingConditions;
};

type Options = ReadonlyArray<Option>;

const options: Options = [
  {
    label: "全て",
    value: "all",
  },
  {
    label: "デフォルト",
    value: "default",
  },
  {
    label: "カスタム",
    value: "custom",
  },
];

export const LearningMaterialTagNarrowForm = ({
  onChangeNarrowingCondition,
  onClickRegisterTag,
}: Props) => {
  return (
    <>
      <Flex marginTop="0rem" justifyContent="space-between">
        <SelectField
          className={styles.selectbox}
          defaultValue={options[0]}
          options={options}
          onChange={(item: { label: string; value: string }) =>
            onChangeNarrowingCondition(item.value as NarrowingConditions)
          }
        />
        <Button onClick={onClickRegisterTag}>教材タグを登録</Button>
      </Flex>
    </>
  );
};
