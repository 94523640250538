import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("正しいメールアドレスを入力してください")
    .trim()
    .required("メールアドレスを入力してください"),
});

export default validationSchema;
