import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";

const defaultPrivileges: OperatorPrivilegesStateInterface = {
  notFound: false,
  data: null,
};

export const operatorPrivileges = (
  state: OperatorPrivilegesStateInterface = defaultPrivileges,
  action: ReduxActions.Action<any>,
): OperatorPrivilegesStateInterface => {
  switch (action.type) {
    case "GET_OPERATOR_PRIVILEGES_RESPONSE_RECEIVED":
      return {
        data: action.payload.operator,
        notFound: false,
      };
    case "GET_OPERATOR_PRIVILEGES_RESPONSE_ERROR":
      if (action.payload && action.payload.status === 404) {
        return {
          ...state,
          notFound: true,
        };
      }
      return state;
    default:
      return state;
  }
};
