import React, { useCallback, useReducer } from "react";

type EntityBase = { id: string };

type Actions<T extends EntityBase> =
  | {
      type: "Add";
      item: T | T[];
    }
  | {
      type: "Remove";
      item: T;
    }
  | {
      type: "Replace";
      item: T | T[];
    };

const reducer = <T extends EntityBase>(
  state: ReadonlyArray<T>,
  action: Actions<T>,
) => {
  switch (action.type) {
    case "Add": {
      let tmp = action.item;
      if (!Array.isArray(tmp)) {
        tmp = [tmp];
      }
      return [...state, ...tmp];
    }
    case "Remove":
      return state.filter((item) => item.id !== action.item.id);
    case "Replace": {
      let tmp = action.item;
      if (!Array.isArray(tmp)) {
        tmp = [tmp];
      }
      return tmp;
    }
  }
};

export const useEntityArray = <T extends EntityBase>(initial?: T[]) => {
  const [items, entityArrayDispatch] = useReducer<
    React.Reducer<T[], Actions<T>>
  >(reducer, initial ?? ([] as T[]));

  const add = useCallback((item: T | T[]) => {
    entityArrayDispatch({ type: "Add", item });
  }, []);

  const remove = useCallback((item: T) => {
    entityArrayDispatch({ type: "Remove", item });
  }, []);

  const replace = useCallback((item: T | T[]) => {
    entityArrayDispatch({ type: "Replace", item });
  }, []);

  return { items, add, remove, replace };
};
