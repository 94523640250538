import { useEffect } from "react";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import { useContentCourseFormState } from "./useContentCourseFormState";
import { useSubmit, SubmitProps } from "./useSubmit";

export type ContentCourseFormProps = SubmitProps;

export const useContentCourseForm = ({
  sectionId,
  course,
}: ContentCourseFormProps) => {
  const { handleSubmit, mutation } = useSubmit({
    sectionId,
    course,
  });
  const form = useContentCourseFormState({ onSubmit: handleSubmit, course });

  useEffect(() => {
    if (!mutation.error) {
      return;
    }

    if (mutation.error.errors) {
      mutation.error.errors.forEach((error: ApiErrorInterface) => {
        error.field && form.setFieldError(error.field, error.message);
      });
    }

    form.setSubmitting(false);
  }, [mutation.error]);

  return form;
};
