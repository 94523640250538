import isEqual from "lodash-es/isEqual";
import * as React from "react";
import { HandleThunkActionCreator } from "react-redux";
import { Link } from "react-router-dom";

import { updateStudentTags } from "../../../actions/common/student";
import StudentInterface from "../../../interfaces/StudentInterface";
import StudentTag from "../../../domains/StudentTag";
import DeprecatedTagButton from "../../features/DeprecatedTagButton";
import SelectTagModal, { SelectTagsFor } from "../SelectTagModal";
import styles from "./styles.scss";

interface Props {
  selectedTags: StudentTag[];
  sectionTags: StudentTag[];
  student: StudentInterface;
  submitting: boolean;
  updateStudentTags: HandleThunkActionCreator<typeof updateStudentTags>;
}

interface State {
  isOpen: boolean;
}

class StudentNavTagList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  UNSAFE_componentWillUpdate(nextProps: Props) {
    if (isEqual(nextProps.student.tags, this.props.student.tags)) {
      return;
    }
    this.setIsOpen(false);
  }

  handleSubmit = (tags: StudentTag[]) => {
    this.props.updateStudentTags(this.props.student.id, tags);
  };

  renderTags = () => {
    return this.props.student.tags.map((tag) => (
      <span className={styles.tag} key={`student-navi-priofile-${tag.id}`}>
        <DeprecatedTagButton label={tag.name} theme="gray" disabled={true} />
      </span>
    ));
  };

  renderSmartTags = () => {
    return this.props.student.smartTags.map((tag) => (
      <span className={styles.tag} key={`student-navi-priofile-${tag.id}`}>
        <DeprecatedTagButton
          label={tag.name}
          theme="smarttag"
          disabled={true}
        />
      </span>
    ));
  };

  render() {
    return (
      <React.Fragment>
        <div className={styles.root}>
          {this.renderTags()}
          {this.renderSmartTags()}
          <div className={styles.selectTag}>
            <DeprecatedTagButton
              label="タグ設定"
              iconName="icon-arrow-right"
              theme="main"
              onClick={() => this.setIsOpen(true)}
            />
          </div>
        </div>
        <SelectTagModal
          submitting={this.props.submitting}
          selectTagsFor={SelectTagsFor.Student}
          selectedTags={this.props.selectedTags}
          sectionTags={this.props.sectionTags}
          isOpen={this.state.isOpen}
          onRequestClose={() => this.setIsOpen(false)}
          onSubmit={this.handleSubmit}
        >
          <h2>
            <Link to={`/students/${this.props.student.id}`}>
              {this.props.student.fullName}
            </Link>
            のタグ設定
          </h2>
        </SelectTagModal>
      </React.Fragment>
    );
  }

  private setIsOpen(value: boolean) {
    this.setState({ isOpen: value });
  }
}

export default StudentNavTagList;
