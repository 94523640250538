import { Lecture } from "../domains/Lecture";

export const fromJson = (json: unknown): Lecture => {
  const record = json as Record<string, any>;

  if (record.updatedAt !== null && typeof record.updatedAt !== "string") {
    throw new Error("Server responded 'updatedAt' as an unexpected value");
  }

  return {
    ...record,
    updatedAt: new Date(record.updatedAt),
  } as Lecture;
};
