import styles from "./styles.scss";

const TextLogo = () => {
  return (
    <div className={styles.root}>
      <img src="/images/logo.svg" className={styles.logoIcon} />
      <img src="/images/logo_txt.svg" className={styles.logoTxt} />
    </div>
  );
};

export default TextLogo;
