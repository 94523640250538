import { useInfiniteQuery } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import { createError, HTTPErrors } from "../../../errors";
import { paths } from "../../../lib/api/v1";
import ApiResponse from "../../../interfaces/ApiResponse";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";

type Props = {
  sectionId: string;
  studyTaskId: string;
};
export type StudyTaskStudentsResponse =
  paths["/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}/students"]["get"]["responses"]["200"]["content"]["application/json"]["students"]["data"];

const URL =
  "/api/v1/sections/{section_id}/study_tasks/{study_task_hashid}/students";

export const useFetchStudyTaskStudents = ({
  sectionId,
  studyTaskId,
}: Props) => {
  const { data, ...result } = useInfiniteQuery<
    ApiResponse<StudyTaskStudentsResponse>,
    HTTPErrors
  >({
    queryKey: [URL, sectionId, studyTaskId],
    queryFn: async ({ pageParam }) => {
      const { data, response } = await boronClient.GET(URL, {
        params: {
          path: {
            section_id: sectionId,
            study_task_hashid: studyTaskId,
          },
          query: {
            page: pageParam as number,
          },
        },
      });
      if (response.ok && data) {
        return {
          data: data.students.data,
          meta: data.students.meta,
        };
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false,
  });
  return {
    data: data?.pages.flatMap((page) => page.data),
    ...result,
  };
};
