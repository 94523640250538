import { useQuery } from "@tanstack/react-query";
import { ImportHistoryInterface } from "../../../domains/ImportHistory";
import { createError, HTTPErrors } from "../../../errors";
import { boronClient } from "../../../api";

export const useFetchImportHistory = ({
  sectionId,
  importHistoryId,
}: {
  sectionId: string;
  importHistoryId: string;
}) => {
  return useQuery<ImportHistoryInterface, HTTPErrors>({
    queryKey: ["import_history", sectionId, importHistoryId],
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/import_histories/{import_history_hashid}",
        {
          params: {
            path: {
              section_id: sectionId,
              import_history_hashid: importHistoryId,
            },
          },
        },
      );
      if (response.ok && data) {
        return data.importHistory;
      } else {
        throw await createError(response);
      }
    },
    refetchOnWindowFocus: false, // reduxだったときにはrefetchしていなかったので合わせる
  });
};
