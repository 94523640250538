import { useInfiniteQuery } from "@tanstack/react-query";
import ApiResponse from "../../interfaces/ApiResponse";
import ApiClient from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { SectionExamination } from "../../domains/SectionExamination";

type Props = {
  sectionId: string;
  organizerId: string | null;
  classificationId: string | null;
};
type ResponseData = {
  examinations: ApiResponse<SectionExamination[]>;
};

export const useFetchSectionExaminations = ({
  sectionId,
  organizerId,
  classificationId,
}: Props) => {
  const result = useInfiniteQuery<ResponseData["examinations"], HTTPErrors>({
    queryKey: cacheKeyOf(sectionId, organizerId, classificationId),
    queryFn: async ({ pageParam = 1 }) => {
      const response = await ApiClient.get(`${baseURLOf(sectionId)}`, {
        query: {
          page: pageParam,
          examination_organizer_hashid: organizerId,
          examination_classification_hashid: classificationId,
        },
        skipNullQuery: true,
      });
      if (response.ok) {
        const json = (await response.json()) as ResponseData;
        return json.examinations;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });
  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};

export const cacheKeyOf = (
  sectionId: string,
  organizerId: string | null,
  classificationId: string | null,
) =>
  [
    sectionId,
    "analytics",
    "examinations",
    organizerId,
    classificationId,
  ].filter((k) => k !== null);

export const baseURLOf = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/analytics/examinations`;
