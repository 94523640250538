import * as React from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import { LearningMaterialFilter, TagFilter } from "../LearningMaterialFilter";
import { useLearningMaterialFilterWithLocalStorageContext } from "../LearningMaterialFilter/useLearningMaterialFilterWithLocalStorageContext";
import { notSetTag } from "../../../domains/LearningMaterialTag";
import { useFetchLearningMaterialTags } from "../../../hooks/http/useLearningMaterialTagApi";
import { DefaultLearningMaterialTagFilterOptions } from "../../../containers/pages/StudentsLearningMaterialTagAnalyticsTimePage/constant";
import { DeprecatedTagButtonProps } from "../DeprecatedTagButton";
import { useLearningMaterialFilterContext } from "../../../containers/pages/StudentsLearningMaterialTagAnalyticsTimePage/useLearningMaterialFilterContext";

type UseAnalytisLearnigMaterialTagFilterProps = {
  context:
    | ReturnType<typeof useLearningMaterialFilterContext>
    | ReturnType<typeof useLearningMaterialFilterWithLocalStorageContext>;
  section: SectionInterface;
  defaultLearningMaterialTagFilterOptions?: DefaultLearningMaterialTagFilterOptions;
  isInitializeByQueryParams?: boolean;
  withNotSetTag?: boolean;
};

// Main hooks

const useAnalytisLearnigMaterialTagFilter = ({
  context,
  section,
  withNotSetTag,
  defaultLearningMaterialTagFilterOptions,
}: UseAnalytisLearnigMaterialTagFilterProps) => {
  const { data, isLoading } = useFetchLearningMaterialTags({
    section,
    narrowingCondition: "all",
    shouldGetAllRecords: true,
  });

  React.useEffect(() => {
    if (isLoading) return;

    context.setIsTagLoaded(true);
  }, [data]);

  const tags = [...(withNotSetTag ? [notSetTag] : []), ...(data || [])];

  let defaultOptions = undefined;
  if (defaultLearningMaterialTagFilterOptions) {
    defaultOptions = tags.filter((tag) => {
      return defaultLearningMaterialTagFilterOptions.find(
        (defaultOption) => tag.name === defaultOption.name,
      );
    });
  }
  return {
    tags,
    sectionId: section.id,
    defaultOptions,
  };
};

type Props = UseAnalytisLearnigMaterialTagFilterProps & {
  label?: string;
  colorType?: DeprecatedTagButtonProps["colorType"];
  variants?: DeprecatedTagButtonProps["variants"];
  withStudentFilter?: boolean;
  isUseLocalStorage?: boolean;
};
export const AnalyticsLearningMaterialTagFilter = (props: Props) => {
  const { tags, sectionId, defaultOptions } =
    useAnalytisLearnigMaterialTagFilter(props);

  const Filter = props.withStudentFilter ? TagFilter : LearningMaterialFilter;
  return (
    <Filter
      context={props.context}
      tags={tags}
      sectionId={sectionId}
      defaultOptions={defaultOptions}
      label={props.label}
      colorType={props.colorType}
      variants={props.variants}
      isInitializeByQueryParams={props.isInitializeByQueryParams}
      isUseLocalStorage={props.isUseLocalStorage}
    />
  );
};
