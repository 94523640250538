import { LoadSuccessPayload, DeleteSuccessPayload } from "./types";
import { ActionTypes } from "./types";
import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import ApiClient from "../../../api";
import { apiRequestError } from "../../apiRequestError";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../../flashMessage";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { buildMainResourceApiErrorAction } from "../../common/errors";

export const initializeSkills = createAction<void>(ActionTypes.INITIALIZE);

const startLoadingSkills = createAction<void>(ActionTypes.START_LOADING);

const loadSkillsSuccess = createAction<LoadSuccessPayload>(
  ActionTypes.LOAD_SUCCESS,
);

const loadSkillsFailure = createAction<void>(ActionTypes.LOAD_FAILURE);

interface SkillsParamsInterface {
  sectionId: string;
  page?: number;
}

export const loadSkills =
  (params: SkillsParamsInterface) =>
  (dispatch: Dispatch, getState: () => AppStateInterface) => {
    const { sectionsSettingsSkills } = getState();
    if (sectionsSettingsSkills.loading) {
      return;
    }

    dispatch(startLoadingSkills());

    const requestOptions = params.page
      ? { query: { page: params.page } }
      : undefined;

    ApiClient.interruptGet(
      `/api/v1/sections/${params.sectionId}/skills`,
      requestOptions,
    )
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: LoadSuccessPayload) => {
            dispatch(loadSkillsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(apiRequestError());
          dispatch(loadSkillsFailure());
        }
      })
      .catch(() => {
        dispatch(apiRequestError());
        dispatch(loadSkillsFailure());
      });
  };

const deleteSkillRequest = createAction<void>(ActionTypes.DELETE_REQUEST);
const deleteSkillSuccess = createAction<DeleteSuccessPayload>(
  ActionTypes.DELETE_SUCCESS,
);
const deleteSkillFailure = createAction<void>(ActionTypes.DELETE_FAILURE);
export const deleteSkill =
  (sectionId: string, skillId: string) => (dispatch: Dispatch) => {
    dispatch(deleteSkillRequest());
    ApiClient.delete(`/api/v1/sections/${sectionId}/skills/${skillId}`)
      .then((res: Response) => {
        if (res.ok) {
          dispatch(deleteSkillSuccess({ skillId }));
          dispatch(showSuccessFlashMessage("スキルを削除しました"));
        } else {
          dispatch(deleteSkillFailure());
          dispatch(showErrorFlashMessage("スキルを削除できませんでした"));
        }
      })
      .catch(() => {
        dispatch(apiRequestError());
        dispatch(showErrorFlashMessage("スキルを削除できませんでした"));
      });
  };

/**
 * スキルのオン/オフ
 */
const enableSkillSuccess = createAction<string>(ActionTypes.ENABLE_SUCCESS);
const enableSkillFailure = createAction<void>(ActionTypes.ENABLE_FAILURE);
export const enableSkill =
  (sectionId: string, skillId: string) =>
  (dispatch: Dispatch): Promise<boolean> => {
    return ApiClient.put(
      `/api/v1/sections/${sectionId}/skills/${skillId}/enabled`,
    )
      .then((res: Response) => {
        if (res.ok) {
          dispatch(enableSkillSuccess(skillId));
          dispatch(showSuccessFlashMessage("スキルを有効にしました"));
          return Promise.resolve(true);
        } else {
          dispatch(enableSkillFailure());
          dispatch(showErrorFlashMessage("スキルを有効にできませんでした"));
          return Promise.reject();
        }
      })
      .catch(() => {
        dispatch(enableSkillFailure());
        dispatch(showErrorFlashMessage("スキルを有効にできませんでした"));
        return Promise.reject();
      });
  };

const disableSkillSuccess = createAction<string>(ActionTypes.DISABLE_SUCCESS);
const disableSkillFailure = createAction<void>(ActionTypes.DISABLE_FAILURE);
export const disableSkill =
  (sectionId: string, skillId: string) =>
  (dispatch: Dispatch): Promise<boolean> => {
    return ApiClient.delete(
      `/api/v1/sections/${sectionId}/skills/${skillId}/enabled`,
    )
      .then((res: Response) => {
        if (res.ok) {
          dispatch(disableSkillSuccess(skillId));
          dispatch(showSuccessFlashMessage("スキルを無効にしました"));
          return Promise.resolve(false);
        } else {
          dispatch(disableSkillFailure());
          dispatch(showErrorFlashMessage("スキルを無効にできませんでした"));
          return Promise.reject();
        }
      })
      .catch(() => {
        dispatch(disableSkillFailure());
        dispatch(showErrorFlashMessage("スキルを無効にできませんでした"));
        return Promise.reject();
      });
  };
