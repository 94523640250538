import * as React from "react";
import { LearningMaterialTagAnalyticsHeatMap } from "../../../interfaces/StudentAnalyticsTableInterface";
import { MixedCheckValue } from "../AnalyticsTable";

/**
 * 選択中のアナリティクスのテーブルの行を管理する
 * - アナリティクステーブルで、左端のチェックボックスのカラムのチェックボックスの状態を保持
 * - 閲覧中の期間に返されたヒートマップの行がscoreが0かそれ以外かのフラグを管理する状態の保持
 * - アナリティクステーブルのヘッダーの全ての行を操作するチェックボックスの状態を管理
 *
 * 各レコードのチェックボックの状態を管理する`activeHeatMapRowRecord`
 * {[行のレコードid]: boolean}
 *
 * 現在閲覧中の期間で各レコードに学習履歴のスコアがない(=0)かを管理する `noScoreInSpecificTermRecord`
 * {[行のレコードid]: boolean}
 *
 * 行のチェックボックスを操作してから、アナリティクス内の日付送りボタンから期間を変えた場合
 * - 引き続き同じ教材タグ(行)に学習のスコアがあれば、保持しているチェックボックスの状態を反映させる
 * - チェックボックスを操作済みの教材タグ(行)でも、その期間学習スコアが0なら、そのレコードはdisabledにして横線チェックボックスを表示させる
 *
 * ヒートマップの書く行のチェックボックスの仕様
 * noScoreInSpecificTermRecord[id] が true => 横線のチェックボックス
 * noScoreInSpecificTermRecord[id] が false かつ activeHeatMapRowRecord[id] がtrue => "indeterminate" => チェックボックスon
 * noScoreInSpecificTermRecord[id] が false かつ activeHeatMapRowRecord[id] がfalse => "indeterminate" => チェックボックスoff
 *
 * ヘッダのチェックボックスの仕様(`headerCheckboxChecked`)
 * noScoreInSpecificTermRecord[id] が true を除いた全ての行のnoScoreInSpecificTermRecord[id]がtrue => 全てにチェックが入っているとみなし、ヘッダのチェックボックスをon (true)
 * noScoreInSpecificTermRecord[id] が true を除いた全ての行のnoScoreInSpecificTermRecord[id]がfalse => 全てにチェックが入っているとみなし、ヘッダのチェックボックスをoff (false)
 * noScoreInSpecificTermRecord[id] が true を除いた行のnoScoreInSpecificTermRecord[id]がtrueとfalse混在 => ヘッダのチェックボックスを横線チェックボックスに (mixed)
 */

export type ActiveHeatMapRowRecord = { [id: string]: boolean };

const useActiveHeatMapRowMap = () => {
  const [activeHeatMapRowRecord, setActiveHeatMapRowRecord] =
    React.useState<ActiveHeatMapRowRecord>({});

  const [noScoreInSpecificTermRecord, setNoScoreInSpecificTermRecord] =
    React.useState<ActiveHeatMapRowRecord>({});

  const inactivate = React.useCallback((id: string) => {
    setActiveHeatMapRowRecord((previous) => ({ ...previous, [id]: false }));
  }, []);

  const inactivateAll = React.useCallback(() => {
    setActiveHeatMapRowRecord((previous) =>
      Object.keys(previous).reduce((p, id) => ({ ...p, [id]: false }), {}),
    );
  }, []);

  const activateAll = React.useCallback(() => {
    setActiveHeatMapRowRecord((previous) =>
      Object.keys(previous).reduce((p, id) => ({ ...p, [id]: true }), {}),
    );
  }, []);

  const activate = React.useCallback((id: string) => {
    setActiveHeatMapRowRecord((previous) => ({ ...previous, [id]: true }));
  }, []);

  /**
   * 各レコードのチェックボックス状態から、全てon/全てoff/混在 を判定する
   */
  const headerCheckboxChecked = React.useMemo<MixedCheckValue>(() => {
    const aggregated = Object.keys(activeHeatMapRowRecord).reduce(
      (p, c) => {
        // その期間にスコアがゼロのものは必ずdisabledなので判定を飛ばす
        if (noScoreInSpecificTermRecord[c]) {
          return { ...p };
        }
        // activeCount = checkされている行のカウント
        // allCount = 行自体のカウント
        return {
          activeCount: activeHeatMapRowRecord[c]
            ? p.activeCount + 1
            : p.activeCount,
          allCount: p.allCount + 1,
        };
      },
      { activeCount: 0, allCount: 0 },
    );
    if (aggregated.activeCount === 0 && aggregated.allCount > 0) return false;
    if (
      aggregated.activeCount === aggregated.allCount &&
      aggregated.allCount > 0
    )
      return true;
    return "indeterminate";
  }, [activeHeatMapRowRecord, noScoreInSpecificTermRecord]);

  return {
    activeHeatMapRowRecord,
    noScoreInSpecificTermRecord,
    headerCheckboxChecked,
    activate,
    inactivate,
    activateAll,
    inactivateAll,
    setActiveHeatMapRowRecord,
    setNoScoreInSpecificTermRecord,
  };
};

type UseActiveHeatMapRowState = ReturnType<typeof useActiveHeatMapRowMap>;

/**
 * `activeHeatMapRowMap`, `headerCheckboxChecked` を管理するContext
 */
type ActiveHeatMapRowMapValuesContext = Pick<
  UseActiveHeatMapRowState,
  | "activeHeatMapRowRecord"
  | "headerCheckboxChecked"
  | "noScoreInSpecificTermRecord"
>;
const ActiveHeatMapRowMapValuesContext = React.createContext<
  ActiveHeatMapRowMapValuesContext | undefined
>(undefined);

export const useActiveHeatMapRowMapValues = () => {
  const value = React.useContext(ActiveHeatMapRowMapValuesContext);
  if (!value) {
    throw new Error(
      "This hooks should be called in descendant elements of the Provider.",
    );
  }
  return value;
};

/**
 * 更新系のメソッドを管理するContext
 */
type ActiveHeatMapRowMapDispatchersContext = Omit<
  UseActiveHeatMapRowState,
  | "activeHeatMapRowRecord"
  | "headerCheckboxChecked"
  | "noScoreInSpecificTermRecord"
>;
const ActiveHeatMapRowMapDispatchersContext = React.createContext<
  ActiveHeatMapRowMapDispatchersContext | undefined
>(undefined);

export const useActiveHeatMapRowMapDispatchers = () => {
  const dispatchers = React.useContext(ActiveHeatMapRowMapDispatchersContext);
  if (!dispatchers) {
    throw new Error(
      "This hooks should be called in descendant elements of the Provider.",
    );
  }
  return dispatchers;
};

type Props = {
  children: React.ReactNode;
};

/**
 * ActiveHeatMapRowMap を返すコールバックから、まるっと`activeHeatMapRowMap` のデータを更新する
 * これが必要な理由は
 * - 初期時は全てがon
 * - ページングがかかった時に既存の`activeHeatMapRow`のデータは残しつつ、ページングで新しく取得したレコード分を全てonにしてマージする必要がある
 * の一括処理が必要なため
 */
export const useUpdateActiveHeatMapRowMapWithDeps = (
  learningMaterialTags:
    | LearningMaterialTagAnalyticsHeatMap["learningMaterialTags"]
    | null,
) => {
  const dispatcher = React.useContext(ActiveHeatMapRowMapDispatchersContext);
  if (!dispatcher) {
    throw new Error("The context value must not be undefined");
  }
  React.useEffect(() => {
    if (learningMaterialTags) {
      // ページング・期間変更でもチェックボックスの値を保持するように、APIから新しいデータを受け取ったら、stateの内容を置換せずに既存の内容とマージする
      dispatcher.setActiveHeatMapRowRecord((previous) => {
        return {
          ...previous,
          ...learningMaterialTags.reduce<ActiveHeatMapRowRecord>((p, c) => {
            // 新規のレコードは一旦trueにする
            return typeof previous[c.id] === "undefined"
              ? {
                  ...p,
                  [c.id]: true,
                }
              : { ...p };
          }, {}),
        };
      });
      dispatcher.setNoScoreInSpecificTermRecord((previous) => ({
        ...previous,
        ...learningMaterialTags.reduce(
          (p, c) => ({ ...p, [c.id]: c.totalScore === 0 }),
          {},
        ),
      }));
    }
  }, [learningMaterialTags]);
};

/**
 * ContextのProvider
 */
export const Provider = ({ children }: Props) => {
  const {
    activeHeatMapRowRecord,
    headerCheckboxChecked,
    noScoreInSpecificTermRecord,
    ...dispatchers
  } = useActiveHeatMapRowMap();
  const valuesProps = React.useMemo(
    () => ({
      activeHeatMapRowRecord,
      noScoreInSpecificTermRecord,
      headerCheckboxChecked,
    }),
    [activeHeatMapRowRecord, headerCheckboxChecked],
  );

  const dispatchersProps = React.useMemo<ActiveHeatMapRowMapDispatchersContext>(
    () => ({
      ...dispatchers,
    }),
    [
      dispatchers.activate,
      dispatchers.activateAll,
      dispatchers.inactivate,
      dispatchers.inactivateAll,
      dispatchers.setActiveHeatMapRowRecord,
    ],
  );
  return (
    <ActiveHeatMapRowMapValuesContext.Provider value={valuesProps}>
      <ActiveHeatMapRowMapDispatchersContext.Provider value={dispatchersProps}>
        {children}
      </ActiveHeatMapRowMapDispatchersContext.Provider>
    </ActiveHeatMapRowMapValuesContext.Provider>
  );
};
