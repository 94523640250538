import { SingleDatePickerField } from "../../atoms/SingleDatePickerField";

type Props = {
  daysDuration: number;
  startDate: Date;
  setStartDate: (date: Date) => void;
  calendarFromDate: Date;
  calendarToDate: Date;
};

export const MultiSectionBatchesDownloadDateRange = ({
  daysDuration,
  startDate,
  setStartDate,
  calendarFromDate,
  calendarToDate,
}: Props) => {
  return (
    <div className="flex flex-col gap-8 rounded-lg bg-[#fafafa] px-10 py-[22px]">
      <p>2. 出力したい期間を選択してください</p>
      <div className="flex flex-col gap-4">
        <p className="font-bold">開始日</p>
        <div className="flex items-center gap-8">
          <SingleDatePickerField
            className="w-80 bg-white"
            value={startDate}
            onDateChange={setStartDate}
            calendarFromDate={calendarFromDate}
            calendarToDate={calendarToDate}
          />
          <p className="font-bold">から{daysDuration}日間</p>
        </div>
      </div>
    </div>
  );
};
