import * as React from "react";
import { Modal } from "../../components/atoms/Modal";
import { StudentTagForm } from "../../components/features/StudentTagModalBody";
import { OptionType } from "../../components/features/LoadableStudentsMultiSelect";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import { IconHelpSupport } from "@studyplus/boron-ui";

type Props = ReturnType<typeof useStudentTagModal> & {
  onChange: (values: ReadonlyArray<OptionType> | null | undefined) => void;
  selectedStudents: ReadonlyArray<OptionType> | null;
};

export const StudentTagModal = ({
  isOpen,
  closeModal,
  onChange,
  selectedStudents,
}: Props) => {
  const StudentTagHelp = () => {
    return (
      <PopoverProvider>
        <Popover>
          <PopoverTrigger>
            <IconHelpSupport className="h-[1.4rem] w-[1.4rem] text-gray-800" />
          </PopoverTrigger>

          <PopoverPortal>
            <PopoverContent side="top">
              <div className="text-center">
                課題配信を設定した後に、
                <br />
                配信先の登録に使用した生徒タグに生徒を
                <br />
                追加しても配信の対象には含まれません
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </PopoverProvider>
    );
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <Modal.Header onClose={closeModal}>配信先対象生徒の選択</Modal.Header>
      <Modal.Body>
        <StudentTagForm
          defaultValue={selectedStudents}
          onChange={onChange}
          submitCallback={closeModal}
          filterOptions={{
            billing_plan: "pro",
            status: ["preinviting", "inviting", "active"],
          }}
          studentTagHelpComponent={<StudentTagHelp />}
        />
      </Modal.Body>
    </Modal>
  );
};

export const useStudentTagModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    openModal,
    closeModal,
  };
};
