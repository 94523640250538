import * as React from "react";
import truncate from "lodash-es/truncate";
import styles from "./styles.scss";
import styleVars from "../../../styles/variables.scss";
import { Link } from "react-router-dom";
import StudentInterface from "../../../interfaces/StudentInterface";
import { FormikProps, useFormik, FormikHelpers } from "formik";
import validationSchema from "./validationSchema";
import { Button } from "@studyplus/boron-ui";
import { postKarte } from "../../../actions/studentKartes";
import { connect, HandleThunkActionCreator } from "react-redux";
import InputIcon from "../../atoms/InputIcon";
import DeprecatedTagButton from "../../features/DeprecatedTagButton/index";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { KarteTemplateInterface } from "../../../interfaces/KarteTemplateInterface";
import Select from "react-select";
import { OnChangeValue } from "react-select";
import classNames from "classnames";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import Icon from "../../atoms/Icon/index";
import ResumableTextarea from "../../atoms/ResumableTextarea";
import { useCallback, useEffect, useRef } from "react";
import Input from "../../atoms/Input";
import {
  generateStorageKey,
  useResumableTextarea,
} from "../../atoms/ResumableTextarea/useResumableTextarea";
import { MainSection } from "../../atoms/MainSection";

interface Props {
  student: StudentInterface;
  postKarte: HandleThunkActionCreator<typeof postKarte>;
  apiErrors: ApiErrorInterface[];
  karteTemplates: KarteTemplateInterface[];
  operator: OperatorInterface | null;
  submitting: boolean;
}

interface Values {
  name: string;
  comment: string;
  karteImageAttachments?: File[];
  karteAttachments?: File[];
}

const templateOptions = (karteTemplates: KarteTemplateInterface[]) => {
  return karteTemplates.map((template) => {
    return {
      label: template.name,
      value: template.template,
    };
  });
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: "4.6rem",
    border: `1px solid ${styleVars.colorGrayLighten1}`,
  }),
};

const StudentKarteForm: React.FC<Props> = (props) => {
  const prevSubmitting = useRef(props.submitting);

  const values = mapPropsToValues(props);

  const { clearValue: clearCommentStorage, ...resumableTextareaProps } =
    useResumableTextarea({
      value: values.comment,
      key: generateStorageKey(["kartes", props.student.id, "new"]),
      confirmMessage:
        "編集途中のカルテを復元しますか？\n（「キャンセル」を選択すると復元内容が破棄されます。）",
    });

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const { postKarte, student } = props;
      postKarte(student.id, valuesToParams(values), () => {
        clearCommentStorage();
        formikHelpers.resetForm();
      });
    },
    [props.postKarte, props.student, clearCommentStorage],
  );

  const formik = useFormik<Values>({
    initialValues: values,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const { isResumed, resumedValue } = resumableTextareaProps;
    if (isResumed && resumedValue) {
      formik.getFieldHelpers("comment").setValue(resumedValue);
    }
  }, [resumableTextareaProps.isResumed, resumableTextareaProps.resumedValue]);

  useEffect(() => {
    if (prevSubmitting.current && !props.submitting) {
      formik.setSubmitting(false);
    }
    prevSubmitting.current = props.submitting;
  }, [props.submitting]);

  return (
    <div className={styles.root} aria-label="カルテ投稿フォーム">
      <MainSection>
        <div className={styles.setting}>
          <Link
            to={`/sections/${props.student.section.id}/settings/karte_templates`}
            className={styles.setting__link}
          >
            <Icon name="icon-karte" className={styles.setting__icon} />
            設定
          </Link>
        </div>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.header}>
            <div className={styles.staffName}>
              <label
                htmlFor={"StudentKarteForm-staffName-input"}
                className={styles.label}
              >
                スタッフ名
              </label>
              {renderStaffNameError(formik)}
              <Input
                id="StudentKarteForm-staffName-input"
                name="name"
                className={classNames(styles.staffName__input, {
                  [styles.fieldError]: shouldDisplayError(formik, "name"),
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>

            <div className={styles.template}>
              <label
                htmlFor={"StudentKarteForm-template-options"}
                className={styles.label}
              >
                テンプレート
              </label>
              <br />
              <Select
                options={templateOptions(props.karteTemplates)}
                placeholder="テンプレートを選択"
                onChange={handleChangeTemplate(formik)}
                isSearchable={true}
                noOptionsMessage={() => ""}
                styles={customStyles}
              />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.comment}>
              <label
                htmlFor={"StudentKarteForm-comment-textarea"}
                className={styles.label}
              >
                コメント
              </label>
              {renderCommentError(formik)}
              <ResumableTextarea
                id="StudentKarteForm-comment-textarea"
                name="comment"
                className={classNames(styles.comment__input, {
                  [styles.fieldError]: shouldDisplayError(formik, "comment"),
                })}
                rows={3}
                placeholder="コメントを入力"
                value={formik.values.comment}
                onChange={formik.handleChange}
                {...resumableTextareaProps}
              />
            </div>
          </div>

          {renderApiErrors(props)}

          <div className={styles.footer}>
            {renderAttachments(props, formik)}
            <Button type="submit" disabled={props.submitting}>
              登録
            </Button>
          </div>
        </form>
      </MainSection>
    </div>
  );
};

const renderAttachments = (props: Props, formik: FormikProps<Values>) => {
  if (props.student.canCreateKarteAttachment) {
    return (
      <React.Fragment>
        <div className={styles.attachments}>
          {renderImageNames(formik)}
          {renderAttachmentNames(formik)}
        </div>
        <InputIcon
          iconName="icon-picture-img"
          inputAttributes={{
            accept: "image/jpeg,image/gif,image/png",
            id: "karte_image_attachments_attributes",
            type: "file",
          }}
          className={styles.footer__icon}
          tooltipLabel="画像の追加( jpg,png,gif )"
          onChange={handleChangeImageAttachment(formik)}
        />
        <InputIcon
          iconName="icon-file"
          inputAttributes={{
            id: "karte_attachments_attributes",
            type: "file",
          }}
          className={styles.footer__icon}
          tooltipLabel="ファイルの追加( PDF,Word,Excel,PPT )"
          onChange={handleChangeAttachment(formik)}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const renderAttachmentNames = (formik: FormikProps<Values>) => {
  const { karteAttachments } = formik.values;

  if (karteAttachments) {
    return karteAttachments.map((attachment: File, i: number) => {
      return (
        <div
          className={styles.filename}
          key={`StudentKarteForm-attachmentNames-${i}`}
        >
          <DeprecatedTagButton
            label={truncateFilename(attachment.name, 30)}
            iconName="icon-close-x"
            theme="lightgray"
            onClick={handleAttachmentDelete(formik, i)}
          />
        </div>
      );
    });
  }
};

const renderImageNames = (formik: FormikProps<Values>) => {
  const { karteImageAttachments } = formik.values;

  if (karteImageAttachments) {
    return karteImageAttachments.map((imageAttachment: File, i: number) => {
      return (
        <div
          className={styles.filename}
          key={`StudentKarteForm-imageNames-${i}`}
        >
          <DeprecatedTagButton
            label={truncateFilename(imageAttachment.name, 30)}
            iconName="icon-close-x"
            theme="lightgray"
            onClick={handleImageAttachmentDelete(formik, i)}
          />
        </div>
      );
    });
  }
};

const renderApiErrors = (props: Props) => {
  return (
    <div className={styles.apiErrors}>
      {props.apiErrors.map((error: ApiErrorInterface, i: number) => {
        return (
          <p
            key={`StudentKarteForm-apiErrors-${i}`}
            className={styles.apiErrors__error}
          >
            {error.message}
          </p>
        );
      })}
    </div>
  );
};

const renderStaffNameError = (formik: FormikProps<Values>) => {
  if (shouldDisplayError(formik, "name")) {
    return <p className={styles.staffName__error}>{formik.errors.name}</p>;
  } else {
    return null;
  }
};

const renderCommentError = (formik: FormikProps<Values>) => {
  if (shouldDisplayError(formik, "comment")) {
    return <p className={styles.staffName__error}>{formik.errors.comment}</p>;
  } else {
    return null;
  }
};

const handleAttachmentDelete =
  (formik: FormikProps<Values>, index: number) => () => {
    const { setFieldValue } = formik;
    if (formik.values.karteAttachments) {
      formik.values.karteAttachments.splice(index, 1);
      setFieldValue(`karteAttachments`, formik.values.karteAttachments);
    }
  };

const handleImageAttachmentDelete =
  (formik: FormikProps<Values>, index: number) => () => {
    const { setFieldValue } = formik;
    if (formik.values.karteImageAttachments) {
      formik.values.karteImageAttachments.splice(index, 1);
      setFieldValue(
        `karteImageAttachments`,
        formik.values.karteImageAttachments,
      );
    }
  };

const handleChangeAttachment =
  (formik: FormikProps<Values>) => (e: React.ChangeEvent<any>) => {
    const file = e.currentTarget.files[0];
    const currentIndex = formik.values.karteAttachments
      ? formik.values.karteAttachments.length
      : 0;
    formik.setFieldValue(`karteAttachments[${currentIndex}]`, file);
  };

const handleChangeTemplate =
  (formik: FormikProps<Values>) => (value: OnChangeValue<any, any>) => {
    if (!formik.values.comment) {
      formik.setFieldValue("comment", value.value);
      return;
    }

    if (window.confirm("コメント欄を上書きしてもよろしいですか？")) {
      formik.setFieldValue("comment", value.value);
    }
  };

const handleChangeImageAttachment =
  (formik: FormikProps<Values>) => (e: React.ChangeEvent<any>) => {
    const file = e.currentTarget.files[0];
    const currentIndex = formik.values.karteImageAttachments
      ? formik.values.karteImageAttachments.length
      : 0;
    formik.setFieldValue(`karteImageAttachments[${currentIndex}]`, file);
  };

const mapPropsToValues = (props: Props): Values => {
  return {
    name: props.operator ? props.operator.fullName : "",
    comment: "",
  };
};

const valuesToParams = (values: Values): any => {
  const params: any = {
    "karte[name]": values.name,
    "karte[comment]": values.comment,
  };

  if (values.karteImageAttachments) {
    values.karteImageAttachments.forEach((imageAttachment: File, i: number) => {
      params[`karte[karte_image_attachments_attributes][${i}][file]`] =
        imageAttachment;
    });
  }

  if (values.karteAttachments) {
    values.karteAttachments.forEach((attachment: File, i: number) => {
      params[`karte[karte_attachments_attributes][${i}][file]`] = attachment;
    });
  }

  return params;
};

const shouldDisplayError = (
  formik: FormikProps<Values>,
  key: string,
): boolean => {
  const meta = formik.getFieldMeta(key);
  const { touched, error } = meta;

  return touched && !!error;
};

const truncateFilename = (filename: string, length: number): string => {
  if (filename.length <= length) {
    return filename;
  }

  const extension = filename
    .substring(filename.lastIndexOf(".") + 1, filename.length)
    .toLowerCase();
  const filenameWithoutExt = filename.replace("." + extension, "");
  const truncatedFileName = truncate(filenameWithoutExt, {
    length: length - extension.length,
  });

  return truncatedFileName + extension;
};

const mapStateToProps = (state: AppStateInterface) => {
  return {
    operator: state.session.currentOperator,
  };
};

const actions = {
  postKarte,
};

export default connect(mapStateToProps, actions)(StudentKarteForm);
