import ApiResponse, { CursorMetaData } from "./ApiResponse";
import StudyRecordInterface from "./StudyRecordInterface";
import StudentInterface from "./StudentInterface";

export enum TimelineType {
  TIMELINE_SUMMARY,
  TIMELINE_REALTIME,
  TIMELINE_EXAMINATION,
}

export interface TimelineStateInterface
  extends ApiResponse<StudyRecordInterface[] | null, CursorMetaData> {
  loading: boolean;
  hasMore: boolean;
}

export interface TimelineSummaryInterface extends StudentInterface {
  studyRecords: StudyRecordInterface[];
}

export interface TimelineSummaryStateInterface
  extends ApiResponse<TimelineSummaryInterface[] | null> {
  loading: boolean;
}
