import { KarteTemplateInterface } from "../interfaces/KarteTemplateInterface";

const karteTemplates = (
  state: KarteTemplateInterface[] = [],
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_KARTE_TEMPLATES_SUCCESS":
      return action.payload.karteTemplates;

    default:
      return state;
  }
};

export default karteTemplates;
