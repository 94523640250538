import { format } from "date-fns";
import ApiClient from "../../../api";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

type Props = {
  batchId: number;
  datetimeFrom: string;
  fileNamePrefix: string;
};

export const useDownloadZipFile = ({
  batchId,
  datetimeFrom,
  fileNamePrefix,
}: Props) => {
  const { showErrorMessage } = useFlashMessage();

  const downloadZipFile = async () => {
    try {
      const url = `/api/v1/sections/multi_section_batch_histories/${batchId}/download`;
      const response = await ApiClient.get(url);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");

      downloadLink.style.display = "none";
      downloadLink.href = blobUrl;
      downloadLink.download = `${fileNamePrefix}_${format(
        new Date(datetimeFrom),
        "yyyyMMdd",
      )}.zip`;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      // 不要なリソースを削除
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
    } catch (_) {
      showErrorMessage("ダウンロードできませんでした");
    }
  };

  return {
    downloadZipFile,
  };
};
