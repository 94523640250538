import { useStudentFilterContext } from "../../../components/features/NewStudentFilter/useStudentFilterContext";
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../../errors";
import { boronClient } from "../../../api";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useCallback } from "react";
import { paths } from "../../../lib/api/v1";
import { StudentFilterParams } from "../../../helpers/FiltersHelper";

type Response =
  paths["/api/v1/sections/{section_id}/message_threads"]["get"]["responses"]["200"]["content"]["application/json"]["messageThreads"];

const buildQueryKey = (
  sectionId: string,
  requestParams?: StudentFilterParams,
) => {
  if (!requestParams) {
    return ["/api/v1/sections/{section_id}/message_threads", sectionId];
  }

  return [
    "/api/v1/sections/{section_id}/message_threads",
    sectionId,
    requestParams,
  ];
};

export const useFetchMessageThreads = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const { getStudentFilterParams } = useStudentFilterContext();
  const requestParams = getStudentFilterParams();

  const { data, error, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery<Response, HTTPErrors>({
      queryKey: buildQueryKey(sectionId, requestParams),
      queryFn: async ({ pageParam }) => {
        const { response, data } = await boronClient.GET(
          "/api/v1/sections/{section_id}/message_threads",
          {
            params: {
              path: {
                section_id: sectionId,
              },
              query: {
                ...requestParams,
                page: pageParam as number,
              },
            },
          },
        );
        if (response.ok && data) {
          return data.messageThreads;
        }
        throw await createError(response);
      },
      initialPageParam: 1,
      getNextPageParam,
      refetchOnWindowFocus: false, // reduxだったときにはrefetchしていなかったので合わせる
    });
  useQueryError(error);

  const messageThreads = data?.pages.flatMap((page) => page.data) || [];

  return {
    messageThreads,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  };
};

export const useUpdateMessageThreadLatestMessage = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const queryClient = useQueryClient();
  const updateMessageThreadLatestMessage = useCallback(
    (messageThreadId: string, messageThread: Partial<Response["data"][0]>) => {
      queryClient.setQueriesData<InfiniteData<Response>>(
        { queryKey: buildQueryKey(sectionId) },
        (data) => {
          if (!data) {
            return data;
          }

          return {
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              data: page.data.map((currentMessageThread) =>
                currentMessageThread.id === messageThreadId
                  ? {
                      ...currentMessageThread,
                      ...messageThread,
                    }
                  : currentMessageThread,
              ),
            })),
          };
        },
      );
    },
    [sectionId, queryClient],
  );

  return { updateMessageThreadLatestMessage };
};
