export type ContentCourseResult = {
  id: string;
  name: string;
  studentCount: number;
  startAt: Date | null;
  endAt: Date | null;
};

export const fromJson = (
  contentCourseResult: Record<string, any>,
): ContentCourseResult => ({
  id: contentCourseResult.id,
  name: contentCourseResult.name,
  studentCount: contentCourseResult.studentCount,
  startAt: contentCourseResult.startAt && new Date(contentCourseResult.startAt),
  endAt: contentCourseResult.endAt && new Date(contentCourseResult.endAt),
});
