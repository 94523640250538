import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../errors";

type OkResponse = {
  ok: true;
} & Response;
type ErrorResponse = {
  ok: false;
} & Response;

type FetchResponseData<TData> = {
  response: OkResponse | ErrorResponse;
  data?: TData;
};

// boronClientを使ったmutationを行うhooks
// 結果に合わせて、FSHTTPErrorをthrowする
export const useBoronMutation = <
  TData = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: (variables: TVariables) => Promise<FetchResponseData<TData>>,
  options?: Omit<
    UseMutationOptions<TData, HTTPErrors, TVariables, TContext>,
    "mutationFn"
  >,
): UseMutationResult<TData, HTTPErrors, TVariables, TContext> => {
  return useMutation<TData, HTTPErrors, TVariables, TContext>({
    mutationFn: async (variables) => {
      return await handleBoronClientResult(mutationFn(variables));
    },
    ...options,
  });
};

const handleBoronClientResult = async <TData>(
  mutationFn: Promise<FetchResponseData<TData>>,
): Promise<TData> => {
  const result = await mutationFn;
  if (result.response.ok) {
    return result.data as TData;
  } else {
    throw await createError(result.response);
  }
};
