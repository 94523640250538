import styles from "./styles.scss";
import Time from "../../../components/atoms/Time";

type Props = {
  hidden: boolean;
  seconds: number;
};
export const AnalyticsTotalHeatMapScore = ({ seconds, hidden }: Props) => (
  <div className={styles.root} hidden={hidden}>
    Total:
    <Time seconds={seconds} />
  </div>
);
