import styles from "./styles.scss";
import { TeacherForm } from "./forms/TeacherForm";
import { Modal } from "../../../components/atoms/Modal/index";
import { useFetchTeacherWithId } from "./useTeachersApi";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { createEditURLAction } from "../../../hooks/useDetermineURLAction";

type UseSectionSettingsTeacherEditProps = {
  sectionId: string;
  teacherId: string;
};
const useSectionSettingsTeacherModal = ({
  sectionId,
  teacherId,
}: UseSectionSettingsTeacherEditProps) => {
  const urlAction = createEditURLAction({ teacherId: teacherId });

  const { data: editingTeacher, error } = useFetchTeacherWithId({
    sectionId,
    teacherId,
  });
  useQueryError(error);

  const teacherFormProps = {
    urlActionType: urlAction.type,
    editingTeacher,
  };

  return {
    teacherFormProps,
  };
};

type Props = {
  sectionId: string;
  teacherId: string;
  isOpen: boolean;
  closeModal: () => void;
};
const TeacherEditModal = ({
  sectionId,
  teacherId,
  isOpen,
  closeModal,
}: Props) => {
  const { teacherFormProps } = useSectionSettingsTeacherModal({
    sectionId: sectionId,
    teacherId: teacherId,
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.modalContainer}
    >
      <Modal.Header onClose={closeModal}>講師を編集</Modal.Header>
      <Modal.Body>
        <TeacherForm
          sectionId={sectionId}
          closeModal={closeModal}
          {...teacherFormProps}
        />
      </Modal.Body>
    </Modal>
  );
};

export default TeacherEditModal;
