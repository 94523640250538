import { IconLock } from "@studyplus/boron-ui";

const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/9153866";

type Props = {
  href?: string;
  description?: string;
  iconClassName?: string;
};
export const AnalyticsFreePlanNotice = ({
  href = HELP_URL,
  description = "にすると全ての学習時間の閲覧ができます。",
  iconClassName = "h-3 w-3",
}: Props) => (
  <p className="flex items-center justify-center gap-1 text-center text-gray-800">
    <IconLock
      className={`inline-block shrink-0 grow-0 text-gray-600 ${iconClassName}`}
    />
    <span>
      <a
        href={href}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        プロプラン
      </a>
      {description}
    </span>
  </p>
);
