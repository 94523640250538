import ErrorTemplate from "../../../components/templates/ErrorTemplate/index";

type Props = {
  hideButton?: boolean;
};
const UnknownErrorPage = (props: Props) => {
  return (
    <ErrorTemplate
      title={"エラーが発生しました"}
      heading={"エラーが発生しました"}
      hideButton={props.hideButton}
    >
      <p>
        時間をおいて再度お試しください。
        <br />
        解決しない場合は、お手数ですがお問い合わせください。
      </p>
    </ErrorTemplate>
  );
};

export default UnknownErrorPage;
