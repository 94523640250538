import * as React from "react";
import StudentInterface from "../../interfaces/StudentInterface";
import TabsNavi from "../molecules/TabsNavi";
import { StudentAnalyticsType } from "../../interfaces/AnalyticsTableInterface";
import { usePushHistory } from "../../hooks/usePushHistory";
import { useEffectAvoidFirst } from "../../hooks/useEffectAvoidFirst";
import { useLearningMaterialFilterContext } from "../../containers/pages/StudentsLearningMaterialTagAnalyticsTimePage/useLearningMaterialFilterContext";

type Props = {
  student: StudentInterface;
  activeAnalyticsType: StudentAnalyticsType;
};
export const StudentAnalyticsTypeTab = ({
  student,
  activeAnalyticsType,
}: Props) => {
  const { searchQueryWithLearningMaterialFilter } =
    useStudentAnalyticsSearchQuery();

  return (
    <TabsNavi
      tabs={[
        {
          label: "学習記録",
          link: {
            pathname: `/students/${student.id}/analytics/time`,
            search: searchQueryWithLearningMaterialFilter,
          },
          active:
            activeAnalyticsType === "time" || activeAnalyticsType === "amount",
        },
        {
          label: "学習定着",
          link: {
            pathname: `/students/${student.id}/analytics/retention_level`,
            search: searchQueryWithLearningMaterialFilter,
          },
          active: activeAnalyticsType === "retentionLevel",
        },
        {
          label: "成績記録",
          link: {
            pathname: `/students/${student.id}/analytics/examination`,
            search: searchQueryWithLearningMaterialFilter,
          },
          active: activeAnalyticsType === "examination",
        },
        {
          label: "入退室記録",
          link: {
            pathname: `/students/${student.id}/analytics/stay`,
            search: searchQueryWithLearningMaterialFilter,
          },
          active: activeAnalyticsType === "stay",
        },
        {
          label: "出席記録",
          link: {
            pathname: `/students/${student.id}/analytics/lecture_attendance`,
            search: searchQueryWithLearningMaterialFilter,
          },
          active: activeAnalyticsType === "lectureAttendance",
        },
      ]}
    />
  );
};

export const useStudentAnalyticsSearchQuery = () => {
  const context = useLearningMaterialFilterContext();
  const selectedTagIds = context.items
    ?.filter((tag) => tag.id !== "have_no_tag")
    .map((tag) => tag.id);
  const haveNoTag = context.items?.some((tag) => tag.id === "have_no_tag");

  const { buildQuery } = usePushHistory();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  React.useEffect(() => {
    setSearchQuery("");
  }, []);
  useEffectAvoidFirst(() => {
    setSearchQuery(
      buildQuery({
        learning_material_tag_ids: selectedTagIds,
        have_no_tag: haveNoTag ? haveNoTag.toString() : undefined,
      }),
    );
  }, [selectedTagIds, haveNoTag]);

  return {
    searchQueryWithLearningMaterialFilter: searchQuery,
  };
};
