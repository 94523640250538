import * as React from "react";
import {
  OptionType,
  SelectWrapper,
  SelectWrapperProps,
} from "../../general/SelectWrapper";
import { useFetchExaminationClassifications } from "../../../hooks/http/useFetchExaminationClassifications";
import { ExaminationClassification } from "../../../domains/ExaminationClassification";
import { OnChangeValue } from "react-select";

type OverrideSelectProps = {
  size?: SelectWrapperProps["size"];
  fontSize?: SelectWrapperProps["fontSize"];
  placeholder?: SelectWrapperProps["placeholder"];
  sectionId?: string;
  studentId?: string;
  isDisabled?: boolean;
  value: string | null;
};
type Props = {
  onChange: (value: string | null) => void;
  organizerId: string | null;
  hasError?: boolean;
} & OverrideSelectProps;

export const ExaminationClassificationSelect = (props: Props) => {
  const { options, isDisabled, isLoadingOptions, onChange, selectedOption } =
    useExaminationClassificationSelect({
      sectionId: props.sectionId,
      studentId: props.studentId,
      onChange: props.onChange,
      organizerId: props.organizerId,
      value: props.value,
    });

  return (
    <SelectWrapper
      aria-label="試験の種類を選択"
      value={selectedOption}
      options={options}
      size={props.size}
      fontSize={props.fontSize}
      onChange={onChange}
      placeholder={props.placeholder ?? "試験の種類を選択"}
      isLoading={isLoadingOptions}
      isDisabled={props.isDisabled || isDisabled}
      isClearable
      hasError={props.hasError}
    />
  );
};

const useExaminationClassificationSelect = (
  props: Pick<
    Props,
    "onChange" | "organizerId" | "value" | "sectionId" | "studentId"
  >,
) => {
  const isOrganizerSelected = Boolean(props.organizerId);
  const { data: classifications, isFetching } =
    useFetchExaminationClassifications({
      sectionId: props.sectionId,
      studentId: props.studentId,
      organizerId: props.organizerId,
      enabled: isOrganizerSelected,
    });

  const options = classifications
    ? classifications.map((o) => toOption(o))
    : [];

  const onChange = (option?: OnChangeValue<OptionType, false>) => {
    const value = option ? (option.value as string) : null;
    props.onChange(value);
  };

  // props.organizerIdが変わったときに、選択状態をリセットする
  // (初期値から最初の値が入ったときは除く)
  const prevOrganizerId = React.useRef<string | null>(props.organizerId);
  React.useEffect(() => {
    if (prevOrganizerId.current !== null) {
      props.onChange(null);
    }
    prevOrganizerId.current = props.organizerId;
  }, [props.organizerId]);

  return {
    isDisabled: !isOrganizerSelected,
    isLoadingOptions: isFetching && classifications === undefined,
    options,
    onChange,
    selectedOption:
      options.find((option) => option.value === props.value) || null,
  };
};

const toOption = (classification: ExaminationClassification) => {
  return {
    label: classification.name,
    value: classification.id,
  };
};
