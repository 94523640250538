import { createAction } from "redux-actions";
import { Dispatch } from "redux";
import ApiClient from "../api";
import * as queryString from "query-string";
import { CommentInterface } from "../interfaces/CommentInterface";
import ApiResponse from "../interfaces/ApiResponse";

const getStudyRecordCommentRequest = createAction(
  "GET_STUDY_RECORD_COMMENT_REQUEST",
);
const getStudyRecordCommentSuccess = createAction(
  "GET_STUDY_RECORD_COMMENT_SUCCESS",
);
const getStudyRecordCommentError = createAction(
  "GET_STUDY_RECORD_COMMENT_ERROR",
);
const getStudyRecordCommentInitialSuccess = createAction(
  "GET_STUDY_RECORD_COMMENT_INITIAL_SUCCESS",
);

export const getStudyRecordCommentsInitial =
  (sectionId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(getStudyRecordCommentRequest());
    ApiClient.get(`/api/v1/sections/${sectionId}/study_records/comments`).then(
      (res) => {
        if (res.ok) {
          res
            .json()
            .then(
              (json: {
                studyRecordComments: ApiResponse<CommentInterface[]>;
              }) => {
                dispatch(getStudyRecordCommentInitialSuccess(json));
              },
            );
        } else {
          dispatch(getStudyRecordCommentError());
        }
      },
    );
  };

export const getStudyRecordComments =
  (sectionId: string, page: number) =>
  (dispatch: Dispatch): void => {
    dispatch(getStudyRecordCommentRequest());
    const query = `?${queryString.stringify({ page })}`;
    ApiClient.get(`/api/v1/sections/${sectionId}/study_records/comments`, {
      query,
    }).then((res) => {
      if (res.ok) {
        res
          .json()
          .then(
            (json: {
              studyRecordComments: ApiResponse<CommentInterface[]>;
            }) => {
              dispatch(getStudyRecordCommentSuccess(json));
            },
          );
      } else {
        dispatch(getStudyRecordCommentError());
      }
    });
  };
