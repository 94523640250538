import * as React from "react";
import { HorizontalScrollTable, TableColumn } from "../../atoms/Table";
import { Classroom } from "../../../domains/Classroom";
import styles from "./ClassroomsTable.scss";
import { Text } from "../../general/Text";
import { InlineBlock } from "../../atoms/InlineBlock";

export const ClassroomsTable = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <HorizontalScrollTable
      headers={[
        { text: "教室名", centering: true, key: "name", width: 240 },
        { text: "経緯度座標", centering: true, key: "location" },
        { text: "有効範囲", centering: true, key: "acceptableRange" },
        { text: "", centering: false, key: "blank" },
      ]}
      rows={children as JSX.Element[]}
    />
  );
};

const displayLocation = (
  latitude: Classroom["latitude"],
  longitude: Classroom["longitude"],
) => {
  if (latitude && longitude) {
    return `${latitude}, ${longitude}`;
  } else {
    return null;
  }
};

const displayCorrectionRange = (
  correctionRange: Classroom["correctionRange"],
) => {
  if (correctionRange) {
    return `半径${correctionRange}メートル`;
  } else {
    return null;
  }
};

type ClassroomRowProps = {
  classroom: Classroom;
};
export const ClassroomsTableRow = ({
  classroom,
  children,
}: React.PropsWithChildren<ClassroomRowProps>) => {
  return (
    <tr>
      <TableColumn>
        <Text align="left">
          <InlineBlock marginLeft="1.6rem">{classroom.name}</InlineBlock>
        </Text>
      </TableColumn>
      <TableColumn>
        <Text align="center">
          {displayLocation(classroom.latitude, classroom.longitude)}
        </Text>
      </TableColumn>
      <TableColumn>
        <Text align="center">
          {displayCorrectionRange(classroom.correctionRange)}
        </Text>
      </TableColumn>
      <TableColumn className={styles.actionColumn}>{children}</TableColumn>
    </tr>
  );
};
