export type LearningMaterialTagType = "custom" | "default" | "have_no_tag";
export interface LearningMaterialTag {
  id: string;
  name: string;
  tagType: LearningMaterialTagType;
}
export interface LearningMaterialTagWithDeletable extends LearningMaterialTag {
  isDeletable: boolean;
}

export type LearningMaterialTagList = ReadonlyArray<LearningMaterialTag>;

export const toTypeLabel = (model: LearningMaterialTag) => {
  switch (model.tagType) {
    case "custom":
      return "カスタム";
    case "default":
    default:
      return "デフォルト";
  }
};

export type LearningMaterialTagStudyScore = Pick<
  LearningMaterialTag,
  "id" | "name"
> & { totalScore: number };

export type LearningMaterialTagStudyTimeSummary = {
  totalScore: number;
  learningMaterialTags: ReadonlyArray<LearningMaterialTagStudyScore>;
};

export const notSetTag: LearningMaterialTag = {
  id: "have_no_tag",
  tagType: "have_no_tag",
  name: "タグ未設定",
};

export const toTagParams = ({ tags }: { tags: LearningMaterialTagList }) => {
  const tagIds = tags
    ?.filter((tag) => tag.id !== "have_no_tag")
    .map((tag) => tag.id);
  const haveNoTag = tags?.some((tag) => tag.id === "have_no_tag");

  return {
    learning_material_tag_ids: tagIds || [],
    have_no_tag: haveNoTag ? haveNoTag.toString() : undefined,
  };
};
