import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";
import Icon from "../../../components/atoms/Icon";
import SwitchButton from "../../../components/atoms/SwitchButton";
import {
  updateSectionGuardianMessageSettingError,
  updateSectionGuardianMessageSettingReceived,
} from "../../../actions/pages/SectionsSettingsGuardianMessagePage";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useDispatch } from "react-redux";
import { useMutateAllowGuardianMessage } from "./useMutateAllowGuardianMessage";

type Props = {
  section: SectionInterface;
};

const useMutateAllowGuardianMessageToggle = ({
  sectionId,
}: {
  sectionId: string;
}) => {
  const mutation = useMutateAllowGuardianMessage({ sectionId });
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const paramName = "isAllowedToReceiveGuardianMessage";

  const handleSuccess = (value: boolean) => {
    showSuccessMessage("設定を更新しました");
    dispatch(
      updateSectionGuardianMessageSettingReceived({
        sectionId,
        paramName,
        value,
      }),
    );
  };

  const handleError = () => {
    showErrorMessage("設定が更新できませんでした");
    dispatch(
      updateSectionGuardianMessageSettingError({
        sectionId,
      }),
    );
  };

  const toggle = async (value: boolean) => {
    const result = await mutation.mutateAsync(value);
    result.isSuccess ? handleSuccess(value) : handleError();

    return result.value;
  };

  return { toggle };
};

export const ReceiveGuardianMessageToggleForm = (props: Props) => {
  const { toggle } = useMutateAllowGuardianMessageToggle({
    sectionId: props.section.id,
  });

  return (
    <div className={styles.box}>
      <div className={styles.row}>
        <div className={styles.left}>
          <div className={styles.heading}>
            <h2 className={styles.title}>
              保護者からのLINEメッセージを受け付ける
            </h2>
            <a
              href="https://fs-help.studyplus.co.jp/articles/3079910"
              rel="noopener noreferrer"
              target="_blank"
              className={styles.help}
            >
              <Icon name="icon-help" />
              保護者LINE連携方法
            </a>
          </div>
          <p className={styles.note}>
            ※オンにすると保護者とのLINEメッセージのやり取りが可能になります
          </p>
        </div>
        <div className={styles.right}>
          <SwitchButton
            label={{ on: "オン", off: "オフ" }}
            value={props.section.isAllowedToReceiveGuardianMessage}
            aria-label={`保護者とのLINEメッセージのやり取りを${
              props.section.isAllowedToReceiveGuardianMessage ? "オフ" : "オン"
            }にする`}
            onChange={toggle}
          />
        </div>
      </div>
    </div>
  );
};
