import { SectionsSettingsCourseStudents } from "./SectionsSettingsContentCourseStudents";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";

const pageInfo = {
  title: "コース",
};

export const SectionsSettingsContentCourseStudentsPage =
  enhanceSectionsSettingsPage(SectionsSettingsCourseStudents, pageInfo, [
    "content_courses",
  ]);
