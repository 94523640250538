import * as React from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import StaffBoardPostItem from "../../../components/molecules/StaffBoardPostItem";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { SectionStaffBoardPostStateInterface } from "../../../interfaces/SectionStaffBoardPostInterface";
import BackLink from "../../atoms/BackLink/index";
import SectionInterface from "../../../interfaces/SectionInterface";
import Loader from "../../atoms/Loader";
import {
  deleteStaffBoardPost,
  postComment,
  deleteComment,
} from "../../../actions/pages/SectionStaffBoardPostPage/actions";
import { StaffBoardPostCommentFormInterface } from "../../../interfaces/StaffBoardPostInterface";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../../hocs/enhanceAuthenticatedPage";

interface OwnProps extends WithRouterProps<AuthenticateRouterProps> {
  section: SectionInterface;
  currentOperator: OperatorInterface | undefined;
  staffBoardPostState: SectionStaffBoardPostStateInterface;
}

interface DispatchProps {
  deletePost: HandleThunkActionCreator<typeof deleteStaffBoardPost>;
  postComment: HandleThunkActionCreator<typeof postComment>;
  deleteComment: HandleThunkActionCreator<typeof deleteComment>;
}

type Props = OwnProps & DispatchProps;

const SectionStaffBoardPost = (props: Props) => {
  if (!props.currentOperator) return null;
  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      <Loader loading={props.staffBoardPostState.loading} />
      {renderItem(props)}
    </React.Fragment>
  );
};

const renderItem = (props: Props) => {
  if (
    !props.staffBoardPostState.data ||
    !props.currentOperator ||
    props.staffBoardPostState.loading
  ) {
    return null;
  }

  return (
    <StaffBoardPostItem
      section={props.section}
      currentOperator={props.currentOperator}
      staffBoardPost={props.staffBoardPostState.data}
      submitting={props.staffBoardPostState.submitting}
      postComment={handlePostComment(props)}
      deleteComment={handleDeleteComment(props)}
      deletePost={handleDeletePost(props)}
    />
  );
};

const handleDeletePost = (props: Props) => () => {
  if (!props.staffBoardPostState || !props.staffBoardPostState.data) return;
  props.deletePost(
    props.section.id,
    props.staffBoardPostState.data.id,
    props.navigate,
  );
};

const handleDeleteComment = (props: Props) => (commentId: string) => {
  if (!props.staffBoardPostState || !props.staffBoardPostState.data) return;
  props.deleteComment(
    props.section.id,
    props.staffBoardPostState.data.id,
    commentId,
  );
};

const handlePostComment =
  (props: Props) => (values: StaffBoardPostCommentFormInterface) => {
    if (!props.staffBoardPostState || !props.staffBoardPostState.data) return;
    props.postComment(
      props.section.id,
      props.staffBoardPostState.data.id,
      values,
    );
  };

const actions = {
  deletePost: deleteStaffBoardPost,
  postComment,
  deleteComment,
};

const ConnectedComponent = connect(null, actions)(SectionStaffBoardPost);

export default withRouter<Props>(ConnectedComponent);
