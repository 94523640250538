import styles from "./DrillDetail.scss";

type DrillDetailProps = {
  name: string;
  imageUrl: string;
  creatorName: string;
  sectionCount: number;
};

export const DrillDetail = ({
  name,
  imageUrl,
  creatorName,
  sectionCount,
}: DrillDetailProps) => {
  return (
    <div className={`flex items-center ${styles.drillDetail}`}>
      <div className="mx-8">
        <img src={imageUrl} className={`${styles.image}`} />
      </div>
      <div className="mb-2 ml-7 mt-1">
        <div className="text-2xl font-bold leading-none">{name}</div>
        <div className="mt-4 text-lg font-light leading-none">
          <span>{creatorName}</span>
          <span className="ml-7">セクション：{sectionCount}</span>
        </div>
      </div>
    </div>
  );
};
