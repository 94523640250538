import * as React from "react";

import OperatorInterface from "../../../interfaces/OperatorInterface";
import SectionInterface from "../../../interfaces/SectionInterface";
import Icon from "../../atoms/Icon";
import HeaderAccount from "../../molecules/HeaderAccount";
import HeaderCommentNotifications from "../../molecules/HeaderCommentNotifications";
import HeaderGuardianMessageNotifications from "../../molecules/HeaderGuardianMessageNotifications";
import HeaderMessageNotifications from "../../molecules/HeaderMessageNotifications";
import HeaderStudentSearchForm from "../../molecules/HeaderStudentSearchForm";
import SPGlobalHeader from "../../molecules/SPGlobalHeader";
import styles from "./styles.scss";
import HeaderBotMessageNotifications from "../../molecules/HeaderBotMessageNotifications";

interface Props {
  section: SectionInterface;
  operators: OperatorInterface[];
  currentOperator: OperatorInterface;
  studentNameFilter: {
    firstName?: string;
    lastName?: string;
  };
  onSearchStudent: (params: { firstName: string; lastName: string }) => any;
}

const GlobalHeader = (props: Props) => {
  return (
    <React.Fragment>
      <SPGlobalHeader
        operators={props.operators}
        currentOperator={props.currentOperator}
        section={props.section}
      />
      <nav className={styles.root}>
        <div className={styles.left}>
          <HeaderStudentSearchForm
            sectionId={props.section.id}
            defaultState={{
              firstName: props.studentNameFilter.firstName || "",
              lastName: props.studentNameFilter.lastName || "",
            }}
            onSearch={props.onSearchStudent}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.notificationIcon}>
            <HeaderGuardianMessageNotifications sectionId={props.section.id} />
          </div>
          <div className={styles.notificationIcon}>
            <HeaderMessageNotifications sectionId={props.section.id} />
          </div>
          <div className={styles.notificationIcon}>
            <HeaderBotMessageNotifications sectionId={props.section.id} />
          </div>
          <div className={styles.notificationIcon}>
            <HeaderCommentNotifications sectionId={props.section.id} />
          </div>
          <HeaderAccount
            operators={props.operators}
            currentOperator={props.currentOperator}
            className={styles.headerAccount}
          />
          <div className={styles.help}>
            <a
              href="https://fs-help.studyplus.co.jp"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="icon-help" className={styles.iconHelp} />
            </a>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default GlobalHeader;
