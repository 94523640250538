import classNames from "classnames";
import CircleIcon from "../../atoms/CircleIcon";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";

// NOTE: これまでThemeとして定義していたが,
// 実態としてシェイプの情報なども含まれているため
// ColorTypeとVariantsの組み合わせにしていきたい
// ref: https://studyplus.esa.io/posts/13937
type Theme =
  | "default"
  | "white"
  | "main"
  | "gray"
  | "lightgray"
  | "green"
  | "smarttag"
  | "smarttag-white"
  | "smarttag-active"
  | "sectiontag";

type ColorType = "light-blue" | "light-green";
type Variants = "solid" | "outline";

export interface DeprecatedTagButtonProps {
  label: string;
  theme?: Theme;
  iconName?: string;
  onClick?: (e: any) => void;
  buttonRef?: any;
  className?: string;
  disabled?: boolean;
  variants?: Variants;
  colorType?: ColorType;
  dataTestId?: string;
}

const renderIcon = (props: DeprecatedTagButtonProps) => {
  if (props.iconName) {
    if (props.theme === "lightgray") {
      return (
        <CircleIcon
          iconProps={{
            name: props.iconName,
          }}
          className={styles.icon}
          onClick={props.onClick}
          dataTestId={props.dataTestId}
        />
      );
    } else {
      return <Icon name={props.iconName} className={styles.icon} />;
    }
  } else {
    return null;
  }
};

const classMap = (props: DeprecatedTagButtonProps) => {
  return {
    [styles.defaultColor]: props.theme === "default",
    [styles.whiteColor]: props.theme === "white",
    [styles.mainColor]: props.theme === "main",
    [styles.grayColor]: props.theme === "gray",
    [styles.lightGrayColor]: props.theme === "lightgray",
    [styles.greenColor]: props.theme === "green",
    [styles.lightBlueSolid]:
      props.colorType === "light-blue" && props.variants === "solid",
    [styles.lightBlueOutline]:
      props.colorType === "light-blue" && props.variants === "outline",
    [styles.lightGreenSolid]:
      props.colorType === "light-green" && props.variants === "solid",
    [styles.lightGreenOutline]:
      props.colorType === "light-green" && props.variants === "outline",

    // FIXME: 機能ごとの命名はやめたい
    // スマートタグ
    [styles.smarttagColor]: props.theme === "smarttag",
    [styles.smarttagWhiteColor]: props.theme === "smarttag-white",
    [styles.smarttagActiveColor]: props.theme === "smarttag-active",
    // 校舎タグ
    [styles.sectionTagColor]: props.theme === "sectiontag",

    // アイコン
    [styles.withIcon]: !!props.iconName,
  };
};

// 新しい実装方針のTagButtonに切り替え中。
// 利用箇所が多いためこちらも残しているが、TagButtonのほうですべてのバリエーションに対応できるようになったら
// 消していきたい
const DeprecatedTagButton = (props: DeprecatedTagButtonProps) => {
  if (props.theme === "lightgray") {
    return (
      <button
        className={classNames(styles.root, classMap(props), props.className)}
        ref={props.buttonRef}
        type="button"
        disabled={!!props.disabled}
      >
        <span className={styles.label}>{props.label}</span>
        {renderIcon(props)}
      </button>
    );
  } else {
    return (
      <button
        className={classNames(styles.root, classMap(props), props.className)}
        onClick={props.onClick}
        ref={props.buttonRef}
        type="button"
        disabled={!!props.disabled}
        data-testid={props.dataTestId}
      >
        <span className={styles.label}>{props.label}</span>
        {renderIcon(props)}
      </button>
    );
  }
};

export default DeprecatedTagButton;
