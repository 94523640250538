import { FormikProps } from "formik";

function shouldRenderError(
  props: FormikProps<any>,
  name: string,
): boolean | { [key: string]: any } {
  const splittedNames = name.split(".");

  // name is object
  if (splittedNames.length === 2) {
    const [rootKey, nestedKey] = splittedNames;

    const touched =
      !!(props.touched as any)[rootKey] &&
      (props.touched as any)[rootKey][nestedKey];
    const errors =
      !!(props.errors as any)[rootKey] &&
      (props.errors as any)[rootKey][nestedKey];

    return touched && errors;
  }

  // name is string
  const errors = !!(props.errors as any)[name];
  const touched = !!(props.touched as any)[name];
  return errors && touched;
}

const FormikHelper = {
  shouldRenderError,
};

export default FormikHelper;
