import * as React from "react";
import StudentInterface from "../../../../interfaces/StudentInterface";
import Icon from "../../../../components/atoms/Icon";
import { EllipsisDropdownLink } from "../../../../components/general/EllipsisDropdown";
import { DisconnectAppModal } from "./DisconnectAppModal";

type Props = {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
};
export const DisconnectAppDropdownLink = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleClickLink = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <EllipsisDropdownLink onClick={handleClickLink}>
        <Icon name="icon-students-stop" />
        生徒アプリ連携の停止
      </EllipsisDropdownLink>
      <DisconnectAppModal
        student={props.student}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        updateStudent={props.updateStudent}
      />
    </>
  );
};
