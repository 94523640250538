import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import SectionsKarteTemplatesApi from "../api-clients/SectionsKarteTemplatesApi";
import { KarteTemplateInterface } from "../interfaces/KarteTemplateInterface";
import { HTTP_ERROR_MESSAGE } from "../reducers/index";
import { FlashMessageType } from "../interfaces/FlashMessageInterface";
import { setFlashMessage } from "./flashMessage";
import { NavigateFunction } from "react-router";
import ApiErrorResponseInterface from "../interfaces/ApiErrorResponseInterface";
import { apiRequestError } from "./apiRequestError";
import { buildMainResourceApiErrorAction } from "./common/errors";

const getSectionsKarteTemplatesRequest = createAction(
  "GET_SECTIONS_KARTE_TEMPLATES_REQUEST",
);
const getSectionsKarteTemplatesSuccess = createAction(
  "GET_SECTIONS_KARTE_TEMPLATES_SUCCESS",
);
const getSectionsKarteTemplatesError = createAction(
  "GET_SECTIONS_KARTE_TEMPLATES_ERROR",
);

export const getSectionsKarteTemplates =
  (sectionId: string) => (dispatch: Dispatch) => {
    dispatch(getSectionsKarteTemplatesRequest());

    SectionsKarteTemplatesApi.getSectionsKarteTemplates(sectionId)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((json: { karteTemplates: KarteTemplateInterface[] }) => {
              dispatch(getSectionsKarteTemplatesSuccess(json));
            });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getSectionsKarteTemplatesError());
        }
      })
      .catch(() => {
        dispatch(getSectionsKarteTemplatesError());
      });
  };

const getSectionsKarteTemplateRequest = createAction(
  "GET_SECTIONS_KARTE_TEMPLATE_REQUEST",
);
const getSectionsKarteTemplateSuccess = createAction(
  "GET_SECTIONS_KARTE_TEMPLATE_SUCCESS",
);
const getSectionsKarteTemplateError = createAction(
  "GET_SECTIONS_KARTE_TEMPLATE_ERROR",
);

export const getSectionsKarteTemplate =
  (sectionId: string, karteTemplateId: string) => (dispatch: Dispatch) => {
    dispatch(getSectionsKarteTemplateRequest());

    SectionsKarteTemplatesApi.getSectionsKarteTemplate(
      sectionId,
      karteTemplateId,
    )
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((json: { karteTemplates: KarteTemplateInterface[] }) => {
              dispatch(getSectionsKarteTemplateSuccess(json));
            });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getSectionsKarteTemplateError());
        }
      })
      .catch(() => {
        dispatch(getSectionsKarteTemplateError());
      });
  };

const createSectionsKarteTemplateRequest = createAction(
  "CREATE_SECTIONS_KARTE_TEMPLATE_REQUEST",
);
const createSectionsKarteTemplateSuccess = createAction(
  "CREATE_SECTIONS_KARTE_TEMPLATE_SUCCESS",
);
const createSectionsKarteTemplateError = createAction(
  "CREATE_SECTIONS_KARTE_TEMPLATE_ERROR",
);
const createSectionKarteTemplateDefaultError = {
  message: "テンプレートを作成できませんでした",
};
const createSectionKarteTemplateUnknownError = { message: HTTP_ERROR_MESSAGE };
const setCreateSectionKarteTemplateSuccessMessage = (dispatch: Dispatch) => {
  const successMessage = {
    type: FlashMessageType.SUCCESS,
    message: "テンプレートを作成しました",
  };
  const flashAction = setFlashMessage(successMessage);
  dispatch(flashAction);
};

export const createSectionsKarteTemplate =
  (
    sectionId: string,
    params: { name: string; template: string },
    navigate: NavigateFunction,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(createSectionsKarteTemplateRequest());

    SectionsKarteTemplatesApi.createSectionKarteTemplate(sectionId, params)
      .then((res) => {
        if (res.ok) {
          res.json().then((json: { karteTemplate: KarteTemplateInterface }) => {
            dispatch(createSectionsKarteTemplateSuccess(json));
          });
          setCreateSectionKarteTemplateSuccessMessage(dispatch);
          navigate(`/sections/${sectionId}/settings/karte_templates`);
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(createSectionsKarteTemplateError(json));
          });
          const payload = { errors: [createSectionKarteTemplateDefaultError] };
          dispatch(apiRequestError(payload));
        } else {
          dispatch(createSectionsKarteTemplateError());
          const payload = { errors: [createSectionKarteTemplateUnknownError] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(createSectionsKarteTemplateError());
      });
  };

const updateSectionsKarteTemplateRequest = createAction(
  "UPDATE_SECTIONS_KARTE_TEMPLATE_REQUEST",
);
const updateSectionsKarteTemplateSuccess = createAction(
  "UPDATE_SECTIONS_KARTE_TEMPLATE_SUCCESS",
);
const updateSectionsKarteTemplateError = createAction(
  "UPDATE_SECTIONS_KARTE_TEMPLATE_ERROR",
);
const updateSectionKarteTemplateDefaultError = {
  message: "テンプレートを更新できませんでした",
};
const updateSectionKarteTemplateUnknownError = { message: HTTP_ERROR_MESSAGE };
const setUpdateSectionKarteTemplateSuccessMessage = (dispatch: Dispatch) => {
  const successMessage = {
    type: FlashMessageType.SUCCESS,
    message: "テンプレートを更新しました",
  };
  const flashAction = setFlashMessage(successMessage);
  dispatch(flashAction);
};

export const updateSectionsKarteTemplate =
  (
    sectionId: string,
    karteTemplateId: string,
    params: { name: string; template: string },
    navigate: NavigateFunction,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(updateSectionsKarteTemplateRequest());

    SectionsKarteTemplatesApi.updateSectionKarteTemplate(
      sectionId,
      karteTemplateId,
      params,
    )
      .then((res) => {
        if (res.ok) {
          res.json().then((json: { karteTemplate: KarteTemplateInterface }) => {
            dispatch(updateSectionsKarteTemplateSuccess(json));
          });
          setUpdateSectionKarteTemplateSuccessMessage(dispatch);
          navigate(`/sections/${sectionId}/settings/karte_templates`);
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(updateSectionsKarteTemplateError(json));
          });
          const payload = { errors: [updateSectionKarteTemplateDefaultError] };
          dispatch(apiRequestError(payload));
        } else {
          dispatch(updateSectionsKarteTemplateError());
          const payload = { errors: [updateSectionKarteTemplateUnknownError] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(updateSectionsKarteTemplateError());
      });
  };

const deleteSectionsKarteTemplateRequest = createAction(
  "DELETE_SECTIONS_KARTE_TEMPLATE_REQUEST",
);
const deleteSectionsKarteTemplateSuccess = createAction(
  "DELETE_SECTIONS_KARTE_TEMPLATE_SUCCESS",
);
const deleteSectionsKarteTemplateError = createAction(
  "DELETE_SECTIONS_KARTE_TEMPLATE_ERROR",
);
const deleteSectionKarteTemplateDefaultError = {
  message: "テンプレートを削除できませんでした",
};
const setDeleteSectionKarteTemplateSuccessMessage = (dispatch: Dispatch) => {
  const successMessage = {
    type: FlashMessageType.SUCCESS,
    message: "テンプレートを削除しました",
  };
  const flashAction = setFlashMessage(successMessage);
  dispatch(flashAction);
};

export const deleteSectionsKarteTemplate =
  (sectionId: string, karteTemplateId: string, navigate: NavigateFunction) =>
  (dispatch: Dispatch) => {
    dispatch(deleteSectionsKarteTemplateRequest());

    SectionsKarteTemplatesApi.deleteSectionKarteTemplate(
      sectionId,
      karteTemplateId,
    )
      .then((res) => {
        if (res.ok) {
          dispatch(deleteSectionsKarteTemplateSuccess());
          setDeleteSectionKarteTemplateSuccessMessage(dispatch);
          navigate(`/sections/${sectionId}/settings/karte_templates`);
        } else {
          dispatch(deleteSectionsKarteTemplateError());
          const payload = { errors: [deleteSectionKarteTemplateDefaultError] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(deleteSectionsKarteTemplateError());
      });
  };
