import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import { Schedule } from "../../../domains/Schedule";
import { StudySchedule } from "../../../domains/StudySchedule";
import { createError, HTTPErrors } from "../../../errors";
import SectionInterface from "../../../interfaces/SectionInterface";

type SectionProps = {
  section: SectionInterface;
};

type StudyScheduleParam = Omit<StudySchedule, "learningMaterial"> & {
  learningMaterialPublicId: string;
};
export type SectionScheduleMutateParam = Omit<
  Schedule,
  "id" | "recurrence" | "scheduleType" | "id" | "studySchedule"
> & {
  classroomId: string | null;
  teacherId: string | null;
  lectureId: string | null;
  id?: string;
  studentIds: ReadonlyArray<string>;
  studySchedule: StudyScheduleParam | null;
};

type UseMutateSectionScheduleProps = SectionProps & {
  onError: () => void;
  onSuccess: () => void;
};

export const useMutateSectionSchedule = ({
  onError,
  onSuccess,
  section,
}: UseMutateSectionScheduleProps) => {
  const client = useQueryClient();
  const url = `/api/v1/sections/${section.id}/schedules`;
  return useMutation<unknown, HTTPErrors, SectionScheduleMutateParam>({
    mutationFn: async (p) => {
      const res = p.id
        ? await api.patch(`${url}/${p.id}`, toRequestBody(p))
        : await api.post(url, toRequestBody(p));
      if (res.ok) {
        return;
      }
      throw await createError(res);
    },
    onError,
    onSuccess() {
      client.invalidateQueries({ queryKey: ["section/schedules"] });
      onSuccess();
    },
  });
};

const toRequestBody = (param: SectionScheduleMutateParam) => {
  return {
    summary: param.summary,
    start_at: param.startAt.toISOString(),
    end_at: param.endAt.toISOString(),
    locale: param.locale,
    description: param.description,
    allday: param.allday,
    classroom_id: param.classroomId,
    lecture_id: param.lectureId,
    teacher_id: param.teacherId,
    student_public_ids: param.studentIds,
    study_schedule: param.studySchedule
      ? {
          learning_material_public_id:
            param.studySchedule.learningMaterialPublicId,
          number_of_seconds: param.studySchedule.numberOfSeconds,
          amount: param.studySchedule.amount,
        }
      : null,
  };
};
