import * as React from "react";
import { Button } from "@studyplus/boron-ui";
import {
  SelectWrapper,
  OptionType as SelectOptionType,
} from "../../../components/general/SelectWrapper";
import { useSectionContext } from "../../../contexts/section";
import { useFetchAllTags } from "../../../hooks/http/useFetchTags";
import { useState } from "react";
import {
  UseFetchStudentsOptions,
  useFetchSectionStudents,
} from "../../../hooks/http/useFetchSectionStudents";
import {
  FieldLayoutMultiRows,
  StackForm,
  StackItem,
  FormFooter,
} from "../../../components/features/Schedule/FormParts";
import Icon from "../../../components/atoms/Icon";
import {
  FlexibleTable as Table,
  TableHeadColumn,
  TableColumn,
} from "../../../components/atoms/Table";
import { Flex } from "../../../components/atoms/Flex";
import Loader from "../../../components/atoms/Loader";
import ErrorText from "../../atoms/ErrorText";
import { useQueryError } from "../../../hooks/http/useQueryError";
import styles from "./index.scss";
import { BlockRow } from "../../../components/atoms/BlockRow";
import StudentTag from "../../../domains/StudentTag";
import classnames from "classnames";
import Label from "../../atoms/Label";
import { Text } from "../../general/Text";

type OptionType = {
  label: string;
  value: string;
};

type FilterOptions = Pick<UseFetchStudentsOptions, "billing_plan" | "status">;
type Props = {
  defaultValue: readonly OptionType[] | null;
  onChange: (values: ReadonlyArray<OptionType> | null | undefined) => void;
  submitCallback?: () => void;
  filterOptions?: FilterOptions;
  studentTagHelpComponent?: React.ReactNode;
};

export const StudentTagForm = ({
  defaultValue,
  onChange,
  submitCallback,
  filterOptions,
  studentTagHelpComponent,
}: Props) => {
  const section = useSectionContext();
  const [selectedTag, setSelectedTag] = useState<StudentTag | undefined>(
    undefined,
  );
  const [selectedTagError, setSelectedTagError] = useState(false);

  const { data: tags, error: tagsError } = useFetchAllTags({
    sectionId: section.id,
    params: { types: ["tag_ids", "smart_tag"] },
  });
  useQueryError(tagsError);

  const {
    data: students,
    isInitialLoading: studentsLoading,
    error: studentsError,
  } = useFetchSectionStudents({
    sectionId: section.id,
    params: selectedTag
      ? { [selectedTag.type]: [selectedTag.id], ...filterOptions }
      : { ...filterOptions },
    shouldGetAllRecords: true,
    enabled: !!selectedTag,
  });
  useQueryError(studentsError);

  const handleSelectChange = (selection: SelectOptionType | null) => {
    setSelectedTag(tags?.find((tag) => tag.id === selection?.value));
    setSelectedTagError(false);
  };

  const filteredTags = tags
    ? tags
        .filter((tag) => tag.studentCount && tag.studentCount > 0)
        .map((tag) => {
          return { value: tag.id, label: tag.name };
        })
    : [];

  const findUnionOfStudentValues = (
    oldStudents: readonly OptionType[],
    newStudents: OptionType[],
  ) => {
    return Array.from(
      new Map(
        [...oldStudents, ...newStudents].map((o) => [o.value, o]),
      ).values(),
    );
  };

  const handleSubmit = (e: any) => {
    if (!selectedTag) {
      setSelectedTagError(true);
      e.preventDefault();
      return;
    }
    const studentValues = students?.map((student) => {
      return {
        label: student.fullName,
        value: student.id,
      };
    });
    if (studentValues) {
      defaultValue
        ? onChange(findUnionOfStudentValues(defaultValue, studentValues))
        : onChange(studentValues);
    }

    if (submitCallback) submitCallback();
  };

  const tableClassName = classnames(styles.studentTagFormTable, {
    [styles["studentTagFormTable--bottomBorder_none"]]:
      students === undefined || students.length === 0,
  });

  return (
    <StackForm>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-tags" color="primary" />
          <FormLabel>
            生徒タグを選択
            {studentTagHelpComponent}
          </FormLabel>
          <BlockRow marginTop="0.8rem">
            <SelectWrapper
              placeholder="生徒タグを選択"
              aria-label="生徒タグを選択"
              options={filteredTags}
              noOptionsMessage={() => "生徒タグを登録することで設定できます"}
              size="md"
              isClearable={true}
              onChange={handleSelectChange}
            />
          </BlockRow>
          {selectedTagError && (
            <ErrorText className="text-lg">
              生徒タグを選択してください
            </ErrorText>
          )}
        </FieldLayoutMultiRows>
      </StackItem>
      <StackItem>
        <FieldLayoutMultiRows>
          <Icon name="icon-students" color="primary" />
          <FormLabel>選択した生徒タグに含まれる生徒</FormLabel>
          <BlockRow marginTop="0.8rem">
            <div className={styles.studentTagFormTableWrapper}>
              <Table headSticky noOutlineBorder className={tableClassName}>
                <thead>
                  <tr>
                    <TableHeadColumn sticky centering>
                      氏名
                    </TableHeadColumn>
                    <TableHeadColumn sticky>学年（職業）</TableHeadColumn>
                  </tr>
                </thead>
                <tbody>
                  {studentsLoading && (
                    <tr>
                      <TableColumn
                        colSpan={3}
                        className={styles.studentTagFormTableCell}
                      >
                        <Flex justifyContent="center">
                          <Loader loading />
                        </Flex>
                      </TableColumn>
                    </tr>
                  )}
                  {students &&
                    students.map((student) => {
                      return (
                        <tr
                          key={student.id}
                          className={styles.studentTagFormTableRow}
                        >
                          <TableColumn
                            className={styles.studentTagFormTableCell}
                          >
                            <Flex alignItems="center" marginTop="0">
                              <div>
                                <Icon name="icon-account" color="pink" />
                              </div>
                              <div
                                className={styles.studentTagFormTable__fullName}
                              >
                                {student.fullName}
                              </div>
                            </Flex>
                          </TableColumn>
                          <TableColumn
                            className={styles.studentTagFormTableCell}
                          >
                            {student.jobHuman}
                          </TableColumn>
                        </tr>
                      );
                    })}
                  {!students && !studentsLoading && (
                    <tr>
                      <TableColumn
                        colSpan={3}
                        className={styles.studentTagFormTableCell}
                      >
                        <Flex justifyContent="center">
                          <span className={styles.studentTagFormGreyText}>
                            生徒タグを選択してください
                          </span>
                        </Flex>
                      </TableColumn>
                    </tr>
                  )}
                  <tr></tr>
                </tbody>
              </Table>
            </div>
          </BlockRow>
        </FieldLayoutMultiRows>
      </StackItem>
      <FormFooter>
        <Button onClick={handleSubmit} disabled={studentsLoading}>
          決定
        </Button>
      </FormFooter>
    </StackForm>
  );
};

const FormLabel = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Label noMargin>
      <Text color="gray-darken-2" bold className="flex items-end">
        {children}
      </Text>
    </Label>
  );
};
