import { SectionLearningMaterialNewStateInterface } from "../interfaces/SectionLearningMaterialInterface";

const newDefaultState: SectionLearningMaterialNewStateInterface = {
  loading: false,
  submitting: false,
  apiErrors: [],
};

const sectionLearningMaterialNewState = (
  state: SectionLearningMaterialNewStateInterface = newDefaultState,
  action: ReduxActions.Action<any>,
): any => {
  switch (action.type) {
    case "CLEAR_SECTION_LEARNING_MATERIAL_FORM_API_ERRORS_REQUEST":
      return {
        ...state,
        submitting: false,
        apiErrors: [],
      };
    case "CREATE_SECTION_LEARNING_MATERIAL_SUBMITTING":
      return {
        ...state,
        submitting: true,
      };
    case "CREATE_SECTION_LEARNING_MATERIAL_SUCCESS":
      return {
        ...state,
        submitting: false,
        apiErrors: [],
      };
    case "CREATE_SECTION_LEARNING_MATERIAL_ERROR":
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors || [] : [],
      };
    default:
      return state;
  }
};

export default sectionLearningMaterialNewState;
