import ApiResponse from "../interfaces/ApiResponse";
import KarteInterface, {
  KartesStateInterface,
} from "../interfaces/KarteInterface";
import kartes from "./kartes";

const defaultKartesState: KartesStateInterface = {
  loading: true,
  hasMore: true,
  meta: {
    currentPage: 0,
    totalPages: 0,
  },
  data: null,
};

const concatData = (
  prevData: KarteInterface[] | null,
  data: KarteInterface[],
): KarteInterface[] => {
  if (prevData === null) {
    return data;
  } else {
    return prevData.concat(data);
  }
};

const appendSuccessResponse = (
  state: KartesStateInterface,
  response: ApiResponse<KarteInterface[]>,
) => {
  const { meta, data } = response;

  return {
    ...state,
    hasMore: meta.currentPage < meta.totalPages,
    loading: false,
    data: concatData(state.data, data),
    meta,
  };
};

const sectionKartes = (
  state: KartesStateInterface = defaultKartesState,
  action: ReduxActions.Action<any>,
) => {
  const { payload } = action;

  switch (action.type) {
    // Loading
    case "GET_INITIAL_SECTION_KARTES_REQUEST_SENDING":
      return defaultKartesState;

    case "GET_SECTION_KARTES_REQUEST_SENDING":
      return { ...state, loading: true };

    // Success Response
    case "GET_SECTION_KARTES_RESPONSE_RECEIVED":
    case "GET_INITIAL_SECTION_KARTES_RESPONSE_RECEIVED":
      return appendSuccessResponse(state, payload.kartes);

    // カルテの更新
    case "OPEN_KARTE_SHARE_MODAL":
    case "CLOSE_KARTE_SHARE_MODAL":
    case "POST_KARTE_SHARE_SENDING":
    case "POST_KARTE_SHARE_SUCCESS":
    case "POST_KARTE_SHARE_ERROR":
    case "DELETE_KARTE_COMMENT_SUCCESS":
    case "DELETE_KARTE_COMMENT_ERROR":
    case "POST_KARTE_COMMENT_SUCCESS":
    case "POST_KARTE_COMMENT_ERROR":
    case "DELETE_KARTE_SUCCESS":
      if (state.data) {
        return {
          ...state,
          data: kartes(state.data, action),
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default sectionKartes;
