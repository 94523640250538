import * as React from "react";
import { useStudentCalendarContainer } from "./useStudentCalendarContainer";
import { Calendar } from "../../../components/atoms/Calendar";
import { UseStudentCalendarContainerProps } from "./useStudentCalendarContainer";

type Props = UseStudentCalendarContainerProps;

export const StudentCalendarContainer = (props: Props): React.ReactElement => {
  const {
    isLoading,
    onChangeScheduleFromInteraction,
    events,
    onSelectFromSlot,
    eventColor,
    onClickSchedule,
    onMouseInteractionStart,
    shouldShowPlaceHolderEvent,
  } = useStudentCalendarContainer(props);

  return (
    <Calendar
      events={isLoading ? [] : events}
      startDate={props.startDate}
      calendarType={props.calendarType}
      onSelectSlot={onSelectFromSlot}
      onClickSchedule={onClickSchedule}
      onEventResize={onChangeScheduleFromInteraction}
      onDropSchedule={onChangeScheduleFromInteraction}
      onDragStart={onMouseInteractionStart}
      onEventResizeStart={onMouseInteractionStart}
      eventColor={eventColor}
      shouldShowPlaceHolderEvent={shouldShowPlaceHolderEvent}
    />
  );
};
