import { useCallback, useState } from "react";
import { DateRange as DayPickerDateRange, Matcher } from "react-day-picker";

import { StyledDayPicker } from "../StyledDayPicker";
import addDays from "date-fns/addDays";

type DateRange = {
  startDate?: Date;
  endDate?: Date;
};
type Props = {
  startDate?: Date;
  endDate?: Date;
  endDateOffset: number;
  onDateRangeChange: (dateRange: DateRange) => void;
  numberOfMonths?: number;
  disabled?: Matcher | Matcher[];
  defaultMonth?: Date;
};
// 決められた日数の期間をカレンダーから選択するためのコンポーネント
// 日数が決まっていない場合は DynamicDateRangePicker を使用する
export const DateRangePicker = (props: Props) => {
  const {
    startDate,
    endDate,
    endDateOffset,
    onDateRangeChange,
    ...dayPickerProps
  } = props;
  const dateRange: DayPickerDateRange = { from: startDate, to: endDate };

  const onDayClick = useCallback(
    (date: Date) => {
      onDateRangeChange({
        startDate: date,
        endDate: addDays(date, endDateOffset),
      });
    },
    [onDateRangeChange, endDateOffset],
  );

  // クリック前に対象期間が視覚的にわかるように、MouseEnter/Leave時にhighlight modifiersをつける期間を設定する
  const [targetDays, setTargetDays] = useState<DayPickerDateRange | null>(null);
  const onDayMouseEnter = useCallback(
    (date: any) => {
      const dateRange = createDateRange(date, endDateOffset);
      setTargetDays(dateRange);
    },
    [endDateOffset],
  );
  const onDayMouseLeave = useCallback(() => {
    setTargetDays(null);
  }, []);

  return (
    <StyledDayPicker
      mode="default"
      onDayClick={onDayClick}
      onDayFocus={onDayMouseEnter}
      onDayMouseEnter={onDayMouseEnter}
      onDayMouseLeave={onDayMouseLeave}
      selected={dateRange}
      modifiers={{
        selectedDateRange: dateRange,
        selectedDateRangeStart: dateRange.from ?? false,
        selectedDateRangeEnd: dateRange.to ?? false,
        highlighted: targetDays ?? false,
        highlightedStart: targetDays?.from ?? false,
      }}
      {...dayPickerProps}
    />
  );
};

const createDateRange = (
  date: Date,
  numberOfDays: number,
): DayPickerDateRange => {
  const startDay = date;
  const endDay = addDays(startDay, numberOfDays);
  return { from: startDay, to: endDay };
};
