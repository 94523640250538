import * as queryString from "query-string";

import { AnalyticsTermType } from "../interfaces/AnalyticsTableInterface";
import { DateRangeFilterQueryInterface } from "../interfaces/DateRangeFilterInterface";
import {
  FiltersQueryInterface,
  OrderDirFilterType,
} from "../interfaces/FiltersInterface";
import StudentTag from "../domains/StudentTag";
import StatusTagHelper from "./StatusTagHelper";
import { defaultTagFilter } from "../components/features/NewStudentFilter/useStudentFilterContext";
import AppStateInterface from "../interfaces/AppStateInterface";
import { StudentTagFilterInterface } from "../domains/StudentTag";
import startOfMonth from "date-fns/startOfMonth";
import startOfISOWeek from "date-fns/startOfISOWeek";
import sub from "date-fns/sub";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export interface StudentFilterParams {
  first_name?: string;
  last_name?: string;
  status?: string[];
  job?: string[];
  tag_ids?: string[];
  smart_tag?: string[];
}

interface FilterParams extends StudentFilterParams {
  date?: string;
  initial_date?: string;
  end_date?: string;
  term?: string;
  order?: string;
  order_dir?: string;
  page?: number;
  has_planning?: string;
}

export const buildStudentFilterParams = (
  tagFilter: StudentTagFilterInterface,
): StudentFilterParams => {
  const params: StudentFilterParams = {};
  const { selectedTags, selectedStatusTags } = tagFilter;
  const selectedJobTags = selectedTags.filter((tag) => tag.type === "job");

  params.tag_ids = selectedTags
    .filter((tag) => tag.type === "tag_ids")
    .map((tag) => tag.id);
  params.smart_tag = selectedTags
    .filter((tag) => tag.type === "smart_tag")
    .map((tag) => tag.id);
  params.status = selectedStatusTags.map((tag) => tag.id);

  if (selectedJobTags.length > 0) {
    params.job = selectedJobTags.map((tag) => tag.id);
  }

  return params;
};

const buildDateRangeFilterParams = (
  dateRangeFilter: DateRangeFilterQueryInterface,
): FilterParams => {
  const params: FilterParams = {};
  const { startDate, endDate } = dateRangeFilter;

  // NOTE: boron API の仕様上、initial_dateから過去へ遡る形になるので
  // initialとendを逆にしている
  if (startDate) {
    params.end_date = format(startDate, "yyyy-MM-dd");
  }

  if (endDate) {
    params.initial_date = format(endDate, "yyyy-MM-dd");
  }

  return params;
};

type CreateFiltersWithSectionParams = Pick<
  AppStateInterface,
  "currentSection" | "filters"
> & {
  options?: FiltersQueryInterface;
};

export const createFiltersWithSection = ({
  currentSection,
  filters,
  options,
}: CreateFiltersWithSectionParams) => {
  const sectionId = currentSection.data ? currentSection.data.id : null;
  const sectionFilter = filters.find(
    (filter) => sectionId === filter.sectionId,
  );

  return {
    ...sectionFilter,
    ...options,
  };
};

const stateToFilters = (
  state: AppStateInterface,
  options?: FiltersQueryInterface,
): FiltersQueryInterface =>
  createFiltersWithSection({
    currentSection: state.currentSection,
    filters: state.filters,
    options,
  });

type ToQueryObjectOption = {
  withoutDateRange?: boolean;
};
export const toQueryObject = (
  filters: FiltersQueryInterface,
  option?: ToQueryObjectOption,
): { [key: string]: any } => {
  const {
    dateRangeFilter,
    dateFilter,
    tagFilter,
    term,
    order,
    orderDir,
    page,
    since,
    firstName,
    lastName,
    hasPlanning,
  } = filters;

  let params: any = {};

  if (tagFilter) {
    params = buildStudentFilterParams(tagFilter);
  }

  if (dateRangeFilter && !option?.withoutDateRange) {
    params = {
      ...params,
      ...buildDateRangeFilterParams(dateRangeFilter),
    };
  }

  if (dateFilter && !option?.withoutDateRange) {
    params.date = format(dateFilter, "yyyy-MM-dd");
  }

  if (page) {
    params.page = page.toString();
  }
  if (!option?.withoutDateRange) {
    params.term = term;
  }
  params.order_dir = orderDir;
  params.order = order;
  params.since = since;
  params.first_name = firstName;
  params.last_name = lastName;
  params.has_planning = hasPlanning;

  return params;
};

const toQueryString = (filters: FiltersQueryInterface): string => {
  const params = toQueryObject(filters);
  return `?${queryString.stringify(params, { arrayFormat: "bracket" })}`;
};

const FiltersHelper = {
  calculateStartDate: (initial_date: Date, term: string | undefined): Date => {
    switch (term) {
      case "monthly":
        return startOfMonth(sub(initial_date, { months: 6 }));
      case "weekly":
        return sub(startOfISOWeek(initial_date), { weeks: 6 });
      case "daily":
      default:
        return sub(initial_date, { days: 6 });
    }
  },

  stateToFiltersQueryString: (
    state: AppStateInterface,
    options?: FiltersQueryInterface,
  ): string => {
    return toQueryString(stateToFilters(state, options));
  },

  toQueryString,

  // クエリストリングからstateへの変換
  queryToFilters: (
    query: string,
    allTags: StudentTag[],
  ): FiltersQueryInterface => {
    const queryObject: FilterParams = queryString.parse(query, {
      arrayFormat: "bracket",
    });
    const {
      initial_date,
      end_date,
      term,
      order,
      order_dir,
      page,
      tag_ids,
      job,
      status,
      smart_tag,
      date,
      first_name,
      last_name,
      has_planning,
    } = queryObject;

    const filters: FiltersQueryInterface = {};

    // NOTE: boron API の仕様上、initial_dateから過去へ遡る形になるので
    // initialとendを逆にしている
    if (initial_date && end_date) {
      filters.dateRangeFilter = {
        startDate: parseISO(end_date),
        endDate: parseISO(initial_date),
      };
    }

    if (date) {
      filters.dateFilter = parseISO(date);
    }

    const statusTags = StatusTagHelper.getAll();

    filters.tagFilter = { ...defaultTagFilter };

    if (allTags) {
      filters.tagFilter.tags = allTags.filter(
        (tag) => tag.type === "tag_ids" || tag.type === "smart_tag",
      );
      filters.tagFilter.systemTags = allTags.filter(
        (tag) => tag.type === "job",
      );

      if (tag_ids) {
        filters.tagFilter.selectedTags = allTags.filter((tag: StudentTag) =>
          tag_ids.includes(tag.id),
        );
      } else {
        filters.tagFilter.selectedTags = [];
      }

      if (status) {
        const selectedStatusTags = statusTags.filter((tag: StudentTag) =>
          status.includes(tag.id),
        );
        if (selectedStatusTags.length > 0) {
          filters.tagFilter.selectedStatusTags = selectedStatusTags;
        }
      }

      if (job) {
        const selectedJobTags = allTags.filter((tag: StudentTag) =>
          job.includes(tag.id),
        );
        filters.tagFilter.selectedTags =
          filters.tagFilter.selectedTags.concat(selectedJobTags);
      }

      if (smart_tag) {
        const selectedSmartTags = allTags.filter((tag: StudentTag) =>
          smart_tag.includes(tag.id),
        );
        filters.tagFilter.selectedTags =
          filters.tagFilter.selectedTags.concat(selectedSmartTags);
      }
    }

    if (term) {
      filters.term = term as AnalyticsTermType;
    }
    if (order_dir) {
      filters.orderDir = order_dir as OrderDirFilterType;
    }
    if (order || order === null) {
      filters.order = order;
    }
    if (page) {
      filters.page = page;
    }
    if (first_name) {
      filters.firstName = first_name;
    }
    if (last_name) {
      filters.lastName = last_name;
    }
    if (has_planning) {
      filters.hasPlanning = has_planning;
    }

    return filters;
  },

  getAllTags: (studentFilter: StudentTagFilterInterface): StudentTag[] => {
    if (studentFilter) {
      return studentFilter.systemTags.concat(studentFilter.tags);
    } else {
      return [];
    }
  },
};

export default FiltersHelper;
