import StudyRecordInterface from "../../../../interfaces/StudyRecordInterface";
import styles from "./styles.scss";

interface Props {
  studyRecord: StudyRecordInterface;
}

export const Images = ({ studyRecord }: Props) => {
  const { studyRecordImages } = studyRecord;

  return (
    <div className={styles.studyRecordImages}>
      {studyRecordImages.map((image: any, i: number) => {
        return (
          <div
            className={styles.studyRecordImage}
            key={`TimelineItem-${studyRecord.id}-studyRecordImage-${i}`}
            data-testid="timeline-item-image"
          >
            <a href={image.url} target="_blank" rel="noreferrer">
              <img src={image.url} />
            </a>
          </div>
        );
      })}
    </div>
  );
};
