import * as React from "react";
import StudyRecordInterface, {
  StudyRecordCommentInterface,
} from "../../../../../../interfaces/StudyRecordInterface";
import CommentItem from "../../../../../molecules/CommentItem";
import { useDeleteStudyRecordComment } from "./useDeleteStudyRecordComment";

interface Props {
  studyRecord: StudyRecordInterface;
  comment: StudyRecordCommentInterface;
  studentId: string;
  onDeleteComment: (comment: StudyRecordCommentInterface) => void;
}

export const TimelineComment = ({
  studentId,
  studyRecord,
  comment,
  onDeleteComment,
}: Props) => {
  const { mutate } = useDeleteStudyRecordComment({
    comment: comment,
    studentId: studentId,
    studyRecordHashid: studyRecord.id,
    onSuccess: (comment) => {
      onDeleteComment(comment);
    },
  });

  const handleDeleteStudyRecordComment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    mutate();
  };

  return (
    <CommentItem
      onDelete={handleDeleteStudyRecordComment}
      senderName={comment.senderName}
      sentByStudent={comment.sentByStudent}
      createdAt={comment.createdAt}
      comment={comment.body}
    />
  );
};
