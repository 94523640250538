import {
  Breadcrumbs as SharedBreadCrumbs,
  BreadCrumbItem,
} from "../../../components/general/Breadcrumbs";
import { Link } from "react-router-dom";

type Props = {
  sectionId: string;
  lectureName: string;
};
export const BreadCrumbs = (props: Props) => {
  return (
    <SharedBreadCrumbs items={makeItems(props)}>
      {(item, isLast) =>
        isLast || !item.url ? (
          <BreadCrumbItem>{item.label}</BreadCrumbItem>
        ) : (
          <BreadCrumbItem>
            <Link to={item.url}>{item.label}</Link>
          </BreadCrumbItem>
        )
      }
    </SharedBreadCrumbs>
  );
};

export const makeItems = ({ sectionId, lectureName }: Props) => {
  return [
    {
      label: "出席記録",
      url: `/sections/${sectionId}/analytics/lecture_attendance/lecture${window.location.search}`,
    },
    { label: lectureName },
  ];
};
