import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../errors";
import ApiClient from "../../api";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import ApiResponse from "../../interfaces/ApiResponse";
import { StudentDrillRetentionLevel } from "../../domains/StudentDrillRetentionLevel";

type Response = ApiResponse<StudentDrillRetentionLevel>;

type Props = {
  studentId: string;
};

export const useFetchStudentDrillRetentionLevel = ({ studentId }: Props) => {
  const { data, ...result } = useInfiniteQuery<Response, HTTPErrors>({
    queryKey: cacheKeyOf(studentId),
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.get(
        `/api/v1/students/${studentId}/analytics/drill_learning_materials`,
        {
          query: {
            page: pageParam,
            student_id: studentId,
          },
        },
      );
      if (response.status === 204) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            totalPages: 0,
          },
        };
      }
      if (response.status === 200) {
        const json = await response.json();
        return json.drillLearningMaterialAnalyticsTable;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  return {
    data: data?.pages.flatMap((page) => page.data),
    ...result,
  };
};

const cacheKeyOf = (studentId: string) => [
  `/students/${studentId}/analytics/drill_learning_materials`,
];
