import { createAction } from "redux-actions";
import { Dispatch } from "redux";
import ApiResponse from "../../../interfaces/ApiResponse";
import StaffBoardPostInterface from "../../../interfaces/StaffBoardPostInterface";
import { StaffBoardApi } from "../../../api-clients/StaffBoardApi";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import { apiRequestError } from "./../../apiRequestError";
import {
  StaffBoardPostCommentInterface,
  StaffBoardPostFormInterface,
  StaffBoardPostCommentFormInterface,
} from "../../../interfaces/StaffBoardPostInterface";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
} from "../../flashMessage";
import { buildMainResourceApiErrorAction } from "../../common/errors";

const getStaffBoardPostsRequest = createAction(
  "sections/staff_board_posts/GET_REQUEST",
);
const getStaffBoardPostsInitialSuccess = createAction(
  "sections/staff_board_posts/GET_REQUEST_INITIAL_SUCCESS",
);
const getStaffBoardPostsSuccess = createAction(
  "sections/staff_board_posts/GET_REQUEST_SUCCESS",
);
const getStaffBoardPostsError = createAction(
  "sections/staff_board_posts/GET_REQUEST_FAILURE",
);
export const getStaffBoardPostsInitial =
  (sectionId: string) => (dispatch: Dispatch) => {
    dispatch(getStaffBoardPostsRequest());
    StaffBoardApi.getPosts(sectionId, 1)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(
              (json: {
                staffBoardPosts: ApiResponse<StaffBoardPostInterface[]>;
              }) => {
                dispatch(getStaffBoardPostsInitialSuccess(json));
              },
            );
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStaffBoardPostsError());
        }
      })
      .catch(() => {
        dispatch(getStaffBoardPostsError());
        dispatch(apiRequestError());
      });
  };

export const getStaffBoardPosts =
  (sectionId: string, page: number) => (dispatch: Dispatch) => {
    dispatch(getStaffBoardPostsRequest());
    StaffBoardApi.getPosts(sectionId, page)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(
              (json: {
                staffBoardPosts: ApiResponse<StaffBoardPostInterface[]>;
              }) => {
                dispatch(getStaffBoardPostsSuccess(json));
              },
            );
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStaffBoardPostsError());
        }
      })
      .catch(() => {
        dispatch(getStaffBoardPostsError());
        dispatch(apiRequestError());
      });
  };

const createStaffBoardPostRequest = createAction(
  "sections/staff_booard_posts/CREATE_REQUEST",
);
const createStaffBoardPostSuccess = createAction(
  "sections/staff_booard_posts/CREATE_REQUEST_SUCCESS",
);
const createStaffBoardPostError = createAction(
  "sections/staff_booard_posts/CREATE_REQUEST_FAILURE",
);
export const createStaffBoardPost =
  (
    sectionId: string,
    params: StaffBoardPostFormInterface,
    resetForm: () => void,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(createStaffBoardPostRequest());
    StaffBoardApi.createPost(sectionId, params)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((json: { staffBoardPost: StaffBoardPostInterface }) => {
              dispatch(createStaffBoardPostSuccess(json));
              dispatch(showSuccessFlashMessage("スタッフボードに投稿しました"));
              resetForm();
            });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(createStaffBoardPostError(json));
            dispatch(
              showErrorFlashMessage("スタッフボードに投稿できませんでした"),
            );
          });
        } else {
          dispatch(createStaffBoardPostError());
          dispatch(
            showErrorFlashMessage("スタッフボードに投稿できませんでした"),
          );
        }
      })
      .catch(() => {
        dispatch(createStaffBoardPostError());
        dispatch(apiRequestError());
      });
  };

const deleteStaffBoardPostRequest = createAction(
  "sections/staff_board_posts/DELETE_REQUEST",
);
const deleteStaffBoardPostSuccess = createAction(
  "sections/staff_board_posts/DELETE_REQUEST_SUCCESS",
);
const deleteStaffBoardPostError = createAction(
  "sections/staff_board_posts/DELETE_REQUEST_FAILURE",
);
export const deleteStaffBoardPost =
  (sectionId: string, staffBoardPostId: string) => (dispatch: Dispatch) => {
    dispatch(deleteStaffBoardPostRequest({ staffBoardPostId }));
    StaffBoardApi.deletePost(sectionId, staffBoardPostId)
      .then((res) => {
        if (res.ok) {
          dispatch(deleteStaffBoardPostSuccess({ staffBoardPostId }));
          dispatch(showSuccessFlashMessage("投稿を削除しました"));
        } else {
          dispatch(deleteStaffBoardPostError({ staffBoardPostId }));
          dispatch(showErrorFlashMessage("投稿を削除できませんでした"));
        }
      })
      .catch(() => {
        dispatch(deleteStaffBoardPostError({ staffBoardPostId }));
        dispatch(apiRequestError());
      });
  };

const postStaffBoardPostCommentRequest = createAction(
  "sections/staff_board_posts/POST_COMMENT_REQUEST",
);
const postStaffBoardPostCommentSuccess = createAction(
  "sections/staff_board_posts/POST_COMMENT_REQUEST_SUCCESS",
);
const postStaffBoardPostCommentError = createAction(
  "sections/staff_board_posts/POST_COMMENT_REQUEST_FAILURE",
);
export const postComment =
  (
    sectionId: string,
    staffBoardPostId: string,
    params: StaffBoardPostCommentFormInterface,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(postStaffBoardPostCommentRequest({ staffBoardPostId }));

    StaffBoardApi.postComment(sectionId, staffBoardPostId, params)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(
              (json: {
                staffBoardPostComment: StaffBoardPostCommentInterface;
              }) => {
                const payload = { ...json, staffBoardPostId };
                dispatch(postStaffBoardPostCommentSuccess(payload));
                dispatch(showSuccessFlashMessage("コメントを投稿しました"));
              },
            );
        } else {
          dispatch(postStaffBoardPostCommentError({ staffBoardPostId }));
          dispatch(showErrorFlashMessage("コメントを投稿できませんでした"));
        }
      })
      .catch(() => {
        dispatch(postStaffBoardPostCommentError({ staffBoardPostId }));
        dispatch(apiRequestError());
      });
  };

const deleteStaffBoardPostCommentRequest = createAction(
  "sections/staff_board_posts/DELETE_COMMENT_REQUEST",
);
const deleteStaffBoardPostCommentSuccess = createAction(
  "sections/staff_board_posts/DELETE_COMMENT_REQUEST_SUCCESS",
);
const deleteStaffBoardPostCommentError = createAction(
  "sections/staff_board_posts/DELETE_COMMENT_REQUEST_FAILURE",
);
export const deleteComment =
  (sectionId: string, staffBoardPostId: string, commentId: string) =>
  (dispatch: Dispatch) => {
    dispatch(deleteStaffBoardPostCommentRequest({ staffBoardPostId }));

    StaffBoardApi.deleteComment(sectionId, staffBoardPostId, commentId)
      .then((res) => {
        if (res.ok) {
          const payload = { staffBoardPostId, commentId };
          dispatch(deleteStaffBoardPostCommentSuccess(payload));
          dispatch(showSuccessFlashMessage("コメントを削除しました"));
        } else {
          dispatch(deleteStaffBoardPostCommentError({ staffBoardPostId }));
          dispatch(showErrorFlashMessage("コメントを削除できませんでした"));
        }
      })
      .catch(() => {
        dispatch(deleteStaffBoardPostCommentError({ staffBoardPostId }));
        dispatch(apiRequestError());
      });
  };
