import * as React from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import { AuthenticatedPageCallbacks } from "../../../hocs/enhanceAuthenticatedPage";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { useEffect } from "react";
import Loader from "../../../components/atoms/Loader";
import SkillMasters from "../../../components/organisms/SkillMasters";
import {
  OutletContextProps,
  withRouter,
  WithRouterProps,
} from "../../../helpers/RouterHelper";
import { useFetchSkillMasters } from "./useFetchSkillMasters";

interface OwnProps {
  section: SectionInterface;
}
interface Props
  extends WithRouterProps,
    AuthenticatedPageCallbacks,
    OwnProps,
    OutletContextProps {}

const SectionsSettingsSkillMastersPage: React.FC<Props> = (props) => {
  useEffect(() => {
    props.setActiveMenu("skills");
  }, []);

  const { data, isLoading } = useFetchSkillMasters();

  return (
    <>
      {!isLoading && data && (
        <SkillMasters section={props.section} skillMasters={data} />
      )}
      <Loader loading={isLoading} />
    </>
  );
};

const pageInfo = {
  title: "アシスタント",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsSkillMastersPage,
  pageInfo,
  ["assistant:index"],
);

export default withRouter<Props>(EnhancedPage as any);
