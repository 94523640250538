import { IconLock } from "@studyplus/boron-ui";
import StudyRecordInterface from "../../../../../interfaces/StudyRecordInterface";
import Amount from "../../../../atoms/Amount";
import Time from "../../../../atoms/Time";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../../../general/Popover/Popover";

export const RestrictedContent = ({
  studyRecord,
}: {
  studyRecord: StudyRecordInterface;
}) => {
  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger className="flex cursor-pointer">
          <RestrictedRecord studyRecord={studyRecord} />
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center">
              プロプランにすると生徒が記録した
              <br />
              全学習記録が閲覧できます
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

const RestrictedRecord = ({
  studyRecord,
}: {
  studyRecord: StudyRecordInterface;
}) => {
  return (
    <>
      <IconLock className="mt-6 align-middle text-gray-800" />
      <div className="mt-6">
        <div>
          <Time
            seconds={studyRecord.numberOfSeconds}
            size="small"
            isRestrictions
          />
        </div>
        <div className="mt-3">
          <Amount
            unit={studyRecord.unit}
            amount={studyRecord.amount}
            startPosition={studyRecord.startPosition}
            endPosition={studyRecord.endPosition}
            size="small"
            isRestrictions
          />
        </div>
      </div>
    </>
  );
};
