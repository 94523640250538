import SectionInterface from "../../../interfaces/SectionInterface";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import {
  LectureAttendanceForSection,
  AttandanceStatus,
} from "../../../domains/LectureAttendance";
import { createError, HTTPErrors } from "../../../errors";
import { cacheKeyOf as attendanceCacheKeyOf } from "./useFetchLectureAttedances";
import { cacheKeyOf as summaryCacheKeyOf } from "../LectureSessionSummary/useFetchLectureSession";

type Props = {
  section: SectionInterface;
  lectureSessionId: string;
  onError: () => void;
  onSuccess: () => void;
};

export type UpdateAttendanceActions =
  | { type: "forAttendance" }
  | {
      type: "forAbsence";
      param: {
        absenceReason: LectureAttendanceForSection["absenceReason"];
        absenceDescription: LectureAttendanceForSection["absenceDescription"];
      };
    }
  | {
      type: "forStaffShareNote";
      param: {
        staffShareNote: LectureAttendanceForSection["staffShareNote"];
      };
    };

type UpdateAttendanceParams = {
  targets: ReadonlyArray<LectureAttendanceForSection>;
  action: UpdateAttendanceActions;
};

export const useMutateAttendance = ({
  section,
  lectureSessionId,
  onError,
  onSuccess,
}: Props) => {
  const client = useQueryClient();
  return useMutation<void, HTTPErrors, UpdateAttendanceParams>({
    mutationFn: async ({ targets, action }) => {
      const ids = targets.map((target) => target.id);
      const params = () => {
        switch (action.type) {
          case "forStaffShareNote": {
            return {
              lecture_attendance: {
                staff_share_note: action.param.staffShareNote,
              },
            };
          }
          case "forAbsence": {
            return {
              lecture_attendance_ids: ids,
              lecture_attendance: {
                attendance_status: AttandanceStatus.absence,
                absence_reason: action.param.absenceReason,
                absence_description: action.param.absenceDescription,
              },
            };
          }
          case "forAttendance": {
            return {
              lecture_attendance_ids: ids,
              lecture_attendance: {
                attendance_status: AttandanceStatus.presence,
              },
            };
          }
        }
      };
      const url =
        action.type === "forStaffShareNote"
          ? `${urlOf(section, lectureSessionId)}/${ids[0]}`
          : urlOf(section, lectureSessionId);

      const result = await api.patch(url, params());

      if (result.ok) {
        return;
      }
      throw await createError(result);
    },
    onError,
    onSuccess() {
      const attendanceCacheKey = attendanceCacheKeyOf(
        section,
        lectureSessionId,
      );
      client.invalidateQueries({ queryKey: attendanceCacheKey });
      const summaryCacheKey = summaryCacheKeyOf({
        sectionId: section.id,
        lectureSessionId,
      });
      client.invalidateQueries({ queryKey: summaryCacheKey });
      onSuccess();
    },
  });
};

const urlOf = (section: SectionInterface, lectureSessionId: string) =>
  `/api/v1/sections/${section.id}/lecture_sessions/${lectureSessionId}/lecture_attendances`;
