import * as React from "react";
import styles from "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import { BlockRow } from "../../components/atoms/BlockRow/index";
import { Flex } from "../../components/atoms/Flex/index";
import SwitchTabs from "../../components/molecules/SwitchTabs/index";
import { SwitchTabsProps } from "../../components/molecules/SwitchTabs/index";
import { ActiveTeacherTable } from "./ActiveTeacherTable";
import { ArchivedTeacherTable } from "./ArchivedTeacherTable";
import { useQueryError } from "../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import { useFetchTeachers } from "../../hooks/http/useFetchTeachers";
import Loader from "../../components/atoms/Loader/index";
import { ArchiveStatus } from "../../domains/Attendance";
import { useArchiveStatusParameter } from "./useArchiveStatusParameter";
import TabsNavi from "../../components/molecules/TabsNavi";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";

type Props = WithRouterProps<{ sectionId: string }> & OutletContextProps;
const Page = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("teachers");
  }, []);
  const { sectionId } = props.params;
  const { data, isLoading, isFetchingNextPage, archiveStatus } =
    useSectionsSettingsTeachersPage(sectionId);

  const navigate = useNavigate();
  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "講師",
            link: `/sections/${sectionId}/settings/teachers`,
            active: true,
          },
        ]}
      />
      <Flex justifyContent="space-between" alignItems="center" wrap>
        <SwitchTabs
          tabs={[
            {
              id: "active",
              label: "アクティブ",
              active: archiveStatus === ArchiveStatus.active,
            },
            {
              id: "archived",
              label: "アーカイブ",
              active: archiveStatus === ArchiveStatus.archived,
            },
          ]}
          onClickTab={(
              tab: Omit<SwitchTabsProps<"active" | "archived">, "onClick">,
            ) =>
            () => {
              const basePath = `/sections/${sectionId}/settings/teachers`;
              const path =
                tab.id === "archived" ? `${basePath}/archived` : basePath;

              if (location.pathname !== path) {
                navigate(path);
              }
            }}
        />
        <div className={styles.buttonContainer}>
          <Link
            to={`/sections/${sectionId}/settings/teachers/new`}
            className={`buiButtonBase buiButtonVariantFilled buiButtonBlock`}
          >
            講師の登録
          </Link>
        </div>
      </Flex>
      {
        <BlockRow marginTop="1rem">
          {isLoading ? (
            <Loader loading />
          ) : archiveStatus === ArchiveStatus.archived ? (
            <ArchivedTeacherTable sectionId={sectionId} teachers={data || []} />
          ) : (
            <ActiveTeacherTable sectionId={sectionId} teachers={data || []} />
          )}
          <Loader loading={isFetchingNextPage} />
        </BlockRow>
      }
    </>
  );
};

const useSectionsSettingsTeachersPage = (sectionId: string) => {
  const archiveStatus = useArchiveStatusParameter();
  const { sendNotFound } = useErrorDispatcher();
  if (archiveStatus === null) {
    sendNotFound();
  }

  const {
    isLoading,
    isFetchingNextPage,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useFetchTeachers({
    sectionId,
    status: archiveStatus || ArchiveStatus.active,
  });

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage, fetchNextPage]);

  useQueryError(error);

  return {
    isLoading,
    isFetchingNextPage,
    data,
    archiveStatus,
  };
};

const pageInfo = {
  title: "講師",
};

export const SectionsSettingsTeachersPage = enhanceSectionsSettingsPage(
  Page,
  pageInfo,
  ["teachers"],
);
