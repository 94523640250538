import enhanceHomePage from "../../hocs/enhanceHomePage";
import OperatorInterface from "../../interfaces/OperatorInterface";
import SettingsProfileEdit from "../../components/organisms/SettingsProfileEdit";

interface Props {
  currentOperator: OperatorInterface;
}

const SettingsProfilePage = (props: Props) => {
  return <SettingsProfileEdit operator={props.currentOperator} />;
};

const pageInfo = {
  title: "アカウント設定",
};

export default enhanceHomePage(SettingsProfilePage, pageInfo);
