import * as React from "react";
import SidebarLayout from "../../atoms/SidebarLayout/index";
import SectionInterface from "../../../interfaces/SectionInterface";
import NaviMenuLogo from "../../molecules/NaviMenuLogo/index";
import NaviMenuListItem from "../../molecules/NaviMenuListItem/index";
import NaviMenuList from "../../atoms/NaviMenuList/index";
import NaviMenuToggleButton from "../../molecules/NaviMenuToggleButton/index";
import SidebarFooter from "../../atoms/SidebarFooter/index";
import NaviMenuSchool from "../../molecules/NaviMenuSchool/index";
import NaviMenuSPLogo from "../../molecules/NaviMenuSPLogo/index";
import VisibleOnMobile from "../../atoms/VisibleOnMobile/index";

interface Props {
  section: SectionInterface;
  activeMenu: string;
  opened: boolean;
}

export default class SchoolNavi extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <SidebarLayout opened={this.props.opened} scrollable={false}>
          <NaviMenuLogo
            opened={this.props.opened}
            section={this.props.section}
          />
          <NaviMenuSPLogo
            opened={this.props.opened}
            section={this.props.section}
          />
          <NaviMenuList>
            <NaviMenuSchool
              opened={this.props.opened}
              section={this.props.section}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "dashboard"}
              label="ダッシュボード"
              iconName="icon-dashboard"
              link={`/sections/${this.props.section.id}`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "message"}
              label="メッセージ"
              iconName="icon-message"
              link={`/sections/${this.props.section.id}/message_threads`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "timeline"}
              label="タイムライン"
              iconName="icon-timeline"
              link={`/sections/${this.props.section.id}/timeline/daily`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "analytics"}
              label="アナリティクス"
              iconName="icon-analytics"
              link={`/sections/${this.props.section.id}/analytics/time`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "schedule_list"}
              label="カレンダー"
              iconName="icon-calendar"
              link={`/sections/${this.props.section.id}/schedules/list`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "study_schedules"}
              label="プランニング"
              iconName="icon-planning"
              link={`/sections/${this.props.section.id}/study_schedules`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "karte"}
              label="カルテ"
              iconName="icon-karte"
              link={`/sections/${this.props.section.id}/kartes`}
            />
            <NaviMenuListItem
              opened={this.props.opened}
              label="デジタル教材"
              iconName="icon-textbook"
              link={`/sections/${this.props.section.id}/settings/digital_learning_materials`}
            />
            <VisibleOnMobile>
              <NaviMenuListItem
                opened={this.props.opened}
                isActive={this.props.activeMenu === "setting"}
                label="設定"
                iconName="icon-setting"
                link={`/sections/${this.props.section.id}/settings/students`}
              />
            </VisibleOnMobile>
          </NaviMenuList>
          <SidebarFooter opened={this.props.opened}>
            <NaviMenuListItem
              opened={this.props.opened}
              isActive={this.props.activeMenu === "setting"}
              label="設定"
              iconName="icon-setting"
              link={`/sections/${this.props.section.id}/settings/students`}
            />
            <NaviMenuToggleButton />
          </SidebarFooter>
        </SidebarLayout>
      </React.Fragment>
    );
  }
}
