import * as React from "react";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { ContentVideoForm } from "./ContentVideoForm";
import {
  MainSection,
  PageHeader,
} from "../../../components/atoms/MainSection/index";
import BackLink from "../../../components/atoms/BackLink/index";
import { BlockRow } from "../../../components/atoms/BlockRow/index";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useIndex } from "./useIndex";
import { notFoundError, forbiddenError } from "../../../actions/common/errors";
import { connect, HandleThunkActionCreator } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../components/atoms/Loader";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { OutletContextProps } from "../../../helpers/RouterHelper";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
    tagFilter: StudentTagFilterInterface;
    notFoundError: HandleThunkActionCreator<typeof notFoundError>;
    forbiddenError: HandleThunkActionCreator<typeof forbiddenError>;
  };

// eslint-disable-next-line
const SectionsSettingsContentVideosNewOrEditPage_: React.FC<Props> = (
  props,
) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const { contentId } = useParams<{ contentId?: string }>();
  const state = useIndex({
    section: props.section,
    navigate: props.navigate,
    showErrorFlashMessage: props.showErrorFlashMessage,
    showSuccessFlashMessage: props.showSuccessFlashMessage,
    onNotFoundContent: props.notFoundError,
    onForbiddenContent: props.forbiddenError,
    contentId: contentId ?? null,
  });

  return (
    <>
      <BlockRow marginTop="2rem">
        <BackLink path={`/sections/${props.section.id}/settings/contents`}>
          戻る
        </BackLink>
      </BlockRow>
      <BlockRow marginTop="2.4rem">
        <MainSection>
          <PageHeader helpUrl="https://fs-help.studyplus.co.jp/ja/articles/4964874">
            {contentId ? "動画のURLの編集" : "動画のURLの登録"}
          </PageHeader>
          <BlockRow marginTop="1.6rem">
            {state.status === "ReadyUserInput" && (
              <ContentVideoForm
                initialContent={state.data ?? null}
                onSubmit={state.handleSubmit}
                isLoading={state.isLoading}
                isNew={state.isNew}
              />
            )}
          </BlockRow>
          <Loader loading={state.status === "LoadingContent"} />
        </MainSection>
      </BlockRow>
    </>
  );
};
const pageInfo = {
  title: "動画のURLの登録",
};

const actions = {
  notFoundError,
  forbiddenError,
};

export const SectionsSettingsContentVideosNewOrEditPage =
  enhanceSectionsSettingsPage(
    connect(null, actions)(SectionsSettingsContentVideosNewOrEditPage_),
    pageInfo,
  );
