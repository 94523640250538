import * as React from "react";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import BackLink from "../../../components/atoms/BackLink";
import { MainSection, PageHeader } from "../../../components/atoms/MainSection";
import styles from "./styles.scss";
import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../../components/atoms/Flex";
import Icon from "../../../components/atoms/Icon";
import { ContentFilesTable } from "./ContentFilesTable";
import { ProgressBar } from "../../../components/atoms/ProgressBar";
import {
  useSectionSettingsNewContentFiles,
  UseSectionSettingsNewContentFilesProps,
} from "./useSectionSettingsNewContentFiles";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { BlockRow } from "../../../components/atoms/BlockRow";
import { OutletContextProps } from "../../../helpers/RouterHelper";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    tagFilter: StudentTagFilterInterface;
  } & UseSectionSettingsNewContentFilesProps;

const SectionSettingsNewContentFiles_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const {
    filesState,
    inputFileRef,
    acceptTypes,
    onChangeFiles,
    onClickFile,
    onClickDelete,
    onSubmit,
  } = useSectionSettingsNewContentFiles(props);

  return (
    <div>
      <div className={styles.backLink}>
        <BackLink>戻る</BackLink>
      </div>
      <div className={styles.main}>
        <MainSection>
          <PageHeader helpUrl="https://fs-help.studyplus.co.jp/ja/articles/4964874">
            ファイルの登録
          </PageHeader>
          <BlockRow marginTop="1.2rem">
            <div className={styles.note}>
              ※アップロードできるファイル形式は、JPEG/PNG/PDFデータになります。一度にアップロードできる容量は100MBまでです。
            </div>
          </BlockRow>
          <div className={styles.btnChoose}>
            <Button
              className={styles["button--choose"]}
              variant="outline"
              disabled={filesState.status === "Loading"}
              onClick={onClickFile}
            >
              <Icon name="icon-plus-tag" />
              ファイルの選択
            </Button>
            <input
              ref={inputFileRef}
              type="file"
              onChange={onChangeFiles}
              hidden
              multiple
              accept={acceptTypes}
              data-testid="user-files"
            />
          </div>
          {filesState.status !== "Empty" && (
            <>
              <div className={styles.tableFiles}>
                <ContentFilesTable
                  files={filesState.context.files}
                  onClickDelete={onClickDelete}
                />
              </div>
              {filesState.status === "ValidationError" && (
                <div className={styles.error}>
                  {filesState.context.validationErrors.map((error, i) => (
                    <div key={`error-${i}`}>{error.message}</div>
                  ))}
                </div>
              )}
            </>
          )}
          <Flex marginTop="2.6rem" justifyContent="flex-end">
            {filesState.status === "Loading" && (
              <div className={styles.progress}>
                <div className={styles.progress__sizeInfo}>
                  ファイル容量合計：{filesState.context.size}
                </div>
                <div className={styles.progress__percentInfo}>
                  アップロード中… {filesState.context.progressPercent}%
                </div>
                <div className={styles.progress__bar}>
                  <ProgressBar
                    value={filesState.context.progressPercent}
                    variant="outline"
                  />
                </div>
              </div>
            )}

            <div>
              <Button
                onClick={onSubmit}
                disabled={
                  filesState.status === "Empty" ||
                  filesState.status === "ValidationError"
                }
                className={styles.submitButton}
                isLoading={filesState.status === "Loading"}
              >
                登録
              </Button>
            </div>
          </Flex>
        </MainSection>
      </div>
    </div>
  );
};

const pageInfo = {
  title: "ファイルの追加",
};

export const SectionSettingsNewContentFiles = enhanceSectionsSettingsPage(
  React.memo(SectionSettingsNewContentFiles_),
  pageInfo,
  ["contents"],
);
