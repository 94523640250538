import { createAction } from "redux-actions";
import { FlashMessageType } from "../interfaces/FlashMessageInterface";

export interface FlashMessageAction {
  type: string;
  payload: {
    type: FlashMessageType;
    message: string;
  };
}

// NOTE: ERRORとSUCCESSはよく使うのでsetFlashMessageとは別で関数にしてあります
export const showErrorFlashMessage = (message: string) => {
  return setFlashMessage({
    type: FlashMessageType.ERROR,
    message,
  });
};
export const showSuccessFlashMessage = (message: string) => {
  return setFlashMessage({
    type: FlashMessageType.SUCCESS,
    message,
  });
};
export const setFlashMessage = createAction<{
  type: FlashMessageType;
  message: string;
}>("SET_FLASH_MESSAGE");
export const resetFlashMessage = createAction("RESET_FLASH_MESSAGE");
