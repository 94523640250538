import * as yup from "yup";

// ファイルサイズのバリデーション
const oneMB = 1048576;
const validateFileSize = (file?: File) => (file ? file.size <= oneMB : true);
const fileSizeErrorMessage = "ファイルサイズは1MB以下にしてください";

// ファイルタイプのバリデーション
export const VALID_FILE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
const validateFileType = (file?: File) =>
  file ? VALID_FILE_TYPES.includes(file.type) : true;
const fileTypeErrorMessage =
  "アップロード可能なファイルはJPEG、GIF、PNG画像のみです";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("教材名は必須です")
    .max(128, "教材名は128文字以内で入力してください"),
  file: yup
    .mixed()
    .test("file", fileSizeErrorMessage, validateFileSize)
    .test("file", fileTypeErrorMessage, validateFileType),
});

export default validationSchema;
