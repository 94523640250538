import Loader from "../../components/atoms/Loader";
import GuardianMessageThreadListItem from "../../components/molecules/GuardianMessageThreadListItem";
import styles from "./SectionGuardianMessageThreadList.scss";
import { GuardianMessageThreadsProps } from "./SectionGuardianMessages";
import GuardianMessageThreadInterface from "../../interfaces/GuardianMessageThreadInterface";

interface Props {
  guardianMessageThreadsProps: GuardianMessageThreadsProps;
  onLoadMore: () => void;
  onClickThread: (destinationId: string) => () => void;
}

const SectionGuardianMessageThreadList = (props: Props) => {
  if (
    props.guardianMessageThreadsProps.loading &&
    props.guardianMessageThreadsProps.meta.currentPage === 0
  ) {
    return <Loader loading={props.guardianMessageThreadsProps.loading} />;
  }

  return (
    <div className={styles.root} onScroll={handleScroll(props)}>
      {renderList(props)}
      {renderLoader(props)}
    </div>
  );
};

const renderList = (props: Props) => {
  const { data, openedStudentId } = props.guardianMessageThreadsProps;
  if (!data) {
    return null;
  }
  return data.map((messageThread: GuardianMessageThreadInterface) => {
    return (
      <GuardianMessageThreadListItem
        key={messageThread.student.id}
        threadName={messageThread.student.fullName}
        latestGuardianMessage={messageThread.latestGuardianMessage}
        isActive={
          !!openedStudentId && openedStudentId === messageThread.student.id
        }
        onClickThread={props.onClickThread(messageThread.student.id)}
      />
    );
  });
};

const renderLoader = (props: Props) => {
  if (props.guardianMessageThreadsProps.loading && hasMoreData(props)) {
    return <Loader loading={props.guardianMessageThreadsProps.loading} />;
  } else {
    return null;
  }
};

const handleScroll = (props: Props) => (e: any) => {
  if (props.guardianMessageThreadsProps.loading) {
    return;
  }

  const scrollBottom = e.target.scrollTop + e.target.offsetHeight;
  if (scrollBottom + 100 >= e.target.scrollHeight) {
    props.onLoadMore();
  }
};

const hasMoreData = (props: Props): boolean => {
  const { currentPage, totalPages } = props.guardianMessageThreadsProps.meta;
  return currentPage < totalPages;
};

export default SectionGuardianMessageThreadList;
