import * as React from "react";
import { genId } from "../../../helpers/Id";

const AccordionContext = React.createContext<
  ReturnType<typeof useAccordion> | undefined
>(undefined);

const useAccordionContext = () => {
  return React.useContext(AccordionContext) as ReturnType<typeof useAccordion>;
};

const AccordionProvider = AccordionContext.Provider;

export { AccordionProvider, useAccordionContext };

type AccordionMode = "single" | "multiple";
export type UseAccordionProps = {
  mode?: AccordionMode;
};

type State = {
  ids: readonly string[];
  data: Readonly<Record<string, boolean>>;
  mode: AccordionMode;
};

type Actions =
  | {
      type: "ON_REGISTER";
      id: string;
      value?: boolean;
    }
  | {
      type: "ON_DELETE";
      id: string;
    }
  | {
      type: "ON_TOGGLE";
      id: string;
    };

const accordionReducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "ON_REGISTER": {
      return {
        ...state,
        ids: [...state.ids, action.id],
        data: { [action.id]: action.value ?? false },
      };
    }
    case "ON_DELETE": {
      const newIds = state.ids.filter((el) => el !== action.id);
      return {
        ...state,
        ids: newIds,
        data: newIds.reduce((p, c) => {
          return { ...p, [c]: state.data[c] };
        }, {}),
      };
    }
    case "ON_TOGGLE": {
      const targetValue = state.data[action.id];
      const newData =
        state.mode === "multiple"
          ? state.data
          : state.ids.reduce((p, c) => ({ ...p, [c]: false }), {});

      return {
        ...state,
        data: { ...newData, [action.id]: !targetValue },
      };
    }
  }
};

export const useAccordion = ({ mode = "multiple" }: UseAccordionProps) => {
  const [accordionState, dispatch] = React.useReducer(accordionReducer, {
    mode,
    ids: [],
    data: {},
  });

  const registerAccordionItem = () => {
    const id = genId("accordion");
    dispatch({ type: "ON_REGISTER", id });
    return id;
  };

  const toggleAccordionItem = (id: string) => {
    dispatch({ type: "ON_TOGGLE", id });
  };

  const deleteAccordionItem = (id: string) => {
    dispatch({ type: "ON_DELETE", id });
  };

  const getValue = (id: string) => accordionState.data[id];

  return {
    registerAccordionItem,
    toggleAccordionItem,
    deleteAccordionItem,
    getValue,
  };
};

const AccordionItemContext = React.createContext<
  ReturnType<typeof useAccordionItem> | undefined
>(undefined);

export const AccordionItemProvider = AccordionItemContext.Provider;
const useAccordionItemContext = () => {
  return React.useContext(AccordionItemContext) as ReturnType<
    typeof useAccordionItem
  >;
};

export const useAccordionItem = () => {
  const [myIndex, setMyIndex] = React.useState<string>("");
  const accordionContext = useAccordionContext();

  // DOM構築と同じタイミングで初期化する
  React.useLayoutEffect(() => {
    const id = accordionContext.registerAccordionItem();
    setMyIndex(id);
    return () => {
      accordionContext.deleteAccordionItem(id);
    };
  }, []);

  const onClick = () => {
    accordionContext.toggleAccordionItem(myIndex);
  };
  const isOpen = accordionContext.getValue(myIndex);

  return {
    pannelProps: {
      isOpen,
    },
    buttonProps: {
      isOpen,
      onClick,
    },
  };
};

export const useAccordionButton = () => {
  const itemContext = useAccordionItemContext();
  return {
    ...itemContext.buttonProps,
  };
};

export const useAccordionPanel = () => {
  const itemContext = useAccordionItemContext();
  return {
    ...itemContext.pannelProps,
  };
};
