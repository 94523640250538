import * as React from "react";
import { Helmet } from "react-helmet";

import Settings from "../../../helpers/Settings";
import styles from "./styles.scss";

interface PageInfoInterface {
  title: string;
}

interface Props {
  pageInfo: PageInfoInterface;
  children: React.ReactNode;
}

const StudentsTemplate = (props: Props) => {
  const { pageInfo, children } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {pageInfo.title} | {Settings.SITE_TITLE}
        </title>
      </Helmet>
      <div className={styles.container}>{children}</div>
    </React.Fragment>
  );
};

export default StudentsTemplate;
