import { LectureAttendanceWithSchedule } from "../domains/LectureAttendance";
import {
  LectureAttendanceForSection,
  LectureAttendanceWithLectureAnswers,
} from "../domains/LectureAttendance";

export const toLectureAttedanceForSectionFromJson = (
  json: Record<string, any>,
): LectureAttendanceForSection => {
  return {
    id: json.id,
    student: json.student,
    attendanceStatus: json.attendanceStatus,
    absenceReason: json.absenceReason,
    absenceDescription: json.absenceDescription,
    transferredLectureAttendanceStatus: json.transferredLectureAttendanceStatus,
    hasTransferredLectureAttendance: json.hasTransferredLectureAttendance,
    hasTransferOriginLectureAttendance: json.hasTransferOriginLectureAttendance,
    fromOtherSection: json.fromOtherSection,
    attendanceStatusUpdatedAt: json.attendanceStatusUpdatedAt
      ? new Date(json.attendanceStatusUpdatedAt)
      : null,
    staffShareNote: json.staffShareNote,
    attendanceUpdater: json.attendanceUpdater,
    locationStatus: json.locationStatus,
    lectureSessionId: json.lectureSessionId,
  };
};

export const toLectureAttedanceWithLectureAnswersFromJson = (
  json: Record<string, any>,
): LectureAttendanceWithLectureAnswers => {
  return {
    id: json.id,
    student: json.student,
    lectureAnswers: json.lectureAnswers,
  };
};

export const toLectureAttendanceWithScheduleFromJson = (
  json: Record<string, any>,
): LectureAttendanceWithSchedule => {
  return {
    ...toLectureAttedanceForSectionFromJson(json),
    schedule: {
      id: json.schedule.id,
      summary: json.schedule.summary,
      startAt: new Date(json.schedule.startAt),
      endAt: new Date(json.schedule.endAt),
    },
  };
};
