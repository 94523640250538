import * as React from "react";
import styles from "./styles.scss";
import SectionInterface from "../../../../interfaces/SectionInterface";
import GuardianAnnounceMessagTagSelector from "../GuardianAnnounceMessageTagSelector";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";

import { generateStorageKey } from "../../../../components/atoms/ResumableTextarea/useResumableTextarea";
import { useGuardianAnnounceMessageForm } from "./useGuardianAnnounceMessageForm";
import MessageForm from "../../../../components/features/GuardianMessage/MessageForm";
import { DestinationLabel } from "./DestinationLabel";
import { usePostGuardianAnnounceMessage } from "./usePostGuardianAnnounceMessage";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers";
import { UnprocessableEntityError } from "../../../../errors";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { useUpdateMessageThreadsCache } from "../useFetchMessageThreads";
import { useInsertGuardianAnnounceMessagesCache } from "../useFetchGuardianAnnounceMessages";

interface Props {
  section: SectionInterface;
  destinationTag?: GuardianAnnounceMessageTagInterface;
  destinationTags: GuardianAnnounceMessageTagInterface[];
  setOpenedThreadId: (threadId: string) => void;
  resetOpenedThreadId: () => void;
  isModalOpen: boolean;
  setIsModalOpen: (modalState: boolean) => void;
}

const buildDisabledPlaceholder = (
  destinationTag?: GuardianAnnounceMessageTagInterface,
): string => {
  if (!destinationTag) {
    return "送信先を選択してください。";
  } else if (destinationTag.studentCount === 0) {
    return "指定した送信対象が0件です。送信対象が存在するタグを指定して下さい。";
  } else if (!destinationTag.enabled) {
    return "対象のアシスタントスキルがオフになっているため送信出来ません。";
  } else {
    return "";
  }
};

const GuardianAnnounceMessageForm: React.FC<Props> = (props: Props) => {
  const { showErrorMessage } = useFlashMessage();

  const {
    formState,
    handleSubmit,
    handleSubmitSuccess,
    handleSubmitError,
    handleChangeContent,
    handleChangeFormFile,
  } = useGuardianAnnounceMessageForm(props);

  const { updateMessageThreadsCache } = useUpdateMessageThreadsCache({
    sectionId: props.section.id,
  });

  const { destinationTag, section } = props;

  const canSendMessage =
    !destinationTag ||
    destinationTag.studentCount === 0 ||
    !destinationTag.enabled;

  const insertGuardianAnnounceMessagesCache =
    useInsertGuardianAnnounceMessagesCache();

  const { mutate: mutateMessage } = usePostGuardianAnnounceMessage({
    sectionId: section.id,
    onSuccess: (messages) => {
      handleSubmitSuccess();
      if (!destinationTag) return;

      updateMessageThreadsCache({
        messageThread: {
          id: destinationTag.id,
          name: destinationTag.name,
          type: destinationTag.type,
        },
        latestMessage: messages[0], // 送信日時の降順なので配列の先頭が最新のメッセージ
      });
      insertGuardianAnnounceMessagesCache({
        sectionId: section.id,
        tagId: destinationTag.id,
        newMessages: messages,
      });
    },
    onError: (error) => {
      if (error instanceof UnprocessableEntityError) {
        handleSubmitError(error.originalErrors);
        showErrorMessage(
          error.originalErrors[0]?.message || HTTP_ERROR_MESSAGE,
        );
      } else {
        handleSubmitError([]);
        showErrorMessage(HTTP_ERROR_MESSAGE);
      }
    },
  });

  const handlePostMessage = (content?: string, file?: File) => {
    if (destinationTag && formState) {
      handleSubmit();
      mutateMessage({ tagId: destinationTag.id, content, file });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.control}>
        {destinationTag && destinationTag.studentCount > 0 ? (
          <DestinationLabel
            sectionId={section.id}
            destinationTag={destinationTag}
          />
        ) : (
          <span
            className={`${styles.destinationLabel} ${styles.destinationLabelNoRecipients}`}
          >
            保護者送信先： 0名
          </span>
        )}
        <div className={styles.selector}>
          <GuardianAnnounceMessagTagSelector
            section={props.section}
            guardianAnnounceMessageTags={props.destinationTags}
            isModalOpen={props.isModalOpen}
            onDecideTag={(tag: GuardianAnnounceMessageTagInterface) => {
              props.setOpenedThreadId(tag.id);
            }}
            onDeselectTag={props.resetOpenedThreadId}
            setIsModalOpen={props.setIsModalOpen}
            selectedDestinationId={props.destinationTag?.id}
          />
        </div>
      </div>
      <MessageForm
        formState={formState}
        canSendMessage={!canSendMessage}
        postMessage={handlePostMessage}
        changeFormContent={handleChangeContent}
        changeFormFile={handleChangeFormFile}
        allowedFileTypes={["jpg", "png", "gif", "pdf"]}
        disabledPlaceholder={buildDisabledPlaceholder(props.destinationTag)}
        storageKey={generateStorageKey([
          "guardian_messages",
          section.id,
          destinationTag?.id || "null",
          "new",
        ])}
      />
    </div>
  );
};

export default GuardianAnnounceMessageForm;
