import {
  StudentStayAnalytics,
  StudentStayAnalyticsSummary,
} from "../domains/StudentAnalytics";

export const fromJsonSummary = (json: unknown): StudentStayAnalyticsSummary => {
  const record = json as Record<string, any>;

  return {
    totalScore: record.totalScore,
    stays: record.stays.map((stay: Record<string, any>) => ({
      date: new Date(stay.date),
      totalScore: stay.totalScore,
      days: stay.days,
    })),
  };
};

export const fromJson = (json: unknown): StudentStayAnalytics => {
  const record = json as Record<string, any>;

  return {
    id: record.id,
    enteredAt: new Date(record.enteredAt),
    enteredBuildingName: record.enteredBuildingName,
    enteredStatus: record.enteredStatus,
    exitedAt: record.exitedAt ? new Date(record.exitedAt) : null,
    exitedBuildingName: record.exitedBuildingName,
    exitedStatus: record.exitedStatus,
    staySeconds: record.staySeconds,
    isExited: record.isExited,
    enteredSection: {
      id: record.enteredSection.id,
      fullName: record.enteredSection.fullName,
      schoolName: record.enteredSection.schoolName,
      sectionName: record.enteredSection.sectionName,
      isAllowedToReceiveGuardianMessage:
        record.enteredSection.isAllowedToReceiveGuardianMessage,
      guardianLineConnectionUsable:
        record.enteredSection.guardianLineConnectionUsable,
    },
  };
};
