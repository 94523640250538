import * as React from "react";
import { UIEvent } from "react";
import { connect, HandleThunkActionCreator } from "react-redux";
import { loadMoreDestinationTagOptionRecipients } from "../../../actions/pages/sectionAnnounceMessages/selector";
import styles from "./styles.scss";
import Icon from "../../atoms/Icon";
import Dropdown from "../../atoms/Dropdown";
import Loader from "../../atoms/Loader";
import { Props as FormProps } from "./index";

interface Props extends Pick<FormProps, "destinationTag"> {
  loadMoreDestinationTagOptionRecipients: HandleThunkActionCreator<
    typeof loadMoreDestinationTagOptionRecipients
  >;
}

const DestinationRecipients_: React.FC<Props> = (props) => {
  const { destinationTag } = props;
  const { handleRecipientsScroll, scrollRef } = useDestinationRecipients(props);

  if (!destinationTag || destinationTag.studentCount === 0) {
    return (
      <span
        className={`${styles.destinationLabel} ${styles.destinationLabelNoRecipients}`}
      >
        送信先：（0名）
        <Icon
          name="icon-arrow-down"
          className={styles.destinationLabel__icon}
        />
      </span>
    );
  }

  return (
    <Dropdown.Container>
      <Dropdown.Label>
        <span className={styles.destinationLabel}>
          送信先（{destinationTag.studentCount}名）
          <Icon
            name="icon-arrow-down"
            className={styles.destinationLabel__icon}
          />
        </span>
      </Dropdown.Label>
      <Dropdown.List position={{ bottom: "2rem", left: "0" }}>
        <div
          className={styles.recipientsContainer}
          onScroll={handleRecipientsScroll}
          ref={scrollRef}
        >
          <ul className={styles.recipients}>
            {destinationTag.recipients.data
              ? destinationTag.recipients.data.map((recipient) => (
                  <li
                    key={`AnnounceMessageform-destinationRecipients-${recipient.id}`}
                  >
                    {recipient.fullName}
                  </li>
                ))
              : null}
            <Loader loading={destinationTag.recipients.loading} />
          </ul>
        </div>
      </Dropdown.List>
    </Dropdown.Container>
  );
};

export const DestinationRecipients = connect(null, {
  loadMoreDestinationTagOptionRecipients,
})(DestinationRecipients_);

const useDestinationRecipients = (props: Props) => {
  const { destinationTag, loadMoreDestinationTagOptionRecipients } = props;

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const handleRecipientsScroll = (e: UIEvent<HTMLDivElement>) => {
    if (
      !destinationTag ||
      !destinationTag.recipients.data ||
      destinationTag.recipients.loading ||
      !destinationTag.recipients.hasMore
    ) {
      return;
    }

    if (
      (e.target as Element).scrollTop === 0 &&
      destinationTag &&
      destinationTag.recipients.meta
    ) {
      loadMoreDestinationTagOptionRecipients(
        destinationTag,
        destinationTag.recipients.meta.currentPage + 1,
      );
    }
  };

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, []);

  return {
    handleRecipientsScroll,
    scrollRef,
  };
};
