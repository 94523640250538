import SectionsSettingsSkillsEditStateInterface from "../../../interfaces/SectionsSettingsSkillsEditStateInterface";
import { Actions, ActionTypes } from "../../../actions/common/skills/types";

const defaultState: SectionsSettingsSkillsEditStateInterface = {
  skill: null,
  loading: false,
  submitting: false,
  showModal: false,
  apiErrors: [],
};

const sectionsSettingsSkillsEdit = (
  state: SectionsSettingsSkillsEditStateInterface = defaultState,
  action: Actions,
): SectionsSettingsSkillsEditStateInterface => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        submitting: false,
        showModal: true,
      };
    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors : [],
      };
    case ActionTypes.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        skill: action.payload.skill,
        loading: false,
      };
    case ActionTypes.GET_FAILURE:
      return {
        ...state,
        loading: false,
        apiErrors: action.payload ? action.payload.errors : [],
      };
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
};

export default sectionsSettingsSkillsEdit;
