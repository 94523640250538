import * as React from "react";
import styles from "./TimelineSummaryItem.scss";
import TimelineItem from "../../components/features/Timeline/TimelineItem";
import Time from "../../components/atoms/Time";
import Icon from "../../components/atoms/Icon/index";
import sum from "lodash-es/sum";
import { Link } from "react-router-dom";
import { BillingPlanLabel } from "../../components/features/BillingPlanLabel";
import { IconArrowRight, IconAttention2, IconPlus } from "@studyplus/boron-ui";
import {
  PopoverPortal,
  PopoverProvider,
} from "../../components/general/Popover/Popover";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/general/Popover/Popover";
import {
  SectionTimelineSummaryResponse,
  useFetchSectionsTimelineSummary,
} from "./useFetchSectionsTimelineSummary";
import StudyRecordInterface from "../../interfaces/StudyRecordInterface";
import { StudyRecordItem } from "./StudyRecordItem";
import { useDrawerContext } from "../../components/general/Drawer/useDrawerContext";
import { useSingleDateFilterContext } from "../../contexts/SingleDateFilterContext";
import { format } from "date-fns";
import { useEffectAvoidFirst } from "../../hooks/useEffectAvoidFirst";
import { toTimeString, truncateMinutes } from "../../helpers/TimeHelper";
import classNames from "classnames";
import { CommentFromStudent } from "./CommentFromStudent";
import DeviceHelper from "../../helpers/DeviceHelper";

interface Props {
  summary: SectionTimelineSummaryResponse["data"][0];
  abTestCodes: string[] | undefined;
  updateStudyRecordQueryCache?: ReturnType<
    typeof useFetchSectionsTimelineSummary
  >["updateStudyRecordQueryCache"];
}

const TimelineSummaryItem = (props: Props) => {
  return (
    <div
      className={styles.root}
      data-testid={`${props.summary.id}-timeline-summary-item`}
    >
      <Header {...props}>
        <Total summary={props.summary} />
      </Header>
      <TimelineItemsSwitcher {...props} />
    </div>
  );
};

const Header = (props: React.PropsWithChildren<Props>) => {
  const { summary, children } = props;

  return (
    <div className={styles.header}>
      <Icon name="icon-navi-avatar" className={styles.icon} />
      <div className={`${styles.name} gap-3`}>
        <Link to={`/students/${summary.id}/timeline`}>{summary.fullName}</Link>
        <BillingPlanLabel billingPlan={summary.billingPlan} />
      </div>
      {children}
    </div>
  );
};

type TotalProps = {
  summary: Props["summary"];
};

const Total = ({ summary }: TotalProps) => {
  const isTotalHidden = summary.billingPlan === "free";

  return isTotalHidden ? (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger className="ml-10 cursor-pointer text-black">
          Total:{" "}
          <div className="inline-flex items-center">
            <Time seconds={calcTotalSeconds(summary)} />
            <IconPlus className="h-3 w-3" />
            <IconAttention2 className="h-2 w-2 text-gray-900" />
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent>
            <div className="text-center text-sm font-normal">
              プロプランにすると生徒が記録した
              <br />
              全学習記録が閲覧できます
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  ) : (
    <div className="ml-10">
      Total: <Time seconds={calcTotalSeconds(summary)} />
    </div>
  );
};

const TimelineItemsForDrawerAndMobile = ({
  studyRecords,
  focusStudyRecordId,
  ...props
}: Props & {
  studyRecords: StudyRecordInterface[];
  focusStudyRecordId?: string;
}) => {
  const lastItemIndex = studyRecords.length - 1;
  return (
    <>
      {studyRecords.map((studyRecord, index) => {
        return (
          <TimelineItem
            studyRecord={studyRecord as StudyRecordInterface}
            student={props.summary}
            sectionId={props.summary.section.id}
            canSendComment={props.summary.canSendComment}
            key={studyRecord.id}
            updateStudyRecordQueryCache={props.updateStudyRecordQueryCache}
            isFocus={focusStudyRecordId === studyRecord.id}
            isLastItem={index === lastItemIndex}
          />
        );
      })}
    </>
  );
};

const TimelineItems = (props: Props) => {
  const {
    openDrawer: _openDrawer,
    setHeaderComponent,
    setBodyComponent,
  } = useDrawerContext();
  const { date } = useSingleDateFilterContext();
  const openDrawer = (studyRecordId?: string) => () => {
    setHeaderComponent(
      <Header {...props}>
        <span className="ml-8">{date && format(date, "yyyy年M月d日")}</span>
      </Header>,
    );
    setBodyComponent(
      <DrawerBody focusStudyRecordId={studyRecordId} {...props} />,
    );
    _openDrawer();
  };

  useEffectAvoidFirst(() => {
    // 画面でいいねしたときにドロワーに反映させるために再描画
    setBodyComponent(<DrawerBody {...props} />);
  }, [props.summary.studyRecords]);

  // NOTE: 記録日時の昇順に並び替えるためにreverse()を使用
  const reversedStudyRecords = React.useMemo(() => {
    return [...props.summary.studyRecords].reverse();
  }, [props.summary.studyRecords]);

  return (
    // NOTE: タブレット幅で横スクロールさせるために、MainLayoutのmin-widthにautoが指定されないようにする
    <div data-main-layout_min-width-auto_tablet="false">
      <div className="flex overflow-x-auto p-4">
        {reversedStudyRecords.map((studyRecord, i) => {
          const recordedHour = toTimeString(
            truncateMinutes(studyRecord.recordedAt),
          );
          const prevRecordedHour =
            i > 0
              ? toTimeString(
                  truncateMinutes(reversedStudyRecords[i - 1].recordedAt),
                )
              : "";

          const isBreakRecordedHour = recordedHour !== prevRecordedHour;

          return (
            <div
              key={studyRecord.id}
              className={classNames({ "ml-2": isBreakRecordedHour })}
            >
              <RecordedHour
                isBreakRecordedHour={isBreakRecordedHour}
                recordedHour={toTimeString(
                  truncateMinutes(studyRecord.recordedAt),
                )}
              />
              <div
                className="group flex cursor-pointer"
                onClick={openDrawer(studyRecord.id)}
              >
                <StudyRecordItem
                  studyRecord={studyRecord as StudyRecordInterface}
                  student={props.summary}
                  sectionId={props.summary.section.id}
                  canSendComment={props.summary.canSendComment}
                  updateStudyRecordQueryCache={
                    props.updateStudyRecordQueryCache
                  }
                />
                <CommentFromStudent
                  studyRecord={studyRecord as StudyRecordInterface}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="m-4 flex cursor-pointer items-center justify-between rounded border bg-gray-100 p-2"
        onClick={openDrawer()}
      >
        <span className="ml-2 font-bold text-blue-400">
          詳細を表示 ({props.summary.studyRecords.length}件)
        </span>
        <IconArrowRight className="h-[3rem] w-[3rem] text-blue-400" />
      </div>
    </div>
  );
};

const RecordedHour = ({
  isBreakRecordedHour,
  recordedHour,
}: {
  isBreakRecordedHour: boolean;
  recordedHour: string;
}) => {
  // 要素の高さを合わせたいのでinvisibleクラスを使用して出しわける
  return (
    <span className={classNames("ml-2", { invisible: !isBreakRecordedHour })}>
      {recordedHour}
    </span>
  );
};

type DrawerBodyProps = Props & { focusStudyRecordId?: string };
const DrawerBody = (props: DrawerBodyProps) => {
  const focusStudyRecordIdRef = React.useRef<string | undefined>(
    props.focusStudyRecordId,
  );
  return (
    <div className="max-w-[92rem]">
      <TimelineItemsForDrawerAndMobile
        studyRecords={
          [...props.summary.studyRecords].reverse() as StudyRecordInterface[]
        }
        focusStudyRecordId={focusStudyRecordIdRef.current}
        {...props}
      />
    </div>
  );
};

const TimelineItemsSwitcher = (props: Props) => {
  if (DeviceHelper.isScreenTablet()) {
    return <TimelineItems {...props} />;
  } else {
    return (
      <TimelineItemsForDrawerAndMobile
        studyRecords={props.summary.studyRecords as StudyRecordInterface[]}
        {...props}
      />
    );
  }
};

const calcTotalSeconds = (summary: Props["summary"]): number => {
  const numbersOfSeconds = summary.studyRecords.map(
    (studyRecord) => studyRecord.numberOfSeconds,
  );
  const totalSeconds = sum(numbersOfSeconds);

  return totalSeconds;
};

export default TimelineSummaryItem;
