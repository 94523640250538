import { createAction, Action } from "redux-actions";
import AppStateInterface from "../../interfaces/AppStateInterface";
import ApiClient from "../../api";
import { buildMainResourceApiErrorAction } from "./errors";

// GET /api/v1/sections/:section_hashid actions
export const getSectionRequestSending = createAction(
  "GET_SECTION_REQUEST_SENDING",
);
export const getSectionResponseReceived = createAction(
  "GET_SECTION_RESPONSE_RECEIVED",
);
export const getSectionResponseError = createAction(
  "GET_SECTION_RESPONSE_ERROR",
);
export const loadSection =
  (sectionId: string) =>
  (
    dispatch: (action: Action<any>) => void,
    getState: () => AppStateInterface,
  ): void => {
    const { currentOperator } = getState().session;
    if (!currentOperator) {
      return;
    }
    dispatch(getSectionRequestSending());

    ApiClient.get(`/api/v1/sections/${sectionId}`)
      .then((res) => {
        res
          .json()
          .then((json) => {
            if (res.ok) {
              dispatch(getSectionResponseReceived(json));
            } else {
              dispatch(buildMainResourceApiErrorAction(res.status, json));
            }
          })
          .catch(() => {
            dispatch(buildMainResourceApiErrorAction(res.status));
          });
      })
      .catch(() => {
        dispatch(buildMainResourceApiErrorAction());
      });
  };
