import { LearningMaterial } from "./LearningMaterial";
import { LearningMaterialTagWithDeletable } from "./LearningMaterialTag";

type LearningMaterialType =
  | "public"
  | "section"
  | "student"
  | "supplier"
  | "contents";

export type ManagementLearningMaterial = {
  learningMaterial: Omit<LearningMaterial, "code"> & {
    publicId: string;
    learningMaterialCode: string;
    id: string;
  };
  learningMaterialType: LearningMaterialType;
  tags: ReadonlyArray<LearningMaterialTagWithDeletable>;
  studentCount: number;
};

export type ManagementLearningMaterialList =
  ReadonlyArray<ManagementLearningMaterial>;

export const toLearningMaterialTypeLabel = (
  managementLearningMaterial: ManagementLearningMaterial,
) => {
  switch (managementLearningMaterial.learningMaterialType) {
    case "public":
      return "市販教材";
    case "section":
      return "校舎独自教材";
    case "student":
      return "生徒独自教材";
    case "supplier":
      return "連携教材";
    case "contents":
      return "コンテンツ配信教材";
  }
};
