import * as React from "react";

import Settings from "../../../helpers/Settings";
import SectionInterface from "../../../interfaces/SectionInterface";
import {
  SectionStaysStateInterface,
  StayFormInterface,
} from "../../../interfaces/StayInterface";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import SectionStayList from "../../molecules/SectionStayList";
import StudentFilter from "../../features/NewStudentFilter";
import TabsNavi from "../../molecules/TabsNavi";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { OperatorPrivilege } from "../../../interfaces/OperatorInterface";
import { requiredPrivileges as privilegesRequiredAtStaysDetailsPage } from "../../../features/SectionsSettingsStaysDetailsPage/index";

interface Props {
  section: SectionInterface;
  sectionStaysState: SectionStaysStateInterface;
  updateSectionStay: (
    sectionId: string,
    stayId: string,
    params: StayFormInterface,
  ) => void;
  deleteSectionStay: (sectionId: string, stayId: string) => void;
  privileges: OperatorPrivilege[];
}

const SectionsSettingsStays = (props: Props) => {
  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "入退室記録",
            link: "#",
            active: true,
          },
          {
            label: "入室中生徒",
            link: `/sections/${props.section.id}/settings/stays/staying_students`,
            active: false,
          },
        ]}
      />
      <div className={styles.navigation}>
        <div className={styles.filter}>
          <StudentFilter sectionId={props.section.id} />
        </div>
        {privilegesRequiredAtStaysDetailsPage.every((privilege) =>
          props.privileges.includes(privilege),
        ) && (
          <Link
            to="./details"
            className={`buiButtonBase buiButtonVariantOutline`}
          >
            <Icon name="icon-setting" className={styles.svg} />
            <span>入退室設定</span>
          </Link>
        )}
        <a
          href={Settings.BORON_ENTRY_URL}
          target="_blank"
          className={`buiButtonBase buiButtonVariantOutline ${styles.button}`}
          rel="noopener noreferrer"
        >
          <Icon name="icon-external-link" className={styles.svg} />
          <span>入退室受付画面</span>
        </a>
      </div>
      <SectionStayList
        sectionId={props.section.id}
        sectionStays={props.sectionStaysState.data}
        submitting={props.sectionStaysState.submitting}
        updateSectionStay={props.updateSectionStay}
        deleteSectionStay={props.deleteSectionStay}
      />
      <Loader loading={props.sectionStaysState.loading} />
    </React.Fragment>
  );
};

export default SectionsSettingsStays;
