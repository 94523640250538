import classNames from "classnames";
import Checkbox from "../../atoms/Checkbox";
import Loader from "../../atoms/Loader";
import { AdminSection } from "../../../interfaces/AdminSection";
import { MAX_COUNT_OF_SELECTABLE_SECTIONS } from "./useMultiSectionBatchesAdminSectionsSelectBox";

type Props = {
  hasSectionsError: boolean;
  isLoading: boolean;
  adminSections: AdminSection[];
  hasReachedSectionsLimit: boolean;
  toggleCheckbox: (id: string) => void;
};

export const MultiSectionBatchesAdminSectionsSelectBox = ({
  hasSectionsError,
  isLoading,
  adminSections,
  hasReachedSectionsLimit,
  toggleCheckbox,
}: Props) => {
  return (
    <div
      className={classNames(
        "flex flex-col gap-8 rounded-lg bg-[#fafafa] px-10 py-[22px]",
        {
          "border border-solid border-red-400": hasSectionsError,
        },
      )}
    >
      <div>
        <p>1. 出力したい校舎を選択してください</p>
        <p className="text-sm">
          ※最大{MAX_COUNT_OF_SELECTABLE_SECTIONS}校舎まで設定可能です
        </p>
      </div>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <div>
          {adminSections.length === 0 ? (
            <p className="text-red-400">管理者権限を持つ校舎がありません。</p>
          ) : (
            adminSections.map((section) => (
              <div key={section.id} className="flex">
                <span
                  onClick={() => toggleCheckbox(section.id)}
                  className="flex items-center"
                >
                  <Checkbox
                    checked={section.checked}
                    disabled={!section.checked && !hasReachedSectionsLimit}
                  />
                  <label
                    className={classNames("inline-block", {
                      "text-gray-500":
                        !section.checked && !hasReachedSectionsLimit,
                      "cursor-pointer":
                        section.checked || hasReachedSectionsLimit,
                      "cursor-not-allowed":
                        !section.checked && !hasReachedSectionsLimit,
                    })}
                  >
                    {section.fullName}
                  </label>
                </span>
              </div>
            ))
          )}
        </div>
      )}
      {hasSectionsError && (
        <p className="m-4 text-red-400">校舎を選択してください</p>
      )}
    </div>
  );
};
