import { useQuery } from "@tanstack/react-query";
import { createError } from "../../errors";
import ApiClient from "../../api";
import { MessageNotificationInterface } from "../../interfaces/NotificationInterface";

type Props = {
  sectionId: string;
  enabled: boolean;
};
type ResponseBody = { botMessageNotifications: MessageNotificationInterface[] };
export const useFetchBotMessages = ({ sectionId, enabled }: Props) => {
  const path = `/api/v1/sections/${sectionId}/bot_message_notifications`;
  return useQuery<ResponseBody, Error>({
    queryKey: [path],
    queryFn: async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json;
      }
      throw await createError(response);
    },
    enabled,
  });
};
