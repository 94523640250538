import * as React from "react";
import { SectionExaminationDetail } from "../../domains/SectionExamination";

export const useExaminationValues = (
  data: SectionExaminationDetail[] | undefined,
) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [name, setName] = React.useState("");
  const [lastExaminedOn, setLastExaminedOn] = React.useState("");
  const [organizerName, setOrganizerName] = React.useState("");
  const [classificationName, setClassificationName] = React.useState("");
  const [targetJobHuman, setTargetJobHuman] = React.useState("");

  React.useEffect(() => {
    if (data && data.length > 0) {
      setIsLoading(false);
      setName(data[0].examination.name);
      setLastExaminedOn(data[0].examination.lastExaminedOn);
      setOrganizerName(data[0].examination.organizer.name);
      setClassificationName(data[0].examination.classification.name);
      setTargetJobHuman(data[0].examination.targetJob.human);
    }
  }, [data?.[0]?.examination]);

  return {
    isLoading,
    name,
    lastExaminedOn,
    organizerName,
    classificationName,
    targetJobHuman,
  };
};
