import styles from "./styles.scss";
import CircleIcon from "../CircleIcon/index";
import classNames from "classnames";

type Size = "small" | "large";
type Theme = "red" | "gray";

interface Props {
  onDelete?: (e: any) => void;
  size?: Size;
  theme?: Theme;
}

const DeleteIcon = (props: Props) => {
  const size: Size = props.size || "small";
  const iconProps = {
    name: "icon-close-x",
    iconClassName: classNames(styles.icon, {
      [styles.smallSize]: size === "small",
      [styles.largeSize]: size === "large",
    }),
  };
  const theme: Theme = props.theme || "red";

  return (
    <CircleIcon
      iconProps={iconProps}
      className={classNames(styles.iconWrapper, {
        [styles.smallSize]: size === "small",
        [styles.largeSize]: size === "large",
        [styles.redTheme]: theme === "red",
        [styles.grayTheme]: theme === "gray",
      })}
      onClick={props.onDelete}
    />
  );
};

export default DeleteIcon;
