import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { addDays } from "date-fns";
import { paths } from "../../lib/api/v1";
import { useNavigate } from "react-router";
import { AdminSection } from "../../interfaces/AdminSection";
import { MultiSectionBatchType } from "../../interfaces/MultiSectionBatchesOperation";
import { createError } from "../../errors";
import { useFlashMessage } from "../useFlashMessage";

type Props = {
  adminSections: AdminSection[];
  startDate?: Date;
  multiSectionBatchType: MultiSectionBatchType;
};

type PathMap = {
  [key in MultiSectionBatchType]: `/api/v1/sections/multi_section_batches/${key}`;
};

export const useMultiSectionBatchesDownload = ({
  adminSections,
  startDate,
  multiSectionBatchType,
}: Props) => {
  const path: PathMap[MultiSectionBatchType] = `/api/v1/sections/multi_section_batches/${multiSectionBatchType}`;

  type RequestBody =
    paths[typeof path]["post"]["requestBody"]["content"]["application/json"];
  type ResponseBody =
    paths[typeof path]["post"]["responses"]["200"]["content"]["application/json"];

  const requestBody: RequestBody = constructRequestBody({
    adminSections,
    startDate,
  });

  const navigate = useNavigate();
  const { showErrorMessage } = useFlashMessage();

  const { isPending, mutate } = useMutation<ResponseBody>({
    mutationFn: async () => {
      const { response } = await boronClient.POST(path, {
        body: requestBody,
      });

      if (response.ok) {
        return response.json();
      }

      throw await createError(response);
    },
    onSuccess: (data) => {
      navigate(`/multi_section_batches/${data.multi_section_batch_history_id}`);
    },
    onError: () => {
      showErrorMessage("作成に失敗しました");
    },
  });

  return {
    isPending,
    mutate,
  };
};

const constructRequestBody = ({
  adminSections,
  startDate,
}: {
  adminSections: AdminSection[];
  startDate?: Date;
}) => {
  const selectedSectionPublicIds = adminSections
    .filter((section) => section.checked)
    .map((section) => section.id);

  if (!startDate) {
    return {
      section_public_ids: selectedSectionPublicIds,
    };
  }
  // NOTE: 現状は7日間(終了日を含まない)のデータをダウンロードする
  const endDate = addDays(startDate, 7);

  return {
    section_public_ids: selectedSectionPublicIds,
    datetime_from: startDate.toISOString(),
    datetime_to: endDate.toISOString(),
  };
};
