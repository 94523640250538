import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../atoms/Icon/index";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "../../general/Tooltip/Tooltip";

interface Props {
  id: string;
  iconName: string;
  className?: string;
  tooltipLabel?: string;
  disabled?: boolean;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

/**
 * ツールチップ表示を伴うアイコンボタン
 */
const TooltipIconButton = ({
  id,
  className,
  disabled,
  tooltipLabel,
  iconName,
  onClick,
}: Props) => {
  const buttonClassName = className
    ? `${styles.button} ${className}`
    : styles.button;

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            id={id}
            className={buttonClassName}
            data-for={`${id}-tooltip`}
            data-tip={true}
            onClick={onClick}
            type="button"
            disabled={disabled}
            aria-label={tooltipLabel}
            data-testid="test-tooltip-button"
          >
            <Icon
              name={iconName}
              disabled={disabled}
              hoverWithBackground={true}
            />
          </button>
        </TooltipTrigger>
        <TooltipPortal>
          {!disabled && (
            <TooltipContent
              side="top"
              sideOffset={5}
              data-testid="test-tooltip-content"
            >
              <p>{tooltipLabel}</p>
            </TooltipContent>
          )}
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipIconButton;
