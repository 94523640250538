import { useEffect, useState } from "react";
import { AdminSection } from "../../../interfaces/AdminSection";
import { useFetchAdminSections } from "../../../hooks/http/useFetchAdminSections";

type Props = {
  operatorId: string;
};

// NOTE: 最大選択可能校舎数
export const MAX_COUNT_OF_SELECTABLE_SECTIONS = 50;

export const useMultiSectionBatchesAdminSectionsSelectBox = ({
  operatorId,
}: Props) => {
  const { data, isLoading } = useFetchAdminSections(operatorId);

  const [adminSections, setAdminSections] = useState<AdminSection[]>([]);
  useEffect(() => {
    if (data === undefined) return;

    setAdminSections(
      data.sections.map((section) => ({ ...section, checked: false })),
    );
  }, [data]);

  const hasReachedSectionsLimit =
    adminSections.filter((section) => section.checked).length <
    MAX_COUNT_OF_SELECTABLE_SECTIONS;

  const toggleCheckbox = (id: string) => {
    if (
      !hasReachedSectionsLimit &&
      !adminSections.find((section) => section.id === id)?.checked
    ) {
      return;
    }

    setAdminSections((prevSections) => {
      return prevSections.map((section) => {
        return section.id === id
          ? { ...section, checked: !section.checked }
          : section;
      });
    });
  };

  return {
    adminSections,
    isLoading,
    hasReachedSectionsLimit,
    toggleCheckbox,
  };
};
