import Loader from "../../../components/atoms/Loader";
import { DrillLearningMaterialValue } from "../useStudyTaskForm";

export const SelectedDrillLearningMaterial = (props: {
  sectionId: string;
  drill: DrillLearningMaterialValue;
  isLoading: boolean;
  onClick: () => void;
}) => {
  const sectionCount = props.drill.sections.length;
  const contentCount = props.drill.sections.flatMap(
    (section) => section.contents,
  ).length;

  if (props.isLoading) {
    return (
      <div className="box-border w-full gap-4 rounded border border-solid border-blue-400 bg-gray-100 p-7 pr-9 text-left">
        <Loader loading />
      </div>
    );
  }

  return (
    <button
      className="box-border w-full gap-4 rounded border border-solid border-blue-400 bg-gray-100 p-7 pr-9 text-left"
      onClick={props.onClick}
    >
      <div className="flex flex-nowrap items-start gap-4">
        <div className="h-[4.5rem] w-[4.5rem] flex-shrink-0 flex-grow-0">
          {props.drill.imageUrl && (
            <img
              src={props.drill.imageUrl}
              alt={props.drill.name}
              className="h-full w-full object-contain"
            />
          )}
        </div>
        <div className="flex-shrink flex-grow">
          <div className="leading-8 text-black">{props.drill.name}</div>
          <div className="pt-4 text-sm text-gray-900">
            {props.drill.creatorName}
          </div>
        </div>
      </div>

      <div className="mt-5 text-md text-black">
        <div>セクション： {sectionCount}</div>
        <div className="mt-1">コンテンツ： {contentCount}</div>
      </div>
    </button>
  );
};
