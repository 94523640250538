import * as React from "react";
import Loader from "../../atoms/Loader";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import SectionInterface from "../../../interfaces/SectionInterface";
import BackLink from "../../atoms/BackLink/index";
import KarteTemplateForm, {
  KarteTemplateFormType,
} from "../../molecules/KarteTemplateForm";
import {
  deleteSectionsKarteTemplate,
  updateSectionsKarteTemplate,
} from "../../../actions/sectionsKarteTemplates";
import { SectionKarteTemplateEditStateInterface } from "../../../interfaces/SectionKarteTemplateInterface";
import { KarteTemplateFormInterface } from "../../../interfaces/KarteTemplateInterface";
import { NavigateFunction } from "react-router";

interface Props {
  section: SectionInterface;
  sectionKarteTemplateState: SectionKarteTemplateEditStateInterface;
  updateSectionsKarteTemplate: HandleThunkActionCreator<
    typeof updateSectionsKarteTemplate
  >;
  deleteSectionsKarteTemplate: HandleThunkActionCreator<
    typeof deleteSectionsKarteTemplate
  >;
}

const SectionsSettingsKarteTemplatesEdit = (props: Props) => {
  return (
    <React.Fragment>
      <BackLink>戻る</BackLink>
      {props.sectionKarteTemplateState.data && (
        <KarteTemplateForm
          type={KarteTemplateFormType.Edit}
          sectionId={props.section.id}
          karteTemplate={props.sectionKarteTemplateState.data}
          submitting={props.sectionKarteTemplateState.submitting}
          loading={props.sectionKarteTemplateState.loading}
          onSubmit={handleUpdateTemplate(props)}
          onDelete={props.deleteSectionsKarteTemplate}
          apiErrors={props.sectionKarteTemplateState.apiErrors}
        />
      )}
      <Loader loading={props.sectionKarteTemplateState.loading} />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppStateInterface, ownProps: any) => ({
  section: ownProps.section,
  sectionKarteTemplateState: state.sectionsSettingsKarteTemplatesEditState,
});

const handleUpdateTemplate =
  (props: Props) =>
  (values: KarteTemplateFormInterface, navigate: NavigateFunction) => {
    if (props.sectionKarteTemplateState.data) {
      props.updateSectionsKarteTemplate(
        props.section.id,
        props.sectionKarteTemplateState.data.id,
        values,
        navigate,
      );
    }
  };

const actions = {
  updateSectionsKarteTemplate,
  deleteSectionsKarteTemplate,
};

export default connect(
  mapStateToProps,
  actions,
)(SectionsSettingsKarteTemplatesEdit);
