import * as React from "react";

import { Period } from ".";
import { createContext } from "../../helpers/React";

const { useContext, Provider } =
  createContext<ReturnType<typeof useStudyTaskSwitchTabs>>();

export const useStudyTaskSwitchTabsContext = useContext;

// 校舎アナリティクス課題進捗の 期間中/期間前/期間終了 のタブの選択状態をグローバルに管理するhooks
const useStudyTaskSwitchTabs = () => {
  const [period, setPeriod] = React.useState<Period>("during");

  return {
    period,
    setPeriod,
  };
};

export const SectionAnalyticsStudyTaskTabsProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <Provider value={useStudyTaskSwitchTabs()}>{children}</Provider>;
};
