import * as yup from "yup";
import { EmailRegex } from "../../../consts/EmailRegex";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("メールアドレスを入力してください")
    .trim()
    .matches(EmailRegex, "不正なフォーマットです"),
});

export default validationSchema;
