import { GetRequestOptions } from "../api";
import ApiClient from "../api";
import { StayFormInterface } from "../interfaces/StayInterface";

const SectionStayApi = {
  getStays: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(`/api/v1/sections/${sectionId}/stays`, options);
  },

  interruptGetStays: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/sections/${sectionId}/stays`,
      options,
    );
  },

  update: (
    sectionId: string,
    stayId: string,
    params: StayFormInterface,
  ): Promise<Response> => {
    return ApiClient.patch(`/api/v1/sections/${sectionId}/stays/${stayId}`, {
      stay: params,
    });
  },

  delete: (sectionId: string, stayId: string): Promise<Response> => {
    return ApiClient.delete(`/api/v1/sections/${sectionId}/stays/${stayId}`);
  },
};

export default SectionStayApi;
