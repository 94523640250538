import { useQuery } from "@tanstack/react-query";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";
import { createError, HTTPErrors } from "../../../../errors";
import { boronClient } from "../../../../api";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { useCallback, useState } from "react";

type Props = {
  sectionId: string;
  initialOpenedTagId?: string;
};
export const useDestinationTag = ({ sectionId, initialOpenedTagId }: Props) => {
  const [openedTagId, setOpenedTagId] = useState<string | null>(
    initialOpenedTagId ?? null,
  );
  const { data, error } = useQuery<
    GuardianAnnounceMessageTagInterface[],
    HTTPErrors
  >({
    queryKey: ["guardian_announce_message_tags", sectionId],
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/guardian_announce_message_tags",
        { params: { path: { section_id: sectionId } } },
      );
      if (response.ok && data) {
        return data.guardianAnnounceMessageTags;
      }
      throw await createError(response);
    },
  });
  useQueryError(error);

  const resetOpenedTagId = useCallback(() => {
    setOpenedTagId(null);
  }, [setOpenedTagId]);

  return {
    destinationTags: data,
    openedThread: data?.find((tag) => tag.id === openedTagId) || null,
    setOpenedThreadId: setOpenedTagId,
    resetOpenedThreadId: resetOpenedTagId,
  };
};
