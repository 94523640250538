import styles from "./styles.scss";
import KarteInterface from "../../../interfaces/KarteInterface";
import KarteComment from "../KarteComment/index";
import KarteCommentForm from "../KarteCommentForm/index";

interface Props {
  karte: KarteInterface;
  studentId: string;
}

const renderComments = (props: Props) => {
  const { karte } = props;

  return karte.comments.map((comment) => (
    <KarteComment
      key={`KarteItemFooter-comment-${comment.id}`}
      karte={karte}
      comment={comment}
      studentId={props.studentId}
    />
  ));
};

const KarteItemFooter = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        {renderComments(props)}
        <KarteCommentForm karte={props.karte} studentId={props.studentId} />
      </div>
    </div>
  );
};

export default KarteItemFooter;
