import { createAction } from "redux-actions";
import { Dispatch, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { NavigateFunction } from "react-router";
import { StaffBoardApi } from "../../../api-clients/StaffBoardApi";
import StaffBoardPostInterface, {
  StaffBoardPostFormInterface,
  StaffBoardPostCommentInterface,
} from "../../../interfaces/StaffBoardPostInterface";
import { notFoundError } from "../../common/errors";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
} from "../../flashMessage";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import { apiRequestError } from "../../apiRequestError";
import { HTTP_ERROR_MESSAGE } from "../../../reducers";
import { buildMainResourceApiErrorAction } from "../../common/errors";

const getStaffBoardPostRequest = createAction(
  "sections/staff_board_post/GET_REQUEST",
);
const getStaffBoardPostSuccess = createAction(
  "sections/staff_board_post/GET_REQUEST_SUCCESS",
);
const getStaffBoardPostError = createAction(
  "sections/staff_board_post/GET_REQUEST_FAILURE",
);

export const getStaffBoardPost =
  (sectionId: string, staffBoardPostId: string) => (dispatch: Dispatch) => {
    dispatch(getStaffBoardPostRequest());
    StaffBoardApi.getPost(sectionId, staffBoardPostId)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((json: { staffBoardPost: StaffBoardPostInterface }) => {
              dispatch(getStaffBoardPostSuccess(json));
            });
        } else if (res.status === 404) {
          dispatch(notFoundError());
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStaffBoardPostError());
        }
      })
      .catch(() => {
        dispatch(getStaffBoardPostError());
      });
  };

const updateStaffBoardPostRequest = createAction(
  "sections/staff_board_post/UPDATE_REQUEST",
);
const updateStaffBoardPostSuccess = createAction(
  "sections/staff_board_post/UPDATE_REQUEST_SUCCESS",
);
const updateStaffBoardPostFailure = createAction(
  "sections/staff_board_post/UPDATE_REQUEST_FAILURE",
);
export const updateStaffBoardPost =
  (
    sectionId: string,
    staffBoardPostId: string,
    staffBoardPostParams: StaffBoardPostFormInterface,
    navigate: NavigateFunction,
  ): ThunkAction<
    void,
    Record<string, unknown>,
    Record<string, unknown>,
    AnyAction
  > =>
  (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      AnyAction
    >,
  ) => {
    dispatch(updateStaffBoardPostRequest());
    StaffBoardApi.updatePost(sectionId, staffBoardPostId, staffBoardPostParams)
      .then((res: Response) => {
        if (res.ok) {
          res
            .json()
            .then((json: { staffBoardPost: StaffBoardPostInterface }) => {
              dispatch(updateStaffBoardPostSuccess(json));
              dispatch(showSuccessFlashMessage("スタッフボードを更新しました"));
              navigate(`/sections/${sectionId}/staff_board_posts`);
            });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(updateStaffBoardPostFailure(json));
            dispatch(
              apiRequestError({
                errors: [{ message: "スタッフボードを更新できませんでした" }],
              }),
            );
          });
        } else {
          dispatch(updateStaffBoardPostFailure());
          dispatch(
            apiRequestError({
              errors: [{ message: "スタッフボードを更新できませんでした" }],
            }),
          );
        }
      })
      .catch(() => {
        dispatch(updateStaffBoardPostFailure());
        dispatch(
          apiRequestError({
            errors: [{ message: HTTP_ERROR_MESSAGE }],
          }),
        );
      });
  };

const deleteStaffBoardPostRequest = createAction(
  "sections/staff_board_post/DELETE_REQUEST",
);
const deleteStaffBoardPostSuccess = createAction(
  "sections/staff_board_post/DELETE_REQUEST_SUCCESS",
);
const deleteStaffBoardPostError = createAction(
  "sections/staff_board_post/DELETE_REQUEST_FAILURE",
);
export const deleteStaffBoardPost =
  (sectionId: string, staffBoardPostId: string, navigate: NavigateFunction) =>
  (dispatch: Dispatch) => {
    dispatch(deleteStaffBoardPostRequest());
    StaffBoardApi.deletePost(sectionId, staffBoardPostId)
      .then((res) => {
        if (res.ok) {
          dispatch(deleteStaffBoardPostSuccess());
          dispatch(showSuccessFlashMessage("投稿を削除しました"));
          navigate(`/sections/${sectionId}/staff_board_posts`);
        } else {
          dispatch(deleteStaffBoardPostError());
          dispatch(showErrorFlashMessage("投稿を削除できませんでした"));
        }
      })
      .catch(() => {
        dispatch(deleteStaffBoardPostError());
        dispatch(apiRequestError());
      });
  };

const postStaffBoardPostCommentRequest = createAction(
  "sections/staff_board_post/POST_COMMENT_REQUEST",
);
const postStaffBoardPostCommentSuccess = createAction(
  "sections/staff_board_post/POST_COMMENT_REQUEST_SUCCESS",
);
const postStaffBoardPostCommentError = createAction(
  "sections/staff_board_post/POST_COMMENT_REQUEST_FAILURE",
);
export const postComment =
  (
    sectionId: string,
    staffBoardPostId: string,
    params: {
      name: string;
      comment: string;
    },
  ) =>
  (dispatch: Dispatch) => {
    dispatch(postStaffBoardPostCommentRequest({ staffBoardPostId }));

    StaffBoardApi.postComment(sectionId, staffBoardPostId, params)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then(
              (json: {
                staffBoardPostComment: StaffBoardPostCommentInterface;
              }) => {
                dispatch(postStaffBoardPostCommentSuccess(json));
                dispatch(showSuccessFlashMessage("コメントを投稿しました"));
              },
            );
        } else {
          dispatch(postStaffBoardPostCommentError());
          dispatch(showErrorFlashMessage("コメントを投稿できませんでした"));
        }
      })
      .catch(() => {
        dispatch(apiRequestError());
      });
  };

const deleteStaffBoardPostCommentRequest = createAction(
  "sections/staff_board_post/DELETE_COMMENT_REQUEST",
);
const deleteStaffBoardPostCommentSuccess = createAction(
  "sections/staff_board_post/DELETE_COMMENT_REQUEST_SUCCESS",
);
const deleteStaffBoardPostCommentError = createAction(
  "sections/staff_board_post/DELETE_COMMENT_REQUEST_FAILURE",
);
export const deleteComment =
  (sectionId: string, staffBoardPostId: string, commentId: string) =>
  (dispatch: Dispatch) => {
    dispatch(deleteStaffBoardPostCommentRequest());

    StaffBoardApi.deleteComment(sectionId, staffBoardPostId, commentId)
      .then((res) => {
        if (res.ok) {
          const payload = { commentId };
          dispatch(deleteStaffBoardPostCommentSuccess(payload));
          dispatch(showSuccessFlashMessage("コメントを削除しました"));
        } else {
          dispatch(deleteStaffBoardPostCommentError());
          dispatch(showErrorFlashMessage("コメントを削除できませんでした"));
        }
      })
      .catch(() => {
        dispatch(apiRequestError());
      });
  };
