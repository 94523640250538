import * as React from "react";
import { connect } from "react-redux";
import AppStateInterface from "../interfaces/AppStateInterface";
import OperatorInterface from "../interfaces/OperatorInterface";
import { dispatchResetPageErrors } from "../actions/common/errors";
import ErrorsStateInterface from "../interfaces/ErrorsStateInterface";
import NotFoundErrorPage from "../containers/pages/NotFoundErrorPage/index";
import ForbiddenErrorPage from "../containers/pages/ForbiddenErrorPage/index";
import CannotAccessToSteakErrorPage from "../containers/pages/CannotAccessToSteakErrorPage/index";
import UnknownErrorPage from "../containers/pages/UnknownErrorPage/index";
import MaintenanceErrorErrorPage from "../containers/pages/MaintenanceErrorPage/index";
import LoginPage from "../containers/pages/LoginPage/index";
import { WithRouterProps } from "../helpers/RouterHelper";

interface DispatchProps {
  dispatchResetPageErrors: () => void;
}

interface StateProps {
  currentOperator: OperatorInterface | null;
  errors: ErrorsStateInterface;
}

type ErrorPageProps = DispatchProps & StateProps & WithRouterProps;

const mapStateToProps = (state: AppStateInterface) => {
  return {
    currentOperator: state.session.currentOperator,
    errors: state.errors,
  };
};

const actions = {
  dispatchResetPageErrors,
};

// ここはTSもインナークラスのPageからダイナミックに推論してるみたいなので、予め戻りの型をかくのが難しく時間がかかりそうなので、今は無視します。
// eslint-disable-next-line
const withErrorPages = (
  WrappedComponent: React.ComponentClass<any, any> | React.FC<any>,
) => {
  class Page extends React.Component<ErrorPageProps> {
    constructor(props: ErrorPageProps) {
      super(props);
    }

    componentWillUnmount() {
      this.props.dispatchResetPageErrors();
    }

    render() {
      if (this.props.errors.notFound) {
        return <NotFoundErrorPage />;
      }

      if (this.props.errors.forbidden) {
        if (!this.props.currentOperator) {
          return null;
        }

        return <ForbiddenErrorPage operator={this.props.currentOperator} />;
      }

      if (this.props.errors.steakError) {
        return (
          <CannotAccessToSteakErrorPage
            emergency={this.props.errors.steakError}
          />
        );
      }

      if (this.props.errors.maintenanceError) {
        return (
          <MaintenanceErrorErrorPage
            maintenance={this.props.errors.maintenanceError}
          />
        );
      }

      if (this.props.errors.unknownError) {
        return <UnknownErrorPage />;
      }

      if (this.props.errors.unauthorized) {
        return <LoginPage />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, actions)(Page);
};

export type WithErrorPages = ReturnType<typeof withErrorPages>;

export default withErrorPages;
