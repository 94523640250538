import { useState } from "react";
import styles from "./styles.scss";
import { Teacher } from "../../domains/Teacher";
import Icon from "../../components/atoms/Icon/index";
import AlignMiddle from "../../components/atoms/AlignMiddle/index";
import LinkButton from "../../components/atoms/LinkButton/index";
import { BlockRow } from "../../components/atoms/BlockRow/index";
import { Text } from "../../components/general/Text/Text";
import { useMutateTeacherArchiveStatus } from "../../hooks/http/useMutateTeacherArchiveStatus";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import {
  TeacherTable,
  TeacherTableRow,
  translateMutateResult,
} from "./TeacherTable";
import TeacherEditModal from "../../containers/pages/SectionsSettingsTeacherFormPage/TeacherEditModal";

type Props = {
  sectionId: string;
  teachers: Teacher[];
};

type UseTeacherEditModal = {
  isModalOpen: boolean;
  editingTeacher: Teacher | null;
  handleModalOpen: (teacher: Teacher) => void;
  handleModalClose: () => void;
};

export const ActiveTeacherTable = ({ sectionId, teachers }: Props) => {
  const onArchive = useArchiveTeacher(sectionId);
  const {
    isModalOpen,
    editingTeacher,
    handleModalOpen,
    handleModalClose,
  }: UseTeacherEditModal = useTeacherEditModal();

  if (teachers.length === 0) {
    return (
      <BlockRow marginTop="1.6rem">
        <Text align="center">
          講師の登録がありません。右上の「講師の登録」から登録ができます。
        </Text>
      </BlockRow>
    );
  }

  return (
    <>
      <TeacherTable>
        {teachers.map((teacher: Teacher) => (
          <TeacherTableRow teacher={teacher} key={teacher.id}>
            <div className={styles.editLinkContainer}>
              <LinkButton onClick={() => handleModalOpen(teacher)}>
                <AlignMiddle>
                  <Icon name="icon-edit" />
                  編集
                </AlignMiddle>
              </LinkButton>
            </div>
            <LinkButton onClick={() => onArchive(teacher.id, teacher.fullName)}>
              <AlignMiddle>
                <Icon name="icon-archive" />
                アーカイブ
              </AlignMiddle>
            </LinkButton>
          </TeacherTableRow>
        ))}
      </TeacherTable>
      {!editingTeacher ? null : (
        <TeacherEditModal
          sectionId={sectionId}
          isOpen={isModalOpen}
          teacherId={editingTeacher.id}
          closeModal={handleModalClose}
        />
      )}
    </>
  );
};

const useArchiveTeacher = (sectionId: string) => {
  const archiveStatusMutation = useMutateTeacherArchiveStatus({
    sectionId,
    status: "archived",
  });
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const archive = (teacherId: string, teacherName: string) => {
    if (
      confirm(
        `「${teacherName}」をアーカイブにしてもよろしいですか？
アーカイブに移動すると、予定に対して該当の講師が選択できなくなります。アーカイブはいつでもアクティブに戻すことができます。`,
      )
    ) {
      archiveStatusMutation.mutate(teacherId, {
        onSuccess: () => {
          const message = translateMutateResult("archived", "success");
          showSuccessMessage(message);
        },
        onError: () => {
          const message = translateMutateResult("archived", "failure");
          showErrorMessage(message);
        },
      });
    }
  };

  return archive;
};

const useTeacherEditModal = (): UseTeacherEditModal => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTeacher, setEditingTeacher] = useState<Teacher | null>(null);

  const handleModalOpen = (teacher: Teacher) => {
    setEditingTeacher(teacher);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setEditingTeacher(null);
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    editingTeacher,
    handleModalOpen,
    handleModalClose,
  };
};
