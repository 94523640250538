import * as React from "react";
import { AttendeesMenu } from "./AttendeesMenu";
import { DependantTable } from "../../../components/atoms/Table";
import { TableHeader } from "./AttendeesTable";
import { AttendeesTableRow } from "./AttendeesTableRow";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useFetchLectureAttendances } from "./useFetchLectureAttedances";
import { useQueryError } from "../../../hooks/http/useQueryError";
import Loader from "../../../components/atoms/Loader";
import styles from "./Attendees.scss";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { UpdateAttendanceModal } from "./UpdateAttendanceModal";
import {
  BatchActionDispatchProvider,
  BatchActionStateProvider,
  useAttendanceBatchActions,
  useBatchActionStateContext,
} from "./AttendBatchActionContext";
import {
  ModalDispatchProvider,
  ModalStateProvider,
  useUpdateModalAction,
} from "./UpdateModalActionContext";
import { LectureAttendanceForSection } from "../../../domains/LectureAttendance";
import { MixedCheckValue } from "../../../components/general/MixedCheckbox";
import LectureTransferReadModal from "./LectureTransferReadModal";

// Main Component
type Props = {
  section: SectionInterface;
  lectureSessionId: string;
};

export const Attendees = (props: Props) => {
  const {
    batchActionDispatchers,
    batchActionState,
    lectureAttedancesForSection,
    isLoading,
    isFetchingNextPage,
    shouldCheckAllSelected,
    modalState,
    modalDsipatchers,
  } = useAttendees(props);
  const {
    isOpenLectureTransferReadModal,
    handleLectureTransferReadModalOpen,
    handleLectureTransferReadModalClose,
    showingLectureAttendance,
  } = useLectureTransferReadModal(props.lectureSessionId);

  return (
    <>
      <BatchActionStateProvider value={batchActionState}>
        <BatchActionDispatchProvider value={batchActionDispatchers}>
          <ModalStateProvider value={modalState}>
            <ModalDispatchProvider value={modalDsipatchers}>
              <>
                {isLoading && <Loader loading />}
                {lectureAttedancesForSection && (
                  <>
                    <section>
                      <AttendeesMenu />
                    </section>
                    <section className={styles.AttendeesTableWrapper}>
                      {lectureAttedancesForSection && (
                        <DependantTable
                          aria-label="生徒出欠席一覧"
                          className={styles.AttendeesTable}
                        >
                          <TableHeader allSelected={shouldCheckAllSelected} />
                          <TableBody
                            lectureAttedancesForSection={
                              lectureAttedancesForSection
                            }
                            handleLectureTransferReadModalOpen={
                              handleLectureTransferReadModalOpen
                            }
                          />
                        </DependantTable>
                      )}
                      <Loader loading={isFetchingNextPage} />
                    </section>
                    <UpdateAttendanceModal />
                  </>
                )}
              </>
            </ModalDispatchProvider>
          </ModalStateProvider>
        </BatchActionDispatchProvider>
      </BatchActionStateProvider>
      <LectureTransferReadModal
        sectionId={props.section.id}
        isOpen={isOpenLectureTransferReadModal}
        lectureAttendance={showingLectureAttendance}
        closeModal={handleLectureTransferReadModalClose}
      />
    </>
  );
};

type TableBodyProps = {
  lectureAttedancesForSection: ReadonlyArray<LectureAttendanceForSection>;
  handleLectureTransferReadModalOpen: (
    lectureAttendanceForSection: LectureAttendanceForSection,
  ) => void;
};
export const TableBody = ({
  lectureAttedancesForSection,
  handleLectureTransferReadModalOpen,
}: TableBodyProps) => {
  const state = useBatchActionStateContext();
  return (
    <tbody>
      {lectureAttedancesForSection.map((lectureAttendanceForSection) => (
        <AttendeesTableRow
          lectureAttendanceForSection={lectureAttendanceForSection}
          selected={state.isSelectedWithId(lectureAttendanceForSection.id)}
          key={`attendee-${lectureAttendanceForSection.id}`}
          handleLectureTransferReadModalOpen={
            handleLectureTransferReadModalOpen
          }
        />
      ))}
    </tbody>
  );
};

// Main hooks

const useAttendees = (props: Props) => {
  const {
    data,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchLectureAttendances(props);
  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  const { dispatchers: batchActionDispatchers, state: batchActionState } =
    useAttendanceBatchActions({
      fnCurrentAllItems() {
        // 休講のlectureAttendanceは除外
        return data?.filter((d) => d.attendanceStatus !== "cancel") ?? [];
      },
    });

  const { state: modalState, dispatchers: modalDsipatchers } =
    useUpdateModalAction();

  return {
    modalState,
    modalDsipatchers,
    batchActionDispatchers,
    batchActionState,
    isLoading,
    isFetchingNextPage,
    lectureAttedancesForSection: data,
    shouldCheckAllSelected: determineCheckAllSelected(
      data?.length ?? 0,
      batchActionState.targets.length,
    ),
  };
};

export const useLectureTransferReadModal = (lectureSessionId: string) => {
  const [isOpenLectureTransferReadModal, setIsOpenLectureTransferReadModal] =
    React.useState(false);
  const [showingLectureAttendance, setShowingLectureAttendance] =
    React.useState<LectureAttendanceForSection | null>(null);

  const handleLectureTransferReadModalOpen = (
    lectureAttendanceForSection: LectureAttendanceForSection,
  ) => {
    setShowingLectureAttendance(lectureAttendanceForSection);
    setIsOpenLectureTransferReadModal(true);
  };

  const handleLectureTransferReadModalClose = () => {
    setShowingLectureAttendance(null);
    setIsOpenLectureTransferReadModal(false);
  };

  // 振替情報モーダルから別講義に遷移したときに初期化
  React.useEffect(() => {
    handleLectureTransferReadModalClose();
    setShowingLectureAttendance(null);
  }, [lectureSessionId]);

  return {
    isOpenLectureTransferReadModal,
    showingLectureAttendance,
    handleLectureTransferReadModalOpen,
    handleLectureTransferReadModalClose,
  };
};

const determineCheckAllSelected = (
  currentAllRecordCount: number,
  batchActiontargetCount: number,
): MixedCheckValue => {
  if (batchActiontargetCount === 0) {
    return false;
  }
  if (batchActiontargetCount === currentAllRecordCount) {
    return true;
  }
  return "indeterminate";
};
