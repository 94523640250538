import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import { isEqual, cloneDeep, merge } from "lodash-es";
import * as React from "react";

interface Props {
  data: ChartData<"bar">;
  options: ChartOptions<"bar">;
  setChart?: (chart: Chart) => void;
}

export default class BarChart extends React.Component<Props> {
  chart: Chart | null = null;
  chartRef: React.RefObject<any>;
  printMediaQueryList: MediaQueryList | null;

  constructor(props: Props) {
    super(props);
    this.chartRef = React.createRef();
    this.printMediaQueryList = null;
  }

  componentDidMount(): void {
    const ctx = this.chartRef.current.getContext("2d");
    Chart.register(...registerables);

    this.chart = new Chart(ctx, {
      options: this.props.options,
      data: this.props.data,
      type: "bar",
    });
    if (this.props.setChart) {
      this.props.setChart(this.chart);
    }

    if (!this.chart.canvas) {
      return;
    }
    this.printMediaQueryList = window.matchMedia("print");
    this.printMediaQueryList?.addListener(this.handleBeforePrint);
  }

  componentDidUpdate(prevProps: Props): void {
    if (!this.chart) {
      return;
    }

    if (this.datasetsChanged() || this.optionsChanged(prevProps.options)) {
      this.chart.options = this.props.options;
      this.chart.data = this.props.data;
      this.chart.update();
    }
  }

  componentWillUnmount(): void {
    if (!this.chart) {
      return;
    }

    this.chart.destroy();
    this.printMediaQueryList?.removeListener(this.handleBeforePrint);
  }

  render(): React.ReactNode {
    return <canvas ref={this.chartRef} />;
  }

  private datasetsChanged(): boolean {
    if (this.chart && this.chart.data.datasets && this.props.data.datasets) {
      return (
        this.chart.data.datasets.length !== this.props.data.datasets.length
      );
    } else {
      return false;
    }
  }
  private optionsChanged(prevOptions: ChartOptions<"bar">): boolean {
    if (this.chart && this.props.options) {
      // NOTE: prevOptionsはデフォルト値のプロパティを含んだ完全なオプションのオブジェクトとなっている一方で、propsのoptionsは変更したいプロパティだけを含んだオプションのオブジェクトとなっている
      //       そのため判定が正しく行われず、不要なタイミングで再描画されてしまう不具合があったので対処
      //       ネストが深いオプションをマージする必要があるのでlodashを使う
      const prev = cloneDeep(prevOptions);
      const current = merge(prev, this.props.options);
      return !isEqual(prev, current);
    } else {
      return false;
    }
  }

  private handleBeforePrint = (mql: MediaQueryListEvent) => {
    if (mql.matches && this.chart) {
      this.chart.resize();
    }
  };
}
