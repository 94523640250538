import * as React from "react";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../../hocs/enhanceSectionsSettingsPage";
import BackLink from "../../../components/atoms/BackLink";
import { MainSection, PageHeader } from "../../../components/atoms/MainSection";
import styles from "./SectionSettingsEditContentFiles.scss";
import { useSectionSettingsEditContentFiles } from "./useSectionSettingsEditContentFiles";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { useParams } from "react-router";
import SectionInterface from "../../../interfaces/SectionInterface";
import { ContentFileForm } from "./ContentFileForm";
import Loader from "../../../components/atoms/Loader";
import { connect, HandleThunkActionCreator } from "react-redux";
import {
  forbiddenError,
  notFoundError,
  unknownError,
} from "../../../actions/common/errors";
import { OutletContextProps } from "../../../helpers/RouterHelper";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
    tagFilter: StudentTagFilterInterface;
    notFoundError: HandleThunkActionCreator<typeof notFoundError>;
    forbiddenError: HandleThunkActionCreator<typeof forbiddenError>;
    unknownError: HandleThunkActionCreator<typeof unknownError>;
  };

const SectionSettingsEditContentFiles_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const sectionId = props.section.id;
  const { contentId = "" } = useParams<{ contentId: string }>();
  const { isLoading, content, mutate, isMutating } =
    useSectionSettingsEditContentFiles({
      sectionId,
      contentId,
      onNotFoundContent: props.notFoundError,
      onForbiddenContent: props.forbiddenError,
      onUnknownError: props.unknownError,
    });

  return (
    <div>
      <div className={styles.backLink}>
        <BackLink>戻る</BackLink>
      </div>
      <div className={styles.main}>
        <MainSection>
          <PageHeader>ファイルの編集</PageHeader>
          {content && (
            <ContentFileForm
              fetchedContent={content}
              mutate={mutate}
              isMutating={isMutating}
              className={styles.form}
            />
          )}
          <Loader loading={isLoading} />
        </MainSection>
      </div>
    </div>
  );
};

const pageInfo = {
  title: "ファイルの追加",
};

const actions = {
  notFoundError,
  forbiddenError,
  unknownError,
};

export const SectionSettingsEditContentFiles = enhanceSectionsSettingsPage(
  connect(null, actions)(SectionSettingsEditContentFiles_),
  pageInfo,
  ["contents"],
);
