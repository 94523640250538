import * as React from "react";
import styles from "./styles.scss";
import { Button } from "@studyplus/boron-ui";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import KarteInterface from "../../../interfaces/KarteInterface";
import { connect } from "react-redux";
import isEqual from "lodash-es/isEqual";
import { postKarteComment } from "../../../actions/karte";
import { PostKarteCommentParamsInterface } from "../../../api-clients/StudentKarteApi";
import OperatorInterface from "../../../interfaces/OperatorInterface";

interface Props {
  karte: KarteInterface;
  postKarteComment: (
    studentId: string,
    karteId: string,
    body: PostKarteCommentParamsInterface,
  ) => void;
  operator: OperatorInterface | null;
  studentId: string;
}

interface State {
  submitting: boolean;
  commentForm: {
    name: string;
    comment: string;
  };
}

class PresentationalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      submitting: false,
      commentForm: {
        name: this.props.operator ? this.props.operator.fullName : "",
        comment: "",
      },
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.commentsUpdated(prevProps)) {
      this.setState({ submitting: false });
    }
  }

  render() {
    return (
      <form
        className={styles.root}
        onSubmit={this.handleSubmit}
        aria-label="カルテコメント投稿フォーム"
      >
        <input
          className={styles.input}
          onChange={this.handleChangeName}
          value={this.commentForm.name}
        />
        <textarea
          placeholder="コメントを入力"
          className={styles.textarea}
          rows={1}
          onChange={this.handleChangeText}
          value={this.commentForm.comment}
        />
        <Button
          disabled={this.isDisabled()}
          className={styles.button}
          type="submit"
        >
          登録
        </Button>
      </form>
    );
  }

  get commentForm() {
    return this.state.commentForm;
  }

  private commentsUpdated(prevProps: Props): boolean {
    if (!prevProps.karte || !this.props.karte) {
      return false;
    }

    return !isEqual(prevProps.karte.comments, this.props.karte.comments);
  }

  private handleChangeName = (e: any) => {
    this.setState({
      commentForm: {
        ...this.state.commentForm,
        name: e.target.value,
      },
    });
  };

  private handleChangeText = (e: any) => {
    this.setState({
      commentForm: {
        ...this.state.commentForm,
        comment: e.target.value,
      },
    });
  };

  private handleSubmit = (e: any) => {
    e.preventDefault();

    if (this.isDisabled()) {
      return;
    }

    this.props.postKarteComment(this.props.studentId, this.props.karte.id, {
      karte_comment: this.state.commentForm,
    });

    this.resetCommentForm();
  };

  private resetCommentForm() {
    this.setState({
      commentForm: {
        name: this.props.operator ? this.props.operator.fullName : "",
        comment: "",
      },
    });
  }

  private isDisabled(): boolean {
    if (
      this.commentForm.comment.trim().length === 0 ||
      this.commentForm.name.trim().length === 0 ||
      this.state.submitting
    ) {
      return true;
    } else {
      return false;
    }
  }
}

const mapStateToProps = (state: AppStateInterface) => {
  return {
    operator: state.session.currentOperator,
  };
};

const actions = {
  postKarteComment,
};

const KarteCommentForm = connect(
  mapStateToProps,
  actions,
)(PresentationalComponent);

export default KarteCommentForm;
