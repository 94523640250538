import { Link } from "react-router-dom";
import { TableColumn } from "../../../components/atoms/Table";
import { SectionExaminationDetailStudent } from "../../../domains/SectionExamination";
import { displayItemType } from "../useFetchSectionExaminationDetail";
import { DeviationMap } from "./hooks/useDeviationMap";
import { getHeatmapClass } from "../../../helpers/AnalyticsHelper";
import { useFieldVisibilityToggleContext } from "../../../components/general/FieldVisibilityToggle/useFieldVisibilityToggleContext";

type Props = {
  examinationId: string;
  studentResults: SectionExaminationDetailStudent[];
  displayItem: displayItemType;
  deviationMap: DeviationMap;
};

export const TableRows = ({
  examinationId,
  studentResults,
  displayItem,
  deviationMap,
}: Props) => {
  const { isInvisible } = useFieldVisibilityToggleContext();

  return (
    <tbody>
      {studentResults.map(({ id, code, fullName, result, subjectResults }) => (
        <tr className="border-none" key={id}>
          {!isInvisible("生徒ID") && (
            <TableColumn className="left-0 z-10 h-auto bg-white" centering>
              {code}
            </TableColumn>
          )}
          <TableColumn
            className="sticky left-0 z-10 h-auto min-w-[20rem] max-w-[20rem] bg-white py-6"
            centering
          >
            <Link
              to={`/students/${id}/analytics/examinations/${examinationId}/result`}
              className="font-bold text-black underline"
            >
              {fullName}
            </Link>
          </TableColumn>
          <TableColumn
            centering
            className={`h-auto min-w-[12rem] max-w-[12rem] py-6 ${getHeatmapClass(
              deviationMap[id][0][displayItem],
              displayItem === "point"
                ? result.point || 0
                : result.deviation || 0,
            )}`}
          >
            {displayItem === "point" ? (
              <div className="whitespace-nowrap">
                {result.point}
                <span className="text-[12px]">
                  {" "}
                  / {result.allocationOfMarks}
                </span>
              </div>
            ) : (
              <div className="whitespace-nowrap">
                {result.deviation?.toFixed(1)}
              </div>
            )}
          </TableColumn>
          {subjectResults.map((subjectResult, index) => (
            <TableColumn
              centering
              key={`subjectResult-${index}`}
              className={`h-auto min-w-[12rem] max-w-[12rem] py-6 ${getHeatmapClass(
                deviationMap[id][index + 1][displayItem],
                displayItem === "point"
                  ? subjectResult.point || 0
                  : subjectResult.deviation || 0,
              )}`}
            >
              {displayItem === "point" ? (
                <div className="whitespace-nowrap">
                  {subjectResult.point}
                  <span className="text-[12px]">
                    {" "}
                    / {subjectResult.allocationOfMarks}
                  </span>
                </div>
              ) : (
                <div className="whitespace-nowrap">
                  {subjectResult.deviation?.toFixed(1)}
                </div>
              )}
            </TableColumn>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
