import * as React from "react";
import styles from "./styles.scss";
import Icon from "../Icon";
import classNames from "classnames";
import styleVars from "../../../styles/variables.scss";
import {
  DateRange,
  DynamicDateRangePicker,
} from "../../general/DynamicDateRangePicker";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import format from "date-fns/format";

interface Props {
  dates: DateRange;
  onChangeDates: (dates: DateRange) => void;
}

/**
 * フォーム要素として日付範囲選択カレンダーを利用するときのコンポーネント
 */
export const DateRangePickerField = (props: Props) => {
  const [isCalendarVisible, setIsCalendarVisible] =
    React.useState<boolean>(false);

  const handleClickController = React.useCallback(() => {
    setIsCalendarVisible(!isCalendarVisible);
  }, []);
  const handleClickOutside = React.useCallback(() => {
    setIsCalendarVisible(false);
  }, []);
  const { insideRef } = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  const handleDatesChange = React.useCallback(
    (dates: DateRange) => {
      const { startDate, endDate } = dates;
      props.onChangeDates(dates);
      if (startDate && endDate) {
        setIsCalendarVisible(false);
      }
    },
    [props.onChangeDates],
  );

  const numberOfMonths =
    window.innerWidth > parseInt(styleVars.breakpointMobile) ? 2 : 1;

  return (
    <div className={styles.root}>
      <Controller {...props.dates} onClick={handleClickController} />
      {isCalendarVisible && (
        <div ref={insideRef} className={styles.calendarContainer}>
          <DynamicDateRangePicker
            {...props.dates}
            onDateRangeChange={handleDatesChange}
            numberOfMonths={numberOfMonths}
            defaultMonth={props.dates.startDate}
          />
        </div>
      )}
    </div>
  );
};

// カレンダーの表示/非表示切り替え, 選択中の日付を表示する部分
const Controller = (props: DateRange & { onClick: () => void }) => {
  const { startDate, endDate, onClick } = props;

  return (
    <div
      className={classNames(styles.controller, {
        [styles.muted]: !startDate && !endDate,
      })}
      onClick={onClick}
    >
      <Icon name="icon-calendar" className={styles.calendarIcon} />
      <Dates startDate={startDate} endDate={endDate} />
    </div>
  );
};

const Dates = (props: DateRange) => {
  const { startDate, endDate } = props;

  if (!startDate && !endDate) {
    return <>計画期間を選択</>;
  }

  return (
    <>
      <span>{startDate ? format(startDate, "yyyy年M月d日") : ""}</span>
      <span>〜</span>
      <span>{endDate ? format(endDate, "yyyy年M月d日") : ""}</span>
    </>
  );
};
