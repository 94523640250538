import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import classNames from "classnames";
import Icon from "../../../../src/components/atoms/Icon";
import {
  ActivityProgressType,
  GradingProgressType,
  detectStatus,
  Status,
  StatusType,
} from "../../../domains/LTI";

type Column = {
  id: string;
  name: string;
  url?: string;
};
type Props = {
  columns: Column[];
  children: React.ReactNode;
};
export const ContentResultTable = React.forwardRef<HTMLTableElement, Props>(
  (props: Props, ref) => {
    return (
      <table className={styles.ContentResultTable__table} ref={ref}>
        <ContentResultTableHeader columns={props.columns} />
        <tbody>{props.children}</tbody>
      </table>
    );
  },
);

const ContentResultTableHeader = ({ columns }: { columns: Column[] }) => {
  return (
    <thead>
      <tr
        className={classNames(
          styles.ContentResultTableHeader__row,
          styles["ContentResultTableHeader__row--sticky1"],
        )}
      >
        <th
          className={classNames(
            styles.ContentResultTableHeader__title,
            styles["ContentResultTableHeader__title--sticky"],
          )}
        />
        <th className={styles.ContentResultTableHeader__title} colSpan={2}>
          合計
        </th>
        {columns.map((column) => (
          <th
            className={styles.ContentResultTableHeader__title}
            key={`ContentResultTable__headtitle--${column.id}`}
          >
            {column.url ? (
              <Link to={column.url} className={styles.ContentResultTable__link}>
                <Icon
                  name="icon-folder-level-2"
                  className={styles.ContentResultTable__icon}
                />
                {column.name}
              </Link>
            ) : (
              column.name
            )}
          </th>
        ))}
      </tr>
      <tr
        className={classNames(
          styles.ContentResultTableHeader__row,
          styles["ContentResultTableHeader__row--sticky2"],
        )}
      >
        <th
          className={classNames(
            styles.ContentResultTableHeader__categories,
            styles["ContentResultTableHeader__categories--sticky"],
          )}
        >
          氏名
        </th>
        <th
          className={classNames(
            styles.ContentResultTableHeader__categories,
            styles["ContentResultTableHeader__categories--noborder"],
          )}
        >
          得点
        </th>
        <th className={styles.ContentResultTableHeader__categories}>順位</th>
        {columns.map((column) => (
          <th
            key={`ContentResultTable__headscore--${column.id}`}
            className={styles.ContentResultTableHeader__categories}
          >
            得点
          </th>
        ))}
      </tr>
    </thead>
  );
};

type ContentResultTableRowProps = {
  student: {
    id: string;
    fullName: string;
  };
  results: {
    scoreGiven: number | null;
    scoreMaximum: number;
  };
  scores: {
    id: string;
    scoreGiven: number | null;
    scoreMaximum: number;
    activityProgress: ActivityProgressType | null;
    gradingProgress: GradingProgressType | null;
  }[];
  rank: number;
  totalCount?: number;
  courseId: string;
  columns: Column[];
};
export const ContentResultTableRow = React.forwardRef<
  HTMLTableRowElement,
  ContentResultTableRowProps
>(({ student, results, scores, rank, totalCount, courseId, columns }, ref) => {
  const url = `/students/${student.id}/content_courses/${courseId}/results`;

  return (
    <tr ref={ref} className={styles.ContentResultTableRow__row}>
      <td
        className={classNames(
          styles.ContentResultTableRow__cell,
          styles["ContentResultTableRow__cell--sticky"],
        )}
      >
        <Link to={url} className={styles.ContentResultTableRow__link}>
          {student.fullName}
        </Link>
      </td>
      <td
        className={classNames(
          styles.ContentResultTableRow__cell,
          styles["ContentResultTableRow__cell--noborder"],
        )}
      >
        {results.scoreGiven || 0}/{results.scoreMaximum}
      </td>
      <td className={styles.ContentResultTableRow__cell}>
        {totalCount ? `${rank}/${totalCount}` : rank}
      </td>
      {columns.map((column) => {
        const score = scores.find((score) => score.id === column.id);
        if (!score) return null;

        const status = detectStatus(score);
        return (
          <td
            key={`ContentResultTableRow__scores--${student.id}-${column.id}`}
            className={styles.ContentResultTableRow__cell}
          >
            <ScoreText
              status={status}
              scoreMaximum={score.scoreMaximum}
              scoreGiven={score.scoreGiven}
            />
          </td>
        );
      })}
    </tr>
  );
});

type ScoreTextProps = {
  status: StatusType;
  scoreGiven: number | null;
  scoreMaximum: number;
};
const ScoreText = ({ status, scoreGiven, scoreMaximum }: ScoreTextProps) => {
  switch (status) {
    case Status.Completed: {
      return <span> {`${scoreGiven}/${scoreMaximum}`}</span>;
    }
    case Status.Submitted: {
      return <span className={styles["ScoreText--green"]}>採点待ち</span>;
    }
    default: {
      return <span className={styles["ScoreText--red"]}>未提出</span>;
    }
  }
};
