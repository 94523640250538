import Select, { Props as SelectProps } from "react-select";
import { SizeProps, customStyles } from "./styles";

export type OptionType = Record<string, unknown>;
export type SelectWrapperProps = SelectProps<OptionType, false> &
  SizeProps & { hasError?: boolean };

const defaultProps: SelectProps<OptionType, false> = {
  noOptionsMessage: () => "見つかりませんでした",
  loadingMessage: () => "読み込み中...",
};

// react-selectのコンポーネントにboron-web独自のスタイルを当てるためのラッパーコンポーネント
export const SelectWrapper = (props: SelectWrapperProps) => {
  const mergedProps = { ...defaultProps, ...props };
  return (
    <Select {...mergedProps} styles={{ ...customStyles, ...props.styles }} />
  );
};

export * from "./LoadableSelect";
