import * as React from "react";
import { MixedCheckbox } from "../../../../components/general/MixedCheckbox";

export const CheckboxWithLabel = (props: {
  id: string;
  checked: boolean;
  indeterminate: boolean;
  onCheck: () => void;
  onUncheck: () => void;
  children: React.ReactNode;
}) => {
  const onChanged = (checked: boolean) => {
    if (checked) {
      props.onCheck();
    } else {
      props.onUncheck();
    }
  };
  return (
    <MixedCheckbox
      id={props.id}
      value={
        props.checked ? true : props.indeterminate ? "indeterminate" : false
      }
      onChanged={onChanged}
    >
      {props.children}
    </MixedCheckbox>
  );
};
