import * as React from "react";
import { ContentTypes } from "../../../../../domains/Content";

export type SearchContentTypes = Array<
  | Extract<ContentTypes, "ContentVideo" | "LtiResourceLink">
  | "ContentFile"
  | "Blank"
>;

export type SearchFormProps = {
  searchText: string;
  searchContentTypes: SearchContentTypes;
  handleChangeSearchText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResetSearchText: () => void;
  handleChangeContentTypes: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const initialContentTypes: SearchContentTypes = [
  "LtiResourceLink",
  "ContentFile",
  "ContentVideo",
  "Blank", // NOTE: 画面非表示. 画面に表示されるチェックボックスを全て外した場合に検索結果を0件にするための値.
];

export const useContentsSearchForm = (): SearchFormProps => {
  // コンテンツ種別(チェックボックス)の検索条件
  const [searchContentTypes, setSearchContentTypes] =
    React.useState<SearchContentTypes>(initialContentTypes);
  const handleChangeContentTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contentType = e.target.value;
    // 型チェック
    if (
      contentType !== "LtiResourceLink" &&
      contentType !== "ContentFile" &&
      contentType !== "ContentVideo"
    ) {
      return;
    }

    if (e.target.checked) {
      setSearchContentTypes([...searchContentTypes, contentType]);
    } else {
      setSearchContentTypes(
        searchContentTypes.filter((type) => type !== contentType),
      );
    }
  };
  // コンテンツ名(部分一致)の検索条件
  const [searchText, setSearchText] = React.useState<string>("");
  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const handleResetSearchText = () => {
    setSearchText("");
  };

  return {
    searchText,
    searchContentTypes,
    handleChangeSearchText,
    handleResetSearchText,
    handleChangeContentTypes,
  };
};
