import * as React from "react";
import SubLayout from "../../components/atoms/SubLayout/index";
import StudyPlusEnTerm from "../../components/atoms/StudyPlusEnTerm/index";
import Helmet from "react-helmet";

const EnglishTermPage = (): React.ReactElement => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <SubLayout>
        <SubLayout.Header />
        <SubLayout.Heading>
          Studyplus for School Terms of Service
        </SubLayout.Heading>
        <SubLayout.Main>
          <StudyPlusEnTerm />
        </SubLayout.Main>
        <SubLayout.Footer />
      </SubLayout>
    </>
  );
};

export default EnglishTermPage;
