import { Button } from "@studyplus/boron-ui";
import styles from "./Menu.scss";
import { SingleDatePickerWrapper } from "../../components/molecules/SingleDatePickerWrapper";
import Icon from "../../components/atoms/Icon";
import PrintButton from "../../components/atoms/PrintButton";
import { useDateFilter } from "../../hooks/filters/useDateFilter";
import {
  Modal,
  ModalProvider,
  useModalState,
  ModalHeader,
  ModalBody,
} from "./modal";
import { SectionScheduleFormFields } from "./SectionScheduleForm";
import { useSelector } from "react-redux";
import AppStateInterface from "../../interfaces/AppStateInterface";
import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";
import { FieldLayoutMultiRows } from "../../components/features/Schedule/FormParts";

export const Menu = () => {
  const { dateFilter, setDateFilter } = useDateFilter();
  const modalState = useModalState();
  const operatorPrivileges = useSelector<
    AppStateInterface,
    OperatorPrivilegesStateInterface
  >((state) => state.operatorPrivileges);
  return (
    <div className={styles.root}>
      <div>
        <Button
          className={`${styles.btn} ${styles.todaybtn}`}
          hoverVariant="filled"
          variant="outline"
          size="xs"
          onClick={() => setDateFilter(new Date())}
        >
          今日
        </Button>
      </div>
      <div>
        <SingleDatePickerWrapper
          date={dateFilter}
          blockType="Nothing"
          onDateChange={(date) => {
            if (date) setDateFilter(date);
          }}
        />
      </div>
      <div className={styles.rightMenu}>
        {operatorPrivileges.data !== null &&
          operatorPrivileges.data.role !== "limited_member" && (
            <Button
              size="xs"
              className={`${styles.btn} ${styles.addbtn}`}
              aria-label="予定の登録"
              onClick={modalState.openAddModal}
            >
              <Icon name="icon-plus-tag" />
            </Button>
          )}
        <PrintButton />
      </div>
      <ModalProvider value={modalState}>
        <Modal>
          {!modalState.isStudentTag ? (
            <ModalHeader>予定の登録</ModalHeader>
          ) : (
            <ModalHeader>
              <span
                onClick={modalState.openAddModal}
                className={styles.backBtn}
              >
                <FieldLayoutMultiRows>
                  <Icon
                    name="icon-arrow-close"
                    className={styles.backArrowIcon}
                  />
                  <p>戻る</p>
                </FieldLayoutMultiRows>
              </span>
            </ModalHeader>
          )}
          <ModalBody>
            <SectionScheduleFormFields initialDate={dateFilter} />
          </ModalBody>
        </Modal>
      </ModalProvider>
    </div>
  );
};
