import * as React from "react";
import styles from "./styles.scss";

const Footer = (props: React.PropsWithChildren<unknown>) => {
  return (
    <footer className={styles.footer}>
      {props.children}
      <p className={styles.footer__copyright}>© 2019 Studyplus, Inc.</p>
    </footer>
  );
};

export default Footer;
