import * as React from "react";
import { createContext } from "../../../helpers/React";

const { useContext, Provider } = createContext<ReturnType<typeof useDrawer>>();

export const useDrawerContext = useContext;

const useDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [headerComponent, setHeaderComponent] =
    React.useState<React.ReactNode>(null);
  const [bodyComponent, setBodyComponent] =
    React.useState<React.ReactNode>(null);

  const openDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    headerComponent,
    bodyComponent,
    setHeaderComponent,
    setBodyComponent,
    openDrawer,
    closeDrawer,
  };
};

export const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  return <Provider value={useDrawer()}>{children}</Provider>;
};
