import * as React from "react";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";
import SectionInterface from "../../interfaces/SectionInterface";
import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import SectionGuardianMessages, {
  GuardianMessageThreadsProps,
} from "./SectionGuardianMessages";
import { useFetchSectionsGuardianMessageThreads } from "./useFetchSectionsGuardianMessageThreads";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { useQueryError } from "../../hooks/http/useQueryError";
import GuardianMessageThreadInterface from "../../interfaces/GuardianMessageThreadInterface";
import GuardianMessageInterface from "../../interfaces/GuardianMessageInterface";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  section: SectionInterface;
  operatorPrivileges: OperatorPrivilegesStateInterface;
}

const SectionsGuardianMessageThreadsPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("message");
  }, []);
  const {
    loadMoreThreads,
    openGuardianMessageThread,
    guardianMessageThreadsProps,
  } = useSectionsGuardianMessageThreads(props);

  return (
    props.section && (
      <SectionGuardianMessages
        section={props.section}
        operatorPrivilege={props.operatorPrivileges.data}
        guardianMessageThreadsProps={guardianMessageThreadsProps}
        onLoadMoreThreads={loadMoreThreads}
        openGuardianMessageThread={openGuardianMessageThread}
      />
    )
  );
};

const useSectionsGuardianMessageThreads = (props: Props) => {
  const [openedStudentId, setOpenedStudentId] = React.useState<string | null>(
    null,
  );
  const studentFilterContext = useStudentFilterContext();
  const [guardianMessageThreads, setGuardianMessageThreads] = React.useState<
    GuardianMessageThreadInterface[]
  >([]);

  const {
    data,
    meta,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = useFetchSectionsGuardianMessageThreads({
    sectionId: props.section.id,
    query: studentFilterContext.filtersQuery.current,
  });
  useQueryError(error);

  React.useEffect(() => {
    setOpenedStudentId(null);
  }, [location.search]);

  React.useEffect(() => {
    if (openedStudentId) return;
    const firstThreadStudentId =
      data && data.length > 0 ? data[0].student.id : null;

    setOpenedStudentId(firstThreadStudentId);
    setGuardianMessageThreads(data || []);
  }, [data]);

  const loadMoreThreads = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  // スレッドの次ページを表示させる
  React.useEffect(() => {
    if (data && data.length > guardianMessageThreads.length) {
      setGuardianMessageThreads(data);
    }
  }, [data]);

  const openGuardianMessageThread = (studentId: string) => {
    setOpenedStudentId(studentId);
  };

  const updateCurrentThreadLatestMessage = (
    guardianMessage: GuardianMessageInterface,
  ) => {
    const nextGuardianMessageThreads = guardianMessageThreads.map((thread) => {
      if (thread.student.id !== openedStudentId) return thread;

      thread.latestGuardianMessage = guardianMessage;
      return thread;
    });

    setGuardianMessageThreads(nextGuardianMessageThreads);
  };

  const guardianMessageThreadsProps = {
    data: guardianMessageThreads,
    meta: meta || { currentPage: 0, totalPages: 0 },
    loading: isLoading,
    hasMore: hasNextPage || false,
    openedStudentId,
    updateCurrentThreadLatestMessage,
  } as GuardianMessageThreadsProps;

  return {
    guardianMessageThreadsProps,
    openedStudentId,
    loadMoreThreads,
    openGuardianMessageThread,
  };
};

const pageInfo = {
  title: "メッセージ",
};

export default enhanceSectionsPage(
  SectionsGuardianMessageThreadsPage,
  pageInfo,
);
