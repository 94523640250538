import * as React from "react";
import styles from "./styles.scss";

const StudyPlusEnTerm = (): React.ReactElement => {
  return (
    <div className={styles.root}>
      <Heading>Preamble</Heading>
      <p className={styles.preambleSection}>
        The Studyplus for School Terms of Use (hereinafter referred to as the
        &quot;Terms&quot;) stipulate the terms and conditions of the provision
        of Studyplus for School (hereinafter referred to as the
        &quot;Service&quot;), a learning management platform provided by
        Studyplus Co., Ltd. (hereinafter referred to as the
        &quot;Company&quot;), as well as the rights and obligations between
        Users and the Company. Please read these Terms in their entirety before
        using this Service.
      </p>
      <Heading>①General Provisions</Heading>
      <Heading>Article 1. Application</Heading>
      <SummarySection>
        ◆Summary
        <br />
        This Agreement applies to all Users of the Studyplus for School service.
        If you have concluded a separate agreement with the Company in addition
        to this Agreement, the provisions of the other agreement shall take
        precedence.
      </SummarySection>
      <ol>
        <li>
          The purpose of this Agreement is to set forth the terms and conditions
          of the provision of the Service and the rights and obligations between
          the User and the Company regarding the use of the Service, and shall
          apply to all relationships between the User and the Company regarding
          the Service.
        </li>
        <li>
          In the event that there is a contract or agreement (hereinafter
          referred to as &quot;Individual Contract, Etc.&quot;) separate from
          these Terms for this Service, the Individual Contract, Etc. shall also
          constintute a part of these Terms. In the event of a conflict between
          the provisions of the Individual Contract, Etc. and these Terms, the
          provisions of the Individual Contract, Etc. shall prevail.
        </li>
        <li>
          In the event that the contents of these Terms differ from the
          descriptions of the Service in other documents, the provisions of
          these Terms shall prevail.
        </li>
        <li>
          In the event that any of the provisions of these Terms do not apply to
          the User at the time the company grants permission to use this
          Service, such provisions shall apply from the time they become
          applicable in the future due to changes in circumstances.
        </li>
      </ol>
      <Heading>Article 2. Definitions</Heading>
      <SummarySection>
        ◆Summary
        <br />
        This section provides &quot;definitions&quot; of terms related to this
        Agreement. In general, &quot;User&quot; refers to an instructor or
        teacher, and &quot;Student&quot; refers to a student who attends the
        cram school.
      </SummarySection>
      <ol>
        <li>
          The terms used in this Agreement are defined as follows.
          <ol className={styles.numberedListSection}>
            <li>
              The term &quot;Service&quot; refers to Studyplus for School, a
              learning management platform provided by the Company.
            </li>
            <li>
              The term &quot;User&quot; refers to a person who logs in and uses
              the Service.
            </li>
            <li>
              The &quot;Application&quot; refers to the study management
              &quot;Studyplus&quot; provided by the Company.
            </li>
            <li>
              The term &quot;Student&quot; refers to a person who uses the
              application in conjunction with the Service.
            </li>
            <li>
              &quot;Studyplus Terms of Use&quot; refers to the terms of use for
              the Studyplus learning management application provided by the
              Company.
            </li>
            <li>
              The term &quot;Account&quot; refers to the Student&apos;s
              Application Account.
            </li>
            <li>
              &quot;Administrative Account&quot; means an account issued to a
              User for use of the Service.
            </li>
            <li>
              The term &quot;Usage Data&quot; refers to the information uploaded
              and stored using the Service.
            </li>
            <li>
              The term &quot;Paid Service&quot; shall mean a service provided
              for a fee by adding functions as specified in the Service.
            </li>
            <li>
              &quot;Customer&quot; means a corporation or organization that has
              agreed to the terms of this Agreement and has applied to use the
              Paid Services or has entered into a contract with the Company in
              accordance with the procedures prescribed by the Company, or a
              person to whom the Company has granted permission to use the Paid
              Services.
            </li>
            <li>
              The term &quot;Agreement&quot; shall mean the agreement and
              application for the use of the Paid Service between the Customer
              and the Company when using the Paid Service.
            </li>
            <li>
              &quot;API Coordination Service&quot; means a service that enables
              one to use the functions or functions of the Service through an
              application programming interface (hereinafter referred to as the
              &quot;API&quot;) provided with respect to the Service.
            </li>
          </ol>
        </li>
      </ol>
      <Heading>② Provision of the Service</Heading>
      <Heading>Article 3. Description of the Service</Heading>
      <SummarySection>
        ◆Summary
        <br />
        When using &quot;Studyplus for School&quot;, please comply with these
        Terms of Use and the guidelines to be provided separately.
      </SummarySection>

      <ol>
        <li>
          This Service is provided by software for the purpose of improving the
          academic performance of the Students in question and the business
          efficiency of the Users.
        </li>
        <li>
          The Company will provide the Service in accordance with the terms and
          conditions set forth in this Agreement.
        </li>
        <li>
          The details of the contents of the Service and the conditions of
          provision shall be determined separately by the Company and presented
          to the User in the form of guidelines, manuals, etc. (hereinafter
          referred to as &quot;Guidelines, Etc.&quot;). The User shall use the
          Service in accordance with the Guidelines, Etc. as well as these
          Terms.
        </li>
      </ol>

      <Heading>Article 4. Basic Principles</Heading>
      <SummarySection>
        ◆Summary
        <br />
        &quot;Studyplus for School&quot; is based on the premise that Students
        use the &quot;Studyplus&quot; application. Students are required to use
        the application in accordance with the Studyplus Terms of Use
      </SummarySection>

      <ol>
        <li>
          The Service is based on the assumption that the Student will use the
          Application.
        </li>
        <li>
          The Student must agree to the Studyplus Terms of Service when using
          the Application.
        </li>
        <li>
          We confirm that the Company and the Student have a direct contractual
          relationship regarding the use of this application.
        </li>
      </ol>

      <Heading>Article 5. Registration</Heading>
      <SummarySection>
        ◆Summary
        <br />
        To use &quot;Studyplus for School&quot;, please register your
        information. Once registration is complete, we will issue an
        Administrative Account and you can start using the Service.
      </SummarySection>
      <ol>
        <li>
          Those who wish to use the Service may apply for registration to use
          the Service by providing certain information specified by the Company
          (hereinafter referred to as &quot;Registration Items&quot;) to the
          Company.
        </li>
        <li>
          Upon receipt of the application as described in the preceding
          paragraph, the Company shall grant the user an Administrative Account.
          Upon the granting of said account, the User will be able to use the
          Service in accordance with this Agreement.
        </li>
        <li>
          The User can issue and give a passcode to the Student from the
          Administrative Account. The Student will be able to use the Service by
          connecting the Application and the Service according to the contents
          as defined by the Company.
        </li>
      </ol>

      <Heading>Article 6. Linked Services</Heading>

      <SummarySection>
        ◆Summary
        <br />
        &quot;Studyplus for School&quot; can be linked with other applications
        and systems.
      </SummarySection>

      <ol>
        <li>
          When API linking service is provided to a User, the User shall use the
          Service at his/her own will and shall be responsible for all
          consequences arising from the use of the Service. Also, the Company
          shall not be responsible for any changes, disclosures, or deletions of
          data resulting from the use of the API linking service.
        </li>
        <li>
          The Company may change the contents of the API or terminate the
          provision of the API at the Company&apos;s convenience. If the Company
          terminates the provision of the API, the Company shall notify the
          Customer in advance.
        </li>
        <li>
          The Company shall not be liable for any damage caused by any measures
          taken by the Company in accordance with this Article.
        </li>
      </ol>

      <Heading>③ Usage Agreement for Paid Services</Heading>

      <Heading>Article 7. Conclusion of Usage Contract</Heading>

      <SummarySection>
        ◆Summary
        <br />
        In order to use the functions of &quot;Studyplus for School&quot;
        without restrictions, you need to register for Paid Services. If you
        wish to do so, please contact us.
      </SummarySection>

      <ol>
        <li>
          If you wish to use the Paid Service, you must agree to these Terms of
          Use and apply for a subscription to the Paid Service in accordance
          with the method prescribed by the Company.
        </li>
        <li>
          The person who has applied for the subscription contract in the
          preceding paragraph (hereinafter referred to as the
          &quot;Applicant&quot;) guarantees to the Company that the person who
          has the authority to conclude the subscription contract with the
          Company has applied for the subscription contract.
        </li>
        <li>
          In the event that the User uses a free trial of the Paid Service, the
          User may use the Paid Service from the date separately agreed upon
          with the Company.
        </li>
      </ol>

      <Heading>Article 8. Usage Fees and Payment Method</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The fee(s) and payment method for Studyplus for School will be informed
        to you separately by our staff.
      </SummarySection>
      <ol>
        <li>
          The usage fee for this Paid Service (hereinafter referred to as the
          &quot;Usage Fee&quot;) shall be subject to the terms and conditions
          set forth in the Agreement and shall be separately agreed upon with
          the Company.
        </li>
        <li>
          The Usage Fee shall accrue from the month that includes the date of
          commencement of the Paid Service.
        </li>
        <li>
          In the event that the Agreement is terminated due to cancellation by
          the Company, the User shall pay the Company the full amount of the
          Usage Fee for the month that includes the date of cancellation.
        </li>
        <li>
          In the event that the Paid Service is terminated due to cancellation,
          premature termination, or any other reason, the Company will not
          refund any usage fees received.
        </li>
      </ol>

      <Heading>Article 9. Term of Contract</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The contract period for the use of &quot;Studyplus for School&quot; is
        until March 31 of each year, and will be renewed annually if the
        customer does not cancel the contract.
      </SummarySection>

      <ol>
        <li>
          The contract period for this service is until the first occurrence of
          March 31 after the start of use.
        </li>
        <li>
          If the contract period for the first year is less than one month, it
          will expire on March 31 of the following year.
        </li>
        <li>
          Unless the Company or the User requests otherwise at least one month
          prior to the expiration of the contract period, the contract period
          shall be extended for another year, and the same shall apply
          thereafter.
        </li>
      </ol>

      <Heading>
        Article 10. Cancellation of the Service and this Agreement
      </Heading>
      <SummarySection>
        ◆Summary
        <br />
        &quot;Studyplus for School&quot; can be terminated by giving one
        month&apos;s notice.
      </SummarySection>

      <ol>
        <li>
          The Customer and the Company may cancel the Service and this Agreement
          by notifying the other party at least one month prior to the
          expiration date of the Agreement in the manner prescribed by the
          Company.
        </li>
        <li>
          If the cancellation date of the Agreement is in the middle of a month,
          the usage fee for the current month shall be incurred without
          prorating.
        </li>
        <li>
          If the Customer owes any debt to the Company upon cancellation, the
          Customer shall lose the benefit of time for all debts owed to the
          Company, and shall immediately fulfill all debts owed to the Company.
        </li>
      </ol>

      <Heading>④Obligations of the User</Heading>

      <Heading>Article 11. Management of Account Information</Heading>
      <SummarySection>
        ◆Summary
        <br />
        Please manage your own account appropriately. If you allow a third party
        to use your account, or if you buy or sell your account, we may suspend
        your use.
      </SummarySection>

      <ol>
        <li>
          Users shall use their accounts at their own risk, and shall not allow
          any third party to use their accounts, or lend, transfer, change the
          name of, or trade their accounts with any third party.
        </li>
      </ol>

      <Heading>
        Article 12. Principle of Self-Risk, Non-Guarantee and Limitation of
        Liability
      </Heading>
      <SummarySection>
        ◆Summary
        <br />
        1.In the event of a problem with another person in the Service, the
        Customer shall, in principle, be responsible for the problem.
        <br />
        2.Please prepare the communication equipment and internet environment in
        advance and check the operation.
        <br />
        3.In the event of any problems, we will do our best to resolve them.
        <br />
        4.Although we have a backup system for your data, we cannot guarantee
        the preservation of your data. However, if for some reason your data is
        damaged or erased, we will do our best to restore it.
        <br />
        5.Service may be unavailable due to unforeseen circumstances.Please note
        that we will not be able to handle any problems between students.
      </SummarySection>

      <ol>
        <li>
          The Company shall not be responsible for any trouble between a User
          and other Users or third parties in relation to this Service, etc.
          unless there is a reason attributable to the Company. In the event
          that the Company incurs expenses due to a problem caused by the User,
          the Company may demand that the User pay the relevant expenses
          (including the expenses of lawyers and other professionals) and
          compensation for damages.
        </li>
        <li>
          The User shall, at his/her own expense and responsibility, prepare the
          communication equipment, etc. (including, but not limited to, Internet
          connection, hardware such as PC, software such as Web browser, etc.)
          necessary for the User to use this Service. However, the Company does
          not guarantee that the Service will operate normally on the
          communication equipment, etc. used by the User.
        </li>
        <li>
          The Company does not guarantee that the use of the Service by the User
          will conform to the laws and regulations applicable to the User or the
          internal rules of industry organizations, that the registered data
          will be accurate, that there will be no defects or failures, or that
          defects will not occur. However, in the event of a failure or
          malfunction caused by the Company while the User is using the Service,
          the Company shall promptly notify the User and respond as much as
          possible.
        </li>
        <li>
          The Company does not warrant against loss or damage of any and all
          information provided in relation to the use of this Service (including
          information provided through the linking source provided by the
          linking service), including information stored on the Company&apos;s
          servers, etc. However, in the event that a User&apos;s data is lost or
          damaged due to some trouble, the Company shall respond to restore the
          User&apos;s data as much as possible.
        </li>
        <li>
          The Company shall be exempted from liability in the following cases.
          However, the Company shall not be exempted from liability in cases of
          intentional or gross negligence.
          <ol>
            <li>
              Damages related to the inability to provide the Service due to
              inability to provide the Service (whether temporary or not) due to
              failure of the Service facilities, operational or technical
              reasons, force majeure such as natural disasters, or other
              reasons.
            </li>
            <li>
              Damages caused by system interruption, delay, discontinuation, or
              loss of data due to communication line or computer failure, damage
              caused by unauthorized access to data, or other damage caused to
              the Company in relation to the User&apos;s services.
            </li>
            <li>
              Damages caused by the inclusion of computer viruses or other
              harmful material planted by a third party in e-mail content sent
              from the Company&apos;s web pages, servers, domains, etc.
            </li>
            <li>
              Damages caused by the User&apos;s violation of this Agreement.
            </li>
            <li>
              Damages caused by changes in the service content of the SNS used
              for login, discontinuation of service provision, etc.
            </li>
            <li>
              Damages caused by errors, bugs, or other deficiencies, losses,
              changes, or losses resulting from accessing or using the service.
            </li>
            <li>
              Damages caused by defamation, attacks, or other actions by third
              parties.
            </li>
            <li>
              Damages caused by the Company&apos;s disclosure of personal
              information for the purpose of other overriding interests, such as
              being ordered to disclose personal information by law or court
              order, or being requested to do so by investigative authorities in
              a voluntary investigation, or by a person who has the legal
              authority to inquire or request disclosure.
            </li>
            <li>
              Any damage caused by communication between a Student and User.
            </li>
          </ol>
        </li>
        <li>
          In case of damage caused by the communication between Students, it
          shall be resolved between the Students, and the User and the Company
          shall not be responsible or liable. However, the User and the Company
          shall discuss actions if necessary.
        </li>
      </ol>

      <Heading>Article 13. Prohibited Matters</Heading>
      <SummarySection>
        ◆Summary
        <br />
        There are a number of prohibitions on the use of &quot;Studyplus for
        School&quot;. If you violate a prohibition, you will be suspended from
        using the Service and may be liable for damages.
      </SummarySection>
      <ol>
        <li>
          When using this Service, Users are prohibited from doing any of the
          following.
          <ol>
            <li>
              Violation of laws and regulations, these Terms of Use, notes on
              the use of this Service, and other individual terms.
            </li>
            <li>
              Damaging the rights, intellectual property rights, profits, honor,
              privacy, etc. of the Company, Users, or other third parties, or
              slandering third parties.
            </li>
            <li>
              To commit acts that lead to criminal or illegal acts, to post
              information about illegal or unfair transactions, or to commit
              other acts that are offensive to public order and morals.
            </li>
            <li>
              Actions that may cause trouble or discomfort to other Users or
              third parties.
            </li>
            <li>Entering false information.</li>
            <li>
              Sending or posting on the Service harmful computer programs, spam
              mail, junk mail, or other harmful mail or other communications, or
              other spamming.
            </li>
            <li>
              Overburdening or illegally accessing the Company&apos;s servers or
              other computers, or interfering with the proper operation of the
              Service.
            </li>
            <li>
              Scraping the Service without the Company&apos;s prior consent.
            </li>
            <li>
              Lending or transferring your e-mail address and password to a
              third party, or sharing them with a third party.
            </li>
            <li>
              Any action that the Company deems to be primarily for the purpose
              of meeting a third party, whether a minor or an adult.
            </li>
            <li>
              Advertising, solicitation, or business activities on the Service
              that are not authorized in advance by the Company.
            </li>
            <li>
              Posting information that leads to sites that the Company deems
              inappropriate, such as one-click fraud sites, sites that
              distribute viruses or other harmful computer programs, etc.
            </li>
            <li>
              Actions that cause disadvantage or damage to the Company, other
              users, or other third parties.
            </li>
            <li>
              Any other actions similar to the above, which the Company
              reasonably deems inappropriate.
            </li>
          </ol>
        </li>
        <li>
          The Company shall not be liable for any damages incurred by the User
          due to violation of the prohibitions of the preceding paragraph.
        </li>
      </ol>

      <Heading>
        ⑤Handling of Confidential Information and Personal Information, etc.
      </Heading>
      <Heading>Article 14. Handling of Personal Information</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The personal information of Customers and Students is properly managed
        and operated in accordance with the law. For details, please refer to
        our &quot;Privacy Policy&quot;.
      </SummarySection>

      <ol>
        <li>
          The term &quot;personal information&quot; as used in these Terms and
          Conditions shall mean personal information as defined in the Act on
          the Protection of Personal Information (Act No. 57 of 2003,
          hereinafter referred to as the &quot;Personal Information Protection
          Act&quot;). The Company will handle the personal information of Users
          and the personal information of Students entrusted to the Company in
          accordance with the privacy policy separately stipulated by the
          Company (hereinafter referred to as the &quot;Privacy Policy&quot;).
        </li>
        <li>
          In the case that the User entrusts the Company with the handling of
          the personal information of Students, the User warrants that the
          personal information of the Students has been obtained through fair
          and legal procedures.
        </li>
        <li>
          The company shall not use, process, copy, or reproduce the personal
          information entrusted by the User beyond the scope of the purpose of
          the entrustment.
        </li>
        <li>
          The Company will appoint a person in charge of the management of
          personal information entrusted by Users and take necessary and
          appropriate measures to prevent leakage, loss, or damage and to
          otherwise safely manage personal information.
        </li>
        <li>
          In the event of an accident such as leakage, loss, or damage of
          personal information entrusted by a User, the Company shall promptly
          report the fact to the Customer, investigate the cause, and take
          necessary measures to prevent the accident from expanding.
        </li>
        <li>
          The provisions of this Article shall survive even if the Service is
          terminated due to cancellation, termination or other regulations.
        </li>
      </ol>

      <Heading>Article 15. Use of Data</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The Company may use the Customer&apos;s data, excluding personal
        information, to provide better services to the Customer.
      </SummarySection>

      <ol>
        <li>
          The Company may use registration data and other data related to
          Customers and Users, excluding those that fall under the category of
          personal information, for the purpose of improving the quality of
          services, including the services that the Company provides or is about
          to provide, informing users about these services, and for other
          purposes specified in the Company&apos;s Privacy Policy.
        </li>
        <li>
          The Company may analyze the usage of the Service or the status of
          Customers and Users for statistical purposes, and may release the
          statistical results to the public. However, individual Customers and
          Users will not be identified.
        </li>
        <li>
          The Company may obtain information on the use of the Service from the
          User, or may request the User to provide feedback on the Service. The
          User shall agree to the Company&apos;s use of such usage information
          or feedback during the term of this Service Agreement and after the
          termination of this Service Agreement. However, the purpose of the use
          of such usage information or the contents of feedback shall be limited
          to the operation, improvement, promotion, and development of new
          services of this Service.
        </li>
      </ol>

      <Heading>Article 16. Management of Confidential Information</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The Company will appropriately manage and operate the confidential
        information provided by Customers.
        <br />
        However, please be aware that there may be cases where the submission of
        confidential information is required by law.
      </SummarySection>

      <ol>
        <li>
          Except as otherwise provided in these Terms of Use, the User and the
          Company shall treat as confidential information any information
          disclosed by the other party in relation to the Service, which is
          explicitly requested to be treated as confidential at the time of
          disclosure, except with the prior written consent of the other party.
          However, items that fall under any of the following items shall not be
          considered confidential information.
          <ol>
            <li>
              Information that was already in the Company’s possession when we
              received the disclosure
            </li>
            <li>
              Information legitimately obtained from a third party without any
              obligation of confidentiality after receiving the disclosure
            </li>
            <li>
              Information that was independently acquired or created after
              receiving the disclosure, regardless of the information disclosed
              by the other party
            </li>
            <li>
              Information that was already in the public domain at the time the
              disclosure was received
            </li>
            <li>
              Information that became public knowledge after receiving the
              disclosure due to reasons not attributable to the party.
            </li>
          </ol>
        </li>
        <li>
          Notwithstanding the provisions of the preceding paragraph, the party
          that received the confidential information (hereinafter referred to as
          the &quot;Receiving Party&quot;) may disclose the confidential
          information without the prior consent of the disclosing party, if the
          disclosure is made in accordance with a legitimate request by a
          regulatory authority or other legal requirement, or if the disclosure
          is made to an officer or employee of the Receiving Party, a contractor
          of the Service, an attorney or accountant, or any other party legally
          obligated to maintain confidentiality.
        </li>
      </ol>

      <Heading>
        Article 17. Term of Obligations Regarding Confidential Information and
        Personal Information
      </Heading>
      <SummarySection>
        ◆Summary
        <br />
        Confidential information and personal information will be managed
        appropriately even after the Service is terminated.
      </SummarySection>

      <ol>
        <li>
          The obligations regarding confidential information and personal
          information of the parties listed in these Terms of Use shall remain
          in effect even after the termination of the Terms of Use.
        </li>
      </ol>

      <Heading>⑥ Suspension, etc. of Provision of the Service</Heading>
      <Heading>Article 18. Suspension, etc. of the Service</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The Service may be suspended or interrupted for maintenance purposes.
        The Company will do its best to restore the Service, and thus the
        Company appreciates your understanding.
      </SummarySection>

      <ol>
        <li>
          The Company may suspend or interrupt the provision of all or part of
          the Service without prior notice to the Customer in any of the
          following cases.
          <ol>
            <li>
              In case of urgent inspection or maintenance of the computer system
              related to the Service.
            </li>
            <li>
              In case of stoppage of the computer or communication line due to
              an accident
            </li>
            <li>
              In the event that the provision or use of external systems (such
              as Amazon Web Services) necessary for the provision of the Service
              is interrupted.
            </li>
            <li>
              In the event that the Company determines that the operation of the
              Service has become impossible due to force majeure such as
              earthquakes, lightning, fire, wind, flood, power outage, spread of
              disease, or natural disasters.
            </li>
            <li>
              In any other cases where the Company deems it necessary to suspend
              or discontinue the Service.
            </li>
          </ol>
        </li>
        <li>
          The Company shall not be liable for any damage caused by the measures
          taken by the Company in accordance with this Article.
        </li>
      </ol>

      <Heading>Article 19. Change or Termination of the Service</Heading>
      <SummarySection>
        ◆Summary
        <br />
        In order to improve the Company&apos;s services and provide better
        services to customers, the Company may terminate the services being
        provided.
      </SummarySection>

      <ol>
        <li>
          The Company may change all or part of the contents of the Service or
          terminate the provision of the Service at the Company&apos;s
          convenience. In the event that the Company terminates the provision of
          the Service, the Company shall notify the User at least six (6) months
          prior to the scheduled termination date. However, in the event that
          the Company is unable to operate this service due to force majeure
          such as earthquake, lightning, fire, windstorm, power outage, spread
          of disease, or natural disaster, the Company may terminate the
          provision of this service without prior notice.
        </li>
        <li>
          The Company shall not be liable for any damage caused by the measures
          taken by the Company in accordance with this Article.
        </li>
      </ol>

      <Heading>
        Article 20. Suspension or Termination of Use by the Company
      </Heading>
      <SummarySection>
        ◆Summary
        <br />
        The Company may terminate the services of any customer who violates the
        &quot;Studyplus for School&quot; Terms of Use. Please be aware of this
        beforehand.
      </SummarySection>

      <ol>
        <li>
          The Company may delete stored data (including, but not limited to,
          registered items and registered data), suspend the use of the service,
          or terminate the service without prior notice or demand if a User
          falls under any of the following items. The Company may, without prior
          notice or demand, delete the stored data (including, but not limited
          to, registration information and registration data), temporarily
          suspend the use of the Service, terminate the registration, or cancel
          this Agreement.
          <ol>
            <li>
              In the event that the User violates any of the provisions of these
              Terms or this Contract.
            </li>
            <li>
              When it is found that there is a falsehood in the registration
              information.
            </li>
            <li>
              When there is a possibility that the User will use the Paid
              Service in a manner that may damage the Company&apos;s social
              credibility.
            </li>
            <li>
              If there is a risk that the User will commit any act that the
              Company deems will affect the Company&apos;s business, such as an
              act that may damage the rights or credibility of the Company, or
              an act that uses the Paid Service for the development or
              benchmarking of products or services similar to the Paid Service.
            </li>
            <li>
              In the event that the Company reasonably determines that the
              entity/person is an anti-social force, etc. (meaning organized
              crime groups, organized crime group members, right-wing groups,
              anti-social forces, quasi-constituents of organized crime groups,
              rackateers, advocates of social movements, specially intelligent
              violent groups, or any other persons equivalent thereto. The same
              shall apply hereinafter.), has some kind of interaction or
              involvement with an anti-social force, etc. such as cooperating
              with or being involved in the maintenance, operation or management
              of an anti-social force, etc. through the provision of funds or
              other means, or is a corporation, etc. in which an anti-social
              force, etc. is substantially involved in the management.
            </li>
            <li>
              When there is a petition for provisional seizure, seizure,
              auction, commencement of bankruptcy proceedings, commencement of
              corporate reorganization proceedings, commencement of civil
              rehabilitation proceedings, etc., or when the Company receives a
              disposition for delinquent payment of taxes and public dues, etc.
            </li>
            <li>
              When there is no response for more than 14 days to inquiries or
              other communications from the Company requesting a response. h. In
              the event of suspension of payment or inability to pay, or in the
              event of a petition for commencement of bankruptcy proceedings,
              commencement of civil rehabilitation proceedings, corporate
              reorganization proceedings, commencement of special liquidation or
              similar proceedings.
            </li>
            <li>
              In the event of suspension of payment or inability to pay, or in
              the event of a petition for commencement of bankruptcy
              proceedings, commencement of civil rehabilitation proceedings,
              corporate reorganization proceedings, commencement of special
              liquidation or similar proceedings.
            </li>
            <li>
              In any other cases where the Company deems it inappropriate for
              the Customer to use the Service, register, or continue the Service
              Agreement.
            </li>
          </ol>
        </li>
        <li>
          In the event of any of the events listed in the preceding paragraph,
          the User shall naturally lose the benefit of time for all debts owed
          to the Company, and shall immediately make payment of all debts to the
          Company.
        </li>
        <li>
          The Company shall not be liable for any damages incurred by you or the
          User as a result of any action taken by the Company in accordance with
          this Article.
        </li>
      </ol>

      <Heading>⑦General Provisions</Heading>
      <Heading>Article 21. Changes to the Terms</Heading>
      <SummarySection>
        ◆Summary
        <br />
        Due to the improvement of services or the provision of new services, it
        may become necessary to change these Terms of Use. In such cases, we
        will modify the Terms of Use after notifying the customer.
      </SummarySection>

      <ol>
        <li>
          The Company reserves the right to make changes, additions, deletions,
          etc. to these Terms of Use as the Company deems necessary. If the User
          uses the Service on or after the date specified in the notice
          (however, if the date is not specified in the notice, the date shall
          be the date on which the Company issues the notice), the User shall be
          deemed to have agreed to the amended Terms, and the amended Terms
          shall apply. If the User does not take the cancellation procedure by
          the date specified in the notice, the User shall be deemed to have
          agreed to the amended Terms.
        </li>
      </ol>

      <Heading>Article 22. Notification and Communication</Heading>
      <ol>
        <li>
          Notification from the Company to the User regarding the Service shall
          be made by posting on the site or at appropriate locations within the
          Service, by sending e-mail, by push notification, or by any other
          method deemed appropriate by the Company.
        </li>
        <li>
          In the event that the Company gives notice to the User, the notice
          shall be deemed to have arrived at the time it is sent.
        </li>
        <li>
          The Company may send e-mails to the e-mail address registered by the
          User regarding the Service, advertisements, promotions, etc., and the
          User shall consent to such distribution.
        </li>
      </ol>

      <Heading>Article 23. Subcontracting</Heading>
      <SummarySection>
        ◆Summary
        <br />
        &quot;Studyplus for School&quot; may outsource its business to other
        companies selected by the Company, in which case the Company shall be
        responsible for managing the outsourced company.
      </SummarySection>
      <ol>
        <li>
          The Company may outsource all or part of the work of providing the
          Service to a third party on its own responsibility. In such cases, the
          Company shall be responsible for the management of said third party.
        </li>
      </ol>

      <Heading>Article 24. Intellectual Property Rights</Heading>
      <SummarySection>
        ◆Summary
        <br />
        The Company respects intellectual property rights such as copyrights,
        patent rights, and trademark rights.
      </SummarySection>

      <ol>
        <li>
          All copyrights, patents, know-how, and other intellectual property
          rights (including the right to receive patents and other intellectual
          property rights, and including the rights set forth in Articles 27 and
          28 of the Copyright Act) related to any and all contents (including
          programs, systems, etc.) comprising the Service shall be the property
          of the Company or its rightful owners.
        </li>
        <li>
          Notwithstanding the provisions of the preceding paragraph, in the
          event that the User provides his/her own intellectual property rights
          in the use of the Service, such rights shall belong to the User.{" "}
        </li>
        <li>
          The User shall grant the Company a prior license to use the
          intellectual property rights related to the information entered by the
          User, but only to the extent necessary for the provision of the
          Service and for other purposes of the Company&apos;s business.
        </li>
        <li>
          In the event that the intellectual property rights provided by the
          User to the Company include the rights of a third party, the User
          shall be assumed to have obtained permission for such rights from the
          third party in advance.
        </li>
      </ol>

      <Heading>Article 25. Force Majeure</Heading>
      <ol>
        <li>
          In the event of the impossibility or delay of the performance of all
          or part of this Agreement due to a natural disaster, war, riot, or
          other force majeure not attributable to the Company or the User, the
          Company or the User shall not be held responsible.
        </li>
      </ol>

      <Heading>Article 26. Compensation for Damages</Heading>
      <ol>
        <li>
          The Company shall be liable for the Service to the extent specified in
          each clause of these Terms of Use, and shall not be liable for matters
          for which the Company does not guarantee or assume responsibility in
          these Terms of Use or for matters for which the User assumes
          responsibility.
        </li>
        <li>
          In addition to the preceding paragraph, even if the Company is liable
          for some reason, the Company shall not be liable for compensation for
          incidental damages, indirect damages, special damages, future damages,
          or damages for lost profits. In such a case, the amount of the
          Company&apos;s liability for damages shall be limited to the total
          amount of the most recent one (1) month&apos;s usage fee received by
          the Company from the Customer for the Service.
        </li>
      </ol>

      <Heading>Article 27. Late Payment Damages</Heading>
      <ol>
        <li>
          In the event that the User delays the payment or repayment of the
          financial obligations owed to the Company based on these Terms and
          Conditions and this Agreement, etc., the User shall pay delay damages
          at the rate of 14.6% per annum from the day following the due date
          until the payment is made.
        </li>
      </ol>

      <Heading>Article 28. Disputes</Heading>
      <ol>
        <li>
          In the event that a dispute arises between the Company or the User and
          a third party through the performance of this Agreement, the Company
          or the User shall promptly attempt to resolve the dispute by
          themselves, and if the cooperation of the other party is required, the
          Company or the User shall promptly report the dispute to the other
          party and shall promptly attempt to resolve the dispute after
          consultation between the Company and the User.
        </li>
      </ol>

      <Heading>Article 29. Governing Law and Court of Jurisdiction</Heading>
      <ol>
        <li>
          These Terms of Use shall be governed by and construed in accordance
          with the laws of Japan, and any and all disputes arising out of or
          relating to these Terms of Use shall be submitted to the exclusive
          jurisdiction of the Tokyo District Court as the court of first
          instance.
        </li>
      </ol>
    </div>
  );
};

const Heading = (props: { children: React.ReactNode }): React.ReactElement => {
  return <h2 className={styles.heading}>{props.children}</h2>;
};
const SummarySection = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  return <p className={styles.summarySection}>{props.children}</p>;
};
export default StudyPlusEnTerm;
