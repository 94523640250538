import * as React from "react";
import styles from "./DropdownMenu.styles.scss";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import {
  resolveColorStyles,
  HoverColorProps,
  ColorProps,
} from "../../../helpers/styles/Colors";

export const DropdownMenu = RadixDropdownMenu.Root;
export const DropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof RadixDropdownMenu.Trigger>,
  React.ComponentProps<typeof RadixDropdownMenu.Trigger>
>(({ children, ...props }, forwardedRef) => {
  return (
    <RadixDropdownMenu.Trigger
      className={styles.DropdownMenuTrigger}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadixDropdownMenu.Trigger>
  );
});

export const DropdownMenuPortal = RadixDropdownMenu.Portal;

export const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof RadixDropdownMenu.Content>,
  React.ComponentProps<typeof RadixDropdownMenu.Content>
>(({ children, ...props }, forwardedRef) => {
  return (
    <RadixDropdownMenu.Content
      className={styles.DropdownMenuContent}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </RadixDropdownMenu.Content>
  );
});

type DropdownMenuItemColorProps = ColorProps & HoverColorProps;
type DropdownMenuItemStateProps = { selected: boolean };
type Props = React.ComponentProps<typeof RadixDropdownMenu.Item> &
  Partial<DropdownMenuItemColorProps & DropdownMenuItemStateProps>;

const defaultColorProps: ColorProps = {
  color: "black",
  backgroundColor: "white",
  hoverColor: "primary",
  hoverBgColor: "gray-lighten-2",
};
const determineClassNames = ({
  color,
  backgroundColor,
  hoverColor,
  hoverBgColor,
  selected,
}: Partial<DropdownMenuItemColorProps & DropdownMenuItemStateProps>) => {
  return classNames(
    styles.DropdownMenuItem,
    { [styles.DropdownMenuItemSelected]: selected },
    resolveColorStyles({
      color: color ?? defaultColorProps.color,
      backgroundColor: backgroundColor ?? defaultColorProps.backgroundColor,
      hoverColor: hoverColor ?? defaultColorProps.hoverColor,
      hoverBgColor: hoverBgColor ?? defaultColorProps.hoverBgColor,
    }),
  );
};

export const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof RadixDropdownMenu.Item>,
  Props
>(
  (
    {
      children,
      color,
      hoverColor,
      backgroundColor,
      hoverBgColor,
      selected,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <RadixDropdownMenu.Item
        className={determineClassNames({
          color,
          hoverColor,
          backgroundColor,
          hoverBgColor,
          selected,
        })}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </RadixDropdownMenu.Item>
    );
  },
);
