import * as React from "react";

import { createContext } from "../helpers/React";

const { useContext, Provider } =
  createContext<ReturnType<typeof useSingleDateFilter>>();

// 単一の日付の選択状態をグローバルに管理するhooks
const useSingleDateFilter = () => {
  const [date, setDate] = React.useState<Date>();

  return {
    date,
    setDate,
  };
};

export const useSingleDateFilterContext = useContext;

export const SingleDateFilterProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <Provider value={useSingleDateFilter()}>{children}</Provider>;
};
