import * as React from "react";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import {
  MainSection,
  PageHeader,
} from "../../../components/atoms/MainSection/index";
import BackLink from "../../../components/atoms/BackLink/index";
import { BlockRow } from "../../../components/atoms/BlockRow/index";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import { SectionDetailInterface } from "../../../interfaces/SectionInterface";
import { Flex } from "../../../components/atoms/Flex";
import { Link } from "react-router-dom";
import { ContentCourseForm } from "./ContentCourseForm";
import { useValidatedParams } from "./useValidatedParams";
import Loader from "../../../components/atoms/Loader";
import { useExistingCourse } from "./useExistingCourse";
import { StudentTagFilterInterface } from "../../../domains/StudentTag";
import { OutletContextProps, withRouter } from "../../../helpers/RouterHelper";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionDetailInterface;
    tagFilter: StudentTagFilterInterface;
    action: "new" | "edit";
  };
const HELP_URL = "https://fs-help.studyplus.co.jp/ja/articles/4964875";

const Page: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const section = props.section;
  const params = useValidatedParams(props.action);
  const pageHeaderText =
    params.action === "edit" ? "コースの編集" : "コースの登録";
  const { course, isLoading } = useExistingCourse({
    section,
    courseId: params.courseId,
  });
  const isReady = params.action === "new" || (course && !isLoading);
  // 遷移元がないときは戻り先を決め打ちのパスにする
  // 遷移元があるときはそちらに戻す
  const pathToBack =
    props.navigate.length === 1
      ? params.action === "edit"
        ? `/sections/${section.id}/settings/content_courses/${params.courseId}`
        : `/sections/${section.id}/settings/content_courses`
      : undefined;

  return (
    <>
      <BlockRow marginTop="2rem">
        <BackLink path={pathToBack}>戻る</BackLink>
      </BlockRow>
      <BlockRow marginTop="2.4rem">
        <MainSection>
          <Flex
            alignItems={"flex-start"}
            justifyContent="space-between"
            marginTop="0"
          >
            <PageHeader helpUrl={HELP_URL}>{pageHeaderText}</PageHeader>
            {params.action === "new" ? (
              <BlockRow marginTop="0.4rem">
                <Link
                  to={`/sections/${section.id}/settings/content_courses/share`}
                  className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm buiButtonRound`}
                >
                  他教室のコース登録
                </Link>
              </BlockRow>
            ) : null}
          </Flex>
          {!isReady ? (
            <Loader loading={isLoading} />
          ) : (
            <ContentCourseForm
              sectionId={section.id}
              course={course}
              ltiDeployments={section.ltiDeployments}
            />
          )}
        </MainSection>
      </BlockRow>
    </>
  );
};
const pageInfo = {
  title: "コースの登録",
};

export const SectionsSettingsContentCoursesNewOrEditPage = withRouter<Props>(
  enhanceSectionsSettingsPage(Page, pageInfo, ["content_courses"]),
);
