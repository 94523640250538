import * as React from "react";

import { useScrollWatcher } from "../../../hooks/useScrollWatcher";
import styles from "./styles.scss";

// TODO: windowにメソッド生やすのあまりよろしくないので、Contextに書き換え
const handleLoadMore = () => {
  if ((window as any).onMainScrollAreaScroll) {
    (window as any).onMainScrollAreaScroll();
  }
};

const MainLayout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const children = React.Children.toArray(props.children);
  const onScroll = useScrollWatcher({ onReachToBottom: handleLoadMore });

  if (children[0] && children[1] && children[2]) {
    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <aside className={styles.navigation}>{children[0]}</aside>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.right} onScroll={onScroll}>
            <div className={styles.header}>{children[1]}</div>
            <div className={styles.main}>{children[2]}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MainLayout;
