import { useEffect, useRef } from "react";

/*
 * onTouchMoveのようなpassiveなイベントリスナー内ではイベントを無効化するevent.preventDefault()が呼び出せない
 * (Unable to preventDefault inside passive event listener invocation.というエラーが出る)
 * 代わりにpassiveでない形でイベントリスナーを貼るためのカスタムフック
 **/

export const usePreventDefault = <T extends HTMLElement>(
  eventName: string,
  enable = true,
) => {
  const ref = useRef<T>(null);
  useEffect(() => {
    const current = ref.current;
    if (!current) {
      return;
    }
    const handler = (event: Event) => {
      if (enable) {
        event.preventDefault();
      }
    };
    current.addEventListener(eventName, handler);
    return () => {
      current.removeEventListener(eventName, handler);
    };
  }, [enable, eventName]);

  return ref;
};
