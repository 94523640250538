import { ArchiveStatus } from "./Attendance";
import { LectureQuestionnaire } from "./LectureQuestionnaire";

export type LectureAttendanceConfirm =
  | "none"
  | "presence_only"
  | "presence_and_absence";

export type Lecture = {
  id: string;
  name: string;
  attendanceConfirm: LectureAttendanceConfirm;
  attendanceLimitDays: number | null;
  attendanceLocationCollect: boolean;
  status: ArchiveStatus;
  questionnaires: LectureQuestionnaire[];
  updatedAt: Date;
};

// デザインレビューのため暫定
export type StudentScheduleLecture = Lecture & {
  sectionName: string;
};

// helpers
export const hasQuestionnaire = (lecture: Lecture) =>
  lecture.questionnaires.length > 0;

// translations
export const translateAttendanceConfirm = (
  attendanceConfirm: Lecture["attendanceConfirm"],
) => {
  switch (attendanceConfirm) {
    case "none":
      return "確認しない";
    case "presence_only":
      return "出席のみ確認";
    case "presence_and_absence":
      return "出席・欠席を確認";
  }
};
export const translateAttendanceLimitDays = (
  attendanceLimitDays: Lecture["attendanceLimitDays"],
) => {
  if (attendanceLimitDays == null) return "なし";
  if (attendanceLimitDays == 0) return "講座予定日の：当日";
  return `講座予定日の：${attendanceLimitDays}日後`;
};
export const translateAttendanceLocationCollect = (
  attendanceLocationCollect: Lecture["attendanceLocationCollect"],
) => (attendanceLocationCollect ? "有効" : "無効");
export const translateQuestionnairePresence = (lecture: Lecture) =>
  hasQuestionnaire(lecture) ? "あり" : "なし";
