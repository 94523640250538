import ReactGA from "react-ga4";

// see https://docs.google.com/spreadsheets/d/1I-N5cBZ1vaWFGGIpZbB0HvXPBYb7a93xFFTKfWBbjPY/edit#gid=0
export const EventType = {
  LikeStudyRecord: "like_study_record",
  UnlikeStudyRecord: "unlike_study_record",
} as const;

// 学習記録をいいねしたときに送信するイベント(No.1)
type LikeStudyRecordEvent = {
  type: typeof EventType.LikeStudyRecord;
  studyRecordId: string;
  studentId: string;
};
type UnlikeStudyRecordEvent = {
  type: typeof EventType.UnlikeStudyRecord;
  studyRecordId: string;
  studentId: string;
};
type Event = LikeStudyRecordEvent | UnlikeStudyRecordEvent;
export const sendUserEvent = (e: Event) => {
  switch (e.type) {
    case EventType.LikeStudyRecord:
    case EventType.UnlikeStudyRecord:
      ReactGA.event(e.type, {
        student_id: e.studentId,
        study_record_id: e.studyRecordId,
      });
  }
};
