import classNames from "classnames";
import { FieldProps } from "formik";
import * as React from "react";
import Icon from "../Icon";
import styles from "./styles.scss";

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    React.ClassAttributes<HTMLDivElement> {
  field?: FieldProps<any>["field"];
  className?: string;
  label: string;
  isBlock?: boolean;
  weight?: "bold" | "normal";
}

const Radio: React.FC<Props> = (props: Props) => {
  const {
    isBlock,
    field,
    children,
    key,
    ref,
    className,
    checked,
    ...attributes
  } = props;

  const isChecked = field ? field.checked : !!checked;
  const iconName = isChecked ? "icon-radio-selected" : "icon-radio-default";

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.block]: !!isBlock,
      })}
      key={key}
      ref={ref}
    >
      <input
        {...attributes}
        {...field}
        type="radio"
        className={styles.input}
        checked={checked}
      />
      <label
        htmlFor={props.id}
        className={classNames(styles.label, {
          [styles.normal]: props.weight && props.weight === "normal",
          [styles.bold]: !props.weight || props.weight === "bold",
          [styles.block__label]: isBlock,
          [styles.labelDisabled]: attributes.disabled,
        })}
      >
        <Icon
          name={iconName}
          className={classNames(styles.icon, {
            [styles.iconChecked]: isChecked,
            [styles.iconDisabled]: attributes.disabled,
          })}
        />
        <span className={styles.label__text}>{props.label}</span>
      </label>

      {children}
    </div>
  );
};

export default Radio;
