import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import StudentPreRegisteredLearningMaterialApi from "../../../api-clients/StudentPreRegisteredLearningMaterialApi";
import { buildMainResourceApiErrorAction } from "../../common/errors";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../../flashMessage";

const getLearningMaterialsPreRegistrations = createAction(
  "GET_LEARNING_MATERIALS_PRE_REGISTRATIONS",
);
const getLearningMaterialsPreRegistrationsSuccess = createAction(
  "GET_LEARNING_MATERIALS_PRE_REGISTRATIONS_SUCCESS",
);
const getLearningMaterialsPreRegistrationsError = createAction(
  "GET_LEARNING_MATERIALS_PRE_REGISTRATIONS_ERROR",
);

// 初期ロード
export const dispatchGetLearningMaterialsPreRegistrations =
  (studentId: string) => (dispatch: Dispatch) => {
    dispatch(getLearningMaterialsPreRegistrations());

    StudentPreRegisteredLearningMaterialApi.getLearningMaterials(studentId)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getLearningMaterialsPreRegistrationsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getLearningMaterialsPreRegistrationsError());
        }
      })
      .catch(() => {
        dispatch(getLearningMaterialsPreRegistrationsError());
      });
  };

// 教材削除
const deletePreRegisteredLearningMaterial = createAction(
  "DELETE_STUDENT_PRE_REGISTERED_LEARNING_MATERIAL",
);
const deletePreRegisteredLearningMaterialSuccess = createAction(
  "DELETE_STUDENT_PRE_REGISTERED_LEARNING_MATERIAL_SUCCESS",
);
const deletePreRegisteredLearningMaterialError = createAction(
  "DELETE_STUDENT_PRE_REGISTERED_LEARNING_MATERIAL_ERROR",
);
export const dispatchDeleteStudentPreRegisteredLearningMaterial =
  (studentId: string, learningMaterialCode: string) => (dispatch: Dispatch) => {
    dispatch(deletePreRegisteredLearningMaterial());

    StudentPreRegisteredLearningMaterialApi.delete(
      studentId,
      learningMaterialCode,
    )
      .then((res) => {
        if (res.ok) {
          dispatch(
            deletePreRegisteredLearningMaterialSuccess(learningMaterialCode),
          );
          dispatch(showSuccessFlashMessage("本棚から削除しました"));
        } else {
          dispatch(deletePreRegisteredLearningMaterialError());
          dispatch(showErrorFlashMessage("本棚から削除できませんでした"));
        }
      })
      .catch(() => {
        dispatch(deletePreRegisteredLearningMaterialError());
        dispatch(showErrorFlashMessage("本棚から削除できませんでした"));
      });
  };
