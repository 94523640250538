import * as React from "react";
import { AuthenticatedPageProps } from "../../../hocs/enhanceAuthenticatedPage";
import FiltersHelper from "../../../helpers/FiltersHelper";
import StudyRecordSkillForm from "../../../components/organisms/StudyRecordSkillForm/index";
import SectionsSettingsSkillsNewStateInterface from "../../../interfaces/SectionsSettingsSkillsNewStateInterface";
import {
  postSkill,
  navigateToSkillIndexPage,
} from "../../../actions/common/skills/index";
import { StudyRecordSkillParamsInterface } from "../../../interfaces/StudyRecordSkillInterface";
import { FormikErrors } from "formik";
import SkillModal from "../../../components/molecules/SkillModal";
import { WithRouterProps } from "../../../helpers/RouterHelper";

interface DispatchProps {
  postSkill: typeof postSkill;
  navigateToSkillIndexPage: typeof navigateToSkillIndexPage;
}
type StateProps = SectionsSettingsSkillsNewStateInterface;

type Props = StateProps &
  DispatchProps &
  Pick<AuthenticatedPageProps, "currentSection" | "studentFilterContext"> &
  WithRouterProps<{ id: string }>;

const handleSubmit =
  (props: Props, sectionId: string) =>
  (
    params: StudyRecordSkillParamsInterface,
    setSubmitting: (submitState: boolean) => void,
    setErrors: (errors: FormikErrors<any>) => void,
  ) => {
    props.postSkill(sectionId, params, setSubmitting, setErrors);
  };

const SectionsSettingsStudyRecordSkillsNewPage: React.FC<Props> = (props) => {
  if (!props.currentSection.data || !props.skillMaster) {
    return null;
  }

  const sectionId = props.currentSection.data.id;
  const tags = FiltersHelper.getAllTags(
    props.studentFilterContext.currentFilter,
  );
  const navigateToSkillIndexPage = () => {
    props.navigateToSkillIndexPage(sectionId, props.navigate);
  };

  return (
    <>
      <StudyRecordSkillForm
        sectionId={sectionId}
        skillMasterId={props.skillMaster.id}
        action="new"
        tags={tags}
        onSubmit={handleSubmit(props, sectionId)}
        submitting={props.submitting}
        apiErrors={props.apiErrors}
        heading="学習時間のお知らせ"
        helpUrl="https://fs-help.studyplus.co.jp/ja/articles/4250110-%E5%AD%A6%E7%BF%92%E6%99%82%E9%96%93%E3%81%AE%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B%E3%82%B9%E3%82%AD%E3%83%AB%E3%81%AE%E8%A8%AD%E5%AE%9A%E6%96%B9%E6%B3%95"
      />
      <SkillModal
        showModal={props.showModal}
        onComplete={navigateToSkillIndexPage}
        action="new"
      />
    </>
  );
};

export default SectionsSettingsStudyRecordSkillsNewPage;
