import { useEffect, useRef } from "react";
import Settings from "../helpers/Settings";
import { useSelector } from "react-redux";
import AppStateInterface from "../interfaces/AppStateInterface";
import OperatorInterface from "../interfaces/OperatorInterface";

interface IntercomProps {
  appID: string;
  user_id?: string;
  name?: string;
  email?: string;
  created_at?: number;
  user_hash?: string;
}

interface IntercomAppendProps {
  window: Window & typeof globalThis;
  document: Document;
  app_id: string;
}

const IntercomContainer = () => {
  const currentOperator = useSelector<
    AppStateInterface,
    OperatorInterface | null
  >((state) => state.session.currentOperator);

  const intercomProps: IntercomProps = {
    appID: Settings.INTERCOM_APP_ID || "",
  };

  if (currentOperator) {
    intercomProps.user_id = currentOperator.id;
    // NOTE: 外部サービスとの連携時に表記ゆれがおきないようにするため, スペースを削除する
    intercomProps.name = currentOperator.fullName.replace(/\s/, "");
    intercomProps.email = currentOperator.email;
    intercomProps.created_at = currentOperator.signedUpAt
      ? new Date(currentOperator.signedUpAt).getTime() / 1000
      : undefined;
    intercomProps.user_hash = currentOperator.userHash;
  }

  return <Intercom {...intercomProps} />;
};

// ref: https://github.com/nhagen/react-intercom/blob/87212189d3d36527c01554caf3e06bf19b8dd2d2/src/index.js
const Intercom = (props: IntercomProps) => {
  // componentDidUpdate
  const prevProps = usePrevious(props);

  useEffect(() => {
    const { appID, ...otherProps } = props;

    (window as any).intercomSettings = { ...otherProps, app_id: appID };

    if ((window as any).Intercom) {
      if (loggedIn(prevProps) && !loggedIn(props)) {
        (window as any).Intercom("shutdown");
        (window as any).Intercom("boot", otherProps);
      } else {
        (window as any).Intercom("update", otherProps);
      }
    }
  }, [props.email, props.user_hash, props.user_id, props.name]);

  // loggedIn
  const loggedIn = (props: IntercomProps) => {
    return Boolean(props.email) || Boolean(props.user_id);
  };

  // componentWillUnmount
  useEffect(() => {
    return () => {
      shutdownIntercom();
    };
  }, []);

  const shutdownIntercom = () => {
    if (!(window as any).Intercom) return false;

    (window as any).Intercom("shutdown");

    delete (window as any).Intercom;
    delete (window as any).intercomSettings;
  };

  // constructor
  useEffect(() => {
    const { appID, ...otherProps } = props;

    if (!appID) {
      return;
    }

    if (!(window as any).Intercom) {
      (({ window, document, app_id }: IntercomAppendProps) => {
        const intercom: {
          c(args: any): void;
          q: any[];
        } = (...args: any) => {
          intercom.c(args);
        };
        intercom.c = (args: any) => {
          intercom.q.push(args);
        };
        intercom.q = [];
        (window as any).Intercom = intercom;
        const newScriptElement = document.createElement("script");
        newScriptElement.async = true;
        newScriptElement.src = "https://widget.intercom.io/widget/" + app_id;
        document.head.appendChild(newScriptElement);
      })({ window: window, document: document, app_id: appID });
    }

    (window as any).intercomSettings = { ...otherProps, app_id: appID };

    if ((window as any).Intercom) {
      (window as any).Intercom("boot", otherProps);
    }
  }, []);

  // render
  return null;
};

// useEffectでもcomponentDidUpdateのように一つ前のpropsを参照できるようにするために作成したカスタムhooks
const usePrevious = (props: IntercomProps) => {
  const initialProps: IntercomProps = { appID: "" };
  const ref = useRef(initialProps);

  useEffect(() => {
    ref.current = props;
  }, [props.email, props.user_hash, props.user_id, props.name]);

  return ref.current;
};

export default IntercomContainer;
