import GlobalHeader from "../components/organisms/GlobalHeader";
import {
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "../hocs/enhanceAuthenticatedPage";
import withFiltersInitialization from "../hocs/withFiltersInitialization";
import FiltersInterface from "../interfaces/FiltersInterface";

export type GlobalHeaderProps = AuthenticatedPageProps &
  AuthenticatedPageCallbacks;

const SectionGlobalHeader = (props: GlobalHeaderProps) => {
  const filter = props.filters.find((filter: FiltersInterface) => {
    return (
      !!props.currentSection.data &&
      filter.sectionId === props.currentSection.data.id
    );
  });

  if (!props.currentSection.data || !props.currentOperator || !filter) {
    return null;
  }

  return (
    <GlobalHeader
      section={props.currentSection.data}
      operators={props.operators}
      currentOperator={props.currentOperator}
      studentNameFilter={{
        firstName: filter.firstName,
        lastName: filter.lastName,
      }}
      onSearchStudent={props.onSearchStudent}
    />
  );
};

export default withFiltersInitialization(SectionGlobalHeader);
