import * as React from "react";
import { ClassroomList } from "../../domains/Classroom";
import { translateMutateArchiveStatusResult } from "../../domains/Attendance";
import { Link } from "react-router-dom";
import { useUpdateClassroomArchiveStatus } from "../../hooks/http/useClassroomsApi";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import { ScreenReaderOnly } from "../../components/general/ScreenReaderOnly";
import {
  ClassroomsTable,
  ClassroomsTableRow,
} from "../../components/features/ClassroomsTable";
import LinkButton from "../../components/atoms/LinkButton";
import Icon from "../../components/atoms/Icon";
import { Text } from "../../components/general/Text";
import { Flex } from "../../components/atoms/Flex";
import styles from "./ActiveClassroomsTable.scss";
import AlignMiddle from "../../components/atoms/AlignMiddle";

type Props = {
  sectionId: string;
  classrooms: ClassroomList | undefined;
};

const useClassroomsTable = ({ sectionId }: Props) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const { mutate: archive } = useUpdateClassroomArchiveStatus({
    sectionId,
    onSuccess() {
      showSuccessMessage(
        translateMutateArchiveStatusResult("archived", "success"),
      );
    },
    onError() {
      showErrorMessage(
        translateMutateArchiveStatusResult("archived", "failure"),
      );
    },
  });

  const archiveClassroom = (classroomId: string, classroomName: string) => {
    if (
      confirm(`「 ${classroomName} 」をアーカイブにしてもよろしいですか？
アーカイブに移動すると、予定に対して該当の教室が選択できなくなります。
アーカイブはいつでもアクティブに戻すことができます。`)
    ) {
      archive({ classroomId, archiveStatus: "archived" });
    }
  };

  return {
    archiveClassroom,
  };
};

const ActiveClassroomsTable_ = ({ classrooms, sectionId }: Props) => {
  const { archiveClassroom } = useClassroomsTable({ sectionId, classrooms });

  if (!classrooms) {
    return null;
  }
  if (classrooms.length === 0) {
    return (
      <Text align="center">
        教室の登録がありません。右上の「教室の登録」から登録ができます。
      </Text>
    );
  }

  return (
    <ClassroomsTable>
      {classrooms.map((classroom) => (
        <ClassroomsTableRow
          key={`active-classroom-${classroom.id}`}
          classroom={classroom}
        >
          <Flex marginTop="0" alignItems="center" justifyContent="end" gap="2">
            <Link to={`./${classroom.id}/edit`} className={styles.RowLink}>
              <AlignMiddle>
                <Icon name="icon-edit" />
                <ScreenReaderOnly>{classroom.name}を</ScreenReaderOnly>
                <Text as="span" whiteSpace="nowrap">
                  編集
                </Text>
              </AlignMiddle>
            </Link>
            <Link to={`./${classroom.id}/copy`} className={styles.RowLink}>
              <AlignMiddle>
                <Icon name="icon-duplicate" />
                <ScreenReaderOnly>{classroom.name}を</ScreenReaderOnly>
                <Text as="span" whiteSpace="nowrap">
                  複製
                </Text>
              </AlignMiddle>
            </Link>
            <LinkButton
              onClick={() => {
                archiveClassroom(classroom.id, classroom.name);
              }}
            >
              <AlignMiddle>
                <Icon name="icon-archive" />
                <ScreenReaderOnly>{classroom.name}を</ScreenReaderOnly>
                <Text as="span" whiteSpace="nowrap">
                  アーカイブ
                </Text>
              </AlignMiddle>
            </LinkButton>
          </Flex>
        </ClassroomsTableRow>
      ))}
    </ClassroomsTable>
  );
};
export const ActiveClassroomsTable = React.memo(ActiveClassroomsTable_);
