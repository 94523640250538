import * as React from "react";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import { connect } from "react-redux";
import AppStateInterface from "../../interfaces/AppStateInterface";
import { SectionStudyRecordCommentsStateInterface } from "../../interfaces/SectionStudyRecordCommentsStateInterface";
import {
  getStudyRecordCommentsInitial,
  getStudyRecordComments,
} from "../../actions/studyRecordComment";
import SectionStudyRecordComments from "../../components/organisms/SectionStudyRecordComments";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";

interface Props extends OutletContextProps {
  section: SectionInterface;
  comments: SectionStudyRecordCommentsStateInterface;
  getStudyRecordCommentsInitial: typeof getStudyRecordCommentsInitial;
  getStudyRecordComments: typeof getStudyRecordComments;
}

const SectionStudyRecordCommentsPage = ({
  section,
  comments,
  getStudyRecordCommentsInitial,
  getStudyRecordComments,
  setActiveMenu,
}: Props) => {
  React.useEffect(() => {
    getStudyRecordCommentsInitial(section.id);
    setActiveMenu("");
  }, []);

  useOnMainScrollAreaScroll(() => {
    const meta = comments.meta;

    if (!comments.loading && comments.hasMore) {
      getStudyRecordComments(section.id, meta.currentPage + 1);
    }
  }, [comments.loading, comments.hasMore]);

  return <SectionStudyRecordComments commentsState={comments} />;
};

const pageInfo = {
  title: "お知らせ一覧",
};
const EnhancedPage = enhanceSectionsPage(
  SectionStudyRecordCommentsPage,
  pageInfo,
);

const actions = {
  getStudyRecordCommentsInitial,
  getStudyRecordComments,
};

const mapStateToProps = (state: AppStateInterface) => ({
  comments: state.sectionStudyRecordCommentsState,
});

export default withRouter(connect(mapStateToProps, actions)(EnhancedPage));
