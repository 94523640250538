import { StudentStateInterface } from "../interfaces/StudentInterface";
import { HTTP_ERROR_MESSAGE } from "./index";

const defaultState = {
  data: null,
  submitting: false,
  apiErrors: [],
};

const student = (
  state: StudentStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): StudentStateInterface => {
  switch (action.type) {
    case "CLEAR_STUDENT":
      return {
        ...state,
        data: null,
      };
    case "GET_STUDENT_RESPONSE_RECEIVED":
      return {
        ...state,
        data: action.payload.student,
        apiErrors: [],
      };
    case "GET_STUDENT_RESPONSE_ERROR":
      if (!action.payload || !action.payload.status) {
        alert(HTTP_ERROR_MESSAGE);
      }

      return state;
    case "UPDATE_STUDENT_TAGS_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "UPDATE_STUDENT_TAGS_SUCCESS":
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        submitting: false,
        apiErrors: [],
        data: {
          ...state.data,
          tags: action.payload,
        },
      };
    case "UPDATE_STUDENT_TAGS_FAILURE":
      return {
        ...state,
        submitting: false,
      };
    case "UPDATE_STUDENT_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "UPDATE_STUDENT_SUCCESS":
      return {
        ...state,
        data: action.payload.student,
        apiErrors: [],
        submitting: false,
      };
    case "UPDATE_STUDENT_FAILURE":
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors || [] : [],
      };
    default:
      return state;
  }
};

export default student;
