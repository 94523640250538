import * as React from "react";
import { SectionSchedule } from "../../../domains/SectionSchedule";
import { createContext } from "../../../helpers/React";
import {
  Modal as ReactModal,
  Props as ReactModalProps,
} from "../../../components/atoms/Modal";

const ModalStatus = {
  closed: "closed",
  detail: "detail",
  editing: "editing",
  creating: "creating",
  studentTag: "studentTag",
} as const;
type ModalStatus = keyof typeof ModalStatus;

type State = {
  modalStatus: ModalStatus;
  selectedSectionSchedule?: SectionSchedule;
};

const ModalActions = {
  open: "open",
  edit: "edit",
  close: "close",
  new: "new",
  studentTag: "studentTag",
} as const;

type Actions =
  | {
      type: (typeof ModalActions)["open"];
      selectedSchedule: SectionSchedule;
    }
  | {
      type: (typeof ModalActions)["edit"];
    }
  | {
      type: (typeof ModalActions)["close"];
    }
  | {
      type: (typeof ModalActions)["new"];
    }
  | {
      type: (typeof ModalActions)["studentTag"];
    };

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case ModalActions.open:
      return {
        modalStatus: ModalStatus.detail, // 今のところモーダル開いて詳細 -> editボタンを押すというフローになるので、open時=detail
        selectedSectionSchedule: action.selectedSchedule,
      };
    case ModalActions.studentTag:
      return { ...state, modalStatus: ModalStatus.studentTag };
    case ModalActions.edit:
      return { ...state, modalStatus: ModalStatus.editing };
    case ModalActions.new:
      return { ...state, modalStatus: ModalStatus.creating };
    case ModalActions.close:
      return { modalStatus: ModalStatus.closed };
  }
};
const initialState: State = { modalStatus: ModalStatus.closed };

export const useModalState = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const open = React.useCallback((sectionSchedule: SectionSchedule) => {
    dispatch({ type: ModalActions.open, selectedSchedule: sectionSchedule });
  }, []);

  const openStudentTags = React.useCallback(() => {
    dispatch({ type: ModalActions.studentTag });
  }, []);

  const close = React.useCallback(() => {
    dispatch({ type: ModalActions.close });
  }, []);

  const edit = React.useCallback(() => {
    dispatch({ type: ModalActions.edit });
  }, []);
  const openAddModal = React.useCallback(() => {
    dispatch({ type: ModalActions.new });
  }, []);

  const value = React.useMemo(() => {
    return {
      isOpen: state.modalStatus !== ModalStatus.closed,
      isEditing: state.modalStatus === ModalStatus.editing,
      isDetail: state.modalStatus === ModalStatus.detail,
      isStudentTag: state.modalStatus === ModalStatus.studentTag,
      selectedSectionSchedule: state.selectedSectionSchedule,
      openAddModal,
      openStudentTags,
      close,
      edit,
      open,
    };
  }, [state, open, close, edit]);

  return value;
};

type ModalContextState = ReturnType<typeof useModalState>;

export const { Provider: ModalProvider, useContext: useModalContext } =
  createContext<ModalContextState>();

type ModalProps = Omit<ReactModalProps, "onRequestClose" | "isOpen">;
export const Modal = ({ className, children }: ModalProps) => {
  const modalState = useModalContext();

  return (
    <ReactModal
      className={className}
      onRequestClose={modalState.close}
      isOpen={modalState.isOpen}
    >
      {children}
    </ReactModal>
  );
};

export const ModalHeader = ({ children }: React.PropsWithChildren<unknown>) => {
  const { close } = useModalContext();
  return <ReactModal.Header onClose={close}>{children}</ReactModal.Header>;
};

export const ModalBody = ReactModal.Body;
