import * as React from "react";
import styles from "./SectionsLectureTransfersNewPage.scss";
import enhanceSectionsPage from "../../hocs/enhanceSectionsPage";
import { SectionsLectureTransferForm } from "./SectionsLectureTransferForm";
import { useFetchLectureAttendance } from "./useFetchLectureAttendance";
import Loader from "../../components/atoms/Loader/index";
import SectionInterface from "../../interfaces/SectionInterface";
import { useErrorDispatcher } from "../../hooks/useErrorDispatcher";
import { useQueryString } from "../../hooks/useQueryString";
import { Breadcrumbs } from "../../components/general/Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";

type Props = OutletContextProps & { section: SectionInterface };
export const SectionsLectureTransfersNewPage_ = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("schedule_list");
  }, []);
  const section = props.section;
  const { data, isLoading } = useLectureAttendance({ section });

  if (isLoading || !data) {
    return <Loader loading />;
  }

  const breadCrumbItems = buildBreadCrumbItems(
    section.id,
    data.lectureSessionId,
  );

  return (
    <>
      <div className={styles.breadCrumbWrapper}>
        <Breadcrumbs items={breadCrumbItems}>
          {(item, isLastItem) =>
            isLastItem ? (
              item.label
            ) : (
              <Link to={item.url ?? "/"}>{item.label}</Link>
            )
          }
        </Breadcrumbs>
      </div>
      <div className={styles.formWrapper}>
        <SectionsLectureTransferForm
          isOriginEditable={false}
          originLectureAttendance={data}
          section={section}
        />
      </div>
    </>
  );
};

const buildBreadCrumbItems = (sectionId: string, lectureSessionId?: string) => {
  if (lectureSessionId) {
    return [
      { label: "校舎予定リスト", url: `/sections/${sectionId}/schedules/list` },
      {
        label: "講座出席詳細",
        url: `/sections/${sectionId}/lecture_sessions/${lectureSessionId}/attendees`,
      },
      { label: "振替受講の登録" },
    ];
  }

  return [
    { label: "校舎予定リスト", url: `/sections/${sectionId}/schedules/list` },
    { label: "振替受講の登録" },
  ];
};

const useLectureAttendance = ({ section }: { section: Props["section"] }) => {
  // NOTE: 現状はこの画面は出席詳細からの遷移を前提としているため
  // クエリストリングにlecture_attendance_idがない場合は404としています
  const { sendNotFound } = useErrorDispatcher();
  const { lecture_attendance_id } = useQueryString();
  if (!lecture_attendance_id) {
    sendNotFound();
  }

  return useFetchLectureAttendance({
    sectionId: section.id,
    lectureAttendanceId: lecture_attendance_id,
  });
};

const pageInfo = {
  title: "振替受講の登録",
};
export const SectionsLectureTransfersNewPage = withRouter(
  enhanceSectionsPage(SectionsLectureTransfersNewPage_, pageInfo),
);
