import { Content } from "../../../domains/Content";
import { useDeleteContent, useFetchSectionContents } from "./useContentsApi";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import LtiDeploymentInterface from "../../../interfaces/LtiDeploymentInterface";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useQueryString } from "../../../hooks/useQueryString";
import { useEffect } from "react";
import { useQueryError } from "../../../hooks/http/useQueryError";

type UseSectionsSettingsContentsPageProps = {
  sectionId: string;
  ltiDeployments: LtiDeploymentInterface[];
};

export const useSectionsSettingsContentsPage = ({
  sectionId,
  ltiDeployments,
}: UseSectionsSettingsContentsPageProps) => {
  const sectionContents = useFetchSectionContents({ sectionId });
  useQueryError(sectionContents.error);
  const { isDeleting, onDelete } = useDeleteContent({ sectionId });
  useLtiDeeplinkingResponseNotification();

  useOnMainScrollAreaScroll(() => {
    if (
      sectionContents.hasNextPage &&
      !sectionContents.isLoading &&
      !sectionContents.isFetchingNextPage
    ) {
      sectionContents.fetchNextPage();
    }
  }, [
    sectionContents.hasNextPage,
    sectionContents.isLoading,
    sectionContents.isFetchingNextPage,
    sectionContents.fetchNextPage,
  ]);

  const contents = sectionContents.data
    ? sectionContents.data.pages.reduce<Content[]>(
        (previousValue, currentValue) => {
          return [...previousValue, ...currentValue.data];
        },
        [],
      )
    : [];

  const mexcbtLtiDeployment: LtiDeploymentInterface | undefined =
    ltiDeployments.find(
      (item: LtiDeploymentInterface) => item.oauthApplicationName === "MEXCBT",
    );

  const basicTabs = [
    {
      label: "コンテンツ",
      active: true,
      link: `/sections/${sectionId}/settings/contents`,
    },
    {
      label: "コース",
      active: false,
      link: `/sections/${sectionId}/settings/content_courses`,
    },
  ];
  const tabs =
    ltiDeployments.length == 0
      ? basicTabs
      : [
          ...basicTabs,
          {
            label: "学習結果",
            active: false,
            link: `/sections/${sectionId}/settings/content_courses/results`,
          },
        ];

  return {
    isLoading: sectionContents.isLoading,
    isFetchingNextPage: sectionContents.isFetchingNextPage,
    contents,
    isDeleting,
    onDelete,
    mexcbtLtiDeployment,
    tabs: tabs,
  };
};

const useLtiDeeplinkingResponseNotification = () => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const query = useQueryString();

  useEffect(() => {
    query.lti_dl === "success" &&
      showSuccessMessage("コンテンツを登録しました");
    query.lti_dl === "failed" &&
      showErrorMessage("コンテンツの登録に失敗しました");
  }, []);
};
