import SwitchTabs from "../../components/molecules/SwitchTabs";
import { Period } from "./index";

export const StudyTaskSwitchTabs = ({
  period,
  setPeriod,
}: {
  period: Period;
  setPeriod: (period: Period) => void;
}) => (
  <SwitchTabs
    tabs={[
      {
        id: "during",
        label: "期間中",
        active: period === "during",
        disabled: false,
      },
      {
        id: "before",
        label: "期間前",
        active: period === "before",
        disabled: false,
      },
      {
        id: "after",
        label: "期間終了",
        active: period === "after",
        disabled: false,
      },
    ]}
    onClickTab={(tab) => () => {
      setPeriod(tab.id as Period);
    }}
  />
);
