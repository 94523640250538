import * as React from "react";

import styles from "./styles.scss";
import classNames from "classnames";

interface Props {
  className?: string;
  onClick?: React.DOMAttributes<HTMLDivElement>["onClick"];
}

const Backdrop = (props: Props): JSX.Element => {
  return (
    <div
      className={classNames(styles.backdrop, props.className)}
      onClick={props.onClick}
    />
  );
};

export default Backdrop;
