import * as React from "react";
import { OutletContextProps, withRouter } from "../../helpers/RouterHelper";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import StudentInterface from "../../interfaces/StudentInterface";
import { StudentAnalyticsTypeTab } from "../../components/features/StudentAnalyticsTypeTab";
import { ExaminationOrganizerSelect } from "../../components/features/ExaminationOrganizerSelect/ExaminationOrganizerSelect";
import { ExaminationClassificationSelect } from "../../components/features/ExaminationClassificationSelect/ExaminationClassificationSelect";
import Loader from "../../components/atoms/Loader";
import { useEffect } from "react";
import ContentBox from "../../components/atoms/ContentBox";
import SwitchTabs from "../../components/molecules/SwitchTabs";
import { useFetchStudentAnalyticsExamination } from "./useFetchStudentAnalyticsExamination";
import { useQueryError } from "../../hooks/http/useQueryError";
import { ExaminationResultTable } from "./ExaminationResultTable";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";
import { ExaminationSubject } from "../../domains/ExaminationSubject";
import { ExaminationResult } from "../../domains/ExaminationResult";
import { Link } from "react-router-dom";

const getSubjects = (examinationResults?: ExaminationResult[]) =>
  examinationResults
    ?.flatMap((examinationResult) =>
      examinationResult.subjectResults.flatMap(
        (subjectResult) => subjectResult.subject,
      ),
    )
    .sort((a, b) => a.sequence - b.sequence);

const makeUniqueSubjects = (examinationSubjects?: ExaminationSubject[]) =>
  examinationSubjects?.filter(
    (subject, index, self) =>
      self.findIndex((s) => s.id === subject.id) === index,
  );

type Props = OutletContextProps & { student: StudentInterface };
const StudentsAnalyticsExaminationPage_ = ({
  student,
  setActiveMenu,
}: Props) => {
  const studentId = student.id;

  React.useEffect(() => {
    setActiveMenu("analytics");
  }, []);

  const [examinationOrganizerId, setExaminationOrganizerId] = React.useState<
    string | null
  >(null);
  const [examinationClassificationId, setExaminationClassificationId] =
    React.useState<string | null>(null);
  const [scoreDisplayType, setScoreDisplayType] = React.useState<
    "point" | "deviation"
  >("point");

  useEffect(() => {
    remove();
  }, [examinationOrganizerId, examinationClassificationId]);

  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    error,
    remove,
  } = useFetchStudentAnalyticsExamination({
    studentId,
    examinationOrganizerId,
    examinationClassificationId,
  });
  useQueryError(error);

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage, fetchNextPage]);

  const subjects = getSubjects(data);
  const uniqueSubjects = makeUniqueSubjects(subjects);

  return (
    <>
      <div className="mb-4">
        <StudentAnalyticsTypeTab
          student={student}
          activeAnalyticsType="examination"
        />
      </div>

      <div>
        <div className="mb-7 grid grid-cols-1 items-center justify-between gap-4 sm:grid-cols-[22rem_24rem_1fr]">
          <div className="my-3">
            <ExaminationOrganizerSelect
              studentId={student.id}
              size="xs"
              placeholder="主催者"
              onChange={setExaminationOrganizerId}
            />
          </div>
          <div className="my-3">
            <ExaminationClassificationSelect
              studentId={student.id}
              size="xs"
              organizerId={examinationOrganizerId}
              placeholder="試験の種類"
              onChange={setExaminationClassificationId}
              value={examinationClassificationId}
            />
          </div>
          {student.status !== "preinviting" && student.status !== "inactive" ? (
            <div className="flex justify-end">
              <Link
                className="buiButtonBase buiButtonVariantFilled"
                to={`/students/${studentId}/analytics/examinations/result/new`}
              >
                成績記録の登録
              </Link>
            </div>
          ) : null}
        </div>
        {isLoading || data == null || uniqueSubjects == null ? (
          <Loader loading />
        ) : data.length === 0 ? (
          <p className="mt-2 text-center text-md text-black">
            成績記録が登録されていません
          </p>
        ) : (
          <ContentBox className="overflow-hidden p-0">
            <div className="flex justify-end p-7">
              <SwitchTabs
                tabs={[
                  {
                    id: "point",
                    label: "得点",
                    active: scoreDisplayType === "point",
                  },
                  {
                    id: "deviation",
                    label: "偏差値",
                    active: scoreDisplayType === "deviation",
                  },
                ]}
                onClickTab={(tab) => (e) => {
                  e.preventDefault();
                  setScoreDisplayType(tab.id as "point" | "deviation");
                }}
              />
            </div>
            <ExaminationResultTable
              studentId={studentId}
              scoreDisplayType={scoreDisplayType}
              subjects={uniqueSubjects}
              examinationResults={data}
            />
          </ContentBox>
        )}
        {isFetchingNextPage && <Loader loading />}
      </div>
    </>
  );
};

const pageInfo = {
  title: "アナリティクス",
};
export const StudentsAnalyticsExaminationPage = withRouter(
  enhanceStudentsPage(StudentsAnalyticsExaminationPage_, pageInfo),
);
