import ApiClient from "../api";
import { GetRequestOptions } from "../api";

const SectionKarteApi = {
  interruptGetKartes: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/sections/${sectionId}/kartes`,
      options,
    );
  },

  getKartes: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(`/api/v1/sections/${sectionId}/kartes`, options);
  },

  getKarteTemplates: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/sections/${sectionId}/karte_templates`,
      options,
    );
  },
};

export default SectionKarteApi;
