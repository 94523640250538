import Container from "./Container";
import List from "./List";
import Label from "./Label";
import Item from "./Item";

const Dropdown = {
  Container,
  List,
  Label,
  Item,
};

export default Dropdown;
