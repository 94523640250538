import * as React from "react";
import { connect, HandleThunkActionCreator } from "react-redux";
import TabsNavi from "../../molecules/TabsNavi";
import SectionInterface from "../../../interfaces/SectionInterface";
import StaffBoardPostForm from "../../molecules/StaffBoardPostForm";
import StaffBoardPostList from "../../molecules/StaffBoardPostList";
import Loader from "../../atoms/Loader/index";
import { SectionStaffBoardPostsStateInterface } from "../../../interfaces/SectionStaffBoardPostInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { StaffBoardPostFormInterface } from "../../../interfaces/StaffBoardPostInterface";
import {
  postComment,
  createStaffBoardPost,
  deleteStaffBoardPost,
  deleteComment,
} from "../../../actions/pages/SectionStaffBoardPostsPage/actions";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface | undefined;
  staffBoardPostsState: SectionStaffBoardPostsStateInterface;
  createPost: HandleThunkActionCreator<typeof createStaffBoardPost>;
  deletePost: HandleThunkActionCreator<typeof deleteStaffBoardPost>;
  postComment: HandleThunkActionCreator<typeof postComment>;
  deleteComment: HandleThunkActionCreator<typeof deleteComment>;
}

const SectionStaffBoardPosts = (props: Props) => {
  if (!props.currentOperator) return null;
  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "スタッフボード",
            link: `#`,
            active: true,
          },
        ]}
      />
      <StaffBoardPostForm
        submitting={props.staffBoardPostsState.submitting}
        operatorName={props.currentOperator.fullName}
        onSubmit={handleSubmit(props)}
      />
      <StaffBoardPostList
        section={props.section}
        currentOperator={props.currentOperator}
        staffBoardPosts={props.staffBoardPostsState.data}
        deletePost={props.deletePost}
        postComment={props.postComment}
        deleteComment={props.deleteComment}
      />
      <Loader loading={props.staffBoardPostsState.loading} />
    </React.Fragment>
  );
};

const handleSubmit =
  (props: Props) =>
  (values: StaffBoardPostFormInterface, resetForm: () => void) => {
    props.createPost(props.section.id, values, resetForm);
  };

const actions = {
  createPost: createStaffBoardPost,
  deletePost: deleteStaffBoardPost,
  postComment,
  deleteComment,
};

export default connect(null, actions)(SectionStaffBoardPosts);
