import * as React from "react";
import StudentInterface from "../../../../interfaces/StudentInterface";
import { BillingPlanLabel } from "../../../../components/features/BillingPlanLabel";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../../../../components/general/DropdownMenu/DropdownMenu";
import { IconArrowDown } from "@studyplus/boron-ui";
import { DropdownMenuItem } from "../../../../components/general/DropdownMenu/DropdownMenu";
import { StudentBillingPlanModal } from "./StudentBillingPlanModal";
import { toJapanese, BillingPlan } from "../../../../domains/BillingPlan";

type Props = {
  student: StudentInterface;
  updateStudent: (student: StudentInterface) => void;
};

export const StudentBillingPlan = ({ student, updateStudent }: Props) => {
  const { isOpen, openFreeModal, openProModal, newBillingPlan, close } =
    useStudentBillingPlanModal({ currentBillingPlan: student.billingPlan });

  return (
    <>
      <div className="flex items-center justify-center gap-5 font-bold">
        <BillingPlanLabel billingPlan={student.billingPlan} />
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="flex items-center gap-6">
              <div className="w-7 text-left font-bold text-black">
                {toJapanese(student.billingPlan)}
              </div>
              <IconArrowDown className="text-gray-600" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onSelect={openFreeModal}
              selected={student.billingPlan === "free"}
            >
              <div className="px-11">フリー</div>
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={openProModal}
              selected={student.billingPlan === "pro"}
            >
              <div className="px-11">プロ</div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {newBillingPlan && (
        <StudentBillingPlanModal
          student={student}
          updateStudent={updateStudent}
          isOpen={isOpen}
          closeModal={close}
          newBillingPlan={newBillingPlan}
        />
      )}
    </>
  );
};

const useStudentBillingPlanModal = ({
  currentBillingPlan,
}: {
  currentBillingPlan: BillingPlan;
}) => {
  const [isOpen, setIsModalOpen] = React.useState(false);
  const [newBillingPlan, setNewBillingPlan] =
    React.useState<BillingPlan | null>(null);

  const openFreeModal = () => {
    if (currentBillingPlan === "free") {
      return;
    }

    setNewBillingPlan("free");
    setIsModalOpen(true);
  };

  const openProModal = () => {
    if (currentBillingPlan === "pro") {
      return;
    }

    setNewBillingPlan("pro");
    setIsModalOpen(true);
  };

  const close = () => {
    setIsModalOpen(false);
  };

  return {
    openFreeModal,
    openProModal,
    close,
    isOpen,
    newBillingPlan,
  };
};
