import * as React from "react";
import styles from "./styles.scss";
import { TagFilterContainer } from "../TagFilter";
import { TagFilterSelectedTagsArea } from "../TagFilter/TagFilterSelectedTagsArea";

type FilterWrapperProps = {
  StudentStatusFilter?: React.ReactNode;
  StudentTagFilter?: React.ReactNode;
  LearningMaterialTagFilter?: React.ReactNode;
};

// NOTE: 生徒フィルターと教材タグフィルターを共存して使うことを考慮したラッパーコンポーネントです
//       生徒フィルターだけ、教材タグフィルターだけを使う場合でもこのラッパーを使います
export const FilterWrapper = ({
  StudentStatusFilter,
  StudentTagFilter,
  LearningMaterialTagFilter,
}: FilterWrapperProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.label}>絞込検索：</div>
      <div className={styles.tags}>
        {StudentStatusFilter && (
          <span className={styles.tag}>{StudentStatusFilter}</span>
        )}
        {(StudentTagFilter || LearningMaterialTagFilter) && (
          <div className={styles.tagFilter}>
            <TagFilterContainer>
              <TagFilterSelectedTagsArea>
                {StudentTagFilter}
                {LearningMaterialTagFilter}
              </TagFilterSelectedTagsArea>
            </TagFilterContainer>
          </div>
        )}
      </div>
    </div>
  );
};
