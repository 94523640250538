import * as React from "react";
import styles from "./styles.scss";
import SectionInterface from "../../../interfaces/SectionInterface";
import Icon from "../../atoms/Icon/index";
import StaffBoardPostInterface, {
  StaffBoardPostCommentInterface,
} from "../../../interfaces/StaffBoardPostInterface";
import TimeHelper from "../../../helpers/TimeHelper";
import StaffBoardPostCommentForm from "../StaffBoardPostCommentForm";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { HandleThunkActionCreator } from "react-redux";
import CommentItem from "../CommentItem";
import { Link } from "react-router-dom";
import {
  EllipsisDropdown,
  EllipsisDropdownLink,
} from "../../general/EllipsisDropdown/index";
import { StaffBoardPostCommentFormInterface } from "../../../interfaces/StaffBoardPostInterface";
import { RegexpHelper } from "../../../helpers/RegexpHelper";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";
const nl2br = require("react-nl2br");
const reactStringReplace = require("react-string-replace");

type SuperProps = React.PropsWithChildren<unknown> & WithRouterProps;
interface Props extends SuperProps {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  submitting: boolean;
  staffBoardPost: StaffBoardPostInterface;
  deletePost: HandleThunkActionCreator<() => void>;
  postComment: HandleThunkActionCreator<
    (values: StaffBoardPostCommentFormInterface) => void
  >;
  deleteComment: HandleThunkActionCreator<(commentId: string) => void>;
}

class StaffBoardPostItem extends React.Component<
  Props,
  Record<string, unknown>
> {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.main}>{this.renderMain()}</div>
        <div className={styles.footer}>
          <StaffBoardPostCommentForm
            sectionId={this.props.section.id}
            staffBoardPost={this.props.staffBoardPost}
            currentOperator={this.props.currentOperator}
            postComment={this.props.postComment}
            submitting={this.props.submitting}
          />
        </div>
      </div>
    );
  }

  renderMain() {
    const { staffBoardPost } = this.props;
    const regexp = RegexpHelper.URL;
    return (
      <React.Fragment>
        <div className={styles.content}>
          <div className={styles.meta}>
            <p>
              {TimeHelper.japaneseFormatFullDateTime(staffBoardPost.postedAt)}
            </p>
            <div className={styles.right}>
              <Icon name="icon-account" className={styles.svg} />
              <p className={styles.sectionName}>
                {this.props.staffBoardPost.name}
              </p>
              {this.renderDropDown()}
            </div>
          </div>
          <div className={styles.title}>
            <Link
              to={`/sections/${this.props.section.id}/staff_board_posts/${this.props.staffBoardPost.id}`}
            >
              <p>{staffBoardPost.title}</p>
            </Link>
          </div>
          <div className={styles.body}>
            {nl2br(staffBoardPost.comment).flatMap(
              (line: string, i: number) => {
                return reactStringReplace(
                  line,
                  regexp,
                  (match: string, n: number) => {
                    return (
                      <a
                        href={match}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={`staffBoardPost-link-${i}-${n}`}
                      >
                        {match}
                      </a>
                    );
                  },
                );
              },
            )}
          </div>
          {this.renderCommentIcon()}
        </div>
        <div className={styles.comments}>{this.renderComments()}</div>
      </React.Fragment>
    );
  }

  private renderDropDown() {
    return (
      <EllipsisDropdown>
        <EllipsisDropdownLink
          href={`/sections/${this.props.section.id}/staff_board_posts/${this.props.staffBoardPost.id}/edit`}
        >
          <Icon name="icon-edit" />
          編集
        </EllipsisDropdownLink>
        <EllipsisDropdownLink onClick={this.handleDeletePost}>
          <Icon name="icon-trash" />
          削除
        </EllipsisDropdownLink>
      </EllipsisDropdown>
    );
  }

  private renderCommentIcon() {
    if (this.props.staffBoardPost.comments.length === 0) {
      return (
        <span className={styles.commentIcon}>
          <Icon name="icon-action-comment-default" className={styles.icon} />
          コメント
        </span>
      );
    } else {
      return (
        <span className={`${styles.commentIcon} ${styles.active}`}>
          <Icon
            name="icon-action-comment-selected"
            className={`${styles.icon}`}
          />
          {this.props.staffBoardPost.comments.length}
        </span>
      );
    }
  }

  private renderComments() {
    return this.props.staffBoardPost.comments.map(
      (comment: StaffBoardPostCommentInterface) => {
        return (
          <CommentItem
            senderName={comment.name}
            createdAt={comment.postedAt}
            comment={comment.comment}
            onDelete={this.handleDeleteComment(comment.id)}
            key={`staff_board_comment-${this.props.staffBoardPost.id}=${comment.id}`}
          />
        );
      },
    );
  }

  private handleDeleteComment = (commentId: string) => () => {
    this.props.deleteComment(commentId);
  };

  private handleDeletePost = () => {
    if (
      !this.props.submitting &&
      window.confirm("本当に削除してよろしいですか？")
    ) {
      this.props.deletePost();
    }
  };
}

export default withRouter<Props>(StaffBoardPostItem);
