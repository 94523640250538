import { HTTPMethod } from "../api";

export const translateHttpMethod = (method: HTTPMethod) => {
  switch (method) {
    case "GET":
      return "取得";
    case "POST":
      return "登録";
    case "DELETE":
      return "削除";
    case "PUT":
    case "PATCH":
      return "更新";
  }
};
