import { OperatorSettingsInterface } from "../../../interfaces/OperatorSettingsInterface";
import {
  ActionTypes,
  Action,
} from "../../../actions/pages/SettingsProfilePage/types";

const defaultState: OperatorSettingsInterface = {
  submitting: false,
  emailApiErrors: [],
  nameApiErrors: [],
  passwordApiErrors: [],
  operatorProfile: null,
  meetingSettingErrors: [],
};

export const operatorSettings = (
  state: OperatorSettingsInterface = defaultState,
  action: Action,
): OperatorSettingsInterface => {
  switch (action.type) {
    case ActionTypes.UPDATE_OPERATOR_EMAIL_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.UPDATE_OPERATOR_EMAIL_SUCCESS:
      return {
        ...state,
        submitting: false,
        emailApiErrors: [],
      };
    case ActionTypes.UPDATE_OPERATOR_EMAIL_ERROR:
      return {
        ...state,
        submitting: false,
        emailApiErrors: action.payload
          ? action.payload.errors || []
          : state.emailApiErrors,
      };
    case ActionTypes.UPDATE_OPERATOR_NAME_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.UPDATE_OPERATOR_NAME_SUCCESS:
      return {
        ...state,
        submitting: false,
        nameApiErrors: [],
      };
    case ActionTypes.UPDATE_OPERATOR_NAME_ERROR:
      return {
        ...state,
        submitting: false,
        nameApiErrors: action.payload
          ? action.payload.errors || []
          : state.nameApiErrors,
      };
    case ActionTypes.UPDATE_OPERATOR_PASSWORD_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.UPDATE_OPERATOR_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
        passwordApiErrors: [],
      };
    case ActionTypes.UPDATE_OPERATOR_PASSWORD_ERROR:
      return {
        ...state,
        submitting: false,
        passwordApiErrors: action.payload
          ? action.payload.errors || []
          : state.nameApiErrors,
      };
    case ActionTypes.GET_OPERATOR_PROFILE_SUCCESS:
      return {
        ...state,
        operatorProfile: action.payload,
      };
    case ActionTypes.DISCONNECT_ZOOM_SUCCESS:
      return {
        ...state,
        operatorProfile: state.operatorProfile
          ? {
              ...state.operatorProfile,
              zoomConnected: false,
              zoomProfile: undefined,
            }
          : null,
      };
    case ActionTypes.CONNECT_GOOGLE_CLASSROOM_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.CONNECT_GOOGLE_CLASSROOM_ERROR:
      return {
        ...state,
        submitting: false,
      };
    case ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_SUCCESS:
      return {
        ...state,
        submitting: false,
        operatorProfile: state.operatorProfile
          ? {
              ...state.operatorProfile,
              googleClassroomConnected: false,
              googleUserProfile: undefined,
            }
          : null,
      };
    case ActionTypes.UPDATE_ZOOM_MEETING_SETTING_REQUEST:
      return {
        ...state,
        submitting: true,
        meetingSettingErrors: [],
      };
    case ActionTypes.UPDATE_ZOOM_MEETING_SETTING_ERROR:
      return {
        ...state,
        submitting: false,
        meetingSettingErrors: action.payload?.errors || [],
      };
    case ActionTypes.UPDATE_ZOOM_MEETING_SETTING_SUCCESS:
      if (!state.operatorProfile || !state.operatorProfile.zoomConnected) {
        return state;
      }

      return {
        ...state,
        operatorProfile: {
          ...state.operatorProfile,
          zoomMeetingSetting: action.payload,
        },
        submitting: false,
      };
    default:
      return state;
  }
};
