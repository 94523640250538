import * as React from "react";
import styles from "./styles.scss";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { Link } from "react-router-dom";
import CircleIcon from "../../atoms/CircleIcon/index";
import { connect, HandleThunkActionCreator } from "react-redux";
import { dispatchSwitchOperator } from "../../../actions/common/session";

interface Props {
  operator: OperatorInterface;
  isCurrentOperator: boolean;
  switchOperator: HandleThunkActionCreator<typeof dispatchSwitchOperator>;
}

const handleClick = (props: Props) => () => {
  if (props.isCurrentOperator) {
    return;
  }

  props.switchOperator(props.operator);
};

const CurrentUsername = (props: Props): React.ReactElement => {
  return (
    <Link
      to="/settings/profile"
      className={`${styles.root} ${styles.isCurrent}`}
    >
      <div className={styles.left}>
        <CircleIcon
          className={styles.iconAccount}
          iconProps={{
            name: "icon-account",
            iconClassName: styles.iconAccount,
          }}
        />
      </div>
      <div className={styles.right}>
        <p className={styles.name}>{props.operator.fullName}</p>
        <p className={styles.link}>アカウント編集</p>
      </div>
    </Link>
  );
};

const OtherUsername = (props: Props) => {
  return (
    <div className={styles.root} onClick={handleClick(props)}>
      <div className={styles.left}>
        <CircleIcon
          className={styles.iconAccount}
          iconProps={{
            name: "icon-account",
            iconClassName: styles.iconAccount,
          }}
        />
      </div>
      <div className={styles.right}>
        <p className={styles.name}>{props.operator.fullName}</p>
      </div>
    </div>
  );
};

const HeaderAccountUsername = (props: Props) => {
  if (props.isCurrentOperator) {
    return <CurrentUsername {...props} />;
  } else {
    return <OtherUsername {...props} />;
  }
};

const actions = {
  switchOperator: dispatchSwitchOperator,
};

export default connect(null, actions)(HeaderAccountUsername);
