import * as React from "react";
import styles from "./styles.scss";

const EnglishPrivacyPolicy: React.FC<Record<string, never>> = () => {
  return (
    <div className={styles.root}>
      <Terms />
    </div>
  );
};

const Terms = () => {
  return (
    <React.Fragment>
      <h2>Personal Information Protection Policy</h2>
      <p>
        With regard to the protection of all personal information handled by us,
        we will fully recognize its social mission and comply with laws and
        regulations regarding the protection of the rights of the individual and
        personal information. To implement the following policies, we also
        declare that we will build a personal information protection management
        system and we will make company-wide efforts to continuously improve it
        while always keeping abreast of the latest trends in information
        technology, changes in social demands, and changes in the business
        environment.
      </p>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          Personal information will be acquired, used and provided only to the
          extent necessary for the execution of our legitimate business
          operations in planning, development and operation of learning
          management and learning information platform as well as for the hiring
          and personnel management of employees, and will not be used beyond the
          scope necessary to achieve the specified purpose of use (we will not
          use personal information for purposes other than those specified). We
          will take measures to ensure that the information is not used for any
          other purpose.
        </li>
        <li className={styles.list_item}>
          We will comply with the laws and regulations, national guidelines and
          other standards regarding the protection of personal information.
        </li>
        <li className={styles.list_item}>
          To prevent risks such as leakage, loss, or damage of personal
          information, we will take reasonable safety measures to prevent such
          risks and continuously improve our personal information security
          system by allocating management resources to meet the actual
          conditions of the business. In addition, in the event of an incident,
          we will promptly take corrective measures.
        </li>
        <li className={styles.list_item}>
          Complaints and consultations regarding the handling of personal
          information will be dealt with promptly and sincerely in an
          appropriate manner.
        </li>
        <li className={styles.list_item}>
          Based on changes in the environment surrounding us, the personal
          information protection management system will be reviewed in a timely
          and appropriate manner and improvements will be continuously promoted.
        </li>
      </ol>
      <Heading />
      <p>
        This policy will be distributed to all employees and will be available
        to anyone at any time by posting it on our website and in pamphlets.
      </p>
      <p>
        If you have any questions or complaints about our privacy policy, please
        contact us at the following
      </p>
      <Heading />
      <p>2-5-12, Kanda-Surugadai 2-chome, Chiyoda-ku, Tokyo 101-0062, Japan</p>
      <p>
        Addressed to the Personal Information Protection Officer, Study Plus,
        Inc.
      </p>

      <hr />
      <h2>Privacy Policy</h2>
      <p>{`Handling of Members' Personal Information`}</p>
      <Heading />
      <p>
        {`Studyplus Inc. ("we", "us" or "our") set forth below the Privacy Policy (hereinafter referred to as the "Policy") regarding the handling of the personal information of users (the "User" refers to the entity to which the personal information is attributed) in providing our study management service "Studyplus" and "Studyplus for School" (hereinafter referred to as "the Service") as defined in Article 5 of the Studyplus Terms of Service.This policy defines the policy for the handling of user information including personal information obtained by us, and is intended to ensure that we are able to provide users with greater trust and a sense of security, to comply with laws and regulations regarding personal information, and to realize the appropriate handling of user information including personal information.`}
      </p>
      <Heading>
        Article 1 (Information to be collected and method of collection)
      </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          {`In this policy, "personal information" refers to the personal information specified in the Personal Information Protection Law.`}
        </li>
        <li className={styles.list_item}>
          {`In this policy, "user information" means information related to the identification of the user, behavioral history of the communication service, and other information generated or stored in relation to the user or the user's terminal, including the user's smartphone, which is collected by the company in accordance with this policy. The personal information provided by the User to us shall be included in the User Information.`}
        </li>
        <li className={styles.list_item}>
          The user information collected by us and the method of collection are
          as follows.
          <ol className={styles.body}>
            <li className={styles.list_item_latin}>
              Information that the User provides to the Company by entering the
              information.
              <ol className={styles.body} type="i">
                <li className={styles.list_item_roman}>
                  Your name (including your furigana)
                </li>
                <li className={styles.list_item_roman}>Email address</li>
                <li className={styles.list_item_roman}>Date of birth</li>
                <li className={styles.list_item_roman}>
                  Credit card information
                </li>
                <li className={styles.list_item_roman}>Photo</li>
                <li className={styles.list_item_roman}>Gender</li>
                <li className={styles.list_item_roman}>
                  Current address (postal code, prefecture, city, town, village,
                  or other details of current address)
                </li>
                <li className={styles.list_item_roman}>
                  Phone number (including mobile phone number)
                </li>
                <li className={styles.list_item_roman}>
                  Email address (including mobile phone email address)
                </li>
                <li className={styles.list_item_roman}>
                  Name of the high school you are attending (from)
                </li>
                <li className={styles.list_item_roman}>
                  Date of graduation (planned)
                </li>
                <li className={styles.list_item_roman}>
                  Other personal information and user information entered by the
                  user in the form specified by us
                </li>
              </ol>
            </li>
            <li className={styles.list_item_latin}>
              {`When a user uses this service, if the user gives permission to cooperate with Twitter, Facebook, LINE and other social networking services (hereinafter referred to as "SNS") that are designated by us, we shall receive information provided by the SNS.`}
              <ol className={styles.body} type="i">
                <li className={styles.list_item_roman}>
                  IDs used by users on the relevant SNS
                </li>
                <li className={styles.list_item_roman}>
                  Other information that the user has allowed to be disclosed to
                  the company based on the privacy settings of the SNS.
                </li>
              </ol>
            </li>
            <li className={styles.list_item_latin}>
              Information that the Company collects on its own when the User
              uses the Service
              <ol className={styles.body} type="i">
                <li className={styles.list_item_roman}>Device information</li>
                <li className={styles.list_item_roman}>Log information</li>
                <li className={styles.list_item_roman}>
                  Cookies and anonymous IDs
                </li>
                <li className={styles.list_item_roman}>
                  Ad identifier (IDFA, Advertising ID, etc.)
                </li>
                <li className={styles.list_item_roman}>Location information</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <Heading>Article 2 (Purpose of Use)</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          The Company may use user information, including personal information
          of the users of the Service, for the purpose of providing the Service
          and for other purposes as set forth in this policy.
        </li>
        <li className={styles.list_item}>
          The specific purposes of use of user information in the Service are as
          follows.
          <ol className={styles.body}>
            <li className={styles.list_item_latin}>
              To provide, maintain, and protect the Service, including the
              receipt of registration, identification, authentication,
              calculation of usage fees, etc., for the Service, to respond to
              defects, and to provide technical support and improvements.
            </li>
            <li className={styles.list_item_latin}>
              To respond to inquiries about the service
            </li>
            <li className={styles.list_item_latin}>
              To respond to acts that violate the Terms of Service and other
              regulations.
            </li>
            <li className={styles.list_item_latin}>
              To notify you of changes to the Terms of Service, etc.
            </li>
            <li className={styles.list_item_latin}>
              To exercise our rights and perform our obligations under the
              contract and laws related to the Service.
            </li>
            <li className={styles.list_item_latin}>
              For purposes incidental to the above purposes of use.
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          In addition to the purposes of use listed in the preceding paragraph,
          the Company may use the information for the following purposes.
          <ol className={styles.body}>
            <li className={styles.list_item_latin}>
              To create and use statistical information in relation to the
              Service to the extent that individuals cannot be identified
            </li>
            <li className={styles.list_item_latin}>
              To analyze data necessary for the development of our new services.
            </li>
            <li className={styles.list_item_latin}>
              {`To provide personal information to educational institutions (including schools, prep schools, cram schools, etc.) based on the user's request for information materials and application forms.`}
            </li>
            <li className={styles.list_item_latin}>
              To deliver or display advertisements for products and services of
              us or third parties (including targeted advertisements using
              attribute information, access history, etc.) or e-mail newsletters
              that we deems useful to users of the Service.
            </li>
            <li className={styles.list_item_latin}>
              For other marketing purposes.
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          {`When the user provides information to the educational institution for the purpose of requesting information from the educational institution, the user agrees to provide the educational institution of the user's choice with the personal information provided by the user from among the following information registered.The personal information provided to the educational institution shall be managed by the educational institution for the purpose of contacting the member for student recruitment, and the user shall agree to be contacted directly by the educational institution by phone, email, or mail. Please inquire directly to the educational institution concerned about the handling of personal information managed by the institution. The educational institution concerned is prohibited from using the personal information obtained through this service for any purpose other than those mentioned above.`}
          <ol className={styles.body}>
            <li className={styles.list_item_latin}>Name (including frigana)</li>
            <li className={styles.list_item_latin}>Email address</li>
            <li className={styles.list_item_latin}>Date of birth</li>
            <li className={styles.list_item_latin}>Gender</li>
            <li className={styles.list_item_latin}>
              Current address (postal code, prefecture, city, town, village, and
              other details of current address)
            </li>
            <li className={styles.list_item_latin}>
              Phone number (including mobile phone number)
            </li>
            <li className={styles.list_item_latin}>
              E-mail address (including mobile phone e-mail address)
            </li>
            <li className={styles.list_item_latin}>
              Name of the high school you are attending (from)
            </li>
            <li className={styles.list_item_latin}>
              Date of graduation (planned)
            </li>
            <li className={styles.list_item_latin}>
              Relationship with the student.
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          {`For the purpose of effectively delivering optimized advertisements for users in external media other than us, we provides user attributes (hereinafter referred to as "informative data") determined or inferred from cookies, ad identifiers, and the information accumulated with cookies, ad identifiers, that cannot directly identify a specific individual from among the personal information obtained from users  to the companies involved in ad serving (ad serving companies, media companies, advertising companies, advertisers, etc.), and the user agrees above.The above-mentioned informative data provided by us may be collated with the user's attribute information held by the above-mentioned companies and used to deliver optimized advertisements to users in accordance with the user's attributes. The companies that provide such data are those listed in Article 9.2.`}
        </li>
      </ol>
      <Heading>Article 3 (Use of Statistical Data)</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          We may use the personal information you provide to create statistical
          data that does not identify you personally. The Company may use any
          statistical data that does not identify you personally without any
          restrictions.
        </li>
      </ol>
      <Heading>Article 4 (Policy on Acquisition of User Information)</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          {`The Company shall obtain user information, including personal information, by lawful and fair means, and shall not obtain it by illegal methods against the user's will.`}
        </li>
      </ol>
      <Heading>
        Article 5 (Retention and Management of User Information)
      </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          {`The Company shall take necessary and appropriate measures for the safe management of user information and shall strive to strengthen security to a reasonable extent to prevent third parties from coming into undue contact with user information, including users' personal information.`}
        </li>
      </ol>
      <Heading>
        Article 6 (Provision, Publication and Display of User Informatio
      </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          Please note that we may provide, publish or post user information to
          third parties in the following cases
          <ol className={styles.body}>
            <li className={styles.list_item_latin}>
              When we are ordered by law, court of law, government and other
              public institutions.
            </li>
            <li className={styles.list_item_latin}>
              When the Company deems it necessary to protect the life, body or
              property of the Company, the customer or a third party.
            </li>
            <li className={styles.list_item_latin}>
              When the User commits or is likely to commit an act that violates
              the Terms of Use of the Service and necessary measures are taken
              against the act in question.
            </li>
            <li className={styles.list_item_latin}>
              When the Company deems it necessary to improve public health or
              promote the sound growth of children.
            </li>
            <li className={styles.list_item_latin}>
              {`In the event that the business, including the User's personal information, is transferred due to a merger, corporate separation, business transfer, or other reasons.`}
            </li>
            <li className={styles.list_item_latin}>
              As specified in these guidelines
            </li>
            <li className={styles.list_item_latin}>
              {`When the customer's consent is obtained in advance`}
            </li>
          </ol>
        </li>
      </ol>
      <Heading>
        Article 7 (Consignment of Personal Information Handling)
      </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          We may outsource the handling of user information to an external party
          in order to accomplish the purpose of use described above. In such
          cases, we will select a contractor who is recognized to have taken the
          necessary and appropriate measures to ensure the safe management of
          user information, sign a confidentiality agreement, and supervise the
          contractor as necessary and appropriate.
        </li>
      </ol>
      <Heading>
        Article 8 (Request for Disclosure, Correction, Deletion, etc. of
        Personal Information)
      </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          {`In accordance with the Personal Information Protection Law and other laws and regulations, the User may request the Company to notify the purpose of use of his/her personal information, disclose, correct, add or delete the content, stop using, delete, and stop providing the personal information to third parties (hereinafter referred to as "disclosure, etc.") to the contact listed in Article 12. In such cases, the Company shall respond to the request within a reasonable period of time. However, the Company may not be able to respond to your request if the identity of the user making the request could not be verified, if the request does not meet the requirements of the Personal Information Protection Law, or if the same request is repeated without justifiable reason. If you wish to request disclosure, correction or deletion of your personal information stored by the Company, please contact the contact point listed in Article 12. In principle, the Company does not disclose any information other than personal information such as access logs.`}
        </li>
      </ol>
      <Heading>Article 9 (Opt-out)</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          {`Some pages on the Company's website use cookies, a technology that identifies the user's computer from the website. In addition, smartphone applications that do not use cookies use an advertisement identifier provided by the smartphone OS as a unique ID to identify the user.`}
        </li>
        <li className={styles.list_item}>
          {`Users can opt-out of receiving targeted advertisements based on cookies or ad identifiers through their browser or smartphone device settings. If you wish to learn more about opt-out, please refer to the following company's privacy policy.`}
          <ol className={styles.body}>
            <li className={styles.list_item_latin}>
              Disabling Cookies for Access Analysis (Opt-out)
              <ol className={styles.body}>
                <li className={styles.list_item_roman}>Google Analytics</li>
              </ol>
            </li>
            <li className={styles.list_item_latin}>
              Disabling targeting for ad serving (opt-out)
              <ol className={styles.body}>
                <li className={styles.list_item_roman}>Freak Out Inc.</li>
                <li className={styles.list_item_roman}>LINE Corporation</li>
                <li className={styles.list_item_roman}>Twitter, Inc.</li>
                <li className={styles.list_item_roman}>
                  Digital Advertising Consortium, Inc.
                </li>
                <li className={styles.list_item_roman}>
                  Supership Corporation
                </li>
                <li className={styles.list_item_roman}>Five Co.</li>
              </ol>
            </li>
          </ol>
          If you choose to opt-out, you will still be able to use the Service,
          but you may be subject to certain functional limitations. The
          untargeted ads will continue to be displayed and user data will
          continue to be collected for ad reporting and conversion measurement
          purposes. The opt-out feature must be configured for each browser on
          which ads are displayed, and if you disable this setting, for example
          by deleting cookies or changing your PC or smartphone, the
          behaviorally targeted ads may begin to be served again.
        </li>
      </ol>
      <Heading>
        Article 10 (Publication and Modification of Privacy Policy)
      </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          We reserve the right to change our privacy policy at any time as
          necessary to protect your personal information. Any significant
          changes will be posted prominently on the site.
        </li>
      </ol>
      <Heading>Article 11 (Compliance with Laws and Regulations)</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          We will comply with the relevant laws and regulations regarding
          personal information.
        </li>
      </ol>
      <Heading>Article 12 (Inquiries about Member Information)</Heading>
      <p>
        If you have any questions about membership information, please contact
        us at the following 2-5-12, Kanda-Surugadai, Chiyoda-ku, Tokyo 101-0062,
        Japan Studyplus Inc. Personal Information Inquiry Desk You can contact
        us by email at info@studyplus.jp
      </p>
      <Heading>Article 13 (Other Treatment of Personal Information)</Heading>
      <p>Studyplus Inc. Director and Chief Privacy Officer Hanae Nakajima</p>
      <p>You can contact us by email at info@studyplus.jp</p>
      <p>
        However, please be aware that we may not be able to provide you with
        appropriate services if you do not register your personal information
        correctly.
      </p>
      <Heading>Article 14 (Data Retention)</Heading>
      <p>
        We will retain your Personal Information for the period necessary to
        fulfill the purposes outlined in this Privacy Policy unless a longer
        retention period is required or permitted by law.
      </p>
      <hr />
      <p>Enacted on March 13, 2012</p>
      <p>Revised on March 28, 2012</p>
      <p>Revised on July 9, 2012.</p>
      <p>Revised April 5, 2013.</p>
      <p>Revised July 5, 2013.</p>
      <p>Revised November 26, 2013.</p>
      <p>Revised April 1, 2016.</p>
      <p>Revised February 14, 2017.</p>
      <p>Revised June 8, 2017.</p>
      <p>Revised December 1, 2017.</p>
      <p>Revised on December 11, 2019</p>
      <p>Revised on April 1, 2020</p>
    </React.Fragment>
  );
};

const Heading = (props: React.PropsWithChildren<Record<string, unknown>>) => {
  return <p className={styles.heading}>{props.children}</p>;
};

export default EnglishPrivacyPolicy;
