import * as React from "react";
import { SectionView } from "../../../components/general/SectionView";
import styles from "./LectureSessionSummary.scss";
import { useFetchLectureSession } from "./useFetchLectureSession";
import Loader from "../../../components/atoms/Loader";
import { PageHeader } from "../../../components/atoms/MainSection";
import { Text } from "../../../components/general/Text";
import TimeHelper, {
  toDateString,
  toTimeString,
} from "../../../helpers/TimeHelper";
import { Flex } from "../../../components/atoms/Flex";
import { Schedule } from "../../../domains/Schedule";
import { LectureSessionDetail } from "../../../domains/LectureSession";
import { StudySchedule } from "../../../domains/StudySchedule";
import { formatRecurrence } from "../../../domains/Recurrence";
import { LectureDetail } from "./LectureDetail";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { SpecialSizeText } from "./SpecialSizeText";
import subDays from "date-fns/subDays";

type Props = {
  sectionId: string;
  lectureSessionId: string;
};

export const LectureSessionSummary = ({
  sectionId,
  lectureSessionId,
}: Props) => {
  const {
    isLoading,
    data: lectureSession,
    error,
  } = useFetchLectureSession({
    sectionId,
    lectureSessionId,
  });
  useQueryError(error);
  if (isLoading || lectureSession == null) {
    return (
      <SectionView>
        <div className={styles.summaryWrapper}>
          <Loader loading />
        </div>
      </SectionView>
    );
  }

  const { lecture, schedule, classroom, teacher } = lectureSession;

  return (
    <SectionView>
      <div className={styles.summaryWrapper}>
        <PageHeader>{schedule.summary}</PageHeader>

        <div className={styles.summaryText}>
          <ScheduleTime schedule={schedule} />
          <Classroom classroom={classroom} />
          <Teacher teacher={teacher} />
          <Flex marginTop="0" alignItems="baseline" wrap>
            <LearningMaterial
              learningMaterial={schedule.studySchedule?.learningMaterial}
            />
            {schedule.studySchedule?.learningMaterial ? <Separator /> : null}
            <Flex marginTop="0" gap="1" alignItems="baseline">
              <StudyDuration studySchedule={schedule.studySchedule} />
              <StudyAmount studySchedule={schedule.studySchedule} />
            </Flex>
          </Flex>
        </div>

        <div className={styles.summaryBoxes}>
          <Flex marginTop="0" gap="1.6" wrap>
            <SummaryBox label="対象者">
              <SummaryBoxNumber number={lectureSession.totalCount} />
            </SummaryBox>
            <SummaryBox label="出席数">
              <SummaryBoxNumber number={lectureSession.attendanceCount} />
            </SummaryBox>
            <SummaryBox label="欠席数">
              <SummaryBoxNumber number={lectureSession.absenceCount} />
            </SummaryBox>
            {lectureSession.fromOtherSectionCount > 0 ? (
              <SummaryBox label="併校受講数">
                <SummaryBoxNumber
                  number={lectureSession.fromOtherSectionCount}
                />
              </SummaryBox>
            ) : null}
            {lectureSession.transferCount > 0 ? (
              <SummaryBox label="振替受講数">
                <span>予定：{lectureSession.transferCount}人</span>
                <span>出席：{lectureSession.transferAttendanceCount}人</span>
                <span>欠席：{lectureSession.transferAbsenceCount}人</span>
              </SummaryBox>
            ) : null}
          </Flex>
        </div>
      </div>

      <div className={styles.lectureDetailWrapper}>
        <LectureDetail sectionId={sectionId} lecture={lecture} />
      </div>
    </SectionView>
  );
};

type ScheduleTimeProps = { schedule: Schedule };
const ScheduleTime = ({ schedule }: ScheduleTimeProps) => {
  const { startAt, endAt: originalEndAt, allday, recurrence } = schedule;

  const endAt = allday ? subDays(originalEndAt, 1) : originalEndAt;

  return (
    <SpecialSizeText>
      <time className={styles.ScheduleTime}>
        <span>{toDateString(startAt, "/")}</span>
        {allday ? null : <span>{toTimeString(startAt)}</span>}〜
        <span>{toDateString(endAt, "/")}</span>
        {allday ? null : <span>{toTimeString(endAt)}</span>}
      </time>
      {recurrence ? <span>{formatRecurrence(recurrence)}</span> : null}
    </SpecialSizeText>
  );
};

type ClassroomProps = {
  classroom: LectureSessionDetail["classroom"];
};
const Classroom = ({ classroom }: ClassroomProps) => {
  if (classroom == null) return null;

  return <SpecialSizeText>教室：{classroom.name}</SpecialSizeText>;
};

type TeacherProps = { teacher: LectureSessionDetail["teacher"] };
const Teacher = ({ teacher }: TeacherProps) => {
  if (teacher == null) return null;

  return <SpecialSizeText>講師：{teacher.fullName}</SpecialSizeText>;
};

type LearningMaterialProps = {
  learningMaterial: StudySchedule["learningMaterial"] | undefined;
};
const LearningMaterial = ({ learningMaterial }: LearningMaterialProps) => {
  if (learningMaterial == null) return null;

  return (
    <SpecialSizeText>
      <Flex marginTop="0" alignItems="baseline">
        <div>学習教材：</div>
        <div className={styles.LearningMaterial_name}>
          {learningMaterial.name}
        </div>
      </Flex>
    </SpecialSizeText>
  );
};

const Separator = () => <span className={styles.Separator}>／</span>;

type StudyDurationProps = {
  studySchedule: LectureSessionDetail["schedule"]["studySchedule"];
};
const StudyDuration = ({ studySchedule }: StudyDurationProps) => {
  if (studySchedule == null || studySchedule.numberOfSeconds == 0) return null;

  return (
    <SpecialSizeText>
      {TimeHelper.secondsToDisplayTime(studySchedule.numberOfSeconds)}
    </SpecialSizeText>
  );
};

type StudyAmountProps = {
  studySchedule: LectureSessionDetail["schedule"]["studySchedule"];
};
const StudyAmount = ({ studySchedule }: StudyAmountProps) => {
  if (studySchedule == null || studySchedule.amount == 0) return null;

  return (
    <SpecialSizeText>
      {studySchedule.amount}
      {studySchedule.learningMaterial.unit}
    </SpecialSizeText>
  );
};

type SummaryBoxProps = {
  label: string;
};
const SummaryBox = ({
  label,
  children,
}: React.PropsWithChildren<SummaryBoxProps>) => (
  <div className={styles.SummaryBox}>
    <div>
      <Text size="sm">{label}</Text>
    </div>
    <div className={styles.SummaryBox__text}>{children}</div>
  </div>
);

const SummaryBoxNumber = ({ number }: { number: number }) => (
  <div>
    <span className={styles.SummaryBox__number}>{number}</span>
    <span className={styles.SummaryBox__unit}>人</span>
  </div>
);
