import { LearningMaterial } from "./LearningMaterial";

export type StudySchedule = {
  learningMaterial: LearningMaterial;
  numberOfSeconds: number;
  amount: number;
};

export type RequestBody = {
  learning_material_code: string;
  number_of_seconds: number | null;
  amount: number | null;
};
export const toJson = (studySchedule: StudySchedule): RequestBody => ({
  learning_material_code: studySchedule.learningMaterial.code,
  number_of_seconds: studySchedule.numberOfSeconds,
  amount: studySchedule.amount,
});

export const MAX_VALUES = {
  hours: 9999,
  minutes: 59,
  amount: 9999,
  comment: 1000,
};
