import * as React from "react";
import { Helmet } from "react-helmet";
import Settings from "../../../helpers/Settings";

interface PageInfoInterface {
  title: string;
}

interface Props extends React.PropsWithChildren<Record<string, unknown>> {
  pageInfo: PageInfoInterface;
}

const SectionsTemplate = (props: Props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {props.pageInfo.title} | {Settings.SITE_TITLE}
        </title>
      </Helmet>
      {props.children}
    </React.Fragment>
  );
};

export default SectionsTemplate;
