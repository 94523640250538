import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../components/atoms/Flex";
import styles from "./StudentsStayItem.scss";
import {
  useStudentStayForm,
  UseStudentStayFormProps,
} from "./useStudentStayForm";
import ErrorText from "../../components/atoms/ErrorText";
import { SingleDatePickerField } from "../../components/atoms/SingleDatePickerField";
import { TimeInput } from "../../components/atoms/TimeInput";

type Props = UseStudentStayFormProps & { closeModal?: () => void };

export const StudentStayForm = (props: Props) => {
  const {
    enteredDateProps,
    enteredAtProps,
    enteredAtErrorProps,
    exitedAtProps,
    exitedAtErrorProps,
    buttonProps,
    formProps,
    exitedAtErrorText,
    enteredAtErrorText,
  } = useStudentStayForm(props);

  return (
    <Flex justifyContent="center" marginTop="0">
      <form {...formProps} className={styles.formContainer}>
        <ErrorText {...enteredAtErrorProps}>{enteredAtErrorText}</ErrorText>
        <ErrorText {...exitedAtErrorProps}>{exitedAtErrorText}</ErrorText>
        <div className={styles.formRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="enteredDate">日付</label>
            <SingleDatePickerField
              {...enteredDateProps}
              className={styles.inputDate}
            />
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="enteredAt">入室時間</label>
            <div className={styles.inputTimeWrapper}>
              <TimeInput
                {...enteredAtProps}
                className={styles.inputTime}
                id="enteredAt"
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="exitedAt">退室時間</label>
            <TimeInput
              {...exitedAtProps}
              className={styles.inputTime}
              id="exitedAt"
            />
          </div>
        </div>
        <div className={styles.formRowButton}>
          <Button type="submit" className={styles.btn} {...buttonProps}>
            入退室記録を更新
          </Button>
        </div>
      </form>
    </Flex>
  );
};
