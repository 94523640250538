import * as React from "react";
import {
  OptionType,
  SelectWrapper,
  SelectWrapperProps,
} from "../../general/SelectWrapper";
import { OnChangeValue } from "react-select";
import { useFetchExaminations } from "../../../hooks/http/useFetchExaminations";
import { Examination } from "../../../domains/Examination";

type OverrideSelectProps = {
  size?: SelectWrapperProps["size"];
  fontSize?: SelectWrapperProps["fontSize"];
  isDisabled?: boolean;
};
type Props = {
  onChange: (value: Examination | undefined) => void;
  classificationId: string | null;
  organizerId: string | null;
  hasError?: boolean;
  value?: string;
} & OverrideSelectProps;

export const ExaminationSelect = (props: Props) => {
  const { options, isDisabled, isLoadingOptions, onChange, selectedOption } =
    useExaminationSelect({
      onChange: props.onChange,
      organizerId: props.organizerId,
      classificationId: props.classificationId,
      value: props.value,
    });

  return (
    <SelectWrapper
      aria-label="試験名を選択"
      value={selectedOption}
      options={options}
      size={props.size}
      fontSize={props.fontSize}
      onChange={onChange}
      placeholder="試験名を選択"
      isLoading={isLoadingOptions}
      isDisabled={props.isDisabled || isDisabled}
      isClearable
      hasError={props.hasError}
    />
  );
};

const useExaminationSelect = (
  props: Pick<Props, "onChange" | "organizerId" | "classificationId" | "value">,
) => {
  const isReadyToSelect =
    Boolean(props.classificationId) && Boolean(props.organizerId);
  const { data: examinations, isFetching } = useFetchExaminations({
    organizerId: props.organizerId,
    classificationId: props.classificationId,
    enabled: isReadyToSelect,
  });

  const options = examinations ? toOptions(examinations) : [];

  const onChange = (option?: OnChangeValue<OptionType, false>) => {
    const value = option ? (option.value as string) : null;
    const targetExamination = examinations?.find((e) => e.id === value);
    props.onChange(targetExamination);
  };

  // organizerIdかclassificaitonIdが変わったときは、選択状態をリセットする
  const prevOrganizerId = React.useRef<string | null>(props.organizerId);
  const prevClassificationId = React.useRef<string | null>(
    props.classificationId,
  );

  React.useEffect(() => {
    if (
      prevOrganizerId.current !== null &&
      prevClassificationId.current !== null
    ) {
      props.onChange(undefined);
      prevOrganizerId.current = props.organizerId;
      prevClassificationId.current = props.classificationId;
    }
  }, [props.classificationId, props.organizerId]);

  return {
    isDisabled: !isReadyToSelect,
    isLoadingOptions: isFetching && examinations === undefined,
    options,
    onChange,
    selectedOption:
      options.find((option) => option.value === props.value) || null,
  };
};

const toOptions = (examinations: readonly Examination[]) => {
  return examinations.map((examination: Examination) => {
    const label = examination.targetJob?.human
      ? `${examination.name} ${examination.targetJob.human}`
      : examination.name;

    return {
      label,
      value: examination.id,
    };
  });
};
