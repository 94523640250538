export const maxClassNameSize = 32;
export const minLatValue = -90;
export const maxLatValue = 90;
export const minLonValue = -180;
export const maxLonValue = 180;
export const minCorrectionRange = 0;
export const maxCorrectionRange = 1000;

export type Classroom = {
  id: string;
  name: string;
  latitude: number | null;
  longitude: number | null;
  correctionRange: number | null;
};

export type ClassroomList = ReadonlyArray<Classroom>;
