import * as React from "react";
import {
  Schedule,
  defaultScheduleTitle,
  ScheduleForStudent,
} from "../../../../domains/Schedule";
import { isCanceledLecture } from "../../../../domains/LectureAttendance";
import {
  EditButton,
  TrashButton,
  CloseButton,
  ModalDetailHeader,
} from "../../../../components/general/ModalDetailHeaderButtons";
import {
  ScheduleTitle,
  Title,
  Locale,
  Note,
  StudySchedule,
  ScheduleTime,
  Stack,
  ScheduleDetailModalBox,
  Classroom,
  Teacher,
  TitleSectionName,
  AdjustIconPositionWrapper,
} from "../../../../components/features/Schedule/DetailModalParts";
import styles from "./ScheduleDetail.scss";
import Icon from "../../../../components/atoms/Icon";
import {
  AbsenceReasonOptions,
  LectureAttendance,
  reasonOptionLabels,
} from "../../../../domains/LectureAttendance";
import { AttandanceStatus } from "../../../../domains/LectureAttendance";
import { Text } from "../../../../components/general/Text";
import { Flex } from "../../../../components/atoms/Flex";
import { LectureTypeLabel } from "../../../../components/features/Schedule/LectureTypeLabel";
import { ColorDot } from "../../../../components/general/ColorDot";
import { determineScheduleColor } from "../utilities";
import { CanceledLectureType } from "../../../../components/features/Schedule/LectureTypeLabel";
import { LectureSession } from "../../../../domains/LectureSession";
import { Link } from "react-router-dom";
import SectionInterface from "../../../../interfaces/SectionInterface";
import {
  attendanceLabels,
  transferAttendanceLabels,
  attendanceIcon,
} from "../../../../helpers/AttendanceHelper";

type Props = {
  schedule: ScheduleForStudent;
  onClose: () => void;
  onClickEdit: (schedule: Schedule) => void;
  onClickDelete: (schedule: ScheduleForStudent) => void;
};

export const prepareScheduleDetailProps = (schedule: ScheduleForStudent) => {
  return {
    isStudentFromOtherSection:
      schedule.lectureAttendance?.fromOtherSection ?? false,
    sectionNameLectureHold: schedule.section?.sectionName,
    isScheduleOwnedBySection: schedule.isOwnedBySection,
    scheduleColor: determineScheduleColor(schedule),
    isCanceledLecture: schedule.lectureAttendance
      ? isCanceledLecture(schedule.lectureAttendance)
      : false,
  };
};

export const ScheduleDetail = (props: Props): React.ReactElement => {
  const { schedule } = props;
  const {
    scheduleColor,
    isCanceledLecture,
    isScheduleOwnedBySection,
    isStudentFromOtherSection,
    sectionNameLectureHold,
  } = prepareScheduleDetailProps(schedule);
  return (
    <ScheduleDetailModalBox>
      <ModalDetailHeader>
        {!isScheduleOwnedBySection && (
          <>
            <EditButton
              onClick={() => props.onClickEdit(schedule as Schedule)}
            />
            <TrashButton
              onClick={() => {
                props.onClickDelete(schedule);
              }}
            />
          </>
        )}
        <CloseButton onClick={props.onClose} />
      </ModalDetailHeader>
      <Stack>
        <ScheduleTitle>
          <ColorDot backgroundColor={scheduleColor} />
          <Title>
            <Flex marginTop="0" alignItems="center" gap="0.8">
              {isCanceledLecture && <CanceledLectureType />}
              <Text size="xl" bold>
                {schedule.summary || defaultScheduleTitle}
              </Text>
            </Flex>
          </Title>
          {isStudentFromOtherSection && (
            <TitleSectionName>{sectionNameLectureHold}</TitleSectionName>
          )}
        </ScheduleTitle>
        <ScheduleTime
          allday={schedule.allday}
          startAt={schedule.startAt}
          endAt={schedule.endAt}
          recurrence={schedule.recurrence}
        />
        <StudySchedule studySchedule={schedule.studySchedule} />
        {/* (schedule any) は暫定 */}
        {isScheduleOwnedBySection && (
          <>
            <Classroom>{schedule.classroom?.name}</Classroom>
            <Teacher>{schedule.teacher?.fullName}</Teacher>
            <Lecture>
              <LectureContent
                lectureSession={schedule.lectureSession}
                lectureAttendance={schedule.lectureAttendance ?? null}
                section={schedule.section}
              />
            </Lecture>
          </>
        )}

        <Locale>{schedule.locale}</Locale>
        <Note>{schedule.description}</Note>
      </Stack>
    </ScheduleDetailModalBox>
  );
};

export const Lecture = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <div className={styles.Lecture}>
      <AdjustIconPositionWrapper>
        <Icon name="icon-textbooks" color="gray-darken-1" />
      </AdjustIconPositionWrapper>
      {children}
    </div>
  );
};

const prepareLectureContentProps = (
  lectureAttendance: LectureAttendance,
  lectureSession: LectureSession,
) => {
  const lectureType: LectureTypes = determineLectureType(lectureAttendance);

  return {
    attendanceType: lectureAttendance.attendanceStatus,
    transferredAttendanceType:
      lectureAttendance.transferredLectureAttendanceStatus,
    isAbsent: lectureAttendance.attendanceStatus === "absence",
    absenceReason: lectureAttendance.absenceReason,
    absenceDescription: lectureAttendance.absenceDescription,
    lectureName: lectureSession.lecture?.name,
    lectureType,
  };
};
type LectureContentProps = {
  lectureAttendance: LectureAttendance | null;
  lectureSession: LectureSession | null;
  section: SectionInterface | null;
};
export const LectureContent = ({
  lectureAttendance,
  lectureSession,
  section,
}: LectureContentProps) => {
  if (!lectureAttendance || !lectureSession || !section) {
    return null;
  }

  const {
    attendanceType,
    transferredAttendanceType,
    isAbsent,
    absenceReason,
    absenceDescription,
    lectureType,
    lectureName,
  } = prepareLectureContentProps(lectureAttendance, lectureSession);
  return (
    <>
      <Flex marginTop="0" alignItems="center" gap="0.8">
        <LectureTypeLabel lectureType={lectureType} />
        <Text bold>講座：{lectureName}</Text>
      </Flex>
      <div className={styles.Lecture__attendance}>
        <div>
          <StudentAttendance
            attendanceType={attendanceType}
            transferredAttendanceType={transferredAttendanceType}
          />
          {isAbsent &&
            absenceReason !== null &&
            absenceDescription !== null && (
              <AbsenceReason
                reason={absenceReason}
                description={absenceDescription}
              />
            )}
        </div>
        {lectureType !== "fromOtherSection" && (
          <div>
            <Link
              to={`/sections/${section.id}/lecture_sessions/${lectureSession.id}/attendees`}
              className={`buiButtonBase buiButtonVariantOutline`}
            >
              講座出席詳細
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

const determineLectureType = (
  lectureAttendance: LectureAttendance,
): LectureTypes => {
  if (lectureAttendance.hasTransferOriginLectureAttendance) {
    return "transferred";
  }

  if (lectureAttendance.fromOtherSection) {
    return "fromOtherSection";
  }
  return "normal";
};

// 暫定ステータス・props、Swaggerの定義が上がってき次第もう一度整理する
type LectureTypes = "transferred" | "fromOtherSection" | "normal";
type TransferredAttendanceStatus = AttandanceStatus | null;

type StudentAttendanceProps = {
  attendanceType: AttandanceStatus;
  transferredAttendanceType: TransferredAttendanceStatus;
};

export const StudentAttendance = ({
  attendanceType,
  transferredAttendanceType,
}: StudentAttendanceProps) => {
  const transferredAttendanceText = transferredAttendanceType
    ? `：${transferAttendanceLabels[transferredAttendanceType]}`
    : "";
  return (
    <Flex alignItems="center" marginTop="0">
      <Icon
        name={attendanceIcon[attendanceType].iconName}
        className={styles.Lecture__attendanceIcon}
        color={attendanceIcon[attendanceType].iconColor}
      />
      <div className={styles.Lecture__attendanceStatus} aria-label="出欠席状態">
        <Text as="span">
          {attendanceLabels[attendanceType]}
          {transferredAttendanceText}
        </Text>
      </div>
    </Flex>
  );
};

type AbsenceReasonProps = {
  reason: AbsenceReasonOptions;
  description: string;
};
export const AbsenceReason = ({ reason, description }: AbsenceReasonProps) => (
  <div
    className={styles.Lecture__absenseReason}
  >{`${reasonOptionLabels[reason]}：${description}`}</div>
);
