import MessageThreadInterface from "../../../../../interfaces/MessageThreadInterface";
import CommonMessageForm from "../../../../../components/molecules/CommonMessageForm";
import OperatorProfileInterface from "../../../../../interfaces/OperatorProfileInterface";
import OperatorInterface from "../../../../../interfaces/OperatorInterface";
import { generateStorageKey } from "../../../../../components/atoms/ResumableTextarea/useResumableTextarea";
import { useDirectMessageForm } from "./useDirectMessageForm";

export interface Props {
  sectionId: string;
  messageThread: MessageThreadInterface;
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface;
}

const DirectMessageForm = (props: Props) => {
  const {
    formState,
    handlePostMessage,
    handlePostMessageFile,
    handleChangeContent,
    handleChangeFile,
    handlePostZoomMeetingMessage,
  } = useDirectMessageForm({
    sectionId: props.sectionId,
    messageThread: props.messageThread,
  });
  return (
    <CommonMessageForm
      formState={formState}
      canSendMessage={props.messageThread.student.canSendMessage}
      postMessage={handlePostMessage}
      postMessageFile={handlePostMessageFile}
      postZoomMeetingMessage={handlePostZoomMeetingMessage}
      changeFormContent={handleChangeContent}
      changeFormFile={handleChangeFile}
      allowedFileTypes={["jpg", "png", "gif", "pdf"]}
      disabledPlaceholder={
        "連携していない生徒のため、メッセージが送信できません"
      }
      rows={1}
      operator={props.operator}
      operatorProfile={props.operatorProfile}
      destination={{
        path: `/students/${props.messageThread.student.id}`,
        name: props.messageThread.student.fullName,
      }}
      defaultZoomMeetingContent={`${props.messageThread.student.fullName}さんとZoomミーティングを行います。`}
      storageKey={generateStorageKey([
        "messages",
        props.messageThread.student.id,
        "new",
      ])}
    />
  );
};

export default DirectMessageForm;
