import styles from "./styles.scss";
import Icon, { IconFileType } from "../../atoms/Icon";

interface Props {
  className?: string;
  iconProps: {
    name: string;
    iconClassName?: string;
    fileType?: IconFileType;
  };
  onClick?: (e: any) => void;
  dataTestId?: string;
}

const attachParentClassName = (
  baseClassName: string,
  parentClassName?: string,
): string => {
  if (parentClassName) {
    return `${baseClassName} ${parentClassName}`;
  } else {
    return baseClassName;
  }
};

const CircleIcon = (props: Props) => {
  return (
    <div className={attachParentClassName(styles.root, props.className)}>
      <Icon
        {...props.iconProps}
        className={props.iconProps.iconClassName || ""}
        onClick={props.onClick}
        dataTestId={props.dataTestId}
      />
    </div>
  );
};

export default CircleIcon;
