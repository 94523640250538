import { useInfiniteQuery } from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../errors";
import { boronClient } from "../../api";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { Period } from "./index";
import { paths } from "../../lib/api/v1";

type Response =
  paths["/api/v1/sections/{section_id}/analytics/study_tasks"]["get"]["responses"]["200"]["content"]["application/json"];
export const useFetchStudyTasks = (
  sectionId: string,
  period: Period,
  enabled: boolean,
) =>
  useInfiniteQuery<Response["studyTasks"], HTTPErrors>({
    queryKey: [
      "/api/v1/sections/{section_id}/analytics/study_tasks",
      sectionId,
      period,
    ],
    queryFn: async ({ pageParam }) => {
      const { data, response } = await boronClient.GET(
        "/api/v1/sections/{section_id}/analytics/study_tasks",
        {
          params: {
            path: {
              section_id: sectionId,
            },
            query: {
              page: pageParam as number,
              period,
            },
          },
        },
      );
      if (data) {
        return data.studyTasks;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    enabled,
    getNextPageParam,
  });
