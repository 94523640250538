// react-copy-to-clipboard というライブラリを移植したコンポーネント
//  cf. https://github.com/nkbt/react-copy-to-clipboard
// react v18に更新するタイミングでリプレース (@types/react-copy-to-clipboard がreact v18に対応されていない状況のため)
import * as React from "react";
import copy from "copy-to-clipboard";

type Props = {
  text: string;
  children: JSX.Element;
  onCopy?: (text: string, result: boolean) => void;
  options?: {
    debug: boolean;
    message: string;
    format: string;
  };
};

export const CopyToClipboard = (props: Props) => {
  const { text, children, onCopy, options } = props;

  const child = React.Children.only(children);
  const onClick = (event: Event) => {
    const result = copy(text, options);

    if (onCopy) {
      onCopy(text, result);
    }

    if (child && child.props && typeof child.props.onClick === "function") {
      child.props.onClick(event);
    }
  };

  return React.cloneElement(child, { ...child.props, onClick });
};
