import {
  IconDoorOpen,
  IconImportStudent,
  IconKarte,
  IconTextbook,
} from "@studyplus/boron-ui";
import { MultiSectionBatchType } from "../../../interfaces/MultiSectionBatchesOperation";

type MultiSectionBatchInfo = {
  batchTypeHuman: string;
  dataTypeLabel: string;
  fileNamePrefix: string;
  Icon: React.FC<{ className: string }>;
};

export const getMultiSectionBatchInfo = (
  batchType: MultiSectionBatchType,
): MultiSectionBatchInfo => {
  switch (batchType) {
    case "download_study_records":
      return {
        batchTypeHuman: "学習記録のダウンロード",
        dataTypeLabel: "学習記録データ",
        fileNamePrefix: "学習記録",
        Icon: IconTextbook,
      };
    case "download_stays":
      return {
        batchTypeHuman: "入退室記録のダウンロード",
        dataTypeLabel: "入退室記録データ",
        fileNamePrefix: "入退室記録",
        Icon: IconDoorOpen,
      };
    case "download_kartes":
      return {
        batchTypeHuman: "面談記録のダウンロード",
        dataTypeLabel: "面談記録データ",
        fileNamePrefix: "面談記録",
        Icon: IconKarte,
      };
    case "download_students":
      return {
        batchTypeHuman: "生徒一覧のダウンロード",
        dataTypeLabel: "生徒一覧データ",
        fileNamePrefix: "生徒一覧",
        Icon: IconImportStudent,
      };
    default:
      throw "無効なバッチタイプです";
  }
};
