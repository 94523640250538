import ApiClient from "../api";
import { GetRequestOptions } from "../api";
import { StudentParamsInterface } from "../interfaces/StudentInterface";

export interface StudentSearchParams {
  first_name?: string;
  last_name?: string;
}

const StudentApi = {
  getStudents: (
    sectionId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(`/api/v1/sections/${sectionId}/students`, options);
  },

  postNewStudent: (
    sectionId: string,
    params: StudentParamsInterface,
  ): Promise<Response> => {
    return ApiClient.post(`/api/v1/sections/${sectionId}/students`, {
      student: params,
    });
  },
};

export default StudentApi;
