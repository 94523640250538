import { useMemo, useCallback } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import api from "../../api";
import StudentInterface from "../../interfaces/StudentInterface";
import { toQueryString } from "../../helpers/TimeHelper";
import { AnalyticsTermType } from "../../interfaces/AnalyticsTableInterface";
import ApiResponse from "../../interfaces/ApiResponse";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import {
  HTTPErrors,
  createError,
  UnprocessableEntityError,
  UnprocessableEntityErrorItem,
} from "../../errors";
import {
  StudentStayAnalytics,
  StudentStayAnalyticsSummary,
} from "../../domains/StudentAnalytics";
import {
  fromJson,
  fromJsonSummary,
} from "../../domain-adapters/StudentStayAnalytics";
import {
  StayFormInterface,
  StayInterface,
} from "../../interfaces/StayInterface";

export type UseFetchStudentStayAnalyticsProps = {
  student: StudentInterface;
  startDate?: Date;
  term?: AnalyticsTermType;
  enabledRequest: boolean;
};

export const useFetchStudentStayAnalyticsSummary = ({
  student,
  startDate,
  term,
  enabledRequest,
}: UseFetchStudentStayAnalyticsProps) => {
  const baseUrl = baseUrlOfSummary(student);

  return useQuery<StudentStayAnalyticsSummary, HTTPErrors>({
    queryKey: [baseUrl, startDate?.toISOString(), term],
    queryFn: async () => {
      const res = await api.interruptGet(baseUrl, {
        query: {
          initial_date: startDate && toQueryString(startDate),
          term,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return fromJsonSummary(json.stayAnalyticsSummary);
      }
      throw await createError(res);
    },
    // canvas が再描画してしまうのを防ぐ
    refetchOnWindowFocus: false,
    gcTime: 0,
    enabled: enabledRequest,
  });
};

const baseUrlOfSummary = (student: StudentInterface) =>
  `/api/v1/students/${student.id}/analytics/stay/summary`;

type StudentStayAnalyticsResponse = ApiResponse<StudentStayAnalytics>;
export const useFetchStudentStayAnalytics = ({
  student,
  startDate,
  term,
  enabledRequest,
}: UseFetchStudentStayAnalyticsProps) => {
  const baseUrl = baseUrlOfStay(student);
  const result = useInfiniteQuery<StudentStayAnalyticsResponse, HTTPErrors>({
    queryKey: [baseUrl, startDate?.toISOString(), term],
    queryFn: async ({ pageParam }) => {
      const res = await api.interruptGet(`${baseUrl}`, {
        query: {
          initial_date: startDate && toQueryString(startDate),
          term,
          page: pageParam,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return {
          meta: json.stays.meta,
          data: json.stays.data.map((stay: Record<string, any>) =>
            fromJson(stay),
          ),
        };
      }
      throw await createError(res);
    },
    initialPageParam: 1,
    getNextPageParam,
    enabled: enabledRequest,
  });

  const data = useMemo(() => {
    return result.data ? result.data.pages.flatMap((page) => page.data) : null;
  }, [result.data]);

  return { ...result, data };
};

const baseUrlOfStay = (student: StudentInterface) =>
  `/api/v1/students/${student.id}/analytics/stay`;

export const useRefreshAnalyticsApi = (student: StudentInterface) => {
  const client = useQueryClient();
  const execute = useCallback(() => {
    client.resetQueries({ queryKey: [baseUrlOfSummary(student)] });
    client.resetQueries({ queryKey: [baseUrlOfStay(student)] });
  }, []);
  return { execute };
};

type useMutateStudentStayProps = {
  sectionId: string;
  stayId: string;
  student: StudentInterface;
  onSuccess: () => void;
  onError: () => void;
};

const requestUpdate = (
  sectionId: string,
  stayId: string,
  params: StayFormInterface,
) =>
  api.patch(`/api/v1/sections/${sectionId}/stays/${stayId}`, {
    stay: params,
  });

export const useUpdateStudentStay = ({
  sectionId,
  stayId,
  student,
  onSuccess,
  onError,
}: useMutateStudentStayProps) => {
  const client = useQueryClient();

  return useMutation<StayInterface, HTTPErrors, StayFormInterface, unknown>({
    mutationFn: async (params) => {
      const res = await requestUpdate(sectionId, stayId, params);

      if (res.ok) {
        const json = (await res.json()) as { stay: StayInterface };
        return json.stay;
      }

      if (res.status === 422) {
        const json = await res.json();
        throw new UnprocessableEntityError(
          json.errors as UnprocessableEntityErrorItem[],
        );
      }

      throw await createError(res);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [baseUrlOfStay(student)] });
      client.invalidateQueries({ queryKey: [baseUrlOfSummary(student)] });
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
};

export type useDeleteStudentStayProps = {
  sectionId: string;
  stayId: string;
  student: StudentInterface;
};

const requestDelete = (sectionId: string, stayId: string) =>
  api.delete(`/api/v1/sections/${sectionId}/stays/${stayId}`);

export const useDeleteStudentStay = ({
  sectionId,
  stayId,
  student,
}: useDeleteStudentStayProps) => {
  const client = useQueryClient();

  return useMutation<void, HTTPErrors, unknown, unknown>({
    mutationFn: async () => {
      const res = await requestDelete(sectionId, stayId);

      if (res.ok) {
        return;
      }

      throw await createError(res);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [baseUrlOfStay(student)] });
      client.invalidateQueries({ queryKey: [baseUrlOfSummary(student)] });
    },
  });
};
