import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../atoms/Icon";
import Dropdown from "../../atoms/Dropdown/index";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";

type Props = React.HTMLAttributes<HTMLUListElement> & {
  position?: { top?: string; right?: string; bottom?: string; left?: string };
};

/**
 * @deprecated
 * ドロップダウン表示のコンポーネント。
 * 新規に実装する場合はgeneral/DropdownMenuの方をご利用ください
 */
export const EllipsisDropdown = (props: React.PropsWithChildren<Props>) => {
  const { position, ...listAttributes } = props;
  return (
    <Dropdown.Container>
      <Dropdown.Label>
        <button type="button">
          <Icon name="icon-ellipsis" className={styles.icon} />
        </button>
      </Dropdown.Label>
      <Dropdown.List position={position || { top: "0", left: "-8rem" }}>
        <ul className={styles.list} {...listAttributes}>
          {props.children}
        </ul>
      </Dropdown.List>
    </Dropdown.Container>
  );
};

type ColorType = "default" | "danger";
interface LinkProps {
  href?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  colorType?: ColorType;
}

export const EllipsisDropdownLink = (
  props: React.PropsWithChildren<LinkProps>,
) => {
  return (
    <li>
      {props.href ? (
        <RouterLink
          to={props.href}
          className={determineClassName(props.colorType)}
        >
          {props.children}
        </RouterLink>
      ) : (
        <span
          onClick={props.onClick}
          className={determineClassName(props.colorType)}
        >
          {props.children}
        </span>
      )}
    </li>
  );
};

const determineClassName = (colorType?: ColorType): string => {
  if (colorType == "danger") {
    return classNames(styles.dropdownItem, styles["dropdownItem--danger"]);
  }
  return styles.dropdownItem;
};
