import * as React from "react";
import styles from "./styles.scss";

const StudyPlusTerm = () => {
  return (
    <div className={styles.root}>
      <Terms />
    </div>
  );
};

const Terms = () => {
  return (
    <React.Fragment>
      <p>
        Studyplus for
        Schoolの利用者（以下、「甲」という。）は、スタディプラス株式会社(以下「乙」という。)が提供する学習管理プラットフォーム「Studyplus
        for
        School」に関するサービス(有償、無償を問わず、トライアル期間中のものを含み、以下「本サービス」という。)を利用するにあたり、Studyplus
        for School 利用規約(以下「本規約」という。)に同意する。
      </p>
      <Heading>第 1 条（目的等）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          本サービスは、甲に在籍する生徒であり本サービスを利用する者（以下「本件生徒」という。）の学力向上、及び甲の業務効率化を目的とする。
        </li>
      </ol>
      <Heading>第 2 条（基本原則）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲及び乙は、信義誠実の原則に従って本規約を履行する。
        </li>
        <li className={styles.list_item}>
          本規約は、本サービスの利用に関し、甲乙間の権利義務関係を定めるものであって、甲乙間における本サービスの提供に関わる一切の関係に適用される。
        </li>
        <li className={styles.list_item}>
          甲は、本サービスは、乙が提供する学習管理アプリ「Studyplus」（以下「本アプリ」という。）を本件生徒が利用することが前提となることを了解する。
        </li>
        <li className={styles.list_item}>
          本アプリの本件生徒への提供に際しては、乙が定める Studyplus
          利用規約（以下「本件利用規約」という。）に本件生徒が同意することにより、乙が本件生徒と直接の契約関係に立つものとする。
        </li>
      </ol>
      <Heading>第 3 条（利用登録）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          {" "}
          乙は、甲乙間の協議によって合意した細目に従って本サービスのシステムを構築する。{" "}
        </li>
        <li className={styles.list_item}>
          乙は甲の請求に従って、本サービスを利用するためのアカウント(以下「管理アカウント」という。)を甲に対して発行し、甲は乙から発行された管理アカウントからパスコードを、本件生徒に対して発行し、付与することとする。当該本件生徒は事前に乙が定める内容に従って本サービスの登録手続きを行い、本件利用規約に同意することで本サービスを利用することが出来ることとする。
        </li>
        <li className={styles.list_item}>
          乙は、本サービスを通して蓄積された学習データ及び本サービスの利用状況（以下「利用状況等」という。）を確認するための管理アカウントを甲に対して別途発行し、甲は当該管理アカウントを用いて利用状況等を分析、統計した情報を、本件生徒への学習指導に用いることが出来ることとする。
        </li>
      </ol>
      <Heading>第 4 条（利用停止等）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          乙は、本件生徒の本件利用規約違反を認めた場合、当該本件生徒が本サービス上に投稿した内容の全部又は一部を削除することが出来るとともに、甲に対して当該違反の事実を通知し、当該本件生徒に対する以後の対応を甲と協議して決定するものとする。
        </li>
        <li className={styles.list_item}>
          甲は、本件生徒が本件利用規約違反その他、本サービスを利用するのに不適切な行為を行ったと判断した場合、当該本件生徒の不適切な行為を乙に通知し、乙は甲の請求に従って、本件利用規約上根拠があると認められる場合には、当該本件生徒が本サービス上に投稿した内容の全部又は一部を削除し、又は当該本件生徒の本アプリのアカウント（以下「本アカウント」という。）の使用を停止することとする。
        </li>
        <li className={styles.list_item}>
          前項の場合において、本アカウント自体は削除又は停止されず、本件生徒は、本サービス以外の本アプリの利用を引き続き継続することができることを甲は了解するものとする。
        </li>
      </ol>
      <Heading>第 5 条（個人情報の取扱い） </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          乙は、本件生徒の登録された個人情報を、個人情報保護法等の法令及び第11条（機密情報の保持）に従って管理するものとする。
        </li>
      </ol>
      <Heading>第 6 条（知的財産権）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          本サービスを構成する一切のコンテンツ（プログラム・システム等を含む）に関する著作権、特許権、ノウハウその他の一切の知的財産権（特許を受ける権利その他の知的財産権を受ける権利を含み、著作権法
          27 条及び 28
          条の権利を含む。）その他の権利は、乙に帰属するものとする。
        </li>
        <li className={styles.list_item}>
          前項の定めにかかわらず、乙が本サービスの利用にあたり、自ら入力した情報に関する知的財産権その他の権利は、乙又は第三者に帰属するものとするが、乙は、かかる情報については、甲に対して本サービスの提供をするためその他甲の事業の目的上必要な範囲内において、その利用を許諾し、このために第三者から許諾を得る必要がある場合には、当該許諾を得るものとする。
        </li>
      </ol>
      <Heading>第 7 条（アカウントの管理）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲及び本件生徒は、自己の責任により各自のアカウントを管理するものとし、これを第三者に利用させ又は第三者に貸与、譲渡、名義変更、売買してはならないものとする。
        </li>
        <li className={styles.list_item}>
          甲又は本件生徒が本サービスを第三者に使用させたことによって乙に損害が発生した場合には、当該損害に対する帰責事由を負う甲又は本件生徒が各自で当該損害を賠償する責任を負うものとする。
        </li>
      </ol>
      <Heading>第 8 条（運用開始日） </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          本サービスの運用開始日は、乙が本規約に同意した日とする。{" "}
        </li>
      </ol>
      <Heading>第 9 条（契約期間）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          本規約の有効期間は、運用開始日から1年とする。但し、期間満了の１ヶ月前までに、甲又は乙から相手方に対して書面による特段の意思表示がなされなかった場合には、本契約は同一内容及び同一条件で一年間の期間をもって自動更新されるものとし、その後も同様とする。
        </li>
        <li className={styles.list_item}>
          本規約 4 条 3 項、6 条、11 条、13 条、16 条、17 条、19
          条の定めは、本規約の終了後も効力が存続するものとする。
        </li>
      </ol>
      <Heading>第 10 条（料金及び支払方法） </Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲は本サービスを有償で利用する場合は、乙に対し本サービス利用に対する利用料を支払う。当該利用料の算定方法及び支払期限、並びに支払方法は別途定める。
        </li>
      </ol>
      <Heading>第 11 条（機密情報の保持）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          機密情報とは、甲または乙から相手方に開示される「個人情報（個人情報保護法第２条１項による）ならびに営業に関する固有の情報（不正競争防止法第２条６項による）」であって、書面上機密情報である旨を明記したもの及び口頭で開示したもののうち、開示に際して、秘密情報であるとの明示があったものを指す。文書、口頭、電子情報、音声情報など、情報の形態を問わないものとする。但し、次の各号に該当する情報は機密情報に含まないものとする。
          <ol className={styles.body}>
            <li className={styles.list_item}>
              {" "}
              既に公知であるか、本規約に抵触することなく公知となったもの{" "}
            </li>
            <li className={styles.list_item}>
              {" "}
              情報を受領する当事者（以下、「受領者」という）が開示を受ける前より知り得ていたもの{" "}
            </li>
            <li className={styles.list_item}>
              {" "}
              守秘義務を負うことなく第三者から知り得たもの{" "}
            </li>
            <li className={styles.list_item}>
              {" "}
              情報を提供する当事者（以下、「開示者」という）が書面により公表を承認したもの{" "}
            </li>
            <li className={styles.list_item}>
              {" "}
              機密情報によらず独自に保有するに至ったもの{" "}
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          受領者は、開示者から提供された機密情報を本業務の遂行に必要な限度を超えて使用（開示、提供、預託を含む）せず、本業務のために必要な限度を超えて加工、利用、複写、複製しないことを保証するものとする。
        </li>
        <li className={styles.list_item}>
          受領者は、別段の定めがない限り、当該業務に関して当該機密情報を知る必要があり、かつ機密情報の守秘義務を負う役員、従業員にのみ開示し、それ以外の者には機密情報を開示しないものとする。但し、法令の定めに基づいた司法もしくは行政機関の適正な開示命令に従って当該司法機関または行政機関に開示する場合を除く。この場合においては、法令又は命令等により禁止されている場合を除き、開示後直ちに開示者に報告するものとする。
        </li>
        <li className={styles.list_item}>
          前項の定めにかかわらず、受領者は、公務員、弁護士、会計士、税理士等法律上守秘義務を負う者に対して機密情報を開示することができるものとする。
        </li>
        <li className={styles.list_item}>
          受領者は、機密情報を取り扱う業務が終了した時、または開示者の要請があった時のいずれか早い時をもって、機密情報およびそれらの複製物一切（二次的情報を含む）を、一切の複製物を残すことなく、開示者の指示に従って速やかに開示者に返却または破棄するものとする。その際、開示者に返却することが困難なものについては、開示者の承諾を得てかかる情報を廃棄することにより、返却に代えることができる。
        </li>
        <li className={styles.list_item}>
          受領者が個人情報の対象となっている本人等から、個人情報の開示、訂正、削除、利用停止等の要求を受けたときには、直ちに開示者に通知の上、開示者の指示に従うものとする。
        </li>
        <li className={styles.list_item}>
          {" "}
          本規約終了後においても、開示された機密情報の取扱について引き続きその効力を有するものとする。{" "}
        </li>
      </ol>
      <Heading>第 12 条（再委託）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          乙は、本件業務の全部または一部を第三者に再委託することができるものとする。ただし、乙は、甲に対して、再委託先の名称を通知し、かつ、再委託先に乙と同様の義務を負わせ、再委託先の行為については、乙が責任を負うものとする。
        </li>
      </ol>
      <Heading>第 13 条（契約の解除）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲又は乙は、相手方が本規約の各条項の一に違反したと認められる場合、当該違反の是正を促す旨の催告又は通知を行った後、相当と認められる期間が経過したにもかかわらず、その是正が完全になされなかったときには本規約を解除することができる。
        </li>
        <li className={styles.list_item}>
          甲及び乙は、相手方が次の各号のいずれかに該当する場合、相手方への事前の通知または催告を要することなく本規約の全部若しくは一部を解約することができるものとする。
          <ol className={styles.body}>
            <li className={styles.list_item}>
              支払停止、支払不能又は手形・小切手が不渡りとなった場合
            </li>
            <li className={styles.list_item}>
              差押、仮差押若しくは競売の申立があったとき又は公租公課の滞納処分を受けた場合
            </li>
            <li className={styles.list_item}>
              破産、会社整理開始、会社更生手続開始若しくは民事再生手続開始の申立があったとき又は信用状態に重大な不安が生じた場合
            </li>
            <li className={styles.list_item}>
              監督官庁から営業許可の取消、停止等の処分を受けた場合
            </li>
            <li className={styles.list_item}>
              解散、減資、営業の全部又は重要な一部の譲渡等の決議をした場合
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          本条の規定は、相手方に対する損害賠償請求権の行使を妨げない。
        </li>
        <li className={styles.list_item}>
          乙は、甲が本規約のいずれかの条項に違反したと認められ、乙からの当該違反の是正を促す旨の催告又は通知を行った後、相当と認められる期間が経過したにもかかわらず、その是正が完全になされなかった場合又は第
          2
          項各号のいずれかの事由に該当した場合には、甲へ事前の通知若しくは催告を要することなく、本サービスの全部又は一部の提供を停止することができるものとする。
        </li>
        <li className={styles.list_item}>
          本規約が解除により終了した場合、甲又は乙の相手方に対する金銭債務が残存するときは、当然に期限の利益を喪失し、直ちに当該残存金銭債務を一括で相手方に支払うものとする。
        </li>
      </ol>
      <Heading>第 14 条（自己責任の原則、非保証・責任の制限）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲は、本サービスの利用に関して、第三者（本件生徒を含む。以下同じ。）との間で紛争が発生した場合、自らの責任および負担において解決するものとし、乙に対して、損害を与えないことを保証するものとする。乙は、甲が本サービスを利用することにより甲と第三者との間で生じた紛争等について一切責任を負わないものとする。
        </li>
        <li className={styles.list_item}>
          乙が、甲の故意または重過失のある行為に基づき損害を受けた場合、または乙が前項に規定する甲と第三者との間の紛争（甲と本件生徒との紛争を含む。）に対応した場合、乙が負担した通常の損害について、甲は、乙の請求に応じ、その損害を賠償するものとする。
        </li>
        <li className={styles.list_item}>
          乙は、甲に対し、本サービス又は本サービスが利用できること、内容の正確性、完全性、有用性、適法性、安全性、甲の利用目的への適合性、瑕疵や障害がないこと、第三者の権利を侵害しないこと等を保証するものではなく、本サービス又は本サービスを自己の責任で利用するものとする。乙は、事前の予告なく本サービス用設備の故障、運用上又は技術上の理由、天災地変等不可抗力、その他の理由で本サービス又は本サービスの提供を中断・停止する場合があり、甲はこのことをあらかじめ承諾するものとする。
        </li>
        <li className={styles.list_item}>
          乙は以下の場合には、免責されるものとする。ただし、乙に故意又は重過失がある場合にはこの限りではないものとする。
          <ol className={styles.body}>
            <li className={styles.list_item}>
              本サービス用設備の故障、運用上又は技術上の理由、天災地変等不可抗力、その他の理由により本サービスを提供できない（一時的であるか否かを問わない。）ことに関する損害
            </li>
            <li className={styles.list_item}>
              通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害、その他乙のサービスに関して甲に生じた損害
            </li>
            <li className={styles.list_item}>
              乙のウェブページ・サーバ・ドメインなどから送られるメール・コンテンツに、第三者により仕組まれたコンピューター・ウィルスなどの有害なものが含まれていたことによる損害
            </li>
            <li className={styles.list_item}>
              甲が本利用規約に違反したことによって生じた損害
            </li>
            <li className={styles.list_item}>
              ログインに利用している SNS
              のサービス内容の変更、サービス提供の中止等による損害
            </li>
            <li className={styles.list_item}>
              本サービスへのアクセス・利用により生じるエラー、バグ等の欠損、消失、変更、損失による損害
            </li>
            <li className={styles.list_item}>
              第三者による中傷、攻撃等の行為による損害
            </li>
            <li className={styles.list_item}>
              法令または裁判所の命令によって個人情報の開示を命じられ、または捜査当局の任意捜査もしくは法律上照会権限、開示請求権を持つ者によって照会・開示を求められるなど他の優先する利益のために乙が個人情報を開示したことによる損害
            </li>
            <li className={styles.list_item}>
              本件生徒と甲の間のコミュニケーション等により生じた損害
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          本件生徒相互間のコミュニケーション等により損害が生じた場合は、当該本件生徒相互間で解決されるものとし、甲及び乙は責任を負わないものとする。ただし、甲及び乙は、必要に応じてその対応について協議するものとする。
        </li>
        <li className={styles.list_item}>
          甲は、乙のサーバ等に保存されている情報を含めて、本サービスの利用に関連して提供された一切の情報（連携サービスにより提供された連携元を通じて提供される情報を含む。）について、自己の責任と費用でデータのバックアップを行うものとし、乙はデータの滅失、毀損について一切の責任を負わないものとする。
        </li>
        <li className={styles.list_item}>
          乙が、甲が本サービスを利用するにあたり、甲が管理する個人情報を処理することがあるが、かかる情報について、保有個人データに関する責任を負うのは、甲とする。
        </li>
        <li className={styles.list_item}>
          本サービス又は本規約等に関して、甲又は乙の責めに帰すべき事由により契約書に定めた内容が守られず、乙又は甲が損害賠償責任を負う場合においては、その範囲は、債務不履行責任、不法行為責任、その他法律上の請求原因の如何を問わず、現実に発生した通常の損害に限定され、損害賠償の額は本サービスの
          1
          ヶ月分利用料金相当額を超えないものとする。なお、甲及び乙の責に帰すことができない事由から生じた損害については、甲乙協議するものとする。
        </li>
      </ol>
      <Heading>第 15 条（契約内容の変更）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          乙が本規約の変更を希望する場合、相手方に対して、乙が別途定める方法により、その内容を通知し、甲の同意を得るものとする。
        </li>
      </ol>
      <Heading>第 16 条（中途解約）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲又は乙が本規約を解約しようとする場合、当該解約希望日の１ヶ月前までに、相手方に対して書面によって通知し、協議の後、双方が合意に至ったときには、解約合意書を締結して、本規約を解約することができる。
        </li>
      </ol>
      <Heading>第 17 条（不可抗力）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲又は乙の責に帰すことができない天災、戦争、暴動、その他の不可抗力により、本規約の全部又は一部の履行の不能又は遅滞が生じた場合には、甲又は乙はその責任を負わないこととする。
        </li>
      </ol>
      <Heading>第 18 条（紛議）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲及び乙は、本規約の履行を通して第三者との間において紛議が発生した場合、各自で速やかにその解決を図ることとし、相手方の協力を必要とするときには相手方に速やかに報告し、甲乙双方が協議した上で速やかにその解決を図るものとする。
        </li>
      </ol>
      <Heading>第 19 条（表明保証）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲及び乙は、現在及び将来に亘り、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、又は総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団、その他これらに準ずる者（以下総称して、「反社会的勢力」という。）のいずれにも該当しないことを表明し保証する。
        </li>
        <li className={styles.list_item}>
          甲及び乙は、現在及び将来に亘り、前項に規定する反社会的勢力又は反社会的勢力と密接な関係を有する者（以下「反社会的勢力等」という。)と以下の各号の一に該当する関係を有するものではないことを表明し保証する。
          <ol className={styles.body}>
            <li className={styles.list_item}>
              反社会的勢力等がその経営に実質的に関与する関係
            </li>
            <li className={styles.list_item}>
              反社会的勢力等に対し資金等を提供し、又は便宜を供与する関係
            </li>
            <li className={styles.list_item}>
              前３号の反社会的勢力等と社会的かつ道義的に非難される関係
            </li>
          </ol>
        </li>
        <li className={styles.list_item}>
          甲及び乙は、自己又は第三者を利用して、自己又は第三者の利益のために、以下の各号の一を行わないことを表明し保証する。
          <ol className={styles.body}>
            <li className={styles.list_item}>暴力的な要求行為</li>
            <li className={styles.list_item}>
              実定法上の責任を超過する不当な要求行為
            </li>
            <li className={styles.list_item}>
              本規約に約定する権利の行使又は義務の履行において脅迫的言動をなし、又は暴力を用いる行為
            </li>
            <li className={styles.list_item}>
              風説を流布し、偽計又は威力を用いて相手方の信用を毀損し業務を妨害する行為
            </li>
            <li className={styles.list_item}>前４号に準ずる行為</li>
          </ol>
        </li>
        <li className={styles.list_item}>
          甲及び乙は、前３項に相手方が該当する事実が明白である場合、本規約を無催告で直ちに解除し、損害賠償を相手方に対し請求することができるものとする。
        </li>
        <li className={styles.list_item}>
          本条の違反により、甲又は乙に損害が生じた場合には、相手方はその損害を賠償する。
        </li>
      </ol>
      <Heading>第 20 条（準拠法及び管轄裁判所）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とする。
        </li>
      </ol>
      <Heading>第 21 条（適用）</Heading>
      <ol className={styles.body}>
        <li className={styles.list_item}>
          甲は本サービスを有償で利用する場合は、甲乙間で別途Studyplus for
          School利用契約書（以下、「本契約」という。）を締結することとし、本規約と本契約との間に矛盾・抵触する規定がある場合は、本契約の規定が優先しするものとする。
        </li>
      </ol>
    </React.Fragment>
  );
};

const Heading = (props: React.PropsWithChildren<any>) => {
  return <p className={styles.heading}>{props.children}</p>;
};

export default StudyPlusTerm;
