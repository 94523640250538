import * as React from "react";
import styles from "./styles.scss";

type Props = {
  children: React.ReactNode;
  marginLeft: string;
};

export const InlineBlock = ({ children, marginLeft }: Props) => {
  return (
    <div className={styles.root} style={{ marginLeft }}>
      {children}
    </div>
  );
};
