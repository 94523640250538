import { FlashMessageType } from "../interfaces/FlashMessageInterface";
import FlashMessageInterface from "../interfaces/FlashMessageInterface";
import { ApiErrorInterface } from "../interfaces/ApiErrorResponseInterface";
import { HTTP_ERROR_MESSAGE } from "./index";

const flashMessage = (
  state: FlashMessageInterface = {
    visible: false,
    type: FlashMessageType.NONE,
    messages: [],
  },
  action: ReduxActions.Action<any>,
): FlashMessageInterface => {
  switch (action.type) {
    case "SWITCH_OPERATOR_SUCCESS":
    case "SWITCH_OPERATOR_WITH_REDIRECT":
      return {
        visible: true,
        type: FlashMessageType.SUCCESS,
        messages: ["アカウントを切り替えました"],
      };
    case "SET_FLASH_MESSAGE":
      return {
        visible: true,
        type: action.payload.type,
        messages: [action.payload.message],
      };
    case "RESET_FLASH_MESSAGE":
      return {
        visible: false,
        type: FlashMessageType.NONE,
        messages: [],
      };
    case "API_REQUEST_ERROR":
      if (action.payload && action.payload.errors) {
        return {
          visible: true,
          type: FlashMessageType.ERROR,
          messages: action.payload.errors.map(
            (error: ApiErrorInterface) => error.message,
          ),
        };
      } else {
        return {
          visible: true,
          type: FlashMessageType.ERROR,
          messages: [HTTP_ERROR_MESSAGE],
        };
      }
    default:
      return state;
  }
};

export default flashMessage;
