import styles from "./Questionnaires.scss";
import { DependantTable } from "../../../components/atoms/Table";
import SectionInterface from "../../../interfaces/SectionInterface";
import Loader from "../../../components/atoms/Loader";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import { LectureAttendanceWithLectureAnswers } from "../../../domains/LectureAttendance";
import TimeHelper from "../../../helpers/TimeHelper";
import {
  TableColumn,
  TableHeadColumn,
} from "../../../components/atoms/Table/index";
import { LectureAnswer } from "../../../domains/LectureAnswer";
import { Text } from "../../../components/general/Text/Text";
import { useFetchLectureAnswers } from "./useFetchLectureAnswers";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { Flex } from "../../../components/atoms/Flex";

type Props = {
  section: SectionInterface;
  lectureSessionId: string;
};
export const Questionnaires = (props: Props) => {
  const { attendees, isLoading, isFetchingNextPage } = useLectureAnswers(props);

  return (
    <>
      {isLoading && <Loader loading />}
      {attendees && (
        <section className={styles.root}>
          {attendees && (
            <DependantTable aria-label="アンケート回答一覧">
              <TableHeader />
              <TableBody attendees={attendees} />
            </DependantTable>
          )}
        </section>
      )}
      {isFetchingNextPage && <Loader loading />}
    </>
  );
};

const TableBody = ({
  attendees,
}: {
  attendees: LectureAttendanceWithLectureAnswers[];
}) => (
  <tbody>
    {attendees.flatMap((attendee: LectureAttendanceWithLectureAnswers) =>
      attendee.lectureAnswers.map(
        (answer: LectureAnswer, indexOfAnswer: number) => (
          <TableRow
            key={answer.id}
            attendee={attendee}
            answer={answer}
            sequence={indexOfAnswer + 1}
          />
        ),
      ),
    )}
  </tbody>
);

type TableRowProps = {
  attendee: LectureAttendanceWithLectureAnswers;
  answer: LectureAnswer;
  sequence: number;
};
const TableRow = ({ attendee, answer, sequence }: TableRowProps) => (
  <tr className={styles.row}>
    {sequence === 1 && (
      <>
        <TableColumn centering rowSpan={attendee.lectureAnswers.length}>
          {attendee.student.fullName}
        </TableColumn>
        <TableColumn centering rowSpan={attendee.lectureAnswers.length}>
          {attendee.student.jobHuman}
        </TableColumn>
        <TableColumn centering rowSpan={attendee.lectureAnswers.length}>
          {TimeHelper.fullFormat(attendee.lectureAnswers[0].updatedAt)}
        </TableColumn>
      </>
    )}
    <TableColumn>
      <Flex gap="0.8" marginTop="0">
        <Text as="span" bold whiteSpace="nowrap">
          Q{sequence}.
        </Text>
        <span>{answer.lectureQuestionnaire.question}</span>
      </Flex>
    </TableColumn>
    <TableColumn>
      <Flex gap="0.8" marginTop="0">
        <Text as="span" bold whiteSpace="nowrap">
          A{sequence}.
        </Text>
        <span>
          {Array.isArray(answer.answer)
            ? answer.answer.join("、")
            : answer.answer}
        </span>
      </Flex>
    </TableColumn>
  </tr>
);

const TableHeader = () => (
  <thead>
    <tr>
      <TableHeadColumn centering className={styles.nameColumn}>
        氏名
      </TableHeadColumn>
      <TableHeadColumn centering className={styles.jobColumn}>
        職業（学年）
      </TableHeadColumn>
      <TableHeadColumn centering className={styles.datetimeColumn}>
        回答日時
      </TableHeadColumn>
      <TableHeadColumn centering className={styles.questionColumn}>
        質問
      </TableHeadColumn>
      <TableHeadColumn centering className={styles.answerColumn}>
        回答
      </TableHeadColumn>
    </tr>
  </thead>
);

const useLectureAnswers = (props: Props) => {
  const {
    data,
    isLoading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchLectureAnswers(props);
  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  return {
    isLoading,
    isFetchingNextPage,
    attendees: data,
  };
};
