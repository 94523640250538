import { Link } from "react-router-dom";
import Icon from "../../../components/atoms/Icon";
import Table, { TableColumn } from "../../../components/atoms/Table";
import { ContentCourseResult } from "../../../domains/ContentCourseResult";
import TimeHelper from "../../../helpers/TimeHelper";
import SectionInterface from "../../../interfaces/SectionInterface";
import styles from "./styles.scss";

type Props = {
  section: SectionInterface;
  courseResults: ContentCourseResult[];
};
export const CourseResultTable = ({ section, courseResults }: Props) => {
  return (
    <Table
      headers={[
        {
          text: "コース名",
          centering: true,
        },
        {
          text: "",
          centering: true,
        },
        {
          text: "配信期間",
          centering: true,
        },
        {
          text: "対象人数",
          centering: true,
        },
      ]}
      noRowsText="表示可能な学習結果はありません"
      rows={courseResults.map((courseResult) => (
        <tr key={`row-${courseResult.id}`}>
          <TableColumn bold>
            <Link
              to={`/sections/${section.id}/settings/content_courses/${courseResult.id}/results`}
              className={styles.courseResult__name}
            >
              {courseResult.name}
            </Link>
          </TableColumn>
          <TableColumn centering>
            <BtnPreview sectionId={section.id} courseId={courseResult.id} />
          </TableColumn>
          <TableColumn centering>{deliveryPeriod(courseResult)}</TableColumn>
          <TableColumn centering>{courseResult.studentCount}人</TableColumn>
        </tr>
      ))}
    ></Table>
  );
};

type BtnPreviewProps = {
  sectionId: string;
  courseId: string;
};
const BtnPreview = ({ sectionId, courseId }: BtnPreviewProps) => (
  <div className={styles.BtnPreview}>
    <Link
      className={styles.BtnPreview__Link}
      to={`/sections/${sectionId}/settings/content_courses/${courseId}`}
    >
      <Icon name="icon-content-list" />
    </Link>
  </div>
);

const deliveryPeriod = (result: ContentCourseResult): string => {
  if (result.startAt && result.endAt) {
    return `${TimeHelper.fullFormat(result.startAt)} 〜 ${TimeHelper.fullFormat(
      result.endAt,
    )}`;
  }
  return "";
};
