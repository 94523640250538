import { Dispatch } from "redux";
import { createAction } from "redux-actions";

import StudentKarteApi, {
  PostKarteCommentParamsInterface,
} from "../api-clients/StudentKarteApi";
import ApiErrorResponseInterface from "../interfaces/ApiErrorResponseInterface";
import { FlashMessageType } from "../interfaces/FlashMessageInterface";
import { buildMainResourceApiErrorAction } from "./common/errors";
import { KarteCommentInterface } from "../interfaces/KarteInterface";
import { HTTP_ERROR_MESSAGE } from "../reducers";
import { apiRequestError } from "./apiRequestError";
import {
  setFlashMessage,
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "./flashMessage";

// カルテ
const getKarteRequest = createAction("GET_STUDENT_KARTE_REQUEST");
const getKarteSuccess = createAction("GET_STUDENT_KARTE_SUCCESS");
const getKarteError = createAction("GET_STUDENT_KARTE_ERROR");
export const resetKarte = createAction("RESET_STUDENT_KARTE");
export const getKarte =
  (studentId: string, karteId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(getKarteRequest());
    StudentKarteApi.getKarte(studentId, karteId)
      .then((res) => {
        res
          .json()
          .then((json) => {
            if (res.ok) {
              dispatch(getKarteSuccess(json));
            } else {
              dispatch(buildMainResourceApiErrorAction(res.status));
              dispatch(getKarteError());
              dispatch(buildMainResourceApiErrorAction(res.status, json));
            }
          })
          .catch(() => {
            dispatch(buildMainResourceApiErrorAction(res.status));
          });
      })
      .catch(() => {
        dispatch(getKarteError());
        dispatch(buildMainResourceApiErrorAction());
      });
  };

const deleteKarteSuccess = createAction("DELETE_KARTE_SUCCESS");
const deleteKarteError = createAction("DELETE_KARTE_ERROR");
export const deleteKarte =
  (studentId: string, karteId: string) =>
  (dispatch: Dispatch): void => {
    StudentKarteApi.deleteKarte(studentId, karteId)
      .then((res) => {
        if (res.ok) {
          const payload = { karteId };
          dispatch(deleteKarteSuccess(payload));
          dispatch(
            setFlashMessage({
              type: FlashMessageType.SUCCESS,
              message: "カルテを削除しました",
            }),
          );
          window.location.href = `/students/${studentId}/kartes`;
        } else {
          dispatch(showErrorFlashMessage("カルテの削除に失敗しました"));
          dispatch(deleteKarteError());
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("カルテの削除に失敗しました"));
        dispatch(deleteKarteError());
      });
  };
// カルテコメント
const postKarteCommentSuccess = createAction("POST_KARTE_COMMENT_SUCCESS");
const postKarteCommentError = createAction("POST_KARTE_COMMENT_ERROR");
export const postKarteComment =
  (
    studentId: string,
    karteId: string,
    params: PostKarteCommentParamsInterface,
  ) =>
  (dispatch: Dispatch): void => {
    StudentKarteApi.postKarteComment(studentId, karteId, params)
      .then((res) => {
        if (res.ok) {
          res.json().then((json: { karteComment: KarteCommentInterface }) => {
            const payload = {
              karteId,
              karteComment: json.karteComment,
            };
            dispatch(postKarteCommentSuccess(payload));
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(postKarteCommentError());
            dispatch(apiRequestError(json));
          });
        } else {
          const payload = {
            errors: HTTP_ERROR_MESSAGE,
          };

          dispatch(postKarteCommentError());
          dispatch(apiRequestError(payload));
        }
      })
      .catch((err: ApiErrorResponseInterface) => {
        dispatch(postKarteCommentError());
        dispatch(apiRequestError(err));
      });
  };

const deleteKarteCommentSuccess = createAction("DELETE_KARTE_COMMENT_SUCCESS");
const deleteKarteCommentError = createAction("DELETE_KARTE_COMMENT_ERROR");
export const deleteKarteComment =
  (studentId: string, karteId: string, commentId: string) =>
  (dispatch: Dispatch): void => {
    StudentKarteApi.deleteComment(studentId, karteId, commentId)
      .then((res) => {
        if (res.ok) {
          const payload = {
            karteId,
            commentId,
          };
          dispatch(deleteKarteCommentSuccess(payload));
        } else {
          dispatch(showErrorFlashMessage("コメントの削除に失敗しました"));
          dispatch(deleteKarteCommentError());
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("コメントの削除に失敗しました"));
        dispatch(deleteKarteCommentError());
      });
  };

const postKartePinSuccess = createAction("POST_KARTE_PIN_SUCCESS");
const postKartePinError = createAction("POST_KARTE_PIN_ERROR");
export const postKartePin =
  (studentId: string, karteId: string) =>
  (dispatch: Dispatch): void => {
    StudentKarteApi.pinKarte(studentId, karteId)
      .then((res) => {
        if (res.ok) {
          const payload = { karteId };
          dispatch(postKartePinSuccess(payload));
        } else {
          dispatch(showErrorFlashMessage("トップに固定を失敗しました"));
          dispatch(postKartePinError());
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("トップに固定を失敗しました"));
        dispatch(postKartePinError());
      });
  };

const deleteKartePinSuccess = createAction("DELETE_KARTE_PIN_SUCCESS");
const deleteKartePinError = createAction("DELETE_KARTE_PIN_ERROR");
export const deleteKartePin =
  (studentId: string, karteId: string) =>
  (dispatch: Dispatch): void => {
    StudentKarteApi.deleteKartePin(studentId, karteId)
      .then((res) => {
        if (res.ok) {
          const payload = { karteId };
          dispatch(deleteKartePinSuccess(payload));
        } else {
          dispatch(showErrorFlashMessage("トップに固定を解除に失敗しました"));
          dispatch(deleteKartePinError());
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("トップに固定を解除に失敗しました"));
        dispatch(deleteKartePinError());
      });
  };

const openKarteShareModal = createAction("OPEN_KARTE_SHARE_MODAL");
const closeKarteShareModal = createAction("CLOSE_KARTE_SHARE_MODAL");
export const toggleModal =
  (karteId: string, isOpen: boolean) =>
  (dispatch: Dispatch): void => {
    if (isOpen) {
      dispatch(openKarteShareModal({ karteId }));
    } else {
      dispatch(closeKarteShareModal({ karteId }));
    }
  };

const postKarteShareSending = createAction("POST_KARTE_SHARE_SENDING");
const postKarteShareSuccess = createAction("POST_KARTE_SHARE_SUCCESS");
const postKarteShareError = createAction("POST_KARTE_SHARE_ERROR");

export const postKarteShare =
  (studentId: string, karteId: string, message: string) =>
  (dispatch: Dispatch): void => {
    dispatch(postKarteShareSending({ karteId }));
    StudentKarteApi.postStudentShare(studentId, karteId, message)
      .then((res) => {
        if (res.ok) {
          dispatch(showSuccessFlashMessage("カルテを送信しました"));
          dispatch(postKarteShareSuccess({ karteId }));
        } else {
          dispatch(showErrorFlashMessage("カルテの送信に失敗しました"));
          dispatch(postKarteShareError({ karteId }));
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("カルテの送信に失敗しました"));
        dispatch(postKarteShareError({ karteId }));
      });
  };
