import classNames from "classnames";
import * as React from "react";

import styles from "./styles.scss";

interface Props {
  isMute?: boolean;
  isInline?: boolean;
  required?: boolean;
  noMargin?: boolean;
  children: React.ReactNode;
}

const Label = (props: Props & React.LabelHTMLAttributes<HTMLLabelElement>) => {
  const { children, isInline, isMute, noMargin, ...attributes } = props;

  return (
    <label
      {...attributes}
      className={classNames(styles.root, {
        [styles.mute]: isMute,
        [styles.inline]: isInline,
        [styles.noMargin]: noMargin,
      })}
    >
      {children}
      {props.required && <span className={styles.required}> *</span>}
    </label>
  );
};

export default Label;
