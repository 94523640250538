import ApiClient, { GetRequestOptions } from "../api";
import compressImage from "../helpers/compressImage";

export interface PostMessageParams {
  content?: string;
  image?: File;
}

const StudentGuardianMessageApi = {
  interruptGetMessages: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.interruptGet(
      `/api/v1/students/${studentId}/guardian_messages`,
      options,
    );
  },

  getGuardianMessages: (
    studentId: string,
    options?: GetRequestOptions,
  ): Promise<Response> => {
    return ApiClient.get(
      `/api/v1/students/${studentId}/guardian_messages`,
      options,
    );
  },

  postGuardianMessage: (
    studentId: string,
    content?: string,
    file?: File,
  ): Promise<Response> => {
    if (file) {
      return postGuardianMessageFormData({ studentId, file, content });
    }
    return postGuardianMessageJson({ studentId, content: content ?? "" });
  },
};

const postGuardianMessageJson = ({
  studentId,
  content,
}: {
  studentId: string;
  content: string;
}): Promise<Response> => {
  return ApiClient.post(`/api/v1/students/${studentId}/guardian_messages`, {
    content,
  });
};

const postGuardianMessageFormData = async ({
  studentId,
  content,
  file,
}: {
  studentId: string;
  content?: string;
  file: File;
}): Promise<Response> => {
  const fileBody =
    file.type === "application/pdf"
      ? { file }
      : { image: await compressImage(file, { maxSizeMB: 0.5 }) };
  return ApiClient.sendFormData(
    `/api/v1/students/${studentId}/guardian_messages`,
    "POST",
    { ...fileBody, ...(content ? { content } : {}) },
  );
};

export default StudentGuardianMessageApi;
