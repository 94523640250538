import * as queryString from "query-string";
import { NavigateOptions, useLocation, useNavigate } from "react-router";

// クエリパラメータを更新する関数を返すカスタムフック
export const usePushHistory = <T>() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryParseOption = {
    arrayFormat: "bracket",
  } as queryString.ParseOptions;

  // NOTE: currentParams に存在するキーをリセットしたい場合は deleteKeys に指定する
  //       params に指定してもキーは追加されるだけで、既存のキーは削除されないため
  type PushHistoryOptions = NavigateOptions & { deleteKeys?: Array<string> };
  const pushHistory = (params: T, options?: PushHistoryOptions) => {
    const { deleteKeys, ...navigateOptions } = options || {};
    const query = buildQuery(params, deleteKeys);
    navigate(`${pathname}?${query}`, navigateOptions);
  };

  const buildQuery = (params: T, deleteKeys?: Array<string>) => {
    const currentParams = queryString.parse(location.search, queryParseOption);
    if (deleteKeys) {
      deleteKeys.forEach((key) => delete currentParams[key]);
    }
    const newParams = {
      ...currentParams,
      ...params,
    };

    return queryString.stringify(newParams, queryParseOption);
  };

  return {
    pushHistory,
    buildQuery,
  };
};
