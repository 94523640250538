import { SectionStaffBoardPostStateInterface } from "../interfaces/SectionStaffBoardPostInterface";
import { StaffBoardPostCommentInterface } from "../interfaces/StaffBoardPostInterface";

const defaultState: SectionStaffBoardPostStateInterface = {
  data: null,
  submitting: false,
  loading: false,
  apiErrors: [],
};

const sectionStaffBoardPostState = (
  state: SectionStaffBoardPostStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    // スタッフボードの取得
    case "sections/staff_board_post/GET_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "sections/staff_board_post/GET_REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload.staffBoardPost,
      };
    case "sections/staff_board_post/GET_REQUEST_FAILURE":
      return {
        ...state,
        loading: false,
      };

    // スタッフボードの更新
    case "UPDATE_STAFF_BOARD_POST_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "UPDATE_STAFF_BOARD_POST_SUCCESS":
      return {
        ...state,
        data: action.payload.staffBoardPost,
        submitting: false,
      };
    case "UPDATE_STAFF_BOARD_POST_FAILURE":
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors || [] : [],
      };

    // スタッフボードの削除
    case "sections/staff_board_post/DELETE_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "sections/staff_board_post/DELETE_REQUEST_SUCCESS":
      return defaultState;
    case "sections/staff_board_post/DELETE_REQUEST_FAILURE":
      return {
        ...state,
        submitting: false,
      };

    // スタッフボードコメントの投稿
    case "sections/staff_board_post/POST_COMMENT_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "sections/staff_board_post/POST_COMMENT_REQUEST_SUCCESS":
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        submitting: false,
        data: {
          ...state.data,
          comments: [
            ...state.data.comments,
            action.payload.staffBoardPostComment,
          ],
        },
      };
    case "sections/staff_board_post/POST_COMMENT_REQUEST_FAILURE":
      return {
        ...state,
        submitting: false,
      };

    // スタッフボードコメントの削除
    case "sections/staff_board_post/DELETE_COMMENT_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "sections/staff_board_post/DELETE_COMMENT_REQUEST_SUCCESS":
      if (!state.data) {
        return state;
      }
      return {
        ...state,
        submitting: false,
        data: {
          ...state.data,
          comments: state.data.comments.filter(
            (comment: StaffBoardPostCommentInterface) => {
              return comment.id != action.payload.commentId;
            },
          ),
        },
      };
    case "sections/staff_board_post/DELETE_COMMENT_REQUEST_FAILURE":
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
};
export default sectionStaffBoardPostState;
