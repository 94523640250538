import * as React from "react";
import { Table } from "../../components/features/AnalyticsTable";
import { ExaminationResult } from "../../domains/ExaminationResult";
import { ExaminationSubjectResult } from "../../domains/ExaminationSubjectResult";
import { ExaminationSubject } from "../../domains/ExaminationSubject";
import parseISO from "date-fns/parseISO";
import { formatDate } from "../../helpers/TimeHelper";

type ExaminationResultTableProps = {
  studentId: string;
  scoreDisplayType: "point" | "deviation";
  subjects: ExaminationSubject[];
  examinationResults: ExaminationResult[];
};

export const ExaminationResultTable = ({
  studentId,
  scoreDisplayType,
  subjects,
  examinationResults,
}: ExaminationResultTableProps) => {
  return (
    <Table>
      <THead>
        <HeaderTr>
          <ExaminationNameTh>試験名</ExaminationNameTh>
          <Th>総合</Th>
          {subjects?.map((subject) => <Th key={subject.id}>{subject.name}</Th>)}
          <MarginTh />
        </HeaderTr>
      </THead>
      <TBody>
        {examinationResults.map((examinationResult) => (
          <Tr key={examinationResult.id}>
            <ExaminationNameTd>
              <ExaminationName
                examinationResult={examinationResult}
                studentId={studentId}
              />
            </ExaminationNameTd>
            <Td>
              {scoreDisplayType === "point" ? (
                <Point
                  point={examinationResult.point}
                  allocationOfMarks={examinationResult.allocationOfMarks}
                />
              ) : (
                <Deviation deviation={examinationResult.deviation} />
              )}
            </Td>
            {subjects?.map((subject) => (
              <Td key={subject.id}>
                <SubjectPointOrDeviation
                  subjectResult={examinationResult.subjectResults.find(
                    (s) => s.subject.id === subject.id,
                  )}
                  scoreDisplayType={scoreDisplayType}
                />
              </Td>
            ))}
            <MarginTd />
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};

const THead = ({ children }: { children: React.ReactNode }) => (
  <thead>{children}</thead>
);

const TBody = ({ children }: { children: React.ReactNode }) => (
  <tbody className="divide-x-0 divide-y divide-solid divide-gray-400">
    {children}
  </tbody>
);

const HeaderTr = ({ children }: { children: React.ReactNode }) => (
  <tr className="h-4 border-0 border-y border-solid border-gray-400">
    {children}
  </tr>
);

const Tr = ({ children }: { children: React.ReactNode }) => <tr>{children}</tr>;

const ExaminationNameTh = ({ children }: { children: React.ReactNode }) => (
  <th className="z-2 left-0 h-4 w-1/3 min-w-[38rem] max-w-xl bg-white p-0 text-center text-sm text-gray-900 md:sticky">
    {children}
  </th>
);

const ExaminationNameTd = ({ children }: { children: React.ReactNode }) => (
  <td className="z-2 left-0 w-1/3 max-w-xl bg-white px-7 py-6 leading-none text-black md:sticky">
    {children}
  </td>
);

const Th = ({ children }: { children: React.ReactNode }) => (
  <th className="box-border h-4 w-[9.5rem] min-w-[9.5rem] p-0 text-center text-sm text-gray-900">
    {children}
  </th>
);

const Td = ({ children }: { children: React.ReactNode }) => (
  <td className="box-border w-[9.5rem] min-w-[9.5rem] px-7 py-6 text-center leading-none text-black">
    {children}
  </td>
);

const MarginTh = () => <th className="w-auto" />;

const MarginTd = () => <td className="w-auto" />;

const ExaminationName = (props: {
  examinationResult: ExaminationResult;
  studentId: string;
}) => {
  const { examinationResult, studentId } = props;
  const { examination } = examinationResult;
  const examinedOnDate = formatDate(parseISO(examinationResult.examinedOn));

  return (
    <div className="text-left leading-[1.6rem]">
      <div className="flex flex-wrap gap-x-4 gap-y-1 text-md">
        <span>{examinedOnDate}</span>
        <span>{examination.organizer.name}</span>
        <span>{examination.classification.name}</span>
        <span>{examination.targetJob.human}</span>
      </div>
      <div className="mt-6">
        <a
          href={`/students/${studentId}/analytics/examinations/${examination.id}/result`}
          className="text-lg text-blue-400 underline"
        >
          {examinationResult.examination.name}
        </a>
      </div>
    </div>
  );
};

const SubjectPointOrDeviation = (props: {
  subjectResult?: ExaminationSubjectResult;
  scoreDisplayType: "point" | "deviation";
}) => {
  const { subjectResult, scoreDisplayType } = props;
  return (
    <>
      {scoreDisplayType === "point" ? (
        <Point
          point={subjectResult?.point}
          allocationOfMarks={subjectResult?.allocationOfMarks}
        />
      ) : (
        <Deviation deviation={subjectResult?.deviation} />
      )}
    </>
  );
};

const Point = (props: {
  point?: number | null;
  allocationOfMarks?: number | null;
}) => {
  const { point, allocationOfMarks } = props;
  if (point === undefined || allocationOfMarks === undefined) return null;
  return (
    <div className="flex items-baseline justify-center gap-0.5">
      <div>{point}</div>
      <div className="text-sm text-gray-700">/</div>
      <div className="text-sm text-gray-700">{allocationOfMarks}</div>
    </div>
  );
};

const Deviation = (props: { deviation?: number | null }) => {
  const { deviation } = props;
  return <div>{deviation}</div>;
};
