import { Button } from "@studyplus/boron-ui";
import ErrorText from "../../../components/atoms/ErrorText";
import Input from "../../../components/atoms/Input";
import Label from "../../../components/atoms/Label";
import { Flex } from "../../../components/atoms/Flex/index";
import { BlockRow } from "../../../components/atoms/BlockRow/index";
import {
  ContentVideoFormProps,
  useContentVideoForm,
} from "./useContentVideoForm";
import styles from "./styles.scss";

type Props = ContentVideoFormProps & { isLoading: boolean; isNew: boolean };

export const ContentVideoForm = (props: Props) => {
  const form = useContentVideoForm(props);

  return (
    <form onSubmit={form.handleSubmit}>
      <Label isMute={true}>コンテンツ名</Label>
      <Input
        placeholder="コンテンツ名を入力"
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        hasError={form.touched["name"] && Boolean(form.errors["name"])}
      />
      <ErrorText>{form.touched["name"] && form.errors["name"]}</ErrorText>
      <BlockRow marginTop="1.6rem">
        <Label isMute={true}>
          <span>動画のURL</span>
          <span style={{ marginLeft: "1rem" }}>
            ※動画の登録はYouTubeまたはVimeoのURLのみ利用可能です。
          </span>
        </Label>
        <Input
          placeholder="YouTubeまたはVimeoのURLを入力"
          name="url"
          value={form.values.url}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          hasError={form.touched["url"] && Boolean(form.errors["url"])}
        />
        <ErrorText>{form.touched["url"] && form.errors["url"]}</ErrorText>
      </BlockRow>
      <Flex justifyContent="flex-end" marginTop="2.4rem">
        <Button
          type="submit"
          disabled={!form.isValid}
          isLoading={props.isLoading}
          className={styles.submitButton}
        >
          {props.isNew ? "登録" : "更新"}
        </Button>
      </Flex>
    </form>
  );
};
