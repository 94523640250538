import * as React from "react";
import styles from "./styles.scss";

/**
 * JobHumanLabel
 * アナリティクスで使われている生徒の職業タグを共通化したもの
 */
export const JobHumanLabel = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return <span className={styles.jobHumanLabel}>{children}</span>;
};
