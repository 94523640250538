import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import StudentLearningMaterialApi from "../../../api-clients/StudentLearningMaterialApi";
import { BookshelfStatusType } from "../../../interfaces/BookshelfEntryEntityInteface";
import { buildMainResourceApiErrorAction } from "../../common/errors";

// 生徒本棚取得
const getStudentLearningMaterials = createAction(
  "GET_STUDENT_LEARNING_MATERIALS",
);
const getStudentLearningMaterialsSuccess = createAction(
  "GET_STUDENT_LEARNING_MATERIALS_SUCCESS",
);
const getStudentLearningMaterialsError = createAction(
  "GET_STUDENT_LEARNING_MATERIALS_ERROR",
);
// 連携済み生徒用
export const dispatchGetStudentLearningMaterials =
  (studentId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(getStudentLearningMaterials());

    StudentLearningMaterialApi.getLearningMaterials(studentId, "all")
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getStudentLearningMaterialsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentLearningMaterialsError());
        }
      })
      .catch(() => {
        dispatch(getStudentLearningMaterialsError());
      });
  };
// 招待前生徒用
export const getPreRegisteredStudentLearningMaterials =
  (studentId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(getStudentLearningMaterials());

    StudentLearningMaterialApi.getPreRegisteredLearningMaterials(studentId)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getStudentLearningMaterialsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentLearningMaterialsError());
        }
      })
      .catch(() => {
        dispatch(getStudentLearningMaterialsError());
      });
  };

// 表示する本棚のステータス変更
export const dispatchChangeStudentsLearningMaterialsBookshelfType =
  createAction<BookshelfStatusType>(
    "CHANGE_STUDENTS_LEARNING_MATERIALS_BOOKSHELF_TYPE",
  );
