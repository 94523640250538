import { createAction } from "redux-actions";
import StudentMessageApi from "../../../api-clients/StudentMessageApi";
import { Dispatch, AnyAction } from "redux";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
  FlashMessageAction,
} from "../../flashMessage";
import {
  ActionTypes,
  MessagePayload,
  PostMessageFailurePayload,
  PostMessageSuccessPayload,
  PostFileSuccessPayload,
  PostFileFailurePayload,
  ChangeBotMessageStatusSuccessPayload,
  DeleteMessageSuccessPayload,
  Actions,
} from "./types";
import { MessageInterface } from "../../../interfaces/MessageInterface";
import BotMessageInterface, {
  BotMessagePointer,
} from "../../../interfaces/BotMessageInterface";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import ApiResponse, { CursorMetaData } from "../../../interfaces/ApiResponse";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { apiRequestError } from "../../apiRequestError";
import { DirectMessage } from "../../../interfaces/MessageInterface";
import compressImage from "../../../helpers/compressImage";
import { PostZoomMeetingMessageParams } from "../../../interfaces/PostZoomMeetingMessageParams";
import { buildMainResourceApiErrorAction } from "../../common/errors";

export const initialGetMessagesRequest = createAction<void>(
  ActionTypes.INITIAL_GET_REQUEST,
);
export const getMessagesRequest = createAction<void>(ActionTypes.GET_REQUEST);
export const getMessagesSuccess = createAction<MessagePayload>(
  ActionTypes.GET_SUCCESS,
);
export const getMessagesFailure = createAction<void>(ActionTypes.GET_FAILURE);

// 初期ロード
export const loadInitialStudentMessages =
  (studentId: string) =>
  (
    dispatch: ThunkDispatch<
      AppStateInterface,
      Record<string, never>,
      AnyAction
    >,
    getState: () => AppStateInterface,
  ): void => {
    const { studentMessagesState } = getState();
    if (studentMessagesState.loading) {
      return;
    }

    dispatch(initialGetMessagesRequest());
    dispatch(loadDirectMessages(studentId, undefined)).then(
      (res: ApiResponse<DirectMessage[], CursorMetaData> | void) => {
        if (!res) return;

        const directMessages = res.data;
        const cursorMeta = res.meta;
        // FIXME: 現状サーバー側が個別メッセージ，BOTメッセージAPIを別々に提供しているので2つをあわせたAPIが提供されたら別々に取得するロジックを辞める
        const pointer =
          res.data.length < 20
            ? { from: new Date(0).toISOString(), to: new Date().toISOString() }
            : { from: res.data[res.data.length - 1].sentAt };
        dispatch(loadBotMessages(studentId, pointer)).then(
          (res: BotMessageInterface[] | void) => {
            if (!res) return;

            const botMessages = res;
            const payload: MessagePayload = {
              directMessages,
              botMessages,
              meta: cursorMeta,
            };
            dispatch(getMessagesSuccess(payload));
          },
        );
      },
    );
  };

// 追加読み込み
export const loadMoreStudentMessages =
  (studentId: string, sinceCursor?: string) =>
  (
    dispatch: ThunkDispatch<
      AppStateInterface,
      Record<string, never>,
      AnyAction
    >,
    getState: () => AppStateInterface,
  ): void => {
    dispatch(getMessagesRequest());

    dispatch(loadDirectMessages(studentId, sinceCursor)).then(
      (res: ApiResponse<DirectMessage[], CursorMetaData> | void) => {
        if (!res) return;
        const directMessages = res.data;
        const meta = res.meta;

        const pointer = botMessagePointer(
          directMessages,
          getState().studentMessagesState.data,
        );
        dispatch(loadBotMessages(studentId, pointer)).then(
          (res: BotMessageInterface[] | void) => {
            if (!res) return;
            const botMessages = res;
            const payload: MessagePayload = {
              directMessages,
              botMessages,
              meta,
            };
            dispatch(getMessagesSuccess(payload));
          },
        );
      },
    );
  };

const botMessagePointer = (
  directMessages: DirectMessage[],
  messages: MessageInterface[],
): BotMessagePointer => {
  switch (directMessages.length) {
    case 0:
      return {
        to:
          messages.length > 0
            ? messages[messages.length - 1].sentAt
            : new Date().toISOString(),
      };
    case 1:
      return {
        to: directMessages[directMessages.length - 1].sentAt,
      };
    default:
      return {
        from: directMessages[directMessages.length - 1].sentAt,
        to: directMessages[0].sentAt,
      };
  }
};

// 個別メッセージ読込
const loadDirectMessages =
  (
    studentId: string,
    cursor: string | undefined,
  ): ThunkAction<
    Promise<ApiResponse<DirectMessage[], CursorMetaData> | void>,
    AppStateInterface,
    Record<string, never>,
    AnyAction
  > =>
  (
    dispatch: ThunkDispatch<
      AppStateInterface,
      Record<string, never>,
      AnyAction
    >,
  ): Promise<ApiResponse<DirectMessage[], CursorMetaData> | void> => {
    const options = cursor ? { since: cursor } : "";

    return StudentMessageApi.getMessages(studentId, { query: options })
      .then(async (res) => {
        if (res.ok) {
          return await res
            .json()
            .then(
              (json: {
                messages: ApiResponse<DirectMessage[], CursorMetaData>;
              }) => json.messages,
            );
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getMessagesFailure());
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(getMessagesFailure());
        dispatch(apiRequestError());
      });
  };

// BOTメッセージ読込
const loadBotMessages =
  (
    studentId: string,
    pointer: { from?: string; to?: string },
  ): ThunkAction<
    Promise<BotMessageInterface[] | void>,
    AppStateInterface,
    Record<string, never>,
    AnyAction
  > =>
  (
    dispatch: ThunkDispatch<
      AppStateInterface,
      Record<string, never>,
      AnyAction
    >,
  ) => {
    return StudentMessageApi.getBotMessages(studentId, pointer)
      .then(async (res) => {
        if (res.ok) {
          return await res
            .json()
            .then(
              (json: { botMessages: BotMessageInterface[] }) =>
                json.botMessages,
            );
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getMessagesFailure());
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(buildMainResourceApiErrorAction(e.status));
        dispatch(getMessagesFailure());
        dispatch(apiRequestError());
      });
  };

// フォロー
const followStudentMessageSuccess = createAction<void>(
  ActionTypes.FOLLOW_THREAD_SUCCESS,
);
const followStudentMessageFailure = createAction<void>(
  ActionTypes.FOLLOW_THREAD_FAILURE,
);
export const followStudentMessage =
  (studentId: string) =>
  (dispatch: Dispatch): void => {
    StudentMessageApi.postFollow(studentId)
      .then((res: any) => {
        if (res.ok) {
          dispatch(followStudentMessageSuccess());
        } else {
          dispatch(followStudentMessageFailure());
        }
      })
      .catch((err: any) => {
        console.error(err);
        dispatch(followStudentMessageFailure());
        dispatch(apiRequestError());
      });
  };

// フォロー解除
const unfollowStudentMessageSuccess = createAction<void>(
  ActionTypes.UNFOLLOW_THREAD_SUCCESS,
);
const unfollowStudentMessageFailure = createAction<void>(
  ActionTypes.UNFOLLOW_THREAD_FAILURE,
);
export const unfollowStudentMessage =
  (studentId: string) =>
  (dispatch: Dispatch): void => {
    StudentMessageApi.deleteFollow(studentId)
      .then((res: any) => {
        if (res.ok) {
          dispatch(unfollowStudentMessageSuccess());
        } else {
          dispatch(unfollowStudentMessageFailure());
        }
      })
      .catch((err: any) => {
        console.error(err);
        dispatch(unfollowStudentMessageFailure());
        dispatch(apiRequestError());
      });
  };

const getFollowStatusSuccess = createAction<boolean>(
  ActionTypes.GET_FOLLOW_STATUS_SUCCESS,
);
export const loadFollowStatus =
  (studentId: string) =>
  (dispatch: Dispatch): void => {
    StudentMessageApi.getFollowStatus(studentId)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(getFollowStatusSuccess(json.followStatus));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

// メッセージ送信
const postMessageRequest = createAction<void>(ActionTypes.POST_MESSAGE_REQUEST);
const postMessageSuccess = createAction<PostMessageSuccessPayload>(
  ActionTypes.POST_MESSAGE_SUCCESS,
);
const postMessageFailure = createAction<PostMessageFailurePayload | void>(
  ActionTypes.POST_MESSAGE_FAILURE,
);
const handlePostMessageError = (
  dispatch: Dispatch,
  json?: ApiErrorResponseInterface,
) => {
  json ? dispatch(postMessageFailure(json)) : dispatch(postMessageFailure());
  dispatch(showErrorFlashMessage("メッセージが送信できませんでした"));
};
export const postStudentMessage =
  (studentId: string, content: string) =>
  (dispatch: Dispatch): void => {
    dispatch(postMessageRequest());
    StudentMessageApi.postMessage(studentId, content)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: { message: DirectMessage }) => {
            dispatch(postMessageSuccess(json));
          });
        } else if (res.status === 422) {
          res.json().then((json: any) => {
            handlePostMessageError(dispatch, json);
          });
        } else {
          handlePostMessageError(dispatch);
        }
      })
      .catch(() => {
        handlePostMessageError(dispatch);
      });
  };

const postMessageFileRequest = createAction<void>(
  ActionTypes.POST_FILE_REQUEST,
);
const postMessageFileSuccess = createAction<PostFileSuccessPayload>(
  ActionTypes.POST_FILE_SUCCESS,
);
const postMessageFileFailure = createAction<PostFileFailurePayload | void>(
  ActionTypes.POST_FILE_FAILURE,
);
const handlePostMessageFileError = (dispatch: Dispatch, json?: any) => {
  json
    ? dispatch(postMessageFileFailure(json))
    : dispatch(postMessageFileFailure());
  dispatch(showErrorFlashMessage("メッセージが送信できませんでした"));
};
export const postStudentMessageFile =
  (studentId: string, file: File) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(postMessageFileRequest());
    let compressedFile;
    try {
      compressedFile =
        file.type === "application/pdf" ? file : await compressImage(file);
    } catch (_) {
      // NOTE: 画像の圧縮に失敗した場合にはboronにリクエストを送る前にエラーメッセージを出す
      const json: ApiErrorResponseInterface = {
        errors: [
          {
            field: "content",
            message:
              "画像ファイルのファイル形式はimage/jpeg, image/gif, image/pngにしてください。pdfファイルのファイル形式はapplication/pdfにしてください。",
          },
        ],
      };
      handlePostMessageFileError(dispatch, json);
      return;
    }
    StudentMessageApi.postMessageFile(studentId, compressedFile)
      .then((res: Response) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(
              postMessageFileSuccess({
                message: json.message,
              }),
            );
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            handlePostMessageFileError(dispatch, json);
          });
        } else {
          handlePostMessageFileError(dispatch);
        }
      })
      .catch(() => {
        handlePostMessageFileError(dispatch);
      });
  };

// BOTメッセージの対応状況をDONE
const changeBotMessageStatusDoneRequest = createAction<void>(
  ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_REQUEST,
);
const changeBotMessageStatusDoneFailure = createAction<void>(
  ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_FAILURE,
);
const changeBotMessageStatusDoneSuccess =
  createAction<ChangeBotMessageStatusSuccessPayload>(
    ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_SUCCESS,
  );
export const changeBotMessageStatusDone =
  (studentId: string, messageId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(changeBotMessageStatusDoneRequest());
    StudentMessageApi.changeBotMessageStatusDone(studentId, messageId)
      .then((res) => {
        if (res.ok) {
          dispatch(changeBotMessageStatusDoneSuccess({ messageId }));
          dispatch(showSuccessFlashMessage("対応状況を変更しました"));
        } else {
          dispatch(changeBotMessageStatusDoneFailure());
          dispatch(showErrorFlashMessage("対応状況を変更できませんでした"));
        }
      })
      .catch((e: Error) => {
        console.error(e);
        dispatch(apiRequestError());
      });
  };

// BOTメッセージの対応状況をIGNORE
const changeBotMessageStatusIgnoredRequest = createAction<void>(
  ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_REQUEST,
);
const changeBotMessageStatusIgnoredFailure = createAction<void>(
  ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_FAILURE,
);
const changeBotMessageStatusIgnoredSuccess =
  createAction<ChangeBotMessageStatusSuccessPayload>(
    ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_SUCCESS,
  );
export const changeBotMessageStatusIgnored =
  (studentId: string, messageId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(changeBotMessageStatusIgnoredRequest());
    StudentMessageApi.changeBotMessageStatusIgnored(studentId, messageId)
      .then((res) => {
        if (res.ok) {
          dispatch(changeBotMessageStatusIgnoredSuccess({ messageId }));
          dispatch(showSuccessFlashMessage("対応状況を変更しました"));
        } else {
          dispatch(changeBotMessageStatusIgnoredFailure());
          dispatch(showErrorFlashMessage("対応状況を変更できませんでした"));
        }
      })
      .catch((e: Error) => {
        console.error(e);
        dispatch(apiRequestError());
      });
  };

// Zoomメッセージ送信
export const postZoomMeetingMessage =
  (
    studentId: string,
    params: PostZoomMeetingMessageParams,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ) =>
  async (dispatch: Dispatch<Actions | FlashMessageAction>): Promise<void> => {
    dispatch({ type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_REQUEST });

    try {
      setSubmitting(true);
      const response = await StudentMessageApi.postZoomMeetingMessage(
        studentId,
        params,
      );

      if (response.ok) {
        const json: { message: DirectMessage } = await response.json();
        dispatch({
          type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_SUCCESS,
          payload: json,
        });
        dispatch(showSuccessFlashMessage("メッセージを送信しました"));
        setSubmitting(false);
        onSuccessCallback();
      } else if (response.status === 422) {
        const json: ApiErrorResponseInterface = await response.json();
        dispatch({
          type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_ERROR,
          payload: json,
        });

        const errors: Record<string, unknown> = {};
        json.errors.forEach((error) => {
          if (error.field) {
            errors[error.field] = error.message;
          }
        });
        setErrors(errors);

        dispatch(showErrorFlashMessage("メッセージを送信できませんでした"));
        setSubmitting(false);
      } else {
        dispatch({ type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_ERROR });
        dispatch(showErrorFlashMessage("メッセージを送信できませんでした"));
        setSubmitting(false);
      }
    } catch {
      dispatch({ type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_ERROR });
      dispatch(showErrorFlashMessage("メッセージを送信できませんでした"));
      setSubmitting(false);
    }
  };

// メッセージ削除
const deleteMessageRequest = createAction<void>(
  ActionTypes.DELETE_MESSAGE_REQUEST,
);
const deleteMessageFailure = createAction<void>(
  ActionTypes.DELETE_MESSAGE_FAILURE,
);
const deleteMessageSuccess = createAction<DeleteMessageSuccessPayload>(
  ActionTypes.DELETE_MESSAGE_SUCCESS,
);
export const deleteMessage =
  (studentId: string, messageId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(deleteMessageRequest());
    StudentMessageApi.deleteMessage(studentId, messageId)
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then((json) =>
              dispatch(deleteMessageSuccess({ message: json.message })),
            );
        } else {
          dispatch(deleteMessageFailure());
          dispatch(showErrorFlashMessage("削除できませんでした"));
        }
      })
      .catch((e: Error) => {
        console.error(e);
        dispatch(apiRequestError());
      });
  };
