import * as React from "react";
import styles from "./styles.scss";
import Button from "../../atoms/Button/index";
import { Link } from "react-router-dom";
import SubLayout from "../../atoms/SubLayout/index";
import Settings from "../../../helpers/Settings";
import { Helmet } from "react-helmet";

interface Props extends React.PropsWithChildren<Record<string, unknown>> {
  heading: string;
  title: string;
  buttonLink?: string;
  buttonText?: string;
  onButtonClick?: (e: any) => void;
  hideButton?: boolean;
  bottomDescription?: JSX.Element;
}

const ErrorTemplate = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>{`${props.title} | ${Settings.SITE_TITLE}`}</title>
      </Helmet>
      <SubLayout>
        <SubLayout.Header />
        <SubLayout.Main>
          <div className={styles.main}>
            <SubLayout.Heading>{props.heading}</SubLayout.Heading>
            <div className={styles.children}>{props.children}</div>
            {!props.hideButton ? (
              <div className={styles.buttonContainer}>
                <Link to={props.buttonLink || "/"}>
                  <Button
                    disabled={false}
                    className={styles.button}
                    onClick={props.onButtonClick}
                  >
                    {props.buttonText || "トップページへ戻る"}
                  </Button>
                </Link>
              </div>
            ) : null}
            {props.bottomDescription ? <>{props.bottomDescription}</> : null}
          </div>
        </SubLayout.Main>
        <SubLayout.Footer />
      </SubLayout>
    </>
  );
};

export default ErrorTemplate;
