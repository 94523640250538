import * as React from "react";
import { parseISO } from "date-fns";
import TimeHelper, { formatDate } from "../../../helpers/TimeHelper";
import Loader from "../../../components/atoms/Loader";
import LinkButton from "../../../components/atoms/LinkButton";
import { Text } from "../../../components/general/Text";
import { IconArrowDown, IconArrowRight } from "@studyplus/boron-ui";
import { useFetchStudyTask } from "./useFetchStudyTask";
import { StudyTaskContentsDetail } from "./StudyTaskContentsDetail";
import { useQueryError } from "../../../hooks/http/useQueryError";

type Props = {
  sectionId: string;
  studyTaskId: string;
  setBreadcrumbItemName: (title: string) => void;
  setTableColumnName: (name: string) => void;
};
export const StudyTaskDetail = ({
  sectionId,
  studyTaskId,
  setBreadcrumbItemName,
  setTableColumnName,
}: Props) => {
  const {
    data: studyTask,
    error,
    isLoading,
  } = useFetchStudyTask({
    sectionId,
    studyTaskId,
  });
  useQueryError(error);

  React.useEffect(() => {
    setBreadcrumbItemName(studyTask?.title || "");
  }, [studyTask?.title]);

  React.useEffect(() => {
    setTableColumnName(studyTask?.learningMaterial.name || "");
  }, [studyTask?.learningMaterial?.name]);

  const [isOpenStudyTaskContentsDetail, setOpenStudyTaskContentsDetail] =
    React.useState<boolean>(false);

  if (isLoading || !studyTask) {
    return <Loader loading={isLoading || !studyTask} />;
  }

  return (
    <div
      className="rounded border border-solid border-gray-400 bg-white pt-6"
      aria-label="課題の詳細内容"
    >
      <div className="px-7 pb-7">
        <div className="break-words text-2xl font-bold leading-[2rem]">
          {studyTask.title}
        </div>
        <div className="mt-2">
          <span className="whitespace-nowrap">
            配信期間：{formatDate(parseISO(studyTask.startAt))} 〜{" "}
            {formatDate(parseISO(studyTask.endAt))}
          </span>
          <span className="ml-8 whitespace-nowrap">
            作成日：{TimeHelper.fullFormat(parseISO(studyTask.createdAt))}
          </span>
        </div>
      </div>
      <div className="border border-0 border-t border-solid border-gray-400 px-7">
        <div className="py-3">
          <StudyTaskContentsToggleButton
            onClick={() =>
              setOpenStudyTaskContentsDetail(!isOpenStudyTaskContentsDetail)
            }
            isOpenStudyTaskContentsDetail={isOpenStudyTaskContentsDetail}
          />
          {isOpenStudyTaskContentsDetail && (
            <StudyTaskContentsDetail
              sectionId={sectionId}
              studyTask={studyTask}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type StudyTaskContentsToggleButtonProps = {
  isOpenStudyTaskContentsDetail: boolean;
  onClick: () => void;
};
const StudyTaskContentsToggleButton = ({
  isOpenStudyTaskContentsDetail,
  onClick,
}: StudyTaskContentsToggleButtonProps) => {
  return (
    <LinkButton onClick={onClick}>
      <div className="flex items-center p-0">
        {isOpenStudyTaskContentsDetail ? (
          <IconArrowDown className="text-blue-400" />
        ) : (
          <IconArrowRight className="text-blue-400" />
        )}

        <Text color="primary" bold>
          課題内容を見る
        </Text>
      </div>
    </LinkButton>
  );
};
