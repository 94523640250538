import * as React from "react";

import Loader from "../../../../components/atoms/Loader";
import MessageThreadListItem from "./MessageThreadListItem";
import styles from "./styles.scss";
import MessageThreadInterface from "../../../../interfaces/MessageThreadInterface";

interface Props {
  messageThreads: MessageThreadInterface[];
  openedThread: MessageThreadInterface | null;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  onLoadMore: () => void;
  onClickThread: (thread: MessageThreadInterface) => void;
}

const SectionMessageThreadList = (props: Props) => {
  if (props.isLoading) {
    return <Loader loading={props.isLoading} />;
  }

  return (
    <div
      className={styles.root}
      onScroll={handleScroll(props)}
      aria-label="メッセージスレッド一覧"
    >
      <List
        messageThreads={props.messageThreads}
        openedThread={props.openedThread}
        onClickThread={props.onClickThread}
      />
      <Loader loading={props.isFetchingNextPage} />
    </div>
  );
};

const List = ({
  messageThreads,
  openedThread,
  onClickThread,
}: Pick<Props, "messageThreads" | "onClickThread"> & {
  openedThread: MessageThreadInterface | null;
}) => {
  if (!messageThreads || messageThreads.length === 0) {
    return <p className={styles.no_student}>生徒の登録がありません</p>;
  }

  return (
    <>
      {messageThreads.map((messageThread) => (
        <MessageThreadListItem
          key={messageThread.student.id}
          messageThread={messageThread}
          isActive={!!openedThread && openedThread.id === messageThread.id}
          onClickThread={onClickThread}
        />
      ))}
    </>
  );
};

const handleScroll =
  (props: Props) => (e: React.WheelEvent<HTMLDivElement>) => {
    if (props.isLoading || props.isFetchingNextPage) {
      return;
    }

    const scrollBottom =
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight;
    if (scrollBottom + 100 >= e.currentTarget.scrollHeight) {
      props.onLoadMore();
    }
  };

export default SectionMessageThreadList;
