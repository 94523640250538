import { StudentKarteStateInterface } from "../interfaces/KarteInterface";
import karte from "./karte";

const defaultKarteState: StudentKarteStateInterface = {
  loading: true,
  data: null,
};

const studentKarte = (
  state: StudentKarteStateInterface = defaultKarteState,
  action: ReduxActions.Action<any>,
) => {
  const { payload } = action;

  switch (action.type) {
    case "GET_STUDENT_KARTE_SUCCESS":
      return {
        data: payload.karte,
        loading: false,
      };

    // カルテの更新
    case "DELETE_KARTE_SUCCESS":
      return state;

    // コメント
    case "OPEN_KARTE_SHARE_MODAL":
    case "CLOSE_KARTE_SHARE_MODAL":
    case "POST_KARTE_SHARE_SENDING":
    case "POST_KARTE_SHARE_SUCCESS":
    case "POST_KARTE_SHARE_ERROR":
    case "DELETE_KARTE_COMMENT_SUCCESS":
    case "DELETE_KARTE_COMMENT_ERROR":
    case "POST_KARTE_COMMENT_SUCCESS":
    case "POST_KARTE_COMMENT_ERROR":
      return {
        data: karte(state.data, action),
        loading: false,
      };

    default:
      return state;
  }
};

export default studentKarte;
