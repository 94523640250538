import SectionStudentsNewStateInterface from "../../interfaces/SectionStudentsNewStateInterface";

const defaultState: SectionStudentsNewStateInterface = {
  submitting: false,
  apiErrors: [],
};

const sectionsStudentsNewState = (
  state: SectionStudentsNewStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): any => {
  switch (action.type) {
    case "POST_NEW_SECTION_STUDENT_REQUEST":
      return {
        ...state,
        submitting: true,
      };
    case "POST_NEW_SECTION_STUDENT_SUCCESS":
      return {
        ...state,
        submitting: false,
      };
    case "POST_NEW_SECTION_STUDENT_FAILURE":
      return {
        ...state,
        submitting: false,
        apiErrors: action.payload ? action.payload.errors || [] : [],
      };
    default:
      return state;
  }
};

export default sectionsStudentsNewState;
