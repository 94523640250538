import { Dispatch } from "redux";
import { NavigateFunction } from "react-router";
import ApiClient from "./../../../api";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
} from "../../flashMessage";
import { apiRequestError } from "../../apiRequestError";
import { createAction } from "redux-actions";
import { ActionTypes } from "./types";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";

const resetPasswordRequest = createAction<void>(
  ActionTypes.RESET_PASSWORD_REQUEST,
);
const resetPasswordSuccess = createAction<void>(
  ActionTypes.RESET_PASSWORD_SUCCESS,
);
const resetPasswordFailure = createAction<ApiErrorInterface[]>(
  ActionTypes.RESET_PASSWORD_FAILURE,
);
export const resetPassword =
  (
    token: string,
    password: string,
    passwordConfirmation: string,
    navigate: NavigateFunction,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(resetPasswordRequest());
    ApiClient.patch(`/api/v1/password_reset/${token}`, {
      operator: {
        password: password,
        password_confirmation: passwordConfirmation,
      },
    }).then((res) => {
      if (res.ok) {
        dispatch(resetPasswordSuccess());
        showSuccessFlashMessage("パスワードを再設定しました");
        navigate(`/login`);
      } else if (res.status === 422) {
        showErrorFlashMessage("パスワードを再設定できませんでした");
        res.json().then((json: ApiErrorResponseInterface) => {
          dispatch(resetPasswordFailure(json.errors));
        });
        showErrorFlashMessage("パスワードを再設定できませんでした");
      } else {
        dispatch(apiRequestError());
      }
    });
  };

const checkTokenValidityRequest = createAction<void>(
  ActionTypes.CHECK_VALIDITY_REQUEST,
);
const checkTokenValiditySuccess = createAction<void>(
  ActionTypes.CHECK_VALIDITY_SUCCESS,
);
const checkTokenValidityFailure = createAction<void>(
  ActionTypes.CHECK_VALIDITY_FAILURE,
);
const initializedResetPasswordPage = createAction<void>(
  ActionTypes.INITIALIZED,
);
export const initializeResetPasswordPage =
  (token: string) => (dispatch: Dispatch) => {
    dispatch(checkTokenValidityRequest());
    ApiClient.get(`/api/v1/password_reset/${token}`).then((res) => {
      if (res.ok) {
        dispatch(checkTokenValiditySuccess());
      } else {
        dispatch(checkTokenValidityFailure());
      }
      dispatch(initializedResetPasswordPage());
    });
  };
