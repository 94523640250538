import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../../api";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import LinkButton from "../../../components/atoms/LinkButton";
import StudentInterface from "../../../interfaces/StudentInterface";

type Props = {
  studentId: string;
  reissue: boolean;
  updateStudent: (student: StudentInterface) => void;
  confirmMessage?: string;
  children: React.ReactNode;
};
export const IssuePasscodeButton = ({
  studentId,
  reissue,
  updateStudent,
  confirmMessage,
  children,
}: Props) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const { response, data } = reissue
        ? await boronClient.PATCH(`/api/v1/students/{student_id}/passcodes`, {
            params: { path: { student_id: studentId } },
          })
        : await boronClient.POST(`/api/v1/students/{student_id}/passcodes`, {
            params: { path: { student_id: studentId } },
          });
      if (response.ok && data) {
        updateStudent(data.student);
        const message = reissue
          ? "アプリ連携コードを再発行しました"
          : "アプリ連携コードを発行しました";
        showSuccessMessage(message);
        return;
      }
      showErrorMessage("アプリ連携コードを発行できませんでした");
    },
  });

  const handleIssuePasscode = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (confirmMessage == null || window.confirm(confirmMessage)) {
      if (!isPending) mutate();
    }
  };

  return (
    <LinkButton
      onClick={handleIssuePasscode}
      className="p-0 text-gray-800 hover:text-blue-400"
    >
      {children}
    </LinkButton>
  );
};
