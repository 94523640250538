import StudentInterface from "../../../interfaces/StudentInterface";
import { EllipsisDropdown } from "../../../components/general/EllipsisDropdown";
import { DisconnectAppDropdownLink } from "./DisconnectAppDropdownLink";
import { DisableStudentDropdownLink } from "./DisableStudentDropdownLink";
import { OperatorRole } from "../../../interfaces/OperatorInterface";
import { StudentEditLink } from "./StudentEditLink";
import { DisconnectLineDropdownLink } from "./DisconnectLineDropdownLink";

type Props = {
  student: StudentInterface;
  operatorRole: OperatorRole;
  guardianLineConnectionUsable: boolean;
  updateStudent: (student: StudentInterface) => void;
  removeStudent: (studentId: string) => void;
};
export const StudentItemDropdown = ({
  student,
  operatorRole,
  guardianLineConnectionUsable,
  updateStudent,
  removeStudent,
}: Props) => {
  if (operatorRole === "limited_member") {
    return null;
  }

  return (
    <EllipsisDropdown position={{ bottom: "0", right: "3rem" }}>
      <StudentEditLink student={student} updateStudent={updateStudent} />
      {student.status !== "active" && student.status !== "inviting" ? null : (
        <DisconnectAppDropdownLink
          student={student}
          updateStudent={updateStudent}
        />
      )}
      {guardianLineConnectionUsable && (
        <DisconnectLineDropdownLink
          student={student}
          updateStudent={updateStudent}
        />
      )}
      {student.isDeletable ? (
        <DisableStudentDropdownLink
          student={student}
          removeStudent={removeStudent}
        />
      ) : null}
    </EllipsisDropdown>
  );
};
