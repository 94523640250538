import * as React from "react";
import styles from "./styles.scss";
import Select from "react-select";
import { Link } from "react-router-dom";
import StudentInterface from "../../../interfaces/StudentInterface";
import { BookshelfStatusType } from "../../../interfaces/BookshelfEntryEntityInteface";
import groupBy from "lodash-es/groupBy";
import Loader from "../../atoms/Loader/index";
import Card from "../../atoms/Card/index";
import {
  useFetchBookshelfEntries,
  useRemoveBookshelfEntry,
} from "../../../hooks/http/useBookshelfEntries";
import { BookshelfEntry } from "../../../domains/BookshelfEntry";
import { useBookshelfStatusFromURL } from "./useBookshelfStatusFromURL";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

interface Props {
  student: StudentInterface;
  bookshelfEntriesState: ReturnType<typeof useFetchBookshelfEntries>;
}

interface BookshelfOption {
  label: string;
  value: BookshelfStatusType;
}

const bookshelfStatusOptions: BookshelfOption[] = [
  {
    label: "勉強中",
    value: "in_progress",
  },
  {
    label: "スタンバイ",
    value: "open",
  },
  {
    label: "完了済み",
    value: "closed",
  },
];

const StudentBookshelf = (props: Props) => {
  const { selectedType, changeStatusType } = useBookshelfStatusFromURL();

  // FIXME: @types/react-selectの型が誤っているため,
  // option.valueを参照できるようにするため option:any にしています
  // ref: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
  const handleChangeStatusOption = (option: any) => {
    changeStatusType(option.value);
  };

  const selectedOption = bookshelfStatusOptions.find(
    (option) => option.value === selectedType,
  );

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Select
          options={bookshelfStatusOptions}
          onChange={handleChangeStatusOption}
          isSearchable={false}
          className={styles.select}
          value={selectedOption}
        />
        <Link
          to={`/students/${props.student.id}/learning_materials/new`}
          className={`buiButtonBase buiButtonVariantFilled`}
        >
          新規登録
        </Link>
      </div>
      <div className={styles.bookshelf}>
        <Bookshelf {...props} selectedType={selectedType} />
      </div>
    </div>
  );
};

const Bookshelf = ({
  student,
  bookshelfEntriesState,
  selectedType,
}: Props & { selectedType: BookshelfStatusType }) => {
  if (bookshelfEntriesState.isLoading) {
    return <Loader loading={bookshelfEntriesState.isLoading} />;
  }

  const { data } = bookshelfEntriesState;

  if (data) {
    const visibleBookshelfEntries = data.filter(
      (bookshelfEntry: BookshelfEntry) => {
        return bookshelfEntry.status === selectedType;
      },
    );

    const groupedBookshelfEntries = groupBy(
      visibleBookshelfEntries,
      "categoryName",
    );

    return (
      <>
        {Object.keys(groupedBookshelfEntries).map((key) => {
          const bookshelfEntries = groupedBookshelfEntries[key];
          if (bookshelfEntries.length > 0) {
            return (
              <React.Fragment
                key={`StudentBookshelf-Bookshelf-categoryName-${key}`}
              >
                <h3 className={styles.categoryName}>{key}</h3>
                <div className={styles.bookshelf__list}>
                  {bookshelfEntries.map((bookshelfEntry: BookshelfEntry) => (
                    <BookshelfItem
                      student={student}
                      bookshelfEntry={bookshelfEntry}
                      key={bookshelfEntry.learningMaterial.code}
                    />
                  ))}
                </div>
              </React.Fragment>
            );
          }
        })}
      </>
    );
  } else {
    return null;
  }
};

const BookshelfItem = ({
  student,
  bookshelfEntry,
}: {
  student: StudentInterface;
  bookshelfEntry: BookshelfEntry;
}) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();
  const { mutate, isPending } = useRemoveBookshelfEntry({
    studentId: student.id,
    steakMaterialCode: bookshelfEntry.learningMaterial.code,
    onError() {
      const message =
        "本棚から削除に失敗しました。しばらく間を置いてから再度お試しください";
      showErrorMessage(message);
    },
    onSuccess() {
      showSuccessMessage("本棚から削除されました");
    },
  });
  const handleDeleteBookshelfEntry = () => {
    if (
      !isPending &&
      window.confirm(
        `デジタル教材を削除すると、現在連携しているStudyplusユーザーはデジタル教材を学習できなくなります。\n本当に「${student.fullName}」の本棚から「${bookshelfEntry.learningMaterial.name}」のデジタル教材を削除してよろしいですか？`,
      )
    ) {
      mutate();
    }
  };
  return (
    <Card
      title={bookshelfEntry.learningMaterial.name}
      image={bookshelfEntry.learningMaterial.imageUrl}
      key={bookshelfEntry.learningMaterial.code}
      showDeleteIcon={bookshelfEntry.isDeletable}
      onDelete={handleDeleteBookshelfEntry}
    />
  );
};

export default StudentBookshelf;
