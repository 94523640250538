import * as React from "react";
import FullCalendar from "@fullcalendar/react";
import { useEffectAvoidFirst } from "../../../hooks/useEffectAvoidFirst";
import { CalendarType } from "../../atoms/Calendar";

export type UseCalendarProps = {
  calendarType?: CalendarType;
  startDate?: Date;
};

// eslint-disable-next-line
export const useCalendar = (props: UseCalendarProps) => {
  const calendarApiRef = React.useRef<FullCalendar>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const calendarScreenDivRef = React.useRef<HTMLDivElement | null>(null);

  useEffectAvoidFirst(() => {
    if (props.startDate && calendarApiRef.current) {
      calendarApiRef.current.getApi().changeView("timeGrid", props.startDate);
    }
  }, [props.startDate]);

  // スマフォ対応で週の場合は画面が狭いので横スクロールで見せる、
  // 日の場合はスクロールが必要ないのでスクリーンぴったりにする
  // というのを実現するためのハック。.fc-media-screenのエレメントのwidthを切り替えるclassをつけたり消したりしている。
  // fullcalendarのdocsによるとviewClassNamesで切り替えられそうだったが、期待した結果にならなかった。
  React.useLayoutEffect(() => {
    if (calendarScreenDivRef.current === null && rootRef.current) {
      calendarScreenDivRef.current =
        rootRef.current?.querySelector(".fc-media-screen");
    }
    const w = calendarScreenDivRef.current;
    if (w && props.calendarType === "Weekly") {
      w.className = `${w?.className} weekcalendarSize`;
    } else if (w) {
      const newClassNames = w.className
        .split(" ")
        .filter((classsName) => classsName !== "weekcalendarSize")
        .join(" ");
      w.className = newClassNames;
    }
  }, [props.calendarType]);

  return {
    calendarApiRef,
    rootRef,
  };
};
