import { connect } from "react-redux";
import { deleteKarteComment } from "../../../actions/karte";
import CommentItem from "../CommentItem/index";
import KarteInterface from "../../../interfaces/KarteInterface";
import { KarteCommentInterface } from "../../../interfaces/KarteInterface";

interface Props {
  karte: KarteInterface;
  comment: KarteCommentInterface;
  studentId: string;
  deleteKarteComment: (
    studentId: string,
    karteId: string,
    commentId: string,
  ) => void;
}

const handleDeleteKarteComment = (props: Props) => () => {
  props.deleteKarteComment(props.studentId, props.karte.id, props.comment.id);
};

const PresentationalComponent = (props: Props) => {
  const { comment } = props;

  return (
    <CommentItem
      onDelete={handleDeleteKarteComment(props)}
      senderName={comment.staffName}
      createdAt={comment.createdAt}
      comment={comment.comment}
      isDeletable={comment.isDeletable}
    />
  );
};

const actions = {
  deleteKarteComment,
};

const KarteComment = connect(null, actions)(PresentationalComponent);

export default KarteComment;
