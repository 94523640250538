import { useAnalyticsLectureStudent } from "./useAnalyticsLectureStudent";
import { PaginationToolbar } from "../PaginationToolbar";
import { AnalyticsLectureStudentTable } from "../AnalyticsLectureStudentTable";
import styles from "./AnalyticsLectureStudent.scss";
import { Lecture } from "../../../domains/Lecture";
import Loader from "../../../components/atoms/Loader";

type Props = {
  lecture: Lecture;
};

export const AnalyticsLectureStudent = (props: Props) => {
  const {
    searchConditionToolbarProps,
    lectureStudentAnalytics,
    isLoading,
    isFetching,
    analyticsTableProps,
  } = useAnalyticsLectureStudent(props);
  return (
    <main aria-labelledby="lecture-name">
      <h1
        id="lecture-name"
        className={styles.analyticsLectureStudent__heading}
        aria-label={`${props.lecture.name}の生徒出席状況`}
      >
        {props.lecture.name}
      </h1>
      <div className={styles.analyticsLectureStudent__pagination}>
        <PaginationToolbar {...searchConditionToolbarProps} />
      </div>
      {lectureStudentAnalytics && (
        <div className={styles.analyticsLectureStudent__table}>
          <AnalyticsLectureStudentTable
            {...analyticsTableProps}
            lectureStudentAnalytics={lectureStudentAnalytics}
          />
        </div>
      )}
      {(isLoading || isFetching) && <Loader loading />}
    </main>
  );
};
