import * as React from "react";
import {
  FiltersQueryInterface,
  OrderDirFilterType,
} from "../../interfaces/FiltersInterface";
import FiltersHelper from "../../helpers/FiltersHelper";
import { DateRangeFilterQueryInterface } from "../../interfaces/DateRangeFilterInterface";
import { AnalyticsTermType } from "../../interfaces/AnalyticsTableInterface";
import { useNavigate } from "react-router-dom";
import { useStudentFilterContext } from "../../components/features/NewStudentFilter/useStudentFilterContext";
import { useFilter } from "../useFilter";
import { useOnLocationChange } from "../../helpers/RouterHelper";

export type AnalyticsFilter = {
  term: AnalyticsTermType;
  startDate: Date;
  endDate: Date;
  order: string | null;
  orderDir: "asc" | "desc" | null;
  enabledRequest: boolean;
};

type Props = {
  sectionId: string;
  withStudentFilter?: boolean;
};
export const useAnalyticsFilter = ({
  sectionId,
  withStudentFilter = true,
}: Props) => {
  const context = useStudentFilterContext();
  const { filter, enabledRequest } = useFilter();
  const analyticsFilter = {
    term: filter.term,
    startDate: filter.dateRangeFilter.startDate,
    endDate: filter.dateRangeFilter.endDate,
    order: filter.order,
    orderDir: filter.orderDir,
    enabledRequest,
  } as AnalyticsFilter;
  const studentFilter = context.currentFilter;

  // 遷移時にURLパラメータからフィルターを復元する
  const updateStudentFilterByQuery = () => {
    // 生徒フィルターがあるときStudentFilterで行う処理のためスキップ
    if (withStudentFilter) return;

    context.setFilterKey(sectionId);
    context.setIsInitialized(true);
    context.updateStudentFilterByQuery();
  };

  React.useLayoutEffect(() => {
    updateStudentFilterByQuery();
  }, []);

  useOnLocationChange(() => {
    updateStudentFilterByQuery();
  });

  const navigate = useNavigate();
  const pushFilterHistory = (obj: FiltersQueryInterface) => {
    const query = FiltersHelper.toQueryString({
      ...filter,
      ...(studentFilter ? { tagFilter: studentFilter } : {}),
      ...obj,
    });

    navigate({ search: query });
  };

  const changeDates = (dateRangeFilter: DateRangeFilterQueryInterface) => {
    pushFilterHistory({ dateRangeFilter });
  };

  const changeTerm = (term: AnalyticsTermType) => {
    // termが変更されるときはdateの範囲をリセット
    pushFilterHistory({ term, dateRangeFilter: undefined });
  };

  const changeOrder = (
    order: string | null,
    initialOrderDir: OrderDirFilterType = "desc",
  ) => {
    if (analyticsFilter?.order !== order) {
      pushFilterHistory({ order, orderDir: initialOrderDir });
    } else {
      const orderDir = analyticsFilter?.orderDir === "desc" ? "asc" : "desc";
      pushFilterHistory({ order, orderDir });
    }
  };

  return {
    analyticsFilter,
    studentFilter,
    changeDates,
    changeTerm,
    changeOrder,
  };
};
