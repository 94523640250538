import * as React from "react";
import FiltersInterface, {
  FiltersQueryInterface,
} from "../interfaces/FiltersInterface";
import { startOfDay, subDays } from "date-fns";
import DateRangeFilterInterface from "../interfaces/DateRangeFilterInterface";
import { AnalyticsTermType } from "../interfaces/AnalyticsTableInterface";
import { useStudentFilterContext } from "../components/features/NewStudentFilter/useStudentFilterContext";
import startOfISOWeek from "date-fns/startOfISOWeek";
import endOfMonth from "date-fns/endOfMonth";
import FiltersHelper from "../helpers/FiltersHelper";

// 校舎アナリティクスやタイムラインなどの日付や列ソートなどの条件を管理するhooks
export const useFilter = () => {
  const context = useStudentFilterContext();
  const filtersQuery = context.filtersQuery.current;

  const [filter, setFilter] = React.useState<FiltersInterface>(defaultFilter());
  // NOTE: URLパラメータがないときはAPIリクエストを行う
  //       URLパラメータがあるときは初期stateではなく復元したstateでAPIリクエストするための制御を行う
  const [enabledRequest, setEnabledRequest] = React.useState<boolean>(
    !location.search,
  );

  // URLパラメータからstateを更新する
  // term(日/週/月)の表示がチラつくのでuseLayoutEffectを使っています
  React.useLayoutEffect(() => {
    setEnabledRequest(true);
    if (!filtersQuery) return;

    const nextDateRangeFilter = buildDateRangeFilter(filtersQuery);

    setFilter({
      dateFilter: filtersQuery.dateFilter,
      dateRangeFilter: nextDateRangeFilter,
      term: (filtersQuery.term || defaultFilter().term) as AnalyticsTermType,
      orderDir: filtersQuery.orderDir || "desc",
      order: filtersQuery.order || null,
      page: filtersQuery.page,
      since: filtersQuery.since,
      firstName: filtersQuery.firstName,
      lastName: filtersQuery.lastName,
    });
  }, [filtersQuery]);

  return {
    filter,
    enabledRequest,
  };
};

// FIXME: testでuseFakeDateが効かないので関数にしていますが、関数にする必要はないので直す方法があれば直したいです
const defaultFilter = (): FiltersInterface => {
  return {
    dateRangeFilter: {
      startDate: startOfDay(subDays(new Date(), 6)),
      endDate: startOfDay(new Date()),
    },
    term: "daily",
    orderDir: "desc",
    order: null,
  };
};

// term(日/週/月)に応じてstartDateとendDateを返す
export const buildDateRangeFilter = (
  filtersQuery: FiltersQueryInterface,
): DateRangeFilterInterface => {
  let nextDateRangeFilter = defaultFilter().dateRangeFilter;
  let endDate = nextDateRangeFilter.endDate;

  // set endDate
  if (filtersQuery.term) {
    endDate =
      filtersQuery.dateRangeFilter && filtersQuery.dateRangeFilter.endDate
        ? filtersQuery.dateRangeFilter.endDate
        : nextDateRangeFilter.endDate;
  }
  if (filtersQuery.term === "weekly") {
    endDate = startOfISOWeek(endDate);
  } else if (filtersQuery.term === "monthly") {
    endDate = endOfMonth(endDate);
  }

  // set startDate
  const startDate = FiltersHelper.calculateStartDate(
    endDate,
    filtersQuery.term,
  );
  nextDateRangeFilter = {
    startDate,
    endDate,
  };

  return nextDateRangeFilter;
};
