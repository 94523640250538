import SectionInterface from "../../../interfaces/SectionInterface";
import BackLink from "../../atoms/BackLink";
import NaviMenuSPToggleButton from "../NaviMenuSPToggleButton";
import styles from "./styles.scss";

interface Props {
  section: SectionInterface;
}

const NaviBack = (props: Props) => {
  return (
    <div className={styles.root}>
      <BackLink path={`/sections/${props.section.id}`}>戻る</BackLink>
      <div className={styles.toggleButton}>
        <NaviMenuSPToggleButton />
      </div>
    </div>
  );
};

export default NaviBack;
