import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import StudentApi from "../../../api-clients/StudentApi";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
} from "../../flashMessage";
import { NavigateFunction } from "react-router";
import { StudentParamsInterface } from "../../../interfaces/StudentInterface";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";

const postNewSectionStudentRequest = createAction(
  "POST_NEW_SECTION_STUDENT_REQUEST",
);
const postNewSectionStudentSuccess = createAction(
  "POST_NEW_SECTION_STUDENT_SUCCESS",
);
const postNewSectionStudentFailure = createAction(
  "POST_NEW_SECTION_STUDENT_FAILURE",
);

export const dispatchPostNewSectionStudent =
  (
    sectionId: string,
    params: StudentParamsInterface,
    navigate: NavigateFunction,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(postNewSectionStudentRequest());
    StudentApi.postNewStudent(sectionId, params)
      .then((res) => {
        if (res.ok) {
          res.json().then(() => {
            dispatch(postNewSectionStudentSuccess());
            dispatch(showSuccessFlashMessage("生徒を登録しました"));
            navigate(`/sections/${sectionId}/settings/students`);
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(postNewSectionStudentFailure(json));
            dispatch(showErrorFlashMessage("生徒を登録できませんでした"));
          });
        } else {
          dispatch(postNewSectionStudentFailure());
          dispatch(showErrorFlashMessage("生徒を登録できませんでした"));
        }
      })
      .catch(() => {
        dispatch(postNewSectionStudentFailure());
        dispatch(showErrorFlashMessage("生徒を登録できませんでした"));
      });
  };
