import { useCallback } from "react";
import ApiClient from "../../api";
import { createError, HTTPErrors } from "../../errors";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import ApiResponse from "../../interfaces/ApiResponse";
import { Teacher } from "../../domains/Teacher";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { ArchiveStatus } from "../../domains/Attendance";

type UseFetchActiveTeachersProps = {
  sectionId: string;
  status: ArchiveStatus;
  shouldGetAllRecords?: boolean;
  refetchOnWindowFocus?: boolean;
};

type ResponseBody = ApiResponse<Teacher[]>;

export const useFetchTeachers = ({
  refetchOnWindowFocus = true,
  ...props
}: UseFetchActiveTeachersProps) => {
  const path = pathOf(props.sectionId);
  const queryParams = {
    status: props.status,
  };
  const result = useInfiniteQuery<ResponseBody, HTTPErrors>({
    queryKey: teachersCacheKeyOf(props),
    queryFn: async ({ pageParam }) => {
      const res = await ApiClient.get(path, {
        query: {
          ...queryParams,
          page: pageParam,
          per: props.shouldGetAllRecords ? 99999 : 20,
        },
      });
      if (res.ok) {
        const json = await res.json();
        return json.teachers as ResponseBody;
      }
      throw await createError(res);
    },
    initialPageParam: 1,
    refetchOnWindowFocus,
    getNextPageParam,
    retry: false,
  });
  const data = result.data?.pages.flatMap((page) => page.data);
  return {
    ...result,
    data,
    totalCount: result.data?.pages[0].meta.totalCount,
  };
};

export const teachersCacheKeyOf = (props: UseFetchActiveTeachersProps) => [
  pathOf(props.sectionId),
  { status: props.status },
];

const pathOf = (sectionId: string) => `/api/v1/sections/${sectionId}/teachers`;

export const useRefreshActiveTeachers = ({
  sectionId,
}: Pick<UseFetchActiveTeachersProps, "sectionId">) => {
  const client = useQueryClient();

  return useCallback(
    () =>
      client.invalidateQueries({
        queryKey: teachersCacheKeyOf({ sectionId, status: "active" }),
      }),
    [sectionId],
  );
};
