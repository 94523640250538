import * as React from "react";
import { BlockRow } from "../../../../components/atoms/BlockRow/index";
import { Button } from "@studyplus/boron-ui";
import { Flex } from "../../../../components/atoms/Flex";
import { Text } from "../../../../components/general/Text/Text";
import {
  HorizontalScrollTable,
  TableColumn,
} from "../../../../components/atoms/Table/index";
import { Building } from "../../../../domains/Building";
import { useFetchBuildingsApi } from "./useFetchBuildingsApi";
import Loader from "../../../../components/atoms/Loader";
import LinkButton from "../../../../components/atoms/LinkButton/index";
import Icon from "../../../../components/atoms/Icon";
import { InlineBlock } from "../../../../components/atoms/InlineBlock";
import { RegistrationModal } from "./RegistrationModal";
import { useDeleteBuildingApi } from "./useDeleteBuildingApi";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import styles from "./styles.scss";

type Props = {
  sectionId: string;
};
export const Buildings = ({ sectionId }: Props) => {
  const {
    data,
    isLoading,
    modalState,
    openNewModal,
    openEditModal,
    closeModal,
    onClickDelete,
  } = useBuildings({
    sectionId,
  });

  return data ? (
    <BlockRow marginTop="5rem">
      <Flex justifyContent="space-between">
        <Text as="h3" bold>
          位置情報登録一覧
        </Text>
        <Button variant="outline" size="sm" onClick={openNewModal}>
          <Icon name="icon-plus-tag" className={styles.icon} />
          位置情報の追加
        </Button>
      </Flex>
      <BuildingsTable
        buildings={data}
        onClickDelete={onClickDelete}
        onClickEdit={openEditModal}
      />
      <RegistrationModal
        sectionId={sectionId}
        onClose={closeModal}
        isOpen={modalState.isOpen}
        building={modalState.building}
      />
    </BlockRow>
  ) : (
    <Loader loading={isLoading}></Loader>
  );
};

const headers = [
  {
    text: "場所名",
    centering: true,
  },
  {
    text: "経緯度座標",
    centering: true,
  },
  {
    text: "有効範囲",
    centering: true,
  },
];

const headersWithActions = [...headers, { text: "", centering: false }];

// hooks
type ModalState = {
  isOpen: boolean;
  building: Building | null;
};
const useBuildings = ({ sectionId }: Props) => {
  const { data, isLoading } = useFetchBuildingsApi({ sectionId });
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { mutate: deleteBuilding } = useDeleteBuildingApi({ sectionId });

  const [modalState, setModalState] = React.useState<ModalState>({
    isOpen: false,
    building: null,
  });

  const openNewModal = () => {
    setModalState({ isOpen: true, building: null });
  };

  const openEditModal = (building: Building) => {
    setModalState({ isOpen: true, building: building });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, building: null });
  };

  const onClickDelete = (building: Building) => {
    if (!window.confirm(`${building.name}を削除してよろしいですか？`)) {
      return;
    }

    deleteBuilding(building, {
      onSuccess() {
        showSuccessMessage("削除しました");
      },
      onError() {
        showErrorMessage("削除できませんでした");
      },
    });
  };

  return {
    data,
    isLoading,
    modalState,
    openNewModal,
    openEditModal,
    closeModal,
    onClickDelete,
  };
};

// テーブル
type BuildingsTableProps = {
  buildings: Building[];
  onClickEdit: (building: Building) => void;
  onClickDelete: (building: Building) => void;
};
const BuildingsTable = ({
  buildings,
  onClickEdit,
  onClickDelete,
}: BuildingsTableProps) => {
  return (
    <HorizontalScrollTable
      headers={buildings.length > 0 ? headersWithActions : headers}
      rows={buildings.map((building) => (
        <BuildingRow
          building={building}
          key={building.id}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ))}
      noRowsText="位置情報の登録がありません"
    />
  );
};

// 行
type BuildingRowProps = {
  building: Building;
  onClickEdit: (building: Building) => void;
  onClickDelete: (building: Building) => void;
};
const BuildingRow = ({
  building,
  onClickEdit,
  onClickDelete,
}: BuildingRowProps) => {
  return (
    <tr>
      <TableColumn centering>{building.name}</TableColumn>
      <TableColumn centering>
        {building.latitude}, {building.longitude}
      </TableColumn>
      <TableColumn centering>
        半径{building.correctionRange}メートル以内
      </TableColumn>
      <TableColumn>
        <Flex justifyContent="flex-end" marginTop="0">
          <InlineBlock marginLeft="0">
            <LinkButton
              onClick={() => {
                onClickEdit(building);
              }}
            >
              <Icon name="icon-edit" />
              編集
            </LinkButton>
          </InlineBlock>
          <InlineBlock marginLeft="2.4rem">
            <LinkButton
              onClick={() => {
                onClickDelete(building);
              }}
            >
              <Icon name="icon-trash" />
              削除
            </LinkButton>
          </InlineBlock>
        </Flex>
      </TableColumn>
    </tr>
  );
};
