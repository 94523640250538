import classNames from "classnames";
import { FieldProps } from "formik";
import * as React from "react";

import styles from "./styles.scss";

interface Props {
  hasError?: boolean;
  form?: FieldProps<any>["form"];
  field?: FieldProps<any>["field"];
  isBlock?: boolean;
  unit?: string;
}

const Input = (
  props: Props & React.InputHTMLAttributes<HTMLInputElement>,
): React.ReactElement => {
  return (
    <>
      {renderInput(props)}
      {props.unit ? <span className={styles.unit}>{props.unit}</span> : null}
    </>
  );
};

// InputLeftIconGroup
// 入力フォーム左にアイコンを表示する
//
// code:
// <InputLeftIconGroup>
//   <Icon />
//   <Input />
// </InputLeftIconGroup>
//
// layout:
// ------------------------------
// | Icon |        Input        |
// ------------------------------
type InputLeftIconProps = Props & React.InputHTMLAttributes<HTMLInputElement>;
export const InputLeftIconGroup = (props: InputLeftIconProps) => {
  return <div className={styles.inputLeftIconGroup}>{props.children}</div>;
};

const renderInput = (
  props: Props & React.InputHTMLAttributes<HTMLInputElement>,
) => {
  if (props.form && props.field) {
    const { hasError, form, field, type, className, isBlock, ...attributes } =
      props;
    const touched = form.touched[field.name] || false;
    const error = form.errors[field.name];
    const { value, ...fieldAttributes } = field;

    if (type === "file") {
      return (
        <input
          {...fieldAttributes}
          {...attributes}
          type={type}
          className={classNames(className, styles.root, {
            [styles.hasError]: hasError || (touched && error),
            [styles.fileInput]: type === "file",
            [styles.isBlock]: isBlock || isBlock === undefined,
          })}
        />
      );
    } else {
      return (
        <input
          {...fieldAttributes}
          {...attributes}
          value={value}
          type={type}
          className={classNames(className, styles.root, {
            [styles.hasError]: hasError || (touched && error),
            [styles.fileInput]: type === "file",
            [styles.isBlock]: isBlock || isBlock === undefined,
          })}
        />
      );
    }
  } else {
    const { hasError, type, className, isBlock, ...attributes } = props;

    return (
      <input
        {...attributes}
        type={type}
        className={classNames(className, styles.root, {
          [styles.hasError]: hasError,
          [styles.fileInput]: type === "file",
          [styles.isBlock]: isBlock || isBlock === undefined,
        })}
      />
    );
  }
};

export default Input;
