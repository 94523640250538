import { setupWorker } from "msw/browser";

// レスポンスを遅延させたいときはこれを使ってください
// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// モックしたい場合はhandlersの中身を以下の例のように書いてsetupWorkerに渡してください
// 例
// const handlers = [
//   http.get(
//     `${Settings.BORON_URL}/api/v1/sections/:sectionId/analytics/examinations`,
//     () => {
//       return HttpResponse.json(sectionExaminationsMock);
//     }
//   ),
// ];

// export const worker = setupWorker(...handlers);
export const worker = setupWorker();
