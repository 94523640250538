import * as React from "react";
import { connect, HandleThunkActionCreator } from "react-redux";

import {
  deleteSectionStay,
  getSectionStays,
  getSectionStaysInitial,
  interruptGetSectionStays,
  updateSectionStay,
} from "../../actions/sectionStay";
import SectionsSettingsStays from "../../components/organisms/SectionsSettingsStays";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import AppStateInterface from "../../interfaces/AppStateInterface";
import SectionInterface from "../../interfaces/SectionInterface";
import OperatorPrivilegesStateInterface from "../../interfaces/OperatorPrivilegesStateInterface";
import {
  SectionStaysStateInterface,
  StayFormInterface,
} from "../../interfaces/StayInterface";
import {
  OutletContextProps,
  useOnLocationChange,
  WithRouterProps,
} from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";
import { useOnMainScrollAreaScroll } from "../../hooks/useMainScrollAreaScroll";

interface Props
  extends WithRouterProps<AuthenticateRouterProps>,
    OutletContextProps {
  section: SectionInterface;
  sectionStaysState: SectionStaysStateInterface;
  getSectionStaysInitial: HandleThunkActionCreator<
    typeof getSectionStaysInitial
  >;
  getSectionStays: (sectionId: string, page: number) => void;
  interruptGetSectionStays: (sectionId: string, query: string) => void;
  updateSectionStay: (
    sectionId: string,
    stayId: string,
    params: StayFormInterface,
  ) => void;
  deleteSectionStay: (sectionId: string, stayId: string) => void;
  operatorPrivileges: OperatorPrivilegesStateInterface;
}

const SectionsSettingsStaysPage = (props: Props) => {
  const { section, sectionStaysState, updateSectionStay, deleteSectionStay } =
    props;

  React.useEffect(() => {
    props.getSectionStaysInitial(props.section.id, props.location.search);
    props.setActiveMenu("stays");
  }, []);

  useOnLocationChange(() => {
    if (location.search) {
      const queryString = location.search.toString();
      const sectionId = props.params.sectionId;
      props.interruptGetSectionStays(sectionId, queryString);
    }
  });

  useOnMainScrollAreaScroll(() => {
    if (!props.sectionStaysState.loading && props.sectionStaysState.hasMore) {
      props.getSectionStays(
        props.section.id,
        props.sectionStaysState.meta.currentPage + 1,
      );
    }
  }, [props.sectionStaysState.loading, props.sectionStaysState.hasMore]);

  return (
    <SectionsSettingsStays
      section={section}
      sectionStaysState={sectionStaysState}
      updateSectionStay={updateSectionStay}
      deleteSectionStay={deleteSectionStay}
      privileges={props.operatorPrivileges.data?.privileges || []}
    />
  );
};

const pageInfo = {
  title: "入退室履歴",
};

const mapStateToProps = (state: AppStateInterface) => ({
  section: state.currentSection,
  sectionStaysState: state.sectionStaysState,
});

const actions = {
  getSectionStaysInitial,
  getSectionStays,
  interruptGetSectionStays,
  updateSectionStay,
  deleteSectionStay,
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsStaysPage,
  pageInfo,
  ["stay:index"],
);

export default connect(mapStateToProps, actions)(EnhancedPage as any);
