import OperatorInterface from "../../../interfaces/OperatorInterface";
import { Actions as UserInfoActions } from "../../authorization/userInfo";
import AuthenticationsStateInterface from "../../../interfaces/AuthenticationsStateInterface";

export enum ActionTypes {
  SIGN_OUT = "authorization/SIGN_OUT",
  SWITCH_OPERATOR = "authorization/SWITCH_OPERATOR",
  SWITCH_OPERATOR_SUCCESS = "authorization/SWITCH_OPERATOR_SUCCESS",
  SWITCH_OPERATOR_WITH_REDIRECT_SUCCESS = "authorization/SWITCH_OPERATOR_WITH_REDIRECT_SUCCESS",
  UPDATE_OPERATOR_INFO = "authorization/UPDATE_OPERATOR_INFO",
  UNAUTHORIZED_ERROR = "authorization/UNAUTHORIZED_ERROR",
  UPDATE_USER_CONSENT_AGREED_SUCCESS = "UPDATE_USER_CONSENT_AGREED_SUCCESS",
  RESET_BACK_TO_URL = "RESET_BACK_TO_URL",
}

export type Action =
  | SignOut
  | SwitchOperator
  | SwitchOperatorSuccess
  | SwitchOperatorWithRedirectSuccess
  | UpdateOperatorInfo
  | UnauthorizedError
  | UserInfoActions
  | UpdateUserConsentAgreed
  | ResetBackToUrl;

interface SignOut {
  type: ActionTypes.SIGN_OUT;
  payload: AuthenticationsStateInterface;
}
interface SwitchOperator {
  type: ActionTypes.SWITCH_OPERATOR;
}
interface SwitchOperatorSuccess {
  type: ActionTypes.SWITCH_OPERATOR_SUCCESS;
  payload: AuthenticationsStateInterface;
}
interface SwitchOperatorWithRedirectSuccess {
  type: ActionTypes.SWITCH_OPERATOR_WITH_REDIRECT_SUCCESS;
  payload: AuthenticationsStateInterface;
}
interface UpdateOperatorInfo {
  type: ActionTypes.UPDATE_OPERATOR_INFO;
  payload: OperatorInterface;
}
interface UnauthorizedError {
  type: ActionTypes.UNAUTHORIZED_ERROR;
}

interface UpdateUserConsentAgreed {
  type: ActionTypes.UPDATE_USER_CONSENT_AGREED_SUCCESS;
}

interface ResetBackToUrl {
  type: ActionTypes.RESET_BACK_TO_URL;
}
