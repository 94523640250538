import styles from "./styles.scss";
import MessageList from "../../../../components/molecules/MessageList";
import MessageHeader from "../../../../components/molecules/MessageHeader";
import DirectMessageForm from "./DirectMessageForm";
import SplitLayout from "../../../../components/atoms/SplitLayout";
import OperatorProfileInterface from "../../../../interfaces/OperatorProfileInterface";
import OperatorInterface from "../../../../interfaces/OperatorInterface";
import { boronClient } from "../../../../api";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { HTTP_ERROR_MESSAGE } from "../../../../reducers";
import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { FSHTTPError } from "../../../../errors";
import MessageThreadInterface from "../../../../interfaces/MessageThreadInterface";
import { useUpdateMessageThreadLatestMessage } from "../useFetchMessageThreads";
import {
  useFetchMessages,
  useUpdateBotMessageCache,
  useUpdateDirectMessageCache,
} from "./useFetchMessages";
import StudentInterface from "../../../../interfaces/StudentInterface";
import { useQueryError } from "../../../../hooks/http/useQueryError";

interface Props {
  sectionId: string;
  messageThread: MessageThreadInterface | null;
  onCloseRightColumn?: (e: any) => void;
  operator: OperatorInterface;
  operatorProfile: OperatorProfileInterface | null;
}

const SectionStudentMessage = (props: Props) => {
  const { follow, unfollow } = useFollowThread({
    sectionId: props.sectionId,
    messageThread: props.messageThread,
  });

  if (!props.messageThread || !props.operatorProfile) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SplitLayout.Header>
        <MessageHeader
          student={props.messageThread.student}
          isFollowing={!!props.messageThread.isFollowing}
          onClose={props.onCloseRightColumn}
          onFollow={follow}
          onUnfollow={unfollow}
          showCloseIcon={true}
        />
      </SplitLayout.Header>
      <SplitLayout.Body>
        <StudentMessageList
          sectionId={props.sectionId}
          student={props.messageThread.student}
          messageThread={props.messageThread}
        />
        {
          <DirectMessageForm
            sectionId={props.sectionId}
            messageThread={props.messageThread}
            operator={props.operator}
            operatorProfile={props.operatorProfile}
          />
        }
      </SplitLayout.Body>
    </div>
  );
};

const StudentMessageList = ({
  sectionId,
  student,
  messageThread,
}: {
  sectionId: string;
  student: Omit<StudentInterface, "billingPlan">;
  messageThread: MessageThreadInterface;
}) => {
  const { isLoading, error, isFetchingNextPage, fetchNextPage, messages } =
    useFetchMessages({
      studentId: student.id,
    });
  useQueryError(error);

  const deleteMessage = useDeleteMessage({
    sectionId: sectionId,
    messageThread: messageThread,
  });

  const { changeBotMessageStatusDone, changeBotMessageStatusIgnored } =
    useChangeBotMessageStatus();

  const handleLoadMore = () => {
    fetchNextPage();
  };

  return (
    <MessageList
      student={student}
      messages={messages}
      isLoading={isLoading || isFetchingNextPage}
      loadMore={handleLoadMore}
      changeBotMessageStatusDone={changeBotMessageStatusDone}
      changeBotMessageStatusIgnored={changeBotMessageStatusIgnored}
      deleteMessage={deleteMessage}
    />
  );
};

const useDeleteMessage = ({
  sectionId,
  messageThread,
}: {
  sectionId: string;
  messageThread: MessageThreadInterface | null;
}) => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { updateMessageThreadLatestMessage } =
    useUpdateMessageThreadLatestMessage({
      sectionId,
    });

  const { updateDirectMessageCache } = useUpdateDirectMessageCache();

  const { mutate } = useBoronMutation(
    async ({
      studentId,
      messageId,
    }: {
      studentId: string;
      messageId: string;
    }) =>
      boronClient.DELETE(
        "/api/v1/students/{student_id}/direct_messages/{message_id}/delete",
        {
          params: {
            path: { student_id: studentId, message_id: messageId },
          },
        },
      ),
    {
      onSuccess: (response) => {
        if (!messageThread) {
          return;
        }

        updateDirectMessageCache(messageThread.student.id, response.message);
        if (
          messageThread.latestMessage &&
          messageThread.latestMessage.id === response.message.id
        ) {
          updateMessageThreadLatestMessage(messageThread.id, {
            latestMessage: response.message,
          });
        }
        showSuccessMessage("送信したメッセージを削除しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("削除できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  return (studentId: string, messageId: string) => {
    mutate({ studentId, messageId });
  };
};

const useChangeBotMessageStatus = () => {
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const { updateBotMessageCache } = useUpdateBotMessageCache();

  const { mutate } = useBoronMutation(
    async ({
      studentId,
      messageId,
      status,
    }: {
      studentId: string;
      messageId: string;
      status: "done" | "ignored";
    }) =>
      boronClient.PUT(
        `/api/v1/students/{student_id}/bot_messages/{bot_message_hashid}/${status}`,
        {
          params: {
            path: { student_id: studentId, bot_message_hashid: messageId },
          },
        },
      ),
    {
      onSuccess: () => {
        showSuccessMessage("対応状況を変更しました");
      },
      onError: (error) => {
        if (error instanceof FSHTTPError) {
          showErrorMessage("対応状況を変更できませんでした");
        } else {
          showErrorMessage(HTTP_ERROR_MESSAGE);
        }
      },
    },
  );

  const changeBotMessageStatusDone = (studentId: string, messageId: string) => {
    mutate(
      { status: "done", studentId, messageId },
      {
        onSuccess: () =>
          updateBotMessageCache(studentId, { id: messageId, status: "done" }),
      },
    );
  };

  const changeBotMessageStatusIgnored = (
    studentId: string,
    messageId: string,
  ) => {
    mutate(
      { status: "ignored", studentId, messageId },
      {
        onSuccess: () =>
          updateBotMessageCache(studentId, {
            id: messageId,
            status: "ignored",
          }),
      },
    );
  };

  return { changeBotMessageStatusDone, changeBotMessageStatusIgnored };
};

const useFollowThread = ({
  sectionId,
  messageThread,
}: {
  sectionId: string;
  messageThread: MessageThreadInterface | null;
}) => {
  const { showErrorMessage } = useFlashMessage();

  const { updateMessageThreadLatestMessage } =
    useUpdateMessageThreadLatestMessage({
      sectionId,
    });

  const { mutate: mutateFollow } = useBoronMutation(
    async ({ studentId }: { studentId: string }) =>
      await boronClient.POST(
        "/api/v1/students/{student_id}/direct_messages/follow",
        {
          params: {
            path: { student_id: studentId },
          },
        },
      ),
    {
      onSuccess: () => {
        if (!messageThread) {
          return;
        }
        updateMessageThreadLatestMessage(messageThread.id, {
          isFollowing: true,
        });
      },
      onError: () => showErrorMessage(HTTP_ERROR_MESSAGE),
    },
  );

  const { mutate: mutateUnfollow } = useBoronMutation(
    async ({ studentId }: { studentId: string }) =>
      await boronClient.DELETE(
        "/api/v1/students/{student_id}/direct_messages/follow",
        {
          params: {
            path: { student_id: studentId },
          },
        },
      ),
    {
      onSuccess: () => {
        if (!messageThread) {
          return;
        }
        updateMessageThreadLatestMessage(messageThread.id, {
          isFollowing: false,
        });
      },
      onError: () => showErrorMessage(HTTP_ERROR_MESSAGE),
    },
  );

  const follow = () => {
    if (!messageThread) {
      return;
    }

    if (window.confirm("通知をオンにしますか？")) {
      mutateFollow({ studentId: messageThread.student.id });
    }
  };

  const unfollow = () => {
    if (!messageThread) {
      return;
    }

    if (window.confirm("通知を解除しますか？")) {
      mutateUnfollow({ studentId: messageThread.student.id });
    }
  };

  return {
    follow,
    unfollow,
  };
};

export default SectionStudentMessage;
