import { GuardianMessageWithStudentInterface } from "../../../interfaces/GuardianMessageInterface";
import HeaderNotificationEmptyList from "../../atoms/HeaderNotificationEmptyList";
import HeaderNotificationList from "../../atoms/HeaderNotificationList";
import HeaderNotificationLoading from "../../atoms/HeaderNotificationLoading";
import ListItem from "./ListItem";

interface Props {
  loading: boolean;
  messages: GuardianMessageWithStudentInterface[];
}

const renderItem = (message: GuardianMessageWithStudentInterface) => {
  return (
    <ListItem
      message={message}
      key={`header-message-notification-item-${message.id}`}
    />
  );
};

const renderList = (messages: GuardianMessageWithStudentInterface[]) => {
  return (
    <HeaderNotificationList>
      {messages.map((message: GuardianMessageWithStudentInterface) => {
        return renderItem(message);
      })}
    </HeaderNotificationList>
  );
};

const List = (props: Props) => {
  if (props.loading) {
    return <HeaderNotificationLoading />;
  } else if (props.messages.length <= 0) {
    return <HeaderNotificationEmptyList label="メッセージはありません" />;
  } else {
    return renderList(props.messages);
  }
};

export default List;
