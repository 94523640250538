import ErrorsStateInterface from "../../interfaces/ErrorsStateInterface";

const defaultState = {
  unauthorized: false,
  forbidden: false,
  notFound: false,
  steakError: null,
  maintenanceError: null,
  unknownError: false,
};

// ページ単位のエラー
const errors = (
  state: ErrorsStateInterface = defaultState,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "UNAUTHORIZED_ERROR":
      return {
        ...state,
        unauthorized: true,
      };
    case "NOT_FOUND_ERROR":
      return {
        ...state,
        notFound: true,
      };
    case "FORBIDDEN_ERROR":
      return {
        ...state,
        forbidden: true,
      };
    case "STEAK_ERROR":
      return {
        ...state,
        steakError: action.payload,
      };
    case "MAINTENANCE_ERROR":
      return {
        ...state,
        maintenanceError: action.payload,
      };
    case "UNKNOWN_ERROR":
      return {
        ...state,
        unknownError: true,
      };
    case "RESET_PAGE_ERRORS":
      return defaultState;
    default:
      return state;
  }
};

export default errors;
