import { HTTP_ERROR_MESSAGE } from "..";
import { SectionStateInterface } from "../../interfaces/SectionInterface";

const defaultCurrentSection = {
  data: null,
};

export const currentSection = (
  state: SectionStateInterface = defaultCurrentSection,
  action: ReduxActions.Action<any>,
) => {
  switch (action.type) {
    case "GET_SECTION_RESPONSE_RECEIVED":
      return {
        data: action.payload.section,
      };
    case "GET_SECTION_RESPONSE_ERROR":
      if (!action.payload || !action.payload.status) {
        alert(HTTP_ERROR_MESSAGE);
      }

      return state;

    case "UPDATE_SECTION_GUARDIAN_MESSAGE_SETTING_RECEIVED": {
      const { paramName, value } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [paramName]: value,
        },
      };
    }

    case "UPDATE_SECTION_GUARDIAN_MESSAGE_SETTING_ERROR":
    default:
      return state;
  }
};
