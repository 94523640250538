import * as React from "react";
import styles from "./styles.scss";
import OperatorInterface, {
  ActiveOperatorInterface,
  OperatorRoleTypes,
} from "../../../interfaces/OperatorInterface";
import Icon from "../../atoms/Icon/index";
import Select, { OnChangeValue } from "react-select";
import OperatorTagSwitch from "../OperatorTagSwitch/index";
import StudentTag from "../../../domains/StudentTag";
import SectionInterface from "../../../interfaces/SectionInterface";

interface Props {
  section: SectionInterface;
  currentOperator: OperatorInterface;
  sectionTags: StudentTag[];
  submitting: boolean;
  activeOperators: ActiveOperatorInterface[];
  deleteOperator: (sectionId: string, operatorId: string) => void;
  changeOperatorRole: (
    sectionId: string,
    operatorId: string,
    role: OperatorRoleTypes,
  ) => void;
  updateOperatorSectionTags: (
    sectionId: string,
    operatorId: string,
    tag_ids: string[],
  ) => void;
}

class ActiveOperatorList extends React.Component<
  Props,
  Record<string, unknown>
> {
  render(): React.ReactNode {
    if (!this.props.activeOperators || this.props.activeOperators.length == 0) {
      return null;
    }
    return (
      <div className={styles.root}>
        <div className={styles.label}>アクティブスタッフ</div>
        {this.renderTable()}
      </div>
    );
  }

  private renderTable = () => {
    return (
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeader}>
              <th>氏名</th>
              <th>メールアドレス</th>
              <th>権限</th>
              <th>閲覧制限タグ</th>
              <th>Zoom連携</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.renderList()}</tbody>
        </table>
      </div>
    );
  };

  private renderList = () => {
    return this.props.activeOperators.map(
      (operator: ActiveOperatorInterface) => {
        return (
          <tr key={`activeOperator-${operator.id}`} className={styles.tableRow}>
            <td>{this.renderOperatorFullName(operator)}</td>
            <td>{operator.email}</td>
            <td>{this.renderChangeRoleOppositeSelect(operator)}</td>
            <td>{this.renderGiveOperatorSectionTagForm(operator)}</td>
            <td>{this.renderZoomConnected(operator)}</td>
            <td>{this.renderDeleteOperatorLink(operator)}</td>
          </tr>
        );
      },
    );
  };

  private renderOperatorFullName = (operator: ActiveOperatorInterface) => {
    return (
      <div className={styles.operatorName}>
        <Icon name="icon-account" className={styles.iconsvg} />
        {operator.fullName}
      </div>
    );
  };

  private renderChangeRoleOppositeSelect = (
    operator: ActiveOperatorInterface,
  ) => {
    if (operator.id === this.props.currentOperator.id) return null;

    const options = [
      { value: "admin", label: "管理者" },
      { value: "member", label: "メンバー" },
    ];
    return (
      <Select
        className={styles.select}
        options={options}
        isClearable={false}
        isSearchable={false}
        isLoading={operator.submitting}
        isDisabled={operator.submitting}
        value={options.find((option) => option.label == operator.role)}
        onChange={this.handleSelectRole(operator.id)}
      />
    );
  };

  private renderGiveOperatorSectionTagForm = (
    operator: ActiveOperatorInterface,
  ) => {
    if (operator.isAdmin || operator.id === this.props.currentOperator.id) {
      return <p>ー</p>;
    } else {
      return (
        <OperatorTagSwitch
          operator={operator}
          sectionId={this.props.section.id}
          sectionTags={this.props.sectionTags}
          updateOperatorSectionTags={this.props.updateOperatorSectionTags}
        />
      );
    }
  };

  private renderDeleteOperatorLink = (operator: ActiveOperatorInterface) => {
    if (operator.isAdmin || operator.id === this.props.currentOperator.id) {
      return null;
    } else {
      return (
        <button
          className={styles.deleteLink}
          onClick={this.handleDeleteOperator(operator)}
        >
          <Icon name="icon-trash" className={styles.svg} />
          <p>削除</p>
        </button>
      );
    }
  };

  private renderZoomConnected = (operator: ActiveOperatorInterface) => {
    return operator.zoomConnected ? (
      <div className={styles.zoomConnected}>
        <Icon name="icon-zoom" className={styles.svg} />
        <p>連携中</p>
      </div>
    ) : (
      <p>ー</p>
    );
  };

  private handleSelectRole =
    (operatorId: string) => (value: OnChangeValue<any, any>) => {
      this.props.changeOperatorRole(
        this.props.section.id,
        operatorId,
        value.value,
      );
    };

  private handleDeleteOperator = (operator: ActiveOperatorInterface) => () => {
    if (
      !operator.submitting &&
      window.confirm(
        `${operator.fullName}さんの${this.props.section.fullName}へのスタッフ登録を削除してよろしいですか？`,
      )
    ) {
      this.props.deleteOperator(this.props.section.id, operator.id);
    }
  };
}

export default ActiveOperatorList;
