import * as React from "react";
import styles from "./styles.scss";

type WidthType = "narrow" | "wide";

interface Props extends React.PropsWithChildren<unknown> {
  widthType?: WidthType;
}

const widthClassName = (widthType?: WidthType) => {
  switch (widthType) {
    case "narrow":
      return styles.narrow;
    case "wide":
      return styles.wide;
    default:
      return styles.wide;
  }
};

const HeaderPopup = (props: Props) => {
  return (
    <div className={`${styles.root} ${widthClassName(props.widthType)}`}>
      {props.children}
    </div>
  );
};

export default HeaderPopup;
