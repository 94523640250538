import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../errors";
import ApiResponse from "../../interfaces/ApiResponse";
import ApiClient from "../../api";
import { StayStateInterface } from "../../interfaces/StayInterface";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { useOnMainScrollAreaScroll } from "../useMainScrollAreaScroll";
import { useMemo } from "react";

type Props = {
  sectionId: string;
};
type ResponseBody = ApiResponse<StayStateInterface[]>;
export const useFetchSectionStayingStudents = (props: Props) => {
  const path = baseUrl(props.sectionId);
  const result = useInfiniteQuery<ResponseBody, HTTPErrors>({
    queryKey: [path],
    queryFn: async ({ pageParam }) => {
      const response = await ApiClient.get(path, {
        query: { page: pageParam },
      });
      if (response.ok) {
        const json = await response.json();
        return json.stays as ResponseBody;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });

  useOnMainScrollAreaScroll(() => {
    if (!result.isLoading && !result.isFetchingNextPage && result.hasNextPage) {
      result.fetchNextPage();
    }
  }, [result.hasNextPage, result.isLoading, result.isFetchingNextPage]);

  const data = useMemo(
    () => result.data?.pages.flatMap((page) => page.data),
    [result],
  );
  return { ...result, data };
};

const baseUrl = (sectionId: string) =>
  `/api/v1/sections/${sectionId}/stays/staying_students`;
