import * as React from "react";

import Loader from "../../../components/atoms/Loader";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import enhanceSectionsSettingsPage, {
  SectionsSettingsPageProps,
} from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";

import { ReceiveGuardianMessageToggleForm } from "./ReceiveGuardianMessageToggleForm";

type Props = SectionsSettingsPageProps &
  OutletContextProps & {
    section: SectionInterface;
  };

const SectionsSettingsGuardianMessagePage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("guardian_message");
  }, []);

  if (!props.section) {
    return <Loader loading={true} />;
  }

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "保護者連絡",
            link: "#",
            active: true,
          },
        ]}
      />
      <ReceiveGuardianMessageToggleForm section={props.section} />
    </>
  );
};

const pageInfo = {
  title: "保護者連絡",
};

export default enhanceSectionsSettingsPage(
  SectionsSettingsGuardianMessagePage,
  pageInfo,
  ["guardian_message:settings"],
);
