import * as React from "react";

// ref: https://github.com/remix-run/react-router/blob/v6.4.0-pre.2/packages/react-router-dom/index.tsx
export const useBeforeUnload = (
  callback: (e: BeforeUnloadEvent) => void,
): void => {
  React.useEffect(() => {
    window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [callback]);
};
