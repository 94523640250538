import * as React from "react";
import { Schedule, ScheduleForStudent } from "../../../domains/Schedule";

// 一度フォームを通してから実行されるアクションはSchedule型
// - 生徒画面から操作できる予定は校舎予定ではない
// - フォームから操作できるものの対象に校舎予定情報を含むことはない
type Detail = { type: "Detail"; schedule: ScheduleForStudent };
type Form = { type: "Form"; schedule?: Schedule };
type Close = { type: "Close" };
type ConfirmReccurence = {
  type: "UpdateConfirmFromDragAndDrop";
  schedule: ScheduleForStudent;
};
type UpdateConfirmFromForm = {
  type: "UpdateConfirmFromForm";
  schedule: Schedule;
};
type UpdateConfirmToDelete = {
  type: "UpdateConfirmToDelete";
  schedule: ScheduleForStudent;
};

export type ModalMode =
  | Detail
  | Form
  | Close
  | ConfirmReccurence
  | UpdateConfirmFromForm
  | UpdateConfirmToDelete;

export type UseScheduleModalProps = {
  mode: ModalMode;
  onClose: () => void;
};

export const isOpen = (model: ModalMode): boolean => model.type !== "Close";

export type UseModalStateProps = {
  defaultModalMode?: ModalMode;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useModalModeState = (props?: UseModalStateProps) => {
  const [modalMode, setModalMode] = React.useState<ModalMode>(
    props?.defaultModalMode ?? { type: "Close" },
  );

  const openDetailModal = React.useCallback(
    (schedule: ScheduleForStudent | null) => {
      if (schedule) {
        setModalMode({ type: "Detail", schedule });
      }
    },
    [],
  );
  const closeModal = React.useCallback(
    () => setModalMode({ type: "Close" }),
    [],
  );
  const openAddEditModal = React.useCallback((schedule?: Schedule) => {
    setModalMode({ type: "Form", ...(schedule ? { schedule } : {}) });
  }, []);

  const openUpdateConfirmFromDragAndDrop = React.useCallback(
    (schedule: ScheduleForStudent) =>
      setModalMode({ type: "UpdateConfirmFromDragAndDrop", schedule }),
    [],
  );

  const openUpdateConfirmFromForm = React.useCallback((schedule: Schedule) => {
    setModalMode({ type: "UpdateConfirmFromForm", schedule });
  }, []);

  const openUpdateConfirmToDelete = React.useCallback(
    (schedule: ScheduleForStudent) => {
      setModalMode({ type: "UpdateConfirmToDelete", schedule });
    },
    [],
  );

  return {
    modalMode,
    openDetailModal,
    closeModal,
    openAddEditModal,
    openUpdateConfirmFromDragAndDrop,
    openUpdateConfirmFromForm,
    openUpdateConfirmToDelete,
  };
};

export type UseScheduleModalState = ReturnType<typeof useModalModeState>;
