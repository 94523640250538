import * as React from "react";
import styles from "./styles.scss";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import OperatorProfileNameForm from "../../molecules/OperatorProfileNameForm";
import OperatorProfileEmailForm from "../../molecules/OperatorProfileEmailForm";
import OperatorProfilePasswordForm from "../../molecules/OperatorProfilePasswordForm";
import BackLink from "../../atoms/BackLink/index";
import { OperatorSettingsInterface } from "../../../interfaces/OperatorSettingsInterface";
import {
  updateOperatorEmail,
  updateOperatorName,
  updateOperatorPassword,
  loadOperatorProfile,
  connectZoom,
  disconnectZoom,
  connectZoomError,
  updateZoomMeetingSetting,
  connectGoogleClassroomError,
  connectGoogleClassroom,
  disconnectGoogleClassroom,
} from "../../../actions/pages/SettingsProfilePage/index";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import OperatorZoomForm from "../../molecules/OperatorZoomForm";
import OperatorGoogleClassroomForm from "../../molecules/OperatorGoogleClassroomForm";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import { useEffect } from "react";
import Loader from "../../atoms/Loader";
import * as queryString from "query-string";
import { WithRouterProps, withRouter } from "../../../helpers/RouterHelper";

interface DispatchProps {
  updateOperatorEmail: HandleThunkActionCreator<typeof updateOperatorEmail>;
  updateOperatorName: HandleThunkActionCreator<typeof updateOperatorName>;
  updateOperatorPassword: HandleThunkActionCreator<
    typeof updateOperatorPassword
  >;
  loadOperatorProfile: HandleThunkActionCreator<typeof loadOperatorProfile>;
  connectZoom: HandleThunkActionCreator<typeof connectZoom>;
  connectZoomError: HandleThunkActionCreator<typeof connectZoomError>;
  disconnectZoom: HandleThunkActionCreator<typeof disconnectZoom>;
  updateZoomMeetingSetting: HandleThunkActionCreator<
    typeof updateZoomMeetingSetting
  >;
  connectGoogleClassroom: HandleThunkActionCreator<
    typeof connectGoogleClassroom
  >;
  connectGoogleClassroomError: HandleThunkActionCreator<
    typeof connectGoogleClassroomError
  >;
  disconnectGoogleClassroom: HandleThunkActionCreator<
    typeof disconnectGoogleClassroom
  >;
}

interface Props extends DispatchProps, WithRouterProps {
  operator: OperatorInterface;
  operatorSettings: OperatorSettingsInterface;
  operatorProfile: OperatorProfileInterface;
}

const switchZoomConnection =
  (props: Props) =>
  (value: boolean): Promise<boolean> => {
    if (value) {
      props.connectZoom(props.operator.id);
    } else if (confirm("Zoomとの連携を本当に解除してよろしいですか？")) {
      props.disconnectZoom();
    }

    return Promise.resolve(true);
  };

const switchGoogleClassroomConnection =
  (props: Props) =>
  (value: boolean): Promise<boolean> => {
    if (value && !props.operatorSettings.submitting) {
      props.connectGoogleClassroom(props.operator.id);
    } else if (
      confirm(
        "Google Classroomとの連携を本当に解除してよろしいですか？連携解除を実行すると、本アカウントで同期を実行した全てのGoogle ClassroomクラスとStudyplus for Schoolの同期が停止されます。",
      )
    ) {
      props.disconnectGoogleClassroom();
    }

    return Promise.resolve(true);
  };

const SettingsProfileEdit = (props: Props) => {
  useEffect(() => {
    props.loadOperatorProfile();
    queryString.parse(props.location.search).auth_zoom === "failed" &&
      props.connectZoomError();
    queryString.parse(props.location.search).auth_google == "failed" &&
      props.connectGoogleClassroomError();
  }, []);

  const {
    submitting,
    nameApiErrors,
    emailApiErrors,
    passwordApiErrors,
    operatorProfile,
  } = props.operatorSettings;

  if (!operatorProfile) {
    return <Loader loading={true} />;
  }

  return (
    <React.Fragment>
      <div className={styles.backlink}>
        <BackLink path="/sections/">校舎一覧へ</BackLink>
      </div>
      <div className={styles.root}>
        <h2 className={styles.title}>アカウント設定</h2>
        <div>
          <OperatorProfileNameForm
            operator={props.operator}
            submitting={submitting}
            apiErrors={nameApiErrors}
            updateName={props.updateOperatorName}
          />
          <OperatorProfileEmailForm
            operator={props.operator}
            submitting={submitting}
            apiErrors={emailApiErrors}
            updateEmail={props.updateOperatorEmail}
          />
          <OperatorProfilePasswordForm
            submitting={submitting}
            apiErrors={passwordApiErrors}
            updatePassword={props.updateOperatorPassword}
          />
          <h2 className={styles.title}>Zoom連携</h2>
          <p className={styles.description}>
            Zoom連携をするとメッセージ画面でZoom
            URLの自動発行・送付ができるようになります。
          </p>
          <OperatorZoomForm
            connected={operatorProfile.zoomConnected}
            email={operatorProfile.zoomProfile?.email}
            zoomMeetingSetting={operatorProfile.zoomMeetingSetting}
            onSwitch={switchZoomConnection(props)}
            updateZoomMeetingSetting={props.updateZoomMeetingSetting}
            submitting={props.operatorSettings.submitting}
            meetingSettingErrors={props.operatorSettings.meetingSettingErrors}
          />
          <h2 className={styles.title}>Google Classroom連携</h2>
          <p className={styles.description}>
            Google
            Classroom連携をすると生徒から提出された課題がタイムラインに投稿されるようになります。
          </p>
          <OperatorGoogleClassroomForm
            connected={operatorProfile.googleClassroomConnected}
            googleUserProfile={operatorProfile.googleUserProfile}
            onSwitch={switchGoogleClassroomConnection(props)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppStateInterface, ownProps: any) => ({
  operator: ownProps.operator,
  operatorSettings: state.operatorSettings,
});

const actions = {
  updateOperatorEmail,
  updateOperatorName,
  updateOperatorPassword,
  loadOperatorProfile,
  connectZoom,
  connectZoomError,
  disconnectZoom,
  updateZoomMeetingSetting,
  connectGoogleClassroom,
  connectGoogleClassroomError,
  disconnectGoogleClassroom,
};

export default withRouter<Props>(
  connect(mapStateToProps, actions)(SettingsProfileEdit),
);
