import * as React from "react";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../interfaces/SectionInterface";
import SectionsSettingsKarteTemplatesEdit from "../../components/organisms/SectionsSettingsKarteTemplatesEdit";
import { connect } from "react-redux";
import { getSectionsKarteTemplate } from "../../actions/sectionsKarteTemplates";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../helpers/RouterHelper";

interface Props
  extends WithRouterProps<{ karteTemplateId: string }>,
    OutletContextProps {
  section: SectionInterface;
  getKarteTemplate: (sectionId: string, karteTemplateId: string) => void;
}

class SectionsSettingsKarteTemplatesEditPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { karteTemplateId } = this.props.params;
    props.getKarteTemplate(props.section.id, karteTemplateId);
    props.setActiveMenu("template");
  }

  render() {
    return <SectionsSettingsKarteTemplatesEdit section={this.props.section} />;
  }
}

const pageInfo = {
  title: "テンプレート",
};

const actions = {
  getKarteTemplate: getSectionsKarteTemplate,
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsKarteTemplatesEditPage,
  pageInfo,
);

export default connect(null, actions)(EnhancedPage as any);
