import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../../../components/atoms/Icon";
import Table from "../../../../components/atoms/Table/index";
import { TableColumn } from "../../../../components/atoms/Table/index";
import TimeHelper from "../../../../helpers/TimeHelper";
import AlignMiddle from "../../../../components/atoms/AlignMiddle/index";
import LinkButton from "../../../../components/atoms/LinkButton/index";
import { BlockRow } from "../../../../components/atoms/BlockRow";
import {
  Content,
  formatContentType,
  formatFileSize,
  isEditable as isEditableContent,
  isVideoContent,
} from "../../../../domains/Content";
import { useNavigate } from "react-router-dom";
import SectionInterface from "../../../../interfaces/SectionInterface";

type Props = {
  sectionId: SectionInterface["id"];
  contents: Content[];
  isMutating: boolean;
  onDelete: (content: Content) => void;
};
export const ContentIndex = (props: Props) => {
  return (
    <div className={styles.root}>
      <ContentTable {...props} />
    </div>
  );
};

const ContentTable = ({ sectionId, contents, isMutating, onDelete }: Props) => {
  return (
    <Table
      headers={[
        { key: "name", text: "コンテンツ名", centering: false },
        { key: "fileSize", text: "サイズ", centering: true },
        { key: "contentType", text: "種類", centering: true },
        { key: "updatedAt", text: "更新日", centering: true },
        { key: "edit", text: "", centering: false },
        { key: "delete", text: "", centering: false },
      ]}
      rows={
        contents.length > 0
          ? contents.map((contents) => (
              <TableRow
                sectionId={sectionId}
                content={contents}
                isMutating={isMutating}
                onDelete={onDelete}
                key={`content-table-row-${contents.id}`}
              />
            ))
          : [<EmptyTableRow key={"content-table-row-empty"} />]
      }
      headColumnClassName={""}
      className={styles.table}
    />
  );
};

const EmptyTableRow = () => {
  return (
    <tr className={styles.row}>
      <TableColumn
        colSpan={6}
        noWrap
        centering
        className={styles.emptyTableRow}
      >
        動画またはファイルを登録すると、コースの作成時に該当データの配信設定ができます。
      </TableColumn>
    </tr>
  );
};

type RowProps = {
  sectionId: SectionInterface["id"];
  content: Content;
  isMutating: boolean;
  onDelete: (content: Content) => void;
};
const TableRow = ({ sectionId, content, isMutating, onDelete }: RowProps) => {
  const navigate = useNavigate();
  const isEditable = isEditableContent(content);
  const editUrl = isVideoContent(content)
    ? `/sections/${sectionId}/settings/content_videos/${content.id}/edit`
    : `/sections/${sectionId}/settings/content_files/${content.id}/edit`;
  const handleDelete = React.useCallback(() => {
    onDelete(content);
  }, [content, onDelete]);

  return (
    <tr key={`content-${content.id}`} className={styles.row}>
      <TableColumn className={styles.contentNameColumn}>
        <BlockRow>
          {content.url ? (
            <a
              href={content.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.contentNameColumn__name}
            >
              {content.name}
            </a>
          ) : (
            <span className={styles.contentNameColumn__name}>
              {content.name}
            </span>
          )}
        </BlockRow>
        {content.availableStartAt && content.availableEndAt ? (
          <BlockRow marginTop="0.8rem">
            <span>利用期間：</span>
            <span className={styles.contentName__durationValue}>
              {`${TimeHelper.fullFormat(
                content.availableStartAt,
              )} 〜 ${TimeHelper.fullFormat(content.availableEndAt)}`}
            </span>
          </BlockRow>
        ) : null}
      </TableColumn>
      <TableColumn noWrap centering>
        {content.fileSize ? formatFileSize(content.fileSize) : null}
      </TableColumn>
      <TableColumn noWrap centering>
        {formatContentType(content.contentType, content.ltiToolName)}
      </TableColumn>
      <TableColumn noWrap centering>
        {TimeHelper.fullFormat(content.updatedAt)}
      </TableColumn>
      <TableColumn noWrap bold centering className={styles.editColumn}>
        {isEditable && (
          <LinkButton
            onClick={() => navigate(editUrl)}
            disabled={isMutating}
            bold
          >
            <AlignMiddle>
              <Icon name="icon-edit" />
              <span>編集</span>
            </AlignMiddle>
          </LinkButton>
        )}
      </TableColumn>
      <TableColumn noWrap bold centering className={styles.deleteColumn}>
        <LinkButton onClick={handleDelete} disabled={isMutating} bold>
          <AlignMiddle>
            <Icon name="icon-trash" />
            <span>削除</span>
          </AlignMiddle>
        </LinkButton>
      </TableColumn>
    </tr>
  );
};
