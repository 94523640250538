import { DirectMessage } from "../../../interfaces/MessageInterface";
import BotMessageInterface from "../../../interfaces/BotMessageInterface";
import { CursorMetaData } from "../../../interfaces/ApiResponse";
import { Action } from "redux";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";

export enum ActionTypes {
  // メッセージ読込
  INITIAL_GET_REQUEST = "GET_STUDENT_MESSAGES_REQUEST_SENDING",
  GET_REQUEST = "GET_INITIAL_STUDENT_MESSAGES_REQUEST_SENDING",
  GET_SUCCESS = "GET_STUDENT_MESSAGES_RESPONSE_RECEIVED",
  GET_FAILURE = "GET_STUDENT_MESSAGES_RESPONSE_ERROR",
  // 個別メッセージの削除のActionTypes
  DELETE_MESSAGE_REQUEST = "DELETE_SECTION_STUDENT_MESSAGE_REQUEST",
  DELETE_MESSAGE_SUCCESS = "DELETE_SECTION_STUDENT_MESSAGE_SUCCESS",
  DELETE_MESSAGE_FAILURE = "DELETE_SECTION_STUDENT_MESSAGE_ERROR",
  // 通知設定状態取得
  GET_FOLLOW_STATUS_SUCCESS = "GET_FOLLOW_STATUS_SUCCESS",
  // 通知ON
  FOLLOW_THREAD_SUCCESS = "FOLLOW_STUDENT_MESSAGE_SUCCESS",
  FOLLOW_THREAD_FAILURE = "FOLLOW_STUDENT_MESSAGE_ERROR",
  // 通知OFF
  UNFOLLOW_THREAD_SUCCESS = "UNFOLLOW_STUDENT_MESSAGE_SUCCESS",
  UNFOLLOW_THREAD_FAILURE = "UNFOLLOW_STUDENT_MESSAGE_ERROR",
  // テキストメッセージ送信
  POST_MESSAGE_REQUEST = "POST_STUDENT_MESSAGE",
  POST_MESSAGE_SUCCESS = "POST_STUDENT_MESSAGE_SUCCESS",
  POST_MESSAGE_FAILURE = "PIST_STUDENT_MESSAGE_ERROR",
  // 画像メッセージ送信
  POST_FILE_REQUEST = "POST_STUDENT_FILE",
  POST_FILE_SUCCESS = "POST_STUDENT_FILE_SUCCESS",
  POST_FILE_FAILURE = "POST_STUDENT_FILE_ERROR",
  // BOTメッセージ対応状況変更
  CHANGE_BOT_MESSAGE_STATUS_DONE_REQUEST = "students/CHANGE_BOT_MESSAGE_STATUS_DONE_REQUEST",
  CHANGE_BOT_MESSAGE_STATUS_DONE_FAILURE = "students/CHANGE_BOT_MESSAGE_STATUS_DONE_FAILURE",
  CHANGE_BOT_MESSAGE_STATUS_DONE_SUCCESS = "students/CHANGE_BOT_MESSAGE_STATUS_DONE_SUCCESS",
  CHANGE_BOT_MESSAGE_STATUS_IGNORED_REQUEST = "students/CHANGE_BOT_MESSAGE_STATUS_IGNORED_REQUEST",
  CHANGE_BOT_MESSAGE_STATUS_IGNORED_FAILURE = "students/CHANGE_BOT_MESSAGE_STATUS_IGNORED_FAILURE",
  CHANGE_BOT_MESSAGE_STATUS_IGNORED_SUCCESS = "students/CHANGE_BOT_MESSAGE_STATUS_IGNORED_SUCCESS",
  // Zoomメッセージ送信
  POST_ZOOM_MEETING_MESSAGE_REQUEST = "students/POST_ZOOM_MEETING_MESSAGE_REQUEST",
  POST_ZOOM_MEETING_MESSAGE_SUCCESS = "students/POST_ZOOM_MEETING_MESSAGE_SUCCESS",
  POST_ZOOM_MEETING_MESSAGE_ERROR = "students/POST_ZOOM_MEETING_MESSAGE_ERROR",
}

export type Actions =
  | InitialGetMessagesRequest
  | GetMessagesRequest
  | GetMessagesSuccess
  | GetMessagesFailure
  | GetFollowStatusSuccess
  | FollowThreadSuccess
  | FollowThreadFailure
  | UnfollowThreadSuccess
  | UnfollowThreadFailure
  | PostMessageRequest
  | PostMessageSuccess
  | PostMessageFailure
  | PostFileRequest
  | PostFileSuccess
  | PostFileFailure
  | ChangeBotMessageStatusDoneRequest
  | ChangeBotMessageStatusDoneFailure
  | ChangeBotMessageStatusDoneSuccess
  | ChangeBotMessageStatusIgnoredRequest
  | ChangeBotMessageStatusIgnoredFailure
  | ChangeBotMessageStatusIgnoredSuccess
  | PostZoomMeetingMessageRequest
  | PostZoomMeetingMessageSuccess
  | PostZoomMeetingMessageError
  | DeleteMessageRequest
  | DeleteMessageFailure
  | DeleteMessageSuccess;

// メッセージ読込
interface InitialGetMessagesRequest extends Action {
  type: ActionTypes.INITIAL_GET_REQUEST;
}
interface GetMessagesRequest extends Action {
  type: ActionTypes.GET_REQUEST;
}
interface GetMessagesFailure extends Action {
  type: ActionTypes.GET_FAILURE;
}
interface GetMessagesSuccess extends Action {
  type: ActionTypes.GET_SUCCESS;
  payload: MessagePayload;
}
export interface MessagePayload {
  directMessages: DirectMessage[];
  botMessages: BotMessageInterface[];
  meta: CursorMetaData;
}
// 通知設定状態取得
interface GetFollowStatusSuccess extends Action {
  type: ActionTypes.GET_FOLLOW_STATUS_SUCCESS;
  payload: boolean;
}
// 通知ON
interface FollowThreadFailure extends Action {
  type: ActionTypes.FOLLOW_THREAD_FAILURE;
}
interface FollowThreadSuccess extends Action {
  type: ActionTypes.FOLLOW_THREAD_SUCCESS;
}
// 通知OFF
interface UnfollowThreadFailure extends Action {
  type: ActionTypes.UNFOLLOW_THREAD_FAILURE;
}
interface UnfollowThreadSuccess extends Action {
  type: ActionTypes.UNFOLLOW_THREAD_SUCCESS;
}
// テキストメッセージ送信
interface PostMessageRequest extends Action {
  type: ActionTypes.POST_MESSAGE_REQUEST;
}
interface PostMessageSuccess extends Action {
  type: ActionTypes.POST_MESSAGE_SUCCESS;
  payload: PostMessageSuccessPayload;
}
interface PostMessageFailure extends Action {
  type: ActionTypes.POST_MESSAGE_FAILURE;
  payload?: PostMessageFailurePayload;
}
export interface PostMessageSuccessPayload {
  message: DirectMessage;
}
export type PostMessageFailurePayload = ApiErrorResponseInterface;
// 画像メッセージ送信
interface PostFileRequest extends Action {
  type: ActionTypes.POST_FILE_REQUEST;
}
interface PostFileSuccess extends Action {
  type: ActionTypes.POST_FILE_SUCCESS;
  payload: PostFileSuccessPayload;
}
interface PostFileFailure extends Action {
  type: ActionTypes.POST_FILE_FAILURE;
  payload?: PostFileFailurePayload;
}
export interface PostFileSuccessPayload {
  message: DirectMessage;
}
export type PostFileFailurePayload = ApiErrorResponseInterface;
// BOTメッセージの対応状況を変更する
// = Done
interface ChangeBotMessageStatusDoneRequest extends Action {
  type: ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_REQUEST;
}
interface ChangeBotMessageStatusDoneFailure extends Action {
  type: ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_FAILURE;
}
interface ChangeBotMessageStatusDoneSuccess extends Action {
  type: ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_SUCCESS;
  payload: ChangeBotMessageStatusSuccessPayload;
}
// = IGNORE
interface ChangeBotMessageStatusIgnoredRequest extends Action {
  type: ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_REQUEST;
}
interface ChangeBotMessageStatusIgnoredFailure extends Action {
  type: ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_FAILURE;
}
interface ChangeBotMessageStatusIgnoredSuccess extends Action {
  type: ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_SUCCESS;
  payload: ChangeBotMessageStatusSuccessPayload;
}
export interface ChangeBotMessageStatusSuccessPayload {
  messageId: string;
}
// Zoomメッセージ
interface PostZoomMeetingMessageRequest extends Action {
  type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_REQUEST;
}
interface PostZoomMeetingMessageSuccess extends Action {
  type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_SUCCESS;
  payload: PostMessageSuccessPayload;
}
interface PostZoomMeetingMessageError extends Action {
  type: ActionTypes.POST_ZOOM_MEETING_MESSAGE_ERROR;
  payload?: ApiErrorResponseInterface;
}
// メッセージ削除
export interface DeleteMessageSuccessPayload {
  message: DirectMessage;
}
interface DeleteMessageRequest extends Action {
  type: ActionTypes.DELETE_MESSAGE_REQUEST;
}
interface DeleteMessageFailure extends Action {
  type: ActionTypes.DELETE_MESSAGE_FAILURE;
}
interface DeleteMessageSuccess extends Action {
  type: ActionTypes.DELETE_MESSAGE_SUCCESS;
  payload: DeleteMessageSuccessPayload;
}
