import * as React from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import { AuthenticatedPageCallbacks } from "../../../hocs/enhanceAuthenticatedPage";
import TabsNavi from "../../../components/molecules/TabsNavi";
import { useEffect } from "react";
import Loader from "../../../components/atoms/Loader/index";
import SectionsSettingsSkillsStateInterface from "../../../interfaces/SectionsSettingsSkillsStateInterface";
import {
  initializeSkills,
  loadSkills,
} from "../../../actions/pages/SectionsSettingsSkillsPage/index";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionSkills from "../../../components/organisms/SectionSkills";
import {
  OutletContextProps,
  withRouter,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface OwnProps {
  section: SectionInterface;
}

interface StateProps {
  skillsState: SectionsSettingsSkillsStateInterface;
}

interface DispatchProps {
  loadSkills: HandleThunkActionCreator<typeof loadSkills>;
  initializeSkills: typeof initializeSkills;
}

interface Props
  extends WithRouterProps,
    AuthenticatedPageCallbacks,
    StateProps,
    DispatchProps,
    OwnProps,
    OutletContextProps {}

const SectionsSettingsSkillsPage: React.FC<Props> = (props) => {
  useEffect(() => {
    props.setActiveMenu("skills");
  }, []);
  const loadSkills = () => {
    props.initializeSkills();
    props.loadSkills({ sectionId: props.section.id });
  };

  const handleScroll = () => {
    const { loading, meta } = props.skillsState;

    if (loading || meta.currentPage >= meta.totalPages) {
      return;
    }

    props.loadSkills({
      sectionId: props.section.id,
      page: meta.currentPage + 1,
    });
  };

  useEffect(loadSkills, []);

  useEffect(() => {
    window.onMainScrollAreaScroll = handleScroll;

    return () => {
      window.onMainScrollAreaScroll = undefined;
    };
  }, [props.skillsState]);

  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "アシスタント",
            active: true,
            link: "#",
          },
        ]}
      />
      {props.skillsState.loading &&
      props.skillsState.data.length === 0 ? null : (
        <SectionSkills
          section={props.section}
          skills={props.skillsState.data}
          loading={props.skillsState.loading}
          submitting={props.skillsState.submitting}
        />
      )}
      <Loader loading={props.skillsState.loading} />
    </>
  );
};

const pageInfo = {
  title: "アシスタント",
};

const mapStateToProps = (state: AppStateInterface) => ({
  skillsState: state.sectionsSettingsSkills,
});

const actions = {
  loadSkills,
  initializeSkills,
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsSkillsPage,
  pageInfo,
  ["assistant:index"],
);

export default withRouter<Props>(
  connect<StateProps, DispatchProps, OwnProps, AppStateInterface>(
    mapStateToProps,
    actions,
  )(EnhancedPage as any),
);
