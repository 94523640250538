import { useMemo } from "react";
import { useSectionContext } from "../../../contexts/section";
import { Lecture } from "../../../domains/Lecture";
import { LectureStudentAnalytics } from "../../../domains/LectureStudentAnalytics";
import { useFetchAnalyticsLectureStudent } from "../hooks/useFetchAnalyticsLectureStudent";
import { Props as AnalyticsTableProps } from "../AnalyticsLectureStudentTable";
import { Props as PaginationToolbarProps } from "../PaginationToolbar";
import { useURLSyncSearchCondition } from "../hooks/useURLSyncSearchCondition";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
export type Props = {
  lecture: Lecture;
};
export const useAnalyticsLectureStudent = ({ lecture }: Props) => {
  const section = useSectionContext();
  const searchConditionState = useURLSyncSearchCondition();
  const { searchCondition, changeSearchCondition } = searchConditionState;

  const {
    data,
    isLoading,
    isFetching,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchAnalyticsLectureStudent({
    lecture,
    section,
    searchCondition,
  });
  const lectureStudentAnalytics = useMemo<
    LectureStudentAnalytics | undefined
  >(() => {
    if (typeof data === "undefined") {
      return;
    }
    return {
      columns: data.pages[0].data.columns, // 縦のページごとに変わらないため[0]きめうち
      cursor: data.pages[0].data.cursor,
      students: data.pages.flatMap((page) => page.data.students),
    };
  }, [data]);

  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage]);

  const searchConditionToolbarProps: PaginationToolbarProps = {
    onMovePagination: (cursorDir) => {
      if (lectureStudentAnalytics) {
        const targetIndex =
          cursorDir === "next" ? lectureStudentAnalytics.columns.length - 1 : 0;
        const sessionId =
          lectureStudentAnalytics.columns[targetIndex].lectureSessionId;
        changeSearchCondition({
          paginationDir: cursorDir,
          lectureSessionId: sessionId,
        });
      }
    },
    cursor: lectureStudentAnalytics?.cursor ?? {
      availableNext: false,
      availablePrev: false,
    },
  };

  const analyticsTableProps: Omit<
    AnalyticsTableProps,
    "lectureStudentAnalytics"
  > = {
    onClickSort: (sortType, orderDir) => {
      changeSearchCondition({ order: sortType, orderDir });
    },
    sortOrderDirectionType: searchCondition.orderDir,
    sortedColumn: searchCondition.order,
    sectionId: section.id,
  };
  return {
    section,
    searchConditionToolbarProps,
    analyticsTableProps,
    lectureStudentAnalytics,
    isLoading,
    isFetching,
  };
};
