import * as React from "react";
import styles from "./styles.scss";
import SectionInterface from "../../../interfaces/SectionInterface";
import AnnounceDestinationSelector from "../AnnounceMessageDestinationSelector/index";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import CommonMessageForm from "../CommonMessageForm";
import PathHelper from "../../../helpers/PathHelper";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { generateStorageKey } from "../../atoms/ResumableTextarea/useResumableTextarea";
import { useAnnounceMessageForm } from "./useAnnounceMessageForm";
import { DestinationRecipients } from "./DestinationResipients";

export interface Props {
  section: SectionInterface;
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
  isModalOpen: boolean;
  onOpenModal: () => any;
  onCloseModal: () => any;
  currentOperator: OperatorInterface;
}

const AnnounceMessageForm: React.FC<Props> = (props) => {
  const {
    formState,
    handleChangeContent,
    handleChangeFormFile,
    handleSubmitContent,
    handleSubmitFile,
    handlePostZoomMeetingMessage,
    handleSelectDestinationTag,
    handleDeselectDestinationTag,
    hasRecipient,
    disabledPlaceholder,
    operatorProfile,
  } = useAnnounceMessageForm(props);

  return (
    <div className={styles.root}>
      <div className={styles.control}>
        <DestinationRecipients destinationTag={props.destinationTag} />
        <div className={styles.selector}>
          <AnnounceDestinationSelector
            destinationTag={props.destinationTag}
            onDecideTag={handleSelectDestinationTag}
            onDeselectTag={handleDeselectDestinationTag}
            isModalOpen={props.isModalOpen}
            onCloseModal={props.onCloseModal}
            onOpenModal={props.onOpenModal}
          />
        </div>
      </div>

      <CommonMessageForm
        formState={formState}
        canSendMessage={hasRecipient()}
        allowedFileTypes={["jpg", "gif", "png", "pdf"]}
        disabledPlaceholder={disabledPlaceholder()}
        postMessage={handleSubmitContent}
        postMessageFile={handleSubmitFile}
        changeFormContent={handleChangeContent}
        changeFormFile={handleChangeFormFile}
        rows={3}
        destination={
          props.destinationTag
            ? {
                path: PathHelper.getStudentsPath(
                  props.section.id,
                  props.destinationTag,
                ),
                name: props.destinationTag.name,
              }
            : null
        }
        operator={props.currentOperator}
        operatorProfile={operatorProfile}
        postZoomMeetingMessage={handlePostZoomMeetingMessage}
        defaultZoomMeetingContent={`Zoomミーティングを行います。`}
        storageKey={generateStorageKey([
          "messages",
          props.section.id,
          props.destinationTag?.id || "null",
          "new",
        ])}
      />
    </div>
  );
};

export default AnnounceMessageForm;
