import styles from "./styles.scss";
import TimeHelper from "../../../helpers/TimeHelper";
import classNames from "classnames";
import { IconOptionMenu } from "@studyplus/boron-ui";

interface Props {
  seconds: number;
  size?: string;
  isRestrictions?: boolean;
  dataTestId?: string;
}

const rootClassName = (size?: string): string => {
  return classNames(styles.root, {
    [styles.small]: size === "small",
  });
};

const Time = (props: Props) => {
  const { isRestrictions = false } = props;
  if (isRestrictions) {
    return (
      <div className={rootClassName(props.size)}>
        <IconOptionMenu className="mx-1 align-middle text-gray-800" />
        時間
        <IconOptionMenu className="mx-1 align-middle text-gray-800" />分
      </div>
    );
  }

  const time = TimeHelper.secondsToTime(props.seconds);

  return (
    <div className={rootClassName(props.size)} data-testid={props.dataTestId}>
      <span className={styles.number}>{time.hours}</span>時間
      <span className={styles.number}>{time.minutes}</span>分
    </div>
  );
};

export default Time;
