import { Action } from "redux";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";

export enum ActionTypes {
  INITIALIZED = "password_reset/INITIALIZED",
  CHECK_VALIDITY_REQUEST = "password_reset/CHECK_VALIDITY_REQUEST",
  CHECK_VALIDITY_SUCCESS = "password_reset/CHECK_VALIDITY_SUCCESS",
  CHECK_VALIDITY_FAILURE = "password_reset/CHECK_VALIDITY_FAILURE",
  RESET_PASSWORD_REQUEST = "password_reset/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "password_reset/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE = "password_reset/RESET_PASSWORD_FAILURE",
}

interface Initialized extends Action {
  type: ActionTypes.INITIALIZED;
}
interface CheckValidityRequest extends Action {
  type: ActionTypes.CHECK_VALIDITY_REQUEST;
}
interface CheckValiditySuccess extends Action {
  type: ActionTypes.CHECK_VALIDITY_SUCCESS;
}
interface CheckValidityFailure extends Action {
  type: ActionTypes.CHECK_VALIDITY_FAILURE;
}
interface ResetPasswordRequest extends Action {
  type: ActionTypes.RESET_PASSWORD_REQUEST;
}
interface ResetPasswordSuccess extends Action {
  type: ActionTypes.RESET_PASSWORD_SUCCESS;
}
interface ResetPasswordFailure extends Action {
  type: ActionTypes.RESET_PASSWORD_FAILURE;
  payload: ApiErrorInterface[];
}

export type Actions =
  | Initialized
  | CheckValidityRequest
  | CheckValiditySuccess
  | CheckValidityFailure
  | ResetPasswordRequest
  | ResetPasswordSuccess
  | ResetPasswordFailure;
