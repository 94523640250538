import * as React from "react";
import styles from "./styles.scss";
import {
  StayStateInterface,
  StayFormInterface,
} from "../../../interfaces/StayInterface";
import SectionStayItem from "../SectionStayItem/index";

interface Props {
  sectionId: string;
  sectionStays: StayStateInterface[];
  submitting: boolean;
  updateSectionStay: (
    sectionId: string,
    stayId: string,
    params: StayFormInterface,
  ) => void;
  deleteSectionStay: (sectionId: string, stayId: string) => void;
}

const SectionStayList = (props: Props) => {
  if (props.sectionStays.length > 0) {
    return (
      <React.Fragment>
        <div className={styles.table_wrapper}>
          <table className={styles.table}>
            <thead>{renderHeader()}</thead>
            <tbody>{renderBody(props)}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const renderHeader = () => {
  return (
    <tr className={styles.header}>
      <th>氏名</th>
      <th>職業（学年）</th>
      <th>校舎</th>
      <th>入室時間</th>
      <th>退室時間</th>
      <th>滞在時間</th>
      <th></th>
    </tr>
  );
};
const renderBody = (props: Props) => {
  return props.sectionStays.map((stay: StayStateInterface) => {
    return (
      <SectionStayItem
        sectionId={props.sectionId}
        stay={stay}
        key={`SectionStay-0${stay.id}`}
        submitting={props.submitting}
        updateSectionStay={props.updateSectionStay}
        deleteSectionStay={props.deleteSectionStay}
      />
    );
  });
};

export default SectionStayList;
