import * as React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import TabsNavi from "../../../components/molecules/TabsNavi";
import Icon from "../../../components/atoms/Icon";
import SectionInterface from "../../../interfaces/SectionInterface";

interface Props {
  section: SectionInterface;
}

const SectionsSettingsThirdPartyApplications: React.FC<Props> = (props) => {
  return (
    <>
      <TabsNavi
        tabs={[
          {
            label: "アプリ連携",
            active: true,
            link: "#",
          },
        ]}
      />
      <ThirdPartyApplicationSettings
        applicationName={"Google Classroom連携"}
        applicationSettingsPagePath={`/sections/${props.section.id}/settings/applications/google_classroom`}
        applicationDescription={
          "Google Classroomは、教師による課題の管理をサポートするシンプルで使いやすいツールです。Classroom を利用すると、教師はクラスの作成、課題の出題と採点、フィードバックの提供などを 1 か所で行えます。"
        }
        imagePath={`/images/google_classroom_square_icon.png`}
      />
    </>
  );
};

interface ThirdPartyApplicationSettingsProps {
  applicationName: string;
  applicationSettingsPagePath: string;
  applicationDescription: string;
  imagePath: string;
}

const ThirdPartyApplicationSettings: React.FC<
  ThirdPartyApplicationSettingsProps
> = (props) => {
  return (
    <Link to={props.applicationSettingsPagePath} className={styles.root}>
      <div className={styles.application}>
        <div className={styles.application__image}>
          <img src={props.imagePath} />
        </div>
        <div className={styles.application__info}>
          <div className={styles.application__summary}>
            <div className={styles.application__name}>
              <span>{props.applicationName}</span>
            </div>
          </div>
          <div className={styles.application__description}>
            <span>{props.applicationDescription}</span>
          </div>
        </div>
      </div>
      <Icon className={styles.icon} name="icon-arrow-right" />
    </Link>
  );
};

export default SectionsSettingsThirdPartyApplications;
