import * as React from "react";
import StudentInterface from "../../../interfaces/StudentInterface";
import StudentKarteForm from "../../molecules/StudentKarteForm/index";
import { KarteTemplateInterface } from "../../../interfaces/KarteTemplateInterface";
import { StudentKartesStateInterface } from "../../../interfaces/KarteInterface";
import StudentKarteList from "../../molecules/StudentKarteList/index";

interface Props {
  student: StudentInterface;
  studentKartes: StudentKartesStateInterface;
  karteTemplates: KarteTemplateInterface[];
}

const StudentKartes = (props: Props) => {
  return (
    <React.Fragment>
      <StudentKarteForm
        student={props.student}
        apiErrors={props.studentKartes.errors}
        karteTemplates={props.karteTemplates}
        submitting={props.studentKartes.submitting}
      />
      <StudentKarteList karteState={props.studentKartes} />
    </React.Fragment>
  );
};

export default StudentKartes;
