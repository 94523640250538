import * as React from "react";
import { useFetchAllTags } from "../../../../hooks/http/useFetchTags";
import SelectField from "../../../../components/atoms/DeprecatedSelectField";
import { useQueryError } from "../../../../hooks/http/useQueryError";

type UseTagSelectboxProps = {
  sectionId: string;
};

type UseTagOptionsResult = {
  options: { label: string; value: string | null }[];
  idIndexMap: { [tagId: string]: number };
};

const initialTagOptionResult: UseTagOptionsResult = {
  options: [],
  idIndexMap: {},
};

const UnselectedOption = {
  label: "タグを選択しない",
  value: "NoTag",
};

export const useDistributeTagSelectboxOptions = ({
  sectionId,
}: UseTagSelectboxProps) => {
  const fetchTagResult = useFetchAllTags({
    sectionId,
    params: { types: ["tag_ids"] },
  });
  useQueryError(fetchTagResult.error);

  const data = React.useMemo(() => {
    if (!fetchTagResult.data || !fetchTagResult.completeFetchAlltags) {
      return initialTagOptionResult;
    }
    return fetchTagResult.data.reduce<UseTagOptionsResult>(
      (previous, current, i) => {
        return {
          // selectbox の 選択肢に渡すobjectの配列
          options: previous.options.concat({
            label: current.name,
            value: current.id,
          }),
          // {tagId: [data配列のindex]} のテーブルを作ってデータを引っ張りやすくする
          idIndexMap: { ...previous.idIndexMap, [current.id]: i },
        };
      },
      { options: [UnselectedOption], idIndexMap: {} },
    );
  }, [fetchTagResult.data, fetchTagResult.completeFetchAlltags]);

  // selectboxの値からindex値を取得し、配列から該当のtagデータを取得してコールバックの引数にする
  const getTagFromTagId = (tagId: string | null) => {
    if (!tagId) {
      return null;
    }
    const index = data.idIndexMap[tagId];
    const tag = fetchTagResult.data ? fetchTagResult.data[index] : null;
    if (!tag) {
      return;
    }
    return tag;
  };

  const convertTagId = (tagId: string) => (tagId === "NoTag" ? null : tagId);
  return {
    options: data.options,
    getTagFromTagId,
    convertTagId,
    isLoading: fetchTagResult.isLoading,
    completeFetchAlltags: fetchTagResult.completeFetchAlltags,
  };
};

type TagSelectboxProps = Pick<UseTagOptionsResult, "options"> & {
  onChange: (tagId: string | null) => void;
  value: string | null;
};

export const DistributeTagSelectbox = ({
  options,
  onChange,
  value,
}: TagSelectboxProps) => {
  // SelectField が値として null を使用できない、対応修正も影響度が大きそうだったので、
  // 一旦"NoTag"という値で管理し、onChange時に null 変換して他のレイヤーに渡す
  const selectedValue = value === null ? "NoTag" : value;
  return (
    <SelectField
      value={selectedValue}
      options={options}
      onChange={(selected: { label: string; value: string }) =>
        onChange(selected.value === "NoTag" ? null : selected.value)
      }
      isSearchable={false}
      placeholder=""
      hideIndicator
      inputId="distribute-tag"
    />
  );
};
