const Settings = {
  ENVIRONMENT: window.Environment.ENVIRONMENT,
  BORON_URL: window.Environment.BORON_URL ?? "http://localhost:4000",
  BORON_ENTRY_URL:
    window.Environment.BORON_ENTRY_URL || "http://localhost:3100",
  ROOT_URL: window.location.origin,
  SITE_TITLE: "Studyplus for School",
  LINE_OFFICIAL_ACCOUNT_ID: window.Environment.LINE_OFFICIAL_ACCOUNT_ID,
  SENTRY_DSN: window.Environment.SENTRY_DSN,
  INTERCOM_APP_ID: window.Environment.INTERCOM_APP_ID,
  RELEASE: window.Environment.RELEASE,
  GOOGLE_ANALYTICS_TRACKING_ID: window.Environment.GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_ANALYTICS_MESUREMENT_ID:
    window.Environment.GOOGLE_ANALYTICS_MESUREMENT_ID,
  MSW_BROWSER: window.Environment.MSW_BROWSER,
};

// リリース前の機能を公開する環境かどうか
// stagはリリース前に公開してしまうと, この分岐を消したものを出すときにprodでいきなり確認することになってしまうため、
// 本番同様にリリース時に公開することにしている
// ref: https://studyplus.esa.io/posts/7990#Feature%20Flag%E3%81%AB%E3%82%88%E3%82%8B%E5%88%86%E5%B2%90%E3%81%AE%E6%89%B1%E3%81%84
export const isPreReleaseEnvironment = (): boolean => {
  return (
    Settings.ENVIRONMENT === "cage" ||
    Settings.ENVIRONMENT === "local" ||
    Settings.ENVIRONMENT === "ci"
  );
};

export default Settings;
