import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("スタッフ名は必須です")
    .max(33, "スタッフ名は33文字以下でご入力ください"),
  comment: yup.string().trim().required("コメントを入力してください"),
});

export default validationSchema;
