import * as React from "react";

import Button from "../../atoms/Button";
import Dropdown from "../../atoms/Dropdown";
import Icon from "../../atoms/Icon";
import Input from "../../atoms/Input";
import styles from "./styles.scss";

interface Props {
  sectionId: string;
  defaultState: State;
  onSearch: (params: { firstName: string; lastName: string }) => void;
}

interface State {
  firstName: string;
  lastName: string;
}

export default class HeaderStudentSearchForm extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = props.defaultState;
  }

  render() {
    return (
      <div className={styles.root}>
        <Dropdown.Container shouldCloseOnClick={true}>
          <Dropdown.Label>
            <label className={styles.label}>
              <div className={styles.icon}>
                <Icon name="icon-search" className={styles.iconSearch} />
              </div>
              <span className={styles.label__text}>生徒検索</span>
            </label>
          </Dropdown.Label>
          <Dropdown.List className={styles.dropdownList}>
            <form className={styles.dropdownBody} onSubmit={this.handleSubmit}>
              <div className={styles.inputContainer} onClick={this.handleClick}>
                <Input
                  aria-label="姓"
                  placeholder="姓"
                  onChange={this.handleChangeLastName}
                  value={this.state.lastName}
                />
              </div>
              <div className={styles.inputContainer} onClick={this.handleClick}>
                <Input
                  aria-label="名"
                  placeholder="名"
                  onChange={this.handleChangeFirstName}
                  value={this.state.firstName}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button disabled={false} isBlock={true} type="submit">
                  検索
                </Button>
              </div>
            </form>
          </Dropdown.List>
        </Dropdown.Container>
      </div>
    );
  }

  handleSubmit = (e: any) => {
    e.preventDefault();

    const { firstName, lastName } = this.state;
    this.props.onSearch({ firstName, lastName });
  };
  handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: e.target.value });
  };

  handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: e.target.value });
  };
}
