import * as React from "react";
import { useDeleteLearningMaterialTag } from "./useLearningMaterialTagApi";
import {
  TableColumn,
  HorizontalScrollTable,
} from "../../../components/atoms/Table";
import {
  LearningMaterialTag,
  toTypeLabel,
} from "../../../domains/LearningMaterialTag";
import LinkButton from "../../../components/atoms/LinkButton";
import styles from "./LearningMaterialTagTable.scss";
import Icon from "../../../components/atoms/Icon";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import Loader from "../../../components/atoms/Loader";
import {
  NarrowingConditions,
  useFetchLearningMaterialTags,
} from "../../../hooks/http/useLearningMaterialTagApi";

// Main hooks
type UseLearningMaterialTagTableProps = {
  section: SectionInterface;
  narrowingCondition: NarrowingConditions;
  onClickEdit: (learningMaterialTag: LearningMaterialTag) => void;
};
const useLearningMaterialTagTable = (
  props: UseLearningMaterialTagTableProps,
) => {
  const {
    data,
    error,
    isFetchingNextPage,
    isFetching,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useFetchLearningMaterialTags(props);
  useQueryError(error);
  useOnMainScrollAreaScroll(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage]);

  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const { mutate } = useDeleteLearningMaterialTag({
    section: props.section,
    onSuccess() {
      showSuccessMessage("教材タグを削除しました");
    },
    onError() {
      showErrorMessage("教材タグを削除できませんでした");
    },
  });

  const loaderProps = {
    loading: isLoading || isFetchingNextPage,
  };

  return { deleteLearningMaterailTag: mutate, data, loaderProps };
};

// Main Component
type Props = {
  section: SectionInterface;
  narrowingCondition: NarrowingConditions;
  onClickEdit: (learningMaterialTag: LearningMaterialTag) => void;
};
const LearningMaterialTagTable_ = (props: Props) => {
  const { deleteLearningMaterailTag, data, loaderProps } =
    useLearningMaterialTagTable(props);

  return (
    <>
      {data && (
        <HorizontalScrollTable
          headers={[
            { key: "tag-name", text: "教材タグ名", centering: true },
            { key: "tag-type", text: "タグ種別", centering: true },
            { key: "empty-column", text: "", centering: true },
          ]}
          rows={data.map((item) => {
            const deleteItem = () => deleteLearningMaterailTag({ id: item.id });
            return (
              <tr
                className={styles.tableRow}
                key={`learning_material_tag_${item.id}`}
              >
                <TableColumn>{item.name}</TableColumn>
                <TableColumn align="center" className={styles.tagTypeCell}>
                  {toTypeLabel(item)}
                </TableColumn>
                <TableColumn align="right" className={styles.buttonCell}>
                  {item.tagType === "custom" && (
                    <div className={styles.buttonsArea}>
                      <LinkButton onClick={() => props.onClickEdit(item)}>
                        <Icon name="icon-edit" className={styles.icon} />
                        <span>編集</span>
                      </LinkButton>
                      <LinkButton onClick={() => confirmDelete(deleteItem)}>
                        <Icon name="icon-trash" className={styles.icon} />
                        <span>削除</span>
                      </LinkButton>
                    </div>
                  )}
                </TableColumn>
              </tr>
            );
          })}
        />
      )}
      <Loader {...loaderProps} />
    </>
  );
};

export const LearningMaterialTagTable = React.memo(LearningMaterialTagTable_);

// Helpers

const confirmDelete = (callback: () => void) => {
  if (window.confirm("本当に削除してよろしいですか？")) {
    callback();
  }
};
