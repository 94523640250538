import { CommentInterface } from "../../../interfaces/CommentInterface";
import HeaderNotificationEmptyList from "../../atoms/HeaderNotificationEmptyList";
import HeaderNotificationList from "../../atoms/HeaderNotificationList";
import HeaderNotificationLoading from "../../atoms/HeaderNotificationLoading";
import HeaderCommentNotificationItem from "../HeaderCommentNotificationItem";
interface Props {
  loading: boolean;
  comments: CommentInterface[];
}

const renderItem = (comment: CommentInterface) => {
  return (
    <HeaderCommentNotificationItem
      comment={comment}
      key={`header-comment-notification-item-${comment.id}`}
    />
  );
};

const renderList = (comments: CommentInterface[]) => {
  if (comments.length > 0) {
    return (
      <HeaderNotificationList>
        {comments.map((comment) => renderItem(comment))}
      </HeaderNotificationList>
    );
  } else {
    return <HeaderNotificationEmptyList label="通知はありません" />;
  }
};

const HeaderCommentNotificationList = (props: Props) => {
  if (props.loading) {
    return <HeaderNotificationLoading />;
  } else {
    return renderList(props.comments);
  }
};

export default HeaderCommentNotificationList;
