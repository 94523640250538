import classnames from "classnames";
import styles from "../../styles/colors.scss";

export type Colors =
  | "primary"
  | "black"
  | "black-lighten-1"
  | "black-lighten-2"
  | "gray-lighten-1"
  | "gray-lighten-2"
  | "gray-lighten-3"
  | "gray-darken-1"
  | "gray-darken-2"
  | "gray-darken-3"
  | "gray-normal"
  | "yellow"
  | "green"
  | "pink"
  | "notification"
  | "red"
  | "red-lighten-2"
  | "white";

// Colorsの全ての色をhoverに使う必要はないが、hoverで指定される色は必ずColorsのどれかである
type HoverColorTypes<T> = T extends Colors ? T : never;
type HoverColors = HoverColorTypes<"primary" | "notification">;
type HoverBgColors = HoverColorTypes<
  "primary" | "gray-lighten-2" | "red-lighten-2"
>;

const ColorProps = {
  backgroundColor: "bg",
  color: "text",
  hoverColor: "hover-text",
  hoverBgColor: "hover-bg",
} as const;
export type ColorProps = {
  backgroundColor?: Colors;
  color?: Colors;
  hoverColor?: HoverColors;
  hoverBgColor?: HoverBgColors;
};

export const groupHoverClass = styles.groupHover;

export type HoverColorProps = {
  hoverColor: HoverColors;
  hoverBgColor: HoverBgColors;
};

export const resolveColorStyles = (props: ColorProps) => {
  return Object.keys(ColorProps).reduce((p, c) => {
    const value = (props as Record<string, string>)[c];
    return value
      ? classnames(
          p,
          styles[`${(ColorProps as Record<string, string>)[c]}-${value}`],
        )
      : p;
  }, styles["text-default"]);
};

export type GroupHoverProps = {
  groupHover?: boolean;
};

export const resolveGroupHoverStyle = (props: GroupHoverProps) =>
  props.groupHover ? styles.groupHover : null;
