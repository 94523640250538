import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";

import {
  loadInitialDestinationTagOptionRecipients,
  loadMoreDestinationTagOptionRecipients,
} from "../../../actions/pages/sectionAnnounceMessages/selector";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { AnnounceDestinationTagWithFormStateInterface } from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import StudentTag, { StudentTagType } from "../../../domains/StudentTag";
import { Button } from "@studyplus/boron-ui";
import Icon from "../../atoms/Icon";
import Loader from "../../atoms/Loader";
import { Modal } from "../../atoms/Modal";
import DeprecatedTagButton from "../../features/DeprecatedTagButton";
import styles from "./styles.scss";
import { AnnounceMessageRecipientsWrapper } from "../../features/AnnounceMessageRecipientsWrapper";

interface Props {
  destinationTag: AnnounceDestinationTagWithFormStateInterface | null;
  tags: AnnounceDestinationTagWithFormStateInterface[] | null;
  isModalOpen: boolean;
  onDecideTag: (tag: AnnounceDestinationTagWithFormStateInterface) => void;
  onDeselectTag: () => void;
  loadInitialRecipients: (tag: StudentTag) => void;
  loadMoreRecipients: (tag: StudentTag, page: number) => void;
  onCloseModal: () => any;
  onOpenModal: () => any;
}

interface State {
  selectedTag: AnnounceDestinationTagWithFormStateInterface | null;
  openedTag: AnnounceDestinationTagWithFormStateInterface | null;
}

class AnnounceDestinationSelector extends React.Component<Props, State> {
  scroller: React.RefObject<HTMLDivElement>;

  get tags(): AnnounceDestinationTagWithFormStateInterface[] | null {
    if (!this.props.tags) {
      return null;
    }

    // StudentFilterと同じ順番で返す
    return [
      ...this.sortAndFilterByName(this.props.tags, "status"),
      ...this.sortAndFilterByName(this.props.tags, "job"),
      ...this.sortAndFilterByName(this.props.tags, "tag_ids"),
      ...this.sortAndFilterByName(this.props.tags, "smart_tag"),
    ];
  }

  constructor(props: Props) {
    super(props);
    this.scroller = React.createRef<HTMLDivElement>();
    this.state = {
      selectedTag: null,
      openedTag: null,
    };
  }

  render() {
    if (!this.tags) {
      return <Loader loading={true} />;
    }

    if (this.props.destinationTag) {
      return (
        <DeprecatedTagButton
          label={this.props.destinationTag.name}
          theme={
            this.props.destinationTag.type === "smart_tag"
              ? "smarttag-white"
              : "white"
          }
          iconName={"icon-close-x"}
          onClick={this.props.onDeselectTag}
          dataTestId={"announce-message-form-destination-delete-button"}
        />
      );
    } else {
      return (
        <React.Fragment>
          <DeprecatedTagButton
            label="選択"
            iconName="icon-plus-tag"
            theme="main"
            onClick={this.props.onOpenModal}
          />
          {this.renderDestinationModal(this.tags)}
        </React.Fragment>
      );
    }
  }

  private renderDestinationModal(
    tags: AnnounceDestinationTagWithFormStateInterface[],
  ) {
    const studentCount = this.state.selectedTag
      ? this.state.selectedTag.studentCount
      : 0;

    return (
      <Modal
        isOpen={this.props.isModalOpen}
        onRequestClose={this.props.onCloseModal}
      >
        <Modal.Header onClose={this.props.onCloseModal}>
          <h2>宛先選択</h2>
        </Modal.Header>
        <Modal.Body>
          <h3 className={styles.heading}>送信先（{studentCount}名）：</h3>
          <div className={styles.row}>
            <div className={styles.selectedTag}>
              {this.state.selectedTag ? (
                <DeprecatedTagButton
                  label={this.state.selectedTag.name}
                  iconName="icon-close-x"
                  theme={
                    this.state.selectedTag.type === "smart_tag"
                      ? "smarttag-white"
                      : "white"
                  }
                  onClick={this.handleDeselectDestinationTag}
                  dataTestId={
                    "announce-message-destination-modal-option-delete-button"
                  }
                />
              ) : null}
            </div>
            <Button
              type="button"
              disabled={!this.state.selectedTag}
              onClick={this.handleDecideTag}
              className={styles.button}
            >
              決定
            </Button>
          </div>
          <div>
            {tags
              .filter((tag) => tag.enabled)
              .map((destinationTag) =>
                this.renderDestinationOption(destinationTag),
              )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  private renderDestinationOption(
    destinationTag: AnnounceDestinationTagWithFormStateInterface,
  ) {
    if (destinationTag.type === "status" && destinationTag.id !== "active") {
      return null;
    }

    const isOpened =
      !!this.state.openedTag && this.state.openedTag.id === destinationTag.id;
    const isSelected =
      !!this.state.selectedTag &&
      this.state.selectedTag.id === destinationTag.id;

    return (
      <React.Fragment
        key={`AnnounceMessageForm-DestinationOption-${destinationTag.id}`}
      >
        <label
          className={classNames(styles.label, {
            [styles.active]: isSelected,
            [styles.labelDisabled]: this.isDisabledTag(destinationTag),
          })}
          data-testid="announce-message-destination-modal-option-listitem"
        >
          <div
            className={classNames(styles.row, {
              [styles.disabledRow]: this.isDisabledTag(destinationTag),
            })}
          >
            <input
              type="radio"
              name={destinationTag.id}
              checked={isSelected}
              onChange={this.handleSelectDestinationTag(destinationTag)}
              disabled={this.isDisabledTag(destinationTag)}
            />
            <div className={styles.tagName}>
              {destinationTag.name}（{destinationTag.studentCount}名）
            </div>
          </div>
          {isOpened ? (
            <React.Fragment>
              <Icon name="icon-arrow-up" onClick={this.handleCloseOption} />
            </React.Fragment>
          ) : (
            <Icon
              name="icon-arrow-down"
              className={styles.icon}
              onClick={this.handleOpenOption(destinationTag)}
            />
          )}
        </label>
        {isOpened ? this.renderRecipients(destinationTag) : null}
      </React.Fragment>
    );
  }

  private renderRecipients(tag: AnnounceDestinationTagWithFormStateInterface) {
    const { recipients } = tag;

    return (
      <AnnounceMessageRecipientsWrapper
        onReachToBottom={this.handleLoadMoreRecipients(tag)}
      >
        {recipients.data
          ? recipients.data.map((recipient) => (
              <div
                key={`announce-message-recipient-${recipient.id}`}
                className={styles.recipient}
              >
                <Icon
                  name="icon-navi-avatar"
                  className={styles.recipient__icon}
                />
                <div className={styles.recipient__name}>
                  {recipient.fullName}
                </div>
              </div>
            ))
          : null}
        <Loader loading={recipients.loading} />
      </AnnounceMessageRecipientsWrapper>
    );
  }

  private handleLoadMoreRecipients =
    (tag: AnnounceDestinationTagWithFormStateInterface) => () => {
      if (tag.recipients.loading || !tag.recipients.hasMore) {
        return;
      }

      this.props.loadMoreRecipients(tag, tag.recipients.meta.currentPage + 1);
    };

  private handleCloseOption = () => {
    this.setState({
      openedTag: null,
    });
  };

  private handleOpenOption =
    (tag: AnnounceDestinationTagWithFormStateInterface) => () => {
      if (this.isDisabledTag(tag)) {
        return;
      }

      if (!tag.recipients.data) {
        this.props.loadInitialRecipients(tag);
      }

      this.setState({
        openedTag: tag,
      });
    };

  private handleDeselectDestinationTag = () => {
    this.setState({
      selectedTag: null,
    });
  };

  private handleSelectDestinationTag =
    (tag: AnnounceDestinationTagWithFormStateInterface) => () => {
      // 宛先生徒が0人のときは選択できない
      if (!tag.studentCount) {
        return;
      }

      this.setState({
        selectedTag: tag,
      });
    };

  private handleDecideTag = () => {
    if (!this.state.selectedTag) {
      return;
    }

    this.props.onDecideTag(this.state.selectedTag);
    this.props.onCloseModal();
  };

  private isDisabledTag(
    tag: AnnounceDestinationTagWithFormStateInterface,
  ): boolean {
    return !tag.studentCount;
  }

  private sortAndFilterByName(
    tags: AnnounceDestinationTagWithFormStateInterface[],
    type: StudentTagType,
  ): AnnounceDestinationTagWithFormStateInterface[] {
    return tags
      .filter((tag) => tag.type === type)
      .sort((a: StudentTag, b: StudentTag) => {
        return a.name > b.name ? 1 : -1;
      });
  }
}

const mapStateToProps = (state: AppStateInterface) => ({
  tags: state.sectionAnnounceMessages.announceDestinationTagOptions.data,
});

const mapDispatchToProps = {
  loadInitialRecipients: loadInitialDestinationTagOptionRecipients,
  loadMoreRecipients: loadMoreDestinationTagOptionRecipients,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnounceDestinationSelector);
