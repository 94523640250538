import { Field, Form, ErrorMessage, Formik } from "formik";
import Button from "../../atoms/Button/index";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import * as yup from "yup";
import { EmailRegex } from "../../../consts/EmailRegex";
import { TermAndPolicyField } from "../../molecules/TermAndPolicyField";
import { useMutateRegister } from "./useMutateRegister";
import { useNavigate } from "react-router";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

const RegisterForm = () => {
  const navigate = useNavigate();
  const { showErrorMessage } = useFlashMessage();

  const { mutate, isPending } = useMutateRegister({
    onSuccess: () => {
      navigate("/register/sent");
    },
    onError: () => {
      showErrorMessage("アカウント登録ができませんでした");
    },
  });

  const onSubmit = (values: typeof initialValues) => {
    if (!values.isTermsAccepted) {
      return;
    }

    mutate(values.email);
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>新規登録</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <div className={styles.emailContainer}>
              <label htmlFor="RegisterForm-email">メールアドレス</label>
              <Field name="email" type="email" id="RegisterForm-email-input" />
              <ErrorMessage
                component="p"
                name="email"
                className={styles.errorMessage}
              />
            </div>

            <div className={styles.checkboxContainer}>
              <TermAndPolicyField id="RegisterForm-terms-checkbox" />
            </div>

            <div className={styles.buttonContainer}>
              <Button
                disabled={!formik.isValid || isPending}
                isBlock={true}
                type="submit"
              >
                登録する
              </Button>
            </div>

            <p className={styles.description}>
              アカウントをお持ちの方は<Link to="/login">ログイン</Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const initialValues = {
  email: "",
  isTermsAccepted: false,
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("メールアドレスをご入力ください")
    .matches(EmailRegex, "メールアドレスの形式ではありません"),
  isTermsAccepted: yup
    .boolean()
    .oneOf([true], "利用規約、プライバシーポリシーに同意してください"),
});

export default RegisterForm;
