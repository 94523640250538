import { components } from "../lib/api/v1";

export const EntryGuidesOperations = [
  "entry_guides_for_students",
  "entry_guides_for_guardians",
] as const;

export const Operations = [
  "student_insert",
  "student_update",
  "learning_material_insert",
  "learning_material_update",
  "bookshelf_insert",
  "tag_insert",
  "tag_update",
  "student_tag_insert",
  "student_tag_delete",
  "drill_learning_material_insert",
  "student_billing_plan_update",
  "examination_result_insert",
  // 以下のオペレーションは、importオペレーションではないが、画面的に「一括操作（import_operations）」に配置するのが自然なため、ここに配置する
  ...EntryGuidesOperations,
] as const;

type ImportHistory = components["schemas"]["ImportHistory"];

export interface ImportHistoryInterface extends ImportHistory {}

export const renderOperationHuman = (
  operation: ImportHistoryInterface["operation"],
): string => {
  switch (operation) {
    case "bookshelf_insert":
      return "教材の本棚登録";
    case "learning_material_insert":
      return "教材の登録";
    case "learning_material_update":
      return "教材の更新";
    case "student_insert":
      return "生徒の登録";
    case "student_update":
      return "生徒の更新";
    case "student_tag_insert":
      return "生徒へのタグ付け";
    case "student_tag_delete":
      return "生徒へのタグ付けを削除";
    case "tag_insert":
      return "タグの登録";
    case "tag_update":
      return "タグの更新";
    case "drill_learning_material_insert":
      return "デジタル教材の登録";
    case "student_billing_plan_update":
      return "生徒利用プランの変更";
    case "examination_result_insert":
      return "成績記録の登録";
    case "entry_guides_for_students":
      return "初回配布資料の作成（生徒用）";
    case "entry_guides_for_guardians":
      return "初回配布資料の作成（保護者用）";
    default: {
      const unexpectedOperation: never = operation;
      throw new Error(`Illegal parameter error: "${unexpectedOperation}"`);
    }
  }
};

export const requireZipOperation = (
  operation: ImportHistoryInterface["operation"],
) => {
  switch (operation) {
    case "learning_material_insert":
    case "learning_material_update":
      return true;
    default:
      return false;
  }
};

export const helpUrl = (
  operation: ImportHistoryInterface["operation"],
): string => {
  switch (operation) {
    case "bookshelf_insert":
      return "https://fs-help.studyplus.co.jp/articles/3079955";
    case "learning_material_insert":
      return "https://fs-help.studyplus.co.jp/articles/3079937";
    case "learning_material_update":
      return "https://fs-help.studyplus.co.jp/articles/3079950";
    case "student_insert":
      return "https://fs-help.studyplus.co.jp/articles/3079914";
    case "student_update":
      return "https://fs-help.studyplus.co.jp/articles/3079927";
    case "student_tag_insert":
      return "https://fs-help.studyplus.co.jp/articles/3079974";
    case "student_tag_delete":
      return "https://fs-help.studyplus.co.jp/articles/3079979";
    case "tag_insert":
      return "https://fs-help.studyplus.co.jp/articles/3079960";
    case "tag_update":
      return "https://fs-help.studyplus.co.jp/articles/3079965";
    case "drill_learning_material_insert":
      // TODO: デジタル教材の一括操作実装時に修正
      return "";
    case "student_billing_plan_update":
      return "https://fs-help.studyplus.co.jp/ja/articles/9515288";
    case "examination_result_insert":
      return "https://fs-help.studyplus.co.jp/ja/articles/7963946";
    case "entry_guides_for_students":
      return "https://fs-help.studyplus.co.jp/ja/articles/8230759-%E7%94%9F%E5%BE%92%E9%85%8D%E5%B8%83%E8%B3%87%E6%96%99%E3%81%AE%E4%B8%80%E6%8B%AC%E4%BD%9C%E6%88%90%E6%96%B9%E6%B3%95";
    case "entry_guides_for_guardians":
      return "https://fs-help.studyplus.co.jp/ja/articles/8377909-%E4%BF%9D%E8%AD%B7%E8%80%85%E9%85%8D%E5%B8%83%E8%B3%87%E6%96%99%E3%81%AE%E4%B8%80%E6%8B%AC%E4%BD%9C%E6%88%90%E6%96%B9%E6%B3%95";
    default: {
      const unexpectedOperation: never = operation;
      throw new Error(`Illegal parameter error: "${unexpectedOperation}`);
    }
  }
};

export const isEntryGuideOperation = (
  operation: ImportHistoryInterface["operation"],
): operation is (typeof EntryGuidesOperations)[number] => {
  return (
    operation === "entry_guides_for_students" ||
    operation === "entry_guides_for_guardians"
  );
};
