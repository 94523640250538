import * as React from "react";
import enhanceSectionsSettingsPage from "../../hocs/enhanceSectionsSettingsPage";
import AppStateInterface from "../../interfaces/AppStateInterface";
import { connect } from "react-redux";
import { getSectionsKarteTemplates } from "../../actions/sectionsKarteTemplates";
import SectionInterface from "../../interfaces/SectionInterface";
import SectionsSettingsKarteTemplates from "../../components/organisms/SectionsSettingsKarteTemplates/index";
import { SectionKarteTemplatesStateInterface } from "../../interfaces/SectionKarteTemplatesInterface";
import { OutletContextProps } from "../../helpers/RouterHelper";

interface Props extends OutletContextProps {
  section: SectionInterface;
  sectionKarteTemplatesState: SectionKarteTemplatesStateInterface;
  getSectionsKarteTemplates: typeof getSectionsKarteTemplates;
}

class SectionsSettingsKarteTemplatesPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    props.getSectionsKarteTemplates(props.section.id);
    props.setActiveMenu("template");
  }

  render() {
    return (
      <SectionsSettingsKarteTemplates
        section={this.props.section}
        sectionKarteTemplatesState={this.props.sectionKarteTemplatesState}
      />
    );
  }
}

const pageInfo = {
  title: "テンプレート",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsKarteTemplatesPage,
  pageInfo,
);

const mapStateToProps = (state: AppStateInterface) => ({
  sectionKarteTemplatesState: state.sectionsSettingsKarteTemplatesState,
});

const actions = {
  getSectionsKarteTemplates,
};

export default connect(mapStateToProps, actions)(EnhancedPage as any);
