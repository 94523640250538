import {
  AnnounceDestinationTagOptionsStateInterface,
  AnnounceDestinationTagWithFormStateInterface,
} from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import { AnyAction } from "redux";
import StudentTag from "../../../domains/StudentTag";
import destinationTagRecipients, {
  defaultDestinationTagRecipientsState,
} from "../../destinationTagRecipients";
import { MessageFormStateInterface } from "../../../interfaces/MessageInterface";

const announceDestinationTagOptions = (
  state: AnnounceDestinationTagOptionsStateInterface,
  action: AnyAction,
): AnnounceDestinationTagOptionsStateInterface => {
  switch (action.type) {
    case "INITIALIZE_ANNOUNCE_DESTINATION_TAG_OPTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        meta: action.payload.meta,
        data: mapRecipientsState(action.payload.data),
      };

    default:
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: state.data.map((tag) => {
          if (action.payload && tag.id === action.payload.tagId) {
            return {
              ...tag,
              recipients: destinationTagRecipients(tag.recipients, action),
              formState: formState(tag.formState, action),
            };
          } else {
            return tag;
          }
        }),
      };
  }
};

const mapRecipientsState = (
  tags: StudentTag[],
): AnnounceDestinationTagWithFormStateInterface[] => {
  return tags.map((tag) => ({
    ...tag,
    recipients: defaultDestinationTagRecipientsState,
    formState: defaultMessageForm,
  }));
};

export const defaultAnnounceDestinationTagOptions: AnnounceDestinationTagOptionsStateInterface =
  {
    loading: false,
    hasMore: true,
    meta: {
      currentPage: 0,
      totalPages: 0,
    },
    data: null,
  };

const defaultMessageForm = {
  values: {
    content: "",
    file: null,
  },
  submitting: false,
  apiErrors: [],
};

const formState = (
  state: MessageFormStateInterface = defaultMessageForm,
  action: AnyAction,
) => {
  switch (action.type) {
    case "POST_ANNOUNCE_MESSAGE":
    case "POST_ANNOUNCE_MESSAGE_FILE":
      return {
        ...state,
        submitting: true,
      };

    case "POST_ANNOUNCE_MESSAGE_SUCCESS":
    case "POST_ANNOUNCE_MESSAGE_FILE_SUCCESS":
      return defaultMessageForm;

    case "POST_ANNOUNCE_MESSAGE_ERROR":
    case "POST_ANNOUNCE_MESSAGE_FILE_ERROR":
      return {
        ...state,
        apiErrors: action.payload ? action.payload.errors || [] : [],
        submitting: false,
      };

    case "CHANGE_SECTION_ANNOUNCE_MESSAGE_FORM_VALUES":
      return {
        ...state,
        values: action.payload.values,
      };
    default:
      return {
        ...state,
      };
  }
};

export default announceDestinationTagOptions;
