import { StudentKarteEditStateInterface } from "../interfaces/KarteInterface";
import karte from "./karte";

const defaultState: StudentKarteEditStateInterface = {
  loading: true,
  data: null,
  submitting: false,
  errors: [],
};

const studentKarteEdit = (
  state: StudentKarteEditStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): StudentKarteEditStateInterface => {
  const { payload } = action;

  switch (action.type) {
    case "GET_STUDENT_KARTE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_STUDENT_KARTE_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "GET_STUDENT_KARTE_SUCCESS":
      return {
        ...state,
        data: payload.karte,
        loading: false,
      };
    case "RESET_STUDENT_KARTE":
      return defaultState;

    // カルテの更新
    case "UPDATE_KARTE_SENDING":
      return {
        ...state,
        submitting: true,
      };

    case "UPDATE_KARTE_SUCCESS":
      return {
        ...state,
        errors: [],
        data: action.payload.karte,
        submitting: false,
      };
    case "UPDATE_KARTE_ERROR":
      return {
        ...state,
        errors: action.payload ? action.payload.errors || [] : [],
        submitting: false,
      };

    // カルテ削除
    case "DELETE_KARTE_SUCCESS":
      return state;

    // コメント
    case "OPEN_KARTE_SHARE_MODAL":
    case "CLOSE_KARTE_SHARE_MODAL":
    case "POST_KARTE_SHARE_SENDING":
    case "POST_KARTE_SHARE_SUCCESS":
    case "POST_KARTE_SHARE_ERROR":
    case "DELETE_KARTE_COMMENT_SUCCESS":
    case "DELETE_KARTE_COMMENT_ERROR":
    case "POST_KARTE_COMMENT_SUCCESS":
    case "POST_KARTE_COMMENT_ERROR":
      return {
        ...state,
        data: karte(state.data, action),
        loading: false,
      };

    default:
      return state;
  }
};

export default studentKarteEdit;
