type Props = {
  dataTypeLabel: string;
};

export const Pending = ({ dataTypeLabel }: Props) => {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-8 rounded-lg bg-[#fafafa] px-10 py-[22px]">
        <div className="flex flex-col gap-8">
          <p className="font-bold">{dataTypeLabel}を作成中です。</p>
          <p>
            処理が完了後、校舎横断操作完了メールが送信されます。
            <br />
            メールに添付されているリンクから、データをダウンロードしてください。
          </p>
          <p>
            (件数が多い場合、完了までにしばらく時間がかかることがあります。)
          </p>
        </div>
      </div>
    </div>
  );
};
