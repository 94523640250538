import * as React from "react";
import TabsNavi from "../../../../components/molecules/TabsNavi";
import SectionTagForm from "../SectionTagForm";
import SectionTagList from "../SectionTagList";
import SectionInterface from "../../../../interfaces/SectionInterface";
import Loader from "../../../../components/atoms/Loader/index";
import AppStateInterface from "../../../../interfaces/AppStateInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import SectionsSettingsTagsStateInterface from "../../../../interfaces/SectionsSettingsTagsState";
import StudentTag, {
  StudentTagFilterInterface,
} from "../../../../domains/StudentTag";
import {
  createTag,
  updateTag,
  deleteTag,
} from "../../../../actions/pages/SectionsSettingsTagsPage/actions";
import { useStudentFilterContext } from "../../../../components/features/NewStudentFilter/useStudentFilterContext";
import { initializeSectionTags } from "../../../../actions/common/initializeSectionTags";

interface OuterProps {
  section: SectionInterface;
  tagFilter: StudentTagFilterInterface;
}

interface InnerProps {
  sectionsSettingstagsState: SectionsSettingsTagsStateInterface;
  createTag: HandleThunkActionCreator<typeof createTag>;
  updateTag: HandleThunkActionCreator<typeof updateTag>;
  deleteTag: HandleThunkActionCreator<typeof deleteTag>;
  initializeSectionTags: HandleThunkActionCreator<typeof initializeSectionTags>;
}

type Props = InnerProps & OuterProps;

const mapStateToProps = (state: AppStateInterface, ownProps: OuterProps) => ({
  section: ownProps.section,
  tagFilter: ownProps.tagFilter,
  sectionsSettingstagsState: state.sectionsSettingsTagsState,
});

const actions = {
  createTag,
  updateTag,
  deleteTag,
  initializeSectionTags,
};

const SectionsSettingsTags = (props: Props) => {
  const studentFilterContext = useStudentFilterContext();
  // FIXME: ReduxからReact Queryに置き換えたらこのあたりはスッキリ書けるはず
  // 脱Reduxの途中なのでReduxでやっていたロジックをここに持って来ざるを得ない
  const [submitTag, setSubmitTag] = React.useState<{
    type: "create" | "update" | "delete";
    tag: StudentTag;
  } | null>(null);
  const onSubmitCallback = (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => {
    props.initializeSectionTags(props.section.id, studentFilterContext);
    setSubmitTag({ type, tag });
  };
  const [tagList, setTagList] = React.useState<StudentTag[]>(
    props.tagFilter.tags.filter((tag) => tag.type === "tag_ids"),
  );
  React.useEffect(() => {
    if (submitTag === null) return;

    if (submitTag.type === "delete") {
      // // タグ削除はリストから削除
      const newTagList = tagList.filter((tag) => tag.id !== submitTag.tag.id);
      setTagList(newTagList);
    } else if (submitTag.type === "create") {
      // 新規タグはリストの先頭にする
      const newTagList = tagList.filter((tag) => tag.id !== submitTag.tag.id);
      setTagList([submitTag.tag, ...newTagList]);
    } else if (submitTag.type === "update") {
      // 更新タグは入れ替え
      const newTagList = tagList.map((tag) =>
        tag.id === submitTag.tag.id ? submitTag.tag : tag,
      );
      setTagList(newTagList);
    }
  }, [submitTag]);

  if (props.sectionsSettingstagsState.loading) {
    return <Loader loading={true} />;
  }

  return (
    <React.Fragment>
      <TabsNavi
        tabs={[
          {
            label: "タグ",
            link: "#",
            active: true,
          },
        ]}
      />
      <SectionTagForm
        section={props.section}
        submitting={props.sectionsSettingstagsState.submitting}
        apiErrors={props.sectionsSettingstagsState.apiError["new"] || []}
        onSubmit={props.createTag}
        onSubmitCallback={onSubmitCallback}
      />
      <SectionTagList
        section={props.section}
        tags={tagList}
        sectionsSettingsTagsState={props.sectionsSettingstagsState}
        updateTag={props.updateTag}
        deleteTag={props.deleteTag}
        onSubmitCallback={onSubmitCallback}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, actions)(SectionsSettingsTags);
