import * as React from "react";
import SubLayout from "../../../components/atoms/SubLayout";
import Helmet from "react-helmet";
import styles from "./styles.scss";
import Settings from "../../../helpers/Settings";

const PrivacyPolicyPage: React.FC<Record<string, never>> = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex"></meta>
      <title>{`プライバシーポリシー | ${Settings.SITE_TITLE}`}</title>
    </Helmet>
    <SubLayout>
      <SubLayout.Header />
      <SubLayout.Heading>
        Studyplus for School プライバシーポリシー
      </SubLayout.Heading>
      <SubLayout.Main>
        <div className={styles.root}>
          <p>
            スタディプラス株式会社（以下「当社」といいます。）は、当社の提供するStudyplus
            for
            School（以下「本サービス」といいます。）の利用契約を結ぶ個人、又は法人等に所属する従業員等であって本サービスを利用する者（以下「利用者」といいます。）についての個人情報を含む利用者情報の取扱について、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
          </p>
          <p>
            ご登録いただく個人情報の内容は、ご本人の任意によるものですが、正しい登録がされない場合は、適切なサービスをご提供できない場合がありますので、ご承知おきください。
          </p>
          <h2>1.収集する利用者情報および収集方法</h2>
          <p>
            本ポリシーにおいて、個人情報とは、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定める「個人情報」をいいます。また、「利用者情報」とは、利用者の識別に係る情報、通信サービス上の行動履歴、その他利用者のPCやスマートフォン等の端末（以下「通信端末」といいます。）において利用者又は利用者の端末に関連して生成又は蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。この利用者情報には、個人情報が含まれることがあります。本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものです。
          </p>
          <ul>
            <li>(1) 利用者に関する情報</li>
            <ol>
              <li className={styles.list__item__latin}>
                氏名、メールアドレス、電話番号、学習履歴、成績等の利用者が本サービスに直接登録する情報
              </li>
              <li className={styles.list__item__latin}>
                利用者の所属する企業の従業員が、利用者に同意を得て利用者に代行して、本サービスに登録する情報
              </li>
            </ol>
            <li>
              (2) 利用者の所属する会社に関する情報（会社名、所在地、代表者名等）
            </li>
            <li>
              (3) 通信端末や通信環境に関する情報
              <ol>
                <li className={styles.list__item__latin}>
                  通信端末の種別、機種、OSやブラウザ等の情報
                </li>
                <li className={styles.list__item__latin}>
                  IPアドレスや通信の安定度合い等のネットワークに関する情報
                </li>
                <li className={styles.list__item__latin}>
                  本サービスに関するアクセス履歴、ご利用方法に関する情報、ご利用時間帯等の本サービスのご利用状況に関する情報
                </li>
                <li className={styles.list__item__latin}>
                  通信端末から取得する、位置情報その他の情報
                </li>
              </ol>
              <li>(4) その他当社が定める入力フォームに利用者が提供する情報</li>
            </li>
          </ul>
          <h2>2.利用目的</h2>
          <p>利用者情報の具体的な利用目的は以下のとおりです。</p>
          <ul>
            <li>
              (1)本サービスへのログイン等の個人認証や表示情報の切り替えなど、本サービスの利用に必要な利用者の管理のためのシステム運営
            </li>
            <li>(2)本サービスの管理画面内での利用情報者の表示</li>
            <li>(3)本サービスにおける学習計画及び管理を目的とした機能の提供</li>
            <li>
              (4)利用者の所属企業の状況や本サービス内でのログ情報などを用いたレコメンドなど、利用者の利用促進を目的とした機能の提供
            </li>
            <li>
              (5)個人を特定できない形への加工を経た、本サービスに関する統計情報の作成および利用
            </li>
            <li>
              (6)メンテナンス情報や、規約の変更、その他重要なご連絡などを通知するため
            </li>
            <li>(7)本サービスに関する情報提供、問い合わせ等への対応のため</li>
            <li>(8)本サービスの開発およびマーケティング等への利用</li>
            <li>
              (9)個人を特定できない形への加工を経た、当社提携先への情報の提供
            </li>
            <li>(10)上記の利用目的に付随する利用目的のため</li>
          </ul>
          <h2>3.個人情報利用目的の変更</h2>
          <p>
            当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には利用者に通知または当ページもしくは当社ホームページにて公表します。
          </p>
          <h2>4.第三者提供</h2>
          <p>
            当社は、利用者情報のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除き第三者に提供しません。但し、次に掲げる場合はこの限りではありません。
          </p>
          <ul>
            <li>(1)当社が利用者の所属企業または団体に第三者提供する場合</li>
            <li>
              (2)当社が利用目的達成に必要な範囲内において個人情報の取扱の全部又は一部を委託する場合
            </li>
            <li>
              (3)合併その他の事由による事業の承継に伴って個人情報が提供される場合
            </li>
            <li>
              (4)アクセス解析ツールや、アドネットワーク等の情報収集モジュール提供者へ個人情報が提供される場合
            </li>
            <li>
              (5)人の生命、身体又は財産の保護のために必要がある場合であって、利用者本人の同意を得ることが困難であると当社が判断した場合
            </li>
            <li>
              (6)公衆衛生の向上又は児童の健全な育成推進のために特に必要がある場合であって、利用者本人の同意を得ることが困難であると当社が判断した場合
            </li>
            <li>(7)あらかじめ利用者のご了解を得ている場合</li>
            <li>(8)その他、個人情報保護法その他の法令で認められる場合</li>
          </ul>
          <h2>5.個人データの取り扱いの委託</h2>
          <p>
            当社では、先に述べた利用目的を遂行するため、利用者情報の取扱いを外部に委託することがあります。この場合は、利用者情報の安全管理のために必要かつ適切な措置を講じていると認められる委託先を選定し、守秘義務契約を取り交わすとともに、委託先に対して必要かつ適切な監督を行います。
          </p>
          <h2>6.第三者提供に関する免責事項</h2>
          <p>
            以下に該当する場合、第三者による利用者の個人情報の取得に関し、当社は何らの責任を負わないものとします。
          </p>
          <ul>
            <li>
              (1)利用者が本サービスの機能等を利用し、第三者に個人情報を公開した場合
            </li>
            <li>
              (2)本サービスにログインする為に必要な識別情報（メールアドレス/パスワード等）を、利用者以外の者が何らかの方法により取得した場合
            </li>
          </ul>
          <h2>7.個人情報の開示・訂正・削除等の対応</h2>
          <p>
            当社に対し、個人情報保護法その他の法令等に基づき、自身の個人情報の利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去及び第三者への提供の停止（以下、開示等）を下記記載のお問合せ先に請求することができます。その際は、合理的な期間内に対応します。ただし、請求をした利用者の本人確認ができなかった場合、個人情報保護法の定める要件を満たさない場合、正当な理由なく同内容の請求が繰り返されている場合等、ご希望に添えない場合があります。当社が保存している利用者の個人情報の開示請求、修正および削除をご希望される場合には、下記記載の問合せ先にご連絡をください。なお、アクセスログなどの個人情報以外の情報については、原則として開示等はいたしません。
          </p>
          <h2>8.プライバシーポリシーの変更</h2>
          <p>
            当社は、プライバシーポリシーを適宜見直し、継続的な改善に努め、必要に応じて、本ポリシーを変更することがあり、変更した場合には利用者に通知または当ページもしくは当社ホームページにて公表します。
          </p>
          <h2>9.お問い合わせ窓口</h2>
          <p>会員情報に関するお問い合わせは、下記までご連絡お願い致します。</p>
          <p>
            〒101-0062 東京都千代田区神田駿河台二丁目5番地12
            <br />
            スタディプラス株式会社 個人情報問合せ窓口
            <br />
            メールアドレス：privacycenter@studyplus.jp
          </p>
          <p>
            ※
            土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。
          </p>
          <h2>10.個人情報管理責任者</h2>
          <p>
            利用者の個人情報は、以下の者が責任をもって管理するものとします。
            <br />
            管理者名：中島花絵
            <br />
            所属部署：管理部
            <br />
            連絡先：メールアドレス：privacycenter@studyplus.jp
          </p>
        </div>
      </SubLayout.Main>
      <SubLayout.Footer />
    </SubLayout>
  </>
);

export default PrivacyPolicyPage;
