import { useFormik, FormikHelpers } from "formik";
import * as yup from "yup";
import { FormikErrors } from "formik";
import * as React from "react";
import { Student } from "../../../domains/Student";
import { MAX_VALUES } from "../../../domains/StudySchedule";
import { Schedule } from "../../../domains/Schedule";
import { BookshelfEntry } from "../../../domains/BookshelfEntry";
import { LearningMaterial } from "../../../domains/LearningMaterial";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import subYears from "date-fns/subYears";

type Props = {
  student: Student;
  bookshelfEntries: readonly BookshelfEntry[];
  onSubmit: (schedule: Schedule) => void;
};

export type Values = {
  steakMaterialCode: string;
  hours: number;
  minutes: number;
  amount: number;
  initialDate: Date | undefined;
  endDate: Date | undefined;
  comment: string;
};

export const useStudyScheduleForm = (props: Props) => {
  const findLearningMaterialByCode = React.useCallback(
    (steakMaterialCode: string): LearningMaterial => {
      const bookshelfEntry = props.bookshelfEntries.find(
        (e) => e.learningMaterial.code === steakMaterialCode,
      );

      if (!bookshelfEntry) {
        throw "本棚に存在しない教材が選択されています";
      }

      return {
        publicId: "",
        ...bookshelfEntry.learningMaterial,
      };
    },
    [props.bookshelfEntries],
  );

  const toSchedule = React.useCallback(
    (values: Values): Schedule => {
      if (!values.initialDate || !values.endDate) {
        throw "開始日と終了日は必須です";
      }
      return {
        id: "",
        summary: "",
        startAt: startOfDay(values.initialDate),
        endAt: startOfDay(addDays(values.endDate, 1)),
        locale: "",
        description: values.comment,
        allday: true,
        recurrence: null,
        studySchedule: {
          learningMaterial: findLearningMaterialByCode(
            values.steakMaterialCode,
          ),
          numberOfSeconds: values.hours * 60 * 60 + values.minutes * 60,
          amount: values.amount,
        },
        scheduleType: "single_schedule", // 単発の予定の作成なので決め打ち
      };
    },
    [props.bookshelfEntries],
  );

  const onSubmit = React.useCallback(
    (values: Values): void => {
      const params = toSchedule(values);

      props.onSubmit(params);
    },
    [props.onSubmit, props.bookshelfEntries],
  );

  return useFormik<Values>({
    initialValues,
    onSubmit,
    validate,
    validationSchema,
    validateOnMount: true,
    onReset: (values: Values, formikHelpers: FormikHelpers<Values>) => {
      // 単純にresetFormを呼んで値をリセットすると
      // steakMaterialCodeの状態がSelectコンポーネントのpropsとSelectFieldのpropsでずれるため、
      // steakMaterialCodeのみリセット前の状態を引き継ぐ
      formikHelpers.setValues({
        ...initialValues,
        steakMaterialCode: values.steakMaterialCode,
      });
    },
  });
};

const initialValues = {
  steakMaterialCode: "",
  hours: 0,
  minutes: 0,
  amount: 0,
  initialDate: undefined,
  endDate: undefined,
  comment: "",
};

const validate = (values: Values): FormikErrors<Values> => {
  const errors: FormikErrors<Values> = {};

  // 学習時間または学習量の入力が必須
  if (!values.hours && !values.minutes && !values.amount) {
    errors.hours = "学習時間、または学習量を設定してください";
    errors.minutes = "学習時間、または学習量を設定してください";
    errors.amount = "学習時間、または学習量を設定してください";
  }

  // 学習期間のバリデーション

  const today = new Date();

  // 開始日：入力している日付を起点日として3年前まで
  if (
    values.initialDate &&
    values.endDate &&
    isBefore(values.initialDate, subYears(today, 3))
  ) {
    errors.initialDate = "開始日は3年以内に設定してください";
  }

  // 終了日：開始日以降の日付
  if (
    values.initialDate &&
    values.endDate &&
    isBefore(values.endDate, values.initialDate)
  ) {
    errors.endDate = "終了日は開始日以降に設定してください";
  }

  // 終了日：入力している日付を起点日として3年後まで
  if (
    values.initialDate &&
    values.endDate &&
    isAfter(values.endDate, addYears(today, 3))
  ) {
    errors.endDate = "終了日は3年以内に設定してください";
  }

  return errors;
};

// fieldレベルのバリデーション
const validationSchema = yup.object().shape({
  steakMaterialCode: yup.string().trim().required("教材を選択してください"),
  comment: yup
    .string()
    .nullable()
    .max(
      MAX_VALUES.comment,
      `コメントは${MAX_VALUES.comment}文字以内にしてください`,
    ),
  initialDate: yup.mixed().required("開始日を選択してください"),
  endDate: yup.mixed().required("終了日を選択してください"),
});
