import * as React from "react";
import SectionInterface from "../../../interfaces/SectionInterface";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import { connect, HandleThunkActionCreator } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionAnnounceMessages from "../../../components/organisms/SectionAnnounceMessages/index";
import SectionAnnounceMessagesStateInterface from "../../../interfaces/SectionAnnounceMessagesStateInterface";
import {
  loadInitialSectionAnnounceDestinationTags,
  loadSectionAnnounceDestinationTags,
} from "../../../actions/pages/sectionAnnounceMessages/destinationTagList";
import {
  openAnnounceDestinationTag,
  closeAnnounceDestinationTag,
} from "../../../actions/pages/sectionAnnounceMessages/common";
import { OperatorPrivilegeInfoInterface } from "../../../interfaces/OperatorInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import {
  OutletContextProps,
  WithRouterProps,
  withRouter,
} from "../../../helpers/RouterHelper";

interface DispatchProps {
  loadSectionAnnounceDestinationTags: HandleThunkActionCreator<
    typeof loadSectionAnnounceDestinationTags
  >;
  loadInitialSectionAnnounceDestinationTags: HandleThunkActionCreator<
    typeof loadInitialSectionAnnounceDestinationTags
  >;
  openAnnounceDestinationTag: HandleThunkActionCreator<
    typeof openAnnounceDestinationTag
  >;
  closeAnnounceDestinationTag: HandleThunkActionCreator<
    typeof closeAnnounceDestinationTag
  >;
}

interface StateProps {
  section: SectionInterface;
  announceMessagesState: SectionAnnounceMessagesStateInterface;
  operatorPrivilege: OperatorPrivilegeInfoInterface | null;
  currentOperator: OperatorInterface;
}

interface Props
  extends OutletContextProps,
    WithRouterProps<{
      sectionId: string;
      destinationTagId: string;
      action: string;
    }>,
    DispatchProps,
    StateProps {}

class SectionsAnnounceDestinationTagsPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.loadInitialAnnounceDestinationTags();
    this.openAnnounceDestinationTag();
    props.setActiveMenu("message");
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    // 宛先タグの読み込みが完了したら選択中のタグに反映
    if (
      prevProps.announceMessagesState.announceDestinationTagOptions.data ===
        null &&
      this.props.announceMessagesState.announceDestinationTagOptions.data
    ) {
      this.openAnnounceDestinationTag();
    }

    // URL変更時にタグの選択し直しをする
    if (prevProps.location !== this.props.location) {
      if (this.props.params.action === "new") {
        this.props.closeAnnounceDestinationTag();
      } else {
        this.openAnnounceDestinationTag();
      }
    }
  }

  render() {
    if (!this.props.section) {
      return null;
    }

    return (
      <SectionAnnounceMessages
        section={this.props.section}
        announceMessagesState={this.props.announceMessagesState}
        operatorPrivilege={this.props.operatorPrivilege}
        onLoadMore={this.loadMore}
        currentOperator={this.props.currentOperator}
      />
    );
  }

  private loadMore = () => {
    if (!this.hasMoreData()) {
      return;
    }

    this.props.loadSectionAnnounceDestinationTags(
      this.props.section.id,
      this.props.announceMessagesState.meta.currentPage + 1,
    );
  };

  private hasMoreData(): boolean {
    const { currentPage, totalPages } = this.props.announceMessagesState.meta;
    return currentPage < totalPages;
  }

  private loadInitialAnnounceDestinationTags(): void {
    const { sectionId, action } = this.props.params;

    if (action === "new") {
      this.props.loadInitialSectionAnnounceDestinationTags(sectionId);
    } else {
      this.props.loadInitialSectionAnnounceDestinationTags(sectionId, {
        selectInitialTag: true,
      });
    }
  }

  private openAnnounceDestinationTag(): void {
    const {
      section,
      params,
      announceMessagesState,
      openAnnounceDestinationTag,
    } = this.props;

    const { destinationTagId } = params;
    const { announceDestinationTagOptions } = announceMessagesState;

    if (!announceDestinationTagOptions.data) {
      return;
    }

    const destinationTag = announceDestinationTagOptions.data.find(
      (tag) => tag.id === destinationTagId,
    );

    if (destinationTag) {
      openAnnounceDestinationTag(section.id, destinationTag);
    }
  }
}

const pageInfo = {
  title: "メッセージ",
};

const EnhancedPage = enhanceSectionsPage(
  SectionsAnnounceDestinationTagsPage,
  pageInfo,
  ["announce_message:index"],
);

const mapStateToProps = (state: AppStateInterface) => {
  return {
    announceMessagesState: state.sectionAnnounceMessages,
    operatorPrivilege: state.operatorPrivileges.data,
  };
};

const actions = {
  loadInitialSectionAnnounceDestinationTags,
  loadSectionAnnounceDestinationTags,
  openAnnounceDestinationTag,
  closeAnnounceDestinationTag,
};

const ConnectedPage = withRouter<Props>(
  connect(mapStateToProps, actions)(EnhancedPage),
);

export default ConnectedPage;
