import * as React from "react";
import enhanceSectionsPage from "../../../hocs/enhanceSectionsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import SectionDashboard from "../../../components/organisms/SectionDashboard";
import {
  OutletContextProps,
  withRouter,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface OwnProps {
  section: SectionInterface;
}

type Props = OwnProps & WithRouterProps & OutletContextProps;

const SectionsDashboardPage = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("dashboard");
  });

  if (!props.section) {
    return null;
  }

  return <SectionDashboard section={props.section} />;
};

export default withRouter<Props>(
  enhanceSectionsPage(SectionsDashboardPage, {
    title: "ダッシュボード",
  }),
);
