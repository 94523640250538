import * as React from "react";
import styles from "./styles.scss";
import TextLogo from "../TextLogo/index";
import { Link } from "react-router-dom";

const Header = (props: React.PropsWithChildren<unknown>) => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to={"/"}>
          <TextLogo />
        </Link>
      </div>
      {props.children}
    </header>
  );
};

export default Header;
