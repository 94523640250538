import { StayingStudentStateInterface } from "../../interfaces/StayingStudentInterface";
import { Link } from "react-router-dom";

interface Props {
  sectionId: string;
  sectionStays: StayingStudentStateInterface[];
}

const SectionStayingStudentsTable = (props: Props) => {
  return (
    <div className="m-0 overflow-x-auto rounded-[0.5rem] border border-solid border-gray-400 bg-white">
      <table className="w-full border-collapse whitespace-nowrap px-8 py-0 text-center text-black [&_td]:p-6">
        <thead>{renderHeader()}</thead>
        <tbody>{renderBody(props)}</tbody>
      </table>
    </div>
  );
};

const renderHeader = () => {
  return (
    <tr className="box-border h-[3.5rem] border-0 border-b border-solid border-gray-400 text-sm text-gray-800">
      <th>氏名</th>
      <th>職業（学年）</th>
      <th>校舎</th>
    </tr>
  );
};

const renderBody = (props: Props) => {
  if (props.sectionStays.length > 0) {
    return props.sectionStays.map((stay: StayingStudentStateInterface) => {
      return renderRow({ stay });
    });
  }
  return (
    <tr>
      <td colSpan={3}>入室中の生徒がいません</td>
    </tr>
  );
};

type RowProps = {
  stay: StayingStudentStateInterface;
};
const renderRow = (props: RowProps) => {
  const { stay } = props;
  return (
    <tr
      key={`sectionStay-${stay.student.id}`}
      className="box-border h-[5.5rem] border-0 border-b border-solid border-gray-400 [&:last-child]:border-b-0"
    >
      <td className="text-left">
        <Link
          to={`/students/${stay.student.id}`}
          className={"ml-6 font-bold text-black"}
        >
          {stay.student.fullName}
        </Link>
      </td>
      <td>{stay.student.jobHuman}</td>
      <td>{stay.enteredSection?.sectionName}</td>
    </tr>
  );
};

export default SectionStayingStudentsTable;
