export type Content = {
  id: string;
  name: string;
  url: string | null;
  updatedAt: Date;
  fileSize: number | null;
  contentType: ContentTypes;
  ltiToolName: string | null;
  availableStartAt: Date | null;
  availableEndAt: Date | null;
};

export const create = (content: Partial<Content>): Content => ({
  id: content.id ?? "",
  name: content.name ?? "",
  url: content.url ?? "",
  updatedAt: content.updatedAt ?? new Date(),
  fileSize: content.fileSize ?? null,
  contentType: content.contentType ?? "ContentVideo",
  ltiToolName: content.ltiToolName ?? null,
  availableStartAt: content.availableStartAt ?? null,
  availableEndAt: content.availableEndAt ?? null,
});

export const FileTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
] as const;
export type FileTypes = (typeof FileTypes)[number];
export type ContentTypes = FileTypes | "ContentVideo" | "LtiResourceLink";

export const fromJson = (json: unknown): Content => {
  const record = json as Record<string, any>;

  if (typeof record.updatedAt !== "string") {
    throw new Error("Server responded updatedAt as unexpected value");
  }

  return {
    ...record,
    updatedAt: new Date(record.updatedAt),
  } as Content;
};

const oneGB = 1073741824;
const oneMB = 1048576;
const oneKB = 1024;
export const formatFileSize = (bytes: number): string => {
  if (bytes >= oneGB) {
    return Math.round((bytes / oneGB) * 10) / 10 + "GB";
  } else if (bytes >= oneMB) {
    return Math.round((bytes / oneMB) * 10) / 10 + "MB";
  } else if (bytes >= oneKB) {
    return Math.round((bytes / oneKB) * 10) / 10 + "KB";
  }
  return bytes + " bytes";
};

export const formatContentType = (
  filetype: ContentTypes,
  ltiToolName: Content["ltiToolName"],
): string => {
  switch (filetype) {
    case "application/pdf":
      return "PDF";
    case "image/jpg":
    case "image/jpeg":
      return "JPEG";
    case "image/png":
      return "PNG";
    case "ContentVideo":
      return "動画";
    case "LtiResourceLink":
      return ltiToolName ?? "";
  }
};

export const isVideoContent = (content: Content) =>
  content.contentType === "ContentVideo";
export const isEditable = (content: Content) =>
  content.contentType !== "LtiResourceLink";
export const uploadFileSizeLimit = oneMB * 100;
export const isValidFilesize = (size: number) => size < uploadFileSizeLimit;

export const nameLengthLimit = 50;
export const isValidFileName = (name: string) => name.length <= nameLengthLimit;
