import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import SectionLearningMaterialApi, {
  SearchLearningMaterialParams,
} from "../../../api-clients/SectionLearningMaterialApi";
import StudentLearningMaterialApi from "../../../api-clients/StudentLearningMaterialApi";
import {
  showErrorFlashMessage,
  showSuccessFlashMessage,
} from "../../flashMessage";
import { apiRequestError } from "../../apiRequestError";
import { buildMainResourceApiErrorAction } from "../../common/errors";

// 教材検索
const searchLearningMaterials = createAction("SEARCH_LEARNING_MATERIALS");
const searchLearningMaterialsSuccess = createAction(
  "SEARCH_LEARNING_MATERIALS_SUCCESS",
);
const searchLearningMaterialsError = createAction(
  "SEARCH_LEARNING_MATERIALS_ERROR",
);
export const dispatchSearchLearningMaterials =
  (sectionId: string, params: SearchLearningMaterialParams) =>
  (dispatch: Dispatch) => {
    dispatch(searchLearningMaterials());

    SectionLearningMaterialApi.search(sectionId, params)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(searchLearningMaterialsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(searchLearningMaterialsError());
        }
      })
      .catch(() => {
        dispatch(searchLearningMaterialsError());
      });
  };

// 教材もっと見る
const searchMoreLearningMaterials = createAction(
  "SEARCH_MORE_LEARNING_MATERIALS",
);
const searchMoreLearningMaterialsSuccess = createAction(
  "SEARCH_MORE_LEARNING_MATERIALS_SUCCESS",
);
const searchMoreLearningMaterialsError = createAction(
  "SEARCH_MORE_LEARNING_MATERIALS_ERROR",
);
export const dispatchSearchMoreLearningMaterials =
  (sectionId: string, params: SearchLearningMaterialParams) =>
  (dispatch: Dispatch) => {
    dispatch(searchMoreLearningMaterials());

    SectionLearningMaterialApi.search(sectionId, params)
      .then((res) => {
        if (res.ok) {
          res.json().then((json) => {
            dispatch(searchMoreLearningMaterialsSuccess(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(searchMoreLearningMaterialsError());
        }
      })
      .catch(() => {
        dispatch(searchMoreLearningMaterialsError());
      });
  };

// 本棚登録
const registerLearningMaterialToBookshelf = createAction(
  "REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF",
);
const registerLearningMaterialToBookshelfSuccess = createAction(
  "REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_SUCCESS",
);
const registerLearningMaterialToBookshelfError = createAction(
  "REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_ERROR",
);
export const dispatchRegisterLearningMaterialToBookshelf =
  (studentId: string, materialCode: string) => (dispatch: Dispatch) => {
    dispatch(registerLearningMaterialToBookshelf());

    StudentLearningMaterialApi.registerToBookshelf(studentId, materialCode)
      .then((res) => {
        if (res.ok) {
          dispatch(
            registerLearningMaterialToBookshelfSuccess({ materialCode }),
          );
          dispatch(showSuccessFlashMessage("生徒の本棚に登録しました"));
        } else if (res.status === 422) {
          // 配布されていないデジタル教材を登録しようとした際に発生する
          dispatch(registerLearningMaterialToBookshelfError());
          dispatch(
            showErrorFlashMessage(
              "この教材を利用するには、事前申し込みが必要です。",
            ),
          );
        } else {
          dispatch(registerLearningMaterialToBookshelfError());
          dispatch(showErrorFlashMessage("生徒の本棚に登録できませんでした"));
        }
      })
      .catch(() => {
        dispatch(registerLearningMaterialToBookshelfError());
        dispatch(apiRequestError());
      });
  };

// リセット
export const resetLearningMaterialsSearchResult = createAction(
  "RESET_LEARNING_MATERIALS_SEARCH_RESULT",
);
