import Icon from "../../../components/atoms/Icon";
import LinkButton from "../../../components/atoms/LinkButton";
import {
  CursorAvailability,
  PaginateDirectionTypes,
} from "../../../domains/LectureStudentAnalytics";
import styles from "./PaginationToolbar.scss";

export type Props = {
  onMovePagination: (paginateDirection: PaginateDirectionTypes) => void;
  cursor: CursorAvailability;
};

export const PaginationToolbar = ({ onMovePagination, cursor }: Props) => {
  return (
    <div className={styles.paginationToolbar}>
      <LinkButton
        aria-label="前の期間"
        className={`${cursor.availablePrev ? "" : styles.unavailableCursor}`}
        onClick={() => {
          if (cursor.availablePrev) {
            onMovePagination("prev");
          }
        }}
      >
        <Icon
          name="icon-arrow-left"
          color="gray-darken-1"
          {...(cursor.availablePrev ? { hoverColor: "primary" } : {})}
        />
      </LinkButton>
      <LinkButton
        aria-label="次の期間"
        className={`${cursor.availableNext ? "" : styles.unavailableCursor}`}
        onClick={() => {
          if (cursor.availableNext) {
            onMovePagination("next");
          }
        }}
      >
        <Icon
          name="icon-arrow-right"
          color="gray-darken-1"
          {...(cursor.availableNext ? { hoverColor: "primary" } : {})}
        />
      </LinkButton>
    </div>
  );
};
