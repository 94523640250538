import * as React from "react";
import { SectionExaminationDetailStudent } from "../../../../domains/SectionExamination";
import { calculateDeviation } from "../../../../helpers/AnalyticsHelper";

type DeviationMapRow = ReadonlyArray<{
  point: number;
  deviation: number;
}>;

export type DeviationMap = {
  [id: string]: DeviationMapRow;
};

type Props = {
  studentResults?: SectionExaminationDetailStudent[] | null;
  columns: string[];
};

// calculateDeviation して得た値をメモ化するhooks
export const useDeviationMap = ({ studentResults, columns }: Props) =>
  React.useMemo<DeviationMap | null>(() => {
    if (!studentResults) return null;

    // 科目ごとの点数の配列の配列
    const pointsPerSubject = [
      studentResults.map((studentResult) => studentResult.result?.point),
      ...columns.map((_, index) =>
        studentResults.map(
          (studentResult) => studentResult.subjectResults[index].point,
        ),
      ),
    ];

    // 科目ごとの偏差値の配列の配列
    const deviationsPerSubject = [
      studentResults.map((studentResult) => studentResult.result?.deviation),
      ...columns.map((_, index) =>
        studentResults.map(
          (studentResult) => studentResult.subjectResults[index].deviation,
        ),
      ),
    ];

    const deviationMap: Record<string, { point: number; deviation: number }[]> =
      {};

    studentResults.forEach((studentResult) => {
      deviationMap[studentResult.id] = [
        {
          point: calculateDeviation(
            pointsPerSubject[0],
            studentResult.result?.point ?? 0,
          ),
          deviation: calculateDeviation(
            deviationsPerSubject[0],
            studentResult.result?.deviation ?? 0,
          ),
        },
        ...studentResult.subjectResults.map((subjectResult, index) => ({
          point: calculateDeviation(
            pointsPerSubject[index + 1],
            subjectResult.point ?? 0,
          ),
          deviation: calculateDeviation(
            deviationsPerSubject[index + 1],
            subjectResult.deviation ?? 0,
          ),
        })),
      ];
    });

    return deviationMap;
  }, [studentResults]);
