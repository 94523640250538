import StudentTag from "../domains/StudentTag";

const PathHelper = {
  getStudentsPath: (sectionId: string, tag?: StudentTag): string => {
    if (tag) {
      switch (tag.type) {
        case "job":
          return `/sections/${sectionId}/settings/students?job[]=${tag.id}`;
        case "tag_ids":
          return `/sections/${sectionId}/settings/students?tag_ids[]=${tag.id}`;
        case "status":
          return `/sections/${sectionId}/settings/students?status[]=${tag.id}`;
        case "smart_tag":
          return `/sections/${sectionId}/settings/students?smart_tag[]=${tag.id}`;
        default:
          return `/sections/${sectionId}/settings/students`;
      }
    } else {
      return `/students`;
    }
  },
};

export default PathHelper;
