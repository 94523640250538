import SectionsSettingsTagsStateInterface from "../../../interfaces/SectionsSettingsTagsState";
import { Action } from "redux-actions";

const defaultState: SectionsSettingsTagsStateInterface = {
  submitting: false,
  loading: false,
  apiError: {},
};

const sectionsSettingsTagsState = (
  state: SectionsSettingsTagsStateInterface = defaultState,
  action: Action<any>,
): SectionsSettingsTagsStateInterface => {
  switch (action.type) {
    case "sections/settings/tags/INIT":
      return defaultState;
    case "sections/settings/CREATE_TAG_REQUEST":
    case "sections/settings/UPDATE_TAG_REQUEST":
    case "sections/settings/DELETE_TAG_REQUEST":
      return {
        ...state,
        submitting: true,
      };

    case "sections/settings/CREATE_TAG_SUCCESS":
      return {
        ...state,
        submitting: false,
        apiError: {
          ...state.apiError,
          new: [],
        },
      };

    case "sections/settings/UPDATE_TAG_SUCCESS":
      return {
        ...state,
        submitting: false,
        apiError: {
          ...state.apiError,
          [action.payload.tag.id]: [],
        },
      };

    case "sections/settings/DELETE_TAG_SUCCESS":
    case "sections/settings/DELETE_TAG_FAILURE":
      return {
        ...state,
        submitting: false,
      };

    case "sections/settings/CREATE_TAG_FAILURE":
      return {
        ...state,
        submitting: false,
        apiError: {
          ...state.apiError,
          new: (action.payload && action.payload.errors) || [],
        },
      };
    case "sections/settings/UPDATE_TAG_FAILURE":
      return {
        ...state,
        submitting: false,
        apiError: {
          ...state.apiError,
          [action.payload.tagId]: action.payload
            ? action.payload.errors || []
            : [],
        },
      };
    default:
      return state;
  }
};

export default sectionsSettingsTagsState;
