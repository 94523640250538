import classNames from "classnames";
import { FieldProps } from "formik";
import * as React from "react";

import styles from "./styles.scss";

interface FormikProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "form">,
    FieldProps<string, never> {
  hasError?: boolean;
  fullSize?: boolean;
}
interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
  fullSize?: boolean;
}

const Textarea: React.FC<Props | FormikProps> = (props) => {
  if ("field" in props) {
    return <FormikTextarea {...props} />;
  } else {
    return <PlainTextarea {...props} />;
  }
};

const FormikTextarea: React.FC<FormikProps> = (props) => {
  const { form, field, hasError, ...attributes } = props;
  const touched = !!form.touched[field.name];
  const error = !!form.errors[field.name];

  return (
    <PlainTextarea
      {...attributes}
      {...field}
      hasError={hasError || (touched && error)}
    />
  );
};

const PlainTextarea: React.FC<Props> = (props) => {
  const { hasError, fullSize = false, ...attributes } = props;
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  React.useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; //毎回0にすることでテキスト入力エリアのscrollHightを正しく計算する
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [attributes.value]);
  return (
    <textarea
      ref={textAreaRef}
      {...attributes}
      className={classNames(props.className, styles.root, {
        [styles.hasError]: hasError,
        [styles.full]: fullSize,
      })}
    />
  );
};

export default Textarea;
