import StudentInterface from "../../../interfaces/StudentInterface";
import QRCodeModal from "./QRCodeModal";

export const EntryQR = ({ student }: { student: StudentInterface }) => {
  if (student.status === "inviting" || student.status === "active") {
    return (
      <QRCodeModal
        qrcodeString={`student:${student.id}`}
        title={`${student.fullName}さんの入退室QRコード`}
      />
    );
  } else {
    return null;
  }
};
