import { useCallback, useState } from "react";
import {
  DateRange as DayPickerDateRange,
  DayClickEventHandler,
  Matcher,
} from "react-day-picker";

import { StyledDayPicker } from "../StyledDayPicker";

export type DateRange = {
  startDate?: Date;
  endDate?: Date;
};
type Props = {
  startDate?: Date;
  endDate?: Date;
  onDateRangeChange: (dateRange: DateRange) => void;
  numberOfMonths?: number;
  disabled?: Matcher | Matcher[];
  defaultMonth?: Date;
};
// 期間をカレンダーから選択するためのコンポーネント
// 期間の日数が決められている場合は DateRangePicker を使用する
export const DynamicDateRangePicker = (props: Props) => {
  const { startDate, endDate, onDateRangeChange, ...dayPickerProps } = props;
  const dateRange: DayPickerDateRange = { from: startDate, to: endDate };

  const onSelect = useCallback(
    (dateRange?: DayPickerDateRange) => {
      onDateRangeChange({
        startDate: dateRange?.from,
        endDate: dateRange?.to,
      });
    },
    [onDateRangeChange],
  );

  const [targetDays, setTargetDays] = useState<DayPickerDateRange | null>(null);
  const onDayClick: DayClickEventHandler = useCallback(
    (date) => {
      if (startDate != null && endDate == null) {
        if (startDate <= date) {
          onSelect({ from: startDate, to: date });
        } else {
          onSelect({ from: date, to: startDate });
        }
      } else {
        onSelect({ from: date });
      }
    },
    [startDate, endDate, onSelect],
  );
  const onDayMouseEnter = useCallback(
    (date: any) => {
      if (startDate != null && endDate == null) {
        setTargetDays({ from: startDate, to: date });
      } else {
        setTargetDays({ from: date });
      }
    },
    [startDate, endDate],
  );
  const onDayMouseLeave = useCallback(() => {
    setTargetDays(null);
  }, []);

  return (
    <StyledDayPicker
      mode="range"
      onDayClick={onDayClick}
      onDayFocus={onDayMouseEnter}
      onDayMouseEnter={onDayMouseEnter}
      onDayMouseLeave={onDayMouseLeave}
      selected={dateRange}
      modifiers={{
        highlighted: targetDays ?? false,
        highlightedStart: targetDays?.from ?? false,
      }}
      {...dayPickerProps}
    />
  );
};
