import { useEffect, useState } from "react";

const onBeforeUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = "";
};

// beforeunloadでページ遷移時に確認ダイアログを表示する
export const useNavigationBlocker = (): [boolean, (on: boolean) => void] => {
  const [isOn, set] = useState(false);

  useEffect(() => {
    if (isOn) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }
    return () => {
      if (isOn) {
        window.removeEventListener("beforeunload", onBeforeUnload);
      }
    };
  }, [isOn]);

  return [isOn, set];
};
