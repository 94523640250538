import * as React from "react";
import styles from "./styles.scss";
import { Modal } from "../../../../components/atoms/Modal";
import { useCourseSelector } from "./useCourseSelector";
import Button from "../../../../components/atoms/Button";
import Loader from "../../../../components/atoms/Loader";
import { useQuery } from "@tanstack/react-query";
import { boronClient } from "../../../../api";
import { createError, HTTPErrors } from "../../../../errors";
import { LinkableGoogleClassroomCourseInterface } from "../../../../interfaces/SectionsSettingsGoogleClassroomInterface";
import { useQueryError } from "../../../../hooks/http/useQueryError";
import { paths } from "../../../../lib/api/v1";

interface Props {
  submitting: boolean;
  isOpen: boolean;
  closeModal: () => void;
  linkCourses: (courseIds: string[]) => void;
}

type CourseResponse =
  paths["/api/v1/google_classroom/courses"]["get"]["responses"]["200"]["content"]["application/json"]["courses"][0];

const GoogleClassroomCourseLinkModal: React.FC<Props> = (props) => {
  const { data, isLoading, error } = useQuery<CourseResponse[], HTTPErrors>({
    queryKey: ["/api/v1/google_classroom/courses"],
    queryFn: async () => {
      const { response, data } = await boronClient.GET(
        "/api/v1/google_classroom/courses",
      );
      if (response.ok && data) {
        return data.courses;
      }
      throw await createError(response);
    },
    enabled: props.isOpen,
  });
  useQueryError(error);

  return (
    <Modal isOpen={props.isOpen}>
      <Modal.Header onClose={props.closeModal}>
        <h2>同期するクラスを選択</h2>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader loading />
        ) : data && data.length > 0 ? (
          <CourseList
            linkableCourses={data}
            linkCourses={props.linkCourses}
            submitting={props.submitting}
          />
        ) : (
          <CourseNotFound {...props} />
        )}
      </Modal.Body>
    </Modal>
  );
};

type CourseListProps = {
  linkableCourses: LinkableGoogleClassroomCourseInterface[];
  linkCourses: (courseIds: string[]) => void;
  submitting: boolean;
};
const CourseList: React.FC<CourseListProps> = (props) => {
  const [selectedCourseIds, isCourseSelected, handleChange] =
    useCourseSelector();

  const handleSubmit = () => {
    props.linkCourses(selectedCourseIds);
  };

  return (
    <>
      <div className={styles.list}>
        {props.linkableCourses.map((course) => {
          return (
            <div
              className={styles.list__row}
              key={`linkable_course_${course.courseId}`}
            >
              <input
                type="checkbox"
                name={`google_classroom_course_${course.courseId}`}
                className={styles.list__checkbox}
                checked={isCourseSelected(course.courseId)}
                onChange={handleChange(course.courseId)}
              />
              <div
                className={styles.list__name}
                onClick={handleChange(course.courseId)}
              >
                {course.name}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.submit}>
        <Button
          disabled={props.submitting || selectedCourseIds.length === 0}
          onClick={handleSubmit}
          isLoading={props.submitting}
        >
          クラスを追加
        </Button>
      </div>
    </>
  );
};

const CourseNotFound: React.FC<Props> = (props) => {
  return (
    <>
      <h2 className={styles.notfound}>
        Google Classroomのクラスを取得できませんでした。
      </h2>
      <div className={styles.notfound__text}>
        連携中のGoogle
        Classroom側で「クラスの作成」または「クラスの参加」を行い、再度クラスの同期を行ってください。クラスに所属しているのに取得できない場合は、「アカウント設定」のGoogleアカウントとの連携状態をご確認ください。
      </div>
      <div className={styles.submit}>
        <Button onClick={props.closeModal}>閉じる</Button>
      </div>
    </>
  );
};

export default GoogleClassroomCourseLinkModal;
