import { useEffect } from "react";
import { HandleThunkActionCreator, connect } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { Navigate } from "react-router";
import { SettingsEmailStateInterface } from "../../../interfaces/SettingsEmailStateInterface";
import Loader from "../../../components/atoms/Loader";
import { putEmail } from "../../../actions/pages/SettingsEmail/index";
import withErrorPages from "../../../hocs/withErrorPages";
import { withRouter, WithRouterProps } from "../../../helpers/RouterHelper";

interface StateProps {
  settingsEmail: SettingsEmailStateInterface;
}

interface DispatchProps {
  putEmail: HandleThunkActionCreator<typeof putEmail>;
}

type Props = DispatchProps & StateProps & WithRouterProps<{ token: string }>;

const SettingsEmailPage = (props: Props) => {
  useEffect(() => {
    const { token } = props.params;
    props.putEmail(token);
  }, []);

  if (props.settingsEmail.loading) {
    return <Loader loading={props.settingsEmail.loading} />;
  } else {
    return <Navigate to={"/login"} replace />;
  }
};

const mapStateToProps = (state: AppStateInterface) => {
  return {
    settingsEmail: state.settingsEmail,
  };
};

const actions = {
  putEmail,
};

export default withRouter<Props>(
  withErrorPages(connect(mapStateToProps, actions)(SettingsEmailPage)),
);
