import ApiResponse from "../interfaces/ApiResponse";
import { SectionAnnounceMessagesRecipientsStateInterface } from "../interfaces/SectionAnnounceMessagesStateInterface";
import { PER_PAGE } from "../api";
import { AnyAction } from "redux";

const destinationTagRecipients = (
  state: SectionAnnounceMessagesRecipientsStateInterface = defaultDestinationTagRecipientsState,
  action: AnyAction,
) => {
  const { payload } = action;

  switch (action.type) {
    // 宛先選択モーダルの宛先タグの受信者一覧状態を変えるアクション
    case "GET_INITIAL_DESTINATION_TAG_OPTION_RECIPIENTS":
      return {
        ...defaultDestinationTagRecipientsState,
        loading: true,
      };
    case "GET_DESTINATION_TAG_OPTION_RECIPIENTS":
      return {
        ...state,
        loading: true,
      };

    case "GET_INITIAL_DESTINATION_TAG_OPTION_RECIPIENTS_SUCCESS":
    case "GET_DESTINATION_TAG_OPTION_RECIPIENTS_SUCCESS":
      return appendSuccessResponse(state, payload.students);

    default:
      return state;
  }
};

export const defaultDestinationTagRecipientsState: SectionAnnounceMessagesRecipientsStateInterface =
  {
    loading: false,
    hasMore: true,
    data: null,
    meta: {
      currentPage: 0,
      totalPages: 0,
    },
  };

const concatData = (prevData: any[] | null, data: any[]): any[] => {
  if (prevData === null) {
    return data;
  } else {
    return prevData.concat(data);
  }
};

const appendSuccessResponse = (
  state: SectionAnnounceMessagesRecipientsStateInterface,
  response: ApiResponse<any[]>,
) => {
  const { meta, data } = response;

  return {
    ...state,
    hasMore: response.data.length === PER_PAGE,
    loading: false,
    data: concatData(state.data, data),
    meta,
  };
};

export default destinationTagRecipients;
