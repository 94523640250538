import * as React from "react";
import { MessageFormStateInterface } from "../../../../../interfaces/MessageInterface";
import MessageThreadInterface from "../../../../../interfaces/MessageThreadInterface";
import { boronClient, makeFormData } from "../../../../../api";
import { useMutation } from "@tanstack/react-query";
import { UnprocessableEntityError } from "../../../../../errors";
import compressImage from "../../../../../helpers/compressImage";
import { paths } from "../../../../../lib/api/v1";
import { useFlashMessage } from "../../../../../hooks/useFlashMessage";
import { useUpdateMessageThreadLatestMessage } from "../../useFetchMessageThreads";
import { useInsertDirectMessageCache } from "../useFetchMessages";

type MessageResponse =
  paths["/api/v1/students/{student_id}/direct_messages"]["post"]["responses"]["200"]["content"]["application/json"]["message"];
type ZoomMeetingMessageRequestBody =
  paths["/api/v1/students/{student_id}/direct_messages/zoom_meetings"]["post"]["requestBody"]["content"]["application/json"];
type MessageRequestBody =
  paths["/api/v1/students/{student_id}/direct_messages"]["post"]["requestBody"]["content"]["multipart/form-data"];

const initialValues = { content: "", file: null };
const initialFormState: MessageFormStateInterface = {
  values: initialValues,
  apiErrors: [],
  submitting: false,
};

const MESSAGE_POST_ERROR = "メッセージを送信できませんでした";

type Props = {
  sectionId: string;
  messageThread: MessageThreadInterface;
};

export const useDirectMessageForm = (props: Props) => {
  const { showErrorMessage, showSuccessMessage } = useFlashMessage();

  const { updateMessageThreadLatestMessage } =
    useUpdateMessageThreadLatestMessage({
      sectionId: props.sectionId,
    });

  const { insertDirectMessageCache } = useInsertDirectMessageCache();

  const [formStates, setFormStates] = React.useState<{
    [messageThreadId: string]: MessageFormStateInterface;
  }>({});

  const setFormState = (state: Partial<MessageFormStateInterface>) => {
    setFormStates((prevFormStates) => {
      if (!props.messageThread || !props.messageThread.id) {
        return prevFormStates;
      }

      return {
        ...prevFormStates,
        [props.messageThread.id]: {
          ...initialFormState,
          ...prevFormStates[props.messageThread.id],
          ...state,
        },
      };
    });
  };

  const formState: MessageFormStateInterface | undefined =
    props.messageThread && props.messageThread.id
      ? props.messageThread.id in formStates
        ? formStates[props.messageThread.id]
        : initialFormState
      : undefined;

  // フォーム送信後に、送信成功時にフォームの入力内容をリセット
  React.useEffect(() => {
    if (
      props.messageThread &&
      !formState?.submitting &&
      formState?.apiErrors.length == 0
    ) {
      setFormState({
        values: initialValues,
      });
    }
  }, [formState?.submitting]);

  const resetValues = () => setFormState({ values: initialValues });

  const successPostMessage = (message: MessageResponse) => {
    showSuccessMessage("メッセージを送信しました");
    updateMessageThreadLatestMessage(props.messageThread.id, {
      lastReadAt: new Date().toISOString(),
      latestMessage: message,
    });
    insertDirectMessageCache(props.messageThread.student.id, message);
  };

  const { mutate: postMessage } = useMutation<
    MessageResponse | undefined,
    void,
    { content: string } | { file: File }
  >({
    mutationFn: async (params) => {
      setFormState({ submitting: true });

      let body: MessageRequestBody = {};
      try {
        body = await buildMessageBody(params);
      } catch (error) {
        if (error instanceof ImageCompressError) {
          showErrorMessage(MESSAGE_POST_ERROR);
          setFormState({
            submitting: false,
            apiErrors: [
              {
                field: "content",
                message:
                  "画像ファイルのファイル形式はimage/jpeg, image/gif, image/pngにしてください。pdfファイルのファイル形式はapplication/pdfにしてください。",
              },
            ],
          });
          return;
        } else {
          throw error;
        }
      }

      const { response, data, error } = await postMessageRequest({
        studentId: props.messageThread.student.id,
        body,
      });
      setFormState({ submitting: false, apiErrors: [] });

      if (response.ok && data) {
        successPostMessage(data.message);
        return data.message;
      }

      showErrorMessage(MESSAGE_POST_ERROR);
      if (response.status === 422 && error && error.errors) {
        setFormState({ apiErrors: error.errors });
      }
    },
  });

  const { mutate: postZoomMeetingMessage } = useMutation<
    MessageResponse | undefined,
    UnprocessableEntityError,
    ZoomMeetingMessageRequestBody
  >({
    mutationFn: async (body) => {
      setFormState({ submitting: true });

      const { response, data, error } = await boronClient.POST(
        "/api/v1/students/{student_id}/direct_messages/zoom_meetings",
        {
          params: {
            path: { student_id: props.messageThread.student.id },
          },
          body,
        },
      );
      setFormState({ submitting: false, apiErrors: [] });

      if (response.ok && data) {
        successPostMessage(data.message);
        return data.message;
      }

      showErrorMessage(MESSAGE_POST_ERROR);
      if (response.status === 422 && error && error.errors) {
        setFormState({ apiErrors: error.errors });
        throw new UnprocessableEntityError(error.errors);
      }
    },
  });

  const handlePostMessage = (content: string) => {
    postMessage({ content }, { onSuccess: resetValues });
  };
  const handlePostMessageFile = (file: File) => {
    postMessage({ file }, { onSuccess: resetValues });
  };

  const handleChangeContent = (content: string) => {
    setFormState({
      values: { ...initialValues, ...formState?.values, content },
    });
  };

  const handleChangeFile = (file: File | null) => {
    setFormState({ values: { ...initialValues, ...formState?.values, file } });
  };

  const handlePostZoomMeetingMessage = (
    params: ZoomMeetingMessageRequestBody,
    setSubmitting: (submitting: boolean) => void,
    setErrors: (errors: Record<string, unknown>) => void,
    onSuccessCallback: () => void,
  ): void => {
    setSubmitting(true);
    postZoomMeetingMessage(params, {
      onSettled: () => setSubmitting(false),
      onSuccess: onSuccessCallback,
      onError: (error) => {
        const errors: Record<string, any> = [];
        error.originalErrors.forEach((error) => {
          if (error.field) {
            errors[error.field] = error.message;
          }
        });
        setErrors(errors);
      },
    });
  };
  return {
    formState,
    handlePostMessage,
    handlePostMessageFile,
    handleChangeContent,
    handleChangeFile,
    handlePostZoomMeetingMessage,
  };
};

const prepareImageForUpload = async (image: File) => {
  try {
    return await compressImage(image);
  } catch {
    throw new ImageCompressError();
  }
};

const buildMessageBody = async (
  params: { content: string } | { file: File },
) =>
  "file" in params
    ? params.file.type === "application/pdf"
      ? { file: params.file }
      : {
          image: await prepareImageForUpload(params.file),
        }
    : { content: params.content };

const postMessageRequest = async ({
  studentId,
  body,
}: {
  studentId: string;
  body: MessageRequestBody;
}) => {
  return await boronClient.POST(
    "/api/v1/students/{student_id}/direct_messages",
    {
      params: {
        path: { student_id: studentId },
      },
      body,
      bodySerializer:
        "file" in body || "image" in body ? makeFormData : undefined,
    },
  );
};

class ImageCompressError extends Error {
  constructor() {
    super("Failed to compress image");
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
