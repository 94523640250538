import { useMutation } from "@tanstack/react-query";
import { HTTPErrors, createError } from "../../../../../../errors";
import { boronClient } from "../../../../../../api";
import { StudyRecordCommentInterface } from "../../../../../../interfaces/StudyRecordInterface";

interface Props {
  comment: StudyRecordCommentInterface;
  studentId: string;
  studyRecordHashid: string;
  onSuccess: (comment: StudyRecordCommentInterface) => void;
}

export const useDeleteStudyRecordComment = ({
  comment,
  studentId,
  studyRecordHashid,
  onSuccess,
}: Props) => {
  return useMutation<StudyRecordCommentInterface, HTTPErrors, void>({
    mutationFn: () =>
      deleteStudyRecordComment({
        comment,
        studentId,
        studyRecordHashid,
      }),
    onSuccess,
  });
};

const deleteStudyRecordComment = async ({
  comment,
  studentId,
  studyRecordHashid,
}: Omit<Props, "onSuccess">) => {
  const url =
    "/api/v1/students/{student_id}/study_records/{study_record_hashid}/comments/{study_record_comment_hashid}";

  const { response } = await boronClient.DELETE(url, {
    params: {
      path: {
        student_id: studentId,
        study_record_hashid: studyRecordHashid,
        study_record_comment_hashid: comment.id,
      },
    },
  });

  if (!response.ok) {
    throw await createError(response);
  }

  return comment;
};
