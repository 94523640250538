import * as React from "react";
import { TableHeadColumn } from "../../../components/atoms/Table";
import styles from "./AttendeesTable.scss";
import { CheckboxColumGrid, ChecboxColumnGridText } from "./CheckboxColumnGrid";
import {
  MixedCheckbox,
  MixedCheckValue,
} from "../../../components/general/MixedCheckbox";
import { useBatchActionDispatchContext } from "./AttendBatchActionContext";

type TableHeaderProps = {
  allSelected: MixedCheckValue;
};
export const TableHeader_ = ({ allSelected }: TableHeaderProps) => {
  const dispatcher = useBatchActionDispatchContext();
  return (
    <thead>
      <tr>
        <TableHeadColumn centering className={styles.NameColumnHeader}>
          <CheckboxColumGrid noGap>
            <MixedCheckbox
              id="attendees-header"
              value={allSelected}
              onChanged={dispatcher.toggleAllTargets}
            />
            <ChecboxColumnGridText>氏名</ChecboxColumnGridText>
          </CheckboxColumGrid>
        </TableHeadColumn>
        <TableHeadColumn centering className={styles.StudentJobColumnHeader}>
          職業(学年)
        </TableHeadColumn>
        <TableHeadColumn
          centering
          className={styles.AttendanceStatusColumnHeader}
        >
          出席状況
        </TableHeadColumn>
        <TableHeadColumn centering className={styles.UpdaterColumnHeader}>
          入力種別
        </TableHeadColumn>
        <TableHeadColumn centering className={styles.UpdatedAtColumnHeader}>
          記録日時
        </TableHeadColumn>
        <TableHeadColumn centering className={styles.AbsenseColumnHeader}>
          欠席理由
        </TableHeadColumn>
        <TableHeadColumn centering className={styles.StaffNoteColumnHeader}>
          職員共有メモ
        </TableHeadColumn>
      </tr>
    </thead>
  );
};

export const TableHeader = React.memo(TableHeader_);
