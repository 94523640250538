import { BillingPlan } from "../../../domains/BillingPlan";

type Props = {
  billingPlan: BillingPlan;
};

export const BillingPlanLabel = ({ billingPlan }: Props) => {
  return (
    <div className={`${baseClassNames} ${classNames[billingPlan]}`}>
      {billingPlan}
    </div>
  );
};

const baseClassNames = `flex items-center justify-center font-bold rounded-sm w-6 capitalize py-1 leading-none text-xs`;

const classNames = {
  free: `bg-gray-400 text-black`,
  pro: `bg-blue-200 text-blue-400`,
};
