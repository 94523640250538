// 出席管理機能で使う各種リソースのアクティブ/アーカイブ状態
export const ArchiveStatus = {
  active: "active",
  archived: "archived",
} as const;
export type ArchiveStatus = keyof typeof ArchiveStatus;

export const translateMutateArchiveStatusResult = (
  archiveStatus: ArchiveStatus,
  result: "success" | "failure",
) => {
  switch (archiveStatus) {
    case "active":
      return result === "success"
        ? "アクティブに戻しました"
        : "アクティブに戻せませんでした";
    case "archived":
      return result === "success"
        ? "アーカイブしました"
        : "アーカイブできませんでした";
    default: {
      const invalidStatus: never = archiveStatus;
      throw `${invalidStatus}は不正な値です`;
    }
  }
};
export const invertArchiveStatus = (archiveStatus: ArchiveStatus) =>
  archiveStatus === ArchiveStatus.active
    ? ArchiveStatus.archived
    : ArchiveStatus.active;
