import SectionInterface from "../../interfaces/SectionInterface";
import { useParams } from "react-router-dom";
import { useFetchLectureDetail } from "../../hooks/http/useFetchLectureDetail";
import { useQueryError } from "../../hooks/http/useQueryError";

export type HooksProps = {
  section: SectionInterface;
};
export const useSectionAnalyticsLectureStudentPage = ({
  section,
}: HooksProps) => {
  const { lectureId = "" } = useParams<{ lectureId: string }>();
  const {
    isLoading,
    data: lecture,
    error,
  } = useFetchLectureDetail({
    section,
    lectureId,
    skip: false,
  });
  useQueryError(error);

  return {
    isLoading,
    lecture,
  };
};
