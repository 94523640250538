import { LearningMaterialTag } from "../../../domains/LearningMaterialTag";

export type DefaultLearningMaterialTagFilterOptions = ReadonlyArray<
  Pick<LearningMaterialTag, "name" | "tagType">
>;
/**
 * デフォルトで選択する教材タグ
 * 現状タグの名前とタグの種別で文字列比較して特定するしかないため、ここに保持しておく
 */
export const defaultLearningMaterialTagFilterOptions: DefaultLearningMaterialTagFilterOptions =
  [
    { name: "英語", tagType: "default" },
    { name: "数学", tagType: "default" },
    { name: "国語", tagType: "default" },
    { name: "理科", tagType: "default" },
    { name: "社会", tagType: "default" },
  ];
