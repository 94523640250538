import styles from "./LectureTransferReadModal.scss";
import classNames from "classnames";
import { Modal } from "../../../components/atoms/Modal/index";
import { Schedule } from "../../../domains/Schedule";
import {
  LectureAttendanceForSection,
  LectureAttendanceForTransfer,
} from "../../../domains/LectureAttendance";
import { Link } from "react-router-dom";
import { Text } from "../../../components/general/Text/Text";
import { Flex } from "../../../components/atoms/Flex";
import { Button } from "@studyplus/boron-ui";
import Icon from "../../../components/atoms/Icon";
import Loader from "../../../components/atoms/Loader/index";
import { toDateString, toTimeString } from "../../../helpers/TimeHelper";
import { useFetchLectureTransfer } from "./useFetchLectureTransfer";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { useMutateLectureTransfer } from "./useMutateLectureTransfer";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import subDays from "date-fns/subDays";

type Props = {
  sectionId: string;
  isOpen: boolean;
  lectureAttendance: LectureAttendanceForSection | null;
  closeModal: () => void;
};
const LectureTransferReadModal = ({
  sectionId,
  isOpen,
  lectureAttendance,
  closeModal,
}: Props) => {
  const {
    studentProps,
    fromLectureAttendanceProps,
    toLectureAttendanceProps,
    isLoading,
    handleDelete,
    isPending,
  } = useLectureTransferRead({
    sectionId,
    targetLectureAttendance: lectureAttendance,
    closeModal,
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.modalContainer}
    >
      <Modal.Header onClose={closeModal}>
        <Text className={styles.modalHeaderContainer}>
          <Link
            to={`/students/${studentProps.publicId}/calendar`}
            className={styles.studentName}
          >
            {studentProps.fullName}
          </Link>
          <span aria-label={`${studentProps.fullName}の振替情報`}>
            の振替情報
          </span>
        </Text>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loader loading />
        ) : (
          <>
            <Text bold>
              {fromLectureAttendanceProps.isOtherLectureSession
                ? "振替元講座予定"
                : "この講座予定"}
            </Text>
            <div
              className={styles.fromLectureAttendanceContainer}
              aria-label="振替元の情報"
            >
              <Text>
                <ScheduleSummary
                  isLinkable={fromLectureAttendanceProps.isOtherLectureSession}
                  summary={fromLectureAttendanceProps.summary}
                  sectionId={fromLectureAttendanceProps.sectionId}
                  lectureSessionId={fromLectureAttendanceProps.lectureSessionId}
                />
              </Text>
              <Text>
                <ScheduleTime
                  startAt={fromLectureAttendanceProps.startAt}
                  endAt={fromLectureAttendanceProps.endAt}
                  allday={fromLectureAttendanceProps.allday}
                  sectionName={fromLectureAttendanceProps.sectionName}
                />
              </Text>
            </div>
            <Text align="center">
              <Icon
                name="icon-arrow-drop-down"
                className={styles.arrowDownIcon}
              ></Icon>
            </Text>
            <Text bold>
              {toLectureAttendanceProps.isOtherLectureSession
                ? "振替先講座予定"
                : "この講座予定"}
            </Text>
            <div
              className={styles.toLectureAttendanceContainer}
              aria-label="振替先の情報"
            >
              <Flex justifyContent="space-between" marginTop="0">
                <Text>
                  <ScheduleSummary
                    isLinkable={toLectureAttendanceProps.isOtherLectureSession}
                    summary={toLectureAttendanceProps.summary}
                    sectionId={toLectureAttendanceProps.sectionId}
                    lectureSessionId={toLectureAttendanceProps.lectureSessionId}
                  />
                </Text>
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.deleteButton}
                    size="xs"
                    variant="outline"
                    onClick={handleDelete}
                    disabled={isPending}
                  >
                    <Icon name="icon-trash" />
                    削除
                  </Button>
                </div>
              </Flex>
              <Text>
                <ScheduleTime
                  startAt={toLectureAttendanceProps.startAt}
                  endAt={toLectureAttendanceProps.endAt}
                  allday={toLectureAttendanceProps.allday}
                  sectionName={toLectureAttendanceProps.sectionName}
                />
              </Text>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

type UseLectureTransferRead = {
  sectionId: string;
  targetLectureAttendance: LectureAttendanceForSection | null;
  closeModal: () => void;
};

export const useLectureTransferRead = ({
  sectionId,
  targetLectureAttendance,
  closeModal,
}: UseLectureTransferRead) => {
  const { isLoading, data, error } = useFetchLectureTransfer({
    sectionId,
    lectureAttendanceId: targetLectureAttendance?.id ?? "",
  });

  useQueryError(error);

  const studentProps = {
    publicId: data?.student.id,
    fullName: data?.student.fullName,
  };

  const makeLectureAttendanceProps = (
    lectureAttendance: LectureAttendanceForTransfer | undefined,
  ) => {
    return {
      lectureSessionId: lectureAttendance?.lectureSessionId ?? "",
      isOtherLectureSession:
        lectureAttendance?.lectureSessionId !==
        targetLectureAttendance?.lectureSessionId,
      summary: lectureAttendance?.schedule.summary ?? "",
      startAt:
        lectureAttendance?.schedule.startAt &&
        new Date(lectureAttendance?.schedule.startAt),
      endAt:
        lectureAttendance?.schedule.endAt &&
        new Date(lectureAttendance?.schedule.endAt),
      allday: lectureAttendance?.schedule.allday,
      sectionId: lectureAttendance?.section.id ?? "",
      sectionName: lectureAttendance?.section.sectionName ?? "",
    };
  };

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const { mutate, isPending } = useMutateLectureTransfer({
    sectionId,
    lectureAttendanceId: data?.toLectureAttendance.id ?? "",
  });

  const handleDelete = () => {
    if (
      !window.confirm(
        `${studentProps.fullName}の振替情報を本当に削除しますか？`,
      )
    ) {
      return;
    }

    mutate(undefined, {
      onSuccess() {
        showSuccessMessage("振替情報を削除しました。");
        closeModal();
      },
      onError() {
        showErrorMessage("振替情報を削除できませんでした。");
      },
    });
  };

  return {
    studentProps,
    fromLectureAttendanceProps: makeLectureAttendanceProps(
      data?.fromLectureAttendance,
    ),
    toLectureAttendanceProps: makeLectureAttendanceProps(
      data?.toLectureAttendance,
    ),
    isLoading,
    handleDelete,
    isPending,
  };
};

type ScheduleSummaryProps = {
  isLinkable: boolean;
  summary: string;
  sectionId: string;
  lectureSessionId: string;
};

const ScheduleSummary = ({
  isLinkable,
  summary,
  sectionId,
  lectureSessionId,
}: ScheduleSummaryProps) => {
  if (isLinkable) {
    return (
      <Link
        to={`/sections/${sectionId}/lecture_sessions/${lectureSessionId}/attendees`}
        className={styles.summary}
      >
        {summary}
      </Link>
    );
  }
  return <>{summary}</>;
};

type ScheduleTimeProps = Partial<
  Pick<Schedule, "allday" | "startAt" | "endAt"> & {
    sectionName: string;
  }
>;

const ScheduleTime = ({
  allday,
  startAt,
  endAt,
  sectionName,
}: ScheduleTimeProps) => {
  if (!startAt || !endAt || !sectionName) return null;

  const startDateString = toDateString(startAt, "/");
  const startAtString = toTimeString(startAt);
  const endDateString = allday
    ? toDateString(subDays(endAt, 1), "/")
    : toDateString(endAt, "/");
  const endAtString = toTimeString(endAt);

  return (
    <Flex alignItems="center">
      <Icon name="icon-calendar" className={styles.calendarIcon} />
      <div>
        <time>
          <span
            className={classNames(
              styles["u-margin-left-0.5"],
              styles["u-margin-right-0.5"],
            )}
            aria-label={`開始日:${startDateString}`}
          >
            {startDateString}
          </span>
          {allday ? null : (
            <span
              className={styles["u-margin-right-0.5"]}
              aria-label={`開始時間:${startAtString}`}
            >
              {startAtString}
            </span>
          )}
          -
          <span
            className={styles["u-margin-left-0.5"]}
            aria-label={
              allday ? `終了日:${endDateString}` : `終了時間:${endAtString}`
            }
          >
            {allday ? endDateString : endAtString}
          </span>
        </time>
      </div>
      <span className={styles["u-margin-left-2"]}>{sectionName}</span>
    </Flex>
  );
};

export default LectureTransferReadModal;
