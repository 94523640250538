import { useCallback } from "react";
import { FlashMessageType } from "../interfaces/FlashMessageInterface";
import { useDispatch } from "react-redux";

export function useFlashMessage() {
  const dispatch = useDispatch();

  const showSuccessMessage = useCallback(
    (message: string) => {
      dispatch({
        type: "SET_FLASH_MESSAGE",
        payload: { type: FlashMessageType.SUCCESS, message },
      });
    },
    [dispatch],
  );

  const showErrorMessage = useCallback(
    (message: string) => {
      dispatch({
        type: "SET_FLASH_MESSAGE",
        payload: { type: FlashMessageType.ERROR, message },
      });
    },
    [dispatch],
  );

  const hideFlashMessage = useCallback(() => {
    dispatch({ type: "RESET_FLASH_MESSAGE" });
  }, [dispatch]);

  return { showSuccessMessage, showErrorMessage, hideFlashMessage };
}
