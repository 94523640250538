import { createAction } from "redux-actions";
import StudentKarteApi from "../api-clients/StudentKarteApi";
import { Dispatch } from "redux";
import { PostKarteParamsInterface } from "../api-clients/StudentKarteApi";
import KarteInterface from "../interfaces/KarteInterface";
import ApiErrorResponseInterface from "../interfaces/ApiErrorResponseInterface";
import { apiRequestError } from "./apiRequestError";
import FiltersHelper from "../helpers/FiltersHelper";
import { showSuccessFlashMessage, showErrorFlashMessage } from "./flashMessage";
import { buildMainResourceApiErrorAction } from "./common/errors";

// カルテ投稿
const postKarteSending = createAction("POST_KARTE_SENDING");
const postKarteSuccess = createAction("POST_KARTE_SUCCESS");
const postKarteError = createAction("POST_KARTE_ERROR");
export const postKarte =
  (studentId: string, params: PostKarteParamsInterface, callback: () => void) =>
  (dispatch: Dispatch): void => {
    dispatch(postKarteSending());
    StudentKarteApi.postKarte(studentId, params)
      .then((res) => {
        if (res.ok) {
          res.json().then((json: { karte: KarteInterface }) => {
            const payload = {
              karte: json.karte,
            };
            dispatch(postKarteSuccess(payload));
            dispatch(showSuccessFlashMessage("カルテを作成しました"));
            callback();
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(postKarteError(json));
            dispatch(showErrorFlashMessage("カルテを作成できませんでした"));
          });
        } else {
          dispatch(postKarteError());
          dispatch(showErrorFlashMessage("カルテを作成できませんでした"));
        }
      })
      .catch((err: any) => {
        console.error(err);
        dispatch(postKarteError());
        dispatch(apiRequestError());
      });
  };

// カルテ更新
const updateKarteSending = createAction("UPDATE_KARTE_SENDING");
const updateKarteSuccess = createAction("UPDATE_KARTE_SUCCESS");
const updateKarteError = createAction("UPDATE_KARTE_ERROR");
export const updateKarte =
  (
    studentId: string,
    karteId: string,
    params: PostKarteParamsInterface,
    callback: () => void,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(updateKarteSending());
    StudentKarteApi.updateKarte(studentId, karteId, params)
      .then((res) => {
        if (res.ok) {
          dispatch(showSuccessFlashMessage("カルテを更新しました"));
          res.json().then((json: { karte: KarteInterface }) => {
            dispatch(updateKarteSuccess(json));
            callback();
          });
        } else if (res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            dispatch(updateKarteError(json));
            dispatch(showErrorFlashMessage("カルテを更新できませんでした"));
          });
        } else {
          dispatch(updateKarteError());
          dispatch(showErrorFlashMessage("カルテを更新できませんでした"));
        }
      })
      .catch((err: any) => {
        console.error(err);
        dispatch(updateKarteError());
        dispatch(apiRequestError());
      });
  };

// カルテ一覧
export const getStudentKartesRequestSending = createAction(
  "GET_STUDENT_KARTES_REQUEST_SENDING",
);
export const getInitialKartesRequestSending = createAction(
  "GET_INITIAL_STUDENT_KARTES_REQUEST_SENDING",
);
export const getStudentKartesResponseReceived = createAction(
  "GET_STUDENT_KARTES_RESPONSE_RECEIVED",
);
export const getStudentKartesResponseError = createAction(
  "GET_STUDENT_KARTES_RESPONSE_ERROR",
);

// 初期ロード
export const loadInitialStudentKartes =
  (studentId: string) =>
  (dispatch: Dispatch): void => {
    dispatch(getInitialKartesRequestSending());

    StudentKarteApi.getKartes(studentId)
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getStudentKartesResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentKartesResponseError());
        }
      })
      .catch(() => {
        dispatch(getStudentKartesResponseError());
      });
  };

// 追加読み込み
export const loadStudentKartes =
  (studentId: string, page?: number) =>
  (dispatch: Dispatch): void => {
    dispatch(getStudentKartesRequestSending());

    const query = FiltersHelper.toQueryString({ page });

    StudentKarteApi.getKartes(studentId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getStudentKartesResponseReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getStudentKartesResponseError());
        }
      })
      .catch(() => {
        dispatch(getStudentKartesResponseError());
      });
  };
