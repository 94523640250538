import { SectionOperatorInvitationInterface } from "../interfaces/SectionOperatorInvitationInterface";

const sectionOperatorInvitations = (
  state: SectionOperatorInvitationInterface[] = [],
  action: ReduxActions.Action<any>,
): SectionOperatorInvitationInterface[] => {
  switch (action.type) {
    case "GET_SECTION_OPERATOR_INIVITATIONS_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};

export default sectionOperatorInvitations;
