import * as React from "react";
import styles from "./styles.scss";
import TabsNavi from "../../molecules/TabsNavi/index";
import { CommentInterface } from "../../../interfaces/CommentInterface";
import Loader from "../../atoms/Loader/index";
import { SectionStudyRecordCommentsStateInterface } from "../../../interfaces/SectionStudyRecordCommentsStateInterface";
import CircleIcon from "../../atoms/CircleIcon";
import TimeHelper from "../../../helpers/TimeHelper";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { truncate } from "../../../helpers/String";

interface Props {
  commentsState: SectionStudyRecordCommentsStateInterface;
}

class SectionStudyRecordComments extends React.Component<Props> {
  get comments(): CommentInterface[] {
    return this.props.commentsState.data;
  }

  get loading(): boolean {
    return this.props.commentsState.loading;
  }

  render(): React.ReactElement {
    return (
      <React.Fragment>
        <TabsNavi
          tabs={[
            {
              label: "お知らせ",
              link: `#`,
              active: true,
            },
          ]}
        />
        <div className={styles.list}>{this.renderList()}</div>
        <Loader loading={this.loading} />
      </React.Fragment>
    );
  }

  renderList = (): React.ReactNode => {
    if (this.comments.length > 0) {
      return this.comments.map((comment: CommentInterface) => {
        return this.renderItem(comment);
      });
    }
  };

  renderItem = (comment: CommentInterface): React.ReactElement => {
    return (
      <Link
        to={this.notificationPath(comment)}
        key={`study_record_comment-${comment.id}`}
        className={classNames(styles.item, {
          [styles.highlight]: !comment.isRead,
        })}
      >
        <div className={styles.left}>
          <CircleIcon
            iconProps={{ name: "icon-notice-comment" }}
            className={styles.icon}
          />
        </div>
        <div className={styles.right}>
          <div>{this.renderNotificationData(comment)}</div>
          <div className={styles.date}>
            {TimeHelper.defaultFormat(comment.createdAt)}
          </div>
        </div>
      </Link>
    );
  };

  notificationPath = (comment: CommentInterface): string => {
    return `/students/${comment.student.id}/study_records/${comment.studyRecord.id}`;
  };

  renderNotificationData = (comment: CommentInterface): React.ReactElement => {
    const { studyRecord, body } = comment;
    const { fullName } = comment.student;
    return (
      <span>
        <span className={styles.bold}>{fullName}</span>が
        <span className={styles.bold}>
          {TimeHelper.defaultFormat(studyRecord.recordedAt)}
        </span>
        の勉強記録のコメントに返信しました：「{truncate(body, 20)}」
      </span>
    );
  };
}

export default SectionStudyRecordComments;
