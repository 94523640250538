import * as React from "react";
import styles from "./styles.scss";
import Settings from "../../../helpers/Settings";
import { SectionDetailInterface } from "../../../interfaces/SectionInterface";
import LtiDeploymentInterface from "../../../interfaces/LtiDeploymentInterface";
import { AuthenticatedPageCallbacks } from "../../../hocs/enhanceAuthenticatedPage";
import TabsNavi from "../../../components/molecules/TabsNavi";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { ContentIndex } from "./ContentIndex/index";
import Icon from "../../../components/atoms/Icon";
import { InlineBlock } from "../../../components/atoms/InlineBlock";
import { Flex } from "../../../components/atoms/Flex/index";
import { Link } from "react-router-dom";
import Loader from "../../../components/atoms/Loader";
import { useSectionsSettingsContentsPage } from "./useSectionsSettingsContentsPage";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface OwnProps {
  section: SectionDetailInterface;
}

interface Props
  extends WithRouterProps,
    AuthenticatedPageCallbacks,
    OwnProps,
    OutletContextProps {}

type ImportFromMexcbtButtonProps = {
  mexcbtLtiDeployment: LtiDeploymentInterface;
  sectionId: SectionDetailInterface["id"];
};

const ImportFromMexcbtButton = (props: ImportFromMexcbtButtonProps) => {
  return (
    <a
      href={`${Settings.BORON_URL}/lti/tools/${props.mexcbtLtiDeployment.oauthApplicationUid}/sections/${props.sectionId}/operator/messages/lti_deep_linking_request`}
      target="_blank"
      rel="noopener noreferrer"
      className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm ${styles.buttonLink}`}
    >
      MEXCBTから登録
      <InlineBlock marginLeft="1rem">
        <Icon name="icon-external-link" />
      </InlineBlock>
    </a>
  );
};

const SectionsSettingsContentsPage_: React.FC<Props> = (props) => {
  React.useEffect(() => {
    props.setActiveMenu("courses");
  }, []);
  const {
    isLoading,
    isFetchingNextPage,
    contents,
    isDeleting,
    onDelete,
    mexcbtLtiDeployment,
    tabs,
  } = useSectionsSettingsContentsPage({
    sectionId: props.section.id,
    ltiDeployments: props.section.ltiDeployments,
  });

  return (
    <>
      <TabsNavi tabs={tabs} />
      <Flex justifyContent="flex-end" marginTop="1.4rem" wrap>
        {mexcbtLtiDeployment ? (
          <ImportFromMexcbtButton
            mexcbtLtiDeployment={mexcbtLtiDeployment}
            sectionId={props.section.id}
          />
        ) : null}
        <Link
          to={`/sections/${props.section.id}/settings/content_videos/new`}
          className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm ${styles.buttonLink}`}
        >
          <Icon name="icon-video" className={styles.icon} />
          動画のURLの登録
        </Link>
        <Link
          to={`/sections/${props.section.id}/settings/content_files/new`}
          className={`buiButtonBase buiButtonVariantOutline buiButtonSizeSm ${styles.buttonLink}`}
        >
          <Icon name="icon-file" className={styles.icon} />
          ファイルの登録
        </Link>
      </Flex>
      <div className={styles.tableWrapper}>
        {!isLoading ? (
          <ContentIndex
            sectionId={props.section.id}
            contents={contents}
            onDelete={onDelete}
            isMutating={isDeleting}
          />
        ) : null}
        <Loader loading={isLoading || isFetchingNextPage} />
      </div>
    </>
  );
};

const pageInfo = {
  title: "コンテンツ",
};

export const SectionsSettingsContentsPage = enhanceSectionsSettingsPage(
  SectionsSettingsContentsPage_,
  pageInfo,
  ["contents"],
);
