import * as React from "react";
import MainLayout from "../../../components/atoms/MainLayout";
import SectionSidebar from "../../SectionSidebar";
import SectionGlobalHeader from "../../SectionGlobalHeader";
import { Outlet } from "react-router";
import withErrorPages from "../../../hocs/withErrorPages";
import { withRouter } from "../../../helpers/RouterHelper";
import enhanceAuthenticatedPage from "../../../hocs/enhanceAuthenticatedPage";
import {
  AuthenticatedPageProps,
  AuthenticatedPageCallbacks,
} from "../../../hocs/enhanceAuthenticatedPage";

type Props = AuthenticatedPageProps & AuthenticatedPageCallbacks;

const SectionLayout = (props: Props) => {
  const [activeMenu, setActiveMenu] = React.useState<string>("");

  return (
    <MainLayout>
      <SectionSidebar {...props} activeMenu={activeMenu} />
      <SectionGlobalHeader {...props} />
      <Outlet context={{ setActiveMenu }} />
    </MainLayout>
  );
};

export default withRouter(
  withErrorPages(enhanceAuthenticatedPage(SectionLayout)),
);
