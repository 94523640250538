import { useInfiniteQuery } from "@tanstack/react-query";
import Api from "../../api";
import ApiResponse from "../../interfaces/ApiResponse";
import { Student } from "../../domains/Student";
import { stringify } from "query-string";
import { getNextPageParam } from "../../helpers/ReactQueryHelper";
import { createError, HTTPErrors } from "../../errors";
import { StatusType } from "../../interfaces/StudentInterface";

// APIからタグ全件欲しいときは十分に大きい数字をperに入れて投げるという仕様
const MAX_PER = 1000000000;

export type UseFetchStudentsOptions = {
  job?: string[];
  tag_ids?: string[];
  smart_tag?: string[];
  status?: StatusType[];
  billing_plan?: "pro" | "free";
};

export type UseFetchSectionStudentsProps = {
  sectionId: string;
  params: UseFetchStudentsOptions | null;
  shouldGetAllRecords?: boolean;
  enabled: boolean;
};

type ResponseBody = ApiResponse<Student[]>;

export const useFetchSectionStudents = ({
  sectionId,
  params,
  shouldGetAllRecords = false,
  enabled,
}: UseFetchSectionStudentsProps) => {
  const query =
    shouldGetAllRecords && params ? { per: MAX_PER, ...params } : params;
  const queryParams = stringify(query ?? {}, { arrayFormat: "bracket" });
  const url = `/sections/${sectionId}/students?${queryParams}`;

  const result = useInfiniteQuery<ResponseBody, HTTPErrors>({
    queryKey: [url],
    queryFn: async ({ pageParam }) => {
      const res = await Api.interruptGet(`/api/v1${url}&page=${pageParam}`);
      if (res.ok) {
        const json = await res.json();
        return json.students as ResponseBody;
      }
      throw await createError(res);
    },
    initialPageParam: 1,
    enabled,
    getNextPageParam,
  });

  const data = result.data?.pages.flatMap((page) => page.data);
  return { ...result, data };
};
