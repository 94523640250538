import Table, { TableColumn } from "../../atoms/Table";
import styles from "./styles.scss";
import SkillInterface from "../../../interfaces/SkillInterface";
import { Link } from "react-router-dom";
import SectionInterface from "../../../interfaces/SectionInterface";
import SwitchButton from "../../atoms/SwitchButton/index";
import Icon from "../../atoms/Icon";
import AlignMiddle from "../../atoms/AlignMiddle/index";
import { connect, ResolveThunks } from "react-redux";
import {
  deleteSkill,
  enableSkill,
  disableSkill,
} from "../../../actions/pages/SectionsSettingsSkillsPage/index";

interface OwnProps {
  skills: SkillInterface[];
  section: SectionInterface;
  submitting: boolean;
  loading: boolean;
}

interface DispatchProps {
  deleteSkill: typeof deleteSkill;
  enableSkill: typeof enableSkill;
  disableSkill: typeof disableSkill;
}

type Props = OwnProps & ResolveThunks<DispatchProps>;

const actions = { deleteSkill, enableSkill, disableSkill };

const handleChangeEnabled =
  (props: Props, skill: SkillInterface) =>
  (value: boolean): Promise<boolean> => {
    if (value) {
      return props.enableSkill(props.section.id, skill.id);
    } else {
      let confirmMessage = "アシスタントスキルをオフにしてよろしいですか？";
      if (Object.hasOwnProperty.call(skill.arguments, "smartTag")) {
        confirmMessage +=
          "\nオフにすると、生徒に付いているスマートタグも外れます。";
      }

      if (window.confirm(confirmMessage)) {
        return props.disableSkill(props.section.id, skill.id);
      }
      return Promise.resolve(true);
    }
  };

const renderSkillIcon = (skill: SkillInterface) => {
  switch (skill.skillMaster.type) {
    case "study_record_skill_master":
    default:
      return <img src={`/images/icon-skill-study_time.png`} />;
  }
};

const renderSkills = (props: Props) => {
  return props.skills.map((skill) => (
    <tr key={`skill-${skill.id}`}>
      <TableColumn className={styles.nameColumn} centering={false}>
        <div className={styles.skillMaster}>
          {renderSkillIcon(skill)}
          <div className={styles.skillName}>
            <Link
              className={styles.name}
              to={`/sections/${props.section.id}/settings/skills/${skill.id}/edit`}
            >
              {skill.name}
            </Link>
          </div>
        </div>
      </TableColumn>
      <TableColumn centering={false} className={styles.descriptionColumn}>
        <div className={styles.description}>{skill.description}</div>
      </TableColumn>
      <TableColumn centering={true} className={styles.enabledColumn}>
        <SwitchButton
          value={skill.enabled}
          onChange={handleChangeEnabled(props, skill)}
        />
      </TableColumn>
      <TableColumn centering={true} className={styles.editColumn}>
        <Link
          to={`/sections/${props.section.id}/settings/skills/${skill.id}/edit`}
          className={styles.link}
        >
          <AlignMiddle>
            <Icon name="icon-edit" />
            <span className={styles.link__text}>編集</span>
          </AlignMiddle>
        </Link>
      </TableColumn>
      <TableColumn centering={true} className={styles.deleteColumn}>
        <button
          disabled={props.submitting}
          onClick={handleDeleteSkill(skill, props)}
          className={styles.text_button}
        >
          <AlignMiddle>
            <Icon name="icon-trash" />
            <span className={styles.link__text}>削除</span>
          </AlignMiddle>
        </button>
      </TableColumn>
    </tr>
  ));
};

const handleDeleteSkill = (skill: SkillInterface, props: Props) => () => {
  if (props.submitting) return;

  if (skill.enabled) {
    window.alert(
      `スキルは「オン」の状態では削除できません。「オフ」にしてから削除してください。`,
    );
    return;
  }

  if (
    window.confirm(
      `スキル「${skill.name}」を削除してもよろしいですか？\n削除すると、すでに送信されているアシスタントメッセージも削除されます。`,
    )
  ) {
    props.deleteSkill(props.section.id, skill.id);
  }
};

const SectionSkills = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.buttonRow}>
        <Link
          to={`/sections/${props.section.id}/settings/skill_masters`}
          className={`buiButtonBase buiButtonVariantFilled`}
        >
          新規作成
        </Link>
      </div>
      <div className={styles.skills}>
        {props.skills.length === 0 ? (
          <p className={styles.noSkillsText}>
            アシスタントスキルの登録がありません。右上の「新規作成」からアシスタントスキルを登録できます。
          </p>
        ) : (
          <Table
            headers={[
              { text: "アシスタントスキル名", centering: true },
              { text: "説明", centering: true },
              { text: "ステータス", centering: true },
              { text: "", centering: true },
            ]}
            rows={renderSkills(props)}
            headColumnClassName={styles.headColumn}
          />
        )}
      </div>
    </div>
  );
};

export default connect<void, DispatchProps, OwnProps>(
  null,
  actions,
)(SectionSkills);
