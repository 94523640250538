import {
  StudentMessagesStateInterface,
  MessageInterface,
} from "../../../interfaces/MessageInterface";
import {
  ActionTypes,
  Actions,
} from "../../../actions/pages/studentMessages/types";

export const defaultStudentMessagesState: StudentMessagesStateInterface = {
  loading: false,
  hasMore: true,
  meta: {},
  data: [],
  apiErrors: [],
  submitting: false,
  isFollowing: null,
};

const studentMessages = (
  state: StudentMessagesStateInterface = defaultStudentMessagesState,
  action: Actions,
): StudentMessagesStateInterface => {
  switch (action.type) {
    // Loading
    case ActionTypes.INITIAL_GET_REQUEST:
      return {
        ...defaultStudentMessagesState,
        loading: true,
      };

    case ActionTypes.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_REQUEST:
    case ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_REQUEST:
      return {
        ...state,
        submitting: true,
      };

    // Success Response
    case ActionTypes.GET_SUCCESS: {
      const { directMessages, botMessages, meta } = action.payload;
      const data: MessageInterface[] = [...directMessages, ...botMessages].sort(
        (a: MessageInterface, b: MessageInterface) =>
          a.sentAt > b.sentAt ? -1 : 1,
      );

      return {
        ...state,
        hasMore: data.length > 0,
        loading: false,
        data: [...state.data, ...data],
        meta,
      };
    }

    case ActionTypes.FOLLOW_THREAD_SUCCESS:
      return {
        ...state,
        isFollowing: true,
      };

    case ActionTypes.UNFOLLOW_THREAD_SUCCESS:
      return {
        ...state,
        isFollowing: false,
      };

    case ActionTypes.GET_FOLLOW_STATUS_SUCCESS:
      return {
        ...state,
        isFollowing: action.payload,
      };

    case ActionTypes.POST_MESSAGE_REQUEST:
    case ActionTypes.POST_FILE_REQUEST:
      return {
        ...state,
        submitting: true,
      };

    case ActionTypes.POST_MESSAGE_SUCCESS:
    case ActionTypes.POST_FILE_SUCCESS:
    case ActionTypes.POST_ZOOM_MEETING_MESSAGE_SUCCESS:
      return {
        ...state,
        data: [action.payload.message, ...state.data],
        apiErrors: [],
        submitting: false,
      };

    case ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: state.data.map((message) => {
          return message.id === action.payload.messageId
            ? {
                ...message,
                status: "done",
              }
            : message;
        }),
      };

    case ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: state.data.map((message) => {
          return message.id === action.payload.messageId
            ? {
                ...message,
                status: "ignored",
              }
            : message;
        }),
      };

    // failed responses
    case ActionTypes.POST_MESSAGE_FAILURE:
    case ActionTypes.POST_FILE_FAILURE:
      return {
        ...state,
        apiErrors: action.payload ? action.payload.errors || [] : [],
        submitting: false,
      };

    case ActionTypes.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.CHANGE_BOT_MESSAGE_STATUS_DONE_FAILURE:
    case ActionTypes.CHANGE_BOT_MESSAGE_STATUS_IGNORED_FAILURE:
      return {
        ...state,
        submitting: false,
      };

    case ActionTypes.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        data: state.data.map((message): MessageInterface => {
          return message.id === action.payload.message.id
            ? action.payload.message
            : message;
        }),
      };

    default:
      return state;
  }
};

export default studentMessages;
