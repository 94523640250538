import LtiDeploymentInterface from "../../../interfaces/LtiDeploymentInterface";

type UseTabs = {
  sectionId: string;
  ltiDeployments: LtiDeploymentInterface[];
};

export const useTabs = ({ sectionId, ltiDeployments }: UseTabs) => {
  const basicTabs = [
    {
      label: "コンテンツ",
      active: false,
      link: `/sections/${sectionId}/settings/contents`,
    },
    {
      label: "コース",
      active: true,
      link: "#",
    },
  ];

  const tabs =
    ltiDeployments.length == 0
      ? basicTabs
      : [
          ...basicTabs,
          {
            label: "学習結果",
            active: false,
            link: `/sections/${sectionId}/settings/content_courses/results`,
          },
        ];

  return tabs;
};
