import { createStore, applyMiddleware, compose, Store, AnyAction } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import "./styles/global.scss";
import AppStateInterface from "./interfaces/AppStateInterface";
import Settings from "./helpers/Settings";
import * as Sentry from "@sentry/react";

const persistConfig = {
  key: "redux",
  storage,
  whitelist: ["flashMessage"],
};

const persistedReducer = persistReducer<AppStateInterface, AnyAction>(
  persistConfig,
  rootReducer,
);

// development
const createStoreForDev = () => {
  const composeWithDevtools = (window as any)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = composeWithDevtools
    ? composeWithDevtools({
        trace: true,
      })
    : compose;

  return createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );
};

// production
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    // 個人情報が含まれるsessionはsentryに送信しない
    return { ...state, session: null };
  },
});
const createStoreForProd = () => {
  return createStore(
    persistedReducer,
    compose(applyMiddleware(thunk), sentryReduxEnhancer),
  );
};

export const createStoreForEnvironment = () => {
  if (Settings.ENVIRONMENT === "prod") {
    return createStoreForProd();
  } else {
    return createStoreForDev();
  }
};

const store: Store<AppStateInterface & PersistPartial, AnyAction> =
  createStoreForEnvironment();

// redux-persistのpersistorオブジェクト
// ref: https://github.com/rt2zz/redux-persist#persistor-object
// componentからpersistorオブジェクトのイベントを発火させたい場合があるので
// export して、外で使えるようにしてある
export const persistor = persistStore(store, {});

// store
export default store;
