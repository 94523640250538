import { Dispatch, AnyAction } from "redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { ThunkAction } from "redux-thunk";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
  FlashMessageAction,
} from "../../flashMessage";
import { ActionTypes, Action } from "./types";
import ApiClient from "../../../api";
import AuthenticationsStateInterface from "../../../interfaces/AuthenticationsStateInterface";
import { resetApp } from "../resetApp";

// アカウント切り替え
export const dispatchSwitchOperator =
  (operator: OperatorInterface, options?: { withRedirect?: boolean }) =>
  (dispatch: Dispatch<Action | FlashMessageAction>): void => {
    dispatch({ type: ActionTypes.SWITCH_OPERATOR });

    ApiClient.put(`/api/v1/switch_account?login_hint=${operator.id}`)
      .then((response: Response) => {
        if (response.ok) {
          response.json().then((json: AuthenticationsStateInterface) => {
            // アカウント切り替え時は権限の関係で見れていいものなどが変わるので
            // 一度すべての状態をリセットする
            resetApp()(dispatch);

            const action: Action = {
              type: options?.withRedirect
                ? ActionTypes.SWITCH_OPERATOR_WITH_REDIRECT_SUCCESS
                : ActionTypes.SWITCH_OPERATOR_SUCCESS,
              payload: json,
            };

            dispatch(action);
          });
        } else {
          dispatch(
            showErrorFlashMessage("アカウントを切り替えられませんでした"),
          );
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("アカウントを切り替えられませんでした"));
      });
  };

// サインアウト
export const dispatchSignOut =
  (): ThunkAction<void, AppStateInterface, undefined, AnyAction> =>
  (dispatch: Dispatch<Action | FlashMessageAction>): void => {
    ApiClient.delete(`/api/v1/sign_out`)
      .then((response: Response) => {
        if (response.ok) {
          response.json().then((json: AuthenticationsStateInterface) => {
            dispatch({ type: ActionTypes.SIGN_OUT, payload: json });
            dispatch(showSuccessFlashMessage("ログアウトしました"));
          });
        } else {
          dispatch(showErrorFlashMessage("ログアウトできませんでした"));
        }
      })
      .catch(() => {
        dispatch(showErrorFlashMessage("ログアウトできませんでした"));
      });
  };
