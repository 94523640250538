import { Dispatch } from "redux";
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
  FlashMessageAction,
  resetFlashMessage,
} from "../../flashMessage";
import OperatorSettingsApi from "../../../api-clients/OperatorSettingsApi";
import ApiErrorResponseInterface from "../../../interfaces/ApiErrorResponseInterface";
import ApiClient from "../../../api";
import { OperatorSettingsPasswordFormInterface } from "../../../interfaces/OperatorSettingsInterface";
import { ActionTypes, Action } from "./types";
import { Action as AuthAction } from "../../common/session/types";
import Settings from "../../../helpers/Settings";
import OperatorProfileInterface from "../../../interfaces/OperatorProfileInterface";
import { buildMainResourceApiErrorAction } from "../../common/errors";
import { ZoomMeetingSettingInterface } from "../../../interfaces/OperatorProfileInterface";
import snakeCase from "lodash-es/snakeCase";
import AuthenticationsStateInterface from "../../../interfaces/AuthenticationsStateInterface";
import { ActionTypes as UserInfoActionTypes } from "../../authorization/userInfo";

export const updateOperatorEmail =
  (email: string) =>
  async (
    dispatch: Dispatch<Action | AuthAction | FlashMessageAction>,
  ): Promise<void> => {
    dispatch({ type: ActionTypes.UPDATE_OPERATOR_EMAIL_REQUEST });
    try {
      const res = await OperatorSettingsApi.updateEmail(email);
      if (res.ok) {
        dispatch({ type: ActionTypes.UPDATE_OPERATOR_EMAIL_SUCCESS });
        const meResponse = await ApiClient.get(`/api/v1/me`);
        const json: AuthenticationsStateInterface = await meResponse.json();
        dispatch({
          type: UserInfoActionTypes.GET_USER_INFO_SUCCESS,
          payload: json,
        });
        dispatch(
          showSuccessFlashMessage(
            "メールアドレスを更新のため、メールを送信しました",
          ),
        );
      } else if (res.status === 422) {
        const json: ApiErrorResponseInterface = await res.json();
        dispatch(
          showErrorFlashMessage(
            "確認メールの送信に失敗しました。登録済みのメールアドレスには変更できません",
          ),
        );
        dispatch({
          type: ActionTypes.UPDATE_OPERATOR_EMAIL_ERROR,
          payload: json,
        });
      } else {
        dispatch({ type: ActionTypes.UPDATE_OPERATOR_EMAIL_ERROR });
        dispatch(showErrorFlashMessage("メールアドレスが更新できませんでした"));
      }
    } catch {
      dispatch({ type: ActionTypes.UPDATE_OPERATOR_EMAIL_ERROR });
      dispatch(showErrorFlashMessage("メールアドレスが更新できませんでした"));
    }
  };

export const updateOperatorName =
  (firstName: string, lastName: string) =>
  async (
    dispatch: Dispatch<Action | AuthAction | FlashMessageAction>,
  ): Promise<void> => {
    dispatch({ type: ActionTypes.UPDATE_OPERATOR_NAME_REQUEST });

    try {
      const res = await OperatorSettingsApi.updateName(firstName, lastName);
      if (res.ok) {
        dispatch({ type: ActionTypes.UPDATE_OPERATOR_NAME_SUCCESS });
        const meRepsonse = await ApiClient.get(`/api/v1/me`);
        const json: AuthenticationsStateInterface = await meRepsonse.json();
        dispatch({
          type: UserInfoActionTypes.GET_USER_INFO_SUCCESS,
          payload: json,
        });
        dispatch(showSuccessFlashMessage("アカウント名を更新しました"));
      } else if (res.status === 422) {
        const json: ApiErrorResponseInterface = await res.json();
        dispatch(showErrorFlashMessage("アカウント名を更新できませんでした"));
        dispatch({
          type: ActionTypes.UPDATE_OPERATOR_NAME_ERROR,
          payload: json,
        });
      } else {
        dispatch(showErrorFlashMessage("アカウント名を更新できませんでした"));
        dispatch({ type: ActionTypes.UPDATE_OPERATOR_NAME_ERROR });
      }
    } catch {
      dispatch(showErrorFlashMessage("アカウント名を更新できませんでした"));
      dispatch({ type: ActionTypes.UPDATE_OPERATOR_NAME_ERROR });
    }
  };

export const updateOperatorPassword =
  (params: OperatorSettingsPasswordFormInterface) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ActionTypes.UPDATE_OPERATOR_PASSWORD_REQUEST });

    try {
      const res = await OperatorSettingsApi.updatePassword(params);

      if (res.ok) {
        dispatch({ type: ActionTypes.UPDATE_OPERATOR_PASSWORD_SUCCESS });
        dispatch(
          showSuccessFlashMessage(
            "パスワードが更新されました。再度ログインしてください",
          ),
        );
        ApiClient.get(`/api/v1/me`).then((meResponse) => {
          if (meResponse.ok) {
            meResponse.json().then((json: AuthenticationsStateInterface) => {
              dispatch({
                type: UserInfoActionTypes.GET_USER_INFO_SUCCESS,
                payload: json,
              });
            });
          } else {
            dispatch({ type: UserInfoActionTypes.GET_USER_INFO_ERROR });
          }
        });
      } else if (res.status === 422) {
        const json: ApiErrorResponseInterface = await res.json();
        dispatch(showErrorFlashMessage("パスワードを更新できませんでした"));
        dispatch({
          type: ActionTypes.UPDATE_OPERATOR_PASSWORD_ERROR,
          payload: json,
        });
      } else {
        dispatch(showErrorFlashMessage("パスワードを更新できませんでした"));
        dispatch({ type: ActionTypes.UPDATE_OPERATOR_PASSWORD_ERROR });
      }
    } catch {
      dispatch(showErrorFlashMessage("パスワードを更新できませんでした"));
      dispatch({ type: ActionTypes.UPDATE_OPERATOR_PASSWORD_ERROR });
    }
  };

export const loadOperatorProfile =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ActionTypes.GET_OPERATOR_PROFILE_REQUEST });

    try {
      const response = await OperatorSettingsApi.getProfile();
      if (response.ok) {
        const json: {
          operator: OperatorProfileInterface;
        } = await response.json();
        dispatch({
          type: ActionTypes.GET_OPERATOR_PROFILE_SUCCESS,
          payload: json.operator,
        });
      } else {
        dispatch(buildMainResourceApiErrorAction(response.status));
      }
    } catch {
      dispatch(buildMainResourceApiErrorAction());
    }
  };

// GoogleClassroom連携
export const connectGoogleClassroom =
  (operatorId: string) =>
  (dispatch: Dispatch<Action | FlashMessageAction>): void => {
    dispatch({ type: ActionTypes.CONNECT_GOOGLE_CLASSROOM_REQUEST });
    dispatch(resetFlashMessage());
    const authURL = `${Settings.BORON_URL}/google/oauth?operator_id=${operatorId}`;
    location.href = authURL;
  };

export const connectGoogleClassroomError =
  () =>
  (dispatch: Dispatch<Action | FlashMessageAction>): void => {
    dispatch({ type: ActionTypes.CONNECT_GOOGLE_CLASSROOM_ERROR });
    dispatch(showErrorFlashMessage("GoogleClassroom連携に失敗しました"));
  };

export const disconnectGoogleClassroom =
  () =>
  async (dispatch: Dispatch<Action | FlashMessageAction>): Promise<void> => {
    dispatch({ type: ActionTypes.CONNECT_GOOGLE_CLASSROOM_REQUEST });

    try {
      const response = await ApiClient.delete(
        `/api/v1/google_classroom/deauthorize`,
      );

      if (response.ok) {
        dispatch({ type: ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_SUCCESS });
        dispatch(showSuccessFlashMessage("GoogleClassroom連携を解除しました"));
      } else {
        dispatch({ type: ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_ERROR });
        dispatch(
          showErrorFlashMessage("GoogleClassroom連携の解除に失敗しました"),
        );
      }
    } catch {
      dispatch({ type: ActionTypes.DISCONNECT_GOOGLE_CLASSROOM_ERROR });
      dispatch(
        showErrorFlashMessage("GoogleClassroom連携の解除に失敗しました"),
      );
    }
  };

// Zoom連携
export const connectZoom =
  (operatorId: string) =>
  (dispatch: Dispatch<Action | FlashMessageAction>): void => {
    dispatch({ type: ActionTypes.CONNECT_ZOOM_REQUEST });
    dispatch(resetFlashMessage());
    const authURL = `${Settings.BORON_URL}/zoom/oauth?operator_id=${operatorId}`;
    location.href = authURL;
  };

export const connectZoomError =
  () =>
  (dispatch: Dispatch<Action | FlashMessageAction>): void => {
    dispatch({ type: ActionTypes.CONNECT_ZOOM_ERROR });
    dispatch(showErrorFlashMessage("Zoom連携に失敗しました"));
  };

export const disconnectZoom =
  () =>
  async (dispatch: Dispatch<Action | FlashMessageAction>): Promise<void> => {
    dispatch({ type: ActionTypes.CONNECT_ZOOM_REQUEST });

    try {
      const response = await ApiClient.delete(`/api/v1/zoom/deauthorize`);

      if (response.ok) {
        dispatch({ type: ActionTypes.DISCONNECT_ZOOM_SUCCESS });
        dispatch(showSuccessFlashMessage("Zoom連携を解除しました"));
      } else {
        dispatch({ type: ActionTypes.DISCONNECT_ZOOM_ERROR });
        dispatch(showErrorFlashMessage("Zoom連携の解除に失敗しました"));
      }
    } catch {
      dispatch({ type: ActionTypes.DISCONNECT_ZOOM_ERROR });
      dispatch(showErrorFlashMessage("Zoom連携の解除に失敗しました"));
    }
  };

const snakeCaseZoomMeetingSettingParams = (
  params: ZoomMeetingSettingParams,
): Record<string, ZoomMeetingSettingParams[keyof ZoomMeetingSettingParams]> => {
  const requestParams: Record<
    string,
    ZoomMeetingSettingParams[keyof ZoomMeetingSettingParams]
  > = {};
  Object.keys(params).forEach((key: string) => {
    requestParams[snakeCase(key)] =
      params[key as keyof ZoomMeetingSettingParams];
  });

  return requestParams;
};

export type ZoomMeetingSettingParams = Omit<ZoomMeetingSettingInterface, "id">;

export const updateZoomMeetingSetting =
  (meetingId: string, params: ZoomMeetingSettingParams) =>
  async (dispatch: Dispatch<Action | FlashMessageAction>): Promise<void> => {
    dispatch({ type: ActionTypes.UPDATE_ZOOM_MEETING_SETTING_REQUEST });

    try {
      const requestParams = snakeCaseZoomMeetingSettingParams(params);
      const response = await ApiClient.patch(
        `/api/v1/zoom/meeting_settings/${meetingId}`,
        requestParams,
      );
      if (response.ok) {
        const json: {
          zoomMeetingSetting: ZoomMeetingSettingInterface;
        } = await response.json();
        dispatch({
          type: ActionTypes.UPDATE_ZOOM_MEETING_SETTING_SUCCESS,
          payload: json.zoomMeetingSetting,
        });
        dispatch(showSuccessFlashMessage("ミーティング設定を更新しました"));
      } else {
        const json: ApiErrorResponseInterface = await response.json();
        dispatch({
          type: ActionTypes.UPDATE_ZOOM_MEETING_SETTING_ERROR,
          payload: json,
        });
        dispatch(
          showErrorFlashMessage("ミーティング設定を更新できませんでした"),
        );
      }
    } catch {
      dispatch(showErrorFlashMessage("ミーティング設定を更新できませんでした"));
    }
  };
