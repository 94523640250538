import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import SectionStayApi from "../api-clients/SectionStayApi";
import {
  StayFormInterface,
  StayStateInterface,
} from "../interfaces/StayInterface";
import { HTTP_ERROR_MESSAGE } from "../reducers/index";
import { FlashMessageType } from "../interfaces/FlashMessageInterface";
import { setFlashMessage } from "./flashMessage";
import { apiRequestError } from "./apiRequestError";
import ApiResponse from "../interfaces/ApiResponse";
import ApiErrorResponseInterface from "../interfaces/ApiErrorResponseInterface";
import { buildMainResourceApiErrorAction } from "./common/errors";

const getSectionStaysRequest = createAction("GET_SECTION_STAYS_REQUEST");
const getSectionStaysInitialRequest = createAction(
  "GET_SECTION_STAYS_INITIAL_REQUEST",
);
const getSectionStaysReceived = createAction("GET_SECTION_STAYS_RECEIVED");
const getSectionStaysError = createAction("GET_SECTION_STAYS_ERROR");

export const getSectionStaysInitial =
  (sectionId: string, queryString?: string) => (dispatch: Dispatch) => {
    dispatch(getSectionStaysInitialRequest());
    const query = queryString;

    SectionStayApi.interruptGetStays(sectionId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getSectionStaysReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getSectionStaysError());
        }
      })
      .catch(() => {
        dispatch(getSectionStaysError());
      });
  };

export const getSectionStays =
  (sectionId: string, page?: number) => (dispatch: Dispatch) => {
    const query = location.search
      ? location.search + `&page=${page}`
      : `?page=${page}`;

    dispatch(getSectionStaysRequest());

    SectionStayApi.getStays(sectionId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: any) => {
            dispatch(getSectionStaysReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getSectionStaysError());
        }
      })
      .catch(() => {
        dispatch(getSectionStaysError());
      });
  };

export const interruptGetSectionStays =
  (sectionId: string, query: string) => (dispatch: Dispatch) => {
    dispatch(getSectionStaysInitialRequest());
    SectionStayApi.interruptGetStays(sectionId, { query })
      .then((res: any) => {
        if (res.ok) {
          res.json().then((json: ApiResponse<StayStateInterface[]>) => {
            dispatch(getSectionStaysReceived(json));
          });
        } else {
          dispatch(buildMainResourceApiErrorAction(res.status));
          dispatch(getSectionStaysError());
        }
      })
      .catch(() => {
        dispatch(getSectionStaysError());
      });
  };

const updateSectionStayRequest = createAction("UPDATE_SECTION_STAY_REQUEST");
const updateSectionStaySuccess = createAction("UPDATE_SECTION_STAY_SUCCESS");
const updateSectionStayError = createAction("UPDATE_SECTION_STAY_ERROR");

const updateSectionStayDefaultErrorMsg = {
  message: "入退室記録を更新できませんでした",
};
const updateSectionStayUnknownErrorMsg = { message: HTTP_ERROR_MESSAGE };
const setUpdateSectionStaySuccessMsg = (dispatch: Dispatch) => {
  const successMessage = {
    type: FlashMessageType.SUCCESS,
    message: "入退室記録を更新しました",
  };
  const flashAction = setFlashMessage(successMessage);
  dispatch(flashAction);
};

export const updateSectionStay =
  (sectionId: string, stayId: string, params: StayFormInterface) =>
  (dispatch: Dispatch) => {
    dispatch(updateSectionStayRequest());

    SectionStayApi.update(sectionId, stayId, params)
      .then((res: any) => {
        if (res.ok) {
          setUpdateSectionStaySuccessMsg(dispatch);
          res.json().then((json: { stay: ApiResponse<StayStateInterface> }) => {
            dispatch(updateSectionStaySuccess(json));
          });
        } else if (res.status === 403 || res.status === 422) {
          res.json().then((json: ApiErrorResponseInterface) => {
            const responseJson = {
              stayId: stayId,
              errors: json.errors,
            };
            dispatch(updateSectionStayError(responseJson));

            const payload = { errors: [updateSectionStayDefaultErrorMsg] };
            dispatch(apiRequestError(payload));
          });
        } else {
          dispatch(updateSectionStayError());

          const payload = { errors: [updateSectionStayUnknownErrorMsg] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(updateSectionStayError());
      });
  };

const deleteSectionStayRequest = createAction("DELETE_SECTION_STAY_REQUEST");
const deleteSectionStaySuccess = createAction("DELETE_SECTION_STAY_SUCCESS");
const deleteSectionStayError = createAction("DELETE_SECTION_STAY_ERROR");

const deleteSectionStayUnknownErrorMsg = { message: HTTP_ERROR_MESSAGE };
const setDeleteSectionStaySuccessMsg = (dispatch: Dispatch) => {
  const successMessage = {
    type: FlashMessageType.SUCCESS,
    message: "入退室記録を削除しました",
  };
  const flashAction = setFlashMessage(successMessage);
  dispatch(flashAction);
};

export const deleteSectionStay =
  (sectionId: string, stayId: string) => (dispatch: Dispatch) => {
    dispatch(deleteSectionStayRequest());

    SectionStayApi.delete(sectionId, stayId)
      .then((res) => {
        if (res.ok) {
          const payload = { stayId };
          setDeleteSectionStaySuccessMsg(dispatch);
          dispatch(deleteSectionStaySuccess(payload));
        } else {
          dispatch(deleteSectionStayError());
          const payload = { errors: [deleteSectionStayUnknownErrorMsg] };
          dispatch(apiRequestError(payload));
        }
      })
      .catch(() => {
        dispatch(deleteSectionStayError());
      });
  };
