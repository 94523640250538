import * as React from "react";
import TabsNavi from "../../../components/molecules/TabsNavi";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import { LearningMaterialTable } from "./LearningMaterialTable";
import styles from "./index.scss";
import { useFetchManagementLearningMaterials } from "./useManagementLearningMaterialApi";
import { SectionsSettingsPageProps } from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import { useOnMainScrollAreaScroll } from "../../../hooks/useMainScrollAreaScroll";
import Loader from "../../../components/atoms/Loader";
import { BlockRow } from "../../../components/atoms/BlockRow";
import { Flex } from "../../../components/atoms/Flex";
import { SearchInput } from "../../../components/general/SearchInput";
import { Button } from "@studyplus/boron-ui";
import { useLearningMaterialFilter } from "./useLearningMaterialFilter";
import { LearningMaterialFilter } from "../../../components/features/LearningMaterialFilter";
import {
  LearningMaterialTagAssociation,
  LearningMaterialTagModal,
  useOpenLearningMaterialTagModal,
} from "../../../components/features/LearningMaterialTagModal";
import { LearningMaterialTagWithDeletable } from "../../../domains/LearningMaterialTag";
import { useAssociateLearningMaterialTag } from "../../../hooks/http/useLearningMaterialTagApi";
import { useQueryError } from "../../../hooks/http/useQueryError";
import { OutletContextProps } from "../../../helpers/RouterHelper";
import { useLearningMaterialFilterWithLocalStorageContext } from "../../../components/features/LearningMaterialFilter/useLearningMaterialFilterWithLocalStorageContext";

// Main hooks
export type UseSectionsSettingsManagementLearningMaterialsProps = {
  section: SectionInterface;
};

export const useSectionsSettingsManagementLearningMaterials = ({
  section,
}: UseSectionsSettingsManagementLearningMaterialsProps) => {
  const {
    searchText,
    onChangeSearchText,
    searchTextToRequest,
    tags,
    onSearch,
    onResetSearchText,
    isLoadingTags,
  } = useLearningMaterialFilter(section);
  const searchFormProps = {
    onSearch,
    onChangeSearchText,
    onResetSearchText,
    searchText,
  };
  const tagFilterProps = {
    tags,
  };

  const { items: selectedTags } =
    useLearningMaterialFilterWithLocalStorageContext();

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
    error,
  } = useFetchManagementLearningMaterials({
    section,
    tags: selectedTags,
    name: searchTextToRequest || undefined,
    enabled: !isLoadingTags,
  });
  useQueryError(error);

  const { isOpenModal, openModal, closeModal, currentLearningMaterial } =
    useOpenLearningMaterialTagModal();
  const { mutate, isLoading: isUpdatingAssociatedTags } =
    useAssociateLearningMaterialTag({
      sectionId: section.id,
      onSuccess: () => {
        closeModal();
        refetch();
      },
    });

  const onSubmit = (
    learningMaterial: LearningMaterialTagAssociation,
    tags: ReadonlyArray<LearningMaterialTagWithDeletable>,
  ) => {
    mutate({
      learningMaterialCode: learningMaterial.learningMaterialCode,
      learningMaterialTags: tags.filter((tag) => tag.isDeletable),
    });
  };

  const modalProps = {
    sectionId: section.id,
    selectableTags: tags ?? [],
    currentLearningMaterial,
    isOpenModal,
    closeModal,
    onSubmit,
    isSubmitting: isUpdatingAssociatedTags,
  };

  useOnMainScrollAreaScroll(() => {
    if (hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isLoading, isFetchingNextPage]);

  const managementLearningMaterialTableProps = {
    openModal,
    managementLearningMaterials: data ?? [],
  };
  const loaderProps = {
    loading: isLoading || isFetchingNextPage || isLoadingTags,
  };
  const noResultsTextProps = {
    hidden: isLoading || !data || data.length !== 0,
  };
  return {
    noResultsTextProps,
    managementLearningMaterialTableProps,
    loaderProps,
    searchFormProps,
    tagFilterProps,
    modalProps,
  };
};

type Props = SectionsSettingsPageProps &
  UseSectionsSettingsManagementLearningMaterialsProps &
  OutletContextProps;

const SectionsSettingsManagementLearningMaterials = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("learning_material");
  }, []);
  const section = props.section;
  const {
    managementLearningMaterialTableProps,
    loaderProps,
    noResultsTextProps,
    searchFormProps,
    tagFilterProps,
    modalProps,
  } = useSectionsSettingsManagementLearningMaterials({ section });
  return (
    <div>
      <TabsNavi
        tabs={[
          {
            label: "校舎教材一覧",
            link: `/sections/${section.id}/settings/management_learning_materials`,
            active: true,
          },
          {
            label: "デジタル教材",
            link: `/sections/${section.id}/settings/digital_learning_materials`,
            active: false,
          },
          {
            label: "校舎独自教材",
            link: `/sections/${section.id}/settings/section_learning_materials`,
            active: false,
          },
          {
            label: "教材タグ",
            link: `/sections/${section.id}/settings/learning_material_tags`,
            active: false,
          },
        ]}
      />
      <div className={styles.searchForm}>
        <Flex gap="1" marginTop="2.4rem">
          <SearchInput
            isBlock
            placeholder="教材名を入力"
            value={searchFormProps.searchText}
            onChange={searchFormProps.onChangeSearchText}
            onReset={searchFormProps.onResetSearchText}
          />
          <Button
            onClick={searchFormProps.onSearch}
            isLoading={loaderProps.loading}
            className={styles.searchButton}
          >
            検索
          </Button>
        </Flex>
      </div>
      <BlockRow marginTop="1.6rem">
        <LearningMaterialFilter
          context={useLearningMaterialFilterWithLocalStorageContext()}
          tags={tagFilterProps.tags}
          sectionId={section.id}
        />
      </BlockRow>
      <BlockRow marginTop="1.2rem">
        <p {...noResultsTextProps} className={styles.textNoResults}>
          検索結果が存在しません。
        </p>
        <LearningMaterialTable {...managementLearningMaterialTableProps} />
        <LearningMaterialTagModal {...modalProps} />
        <Loader {...loaderProps} />
      </BlockRow>
    </div>
  );
};

const pageInfo = {
  title: "教材管理",
};

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsManagementLearningMaterials,
  pageInfo,
  ["management_learning_materials"],
);

export default EnhancedPage;
