import * as React from "react";
import {
  useScheduleFormState,
  UseScheduleFormStateProps,
} from "./useScheduleFormState";
import { useMutateSchedule } from "../useScheduleApi";
import { useFetchBookshelfEntries } from "../../../../hooks/http/useBookshelfEntries";
import StudentInterface from "../../../../interfaces/StudentInterface";
import {
  Schedule,
  determineUpdateTypeWhenNoNeedConfirm,
  shouldConfirmUpdateType,
  shouldConfirmChangeRecurrence,
} from "../../../../domains/Schedule";

export type UseScheduleFormProps = {
  student: StudentInterface;
  startDate: Date;
  endDate: Date;
  onMutateSuccess: (message: string) => void;
  onMutateError: (message: string) => void;
  onMutateWithUpdateTypeConfirm: (schedule: Schedule) => void;
} & Pick<UseScheduleFormStateProps, "schedule">;

export const useScheduleForm = ({
  student,
  startDate,
  endDate,
  onMutateSuccess,
  onMutateError,
  onMutateWithUpdateTypeConfirm,
  schedule,
}: UseScheduleFormProps) => {
  const updateDoneRef = React.useRef(false);

  const {
    mutate,
    isLoading: mutateLoading,
    isError: mutateError,
    data: mutateData,
  } = useMutateSchedule({
    studentId: student.id,
    from: startDate,
    to: endDate,
    refetchOnSettled: true,
  });

  const onSubmit = React.useCallback(
    (submitSchedule: Schedule) => {
      // 既存の繰り返し予定で、繰り返しの変更がない場合のみダイアログでこの予定のみか、これ以降もか確認する
      // 繰り返しの予定の変更されるときに、この予定のみはNGだから
      if (
        shouldConfirmUpdateType({
          originalSchedule: schedule,
          newSchedule: submitSchedule,
        })
      ) {
        onMutateWithUpdateTypeConfirm(submitSchedule);
        return;
      }

      // 繰り返し頻度を変更する場合更新して良いか確認する
      if (
        shouldConfirmChangeRecurrence({
          originalSchedule: schedule,
          newSchedule: submitSchedule,
        })
      ) {
        window.confirm(
          `この予定の繰り返し規則を変更してもよろしいですか？\n変更内容は、この予定の今後すべての繰り返しに適用されます。`,
        ) &&
          mutate({
            schedule: submitSchedule,
            updateType: determineUpdateTypeWhenNoNeedConfirm(schedule),
          });
        return;
      }

      mutate({
        schedule: submitSchedule,
        updateType: determineUpdateTypeWhenNoNeedConfirm(schedule),
      });
    },
    [onMutateWithUpdateTypeConfirm, schedule],
  );

  const formState = useScheduleFormState({
    onSubmit,
    schedule,
  });

  if (mutateData && updateDoneRef.current === false) {
    updateDoneRef.current = true;
  }

  React.useEffect(() => {
    if (mutateData) {
      onMutateSuccess("登録に成功しました。");
    }
  }, [mutateData]);

  React.useEffect(() => {
    if (mutateError) {
      onMutateError("登録に失敗しました。");
    }
  }, [mutateError]);

  const materialsResult = useFetchBookshelfEntries(student.id, {
    type: "all",
  });

  const materialOptions = React.useMemo(() => {
    if (!materialsResult.data) {
      return [];
    }
    return materialsResult.data.map((item) => ({
      label: item.learningMaterial.name,
      value: item.learningMaterial,
    }));
  }, [materialsResult.data]);

  // 教材がセットされた予定を編集する時に、フォームのコンボボックスでどのオプションを選択済みにするかの決定
  React.useEffect(() => {
    const { learningMaterial: selectedLearningMaterial } = formState.values;
    if (
      materialOptions &&
      materialOptions.length > 0 &&
      selectedLearningMaterial !== null
    ) {
      const selectedOption = materialOptions.find(
        (item) => item.value?.code === selectedLearningMaterial.code,
      );
      if (selectedOption) {
        formState.setFieldValue("learningMaterial", selectedOption.value);
      }
    }
  }, [materialOptions]);

  return {
    ...formState,
    mutateResult: { done: updateDoneRef.current, isLoading: mutateLoading },
    materialsResult,
    materialOptions,
  };
};
