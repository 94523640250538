import * as React from "react";
import { Helmet } from "react-helmet";

import Settings from "../../../helpers/Settings";
import SubLayout from "../../atoms/SubLayout";
import styles from "./styles.scss";

interface Props {
  title: string;
  subHeading: string;
  subMessage: string;
  backgroundUrl: string;
  children: React.ReactNode;
}

const LandingTemplate = (props: Props): React.ReactElement => {
  return (
    <>
      <Helmet>
        <title>{`${props.title} | ${Settings.SITE_TITLE}`}</title>
      </Helmet>
      <SubLayout>
        <SubLayout.Header />

        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.children}>{props.children}</div>
            <footer className={styles.footer}>
              <nav>
                <ul>
                  <li>
                    <a
                      href="https://info.studyplus.co.jp/"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      運営会社
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://for-school.studyplus.co.jp/terms"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      利用規約
                    </a>
                  </li>
                  <li>
                    <a href="/privacy_policy">プライバシーポリシー</a>
                  </li>
                </ul>
              </nav>
              <p className={styles.copyright}>© 2019 Studyplus, Inc.</p>
            </footer>
          </div>

          <aside
            className={styles.aside}
            style={{ backgroundImage: `url(${props.backgroundUrl})` }}
          >
            <h3 className={styles.aside__heading}>{props.subHeading}</h3>
            <p className={styles.aside__message}>{props.subMessage}</p>
            <img
              src="/images/flat_browser_shadow658.png"
              srcSet="/images/flat_browser_shadow.png 2x"
              className={styles.aside__image}
            />
          </aside>
        </div>
      </SubLayout>
    </>
  );
};

export default LandingTemplate;
