import StudentTag from "../domains/StudentTag";

const STATUS_TAGS: StudentTag[] = [
  { id: "active", type: "status", name: "連携中", enabled: true },
  { id: "inviting", type: "status", name: "招待中", enabled: true },
  { id: "preinviting", type: "status", name: "未発行", enabled: true },
  { id: "inactive", type: "status", name: "停止中", enabled: true },
];

const defaultTags: StudentTag[] = STATUS_TAGS.slice(0, 3);

const getAll = (): StudentTag[] => {
  return STATUS_TAGS;
};

const getById = (id: string): StudentTag | undefined => {
  return STATUS_TAGS.find((tag) => tag.id === id);
};

const StatusTagHelper = {
  defaultTags,
  getAll,
  getById,
};

export default StatusTagHelper;
