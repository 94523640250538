import styles from "./styles.scss";
import Icon from "../../atoms/Icon";
import NaviMenuToggleContainer, {
  PresentationalComponentProps,
} from "../../../containers/NaviMenuToggleContainer";
import classNames from "classnames";

const PresetnationalComponent = (props: PresentationalComponentProps) => {
  return (
    <button
      className={classNames(styles.root, { [styles.opened]: props.opened })}
      onClick={props.onToggle}
    >
      <div className={styles.button}>
        <Icon name="icon-navi-menu" className={styles.icon} />
      </div>
    </button>
  );
};

const NaviMenuSPOpenButton = () => (
  <NaviMenuToggleContainer presentationalComponent={PresetnationalComponent} />
);

export default NaviMenuSPOpenButton;
