import * as React from "react";
import api from "../../api";
import { Student } from "../../domains/Student";
import { createError } from "../../errors";
import { useFlashMessage } from "../../hooks/useFlashMessage";
import SectionInterface from "../../interfaces/SectionInterface";
import {
  PrimaryLoadableMultiSelect,
  Props as LoadableSelectProps,
  Additional,
} from "../general/SelectWrapper";
import {
  GroupBase,
  OptionsOrGroups,
  OnChangeValue,
  StylesConfig,
} from "react-select";
import { LoadOptions } from "react-select-async-paginate";
import { customStyles } from "../../components/general/SelectWrapper/styles";
import { OptionType as SelectWrapperOptionType } from "../../components/general/SelectWrapper";
import { UseFetchStudentsOptions } from "../../hooks/http/useFetchSectionStudents";

export type OptionType = {
  label: string;
  value: string;
};

type FilterOptions = Pick<UseFetchStudentsOptions, "billing_plan">;
type UseLoadOptionsProps = {
  section: SectionInterface;
  filterOptions?: FilterOptions;
};

type ReactSelectProps = Omit<
  LoadableSelectProps<OptionType, true>,
  "isMulti" | "loadOptions" | "loadingMessage" | "noOptionsMessage" | "onChange"
>;

type OwnProps = {
  onChange: (values: ReadonlyArray<OptionType> | null | undefined) => void;
  hasError?: boolean;
};

const useLoadOptions = ({ section, filterOptions }: UseLoadOptionsProps) => {
  const { showErrorMessage } = useFlashMessage();

  const fetcher = async (inputWords: string, page: number) => {
    const res = await api.get(`/api/v1/sections/${section.id}/students`, {
      query: {
        last_name: inputWords,
        status: ["active", "inviting", "preinviting"],
        billing_plan: filterOptions ? filterOptions.billing_plan : null,
        page: page,
      },
    });
    if (res.ok) {
      const json = await res.json();
      const students = json.students.data as ReadonlyArray<Student>;
      const options = students.map((student) => ({
        label: student.fullName,
        value: student.id,
      }));
      const meta = json.students.meta;
      return {
        options,
        hasMore: meta.currentPage < meta.totalPages,
      };
    }
    throw await createError(res);
  };

  return async (
    inputWord: string,
    _loadedOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
    { page }: Additional,
  ) => {
    const res = await fetcher(inputWord, page).catch(() => {
      showErrorMessage("生徒の検索に失敗しました。");
    });

    return {
      options: res ? res.options : [],
      hasMore: res ? res.hasMore : false,
      additional: {
        page: page + 1,
      },
    };
  };
};

type Props = UseLoadOptionsProps & ReactSelectProps & OwnProps;
const LoadableStudentsMultiSelect_ = ({
  section,
  placeholder,
  filterOptions,
  onChange,
  hasError,
  ...restProps
}: Props) => {
  const loadOptions = useLoadOptions({ section, filterOptions });
  return (
    <PrimaryLoadableMultiSelect
      {...restProps}
      onChange={(p: OnChangeValue<OptionType, true>) => {
        onChange(p);
      }}
      noOptionsMessage={() => "生徒の姓を入力することで候補を検索できます"}
      loadingMessage={() => "検索中..."}
      loadOptions={
        loadOptions as LoadOptions<
          OptionType,
          GroupBase<OptionType>,
          Additional
        >
      }
      placeholder={placeholder}
      isMulti
      hasError={hasError}
      styles={
        {
          ...customStyles,
          valueContainer: valueContainerStyles,
          control: controlStyles,
        } as any
      }
    />
  );
};

const valueContainerStyles: StylesConfig<SelectWrapperOptionType>["valueContainer"] =
  (styles, state) => {
    const existingStyles = customStyles.valueContainer
      ? customStyles.valueContainer(styles, state)
      : {};
    return {
      ...existingStyles,
      overflow: "visible",
    };
  };

const controlStyles: StylesConfig<SelectWrapperOptionType>["control"] = (
  styles,
  state,
) => {
  const existingStyles = customStyles.control
    ? customStyles.control(styles, state)
    : {};
  return {
    ...existingStyles,
    height: "auto",
    maxHeight: "15rem",
    overflowY: "auto",
  };
};

export const LoadableStudentsMultiSelect = React.memo(
  LoadableStudentsMultiSelect_,
);
