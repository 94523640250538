import * as React from "react";
import styles from "./styles.scss";
import StudentInterface from "../../../interfaces/StudentInterface";
import GuardianMessageInterface from "../../../interfaces/GuardianMessageInterface";
import SplitLayout from "../../atoms/SplitLayout";
import GuardianMessageForm from "./GuardianMessageForm";
import GuardianMessageHeader from "./GuardianMessageHeader";
import GuardianMessageList from "./GuardianMessageList";
import { CursorMetaData } from "../../../interfaces/ApiResponse";

export type GuardianMessageProps = {
  data: GuardianMessageInterface[];
  meta: CursorMetaData;
  loading: boolean;
  hasMore: boolean;
};
interface Props {
  student: StudentInterface;
  guardianMessagesProps: GuardianMessageProps;
  onCloseRightColumn?: (e: any) => void;
  showCloseIcon?: boolean;
  loadMoreMessages: () => void;
  updateCurrentThreadLatestMessage?: (
    guardianMessage: GuardianMessageInterface,
  ) => void;
}

const GuardianMessage = (props: Props) => {
  const [postedGuardianMessages, setPostedGuardianMessages] = React.useState<
    GuardianMessageInterface[]
  >([]);

  React.useEffect(() => setPostedGuardianMessages([]), [props.student.id]);

  if (!props.student) {
    return null;
  }

  const unshiftGuardianMessage = (messages: GuardianMessageInterface[]) => {
    setPostedGuardianMessages([...messages, ...postedGuardianMessages]);
  };

  const showCloseIcon =
    typeof props.showCloseIcon === "undefined" ? true : props.showCloseIcon;

  return (
    <div className={styles.root}>
      <SplitLayout.Header>
        <GuardianMessageHeader
          student={props.student}
          onClose={props.onCloseRightColumn}
          showCloseIcon={showCloseIcon}
        />
      </SplitLayout.Header>
      <SplitLayout.Body>
        {props.guardianMessagesProps.data || postedGuardianMessages ? (
          <GuardianMessageList
            student={props.student}
            guardianMessageProps={props.guardianMessagesProps}
            postedGuardianMessages={postedGuardianMessages}
            loadMoreMessages={props.loadMoreMessages}
          />
        ) : null}
        <GuardianMessageForm
          student={props.student}
          unshiftGuardianMessage={unshiftGuardianMessage}
          updateCurrentThreadLatestMessage={
            props.updateCurrentThreadLatestMessage || undefined
          }
        />
      </SplitLayout.Body>
    </div>
  );
};

export default GuardianMessage;
