import StudentsLearningMaterialsNewStateInterface from "../../interfaces/StudentsLearningMaterialsNewStateInterface";
import LearningMaterialEntityInterface from "../../interfaces/LearningMaterialEntityInterface";

const defaultState: StudentsLearningMaterialsNewStateInterface = {
  meta: {
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
  },
  data: null,
  loading: false,
  hasMore: false,
  submitting: false,
};

const studentsLearningMaterialsNew = (
  state: StudentsLearningMaterialsNewStateInterface = defaultState,
  action: ReduxActions.Action<any>,
): any => {
  switch (action.type) {
    case "SEARCH_LEARNING_MATERIALS_ERROR":
    case "SEARCH_MORE_LEARNING_MATERIALS_ERROR":
      return {
        ...state,
        loading: false,
      };

    // 初期読み込み
    case "SEARCH_LEARNING_MATERIALS":
      return {
        ...state,
        data: null,
        loading: true,
      };
    case "SEARCH_LEARNING_MATERIALS_SUCCESS":
      return {
        ...state,
        meta: action.payload.learningMaterials.meta,
        data: state.data
          ? [...state.data, ...action.payload.learningMaterials.data]
          : action.payload.learningMaterials.data,
        loading: false,
        hasMore:
          action.payload.learningMaterials.meta.totalPages >
          action.payload.learningMaterials.meta.currentPage,
      };

    // もっと見る
    case "SEARCH_MORE_LEARNING_MATERIALS":
      return {
        ...state,
        loading: true,
      };
    case "SEARCH_MORE_LEARNING_MATERIALS_SUCCESS":
      return {
        ...state,
        meta: action.payload.learningMaterials.meta,
        data: state.data
          ? [...state.data, ...action.payload.learningMaterials.data]
          : action.payload.learningMaterials.data,
        loading: false,
        hasMore:
          action.payload.learningMaterials.meta.totalPages >
          action.payload.learningMaterials.meta.currentPage,
      };

    case "RESET_LEARNING_MATERIALS_SEARCH_RESULT":
      return state;
    // return defaultState

    case "REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF":
      return {
        ...state,
        submitting: true,
      };

    case "REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_SUCCESS":
    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_SUCCESS":
      return {
        ...state,
        submitting: false,
        data: state.data
          ? state.data.map(
              (learningMaterial: LearningMaterialEntityInterface) => {
                if (learningMaterial.code === action.payload.materialCode) {
                  return {
                    ...learningMaterial,
                    isAssigned: true,
                  };
                } else {
                  return learningMaterial;
                }
              },
            )
          : state.data,
      };

    case "REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_ERROR":
    case "PRE_REGISTER_LEARNING_MATERIAL_TO_BOOKSHELF_ERROR":
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
};

export default studentsLearningMaterialsNew;
