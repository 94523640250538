import * as React from "react";
import { Link } from "react-router-dom";

import TimeHelper from "../../../helpers/TimeHelper";
import { GuardianMessageWithStudentInterface } from "../../../interfaces/GuardianMessageInterface";
import HeaderNotificationItemRow from "../../atoms/HeaderNotificationItemRow";
import Icon from "../../atoms/Icon";
import styles from "./styles.scss";
import { truncate } from "../../../helpers/String";

interface Props {
  message: GuardianMessageWithStudentInterface;
}

const messagePath = (message: GuardianMessageWithStudentInterface): string => {
  return `/students/${message.student.id}/guardian_messages`;
};

const ListItem = (props: Props): React.ReactElement => {
  const { student } = props.message;

  const content = props.message.imageUrl
    ? "画像ファイル"
    : truncate(props.message.content, 20);

  return (
    <HeaderNotificationItemRow
      highlighted={!props.message.isRead}
      key={props.message.id}
    >
      <Link to={messagePath(props.message)} className={styles.listItem}>
        <div className={styles.left}>
          <Icon name="icon-guardian-message" className={styles.icon} />
        </div>
        <div className={styles.right}>
          <div className={styles.name}>{student.fullName}</div>
          <div className={styles.datetime}>
            {TimeHelper.japaneseFormatFullDateTime(props.message.sentAt)}
          </div>
          <div className={styles.content}>{content}</div>
        </div>
      </Link>
    </HeaderNotificationItemRow>
  );
};

export default ListItem;
