import { AttandanceStatus } from "../domains/LectureAttendance";
import { Colors } from "./styles/Colors";

export const attendanceLabels: Record<keyof typeof AttandanceStatus, string> = {
  presence: "出席",
  absence: "欠席",
  cancel: "休講",
  none: "未入力",
};

export const transferAttendanceLabels: Record<
  keyof typeof AttandanceStatus,
  string
> = {
  presence: "振替出席",
  absence: "振替欠席",
  cancel: "振替休講",
  none: "振替予定",
};

export const attendanceIcon: Record<
  keyof typeof AttandanceStatus,
  { iconName: string; iconColor: Colors }
> = {
  presence: {
    iconName: "icon-success",
    iconColor: "green",
  },
  absence: {
    iconName: "icon-failed",
    iconColor: "red",
  },
  cancel: {
    iconName: "icon-attention-3",
    iconColor: "gray-darken-1",
  },
  none: {
    iconName: "icon-attention-3",
    iconColor: "gray-darken-1",
  },
};
