import { withRouter } from "../helpers/RouterHelper";
import { initializeFiltersByQuery } from "../actions/common/filters";
import { clearStudent } from "../actions/common/student";
import SettingNavi from "../components/organisms/SettingNavi";
import {
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "../hocs/enhanceAuthenticatedPage";
import withFiltersInitialization from "../hocs/withFiltersInitialization";

export interface SidebarProps
  extends AuthenticatedPageProps,
    AuthenticatedPageCallbacks {
  initializeFilterByQuery: typeof initializeFiltersByQuery;
  clearStudent: typeof clearStudent;
  activeMenu: string;
}

const SectionSettingSidebar = (props: SidebarProps) => {
  if (
    !props.currentSection.data ||
    !props.currentOperator ||
    !props.operatorPrivileges.data?.privileges
  ) {
    return null;
  }

  return (
    <SettingNavi
      {...props}
      privileges={props.operatorPrivileges.data?.privileges}
      section={props.currentSection.data}
      opened={props.navigationOpened}
    />
  );
};

export default withRouter<SidebarProps>(
  withFiltersInitialization(SectionSettingSidebar),
);
