import {
  HorizontalScrollTable,
  TableColumn,
} from "../../components/atoms/Table";
import { SectionExamination } from "../../domains/SectionExamination";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";

type Props = {
  examinations: SectionExamination[];
};
export const ExaminationTable = ({ examinations }: Props) => {
  return (
    <HorizontalScrollTable
      noRowsText="成績記録が登録されていません"
      headers={[
        { text: "受験月", centering: true, key: "lastExaminedOn" },
        { text: "主催者", centering: true, key: "organizer" },
        { text: "試験の種類", centering: true, key: "classification" },
        { text: "試験名", centering: true, key: "name" },
        { text: "登録人数", centering: true, key: "numberOfExaminees" },
      ]}
      rows={examinations.map((examination: SectionExamination) => (
        <tr key={`examination-${examination.id}`}>
          <TableColumn className="w-48" centering>
            {format(parseISO(examination.result.lastExaminedOn), "yyyy/M")}
          </TableColumn>
          <TableColumn className="w-64">
            {examination.organizer.name}
          </TableColumn>
          <TableColumn className="w-64">
            {examination.classification.name}
          </TableColumn>
          <TableColumn>
            <Link
              to={`./${examination.id}`}
              className="text-blue-400 underline"
            >
              {examination.name}
            </Link>
            <div className="mt-1 text-gray-900">
              {examination.targetJob.human}
            </div>
          </TableColumn>
          <TableColumn centering className="w-24">
            {`${examination.result.numberOfExaminees}人`}
          </TableColumn>
        </tr>
      ))}
    />
  );
};
