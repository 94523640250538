import styles from "./styles.scss";
import Icon from "../../atoms/Icon/index";
import { Link } from "react-router-dom";
import LinkButton from "../../atoms/LinkButton/index";
import StudentInterface from "../../../interfaces/StudentInterface";

interface Props {
  student: Omit<StudentInterface, "billingPlan">;
  isFollowing: boolean;
  showCloseIcon: boolean;
  onFollow: () => void;
  onUnfollow: () => void;
  onClose?: (e: any) => void;
}

const MessageHeader = (props: Props) => {
  const { student, showCloseIcon } = props;

  return (
    <div className={styles.root}>
      {showCloseIcon ? (
        <Icon
          name="icon-arrow-close"
          className={styles.close}
          onClick={props.onClose}
        />
      ) : null}
      <div className={styles.title}>
        <Link to={`/students/${student.id}`} className={styles.name}>
          {student.fullName}
        </Link>
        とのメッセージ
      </div>
      <div className={styles.follow} aria-label="メッセージ通知">
        {renderFollowButton(props)}
      </div>
    </div>
  );
};

const renderFollowButton = (props: Props) => {
  if (props.isFollowing) {
    return (
      <LinkButton onClick={props.onUnfollow} role="button">
        <Icon name="icon-notice" />
      </LinkButton>
    );
  } else {
    return (
      <LinkButton onClick={props.onFollow} role="button">
        <Icon name="icon-notice-mail-mute" />
      </LinkButton>
    );
  }
};

export default MessageHeader;
