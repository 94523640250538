import ErrorTemplate from "../../../components/templates/ErrorTemplate/index";
const nl2br = require("react-nl2br");

interface Props {
  maintenance: string | null;
}

const MaintenanceErrorErrorPage = (props: Props) => {
  return (
    <ErrorTemplate
      title={"ただいまメンテナンス中です"}
      heading={"ただいまメンテナンス中です"}
      hideButton={true}
    >
      <p>{nl2br(props.maintenance)}</p>
    </ErrorTemplate>
  );
};

export default MaintenanceErrorErrorPage;
