import { useInfiniteQuery } from "@tanstack/react-query";
import ApiResponse from "../../../../interfaces/ApiResponse";
import { createError, HTTPErrors } from "../../../../errors";
import { boronClient } from "../../../../api";
import { getNextPageParam } from "../../../../helpers/ReactQueryHelper";
import { paths } from "../../../../lib/api/v1";

const URL = "/api/v1/sections/{section_id}/drill_learning_materials/search";

export type DrillSearchResponseItem =
  paths["/api/v1/sections/{section_id}/drill_learning_materials/search"]["get"]["responses"]["200"]["content"]["application/json"]["drillLearningMaterials"]["data"][0];

type Props = {
  sectionId: string;
  keyword: string;
};
export const useFetchDrillLearningMaterials = ({
  sectionId,
  keyword,
}: Props) => {
  return useInfiniteQuery<ApiResponse<DrillSearchResponseItem[]>, HTTPErrors>({
    queryKey: [URL, sectionId, keyword],
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(URL, {
        params: {
          path: { section_id: sectionId },
          query: {
            page: pageParam as number,
            ...(keyword != "" ? { keyword } : {}),
          },
        },
      });
      if (data) {
        return data.drillLearningMaterials;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
  });
};
