import * as React from "react";
import { useQueryError } from "../../hooks/http/useQueryError";
import AnalyticsHelper from "../../helpers/AnalyticsHelper";
import {
  AnalyticsBarChart,
  BarChartProps,
} from "../../components/features/AnalyticsChart";
import { AnalyticsTotalHeatMapScore } from "../../components/features/AnalyticsTotalHeatMapScore";
import Loader from "../../components/atoms/Loader";
import styles from "./StayAnalyticsChart.scss";
import StudentInterface from "../../interfaces/StudentInterface";
import { AnalyticsTermType } from "../../interfaces/AnalyticsTableInterface";
import { useFetchStudentStayAnalyticsSummary } from "./useStudentStayAnalyticsApi";
import { StudentStayAnalyticsSummaryItem } from "../../domains/StudentAnalytics";
import { ChartData } from "chart.js";
import TimeHelper, { secondsToTime } from "../../helpers/TimeHelper";

// Main hooks
type UseStayAnalyticsChartProps = {
  student: StudentInterface;
  term: AnalyticsTermType;
  startDate: Date;
  endDate: Date;
  enabledRequest: boolean;
};
const useStayAnalyticsChart = ({
  student,
  term,
  endDate,
  enabledRequest,
}: UseStayAnalyticsChartProps) => {
  const { data, error, isLoading } = useFetchStudentStayAnalyticsSummary({
    student: student,
    startDate: endDate, // API 側は遡って計算するため
    term: term,
    enabledRequest: enabledRequest,
  });
  useQueryError(error);

  const chartInfo = React.useMemo(() => {
    return data?.stays
      ? makeChartInfo({
          stayAnalyticsSummaryItems: data.stays,
          term: term,
        })
      : null;
  }, [data, term]);

  const totalHeatMapScoreProps = {
    seconds: data?.totalScore ?? 0,
    hidden: !data,
  };

  const loaderProps = {
    loading: isLoading,
  };

  return {
    chartInfo,
    totalHeatMapScoreProps,
    loaderProps,
  };
};

// Main component
type Props = UseStayAnalyticsChartProps;
const StayAnalyticsChart_ = (props: Props) => {
  const { chartInfo, totalHeatMapScoreProps, loaderProps } =
    useStayAnalyticsChart(props);
  return (
    <div className={styles.Summary}>
      {chartInfo && (
        <>
          <AnalyticsTotalHeatMapScore {...totalHeatMapScoreProps} />
          <div className={styles.BarChartWrapper}>
            <Loader {...loaderProps} />
            <AnalyticsBarChart {...chartInfo} />
          </div>
        </>
      )}
    </div>
  );
};
export const StayAnalyticsChart = React.memo(StayAnalyticsChart_);

// Helpers
type MakeChartInfoParams = {
  stayAnalyticsSummaryItems: StudentStayAnalyticsSummaryItem[];
  term: AnalyticsTermType;
};
const makeChartInfo = ({
  stayAnalyticsSummaryItems,
  term,
}: MakeChartInfoParams): BarChartProps => {
  const labels = stayAnalyticsSummaryItems.map((item) =>
    AnalyticsHelper.formatTermDate(item.date, term),
  );
  const dataSets: ChartData<"bar">["datasets"] = [
    {
      label: "滞在時間",
      data: stayAnalyticsSummaryItems.map((item) => item.totalScore),
      backgroundColor: "#ff9f9f",
      maxBarThickness: 60,
    },
  ];

  return { labels, dataSets, tooltipLabelCallback, yTickCallback };
};

// Tooltip Item Context
// cf. https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-item-context
const tooltipLabelCallback: BarChartProps["tooltipLabelCallback"] = (
  tooltipItem,
) => {
  if (tooltipItem.datasetIndex == null) return "";
  if (tooltipItem.dataset == null) return "";

  const dataset = tooltipItem.dataset;

  if (dataset.data == null) return "";
  if (tooltipItem.dataIndex == null) return "";

  const score = dataset.data[tooltipItem.dataIndex];

  if (score == null) return "";

  const itemName = dataset.label;
  const formattedScore = TimeHelper.secondsToDisplayTime(score as number);

  return `${itemName}：${formattedScore}`;
};

const yTickCallback: BarChartProps["yTickCallback"] = (
  seconds: number | string,
) => {
  const num = Number(seconds);
  if (isNaN(num)) return;
  const { hours } = secondsToTime(num);
  return `${hours}h`;
};
