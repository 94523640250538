import * as React from "react";
import { OutletContextProps } from "../../helpers/RouterHelper";
import { AuthenticateRouterProps } from "../../hocs/enhanceAuthenticatedPage";
import { useFetchStudentStudyRecord } from "./useFetchStudentStudyRecord";
import { useParams } from "react-router";
import StudentInterface from "../../interfaces/StudentInterface";
import enhanceStudentsPage from "../../hocs/enhanceStudentsPage";
import StudyRecordInterface from "../../interfaces/StudyRecordInterface";
import { useQueryError } from "../../hooks/http/useQueryError";
import BackLink from "../../components/atoms/BackLink";
import Loader from "../../components/atoms/Loader";
import TimelineItem from "../../components/features/Timeline/TimelineItem";

type Props = OutletContextProps &
  AuthenticateRouterProps & {
    student: StudentInterface;
  };

const StudentsStudyRecordPage = (props: Props) => {
  const { student } = props;
  const { studyRecordId = "" } = useParams<{ studyRecordId: string }>();

  React.useEffect(() => {
    props.setActiveMenu("timeline");
  }, []);

  const { data, isLoading, error } = useFetchStudentStudyRecord({
    studentId: student.id,
    studyRecordId,
  });
  useQueryError(error);

  if (!data) return null;

  return (
    <div>
      <BackLink>戻る</BackLink>
      <div className="mx-auto my-8 max-w-[104.6rem] overflow-hidden rounded-md border border-solid border-gray-400 bg-white">
        {isLoading ? (
          <Loader loading={true} />
        ) : (
          <TimelineItem
            student={student}
            sectionId={student.section.id}
            studyRecord={data as StudyRecordInterface}
            showHeader={true}
            canSendComment={student.canSendComment}
          />
        )}
      </div>
    </div>
  );
};

export default enhanceStudentsPage(StudentsStudyRecordPage, {
  title: "タイムライン",
});
