import * as React from "react";
import { Text } from "../../../components/general/Text";
import styles from "./EmptyResultText.scss";

export const EmptyResultText = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  return (
    <Text as="div" align="center">
      <span className={styles.EmptyResultText}>{children}</span>
    </Text>
  );
};
