import * as React from "react";
import styles from "./styles.scss";
import Label from "../../atoms/Label";
import SwitchButton from "../../atoms/SwitchButton/index";
import CircleIcon from "../../atoms/CircleIcon/index";
import Button, { ButtonTheme, ButtonSize } from "../../atoms/Button";
import { OperatorHelpLink } from "../../molecules/OperatorHelpLink";
import { Modal } from "../../atoms/Modal";
import { useState, useEffect } from "react";
import Checkbox from "../../atoms/Checkbox";
import { withFormik, FormikBag, FormikProps, Form, Field } from "formik";
import { ZoomMeetingSettingInterface } from "../../../interfaces/OperatorProfileInterface";
import * as yup from "yup";
import {
  updateZoomMeetingSetting,
  ZoomMeetingSettingParams,
} from "../../../actions/pages/SettingsProfilePage/index";
import { HandleThunkActionCreator } from "react-redux";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import ErrorText from "../../atoms/ErrorText/index";
import classNames from "classnames";

interface Props {
  connected: boolean;
  email?: string;
  onSwitch: (value: boolean) => Promise<boolean>;
  zoomMeetingSetting?: ZoomMeetingSettingInterface;
  updateZoomMeetingSetting: HandleThunkActionCreator<
    typeof updateZoomMeetingSetting
  >;
  submitting: boolean;
  meetingSettingErrors: ApiErrorInterface[];
}

const HELP_URL_TO_CONNECT =
  "https://fs-help.studyplus.co.jp/ja/articles/4310468";
const HELP_URL_TO_DISCONNECT =
  "https://fs-help.studyplus.co.jp/ja/articles/4375870";

const InnerForm = (
  props: Props & FormikProps<Values>,
): React.ReactElement<Props> => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => props.setSubmitting(props.submitting), [props.submitting]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.display}>
          <div className={styles.display__label}>
            <span className={styles.display__label__bold}>Zoom連携の設定</span>
            {renderHelp(props.connected)}
          </div>
          <SwitchButton
            value={props.connected}
            label={{ on: "連携中", off: "未連携" }}
            onChange={props.onSwitch}
          />
        </div>
      </div>
      {props.connected && props.email && props.zoomMeetingSetting ? (
        <>
          <Label isMute={true}>Zoomアカウント</Label>
          <div className={styles.root}>
            <div
              className={classNames(styles.display, styles.display__setting)}
            >
              <div className={styles.display__label}>
                <CircleIcon
                  iconProps={{ name: "icon-zoom", iconClassName: styles.icon }}
                  className={styles.display__label__icon}
                />
                <span className={styles.display__label__email}>
                  {props.email}
                </span>
              </div>
              <div className={styles.setting__btn}>
                <Button
                  disabled={false}
                  theme={ButtonTheme.White}
                  size={ButtonSize.Small}
                  isRound={true}
                  onClick={handleOpenModal}
                >
                  ミーティング設定
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
        <Modal.Header onClose={handleCloseModal}>ミーティング設定</Modal.Header>
        <Modal.Body>
          <Form>
            <div className={styles.field}>
              <Field
                component={Checkbox}
                isBlock={true}
                name="waitingRoom"
                id="waitingRoom"
              >
                待機室を有効にする
              </Field>
            </div>
            <div className={styles.field}>
              <Field
                component={Checkbox}
                isBlock={true}
                name="enabledPassword"
                id="enabledPassword"
              >
                ミーティングパスワードを有効にする
              </Field>
            </div>
            <div className={styles.field}>
              <Field
                component={Checkbox}
                isBlock={true}
                name="joinBeforeHost"
                id="joinBeforeHost"
              >
                ホストより前の参加を有効にする
              </Field>
            </div>
            <div className={styles.field}>
              <Field
                component={Checkbox}
                isBlock={true}
                name="muteUponEntry"
                id="muteUponEntry"
              >
                エントリー時に参加者をミュート
              </Field>
            </div>
            <div className={styles.field}>
              <Field
                component={Checkbox}
                isBlock={true}
                name="autoRecording"
                id="autoRecording"
              >
                ミーティングをローカルコンピューターに自動的にレコーディングする
              </Field>
            </div>
            <div className={styles.submit}>
              <Button
                type="submit"
                disabled={!props.isValid || props.isSubmitting}
                size={ButtonSize.Large}
              >
                更新
              </Button>
            </div>
            {props.meetingSettingErrors.map(
              (error: ApiErrorInterface, i: number) => (
                <ErrorText key={`OperatorZoomForm-meetingSettingError-${i}`}>
                  {error.message}
                </ErrorText>
              ),
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const renderHelp = (connected: boolean) => {
  const [helpUrl, label] = connected
    ? [HELP_URL_TO_DISCONNECT, "連携解除"]
    : [HELP_URL_TO_CONNECT, "連携方法"];

  return <OperatorHelpLink href={helpUrl}>{label}</OperatorHelpLink>;
};

// connect to Formik

// NOTE: APIだと3値を取れる(Zoomの仕様)が、画面的には録画のON/OFFの2値
type Values = Omit<ZoomMeetingSettingParams, "autoRecording"> & {
  autoRecording: boolean;
};

const mapPropsToValues = (props: Props): Values => {
  const defaultValues: Values = {
    waitingRoom: true,
    enabledPassword: true,
    joinBeforeHost: true,
    muteUponEntry: false,
    autoRecording: false,
  };

  return {
    ...defaultValues,
    ...props.zoomMeetingSetting,
    autoRecording: props.zoomMeetingSetting?.autoRecording === "local",
  };
};

const valuesToParams = (values: Values): ZoomMeetingSettingParams => {
  return {
    ...values,
    autoRecording: values.autoRecording ? "local" : "none",
  };
};

const handleSubmit = (
  values: Values,
  formikBag: FormikBag<Props, Values>,
): void => {
  const { zoomMeetingSetting } = formikBag.props;

  if (!zoomMeetingSetting) {
    throw new Error("zoomMeetingSettingが作成できていません");
  }

  formikBag.props.updateZoomMeetingSetting(
    zoomMeetingSetting.id,
    valuesToParams(values),
  );
};

const validationSchema = yup.object().shape({
  waitingRoom: yup.boolean(),
  enabledPassword: yup.boolean(),
  joinBeforeHost: yup.boolean(),
  muteUponEntry: yup.boolean(),
  autoRecording: yup.boolean(),
});

const formikProps = {
  mapPropsToValues,
  handleSubmit,
  isInitialValid: () => true,
  validationSchema,
};

const OperatorZoomForm = withFormik<Props, Values>(formikProps)(InnerForm);

export default OperatorZoomForm;
