import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import {
  SectionSchedule,
  SectionScheduleDetail,
} from "../../../domains/SectionSchedule";
import { HTTPErrors, createError } from "../../../errors";
import { fromJson as toScheduleFromJson } from "../../../domain-adapters/Schedule";
import SectionInterface from "../../../interfaces/SectionInterface";

type UseFetchSectionSchedule = {
  section: SectionInterface;
  sectionSchedule: SectionSchedule | null;
};
export const useFetchSectionSchedule = ({
  section,
  sectionSchedule,
}: UseFetchSectionSchedule) => {
  const apiPath = urlOf({ section, sectionSchedule });
  return useQuery<SectionScheduleDetail, HTTPErrors>({
    queryKey: cacheKeyOf({ section, sectionSchedule }),
    queryFn: async () => {
      const res = await api.interruptGet(apiPath);
      if (res.ok) {
        const json = await res.json();
        return fromJson(json.scheduleSection);
      }
      throw await createError(res);
    },
    enabled: sectionSchedule !== null,
  });
};

const fromJson = (json: Record<string, any>): SectionScheduleDetail => {
  return {
    id: json.id,
    studentCount: json.schedule.students.length,
    schedule: {
      ...toScheduleFromJson(json.schedule),
      students: json.schedule.students,
    },
    classroom: json.classroom,
    teacher: json.teacher,
    lectureSession: json.lectureSession,
  };
};

type UtilParams = Omit<UseFetchSectionSchedule, "onError">;

const urlOf = ({ section, sectionSchedule }: UtilParams) =>
  `/api/v1/sections/${section.id}/schedules/${sectionSchedule?.id ?? ""}`;

const cacheKeyOf = ({ section, sectionSchedule }: UtilParams) => [
  "sectionScheduleDetail",
  section.id,
  sectionSchedule?.id ?? "",
];
