import * as React from "react";

import styles from "./MessageForm.scss";
import { Button } from "@studyplus/boron-ui";
import DeviceHelper from "../../../helpers/DeviceHelper";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { MessageFormStateInterface } from "../../../interfaces/MessageInterface";
import InputIcon from "../../atoms/InputIcon";
import { useResumableTextarea } from "../../atoms/ResumableTextarea/useResumableTextarea";
import Textarea from "../../atoms/Textarea";
import ResumableTextarea from "../../atoms/ResumableTextarea";
import classNames from "classnames";
import DeprecatedTagButton from "../DeprecatedTagButton";
import FileHelper from "../../../helpers/FileHelper";
import CommunicationStatusLabel from "./CommunicationStatusLabel";
import StudentInterface from "../../../interfaces/StudentInterface";

const mimeTypes = {
  jpg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  pdf: "application/pdf",
};
type FileType = keyof typeof mimeTypes;

const resumeMessage =
  "編集途中のメッセージを復元しますか？\n（「キャンセル」を選択すると復元内容が破棄されます。）";

type Props = {
  formState?: MessageFormStateInterface;
  student?: StudentInterface;
  canSendMessage: boolean;
  allowedFileTypes: FileType[];
  disabledPlaceholder: string;
  postMessage: (content?: string, file?: File) => void;
  changeFormContent: (content: string) => void;
  changeFormFile: (file: File | null) => void;
  storageKey: string;
};

const MessageForm = (props: Props) => {
  const { handleSubmit, isValid } = useMessageForm(props);

  return (
    <React.Fragment>
      <form className={styles.root} onSubmit={handleSubmit}>
        <ApiErrors
          apiErrors={props.formState?.apiErrors}
          isShow={!DeviceHelper.isPC()}
        />
        <div className="relative mr-4 w-full flex-col">
          {props.student && (
            <CommunicationStatusLabel
              sectionId={props.student.section.id}
              lineConnectionStatus={props.student.lineConnectionStatus}
              isGuardianEmailConfirmed={props.student.isGuardianEmailConfirmed}
              isAllowedToReceiveGuardianMessage={
                props.student.section.isAllowedToReceiveGuardianMessage
              }
            />
          )}
          <div
            className={classNames(styles.content, {
              [styles.content__withFile]: props.formState?.values.file,
              [styles.fieldError]:
                props.formState && props.formState.apiErrors.length > 0,
            })}
          >
            <ContentField {...props} />
            {props.canSendMessage ? <AttachmentIcon {...props} /> : null}
          </div>
        </div>
        <div className={styles.button}>
          <Button
            type="submit"
            disabled={props.formState?.submitting || !isValid}
            isBlock={true}
          >
            送信
          </Button>
        </div>
      </form>
      <ApiErrors
        apiErrors={props.formState?.apiErrors}
        isShow={DeviceHelper.isPC()}
      />
    </React.Fragment>
  );
};

const useMessageForm = (props: Props) => {
  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const { formState } = props;

      if (!formState) {
        return;
      }

      props.postMessage(
        formState.values.content,
        formState.values.file ?? undefined,
      );
    },
    [props.formState, props.postMessage, props.postMessage],
  );

  const [isValid, setIsValid] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!props.formState) return setIsValid(false);

    const { content, file } = props.formState.values;
    setIsValid(!!content || !!file);
  }, [props.formState]);

  return {
    handleSubmit,
    isValid,
  };
};

type ApiErrorsProps = {
  apiErrors: ApiErrorInterface[] | undefined;
  isShow: boolean;
};
const ApiErrors = ({ apiErrors, isShow }: ApiErrorsProps) => {
  if (isShow && apiErrors && apiErrors.length > 0) {
    return (
      <div className={styles.errors}>
        {apiErrors.map((error: ApiErrorInterface, i: number) => {
          return (
            <p key={`MessageForm-apiErrors-${i}`} className={styles.error}>
              {error.message}
            </p>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

type AttachmentIconProps = {
  formState?: MessageFormStateInterface;
  allowedFileTypes: FileType[];
  changeFormFile: (file: File | null) => void;
};
const AttachmentIcon = (props: AttachmentIconProps) => {
  return (
    <InputIcon
      iconName="icon-picture-img"
      inputAttributes={{
        accept: props.allowedFileTypes.map((t) => mimeTypes[t]).join(","),
        id: "message_image",
        type: "file",
      }}
      className={styles.imageIcon}
      tooltipLabel={`ファイルの添付( ${props.allowedFileTypes
        .map((t) => t)
        .join(",")} )`}
      onChange={handleChangeFileAttachment(props)}
      disabled={props.formState?.submitting}
    />
  );
};

const handleChangeInput = (props: Props) => (e: React.ChangeEvent<any>) => {
  props.changeFormContent(e.target.value);
};

const handleChangeFileAttachment =
  (props: AttachmentIconProps) => (e: React.ChangeEvent<any>) => {
    const file = e.currentTarget.files[0];
    props.changeFormFile(file);
  };

const handleFileDelete = (props: Props) => () => {
  props.changeFormFile(null);
};

const ContentField: React.FC<Props> = (props) => {
  const { formState, storageKey, changeFormContent, canSendMessage } = props;

  const { clearValue: clearContentStorage, ...resumableTextareaProps } =
    useResumableTextarea({
      value: formState?.values.content || "",
      key: storageKey,
      confirmMessage: resumeMessage,
      onResume: changeFormContent,
    });

  const prevSubmitting = React.useRef(formState?.submitting);

  React.useEffect(() => {
    if (formState) {
      if (
        prevSubmitting.current &&
        !formState.submitting &&
        formState.apiErrors.length === 0
      ) {
        clearContentStorage();
      }
      prevSubmitting.current = formState.submitting;
    }
  }, [formState?.submitting]);

  if (!formState) {
    return (
      <Textarea
        name="content"
        disabled={true}
        placeholder={props.disabledPlaceholder}
        value={""}
        className={styles.content__inputDisabled}
      />
    );
  }

  if (!canSendMessage) {
    return (
      <Textarea
        name="content"
        disabled={true}
        placeholder={props.disabledPlaceholder}
        value={""}
        className={styles.content__inputDisabled}
      />
    );
  }

  return (
    <>
      <ResumableTextarea
        name="content"
        hasError={formState.apiErrors.length > 0}
        placeholder="メッセージを入力"
        value={formState.values.content}
        onChange={handleChangeInput(props)}
        className={classNames(styles.content__input, {
          [styles.content__inputWithFile]: formState.values.file,
        })}
        {...resumableTextareaProps}
      />
      <FileName {...props} />
    </>
  );
};

const FileName = (props: Props) => {
  const file = props.formState ? props.formState.values.file : null;
  if (!file) return null;

  return (
    <div className="mb-2 ml-4 mt-1">
      <DeprecatedTagButton
        label={FileHelper.truncateFilename(file.name, 30)}
        iconName="icon-close-x"
        theme="lightgray"
        onClick={handleFileDelete(props)}
      />
    </div>
  );
};

export default MessageForm;
