import LandingTemplate from "../../../components/templates/LandingTemplate/index";
import RegisterForm from "../../../components/organisms/RegisterForm";
import { Helmet } from "react-helmet";

const RegisterPage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <LandingTemplate
        title="新規登録"
        subHeading="次世代の学習管理ツール"
        subMessage="生徒の学習状況がひと目でわかる。"
        backgroundUrl="/images/bg_register.png"
      >
        <RegisterForm />
      </LandingTemplate>
    </>
  );
};

export default RegisterPage;
