import * as React from "react";

export const useGuardianEmailModal = () => {
  const [isOpen, setIsModalOpen] = React.useState(false);

  const open = () => {
    setIsModalOpen(true);
  };

  const close = () => {
    setIsModalOpen(false);
  };

  return {
    open,
    close,
    isOpen,
  };
};
