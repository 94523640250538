import * as yup from "yup";
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("テンプレート名は必須です")
    .max(20, "テンプレート名は20文字以下で入力してください"),
  template: yup.string().trim().required("本文を入力してください"),
});
export default validationSchema;
