import * as React from "react";
import styles from "./styles.scss";

interface Props extends React.PropsWithChildren<unknown> {
  highlighted: boolean;
}

const className = (defaultStyle: string, modifiers: string[]) => {
  return [defaultStyle].concat(modifiers).join(" ");
};

const HeaderNotificationItemRow = (props: Props) => {
  const modifiers = [];

  if (props.highlighted) {
    modifiers.push(styles.highlighted);
  }

  return (
    <div className={className(styles.root, modifiers)}>{props.children}</div>
  );
};

export default HeaderNotificationItemRow;
