import { AttendanceRate } from "./AttendanceRate";

export const LectureSessionCount = ({
  attendanceRate,
  lectureSessionCount,
}: {
  attendanceRate: number;
  lectureSessionCount: number | undefined;
}) => {
  return lectureSessionCount && lectureSessionCount > 0 ? (
    <div>
      <div>
        <AttendanceRate rate={attendanceRate} />
      </div>
      <div>（{lectureSessionCount}件）</div>
    </div>
  ) : (
    <>0件</>
  );
};
