import * as React from "react";
import styles from "./styles.scss";
import DeprecatedTagButton, {
  DeprecatedTagButtonProps,
} from "../../DeprecatedTagButton/index";

type Props = {
  onClick: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
  label?: string;
  colorType?: DeprecatedTagButtonProps["colorType"];
  variants?: DeprecatedTagButtonProps["variants"];
};

export const TagFilterOpenButton = ({
  buttonRef,
  onClick,
  label = "追加",
  colorType,
  variants,
}: Props) => {
  return (
    <>
      <DeprecatedTagButton
        label={label}
        theme="default"
        iconName="icon-plus"
        onClick={onClick}
        buttonRef={buttonRef}
        className={styles.addButton}
        colorType={colorType}
        variants={variants}
      />
      <DeprecatedTagButton
        label=""
        theme="default"
        iconName="icon-plus"
        onClick={onClick}
        className={styles.addButtonSP}
        colorType={colorType}
        variants={variants}
      />
    </>
  );
};
