import { useInfiniteQuery } from "@tanstack/react-query";
import ApiResponse from "../../../../interfaces/ApiResponse";
import { RecipientStudent } from "../../../../interfaces/StudentInterface";
import { createError, HTTPErrors } from "../../../../errors";
import ApiClient from "../../../../api";
import { GuardianAnnounceMessageTagInterface } from "../../../../interfaces/GuardianAnnounceMessageTagInterface";

type Props = {
  sectionId: string;
  tag: GuardianAnnounceMessageTagInterface;
};

export const useFetchRecipients = ({ sectionId, tag }: Props) => {
  return useInfiniteQuery<ApiResponse<RecipientStudent[]>, HTTPErrors>({
    queryKey: [
      "guardianAnnounceMessageRecipients",
      sectionId,
      tag.type,
      tag.id,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await ApiClient.get(
        `/api/v1/sections/${sectionId}/students`,
        {
          query: {
            page: pageParam,
            guardian_contact: true,
            // status: 'active' のときはパラメータに入れない
            // boronが配列を期待しているので、valueは要素1つの配列にする
            ...(tag.id !== "active" ? { [tag.type]: [tag.id] } : {}),
          },
        },
      );

      if (res.ok) {
        return (await res.json()).students;
      } else {
        throw await createError(res);
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage + 1;
      return nextPage <= lastPage.meta.totalPages ? nextPage : undefined;
    },
  });
};
