import * as React from "react";
import { connect } from "react-redux";
import {
  loadSkillMaster,
  postSkill,
  navigateToSkillIndexPage,
} from "../../../actions/common/skills/index";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SectionsSettingsStudyRecordSkillsNewPage from "../SectionsSettingsStudyRecordSkillsNewPage";
import NotFoundErrorPage from "../NotFoundErrorPage";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionsSettingsSkillsNewStateInterface from "../../../interfaces/SectionsSettingsSkillsNewStateInterface";
import {
  AuthenticatedPageCallbacks,
  AuthenticatedPageProps,
} from "../../../hocs/enhanceAuthenticatedPage";
import Loader from "../../../components/atoms/Loader";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface Params {
  id: string;
}

interface DispatchProps {
  loadSkillMaster: typeof loadSkillMaster;
  postSkill: typeof postSkill;
  navigateToSkillIndexPage: typeof navigateToSkillIndexPage;
}

type StateProps = SectionsSettingsSkillsNewStateInterface;

type Props = WithRouterProps<Params> &
  StateProps &
  DispatchProps &
  AuthenticatedPageCallbacks &
  AuthenticatedPageProps &
  OutletContextProps;

const Page = (props: Props) => {
  React.useEffect(() => {
    props.setActiveMenu("skills");
  }, []);
  const { loadSkillMaster, ...childProps } = props;

  const loadSkill = () => {
    loadSkillMaster(props.params.id);
  };

  React.useEffect(loadSkill, []);

  if (props.loading) {
    return <Loader loading={props.loading} />;
  }

  if (!props.skillMaster) {
    return <NotFoundErrorPage />;
  }

  switch (props.skillMaster && props.skillMaster.type) {
    case "study_record_skill_master":
    default:
      return <SectionsSettingsStudyRecordSkillsNewPage {...childProps} />;
  }
};

const mapStateToProps = (state: AppStateInterface): StateProps =>
  state.sectionsSettingsSkillsNew;

const actions = {
  loadSkillMaster,
  postSkill,
  navigateToSkillIndexPage,
};

const pageInfo = {
  title: "アシスタント",
};

const EnhancedPage = enhanceSectionsSettingsPage(Page, pageInfo, [
  "assistant:index",
]);

const SectionsSettingsSkillsNewPage = connect<
  StateProps,
  DispatchProps,
  void,
  AppStateInterface
>(
  mapStateToProps,
  actions,
)(EnhancedPage as any);

export default SectionsSettingsSkillsNewPage;
