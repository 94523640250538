import { Progress, toPercent } from "../../../domains/DrillProgress";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverProvider,
  PopoverTrigger,
} from "../../general/Popover/Popover";

type Props = {
  totalCount: number;
  progress: Progress;
};

export const DrillProgressBarGroup = ({ totalCount, progress }: Props) => {
  return (
    <>
      <DrillProgressBar
        totalCount={totalCount}
        progressKey="perfectCount"
        progress={progress}
      />
      <DrillProgressBar
        totalCount={totalCount}
        progressKey="establishedCount"
        progress={progress}
      />
      <DrillProgressBar
        totalCount={totalCount}
        progressKey="studyingCount"
        progress={progress}
      />
      <DrillProgressBar
        totalCount={totalCount}
        progressKey="notStudiedCount"
        progress={progress}
      />
    </>
  );
};

type DrillProgressBarProps = {
  progress: Progress;
  progressKey: keyof Progress;
  totalCount: number;
};
const barColorClassNames = {
  perfectCount: "bg-blue-400",
  establishedCount: "bg-yellow-400",
  studyingCount: "bg-darkred-300",
  notStudiedCount: "bg-gray-400",
};
const locales = {
  perfectCount: "S",
  establishedCount: "A",
  studyingCount: "B",
  notStudiedCount: "未学習",
};

const DrillProgressBar = ({
  progressKey,
  progress,
  totalCount,
}: DrillProgressBarProps) => {
  const colorClassName = barColorClassNames[progressKey];
  const numerator = numeratorWithFallback(progress, progressKey);
  const percent = toPercent(numerator, totalCount);

  return (
    <PopoverProvider>
      <Popover>
        <PopoverTrigger
          style={{ width: percent }}
          className={`h-[5px] cursor-pointer p-0 ${colorClassName}`}
        />
        <PopoverPortal>
          <PopoverContent>
            {locales[progressKey]}：{percent}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </PopoverProvider>
  );
};

// 定着度を持たない場合は未学習に1を設定することで進捗度バーを表示
const numeratorWithFallback = (
  progress: Progress,
  progressKey: keyof Progress,
) => {
  const hasNoRetentionLevel = !Object.values(progress).find((i) => i > 0);
  if (hasNoRetentionLevel && progressKey === "notStudiedCount") return 1;

  return progress[progressKey];
};
