import { useMemo } from "react";
import { useCollectBatchActionTargets } from "./useCollectBatchActionTargets";
import { LectureAttendanceForSection } from "../../../domains/LectureAttendance";
import { createContext } from "../../../helpers/React";

type UseAttendanceBatchActionsProps = {
  initialTargets?: LectureAttendanceForSection[];
  fnCurrentAllItems: () => LectureAttendanceForSection[];
};
export const useAttendanceBatchActions = ({
  initialTargets,
  fnCurrentAllItems,
}: UseAttendanceBatchActionsProps) => {
  const { targets, isSelectedWithId, ...rest } =
    useCollectBatchActionTargets<LectureAttendanceForSection>({
      initialTargets,
      fnCurrentAllItems,
    });

  const dispatchers = useMemo(() => ({ ...rest }), []);
  const state = useMemo(() => ({ targets, isSelectedWithId }), [targets]);

  return { dispatchers, state };
};
type ReturnBatchAction = ReturnType<typeof useAttendanceBatchActions>;
export type BatchActionDispatchers = ReturnBatchAction["dispatchers"];
export type BatchActionState = ReturnBatchAction["state"];

export const {
  Provider: BatchActionDispatchProvider,
  useContext: useBatchActionDispatchContext,
} = createContext<BatchActionDispatchers>();
export const {
  Provider: BatchActionStateProvider,
  useContext: useBatchActionStateContext,
} = createContext<BatchActionState>();
