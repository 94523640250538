import { TableHeadColumn } from "../../../components/atoms/Table";
import { useFieldVisibilityToggleContext } from "../../../components/general/FieldVisibilityToggle/useFieldVisibilityToggleContext";
import SortLabel, {
  OrderDirectionType,
} from "../../../components/molecules/SortLabel/index";
import { OrderDirFilterType } from "../../../interfaces/FiltersInterface";
import { AnalyticsFilter } from "../../../hooks/filters/useAnalyticsFilter";

type Props = {
  columns: string[];
  analyticsFilter: AnalyticsFilter;
  onOrderChange: (
    order: string | null,
    initialOrderDir?: OrderDirFilterType,
  ) => void;
};

export const TableHeaders = ({
  columns,
  analyticsFilter,
  onOrderChange,
}: Props) => {
  const { isInvisible } = useFieldVisibilityToggleContext();

  return (
    <thead>
      <tr className="border-none">
        {!isInvisible("生徒ID") && (
          <TableHeadColumn className="left-0 z-10 bg-white px-[2.2rem] py-6">
            <div className="flex items-center justify-center">
              <SortLabel
                label="生徒ID"
                orderDirection={analyticsFilter.orderDir as OrderDirectionType}
                active={analyticsFilter.order === "studentCode"}
                onClick={() => onOrderChange("studentCode", "asc")}
              />
            </div>
          </TableHeadColumn>
        )}
        <TableHeadColumn className="sticky left-0 z-10 h-auto min-w-[20rem] max-w-[20rem] bg-white">
          <div className="flex items-center justify-center">氏名</div>
        </TableHeadColumn>
        <TableHeadColumn className="left-0 h-auto min-w-[12rem] max-w-[12rem] bg-white">
          <div className="flex items-center justify-center">
            <SortLabel
              label="総合"
              orderDirection={analyticsFilter.orderDir as OrderDirectionType}
              active={analyticsFilter.order === null}
              onClick={() => onOrderChange(null)}
            />
          </div>
        </TableHeadColumn>
        {columns.map((column, index) => (
          <TableHeadColumn
            key={index}
            className="left-0 h-auto min-w-[12rem] max-w-[12rem] bg-white"
          >
            <div className="flex items-center justify-center whitespace-pre-line">
              {column}
            </div>
          </TableHeadColumn>
        ))}
      </tr>
    </thead>
  );
};
