import * as React from "react";
import { Link } from "react-router-dom";

import TimeHelper from "../../../helpers/TimeHelper";
import { CommentInterface } from "../../../interfaces/CommentInterface";
import CircleIcon from "../../atoms/CircleIcon";
import HeaderNotificationItemRow from "../../atoms/HeaderNotificationItemRow";
import styles from "./styles.scss";
import { truncate } from "../../../helpers/String";

interface Props {
  comment: CommentInterface;
}

const notificationPath = (comment: CommentInterface): string => {
  return `/students/${comment.student.id}/study_records/${comment.studyRecord.id}`;
};

const renderNotificationData = (comment: CommentInterface) => {
  const { body } = comment;
  const { fullName } = comment.student;
  return `${fullName}が勉強記録のコメントに返信しました：「${truncate(
    body,
    44,
  )}」`;
};

const HeaderCommentNotificationItem = (props: Props): React.ReactElement => {
  return (
    <HeaderNotificationItemRow
      highlighted={!props.comment.isRead}
      key={props.comment.id}
    >
      <Link to={notificationPath(props.comment)} className={styles.root}>
        <div className={styles.left}>
          <CircleIcon
            className={styles.iconNoticeCommentWrapper}
            iconProps={{
              name: "icon-notice-comment",
              iconClassName: styles.iconNoticeComment,
            }}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.content}>
            {renderNotificationData(props.comment)}
          </div>
          <div className={styles.datetime}>
            {TimeHelper.japaneseFormatFullDateTime(props.comment.createdAt)}
          </div>
        </div>
      </Link>
    </HeaderNotificationItemRow>
  );
};

export default HeaderCommentNotificationItem;
