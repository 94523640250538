import * as React from "react";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { Helmet } from "react-helmet";
import Settings from "../../../helpers/Settings";
import SubLayout from "../../atoms/SubLayout";
import HomeHeader from "../../organisms/HomeHeader";

interface PageInfoInterface {
  title: string;
}

interface Props extends React.PropsWithChildren<Record<never, any>> {
  pageInfo: PageInfoInterface;
  operators: OperatorInterface[];
  currentOperator: OperatorInterface;
}

const HomeTemplate = (props: Props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {props.pageInfo.title} | {Settings.SITE_TITLE}
        </title>
      </Helmet>
      <SubLayout>
        <SubLayout.Header>
          <HomeHeader
            operators={props.operators}
            currentOperator={props.currentOperator}
          />
        </SubLayout.Header>
        <SubLayout.Main>{props.children}</SubLayout.Main>
        <SubLayout.Footer></SubLayout.Footer>
      </SubLayout>
    </React.Fragment>
  );
};

export default HomeTemplate;
