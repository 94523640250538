export enum FlashMessageType {
  NONE,
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
}

export default interface FlashMessageInterface {
  visible: boolean;
  type: FlashMessageType;
  messages: string[];
}
