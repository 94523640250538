import ErrorTemplate from "../../../components/templates/ErrorTemplate/index";
import OperatorInterface from "../../../interfaces/OperatorInterface";
import { connect, HandleThunkActionCreator } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import SessionsEdit from "../../../components/organisms/SessionsEdit/index";
import { Navigate } from "react-router";
import * as queryString from "query-string";
import AuthorizationStateInterface from "../../../interfaces/AuthorizationStateInterface";
import { BoronNavigator } from "../../../navigators/BoronNavigator";
import { dispatchSwitchOperator } from "../../../actions/common/session/index";
import { withRouter, WithRouterProps } from "../../../helpers/RouterHelper";

interface DispatchProps {
  switchOperator: HandleThunkActionCreator<typeof dispatchSwitchOperator>;
}
interface StateProps {
  currentOperator: OperatorInterface | null;
  operators: OperatorInterface[];
  session: AuthorizationStateInterface;
}
interface Props extends WithRouterProps, DispatchProps, StateProps {}

const SessionsEditPage = (props: Props) => {
  if (!props.currentOperator) {
    return null;
  }

  const backTo = queryString.parse(location.search)["url"] as string;

  if (props.session.shouldRedirect && backTo) {
    return <Navigate to={backTo} replace />;
  }

  return (
    <ErrorTemplate
      title="アカウント切り替え"
      heading="アカウント切り替え"
      buttonText="別のアカウントを追加"
      buttonLink="#"
      onButtonClick={handleLogin(props)}
    >
      <SessionsEdit
        onSwitchOperator={props.switchOperator}
        currentOperator={props.currentOperator}
        operators={props.operators}
        location={props.location}
      />
    </ErrorTemplate>
  );
};

const handleLogin = (props: Props) => () => {
  const url = queryString.parse(props.location.search)["url"] as string;

  BoronNavigator.signInWithBackTo(url);
};

const mapStateToProps = (state: AppStateInterface) => {
  return {
    currentOperator: state.session.currentOperator,
    operators: state.session.loggedInOperators,
    session: state.session,
  };
};

const actions = {
  switchOperator: dispatchSwitchOperator,
};

const ConnectedPage = connect(mapStateToProps, actions)(SessionsEditPage);

export default withRouter<Props>(ConnectedPage);
