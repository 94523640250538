import * as React from "react";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import ApiClient from "../../../api";
import { Content, fromJson } from "../../../domains/Content";
import SectionInterface from "../../../interfaces/SectionInterface";

export type UseMutateProps = {
  sectionId: string;
  onSuccess: () => void;
  onError: UseMutationOptions<Content, string, Content>["onError"];
};

export type RequestBody = {
  name: string;
  url: string;
};

export const useMutateVideo = ({
  sectionId,
  onError,
  onSuccess,
}: UseMutateProps) => {
  const path = `/api/v1/sections/${sectionId}/contents`;

  const mutateResult = useMutation<Content, string, Content>({
    mutationFn: async (params) => {
      const isNew = params.id ? false : true;
      const apiPath = isNew ? `${path}/videos` : `${path}/${params.id}/videos`;
      const contentVideo = {
        content_video: { name: params.name, url: params.url },
      };

      const response = isNew
        ? await ApiClient.post(apiPath, contentVideo)
        : await ApiClient.patch(apiPath, contentVideo);

      if (response.ok) {
        const json = await response.json();
        return fromJson(json.content);
      } else {
        throw `動画を${isNew ? "登録" : "編集"}できませんでした`;
      }
    },
    onError,
    onSuccess,
  });

  const mutate = React.useCallback((param: Content) => {
    mutateResult.mutate(param);
  }, []);

  return {
    mutate,
    isLoading: mutateResult.isPending,
  };
};

export type UseLazyFetchContentErrors = "404Error" | "403Error" | Error;

type UseFetchContentProps = {
  section: SectionInterface;
  contentId: string | null;
};
export const useLazyFetchContent = (props: UseFetchContentProps) => {
  const [enabled, setEnabled] = React.useState(false);
  const result = useQuery<Content, UseLazyFetchContentErrors>({
    queryKey: ["contents/detail"],
    queryFn: async () => {
      const res = await ApiClient.get(
        `/api/v1/sections/${props.section.id}/contents/${props.contentId}`,
      );
      if (res.status === 200) {
        const json: Record<string, any> = await res.json();
        return translateFetchedData(json.content);
      }
      if (res.status === 404) {
        throw "404Error";
      }
      if (res.status === 403) {
        throw "403Error";
      }
      throw Error("Something went wrong");
    },
    enabled: enabled && props.contentId !== null,
    gcTime: 0, // 編集の場合、キャッシュのデータを見せてしまうと不具合になるため
    retry: 0, // 404とかの場合はリトライしたくないので
  });
  return {
    ...result,
    execQuery: () => setEnabled(true),
  };
};

const translateFetchedData = (json: Record<string, any>): Content => ({
  id: json.id,
  name: json.name,
  updatedAt: new Date(json.updatedAt),
  url: json.url,
  contentType: json.contentType,
  fileSize: json.fileSize,
  ltiToolName: json.ltiToolName,
  availableStartAt: json.availableStartAt
    ? new Date(json.availableStartAt)
    : null,
  availableEndAt: json.availableEndAt ? new Date(json.availableEndAt) : null,
});
