import * as React from "react";
import styles from "./styles.scss";
import { Form, Field, ErrorMessage, FormikProps } from "formik";
import FormikHelper from "../../../helpers/FormikHelper";
import Input from "../../atoms/Input/index";
import ErrorText from "../../atoms/ErrorText/index";
import Textarea from "../../atoms/Textarea/index";
import Label from "../../atoms/Label";
import { Button } from "@studyplus/boron-ui";
import classNames from "classnames";
import { BlockRow } from "../../atoms/BlockRow/index";
import { MainSection, PageHeader } from "../../atoms/MainSection";

export type FormAction = "new" | "edit";

export interface SkillFormValues {
  name: string;
  description: string;
  arguments: { [key: string]: any };
}

export const SkillFormArguments: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <>
      <h3 className={styles.argumentsFieldsetHeading}>条件設定</h3>
      <div className={styles.argumentsFieldsetContainer}>{props.children}</div>
    </>
  );
};

export const SkillFormArgumentsRow: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => {
  return <div className={styles.argumentsFieldsetRow}>{props.children}</div>;
};

interface TextProps extends React.PropsWithChildren<unknown> {
  isInline?: boolean;
}
export const SkillFormArgumentsText: React.FC<TextProps> = (
  props: TextProps,
) => {
  return (
    <div
      className={classNames(styles.argumentsFieldsetRow__text, {
        [styles.argumentsFieldsetRow__textInline]: !!props.isInline,
      })}
    >
      {props.children}
    </div>
  );
};

interface SkillFormArgumentsSelectProps
  extends React.PropsWithChildren<unknown> {
  width: string;
}
export const SkillFormArgumentsSelect: React.FC<
  SkillFormArgumentsSelectProps
> = (props: SkillFormArgumentsSelectProps) => {
  return (
    <div
      className={styles.argumentsFieldsetRow__select}
      style={{ width: props.width }}
    >
      {props.children}
    </div>
  );
};

interface SkillFormOptionsProps extends React.PropsWithChildren<unknown> {
  label: string;
  description: string;
}
export const SkillFormOptions: React.FC<SkillFormOptionsProps> = (
  props: SkillFormOptionsProps,
) => {
  return (
    <div className={styles.fieldsetContainer}>
      <h3 className={styles.fieldsetHeading}>{props.label}</h3>
      <div className={styles.fieldset}>
        <p>{props.description}</p>
        {props.children}
      </div>
    </div>
  );
};

interface Props
  extends React.PropsWithChildren<unknown>,
    FormikProps<SkillFormValues> {
  heading: string;
  action: FormAction;
  helpUrl: string;
}

const SkillForm: React.FC<Props> = (
  props: Props,
): React.ReactElement<Props> => {
  return (
    <BlockRow marginTop="2rem">
      <MainSection>
        <Form role="form">
          <PageHeader helpUrl={props.helpUrl}>{props.heading}</PageHeader>
          <Label htmlFor="SkillForm-name" isMute={true}>
            アシスタントスキル名
          </Label>
          <Field
            name="name"
            id="SkillForm-name"
            component={Input}
            placeholder="名称を入力"
            hasError={FormikHelper.shouldRenderError(props, "name")}
          />
          <ErrorMessage name="name" component={ErrorText} />
          <Label htmlFor="SkillForm-description" isMute={true}>
            説明文
          </Label>
          <Field
            name="description"
            id="SkillForm-description"
            component={Textarea}
            placeholder="説明を入力"
            hasError={FormikHelper.shouldRenderError(props, "description")}
          />
          <ErrorMessage name="description" component={ErrorText} />

          {props.children}

          <div className={styles.submit}>
            <Button
              size="lg"
              type="submit"
              disabled={!props.isValid || props.isSubmitting}
              className={styles.submit__button}
            >
              {props.action === "new" ? "登録" : "更新"}
            </Button>
          </div>
        </Form>
      </MainSection>
    </BlockRow>
  );
};

export default SkillForm;
