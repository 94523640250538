import styles from "./LectureDetail.scss";
import LinkButton from "../../../components/atoms/LinkButton";
import { Flex } from "../../../components/atoms/Flex";
import Icon from "../../../components/atoms/Icon";
import { Text } from "../../../components/general/Text";
import * as React from "react";
import {
  FlexibleTable as Table,
  TableColumn,
  TableHeadColumn,
} from "../../../components/atoms/Table";
import { LectureSessionDetail } from "../../../domains/LectureSession";
import {
  translateAttendanceConfirm,
  translateAttendanceLimitDays,
  translateAttendanceLocationCollect,
  translateQuestionnairePresence,
} from "../../../domains/Lecture";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SpecialSizeText } from "./SpecialSizeText";

type LectureDetailProps = {
  sectionId: string;
  lecture: LectureSessionDetail["lecture"];
};
export const LectureDetail = ({ sectionId, lecture }: LectureDetailProps) => {
  const [isOpenLectureTable, setOpenLectureTable] = useState<boolean>(false);
  return (
    <>
      <LectureTableToggleButton
        onClick={() => setOpenLectureTable(!isOpenLectureTable)}
        isOpenLectureTable={isOpenLectureTable}
      />
      {isOpenLectureTable ? (
        <LectureTable sectionId={sectionId} lecture={lecture} />
      ) : null}
    </>
  );
};

type LectureTableToggleButtonProps = {
  onClick: () => void;
  isOpenLectureTable: boolean;
};
const LectureTableToggleButton = ({
  isOpenLectureTable,
  onClick,
}: LectureTableToggleButtonProps) => (
  <div className={styles.LectureTableToggleButton}>
    <LinkButton onClick={onClick}>
      <Flex marginTop="0" alignItems="center">
        {isOpenLectureTable ? (
          <Icon name="icon-arrow-down" color="primary" />
        ) : (
          <Icon name="icon-arrow-right" color="primary" />
        )}
        <SpecialSizeText color="primary" bold>
          講座内容を見る
        </SpecialSizeText>
      </Flex>
    </LinkButton>
  </div>
);

type LectureTableProps = {
  sectionId: string;
  lecture: LectureSessionDetail["lecture"];
};
const LectureTable = ({ sectionId, lecture }: LectureTableProps) => {
  return (
    <div className={styles.LectureTableWrapper}>
      <Table className={styles.LectureTable}>
        <thead className={styles.LectureTable__header}>
          <tr>
            <TableHeadColumn
              colSpan={4}
              className={styles.LectureTable__headerColumn}
            >
              <Flex
                marginTop="0"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text bold color="black">
                  {lecture.name}
                </Text>
                <Link
                  to={`/sections/${sectionId}/settings/lectures/${lecture.id}/edit`}
                  className={styles.LectureTable__editLink}
                >
                  <Flex marginTop="0" alignItems="center">
                    <Icon name="icon-external-link" />
                    <Text bold>編集</Text>
                  </Flex>
                </Link>
              </Flex>
            </TableHeadColumn>
          </tr>
        </thead>
        <tbody>
          <tr>
            <LectureTableColumn>講座の出席確認</LectureTableColumn>
            <LectureTableColumn>
              {translateAttendanceConfirm(lecture.attendanceConfirm)}
            </LectureTableColumn>
            <LectureTableColumn>講座の出席確認期限</LectureTableColumn>
            <LectureTableColumn>
              {translateAttendanceLimitDays(lecture.attendanceLimitDays)}
            </LectureTableColumn>
          </tr>
          <tr>
            <LectureTableColumn>アンケートの設置</LectureTableColumn>
            <LectureTableColumn>
              {translateQuestionnairePresence(lecture)}
            </LectureTableColumn>
            <LectureTableColumn>出席確認の位置情報判定</LectureTableColumn>
            <LectureTableColumn>
              {translateAttendanceLocationCollect(
                lecture.attendanceLocationCollect,
              )}
            </LectureTableColumn>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const LectureTableColumn = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <TableColumn className={styles.LectureTable__column}>
      {children}
    </TableColumn>
  );
};
