import * as React from "react";
import StudentInterface from "../../../interfaces/StudentInterface";
import enhanceStudentsPage from "../../../hocs/enhanceStudentsPage";
import {
  dispatchGetLearningMaterialsPreRegistrations,
  dispatchDeleteStudentPreRegisteredLearningMaterial,
} from "../../../actions/pages/StudentsLearningMaterialsPreRegistrationsPage/index";
import { connect } from "react-redux";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { StudentLearningMaterialsPreRegistrationsStateInterface } from "../../../interfaces/StudentLearningMaterialsPreRegistrationsStateInterface";
import StudentLearningMaterialsPreRegistrations from "../../../components/organisms/StudentLearningMaterialsPreRegistrations/index";
import {
  OutletContextProps,
  WithRouterProps,
} from "../../../helpers/RouterHelper";

interface Props
  extends WithRouterProps<{ studentId: string }>,
    OutletContextProps {
  student: StudentInterface | null;
  loadLearningMaterials: (studentId: string) => void;
  pageState: StudentLearningMaterialsPreRegistrationsStateInterface;
  deleteLearningMaterial: (studentId: string, materialCode: string) => void;
}

class StudentsLearningMaterialsPreRegistrationsPage extends React.Component<
  Props,
  Record<string, never>
> {
  constructor(props: Props) {
    super(props);

    if (props.student) {
      props.loadLearningMaterials(props.student.id);
    }
    props.setActiveMenu("text");
  }

  render() {
    if (!this.props.student) {
      return null;
    }

    return (
      <StudentLearningMaterialsPreRegistrations
        student={this.props.student}
        learningMaterialsState={this.props.pageState}
        onDelete={this.handleDelete}
      />
    );
  }

  private handleDelete = (materialCode: string) => {
    if (this.props.student) {
      this.props.deleteLearningMaterial(this.props.student.id, materialCode);
    }
  };
}

const actions = {
  loadLearningMaterials: dispatchGetLearningMaterialsPreRegistrations,
  deleteLearningMaterial: dispatchDeleteStudentPreRegisteredLearningMaterial,
};

const mapStateToProps = (state: AppStateInterface) => {
  return {
    pageState: state.studentLearningMaterialsPreRegistrations,
  };
};

const ConnectedStudentsLearningMaterialsPreRegistrationsPage = connect(
  mapStateToProps,
  actions,
)(StudentsLearningMaterialsPreRegistrationsPage);

export default enhanceStudentsPage(
  ConnectedStudentsLearningMaterialsPreRegistrationsPage,
  {
    title: "テキスト",
  },
);
